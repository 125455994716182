import isEmpty from 'lodash/isEmpty'

//
// The environment should never be called directly.
// We provides via this file methods to access to the getEnv()ironment configuration.
//
import { getEnv } from './environment'
import { getQueryParam } from 'pmt-utils/url'

const apiVersionQueryParam = getQueryParam('apiVersion')

export const QUERY_PARAM_API_CONSUMER = 'apiConsumer'
export const QUERY_PARAM_API_CONSUMER_DEPRECATED = 'apiconsumer'

export const getQueryApiConsumer = () =>
  getQueryParam(QUERY_PARAM_API_CONSUMER) || getQueryParam(QUERY_PARAM_API_CONSUMER_DEPRECATED)

export const getApiConsumer = () =>
  (window.globalEnvironmentSettings && window.globalEnvironmentSettings.apiConsumer) ||
  getQueryApiConsumer() ||
  getEnv().API_CONSUMER

export const getApiUrl = () => {
  let apiUrl = getEnv().API_URL
  // handle the 'apiVersion' query param, that allows to specify which api version we wants to use
  if (!isEmpty(apiVersionQueryParam)) {
    apiUrl = apiUrl.replace(/https:\/\/[\w-]*paymytable/, '')
    apiUrl = 'https://' + apiVersionQueryParam + '-dot-paymytable' + apiUrl
  }
  return apiUrl
}

export const getApiDocUrl = () => {
  // for trying the webservices, we want Rapidoc to use the API url (including the apiVersion if any) used by the current BO V2 instance
  // except in production where we don't want to use the "bo" apiVersion, but the default one
  if (isProd()){
    return getEnv().API_DOC_URL
  }
  return getApiUrl()
}

export const isLocal = () => getEnv().IS_LOCAL

export const isDev = () => getEnv().IS_DEV

export const isPreprod = () => getEnv().IS_PREPROD

export const isProd = () => getEnv().IS_PROD

export const isWebCustomer = () => getEnv().IS_WEB_CUSTOMER

export const isKiosk = () => getEnv().IS_KIOSK

export const isBo = () => getEnv().IS_BO

export const isOrderPlugin = () => getEnv().IS_ORDER_PLUGIN

export const isEmbeddedFront = () => isWebCustomer() || isOrderPlugin()

export const getGoogleMapApiUrl = () => getEnv().Google.Map.API_URL

export const getGoogleMapApiVersion = () => getEnv().Google.Map.API_VERSION

export const getGoogleMapApiKey = () => getEnv().Google.Map.API_KEY

export const hasAuthEnabled = () => getEnv().HAS_AUTH_ENABLED || (!isBo() && !isKiosk())

export const shouldUseAppConfig = () => {
  // we do not want to load app config on kiosk login page, since we define the api consumer to use
  // on the login page, not before
  if (isKiosk() && window.location.pathname.endsWith('/login')) {
    return false
  }
  return getEnv().SHOULD_USE_APP_CONFIG || false
}

export const isLocalhost = () => window.location.hostname === 'localhost'

export const __DEV__ = process.env.__DEV__

export const __VERSION__ = process.env.__VERSION__

export const __SDK_GIT_COMMIT__ = process.env.__SDK_GIT_COMMIT__
export const __APP_GIT_COMMIT__ = process.env.__APP_GIT_COMMIT__

export const __SDK_GIT_BRANCH__ = process.env.__SDK_GIT_BRANCH__
export const __APP_GIT_BRANCH__ = process.env.__APP_GIT_BRANCH__

export const __VERSION_GIT_HASH__ = `${__APP_GIT_COMMIT__}_${__SDK_GIT_COMMIT__}`

export const getPublicUrl = () => (isLocalhost() ? '' : 'https://' + getEnv().HOSTNAME)

export const shouldDisplayReportDialog = () => getEnv().Logger.DISPLAY_REPORT_DIALOG

export const getSentryUrl = () => getEnv().Logger.SENTRY_URL
