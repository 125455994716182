import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import compose from 'recompose/compose'
import ui from 'pmt-modules/reduxUi'

import { EventManager } from 'pmt-modules/event'

import { withStyles } from 'pmt-ui/styles'
import { CardItem } from 'pmt-ui/Card'
import Snackbar from 'pmt-ui/Snackbar'
import DoneIcon from 'pmt-ui/svg-icons/action/done'

import Button from '../../../../components/Button'
import CardItemOrder from '../../../../components/Card/CardItemOrder'

const styles = (theme) => {
  return {
    cardItemContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    buttonSelect: {
      borderRadius: 10,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    buttonSelectContained: {
      border: `2px solid ${theme.palette.primary.main}`,
      '&:hover': {
        borderColor: theme.palette.primary.dark,
      },
    },
  }
}

class ProductItem extends React.PureComponent {
  handleOnClickDisabledOption = () => {
    this.props.updateUI({ openedSnackbar: true })
  }

  handleClose = () => {
    this.props.updateUI({ openedSnackbar: false })
  }

  render() {
    const {
      product,
      orderMenu,
      restaurant,
      // true if the product can't be selected
      // (part hasReachedMaxQuantity)
      isSelectable,
      onSelectOrderProduct,
      onUnselectOrderProduct,
      onEditOrderProduct,
      onAddToCartFromShortcut,
      ui,
      classes,
    } = this.props

    const isSelectableAndDisabled = product.available && !isSelectable && product.quantity === 0

    return (
      <div className={classes.cardItemContainer}>
        <CardItem
          type={<CardItemOrder />}
          item={product}
          description={product.description}
          image={product.image}
          restaurantLogo={restaurant.logo}
          name={product.name}
          price={product.additionalPrice !== 0 ? `+${product.additionalPriceFormatted}` : null}
          isAvailable={product.available}
          isMenu={true}
          isSelectableAndDisabled={isSelectableAndDisabled}
          isCategory={product.isCategory}
          onClickCard={() => {
            if (isSelectableAndDisabled) {
              this.handleOnClickDisabledOption()
            } else if (product.available) {
              EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_ITEM_DETAIL, {
                item: product,
                menu: orderMenu,
                restaurant,
              })
              if (product.quantity > 0) {
                onEditOrderProduct()
              } else if (isSelectable) {
                onSelectOrderProduct()
              }
            }
          }}
          buttonAdd={
            <div
              onClick={(e) => {
                e.stopPropagation()
                if (isSelectableAndDisabled) {
                  this.handleOnClickDisabledOption()
                }
              }}
            >
              <Button
                size="small"
                variant={product.quantity === 0 ? 'outlined' : 'contained'}
                disabled={isSelectableAndDisabled}
                classes={{
                  root: classNames(classes.buttonSelect, {
                    [classes.buttonSelectOutlined]: product.quantity === 0,
                    [classes.buttonSelectContained]: product.quantity !== 0,
                  }),
                }}
                icon={product.quantity > 0 && <DoneIcon />}
                onClick={(e) => {
                  e.stopPropagation()
                  if (product.available) {
                    if (product.quantity > 0) {
                      onUnselectOrderProduct()
                    } else if (isSelectable) {
                      if (product.isComplexItem) {
                        EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_ITEM_DETAIL, {
                          item: product,
                          menu: orderMenu,
                          restaurant,
                        })
                        if (product.isProduct) {
                          onSelectOrderProduct()
                        }
                      } else {
                        onAddToCartFromShortcut()
                      }
                    }
                  }
                }}
                label={tr(
                  product.quantity === 0
                    ? product.totalPrice > 0
                      ? 'order.menu.select_with_price_with_plus'
                      : 'order.menu.select'
                    : product.totalPrice > 0
                    ? 'order.menu.selected_with_price_with_plus'
                    : 'order.menu.selected',
                  {
                    price: product.totalPriceFormatted,
                  }
                )}
              />
            </div>
          }
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={ui.openedSnackbar}
          autoHideDuration={4000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{tr('global.menu.product.disabled')}</span>}
        />
      </div>
    )
  }
}

export default compose(
  ui({
    state: {
      openedSnackbar: false,
    },
  }),
  withStyles(styles)
)(ProductItem)
