// import React from 'react'

import { connect } from 'react-redux'

import { makeGetOrderContext } from '../selectors'

const OrderRestaurantContextContainer = ({ restaurantId, orderContext, children }) =>
  children({
    orderContext,
  })

const makeMapStateToProp = () => {
  const getOrderContext = makeGetOrderContext()

  return (state, props) => ({
    orderContext: getOrderContext(state, props),
  })
}

export default connect(makeMapStateToProp)(OrderRestaurantContextContainer)
