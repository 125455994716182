import { createAction } from '../redux'
import {
  createApiCallAction,
  createApiEnumAction,
} from '../api/utils'
import { getSearchApiCallFunc } from './config'


export const SearchAction = {
  CREATE: 'SEARCH::CREATE',
  UPDATE: 'SEARCH::UPDATE',
  RESET: 'SEARCH::RESET',
}

/**
 *
 * @param {string} TYPE The SearchType
 * @param {string} formType The SearchType
 * @param {any} data The form data
 */
export const initSearch = (searchType, data) => createAction(
  SearchAction.CREATE,
  {
    searchType,
    data,
  }
)

/**
 *
 * @param {string} TYPE The SearchType
 * @param {string} searchType The SearchType
 * @param {any} data The form data
 */
export const updateSearch = (searchType, data) => createAction(
  SearchAction.UPDATE,
  {
    searchType,
    data,
  }
)

/**
 *
 * @param {string} TYPE The SearchType
 * @param {string} searchType The SearchType
 * @param {any} data The form data
 */
export const resetSearch = (searchType, data) => createAction(
  SearchAction.RESET,
  {
    searchType,
    data,
  }
)

export const GetSearchListAction = createApiEnumAction('SEARCH::GET')

export const getSearch = (searchType, props) => createApiCallAction(
  GetSearchListAction,
  getSearchApiCallFunc(searchType)(props),
  {
    searchType,
    props,
  }
)
