import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Sulphites = props => (
  <SvgIcon {...props}>
    <path
      d="M63.087,14.649,59.158,6.3V0h-6.9V6.3l-3.929,8.348a3.162,3.162,0,0,0,2.861,4.506h9.039a3.162,3.162,0,0,0,2.861-4.506ZM53.44,6.565V1.185h4.534v5.38L60.5,11.924H50.918Zm6.786,11.4H51.187a1.978,1.978,0,0,1-1.976-1.976,1.958,1.958,0,0,1,.187-.84l.962-2.045H61.053l.962,2.045a1.957,1.957,0,0,1,.187.84A1.978,1.978,0,0,1,60.226,17.97Z"
      transform="translate(-44 3)"
    />
  </SvgIcon>
)
Sulphites = pure(Sulphites)
Sulphites.displayName = 'Sulphites'
Sulphites.muiName = 'SvgIcon'

export default Sulphites
