import isNil from 'lodash/isNil'

import createMiddleware from 'pmt-modules/redux/createMiddleware'
import { RestaurantAction } from 'pmt-modules/restaurant/actions'

import { setOrderSettings } from './actions'

const setOrderSettingsWhenHavingARestaurant = createMiddleware(
  RestaurantAction.SET,
  ({ dispatch, action }) => {
    if (!isNil(action.restaurant)) {
      const orderSettings = action.restaurant.orderSettings
      dispatch(setOrderSettings(orderSettings))
    }
  }
)

export const orderSettingsMiddlewares = [setOrderSettingsWhenHavingARestaurant]
