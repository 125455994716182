import concat from 'lodash/concat'
import isNil from 'lodash/isNil'

import { formatProduct } from 'pmt-modules/catalog/format/product'
import { formatMenu } from 'pmt-modules/catalog/format/category'
import { CatalogItemType } from 'pmt-modules/catalog/constants'

export const mergeSuggestedProductsAndMenus = (suggestedProducts, suggestedMenus) => {
  let suggestedItems = []

  if (isNil(suggestedProducts) && isNil(suggestedMenus)) {
    return suggestedItems
  }

  suggestedProducts = (suggestedProducts || []).map(product => {
    product.type = CatalogItemType.PRODUCT
    product = formatProduct(product)

    return product
  })
  suggestedMenus = (suggestedMenus || []).map(menu => {
    menu.type = CatalogItemType.MENU
    menu = formatMenu(menu)

    return menu
  })

  suggestedItems = concat(suggestedProducts, suggestedMenus).sort((a, b) => a.position > b.position)

  return suggestedItems
}
