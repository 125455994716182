const styles = (theme) => ({
  root: {},
  date: {
    borderBottom: `1px solid ${theme.pmt.colors.grey300}`,
    cursor: 'pointer',
  },
  slotContainer: {
    overflow: 'hidden',
    height: 0,
    opacity: 0,
    transition: 'opacity 0.3s ease-in',
  },
  slot: {
    float: 'left',
    width: 50,
    padding: '0.35rem 0.5rem',
    margin: '5px 5px 0 0',
    textAlign: 'center',
    fontSize: 12,
    border: `1px solid ${theme.pmt.colors.grey500}`,
    borderRadius: 3,
    userSelect: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  slotSelected: {
    background: theme.palette.primary.main,
    color: theme.pmt.colors.white,
    '&:hover': {
      color: theme.pmt.colors.white,
    },
  },
  slotAsap: {
    width: '160px !important',
  },
  dateIcon: {
    float: 'right',
    color: theme.palette.primary.main,
  },
  errorMessage: {
    color: theme.pmt.colors.red800,
  },
  textInitial: {
    textTransform: 'initial',
  },
  white: {
    color: theme.pmt.colors.white,
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  blockContainer: {
    padding: theme.spacing(2),
  },

  // type big specific styles
  bigSlotContainerSelected: {
    height: 'auto',
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingLeft: 0,
    opacity: 1,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      paddingBottom: theme.spacing(2),
    },
  },
  bigDateLabel: {
    display: 'block',
    padding: `${theme.spacing(2)}px 0`,
    lineHeight: '35px',
    cursor: 'pointer',
  },
  bigDateIcon: {
    width: 35,
    height: 35,
  },
  bigRadioButton: {
    paddingLeft: 0,
  },
  bigRadioButtonLabel: {
    cursor: 'pointer',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },

  // type small specific styles
  smallSlotContainerSelected: {
    height: 'auto',
    padding: theme.spacing(0.5),
    paddingTop: 0,
    paddingLeft: 0,
    opacity: 1,
  },
  smallDateLabel: {
    display: 'block',
    padding: `${theme.spacing(1)}px 0`,
    lineHeight: '25px',
    cursor: 'pointer',
  },
  smallDateIcon: {
    width: 25,
    height: 25,
  },
  smallRadioButton: {
    height: 25,
    paddingLeft: 0,
  },
  smallRadioButtonLabel: {
    cursor: 'pointer',
    flex: 1,
  },
  slotRow: {
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    maxWidth: 300,
  },
  bigButton: {
    width: 'calc(100% - 70px)',
    marginLeft: 0,
    marginTop: theme.spacing(1),
    maxWidth: 300,
  },
  dueDateLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconClock: {
    width: 17,
    height: 17,
    transition: 'none !important',
  },
  select: {
    borderRadius: theme.shape.border.radius.content,
    borderWidth: theme.shape.border.width.default,
    borderColor: theme.shape.border.color.default,
    borderStyle: 'solid',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
  },
  smallSelect: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  inputBaseSelect: {
    padding: 0,
  },
  buttonLink: {
    fontSize: 12,
    flexShrink: 0,
  },
  iconModify: {
    color: theme.palette.primary.main,
    width: '10px !important',
    height: '10px !important',
  },
})

export default styles
