import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'

import { getFrontSettings, getOrderSettings } from 'pmt-modules/appConfig'
import {
  getOrderFrontDatas,
  getOrderProperties as getOrderFrontProperties,
} from 'pmt-modules/orderFront/selectors'
import { getOpeningHours, getOrderSettingsData } from 'pmt-modules/orderSettings'
import { getUserSelectedCreditCards } from 'pmt-modules/creditCard/selectors'
import { getOrderPreview, getOrderPreviewVerifications } from 'pmt-modules/orderPreview/selectors'

import { formatOrderProperties, formatAppConfigFrontSettings } from './format'
import { generateOrderData } from './utils'

const getOrderPlugin = state => state.entities.orderPlugin

const getSelectedCreditCard = createSelector([getUserSelectedCreditCards], selectedCreditCards => {
  if (selectedCreditCards && !isEmpty(selectedCreditCards)) {
    return selectedCreditCards[0]
  }

  return null
})

export const getOrderProperties = createSelector(
  [
    getOrderPlugin,
    getOrderFrontProperties,
    getOrderSettingsData,
    getOpeningHours,
    getOrderSettings,
    getOrderPreviewVerifications,
    getSelectedCreditCard,
  ],
  (
    orderPluginData,
    orderFrontProperties,
    orderSettingsData,
    openingHours,
    orderAppConfig,
    orderPreviewVerifications,
    selectedCreditCard
  ) =>
    formatOrderProperties(orderPluginData.toJS(), {
      orderFrontProperties,
      orderSettingsData,
      openingHours,
      orderAppConfig,
      orderPreviewVerifications,
      selectedCreditCard,
    })
)

export const getOrderData = createSelector(
  [
    getOrderProperties,
    getOrderFrontDatas,
    getOrderPreview,
    getOrderPreviewVerifications,
    getSelectedCreditCard,
  ],
  (orderProperties, orderFrontDatas, orderPreview, orderPreviewVerifications, selectedCreditCard) =>
    generateOrderData({
      orderProperties,
      orderFrontDatas,
      orderPreview,
      orderPreviewVerifications,
      selectedCreditCard,
    })
)

export const getAppConfigFrontSettings = createSelector(
  [getOrderProperties, getFrontSettings],
  (orderProperties, frontSettings) => {
    const restaurantId =
      orderProperties.restaurantId || orderProperties.postedOrderData?.orderProperties?.restaurantId
    const tableNumber =
      orderProperties.tableNumber || orderProperties.postedOrderData?.orderProperties?.tableNumber
    const posCheckId =
      orderProperties.posCheckId || orderProperties.postedOrderData?.orderProperties?.posCheckId
    return formatAppConfigFrontSettings(frontSettings, { restaurantId, tableNumber, posCheckId })
  }
)
