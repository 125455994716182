import React from 'react'
import isNil from 'lodash/isNil'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { getQueryParam } from 'pmt-utils/url'
import { WebRedirectCallbackStatus } from 'pmt-modules/payment'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withUserMe } from 'pmt-modules/userMe'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import { getOrderData } from 'pmt-modules/orderPlugin/selectors'
import { getOrderProperties } from 'pmt-modules/orderPlugin/selectors'
import {
  postPspDatas,
  throwUserCreditCardToPspSuccess,
  throwUserCreditCardToPspFailure,
  throwUserCreditCardToPspCancel,
  resetUserCreditCardToPspSend,
} from 'pmt-modules/creditCard/actions'
import { PspRegisterUserCardType } from 'pmt-modules/creditCard/constants'
import { getPostPspDatasIsFetching, getPostPspDatasError } from 'pmt-modules/creditCard/selectors'
import BlockContainer from '../../../components/BlockContainer'

const styles = (theme) => ({
  blockContainer: {
    width: '100%',
    height: '100%',
  },
})

/**
 * Payment success with 3DS or redirecting when adding a new credit cart on PayGreen. With Paygreen,
 * we only use this page, not the cancel / failure.
 */
@withRestaurant
@withUserMe
class PaymentSuccessPage extends React.Component {
  shouldComponentUpdate(nextProps) {
    // we wait for the restaurant to be loaded
    if (nextProps.restaurant !== this.props.restaurant || nextProps.user !== this.props.user) {
      return true
    }
    return false
  }

  componentDidUpdate(prevProps) {
    const props = this.props

    // The restaurant and the user has been loaded, lets go.
    // we must only do this once.
    if (
      !isNil(props.restaurant) &&
      !isNil(props.user) &&
      (props.user !== prevProps.user || props.restaurant !== prevProps.restaurant)
    ) {
      const { restaurant, user } = props

      // TODO:
      // for paygreen we need some information here, such as the restaurant / user.
      // so we need to modify this to retrieve the data, and move the following on the receive props
      // once everything is loaded.

      const pspRegisterUserCardType = restaurant.pspRegisterUserCardType

      if (pspRegisterUserCardType === PspRegisterUserCardType.WEB_REDIRECT) {
        // While payzen has different url for success / error / cancel, paygreen only redirect to here.
        //
        // 1/ check for success / error / cancel
        // 2/ redirect to the right page if error or cancel
        // 3/ if success we need to notify the success
        // we can do this if we are on an iframe, otherwise we need to redirect
        // window.parent.postMessage(
        //   { status: PayZenCallbackStatus.SUCCESS, searchQuery: props.location.search.substr(1) },
        //   getAbsolutePath(getRoute('ORDER__PAYMENT__SUCCESS'))
        // )
        const result = getQueryParam('result')
        // transaction id, for example cpb91ac0dff7869135ff9600f3b1a4130d
        const pid = getQueryParam('pid')
        // not really the order id but a generated id by our api when creating the card
        // const orderId = getQueryParam('orderId')

        const restaurantId = restaurant.id
        // or props.orderData.userId , is the same, we must load the user to access the userId.
        const userId = user.id
        const data = pid

        switch (result) {
          case WebRedirectCallbackStatus.SUCCESSED:
            props.postPspDatas(userId, restaurantId, null, null, data)
            // redirect to payment page to avoid creating the card again
            props.redirectTo(getRoute('ORDER__PAYMENT'))
            break

          case WebRedirectCallbackStatus.CANCELLED:
            props.redirectTo(getRoute('ORDER__PAYMENT'))
            break

          // TODO: other cases
          default:
            break
        }
      }
    }
  }

  render() {
    const { classes } = this.props
    return (
      <BlockContainer className={classes.blockContainer} type={BlockContainer.Type.WHITE}>
        <LoadingBlock show />
      </BlockContainer>
    )
  }
}

const mapStateToProps = (state, props) => ({
  errorPostPspDatas: getPostPspDatasError(state),

  postPspDatasIsFetching: getPostPspDatasIsFetching(state),

  orderData: getOrderData(state),

  orderProperties: getOrderProperties(state),
})

export default compose(
  connect(mapStateToProps, {
    redirectTo,
    postPspDatas,
    throwUserCreditCardToPspSuccess,
    throwUserCreditCardToPspFailure,
    throwUserCreditCardToPspCancel,
    resetUserCreditCardToPspSend,
  }),
  withStyles(styles)
)(PaymentSuccessPage)
