import createMiddleware from 'pmt-modules/redux/createMiddleware'

import { RefreshTokenFlowAction } from './actions'

const retryApiCallMiddleware = createMiddleware(
  RefreshTokenFlowAction.RETRY_API_CALL,
  ({ action, dispatch }) => {
    dispatch(action.actionToRetry)
  }
)

export const refreshTokenMiddlewares = [retryApiCallMiddleware]
