import moment from 'moment'

const bodyIndexes = [
  'paymytable_card_number',
  'paymytable_card_expiration_month_MM',
  'paymytable_card_expiration_year_YYYY',
  'paymytable_card_expiration_date_MMYY',
  'paymytable_card_expiration_date_MMYYYY',
  'paymytable_card_cvv',
  'paymytable_card_type',
  'fake_psp_expected_behaviour_placeholder',
]

// we replace indexes from the API response
// so we can send it to the PSP fully complete
export const replaceIndexesByCreditCardFields = (body, creditCard) => {
  if (body) {
    Object.keys(body).forEach(key => {
      const foundKey = bodyIndexes.filter(index => index === body[key]).pop()
      const expirationDateMonth =
        creditCard.expirationDateMonth <= 9
          ? `0${creditCard.expirationDateMonth}`
          : creditCard.expirationDateMonth
      if (foundKey) {
        switch (foundKey) {
          case 'paymytable_card_number':
            body[key] = creditCard.number
            break

          case 'paymytable_card_expiration_month_MM':
            body[key] = expirationDateMonth
            break

          case 'paymytable_card_expiration_year_YYYY':
            body[key] = creditCard.expirationDateYear
            break

          case 'paymytable_card_expiration_date_MMYY':
            const expirationDateYear = parseInt(
              creditCard.expirationDateYear.toString().substr(-2),
              10
            )
            body[key] = `${expirationDateMonth}${expirationDateYear}`
            break

          case 'paymytable_card_expiration_date_MMYYYY':
            body[key] = `${expirationDateMonth}${creditCard.expirationDateYear}`
            break

          case 'paymytable_card_cvv':
            body[key] = creditCard.cvv
            break

          case 'paymytable_card_type':
            body[key] = creditCard.type
            break

            case 'fake_psp_expected_behaviour_placeholder':
            body[key] = creditCard.cvv === '999' ? 'FAILURE' : 'SUCCESS' // see API enum PspFakeExpectedBehaviour
            break

          default:
            break
        }
      }
    })

    return body
  }

  return null
}

export const getCurrentYear = () => parseInt(moment().format('YYYY'), 10)
export const getExpirationYears = currentYear => {
  let expirationYears = []
  // 3 years before the current year so autocompleted expired credit card can fill the form
  // and trigger the display of error message 'invalid expiration date'
  for (let i = -3; i <= 20; i++) {
    expirationYears.push(currentYear + i)
  }

  return expirationYears
}
