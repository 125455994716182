import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Arrow = props => (
  <SvgIcon {...props} width="59.42" height="57.674" viewBox="0 0 59.42 57.674">
    <g
      id="Raggruppa_8028"
      data-name="Raggruppa 8028"
      transform="translate(16.273 -1.317) rotate(23)"
    >
      <path
        id="Tracciato_12175"
        data-name="Tracciato 12175"
        d="M23222.383,19995.465c-.027,0-.055,0-.08,0l-.006,0c-.023,0-.045,0-.066,0a3.076,3.076,0,0,1-1.207-.326,2.547,2.547,0,0,1-1.221-1.256,3.252,3.252,0,0,0-.234-.6c-.012-.025-.023-.055-.037-.082l-.014-.029a.779.779,0,0,1-.049-.127l-.006-.012a4.015,4.015,0,0,1-.08-.883l-.006-.012c-.014-.357-.01-.7.006-1.092l0-.018c.023-.734.131-1.639.229-2.428l.014-.107c.01-.08.018-.16.025-.234s.014-.119.02-.174a1.056,1.056,0,0,0-.141-.869l-1.016-7.883-.023-.182-.082-.162a.639.639,0,0,1-.047-.5.614.614,0,0,1,.3-.371.624.624,0,0,1,.309-.082.632.632,0,0,1,.561.34l.07.127.1.105a24.187,24.187,0,0,1,2.545,2.914,10.07,10.07,0,0,1,1.232,2.283l.006.006c.053.133.09.236.117.326l.689,2.063,1.115-1.863a76.443,76.443,0,0,1,5.086-7.451,71.29,71.29,0,0,1,8.053-8.8c1.389-1.283,2.924-2.566,4.564-3.818,1.551-1.176,3.189-2.314,4.871-3.381a131.7,131.7,0,0,1,15.625-8.3l.059-.029a.673.673,0,0,1,.316-.08.655.655,0,0,1,.566.332.649.649,0,0,1-.252.869l-.07.041c-3.148,2.088-5.963,4.141-8.537,6.045-2.75,2.041-5.4,4.146-8.092,6.439-1.406,1.189-2.8,2.43-4.1,3.592-2.215,1.969-4.154,3.8-5.928,5.588a92.809,92.809,0,0,0-9.342,10.9l-1.426,1.961,2.389-.385c.221-.033.408-.055.572-.064.318-.02.576-.029.836-.029a10.075,10.075,0,0,1,1.764.152,24.972,24.972,0,0,1,3.725,1.045l.135.047.15.006a.643.643,0,0,1-.035,1.285h-.027l-.187-.012-7.73,2.545-.025,0a.8.8,0,0,0-.121-.01,1.117,1.117,0,0,0-.674.244l-.008,0-.02.016-.379.24-.105.068-.062.041c-.531.338-1.334.848-1.98,1.18A5.934,5.934,0,0,1,23222.383,19995.465Z"
        transform="translate(-23217.332 -19951.457)"
      />
    </g>{' '}
  </SvgIcon>
)
Arrow = pure(Arrow)
Arrow.displayName = 'Arrow'
Arrow.muiName = 'SvgIcon'

export default Arrow
