import Immutable from 'immutable'

import { UiAction } from './actions'
import ui from './ui'

export * from './actions'
export * from './selectors'

const DEFAULT = Immutable.fromJS({})

export const uiReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case UiAction.UPDATE:
      return state.mergeIn([action.key], action.data)

    default:
      return state
  }
}

export default ui
