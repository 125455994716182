import React from 'react'
import PropTypes from 'prop-types'

import isNil from 'lodash/isNil'

// https://blog.logrocket.com/advanced-react-router-concepts-code-splitting-animated-transitions-scroll-restoration-recursive-17096c0cf9db
class ScrollToTop extends React.Component {
  state = {
    scrollToTop: false,
  }

  constructor(props) {
    super(props)

    if (props.force) {
      this.setState({ scrollToTop: true })
      window.scrollTo(0, 0)
    }
  }

  componentDidUpdate(prevProps) {
    // Note: we can't compare the location object, since it changes when the anchor change
    if (
      (this.props.location.pathname !== prevProps.location.pathname ||
        // we don't want to scroll to top if we change category
        (!isNil(this.props.isInactive) && !this.props.isInactive(prevProps, this.props)) ||
        isNil(this.props.isInactive)) &&
      !this.state.scrollToTop &&
      !this.props.force
    ) {
      this.setState({ scrollToTop: true })
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { WrappedComponent, ...otherProps } = this.props
    return <WrappedComponent {...otherProps} />
  }
}

ScrollToTop.defaultProps = {
  isInactive: null,
  force: false,
}

ScrollToTop.propTypes = {
  isInactive: PropTypes.func,
  force: PropTypes.bool,
}

export default ScrollToTop
