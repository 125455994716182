import moment from 'moment'
import invariant from 'invariant'
import isEqualWith from 'lodash/isEqualWith'
import isEqual from 'lodash/isEqual'

import { QueryParamType, StatisticsPeriod } from './constants'

export const statisticsOptionsAreEqual = (config, options, optionsToCompare) => {
  // no previous props, so nothing to compare with
  if (!options || !optionsToCompare) {
    return
  }

  const optionsAreEqual = isEqualWith(options, optionsToCompare, (options, optionsToCompare) =>
    getQueryParamsConfig(config).every(queryParamConfig => {
      const optionValue = options[queryParamConfig.name]
      const optionValueToCompare = optionsToCompare[queryParamConfig.name]

      switch (queryParamConfig.type) {
        case QueryParamType.DATE:
          return (
            optionValue.format(queryParamConfig.dateFormat) ===
            optionValueToCompare.format(queryParamConfig.dateFormat)
          )

        default:
          return isEqual(optionValue, optionValueToCompare)
      }
    })
  )
  return optionsAreEqual
}

/**
 * keys of the options we must handle on the url and their config
 */
export const getQueryParamsConfig = config => {
  return [
    {
      name: 'fromTime',
      type: QueryParamType.DATE,
      dateFormat: 'DD/MM/YYYY',
    },
    {
      name: 'toTime',
      type: QueryParamType.DATE,
      dateFormat: 'DD/MM/YYYY',
      endOfDay: true,
    },
    {
      name: 'period',
      type: QueryParamType.STRING,
    },
    ...(config && config.queryParamsConfig ? config.queryParamsConfig : []),
  ]
}

const getDefaultCustomValue = () => {
  return {
    fromTime: moment().startOf('day'),
    toTime: moment().endOf('day'),
  }
}

export const getPeriodDates = (period, defaultCustomValue = getDefaultCustomValue()) => {
  switch (period) {
    case StatisticsPeriod.TODAY:
      return {
        fromTime: moment().startOf('day'),
        toTime: moment().endOf('day'),
      }

    case StatisticsPeriod.LAST_SEVEN_DAYS:
      return {
        fromTime: moment()
          .subtract(1, 'week')
          .startOf('day'),
        toTime: moment().endOf('day'),
      }

    case StatisticsPeriod.LAST_THIRTY_DAYS:
      return {
        fromTime: moment()
          .subtract(29, 'days')
          .startOf('day'),
        toTime: moment().endOf('day'),
      }

    case StatisticsPeriod.CUSTOM:
      return defaultCustomValue

    default:
      invariant(false, `Invalid period ${period}`)
  }
}
