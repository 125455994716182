import { createAction } from '../../redux'

import { createApiCallAction, createApiEnumAction } from '../../api/utils'

import TopUpApi from '../../api/calls/TopUpApi'

export const PostTopUpAction = createApiEnumAction('PostTopUpAction')

export const postTopUpWithCreditCard = (userAccount, selectedCreditCard, topUp) =>
  createApiCallAction(
    PostTopUpAction,
    TopUpApi.postTopUpWithCreditCard(
      userAccount.userId,
      userAccount.id,
      selectedCreditCard.id,
      topUp
    ),
    {
      userAccount,
      selectedCreditCard,
      topUp,
    }
  )

export const postTopUp = (userAccount, userId, topUp, options) =>
  createApiCallAction(PostTopUpAction, TopUpApi.postTopUp(userAccount.id, userId, topUp), {
    userAccount,
    userId,
    topUp,
    options,
  })

//
//
//

export const TopUpAction = {
  INIT_TOP_UP: 'TOP_UP::INIT_TOP_UP',
  SELECT_AMOUNT: 'TOP_UP::SELECT_AMOUNT',
  UNSELECT_AMOUNT: 'TOP_UP::UNSELECT_AMOUNT',
  CLICK_OTHER_AMOUNT: 'TOP_UP::CLICK_OTHER_AMOUNT',
  RESET: 'TOP_UP::RESET',
}

export const topUpInit = userAccountId => createAction(TopUpAction.INIT_TOP_UP, { userAccountId })

export const topUpSelectAmount = (userAccountId, amount) =>
  createAction(TopUpAction.SELECT_AMOUNT, { userAccountId, amount })

export const topUpUnselectAmount = (userAccountId, amount) =>
  createAction(TopUpAction.UNSELECT_AMOUNT, { userAccountId, amount })

export const topUpClickOtherAmount = userAccountId =>
  createAction(TopUpAction.CLICK_OTHER_AMOUNT, { userAccountId })

export const resetTopUp = userAccountId => createAction(TopUpAction.RESET, { userAccountId })
