import { tr } from 'pmt-modules/i18n'
import React from 'react'

import Input, { InputLabel, InputAdornment } from '../Input'
import { FormHelperText } from 'pmt-ui/Form'
import IconButton from '../IconButton'
import Visibility from '../svg-icons/action/visibility'
import VisibilityOff from '../svg-icons/action/visibility-off'

import isEmpty from 'lodash/isEmpty'
import { makeStyles } from 'pmt-ui/styles'

import HibpPasswordRangeContainer from 'pmt-modules/hibp/container/HibpPasswordRangeContainer'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
}))

const HibpPasswordView = ({
  password,
  pwnedText,
  displayIsSecureMessage = true,
  displayCheckSecure = true,
  minPasswordLength = 8,
}) => {
  const classes = useStyles()

  // Kiosk doesn't need HIBP here, and thus, not message block either
  let MessageBlock = null
  if (!process.env.FEATURE_KIOSK) {
    MessageBlock = require('app/components/MessageBlock').default
  }

  return (
    <HibpPasswordRangeContainer password={password}>
      {({ isPwned, isFetching }) => {
        if (isFetching) {
          if (displayCheckSecure) {
            return (
              <MessageBlock
                classes={{ root: classes.root }}
                type={MessageBlock.Type.INFO}
                text={tr('security.hibp.pwned.checking')}
              />
            )
          }
          return null
        }

        if (isPwned) {
          return (
            <MessageBlock
              classes={{ root: classes.root }}
              type={MessageBlock.Type.WARNING}
              text={pwnedText}
            />
          )
        }

        const isTooSmall = isEmpty(password) || password.length < minPasswordLength
        if (displayIsSecureMessage && !isTooSmall) {
          return (
            <MessageBlock
              classes={{ root: classes.root }}
              type={MessageBlock.Type.INFO}
              text={tr('security.hibp.pwned.is_secure')}
            />
          )
        }
        return null
      }}
    </HibpPasswordRangeContainer>
  )
}

class PasswordField extends React.Component {
  constructor(props) {
    super(props)

    // we don't think we can have > 10 password fields on a same page, so this should work
    this.key = Math.random() * 100 + 1
  }

  state = {
    showPassword: false,
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    })
  }

  // Handle field change event, pass name and value to the onChange callback.
  handleChange = event => {
    this.props.onChange(event)
  }

  render() {
    const {
      label,
      value,
      name,
      fullWidth,
      disabled,
      className,
      helperText,
      classes,
      checkSecurity = false,
      ...props
    } = this.props

    const key = `adornment-password_${this.key}`

    return (
      <div className={className}>
        <InputLabel htmlFor={key}>{label}</InputLabel>
        <Input
          id={key}
          name={name}
          type={this.state.showPassword ? 'text' : 'password'}
          value={value}
          fullWidth={fullWidth || false}
          disabled={disabled || false}
          onChange={this.handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}
              >
                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          {...props}
          variant={'outlined'}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {checkSecurity && (
          <HibpPasswordView
            password={value}
            pwnedText={tr('security.hibp.pwned.should_choose_another')}
          />
        )}
      </div>
    )
  }
}

export default PasswordField
