import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Cheese = props => (
  <SvgIcon {...props}>
    <path d="M18.61,14.6c-.32-.02-.55-.26-.55-.56,0-.31,.23-.54,.55-.56,.29-.02,.46-.2,.46-.48,0-.56,0-1.13,0-1.69,0-.45,0-.89,0-1.34,0-.14-.05-.25-.17-.35-.06-.05-.13-.08-.2-.11-.03-.01-.06-.02-.08-.03l-1.89-.84c-2.27-1.01-4.61-2.05-6.91-3.08-.21-.09-.39-.13-.59-.11-.28,.02-.6,.06-.93,.15-2.01,.55-3.37,2.33-3.37,4.42v5.1c0,.98,0,1.95,0,2.93,0,.34,.18,.52,.51,.52,.98,0,1.96,0,2.94,0,.32,0,.49-.16,.51-.48,.02-.31,.26-.54,.56-.54h0c.3,0,.54,.23,.56,.54,.02,.31,.2,.48,.5,.48,2.69,0,5.37,0,8.06,0,.31,0,.49-.18,.49-.49,0-.96,0-1.96,0-2.97,0-.29-.17-.47-.46-.5ZM5.91,9.25c.15-.77,.56-1.48,1.17-2,.6-.53,1.36-.84,2.14-.89,.09,0,.17,0,.24,.04,1.7,.75,3.39,1.51,5.09,2.26l1.54,.68s0,0,.01,0c.03,.01,.07,.03,.1,.1l.02,.07H6.12c-.11,0-.17,0-.21-.04-.04-.04-.02-.1,0-.22Zm12.25,8.21c0,.05,0,.11-.04,.14-.04,.04-.09,.04-.14,.04h0c-2.21,0-4.56,0-6.96,0h0c-.11,0-.16-.04-.21-.14-.28-.58-.73-.88-1.35-.88-.31,0-.57,.07-.8,.22-.22,.15-.41,.37-.55,.66-.04,.09-.09,.14-.21,.14-.68,0-1.29,0-1.87,0h0s-.1,0-.14-.04c-.04-.04-.04-.09-.04-.14,0-2.7,0-4.87,0-6.85,0-.07,.01-.12,.05-.15,.04-.04,.1-.04,.16-.04,1.64,0,3.3,0,4.91,0h2.16c1.58,0,3.22,0,4.82,0,.08,0,.13,0,.17,.05,.04,.04,.05,.11,.05,.17,0,.62,0,1.25,0,1.83,0,.12-.04,.18-.15,.23-.58,.28-.87,.74-.86,1.35,0,.61,.3,1.06,.88,1.33,.09,.04,.14,.09,.14,.21,0,.64,0,1.27,0,1.87Z" />
    <path d="M10.35,12.12c-.37-.37-.87-.58-1.39-.58h0c-1.1,0-1.99,.89-1.98,1.99,0,1.09,.89,1.97,1.99,1.97,1.09,0,1.97-.88,1.98-1.97,0-.54-.21-1.04-.58-1.42Zm-.64,2.16c-.2,.2-.47,.32-.76,.32h0c-.58,0-1.06-.48-1.07-1.06,0-.29,.11-.56,.31-.76,.2-.2,.47-.32,.75-.32h0c.59,0,1.07,.48,1.08,1.07,0,.29-.11,.55-.31,.76Z" />
    <path d="M15.59,14.02c-.28-.28-.65-.44-1.04-.44h0c-.81,0-1.47,.66-1.48,1.47h0c0,.4,.15,.77,.43,1.05,.28,.28,.65,.43,1.03,.43s.77-.16,1.04-.43,.43-.63,.44-1.04c0-.39-.15-.76-.43-1.04Zm-1.03,1.6h-.01c-.15,0-.28-.06-.39-.16-.11-.11-.17-.25-.17-.4,0-.31,.25-.56,.55-.56h0c.3,0,.55,.25,.56,.55,0,.31-.24,.56-.55,.57Z" />
  </SvgIcon>
)
Cheese = pure(Cheese)
Cheese.displayName = 'Cheese'
Cheese.muiName = 'SvgIcon'

export default Cheese
