import isEmpty from 'lodash/isEmpty'
import { getApiUrl, getApiConsumer } from 'pmt-modules/environment'
import { getAppTokenCodeOnUrl } from 'pmt-modules/appSecurity'

const getApiManagerOptions = state => ({
  apiUrl: getApiUrl(),

  headersMiddleware: () => {
    const headers = {
      'api-consumer': getApiConsumer(),
    }

    //
    // Add the `at` header that contains the app token code if exists
    //
    const appTokenCode = getAppTokenCodeOnUrl()
    if (!isEmpty(appTokenCode)) {
      headers.at = appTokenCode
    }

    return headers
  },
})

export default getApiManagerOptions
