import moment from 'moment'
import isNil from 'lodash/isNil'

import { getHourAndMinuteFromMs, isSameDay } from 'pmt-utils/date'
import { createFormatter } from 'pmt-utils/format'

import { PaymentMethodsAllowed } from '../constants'

export const formatPaymentMethodsAllowed = orderModeSettings => {
  if (isNil(orderModeSettings)) {
    return null
  }

  orderModeSettings.isIrlPaymentAllowed =
    (PaymentMethodsAllowed.IRL & orderModeSettings.paymentMethodsAllowed) !== 0
  orderModeSettings.isTRDPaymentAllowed =
    (PaymentMethodsAllowed.TRD & orderModeSettings.paymentMethodsAllowed) !== 0
  orderModeSettings.isCreditCardPaymentAllowed =
    (PaymentMethodsAllowed.CREDIT_CARD & orderModeSettings.paymentMethodsAllowed) !== 0
  orderModeSettings.isUserAccountPaymentAllowed =
    (PaymentMethodsAllowed.USER_ACCOUNT & orderModeSettings.paymentMethodsAllowed) !== 0

  orderModeSettings.isIrlPaymentOnlyAllowed =
    PaymentMethodsAllowed.IRL === orderModeSettings.paymentMethodsAllowed
  orderModeSettings.isCreditCardPaymentOnlyAllowed =
    PaymentMethodsAllowed.CREDIT_CARD === orderModeSettings.paymentMethodsAllowed
  orderModeSettings.isUserAccountPaymentOnlyAllowed =
    PaymentMethodsAllowed.USER_ACCOUNT === orderModeSettings.paymentMethodsAllowed
  orderModeSettings.isTRDPaymentOnlyAllowed =
    PaymentMethodsAllowed.TRD === orderModeSettings.paymentMethodsAllowed

  return orderModeSettings
}

export const formatSlots = (slots, selectedSlot) => {
  slots = slots.map(day => {
    day.isToday = false

    if (isSameDay(moment(), day.date)) {
      day.isToday = true
    }

    day.slots = day.slots.map(slot => {
      slot.hourData = getHourAndMinuteFromMs(slot.value)
      slot.isSelected = !isNil(selectedSlot) && selectedSlot === slot.value

      return slot
    })

    return day
  })

  return slots
}

export const formatOrderSettings = createFormatter(formatSlots, formatPaymentMethodsAllowed)
