import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import find from 'lodash/find'
import isNil from 'lodash/isNil'

import { withAppConfig } from 'pmt-modules/appConfig'
import { haveAuthCredentials } from 'pmt-modules/auth'
import { getItemListFromCart } from 'pmt-modules/cart'
import { EventManager } from 'pmt-modules/event'
import {
  getAppConfigFrontSettings,
  OrderPluginUrlCheckerContainer,
  getOrderProperties,
} from 'pmt-modules/orderPlugin'
import { isFetchingOrderPost, getErrorOrderPost } from 'pmt-modules/orderPost'
import { fieldsType } from 'pmt-modules/registration/forms/register/constants'
import { getRoute, redirectTo } from 'pmt-modules/routing'
import {
  withUserMe,
  updateProfile,
  isFetchingPutUserMe,
  getErrorputUserMe,
} from 'pmt-modules/userMe'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'

import { cleanForApi } from 'pmt-utils/phone'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'
import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'

import OrderPage from '../../../components/OrderPage'
import BlockContainer from '../../../components/BlockContainer'

import View from './View'

@withAppConfig
@withRestaurant
@withUserMe
@withScrollToTop()
class UserProfilePage extends React.PureComponent {
  constructor(props) {
    super(props)

    // user doesn't have credentials, go back to login
    if (!this.props.haveAuthCredentials) {
      this.props.redirectTo(getRoute('LOGIN'))
    }
  }

  componentWillReceiveProps(nextProps) {
    // user doesn't have credentials, go back to login
    if (this.props.haveAuthCredentials && !nextProps.haveAuthCredentials) {
      this.props.redirectTo(getRoute('LOGIN'))
    }
  }

  hasPhoneRequired = () =>
    find(
      this.props.userAppConfig.registration.form.fields,
      ({ required, type }) => required && type === fieldsType.PHONE
    )

  handleSubmit = formData => {
    EventManager.dispatch(EventManager.Events.ON_PROFILE_SUBMIT)

    this.props.updateProfile(
      {
        ...formData,
        phone: cleanForApi(formData.phone),
        id: this.props.me.id,
      },
      {
        isFromProfile: true,
      }
    )
  }

  render() {
    const {
      frontSettings,
      isFetchingMe,
      isFetchingOrderPost,
      isFetchingPutUserMe,
      itemListFromCart,
      location,
      me,
      orderPostError,
      orderProperties,
      restaurant,
      updateUserError,
      width,
    } = this.props

    return (
      <OrderPage
        headerProps={{
          displayTitle: isWidthUp('md', width),
          displayUser: true,
        }}
        orderProperties={orderProperties}
        restaurant={restaurant}
        location={location}
      >
        <OrderPluginUrlCheckerContainer location={location}>
          {!isNil(me) ? (
            <View
              frontSettings={frontSettings}
              itemListFromCart={itemListFromCart}
              hasPhoneRequired={this.hasPhoneRequired}
              orderProperties={orderProperties}
              restaurant={restaurant}
              isFetching={isFetchingMe || isFetchingPutUserMe || isFetchingOrderPost}
              onSubmit={this.handleSubmit}
              user={me}
              defaultData={{
                email: me.email,
                firstName: me.firstName,
                lastName: me.lastName,
                phone: me.phone,
                marketing: {
                  allowContact: me.marketing.allowContact,
                },
              }}
              errors={orderPostError || updateUserError}
            />
          ) : (
            <BlockContainer
              className="u-relative u-padding40 u-marginTop20"
              type={BlockContainer.Type.WHITE}
            >
              <LoadingBlockWrapper show />
            </BlockContainer>
          )}
        </OrderPluginUrlCheckerContainer>
      </OrderPage>
    )
  }
}

const mapStateToProps = state => ({
  frontSettings: getAppConfigFrontSettings(state),
  haveAuthCredentials: haveAuthCredentials(state),
  isFetchingOrderPost: isFetchingOrderPost(state),
  isFetchingPutUserMe: isFetchingPutUserMe(state),
  itemListFromCart: getItemListFromCart(state),
  orderPostError: getErrorOrderPost(state),
  orderProperties: getOrderProperties(state),
  updateUserError: getErrorputUserMe(state),
})

export default compose(
  connect(
    mapStateToProps,
    {
      updateProfile,
      redirectTo,
    }
  ),
  withWidth()
)(UserProfilePage)
