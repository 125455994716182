import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'

import { getRoute, redirectTo } from 'pmt-modules/routing'
import { getUserSettings } from 'pmt-modules/appConfig/selectors'
import { FormType, updateFormOptions } from 'pmt-modules/form'
import {
  Email,
  FirstName,
  LastName,
  PhoneNumber,
  MarketingOptim,
} from 'pmt-modules/user/forms/elements/index'
import { fieldsType } from 'pmt-modules/registration/forms/register/constants'

import Grid from 'pmt-ui/Grid'
import { TypographyCustom } from 'pmt-ui/Typography'
import Button from 'pmt-ui/Button'
import withWidth, { isWidthDown } from 'pmt-ui/utils/withWidth'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    textAlign: 'left',
  },
  caption: {
    color: theme.pmt.status.inactive,
    marginTop: theme.spacing(1),
  },
  marketingOptim: {
    color: theme.palette.text.primary,
  },
  buttonRecoveryPassword: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    marginTop: theme.spacing(1.5),
    textTransform: 'initial',
  },
})

class ProfileFormView extends React.PureComponent {
  constructor(props) {
    super(props)

    this.props.updateFormOptions(FormType.USER_PROFILE, {
      fields: this.props.userSettings.registration.form.fields,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (!isNil(this.props.formData.email) && !isEqual(this.props.formData, nextProps.formData)) {
      this.props.handleOnChangeFormDatas(nextProps.formData)
    }
  }

  // here to check if the field has been asked during registration
  // we do not show the user the field because
  // we don't want him to fill it if it has not been asked during registration
  hasField = fieldType =>
    find(
      this.props.userSettings.registration.form.fields,
      ({ required, type }) => type === fieldType
    )

  handleOnClickRecoveryPassword = () => {
    if (!isNil(this.props.forgottenPasswordLink)) {
      window.location = this.props.forgottenPasswordLink
    } else {
      this.props.redirectTo(getRoute('FORGOT_PASSWORD'))
    }
  }

  render() {
    const { classes, formData, formErrors, width, onChange } = this.props

    return (
      <Grid spacing={isWidthDown('sm', width) ? 0 : 2} container className={classes.root}>
        {this.hasField(fieldsType.FIRST_NAME) && (
          <Grid item xs={12} md={6}>
            <div className={classes.lastName}>
              <LastName
                value={formData.lastName}
                onChange={lastName => {
                  onChange({ ...formData, lastName })
                }}
                validation={formErrors.lastName}
              />
            </div>
          </Grid>
        )}
        {/*
          we want to have a different order of fields relating to viewport width
        */}
        {!isWidthDown('sm', width) &&
          this.hasField(fieldsType.EMAIL) && (
            <Grid item xs={12} md={6}>
              <div className={classes.email}>
                <Email
                  value={formData.email}
                  onChange={email => {
                    onChange({ ...formData, email })
                  }}
                  validation={formErrors.email}
                />
              </div>
            </Grid>
          )}
        {this.hasField(fieldsType.LAST_NAME) && (
          <Grid item xs={12} md={6}>
            <div className={classes.firstName}>
              <FirstName
                value={formData.firstName}
                onChange={firstName => {
                  onChange({ ...formData, firstName })
                }}
                validation={formErrors.firstName}
              />
            </div>
          </Grid>
        )}
        {/*
          we want to have a different order of fields relating to viewport width
        */}
        {isWidthDown('sm', width) &&
          this.hasField(fieldsType.EMAIL) && (
            <Grid item xs={12} md={6}>
              <div className={classes.email}>
                <Email
                  value={formData.email}
                  onChange={email => {
                    onChange({ ...formData, email })
                  }}
                  validation={formErrors.email}
                />
              </div>
            </Grid>
          )}
        {this.hasField(fieldsType.PHONE) && (
          <Grid item xs={12} md={6}>
            <div className={classes.phone}>
              <PhoneNumber
                value={formData.phone}
                onChange={phone => {
                  onChange({ ...formData, phone })
                }}
                validation={formErrors.phone}
              />
              {/*
                Commented on a demand from Meuh. We'll be used later (maybe)
              <TypographyCustom type="124" className={classes.caption}>
                {tr('global.form.user.phone_info')}
              </TypographyCustom> */}
            </div>
          </Grid>
        )}
        {this.hasField(fieldsType.MARKETING_ALLOW_CONTACT) && (
          <Grid item xs={12} md={6} className="u-paddingTop0 u-paddingBottom0">
            <div className={classes.optinMarketing}>
              <MarketingOptim
                className={classes.marketingOptim}
                value={formData.marketing.allowContact}
                onChange={allowContact => {
                  onChange({ ...formData, marketing: { allowContact } })
                }}
                validation={formErrors.marketing.allowContact}
              />
            </div>
          </Grid>
        )}
        {this.hasField(fieldsType.PASSWORD) && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttonRecoveryPassword}
              onClick={this.handleOnClickRecoveryPassword}
            >
              <TypographyCustom type="144" skipColor>
                {tr('global.form.user.password_reset')}
              </TypographyCustom>
            </Button>
          </Grid>
        )}
      </Grid>
    )
  }
}

ProfileFormView.defaultProps = {
  handleOnChangeFormDatas: () => {},
}

ProfileFormView.propTypes = {
  handleOnChangeFormDatas: PropTypes.func,
}

const mapStateToProps = (state, props) => ({
  userSettings: getUserSettings(state),
})

export default compose(
  connect(
    mapStateToProps,
    {
      redirectTo,
      updateFormOptions,
    }
  ),
  withWidth(),
  withStyles(styles)
)(ProfileFormView)
