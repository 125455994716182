import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'

import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'
import isNil from 'lodash/isNil'
import forEach from 'lodash/forEach'
import get from 'lodash/get'

import ErrorBlock from 'pmt-ui/ErrorBlock'
import LoadingBlock from '../LoadingBlock'
import LoadingBlockWrapper from '../LoadingBlock/LoadingBlockWrapper'

class LoadingContainerView extends React.Component {
  constructor(props) {
    super(props)

    if (!this.props.dontUpdateOnMount) {
      this.props.onUpdate && this.props.onUpdate(this.props.data)
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.props.onUpdate && this.props.onUpdate(this.props.data)
    }

    return null
  }

  render() {
    const {
      children,
      data,
      errorData,
      manualRunView,
      size,
      shouldShow,
      shouldShowManualRunView,
      delegateErrorHandlingToChildrenResult,
      withWrapper,
    } = this.props

    if (errorData && !delegateErrorHandlingToChildrenResult) {
      if (process.env.FEATURE_BO) {
        const MessageBlock = require('app/components/MessageBlock').default
        return <MessageBlock.Error error={errorData} />
      }
      return <ErrorBlock error={errorData} />
    }

    if (manualRunView && shouldShowManualRunView(data)) {
      return withWrapper ? (
        <React.Fragment>
          <LoadingBlockWrapper show={shouldShow} size={size} />
          {manualRunView(data)}
        </React.Fragment>
      ) : (
        manualRunView(data)
      )
    }

    return withWrapper ? (
      <React.Fragment>
        <LoadingBlockWrapper show={shouldShow} size={size} />
        {children(data)}
      </React.Fragment>
    ) : (
      <LoadingBlock size={size} show={shouldShow}>
        {() => children(data)}
      </LoadingBlock>
    )
  }
}

/**
 * Shortcut for container that needs to display a LoadingBlock.
 *
 * Note: we could add prop to use a LoadingBlockWrapper instead of LoadingBlock.
 *
 * If a props 'error' is given, we automatically display the api error given by the props if it is a
 * function. It can be a string that gives the variable name to retrieve on the data as an error.
 *
 * Example
 *
 * ```
 * <LoadingContainer
 *   container={
 *     <MyEntityContainer entityId={1} />
 *   }
 *   error={({ myEntityError }) => myEntityError)}
 *   error="myEntityError"
 *   show={({ myEntity, isFetchingMyEntity }) =>
 *     isFetchingMyEntity || !myEntity
 *   }
 * >
 *   {({ myEntity }) => (
 *      // rest of the code
 *   )}
 * </LoadingContainer>
 * ```
 */
const LoadingContainer = ({
  container,
  size,
  show = false,
  withWrapper = false,
  error,
  manualRunView,
  shouldShowManualRunView,
  onUpdate,
  children,
  dontUpdateOnMount = false,
  delegateErrorHandlingToChildren,
}) => {
  return React.cloneElement(container, {}, data => {
    let errorData
    if (isFunction(error)) {
      errorData = error(data)
    } else if (isString(error)) {
      // variable name
      errorData = get(data, error)
    } else if (isArray(error)) {
      forEach(error, errorName => {
        errorData = get(data, errorName)
        if (errorData) {
          return false
        }
      })
    }

    const delegateErrorHandlingToChildrenResult =
      delegateErrorHandlingToChildren && errorData && delegateErrorHandlingToChildren(errorData)

    const shouldShow =
      show && (show(data) || !isNil(errorData)) && !delegateErrorHandlingToChildrenResult

    return (
      <LoadingContainerView
        children={children}
        data={data}
        errorData={errorData}
        manualRunView={manualRunView}
        onUpdate={onUpdate}
        size={size}
        shouldShow={shouldShow}
        shouldShowManualRunView={shouldShowManualRunView}
        withWrapper={withWrapper}
        dontUpdateOnMount={dontUpdateOnMount}
        delegateErrorHandlingToChildrenResult={delegateErrorHandlingToChildrenResult}
      />
    )
  })
}

LoadingContainer.propTypes = {
  show: PropTypes.func,
  onUpdate: PropTypes.func,
  shouldShowManualRunView: PropTypes.func,
  container: PropTypes.node.isRequired,
  children: PropTypes.func.isRequired,
}

export default LoadingContainer
