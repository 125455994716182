import Polyglot from 'node-polyglot'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'

// import manually the language files we need
// TODO: do this dynamically
import 'moment/locale/fr'
import 'moment/locale/de'

import { getQueryParam } from 'pmt-utils/url'
import { localeEquals } from 'pmt-utils/locale'

import { retrieveMessages } from './actions'
import { getLocaleFromStorage } from './storage'

// force locale to be used, can be: 'debug', fr, en, etc
export const QUERY_PARAM_LOCALE = 'l'

export const getDefaultBrowserLocale = () => {
  // Define user's language. Different browsers have the user locale defined
  // on different fields on the `navigator` object, so we make sure to account
  // for these different by checking all of them
  const language =
    (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage

  return language
}

//
//
//

const DEFAULT_OPTIONS = {
  defaultLocale: 'en',
  calendar: false,
}

let _locale = null

//
// - calendar -> tr flatpickr ?
//
let _options = DEFAULT_OPTIONS

// create an instance of the Polyglot class, which you will use for translation.
// Polyglot is class-based so you can maintain different sets of phrases at the same time, possibly
// in different locales. This is very useful for example when serving requests with Express,
// because each request may have a different locale, and you don’t want concurrent requests to
// clobber each other’s phrases.
let _polyglot = new Polyglot({ locale: getDefaultBrowserLocale() })

export const getPolyglot = () => _polyglot

export const setPolyglot = polyglot => {
  _polyglot = polyglot
}

//
//
//

const getLanguageWithoutRegionCode = language => {
  if (isEmpty(language)) {
    return 'en'
  }

  // Split locales with a region code
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0]

  return languageWithoutRegionCode
}

export const getCurrentLanguageWithoutRegionCode = () => {
  return getLanguageWithoutRegionCode(_locale)
}

export const isSupportedLocale = locale =>
  find(_options.locales, supportedLocale => localeEquals(supportedLocale, locale))

export const getDefaultLocale = () => {
  let locale = getDefaultBrowserLocale()

  const queryLocale = getQueryParam(QUERY_PARAM_LOCALE)
  if (!isEmpty(queryLocale) && isSupportedLocale(queryLocale)) {
    locale = queryLocale
  } else {
    // not in query param, search in storage
    const storageLocale = getLocaleFromStorage()
    if (!isEmpty(storageLocale)) {
      locale = storageLocale
    }
  }

  return locale
}

//
//
//

export const configureI18n = (options = {}) => {
  // set options
  _options = { ...DEFAULT_OPTIONS, ...options }

  if (_options.disable) {
    return
  }

  if (!global.Intl) {
    console.error('Required intl Polyfill')
  }

  let locale = getDefaultLocale()
  setLocale(locale)
}

export const getSupportedLocales = () => _options.locales

const localesLabels = {
  FR: 'Français',
  EN: 'English',
  DE: 'Deutsch',
  IT: 'Italiano',
  ES: 'Español',
}

export const getLocaleLabel = locale => {
  const label = localesLabels[locale.toUpperCase()] || locale
  return label
}

export const getSupportedLocalesOptions = () =>
  getSupportedLocales().map(locale => ({
    value: locale,
    label: getLocaleLabel(locale),
  }))

export const setLocale = locale => {
  _locale = locale

  let language = getLanguageWithoutRegionCode(locale)
  if (!isSupportedLocale(language)) {
    language = _options.defaultLocale
  }

  moment.locale(language)

  if (process.env.FEATURE_FLATPICKR) {
    const localizeFlatpickr = require('pmt-ui/Calendar/localizeFlatpickr').default
    localizeFlatpickr(language)
  }

  // retrieve messages
  retrieveMessages(language)
}

export const getLocale = () => _locale

//
//
//

const trWithLocale = (locale, key, options = {}) => {
  if (locale === 'debug') {
    return key
  }

  if (isEmpty(key)) {
    console.error(`[i18n] key is empty.`)
    return null
  }

  try {
    const message = getPolyglot().t(key, options)

    if (isEmpty(message)) {
      console.error(`[i18n][${key}] Missing translation for key for locale ${locale}`)
      return key
    }

    return message
  } catch (e) {
    console.error(e)
    // console.warn(`I18n: ${key} error when formatting for locale ${locale}`, options)
  }

  return locale
}

export const tr = (key, options = {}) => {
  return trWithLocale(_locale, key, options)
}
