import React from 'react'
import ReactDOM from 'react-dom'

import ApplicationView from './ApplicationView'

//
// get the rootElement
//
const Application = appOptions => {
  const rootElement = appOptions.rootElement || document.getElementById('root')

  // obj has to be a function to avoid references
  const polyfills = [
    {
      getObj: () => window.Promise,
      url: '//unpkg.com/es6-promise@3.2.1/dist/es6-promise.min.js',
    },
    {
      getObj: () => window.fetch,
      url: '//unpkg.com/whatwg-fetch@1.0.0/fetch.js',
    },
  ]

  const makeRenderBeforeNumberFormatPolyFill = Component => {
    ReactDOM.render(<Component appOptions={appOptions} />, rootElement)
  }

  const loadScript = (src, done) => {
    const js = document.createElement('script')
    js.src = src
    js.onload = () => done()
    js.onerror = () => console.error('cannot load script ' + src)
    document.head.appendChild(js)
  }

  
  
  const numberFormatPolyfillFeatures = ["Intl.NumberFormat", "Intl.PluralRules", "Intl.GetCanonicalLocales", "Intl.Locale" ]
  appOptions.i18n.locales.forEach(locale => {
    numberFormatPolyfillFeatures.push("Intl.NumberFormat.~locale."+locale)
    numberFormatPolyfillFeatures.push("Intl.PluralRules.~locale."+locale)
  })

  // needed because the value "narrowSymbol" for the option "currencyDisplay" of Intl.NumberFormat is not handled by Safari < 14.1
  const numberFormatAndDependenciesPolyfillUrl = 'https://polyfill.io/v3/polyfill.min.js?features='+numberFormatPolyfillFeatures.join(',')

  const makeRender = Component => loadScript(numberFormatAndDependenciesPolyfillUrl, () => makeRenderBeforeNumberFormatPolyFill(Component))

  const browserSupportsAllFeatures = () => polyfills.every(polyfill => polyfill.getObj())

  if (!browserSupportsAllFeatures()) {
    // load polyfills
    const render = () => browserSupportsAllFeatures() && makeRender(ApplicationView)

    polyfills.forEach(polyfill => {
      polyfill.getObj() || loadScript(polyfill.url, render)
    })
  } else {
    makeRender(ApplicationView)
  }
}

export default Application
