import Immutable from 'immutable'

import {
  SearchAction,
  GetSearchListAction,
} from './actions'

export SearchType from './SearchType'
export * from './actions'
export * from './selectors'
export * from './components'
export * from './config'

let DEFAULT_STATE = Immutable.fromJS({})
export const searchReducer = (state = DEFAULT_STATE, action) => {

  switch (action.type) {
    case SearchAction.CREATE:
      return state.mergeIn([action.searchType], {
        data: null,
        isFetching: false,
        error: null,
      })

    case SearchAction.UPDATE:
      return state.mergeIn([action.searchType], {
        data: action.data,
        isFetching: false,
        error: null,
      })

    case SearchAction.RESET:
      return state.mergeIn([action.searchType], {
        data: null,
        isFetching: false,
        error: null,
      })

    case GetSearchListAction.REQUEST:
      return state.mergeIn([action.data.searchType], {
        list: null,
        isFetching: true,
        lastUpdated: null,
        error: null,
      })

    case GetSearchListAction.SUCCESS:
      return state.mergeIn([action.data.searchType], {
        list: action.response.data,
        // TODO: replace by pagination
        from: action.response.from,
        size: action.response.size,
        totalHit: action.response.totalHit,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case GetSearchListAction.FAILURE:
      return state.mergeIn([action.data.searchType], {
        list: null,
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
      })

    default:
      return state
  }
}
