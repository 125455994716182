import { tr } from 'pmt-modules/i18n'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import invariant from 'invariant'

import isString from 'lodash/isString'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { withStyles } from 'pmt-ui/styles'

import { displayErrorDialog } from './actions'
import SnackbarError from './SnackbarError'

const styles = theme => {
  invariant(
    !isNil(theme.pmt.status) && !isNil(theme.pmt.status.error),
    `theme status error color is not defined`
  )

  return {
    errorBlock: {
      marginTop: 20,
      color: theme.pmt.status.error,
      textAlign: 'center',
    },
  }
}

const DisplayMode = {
  SNACKBAR: 'DISPLAY_MODE::SNACKBAR',
  DIALOG: 'DISPLAY_MODE::DIALOG',
  CUSTOM: 'DISPLAY_MODE::CUSTOM',
}

/**
 * error will be displayed with the color defined in theme: theme.pmt.status.error
 * be sure to set it properly in your config theme file
 */
class ErrorBlock extends React.Component {
  constructor(props) {
    super(props)

    this.setMessage(props)
  }

  componentWillReceiveProps(nextProps) {
    const newMessage = isEmpty(get(nextProps, 'error.localizedMessage'))
      ? get(nextProps, 'error.message', null)
      : tr(nextProps.error.localizedMessage)
    if (this.message !== newMessage) {
      this.setMessage(nextProps)
    }
  }

  setMessage(props) {
    if (!isNil(props.error)) {
      if (isString(props.error)) {
        this.message = props.error
      } else {
        this.message = isEmpty(get(props, 'error.localizedMessage'))
          ? get(props, 'error.message', null)
          : tr(props.error.localizedMessage)
      }
      if (props.mode === DisplayMode.DIALOG) {
        props.displayErrorDialog(this.message, props.options)
      }
      this.message = this.message || tr('global.unknown_error')
    }
  }

  render() {
    const { mode, error, classes, customElement, options } = this.props

    if (isNil(error)) {
      return null
    }

    switch (mode) {
      // we don't need to render anything here, since it will be done via the setMessage func
      case DisplayMode.DIALOG:
        return null

      case DisplayMode.SNACKBAR:
        return <SnackbarError errorMessage={this.message} {...options || {}} />

      case DisplayMode.CUSTOM:
        return React.cloneElement(customElement, {
          text: this.message,
        })

      default:
        return <div className={classes.errorBlock}>{this.message}</div>
    }
  }
}

ErrorBlock.defaultProps = {
  mode: DisplayMode.DEFAULT,
}

ErrorBlock.propTypes = {
  error: PropTypes.object,

  // way to display this error block
  mode: PropTypes.string,

  /**
   * options can contain,
   *
   * for dialog:
   *   - title: dialog title
   *   - actions: dialog actions
   *
   * For snackbar:
   *   - onClose
   */
  options: PropTypes.object,

  // cutomElement to be passed with mode DisplayMode.CUSTOM
  customElement: PropTypes.element,
}

const mapStateToProps = () => ({})

const ErrorBlockComponent = compose(
  connect(
    mapStateToProps,
    {
      displayErrorDialog,
    }
  ),
  withStyles(styles)
)(ErrorBlock)

ErrorBlockComponent.Mode = DisplayMode

export default ErrorBlockComponent
