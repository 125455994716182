import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { hideDietaryInfoDialog } from 'pmt-modules/catalog'
import { getAllergensOptions } from 'pmt-modules/user/constants'

import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import CloseIcon from '@material-ui/icons/Close'

import { TypographyCustom } from 'pmt-ui/Typography'

import DesktopView from './DesktopView'
import MobileView from './MobileView'

const styles = (theme) => ({
  dialogTitleContainer: {
    paddingBottom: 0,
  },
  dialogTitle: {
    float: 'left',
    width: 'calc(100% - 40px)',
    marginTop: 40,
    // color: theme.palette.primary.main,
  },
  closeArea: {
    float: 'right',
    cursor: 'pointer',
  },
  closeBtn: {
    background: theme.pmt.colors.grey100,
    cursor: 'pointer',
    width: 34,
    height: 34,
    padding: 7,
    borderRadius: 10,
    margin: 1,
  },
  dialogContent: {
    paddingBottom: 40,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  wrapper: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
})

/**
 * @specs N/A
 */
class DietaryInfoDialog extends React.PureComponent {
  constructor(props) {
    super(props)

    // set static var for better performance and clean allergens without icon
    this.allergens = getAllergensOptions().filter(
      (a) => props.item.formattedAllergens.indexOf(a.value) >= 0
    )
  }

  render() {
    const { classes, item, hideDietaryInfoDialog } = this.props

    return (
      <React.Fragment>
        <DialogTitle disableTypography className={classes.dialogTitleContainer}>
          <TypographyCustom type="226" skipColor className={classes.dialogTitle}>
            {tr('order.dietary_info.title')}
          </TypographyCustom>
          <div className={classes.closeArea} onClick={hideDietaryInfoDialog}>
            <CloseIcon className={classes.closeBtn} />
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.wrapper}>
            <Hidden xsDown>
              <DesktopView allergens={this.allergens} item={item} />
            </Hidden>
            <Hidden smUp>
              <MobileView allergens={this.allergens} item={item} />
            </Hidden>
          </div>
        </DialogContent>
      </React.Fragment>
    )
  }
}

DietaryInfoDialog.DialogStyles = (theme) => ({
  paper: {
    // fix weird bug on Safari
    zIndex: 100,
    // -- for IE
    '-ms-overflow-style': '-ms-autohiding-scrollbar',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      margin: 0,
      borderRadius: 0,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 1100,
      // -- for IE
      width: '100%',
      borderRadius: 28,
    },
  },
})

const mapStateToProps = () => ({})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    hideDietaryInfoDialog,
  })
)(DietaryInfoDialog)
