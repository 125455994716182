const RestaurantErrors = {
  GET: {
    107: 'global.get_restaurant.error.107',
    108: 'global.get_restaurant.error.108',
    109: 'global.get_restaurant.error.109',
    1004: 'global.get_restaurant.error.1004',
    1005: 'global.get_restaurant.error.1005',
    1006: 'global.get_restaurant.error.1006',

    closed: 'global.get_restaurant.error.closed',
  },
  POST: {
    default: 'global.post_restaurant.default',
    310: 'global.post_restaurant.error.310',
  },
  GET_LIST: {
    100: 'global.get_restaurant_list.error.100',
  },
}

export default RestaurantErrors
