import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { getOrderProperties } from 'pmt-modules/orderPlugin'
import { getCartData, getItemListFromCart } from 'pmt-modules/cart'

import LoadingBlock from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import BlockContainer from 'app/components/BlockContainer'
import Breadcrumb from 'app/components/Breadcrumb'
import { CartItemList, CartFooter } from 'app/components/Cart'

const styles = (theme) => ({
  height100: {
    height: '100%',
    background: theme.pmt.colors.white,
  },
  white: {
    color: theme.pmt.colors.white,
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  modifyButton: {
    textTransform: 'initial',
    color: theme.pmt.colors.white,
  },
  globalContainer: {
    borderRadius: theme.shape.border.radius.main,
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },
  itemListContainer: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },
  containerCartFooter: {
    paddingTop: 0,
  },
})

const breadcrumbElements = [
  {
    name: 'CATALOG',
    active: true,
    hiddenMobile: true,
  },
  {
    name: 'CART',
    active: true,
    current: true,
  },
  {
    name: 'INFORMATIONS',
  },
  {
    name: 'DELIVERY_ADDRESS',
  },
  {
    name: 'PAYMENT',
  },
]

const Cart = ({
  classes,
  errorPostOrder,
  itemListFromCart,
  cartData,
  orderProperties,
  restaurant,
  route,
  handleCartSubmission,
  shouldDisplayBreadCrumb,
  shouldDisplayComment,
  shouldEnableSubmitCart,
  orderAppConfig,
  appConfigPreset,
}) => (
  <div className={classNames('u-relative', classes.height100, classes.globalContainer)}>
    {/* ------------------------------------------------------------ */}
    {/* cart title and item list (BIG version)                       */}
    {/* ------------------------------------------------------------ */}

    <BlockContainer className={classes.itemListContainer}>
      {!isNil(restaurant) ? (
        <React.Fragment>
          {shouldDisplayBreadCrumb && (
            <Breadcrumb className="u-marginBottom20" elements={breadcrumbElements} />
          )}
          <TypographyCustom type="226" component="h3" className="u-marginBottom20">
            {/*
              display a different label for digital menu
              https://www.notion.so/paymytable/Finalisation-du-Menu-Digital-2228f83526ba4e8897619991c1f9c393
            */}
            {appConfigPreset.isDigitalMenu
              ? tr('order.cart.title_digital_menu')
              : tr('order.cart.title')}
          </TypographyCustom>

          {!isNil(itemListFromCart) ? (
            <CartItemList
              orderProperties={orderProperties}
              itemList={itemListFromCart}
              type={CartItemList.Type.BIG}
              fromRoute={route}
              appConfigPreset={appConfigPreset}
            />
          ) : (
            <LoadingBlock
              show
              classes={{
                loadingBlock: classNames('u-marginTop20', classes.loadingBlock),
                circularProgress: classes.progress,
              }}
            />
          )}
        </React.Fragment>
      ) : (
        <LoadingBlock
          show
          classes={{
            loadingBlock: classNames('u-marginTop20', classes.loadingBlock),
            circularProgress: classes.progress,
          }}
        />
      )}
    </BlockContainer>

    {/* ------------------------------------------------------------ */}
    {/* CART FOOTER (BIG version)                                    */}
    {/* ------------------------------------------------------------ */}

    <BlockContainer type={BlockContainer.Type.WHITE} className={classes.containerCartFooter}>
      {!isNil(itemListFromCart) ? (
        <CartFooter
          errorPostOrder={errorPostOrder}
          orderProperties={orderProperties}
          itemList={itemListFromCart}
          comment={cartData.comment}
          fees={orderProperties.fees}
          minimumPrice={cartData.minimumPrice}
          minimumPriceFormatted={cartData.minimumPriceFormatted}
          restaurant={restaurant}
          totalCartPrice={cartData.totalCartPrice}
          totalCartPriceFormatted={cartData.totalCartPriceFormatted}
          totalCartPriceAndFeesFormatted={orderProperties.totalCartPriceAndFeesFormatted}
          type={CartFooter.Type.BIG}
          onSubmitAction={handleCartSubmission}
          enabledSubmitCart={shouldEnableSubmitCart}
          shouldDisplayComment={shouldDisplayComment}
          orderAppConfig={orderAppConfig}
        />
      ) : (
        <LoadingBlock
          show
          classes={{
            loadingBlock: classNames('u-marginTop20', classes.loadingBlock),
            circularProgress: classes.progress,
          }}
        />
      )}
    </BlockContainer>
  </div>
)

const mapStateToProps = (state, props) => ({
  itemListFromCart: getItemListFromCart(state),
  cartData: getCartData(state),
  orderProperties: getOrderProperties(state),
})

export default compose(withStyles(styles), connect(mapStateToProps, {}))(Cart)
