// https://davidwalsh.name/javascript-debounce-function
// this function has been created because the debounce function
// from lodash doesn't seem to work like we want to

export const debounce = (func, wait, immediate) => {
  let timeout = null 
  return (...args) => {
    const context = this

    const later = () => {
      timeout = null
      if (!immediate) {
        func.apply(context, args)
      }
    };
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) {
      func.apply(context, args)
    }
  }
}

export default debounce
