import { createFormatter, createListFormatter } from 'pmt-utils/format'

import { formatPriceWithCurrency } from 'pmt-utils/currency'

const formatValueAdditionalPrice = value => {
  value.additionalPriceFormatted = formatPriceWithCurrency(value.additionalPrice)
  value.isOutOfStock = value.stock === 0
  return value
}

const formatOptionValue = createFormatter(formatValueAdditionalPrice)

export const formatOptionValues = createListFormatter(formatOptionValue)
