import isNil from 'lodash/isNil'
import get from 'lodash/get'

const getElement = (address_components, type, propertyToReturn) => {
  const firstOccurence = address_components.filter(c => c.types.includes(type)).shift()
  return get(firstOccurence, propertyToReturn, null) 
}

export const getAddressElementsFromGooglePlace = place => {

  if (isNil(place)){
    return null
  }

  const address_components = place.address_components

  // street_address indicates a precise street address.
  const preciseStreetAddress = getElement(address_components, "street_address", "short_name")
  const streetNumber = getElement(address_components, "street_number", "short_name")
  const route = getElement(address_components, "route", "short_name")

  // streetNumber/route concatenation order depends of the country
  // ex: 7 rue de Paris / Via Milano 7 / Berlin-Strasse 7
  // we could extract it from formattedAddress, as being the first part before the first comma
  // but it can be problematic as the formatted address can be of several forms:
  // 7 rue de Paris, 75009 Paris, France
  // 7 rue de Paris, 75009, Paris, France
  // 7, rue de Paris, 75009 Paris, France
  // Via Toledo, 123, 80135 Napoli NA, Italy
  // so for now, if the street_address is not provided by Google, we use the street_number+route concatenation
  // with an order depending of the country
  const countryCode = getElement(address_components, "country", "short_name")
  const streetConcatenation = countryCode === "FR" || countryCode === "RE" ? streetNumber + " "+ route : route + " "+ streetNumber

  const street =  preciseStreetAddress || streetConcatenation
  const postCode = getElement(address_components, "postal_code", "short_name")
  const city = getElement(address_components, "locality", "short_name") || getElement(address_components, "postal_town", "short_name")
  const country = getElement(address_components, "country", "long_name")
  return {
    street,
    postCode,
    city,
    country,
    formattedAddress: place.formatted_address
  }
}

// deprecated, do not handle well all addresses (ex: french addresses with a comma between street number and street name, or italian addresses with a comma between street name and street number)
export const getAddressElementsFromFormattedAddress = formatted_address => {
  const mainComponents = formatted_address.split(', ')

  if (mainComponents.length !== 3 && mainComponents.length !== 4) {
    return null
  }

  if (mainComponents.length === 3) {
    const street = mainComponents[0]
    const postCode = mainComponents[1].substr(0, mainComponents[1].indexOf(' '))
    const city = mainComponents[1].split(/ (.+)/)[1]
    const country = mainComponents[2]

    return {
      street,
      postCode,
      city,
      country,
      formattedAddress: formatted_address,
    }
  } else {
    const street = mainComponents[0]
    const city = mainComponents[1]
    const postCode = mainComponents[2]
    const country = mainComponents[3]

    return {
      street,
      postCode,
      city,
      country,
      formattedAddress: formatted_address,
    }
  }
}

export const getGoogleMapsUrl = (position, zoom = 14) =>
  `http://maps.google.com/maps?z=${zoom}&t=m&q=loc:${position.latitude}+${position.longitude}`
