import { tr } from 'pmt-modules/i18n'
/**
 * Define all the different ways to pay a check
 */
export const PaymentType = {
  /**
   * Pay all the check
   */
  ALL: 1,

  /**
   * Pay a part of the check
   * == DEVIDE
   */
  SHARE: 2,

  /**
   * Pay a specific amount of the check
   */
  AMOUNT: 3,

  /**
   * Pay for the attached entries
   */
  ENTRIES: 4,

  /**
   * distant payment request
   */
  DISTANT_PAYMENT: 5,

  ORDER: 6,

  /**
   * Irl payment via TPE.
   * Only a Restaurant can use this PaymentType.
   */
  IRL_CREDIT_CARD: 8,
}

export const getPaymentTypeLabel = paymentType => {
  switch (paymentType) {
    case PaymentType.ALL:
      return tr('global.PaymentType.ALL')
    case PaymentType.SHARE:
      return tr('global.PaymentType.SHARE')
    case PaymentType.AMOUNT:
      return tr('global.PaymentType.AMOUNT')
    case PaymentType.ENTRIES:
      return tr('global.PaymentType.ENTRIES')
    case PaymentType.DISTANT_PAYMENT:
      return tr('global.PaymentType.DISTANT_PAYMENT')
    case PaymentType.ORDER:
      return tr('global.PaymentType.ORDER')
    case PaymentType.IRL_CREDIT_CARD:
      return tr('global.PaymentType.IRL_CREDIT_CARD')
    default:
      return paymentType
  }
}

export default PaymentType
