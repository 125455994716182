import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchUserOrdersList } from '../../actions'

import {
  getUserOrdersList,
  isFetchingUserOrdersList,
  makeGetUserOrdersListPagination,
} from '../../selectors'

/**
 * @specs N/A
 *
 * A HOC that give fetch the check and pass it to the children
 *
 * Requirements:
 * - userId
 *
 * see `withCheck`
 */
class UserOrderListContainer extends React.Component {
  componentWillMount() {
    this.loadUserOrdersList(this.props)
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (nextProps.userId !== props.userId) {
      this.loadUserOrdersList(nextProps)
    }
  }

  loadUserOrdersList(props) {
    const { fetchUserOrdersList, rGroupId, userId } = props

    fetchUserOrdersList(rGroupId, userId)
  }

  loadMore = () => {
    if (!this.props.isFetchingUserOrdersList) {
      this.props.fetchUserOrdersList(
        this.props.rGroupId,
        this.props.userId,
        this.props.userOrdersListPagination.cursors.after
      )
    }
  }

  render() {
    const {
      children,
      userId,
      userOrdersList,
      // paging,
      isFetchingUserOrdersList,
      userOrdersListPagination,
      rGroupId,
    } = this.props

    return children({
      userId,
      rGroupId,
      userOrdersList,
      isFetchingUserOrdersList,
      userOrdersListPagination,
      onLoadMore: this.loadMore,
    })
  }
}

UserOrderListContainer.defaultProps = {}

UserOrderListContainer.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const mapStateToProps = (state, props) => {
  const getUserOrdersListPagination = makeGetUserOrdersListPagination()

  return {
    userOrdersList: getUserOrdersList(state),
    isFetchingUserOrdersList: isFetchingUserOrdersList(state),
    userOrdersListPagination: getUserOrdersListPagination(state),
  }
}

export default connect(
  mapStateToProps,
  {
    fetchUserOrdersList,
  }
)(UserOrderListContainer)
