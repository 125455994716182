export const stopIterationValues = ['fr', 'en']

export const checkAccessSecurityModes = {
  TABLE_NUMBER: 'TABLE_NUMBER',
  POS_CHECK_ID: 'POS_CHECK_ID',
}

// This, as the whole module, is a duplicate of ApiConsumer module because of legacy
export const AppConfigPresets = {
  PLUGIN_ORDER: 'PLUGIN_ORDER',
  DIGITAL_MENU: 'DIGITAL_MENU',
}

export const defaultTexts = {
  ORDER_PLUGIN: {
    labelForOrderMode: {
      '0': {
        fr: '',
        en: '',
      },
      '1': {
        fr: '',
        en: '',
      },
      '2': {
        fr: '',
        en: '',
      },
    },
    titleForOrderMode: {
      '0': {
        fr: '',
        en: '',
      },
      '1': {
        fr: '',
        en: '',
      },
      '2': {
        fr: '',
        en: '',
      },
    },
    totalCartlabelForOrderMode: {
      '0': {
        fr: '',
        en: '',
      },
      '1': {
        fr: '',
        en: '',
      },
      '2': {
        fr: '',
        en: '',
      },
    },
  },
}
