import { saveToStorage, getFromStorage } from 'pmt-modules/storage'

const StorageItem = {
  LOCALE: 'LOCALE',
}

export const saveLocaleToStorage = language => {
  saveToStorage(StorageItem.LOCALE, language)
}

export const getLocaleFromStorage = () => getFromStorage(StorageItem.LOCALE, null)
