import Immutable from 'immutable'

import { createSimpleReducer } from 'pmt-modules/redux'

import { GetUserMeAction, PutUserMeAction, UserMeActions } from './actions'

export * from './actions'
export * from './components'
export * from './selectors'

const DEFAULT = Immutable.fromJS({
  userMe: null,
  isFetching: false,
  lastUpdated: null,
  error: null,
})

export const meReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case GetUserMeAction.REQUEST:
      return state.merge({
        userMe: null,
        isFetching: true,
        lastUpdated: null,
        error: null,
      })

    case PutUserMeAction.SUCCESS:
    case GetUserMeAction.SUCCESS:
      return state.merge({
        userMe: action.response,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case UserMeActions.SET_USER:
      return state.merge({
        userMe: action.user,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case GetUserMeAction.FAILURE:
      return state.merge({
        userMe: null,
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
      })

    default:
      return state
  }
}

export const putUserMeReducer = createSimpleReducer(PutUserMeAction)
