const Allergen = {
  CELERY: 'CELERY',

  CHEESE: 'CHEESE',

  CRUSTACEAN: 'CRUSTACEAN',

  EGG: 'EGG',

  FISH: 'FISH',

  GLUTEN: 'GLUTEN',

  LACTOSE: 'LACTOSE',

  LUPINE: 'LUPINE',

  MUSTARD: 'MUSTARD',

  NUTS: 'NUTS',

  PEANUT: 'PEANUT',

  SESAME: 'SESAME',

  SHELLFISH: 'SHELLFISH',

  SOYA: 'SOYA',

  SULPHITES: 'SULPHITES',
}

export default Allergen
