//
// This module store global configuration and data for every front doing order,
// that don't requires their own module / reducer
//
import Immutable from 'immutable'

import { getDefaultLocale } from 'pmt-modules/i18n'
import { GetRestaurantAction } from 'pmt-modules/restaurant'

import { generateIdempotencyKey } from 'pmt-utils/idempotency'
import { CartActionsToResetIdEmpotency } from 'pmt-modules/cart'

import { OrderFrontAction } from './actions'
import { DueDateMode } from './constants'

export * from './actions'
export * from './format'
export * from './middlewares'
export * from './selectors'

// DEFAULT is function here because we need to call
// idempotencyKey utils function to generate it again
const DEFAULT = () => ({
  lang: getDefaultLocale(),
  idempotencyKey: generateIdempotencyKey(),
  mode: null,
  restaurantId: null,
  paymentMethod: null,
  hasAlreadyDisplayedUpselling: false,
  dueDate: null,
  dueDateMode: DueDateMode.ASAP, // default dueDate is always asap in our fronts
  postedOrderData: null,
  pagerId: null,
  modifierCode: null,
})

/**
 * We must handle the cart actions, to reset the id empotency if
 */
function handleCartAction(state, action) {
  if (CartActionsToResetIdEmpotency.includes(action.type)) {
    return state.merge({
      idempotencyKey: generateIdempotencyKey(),
    })
  }

  return state
}

export const orderFrontReducer = (state = Immutable.fromJS(DEFAULT()), action) => {
  switch (action.type) {
    case GetRestaurantAction.SUCCESS:
      return state.merge({
        restaurantId: action.response.id,
      })

    case OrderFrontAction.SELECT_MODE:
      return state.merge({
        mode: action.mode,
      })

    case OrderFrontAction.SET_RESTAURANT_ID:
      return state.merge({
        restaurantId: action.restaurantId,
      })

    case OrderFrontAction.SET_IDEMPOTENCY_KEY:
      return state.merge({
        idempotencyKey: action.idempotencyKey,
      })

    case OrderFrontAction.SET_DUE_DATE:
      return state.merge({
        dueDate: action.dueDate,
        dueDateMode: action.dueDate ? DueDateMode.SLOT : DueDateMode.ASAP,
      })

    case OrderFrontAction.SET_PAYMENT_METHOD:
      return state.merge({
        paymentMethod: action.paymentMethod,
      })

    case OrderFrontAction.RESET:
      return state.merge(
        Immutable.fromJS({
          ...DEFAULT(),
          dueDate: action.dueDate || null,
          dueDateMode: action.dueDate ? DueDateMode.SLOT : DueDateMode.ASAP,
          mode: state.get('mode'),
          postedOrderData: state.get('postedOrderData'),
          restaurantId: state.get('restaurantId'),
        })
      )

    case OrderFrontAction.RESET_FULL:
      return state.merge(
        Immutable.fromJS({
          ...DEFAULT(),
          mode: state.get('mode'),
        })
      )

    case OrderFrontAction.DISPLAY_UPSELLING:
      return state.merge({
        hasAlreadyDisplayedUpselling: true,
      })

    case OrderFrontAction.SET_LOCALE:
      return state.merge({
        locale: action.locale,
      })

    case OrderFrontAction.SAVE_POSTED_ORDER_DATA:
      return state.merge({
        postedOrderData: action.data,
      })

    case OrderFrontAction.SET_PAGER_ID:
      return state.merge({
        pagerId: action.pagerId,
      })

    case OrderFrontAction.ADD_MODIFIER_CODE:
      return state.merge({
        modifierCode: action.code,
      })

    default:
      return handleCartAction(state, action)
  }
}
