import isArray from 'lodash/isArray'

// this function only works for one level object
export const filterObject = (object, valueToExclude) => {
  let filteredObject = {}

  for (const key in object) {
    if (object[key] !== valueToExclude) {
      filteredObject[key] = object[key]
    }
  }

  return filteredObject
}

export const filterByKey = (object, keyToExcludeParam) => {
  const result = {}

  const keysToExclude = isArray(keyToExcludeParam) ? keyToExcludeParam : [keyToExcludeParam]

  for (const key in object) {
    if (object.hasOwnProperty(key) && keysToExclude.indexOf(key) === -1) {
      result[key] = object[key]
    }
  }

  return result
}
