import createAction from '../redux/createAction'
import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

import OrderApi from 'pmt-modules/api/calls/OrderApi'

export const PostOrderAction = {
  ...createApiEnumAction('ORDER_POST::POST'),
  RESET: 'ORDER_POST::POST::RESET',
}

/**
 *
 * @param {object} orderData see selectors#getOrderData
 *  - restaurantId
 *  - userId
 *  - cart: reconciliated cart
 *  -
 */
export const postOrder = (orderData, options = {}) => {
  return createApiCallAction(PostOrderAction, OrderApi.post(orderData, options), {
    orderData,
    options,
  })
}

export const resetPostOrder = () => createAction(PostOrderAction.RESET)
