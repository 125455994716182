import React from 'react'

import Typography from 'pmt-ui/Typography'

/**
 * Content header view.
 * Display the title of the select view.
 */
const Header = ({ title }) =>
  !title ? null : (
    <Typography variant="body2" component="header">
      {title}
    </Typography>
  )

export default Header
