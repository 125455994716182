import PaymentApi from 'pmt-modules/api/calls/PaymentApi'
import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import { createAction } from 'pmt-modules/redux'

export const FetchPaymentPdfLinkAction = createApiEnumAction('PAYMENT::FETCH::PDF_LINK')

export const fetchPaymentPdfLink = paymentId =>
  createApiCallAction(FetchPaymentPdfLinkAction, PaymentApi.getPaymentPdfLink(paymentId), {
    paymentId,
  })

export const ResetPaymentPdfLinkAction = 'PAYMENT::RESET::PDF_LINK'

export const resetPaymentPdfLink = () => createAction(ResetPaymentPdfLinkAction)
