import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let TakeAwayOrder = props => (
  <SvgIcon {...props} width="58.654" height="58.69" viewBox="0 0 58.654 58.69">
    <path
      d="M79.415,45c-.476-1.233-1.171-9.6-3.009-13.49a1.763,1.763,0,0,0-2.783-.207A3.163,3.163,0,0,1,71.2,32.483c-1.1,0-1.648-.9-2.146-1.446a2.954,2.954,0,0,0-2.329-1.264,3.214,3.214,0,0,0-2.458,1.257,3.935,3.935,0,0,1-3.105,1.537,3.119,3.119,0,0,1-2.6-1.2,3.481,3.481,0,0,0-2.947-1.593c-1.391,0-2,.846-2.782,1.607a3.6,3.6,0,0,1-2.754,1.187,3.4,3.4,0,0,1-2.747-1.285,3.238,3.238,0,0,0-2.589-1.509c-1.281,0-1.8.762-2.438,1.488a3.261,3.261,0,0,1-2.727,1.308v5.393h8.49a1.772,1.772,0,0,1,.523-1.152,1.75,1.75,0,0,1,1.267-.538H60.127a1.8,1.8,0,0,1,1.79,1.69H72.243l-4.674,21.7V86.4H81.755a2.4,2.4,0,0,0,2.386-2.2C84.915,75.095,83.818,56.4,79.415,45Z"
      transform="translate(-26.749 -28.739)"
    />
    <path
      d="M79.956,44.748c-.114-.293-.328-1.721-.52-2.987-.5-3.348-1.268-8.412-2.577-11.137A2.536,2.536,0,0,0,75,29.205a2.733,2.733,0,0,0-2.369.746,2.928,2.928,0,0,1-1.854,1.033c-.729,0-.947-.275-1.416-.945a3.365,3.365,0,0,0-2.955-1.768,4.407,4.407,0,0,0-3.271,1.6,3.029,3.029,0,0,1-2.391,1.2,2.142,2.142,0,0,1-1.936-1.033A4.151,4.151,0,0,0,55.3,28.278,4.3,4.3,0,0,0,51.77,30a2.272,2.272,0,0,1-2,1.033A2.553,2.553,0,0,1,47.607,29.9a3.983,3.983,0,0,0-3.177-1.626A3.777,3.777,0,0,0,41.386,29.9a2.4,2.4,0,0,1-2.122,1.133,1.033,1.033,0,0,0,0,2.066,4.412,4.412,0,0,0,3.678-1.846c.551-.631.821-.911,1.485-.911.746,0,1.046.293,1.653.956a4.549,4.549,0,0,0,3.685,1.8A4.3,4.3,0,0,0,53.3,31.374a2.272,2.272,0,0,1,2-1.033,2.142,2.142,0,0,1,1.935,1.033,4.151,4.151,0,0,0,3.506,1.76,5.073,5.073,0,0,0,3.842-1.786c.677-.663,1.066-1.009,1.829-1.009.614,0,.8.227,1.26.883a3.507,3.507,0,0,0,3.108,1.83,4.8,4.8,0,0,0,3.294-1.619.655.655,0,0,1,.567-.193.472.472,0,0,1,.357.279c1.175,2.446,1.91,7.322,2.4,10.549a22,22,0,0,0,.639,3.433c4.253,10.852,5.44,29.137,4.659,38.166a1.364,1.364,0,0,1-1.357,1.23H68.706a1.033,1.033,0,1,0,0,2.066H81.335a3.443,3.443,0,0,0,3.415-3.118C85.514,74.964,84.523,56.4,79.956,44.748Z"
      transform="translate(-26.329 -28.271)"
      fill="#190800"
    />
    <path
      d="M67.411,32.737c-.186-1.4-1.137-2.033-2.245-1.717a3.025,3.025,0,0,0-1.653,1.217,2.877,2.877,0,0,1-2.4,1.628c-1.48,0-2.024-.881-2.754-1.673A3.359,3.359,0,0,0,55.6,30.961a4.016,4.016,0,0,0-2.947,1.563,4.136,4.136,0,0,1-3.085,1.343,2.923,2.923,0,0,1-2.644-1.329,3.118,3.118,0,0,0-2.844-1.577,3.391,3.391,0,0,0-2.658,1.48,3.475,3.475,0,0,1-2.754,1.425A2.651,2.651,0,0,1,36.4,32.421,3.152,3.152,0,0,0,34.3,30.981a1.905,1.905,0,0,0-2.031,1.343A78.451,78.451,0,0,0,30.9,41.792a12.407,12.407,0,0,1-1.336,4.317c-7.6,14.722-7.3,32-7,38.367a2.41,2.41,0,0,0,2.4,2.293H59.417a2.4,2.4,0,0,0,2.389-2.541c-.42-6.837-1.047-25.966,3.973-38.126a13.253,13.253,0,0,0,.957-3.663C67.019,39.7,67.707,35.01,67.411,32.737ZM54.8,40.422a1.79,1.79,0,0,1-1.274.53H43.952a1.8,1.8,0,0,1-1.8-1.8.834.834,0,0,1,.007-.131,1.737,1.737,0,0,1,.523-1.136,1.763,1.763,0,0,1,1.267-.53H53.53a1.79,1.79,0,0,1,1.79,1.666.839.839,0,0,1,.007.131,1.785,1.785,0,0,1-.523,1.267Z"
      transform="translate(-21.412 -29.103)"
      fill="#fff"
    />
    <path
      d="M67.411,32.737c-.186-1.4-1.137-2.033-2.245-1.717a3.025,3.025,0,0,0-1.653,1.217,2.877,2.877,0,0,1-2.4,1.628c-1.48,0-2.024-.881-2.754-1.673A3.359,3.359,0,0,0,55.6,30.961a4.016,4.016,0,0,0-2.947,1.563,4.136,4.136,0,0,1-3.085,1.343,2.923,2.923,0,0,1-2.644-1.329,3.118,3.118,0,0,0-2.844-1.577,3.391,3.391,0,0,0-2.658,1.48,3.475,3.475,0,0,1-2.754,1.425A2.651,2.651,0,0,1,36.4,32.421,3.152,3.152,0,0,0,34.3,30.981a1.905,1.905,0,0,0-2.031,1.343A78.451,78.451,0,0,0,30.9,41.792a12.407,12.407,0,0,1-1.336,4.317c-7.6,14.722-7.3,32-7,38.367a2.41,2.41,0,0,0,2.4,2.293H59.417a2.4,2.4,0,0,0,2.389-2.541c-.42-6.837-1.047-25.966,3.973-38.126a13.253,13.253,0,0,0,.957-3.663C67.019,39.7,67.707,35.01,67.411,32.737ZM54.8,40.422a1.79,1.79,0,0,1-1.274.53H43.952a1.8,1.8,0,0,1-1.8-1.8.834.834,0,0,1,.007-.131,1.737,1.737,0,0,1,.523-1.136,1.763,1.763,0,0,1,1.267-.53H53.53a1.79,1.79,0,0,1,1.79,1.666.839.839,0,0,1,.007.131,1.785,1.785,0,0,1-.523,1.267Z"
      transform="translate(-21.412 -29.102)"
      opacity="0.05"
    />
    <path
      d="M67.5,50.905H37.181a.9.9,0,1,1,0-1.8H67.5a.9.9,0,1,1,0,1.8Z"
      transform="translate(-25.722 -34.761)"
      fill="#190800"
    />
    <path
      d="M61.525,87.329H24.495a3.43,3.43,0,0,1-3.433-3.272c-.492-10.5.344-25.773,7.121-38.89A11.512,11.512,0,0,0,29.408,41.2c.056-.482.114-1,.174-1.537a60.157,60.157,0,0,1,1.239-8.091,2.92,2.92,0,0,1,3.195-2.079,4.246,4.246,0,0,1,2.823,1.933c.527.7.742.937,1.373.937.983,0,1.41-.462,2.055-1.161a4.275,4.275,0,0,1,3.353-1.746,4.017,4.017,0,0,1,3.559,1.851,2.007,2.007,0,0,0,1.931,1.056,2.9,2.9,0,0,0,2.36-1.171,4.708,4.708,0,0,1,3.672-1.735,4.586,4.586,0,0,1,3.817,1.905c.606.695.908,1,1.69,1,.518,0,.8-.275,1.406-1.013a4.766,4.766,0,0,1,2.365-1.79,2.734,2.734,0,0,1,2.366.43,2.922,2.922,0,0,1,1.194,2.24,95.648,95.648,0,0,1-.68,9.847,13.959,13.959,0,0,1-1.012,3.89c-5.4,13-4.162,34.561-3.811,39.3h1.681a1.033,1.033,0,1,1,0,2.066ZM33.584,31.524a.9.9,0,0,0-.782.611,60.4,60.4,0,0,0-1.171,7.757c-.06.541-.118,1.062-.174,1.547a13.612,13.612,0,0,1-1.443,4.675c-6.568,12.711-7.374,27.6-6.894,37.846a1.368,1.368,0,0,0,1.37,1.3H60.4c-.4-5.2-1.606-26.632,3.977-40.088a11.868,11.868,0,0,0,.86-3.313,94.729,94.729,0,0,0,.671-9.559.842.842,0,0,0-.344-.644.674.674,0,0,0-.587-.114,2.973,2.973,0,0,0-1.34,1.124,3.766,3.766,0,0,1-3,1.76A3.968,3.968,0,0,1,57.4,32.721a2.588,2.588,0,0,0-2.261-1.2,2.713,2.713,0,0,0-2.2,1.124,4.872,4.872,0,0,1-3.826,1.783,4.018,4.018,0,0,1-3.56-1.851,2.006,2.006,0,0,0-1.928-1.056c-.8,0-1.179.371-1.836,1.082a4.558,4.558,0,0,1-3.572,1.825,3.544,3.544,0,0,1-3.02-1.756c-.492-.649-.806-1.023-1.515-1.142A.476.476,0,0,0,33.584,31.524Z"
      transform="translate(-20.945 -28.639)"
      fill="#190800"
    />
    <path
      d="M61.979,44.413H52.4a2.83,2.83,0,1,1,0-5.66h9.582a2.83,2.83,0,1,1,0,5.66ZM52.4,40.82a.764.764,0,1,0,0,1.528h9.582a.764.764,0,0,0,0-1.528Z"
      transform="translate(-29.859 -31.536)"
      fill="#190800"
    />
    <path
      d="M53.151,78.884a2.368,2.368,0,0,0-3.343,0l-7.291,7.291a2.364,2.364,0,1,0,3.344,3.343l7.291-7.291A2.368,2.368,0,0,0,53.151,78.884Z"
      transform="translate(-30.107 -44.05)"
    />
    <path
      d="M56.416,75.153a1.4,1.4,0,0,0-1.98,0L53.1,76.488a4.191,4.191,0,0,0-4.747.847l-7.291,7.291a4.231,4.231,0,1,0,5.983,5.984l7.292-7.292a4.223,4.223,0,0,0,.771-4.881l1.307-1.307A1.4,1.4,0,0,0,56.416,75.153Zm-4.058,6.185-7.291,7.291A1.431,1.431,0,0,1,43.043,86.6l7.291-7.291a1.431,1.431,0,0,1,2.024,2.024Z"
      transform="translate(-29.976 -43.822)"
      fill="#190800"
    />
    <path
      d="M44.426,77.485a6.741,6.741,0,0,1-4.814-2.113,7.679,7.679,0,0,1-1.871-3.318,4.99,4.99,0,0,1,6.088-6.089,7.687,7.687,0,0,1,3.319,1.867,6.6,6.6,0,0,1,1.883,6.555,3.989,3.989,0,0,1-2.866,2.864A6.78,6.78,0,0,1,44.426,77.485Zm-1.848-8.867a2.161,2.161,0,0,0-1.557.626,2.261,2.261,0,0,0-.553,2.169,4.879,4.879,0,0,0,1.12,1.978,3.81,3.81,0,0,0,3.856,1.155h0a1.2,1.2,0,0,0,.874-.875,3.806,3.806,0,0,0-1.15-3.858,4.907,4.907,0,0,0-1.977-1.12,2.717,2.717,0,0,0-.613-.075Z"
      transform="translate(-29.827 -43.225)"
      fill="#190800"
    />
    <path
      d="M60.887,86.081l-7.358-7.359a2.364,2.364,0,1,0-3.344,3.344l7.358,7.359a2.364,2.364,0,1,0,3.344-3.344Z"
      transform="translate(-30.617 -44.041)"
    />
    <path
      d="M61.979,84.547,54.62,77.189a4.172,4.172,0,0,0-4.988-.718l-1.244-1.245a1.4,1.4,0,1,0-1.98,1.98l1.326,1.325a4.225,4.225,0,0,0,.9,4.641l7.358,7.359a4.231,4.231,0,0,0,5.984-5.984ZM60,88.55a1.431,1.431,0,0,1-2.024,0l-7.358-7.358a1.431,1.431,0,1,1,2.024-2.024L60,86.527a1.429,1.429,0,0,1,0,2.023Z"
      transform="translate(-30.387 -43.826)"
      fill="#190800"
    />
    <path
      d="M58.981,77.738A4.589,4.589,0,0,1,55.719,76.4a4.771,4.771,0,0,1,.115-6.734l4.043-4.041a1.4,1.4,0,1,1,1.98,1.98l-4.042,4.04a1.886,1.886,0,1,0,2.658,2.658l4.041-4.041a1.4,1.4,0,1,1,1.98,1.98l-4.041,4.042A4.912,4.912,0,0,1,58.981,77.738Z"
      transform="translate(-30.946 -43.187)"
      fill="#190800"
    />
    <path
      d="M60.987,73.047A1.4,1.4,0,0,1,60,70.657l2.544-2.544a1.4,1.4,0,1,1,1.98,1.98l-2.545,2.544A1.393,1.393,0,0,1,60.987,73.047Z"
      transform="translate(-31.29 -43.352)"
      fill="#190800"
    />
  </SvgIcon>
)
TakeAwayOrder = pure(TakeAwayOrder)
TakeAwayOrder.displayName = 'TakeAwayOrder'
TakeAwayOrder.muiName = 'SvgIcon'

export default TakeAwayOrder
