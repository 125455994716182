import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { EventManager } from 'pmt-modules/event'
import { PaymentMethodsAllowed } from 'pmt-modules/orderSettings'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

import { TypographyCustom } from 'pmt-ui/Typography'

const TRDPayment = ({ setPaymentMethod, orderProperties }) => (
  <FormControlLabel
    value="female"
    onClick={() => {
      EventManager.dispatch(EventManager.Events.ON_PAYMENT_MODE_SELECT, {
        paymentMode: PaymentMethodsAllowed.TRD,
      })
      setPaymentMethod(PaymentMethodsAllowed.TRD)
    }}
    control={
      <Radio
        color="primary"
        name="paymentTRD"
        checked={orderProperties.payment.isTRDMethod}
        value="paymentTRD"
      />
    }
    label={<TypographyCustom type={167}>{tr('order.payment.TRD')}</TypographyCustom>}
  />
)

export default TRDPayment
