import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import Box from 'pmt-ui/Box'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import IconLabel from 'pmt-ui/IconLabel'
import CommunicationMessage from 'pmt-ui/svg-icons/communication/message'

const styles = (theme) => ({
  optionsFromMenu: {},

  commentFromMenu: {},

  grey500: {
    color: theme.pmt.colors.grey500,
  },

  commentIcon: {
    width: 18,
  },

  commentLabel: {},

  productComment: {
    fontStyle: 'italic',
  },

  bigOptionFont: {
    marginBottom: 5,
    '&:empty': {
      marginBottom: 0,
    },
  },
  smallOptionFont: {
    marginBottom: 5,
    '&:empty': {
      marginBottom: 0,
    },
  },
})

const ProductContent = ({ product, fromMenu, type, classes }) => (
  <Box mt={0}>
    {/* product options */}
    <TypographyCustom
      component="div"
      type={124}
      className={classNames(classes[`${type}OptionFont`], classes.grey500, {
        [classes.optionsFromMenu]: fromMenu,
      })}
    >
      {product.options.map((option) =>
        option.values.map(
          (value) =>
            value.quantity > 0 &&
            !isEmpty(value.unavailabilityReasons) && <div key={value.id}>{value.name}</div>
        )
      )}
    </TypographyCustom>

    {/* reclaim later */}
    {product.reclaimLater && (
      <TypographyCustom
        component="div"
        type={124}
        className={classNames(classes[`${type}OptionFont`], classes.grey500, {
          [classes.optionsFromMenu]: fromMenu,
        })}
      >
        {tr('order.product.reclaimLater.label')}
      </TypographyCustom>
    )}

    {/* Product comment */}
    {product.comment && (
      <TypographyCustom
        type={124}
        component="div"
        className={classNames(
          classes[`${type}OptionFont`],
          classes.grey500,
          classes.productComment,
          {
            [classes.commentFromMenu]: fromMenu,
          }
        )}
      >
        <IconLabel
          spacing={5}
          classes={{
            label: classes.commentLabel,
          }}
          icon={<CommunicationMessage className={classes.commentIcon} />}
          label={product.comment}
        />
      </TypographyCustom>
    )}
  </Box>
)

export default withStyles(styles)(ProductContent)
