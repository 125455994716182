import { createApiEnumAction, createApiCallAction, createTestApiCallAction } from '../api/utils'

import AppConfigApi from '../api/calls/AppConfigApi'

import { getAppConfigFixture } from './fixtures'

export const GetAppConfigAction = createApiEnumAction('APP_CONFIG::GET')

export const fetchAppConfig = () => createApiCallAction(GetAppConfigAction, AppConfigApi.get())

export const fetchAppConfigFixture = apiConsumer =>
  createTestApiCallAction(GetAppConfigAction, true, {
    data: {
      apiConsumer,
    },
    response: getAppConfigFixture(),
  })
