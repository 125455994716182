//
// Utils around `Object.freeze`
//

/**
 * A pure function is a function where the return value is only determined by its input values, 
 * without observable side effects.
 * 
 * Example:
 * 
 * ```javascript
 * // good 
 * const myFunc = pureFunction((paramA) => {
 *    return paramA * 2
 * })
 * 
 * // wrong
 * const myFunc = pureFunction((paramA) => {
 *    paramA = paramA * 2
 *    return paramA
 * })
 * ```
 * 
 * It will throw an exception if the func given have side effects (modify the given parameters).
 * Use this for any function that should be pure (= should not have side effect).
 * 
 * TODO: maybe make a function pure only in development?
 * 
 * Freeze each parameter of the given func
 * @param {*} func 
 */
export const pureFunction = (func) => (...args) => {
  const newArgs = args.map(arg => Object.freeze(arg))
  return func.apply(this, newArgs)
}