import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import {
  formatOrderFees,
  formatOrderSettingsFees,
  generateFeesFromSettings,
} from 'pmt-modules/fees'
import { getDefaultLocale } from 'pmt-modules/i18n'
import { formatOrder, OrderMode } from 'pmt-modules/order'
import {
  getMaxTimeAheadAsDaysOnMode,
  PaymentMethodsAllowed,
  getDeliveryTime,
} from 'pmt-modules/orderSettings'

import { getAsyncPaymentBrowserInfo } from 'pmt-utils/browser'
import { formatPriceWithCurrency } from 'pmt-utils/currency'
import { millisecondsToMinutes } from 'pmt-utils/date'
import { createFormatter } from 'pmt-utils/format'

const formatOrderProperties = (
  data,
  { cartData, orderAppConfig, orderSettings, orderPreview, orderPreviewVerifications }
) => {
  data.locale = isNil(data.locale) ? getDefaultLocale() : data.locale

  data.isTakeAway = data.mode === OrderMode.TAKE_AWAY
  data.isOnSite = data.mode === OrderMode.ON_SITE
  data.isDelivery = data.mode === OrderMode.DELIVERY

  data.isIrlMethod = data.paymentMethod === PaymentMethodsAllowed.IRL
  data.isCreditCardMethod = data.paymentMethod === PaymentMethodsAllowed.CREDIT_CARD
  data.isUserAccountMethod = data.paymentMethod === PaymentMethodsAllowed.USER_ACCOUNT
  data.isTRDMethod = data.paymentMethod === PaymentMethodsAllowed.TRD

  if (!isNil(data.postedOrderData)) {
    data.postedOrderData.orderData = formatOrder(data.postedOrderData.orderData)
  }

  // this part seems deprecated
  data.maxTimeAheadAsDaysForMode = null
  if (!isNil(data.mode)) {
    data.maxTimeAheadAsDaysForMode = getMaxTimeAheadAsDaysOnMode(
      data.mode,
      orderSettings.onSiteSettings,
      orderSettings.takeAwaySettings,
      orderSettings.deliverySettings
    )
  }

  if (data.isDelivery && !isNil(orderSettings.deliverySettings)) {
    data.deliveryTime = getDeliveryTime(orderSettings.deliverySettings)
  } else {
    data.deliveryTime = 0
  }

  // delay only contains the manufacture delay
  data.delay = 0
  if (!isNil(orderSettings.manufactureDelay) && !orderAppConfig.bypassManufactureDelay) {
    data.delay = orderSettings.manufactureDelay
  }
  data.delayAndDeliveryFormattedAsMin = millisecondsToMinutes(data.delay + data.deliveryTime)

  data.hasDelayOrDeliveryTime = data.delay + data.deliveryTime > 0

  data.fees = formatOrderFees(
    generateFeesFromSettings(formatOrderSettingsFees(orderSettings.orderFeeSettings || []), {
      orderMode: data.mode,
      totalCartPrice: cartData.totalCartPrice,
    })
  )

  data.modifiers = get(orderPreview, 'modifiers', null)
  data.admissionFees = get(orderPreview, 'admissionFees', null)
  data.userAccount = get(orderPreviewVerifications, 'userAccount', null)

  data.totalFees = data.fees.reduce((total, fee) => (total += fee.amountIncludingTax), 0)

  data.totalModifiersOrder = (get(orderPreview, 'modifiersOrdered') || []).reduce(
    (total, modifier) => (total += modifier.amount),
    0
  )

  data.totalCartPrice = cartData.totalItemList

  data.totalCartPriceAndFees =
    cartData.totalItemList +
    data.totalFees +
    data.totalModifiersOrder +
    get(orderPreview, 'admissionFees.amountIncludingTax', 0) -
    get(orderPreviewVerifications, 'userAccount.subsidies.grantAmount', 0)

  data.totalCartPriceAndFeesFormatted = formatPriceWithCurrency(data.totalCartPriceAndFees)

  return data
}

/**
 * Data neeeded for 3DS v2
 *
 * https://www.notion.so/paymytable/Mangopay-3DS2-543d705dd4534a908b3dc1a64882e7a6.
 */
const formatBrowserInfo = data => ({
  ...data,
  browserInfo: getAsyncPaymentBrowserInfo(),
})

const formatPaymentProperties = data => ({
  ...data,
  payment: {
    method: data.paymentMethod,
    isIrlMethod: data.isIrlMethod,
    isCreditCardMethod: data.isCreditCardMethod,
    isTRDMethod: data.isTRDMethod,
    isUserAccountMethod: data.isUserAccountMethod,
  },
})

export const formatOrderFrontProperties = createFormatter(
  formatOrderProperties,
  formatPaymentProperties,
  formatBrowserInfo
)

const formatOrderData = ({ orderFrontProperties, itemListForApi, userMe, comment }) => {
  if (
    isNil(orderFrontProperties.mode) ||
    isNil(orderFrontProperties.restaurantId) ||
    (isEmpty(itemListForApi.products) && isEmpty(itemListForApi.menus))
  ) {
    return {}
  }

  const userId = userMe ? userMe.id : null

  // the attributes set to null here "must be"
  // set in the specific fronts part (order plugin / order kiosk)

  let data = {
    ...itemListForApi,
    dueDateMode: orderFrontProperties.dueDateMode,
    mode: Number(orderFrontProperties.mode),
    restaurantId: orderFrontProperties.restaurantId,
    userId,
    tableNumber: null,
    posCheckId: null,
    pagerId: orderFrontProperties.pagerId,
    modifiers: orderFrontProperties.modifiers,
    comment,
    idempotencyKey: orderFrontProperties.idempotencyKey,
    address: {
      city: null,
      complement: null,
      country: null,
      id: null,
      name: null,
      postCode: null,
      street: null,
    },
    admissionFees: orderFrontProperties.admissionFees,
    fees: orderFrontProperties.fees,
    payment: null,
    // since the API is implicit on the payment method, we add the chosen payment method on the front
    // allow us to find errors / bad behaviour, such as a payment with user account at 0 being
    // transformed to an on site payment.
    paymentMethod: orderFrontProperties.paymentMethod,
    browserInfo: orderFrontProperties.browserInfo,
  }

  if (!isNil(orderFrontProperties.dueDate)) {
    data = { ...data, dueDate: orderFrontProperties.dueDate }
  }

  return data
}

export const formatOrderFrontData = createFormatter(formatOrderData)
