import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import isNull from 'lodash/isNull'

import { fetchUserUserAccounts } from '../../actions'

import {
  makeGetUserUserAccounts,
  makeIsFetchingUserUserAccounts,
  makeGetDefaultUserUserAccount,
} from '../../selectors'

import { redirectTo, getRoute } from '../../../routing'

/**
 * Container that fetch the credit cards for the given `userId` and pass it to the children.
 * The `children` must be a `Function as child component`.
 *
 * Requirements:
 * - userId, can be 'me'
 *
 */
class UserAccountsContainer extends React.Component {
  constructor(props) {
    super(props)

    if (
      props.forceUpdate ||
      ((isNull(props.userAccounts) || isNull(props.userAccounts[0])) &&
        !props.isFetchingUserAccounts)
    ) {
      this.loadUserUserAccounts(props)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (nextProps.userId !== props.userId && !props.isFetchingUserAccounts) {
      this.loadUserUserAccounts(nextProps)
    }
  }

  loadUserUserAccounts(props) {
    props.fetchUserUserAccounts(props.userId)
  }

  render() {
    const { isFetchingUserAccounts, userAccounts, userId, children, ...otherProps } = this.props

    return children({
      isFetchingUserAccounts,
      userAccounts,
      // in most cases, a user only have one user account
      userAccount: userAccounts && userAccounts.length > 0 ? userAccounts[0] : null,
      userId,
      onRedirectToTopUp: () => {
        this.props.redirectTo(getRoute('USER_TOP_UP'))
      },
      ...otherProps,
    })
  }
}

UserAccountsContainer.propTypes = {
  fetchUserUserAccounts: PropTypes.func.isRequired,
  isFetchingUserAccounts: PropTypes.bool,
  userAccounts: PropTypes.array,

  // children MUST be a function
  children: PropTypes.func.isRequired,
}

const makeMapStateToProps = () => {
  const getUserAccounts = makeGetUserUserAccounts()
  const getDefaultUserUserAccount = makeGetDefaultUserUserAccount()
  const isFetchingUserAccounts = makeIsFetchingUserUserAccounts()

  const mapStateToProps = (state, props) => {
    const defaultUserAccount = getDefaultUserUserAccount(state, props)

    return {
      defaultUserAccount,
      // Note: For now we handle only one UserAccount, so the userAccount is the default one
      userAccount: defaultUserAccount,
      userAccounts: getUserAccounts(state, props),
      isFetchingUserAccounts: isFetchingUserAccounts(state, props),
    }
  }
  return mapStateToProps
}

export default connect(
  makeMapStateToProps,
  {
    fetchUserUserAccounts,
    redirectTo,
  }
)(UserAccountsContainer)
