import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'

import { getItemList, deleteCartDiffItemList, hideCartDiffDialog } from 'pmt-modules/cartDiff'

import { TypographyCustom } from 'pmt-ui/Typography'
import { DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import { withStyles } from 'pmt-ui/styles'

import Button from '../Button'
import ProductContent from './components/ProductContent'
import Type from './Type'

const styles = (theme) => ({
  title: {
    clear: 'both',
  },
  itemContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  cross: {
    width: 30,
    height: 30,
  },
  quantityContainer: {
    float: 'left',
    width: 30,
    height: 30,
  },
  itemNameContainer: {
    float: 'left',
    width: 'calc(100% - 90px)',
  },
  itemOptions: {
    color: theme.pmt.colors.grey500,
  },
  closeArea: {
    float: 'right',
    cursor: 'pointer',
    padding: theme.spacing(1),
    paddingBottom: 0,
    marginTop: -theme.spacing(3),
    marginRight: -theme.spacing(3),
  },
  closeBtn: {
    background: theme.pmt.colors.grey100,
    cursor: 'pointer',
    width: 34,
    height: 34,
    padding: 7,
    borderRadius: 10,
    margin: 1,
  },
  bigButtonMobile: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  footer: {
    padding: theme.spacing(3),
  },
})

class CartDiffItemsDialog extends React.PureComponent {
  // ensure to remove unavailable items from cart when unloading page
  onUnload = () => {
    this.props.deleteCartDiffItemList()
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  handleRemoveItemsFromCart = () => {
    const { deleteCartDiffItemList, hideCartDiffDialog } = this.props

    deleteCartDiffItemList()
    hideCartDiffDialog()
  }

  render() {
    const { itemList, classes } = this.props

    return (
      <React.Fragment>
        <DialogTitle>
          <TypographyCustom type="226" component="p" className={classes.title}>
            {tr('order.cart_diff.title')}
          </TypographyCustom>
          <TypographyCustom type="164" className="u-marginTop5">
            {tr('order.cart_diff.info')}
          </TypographyCustom>
        </DialogTitle>

        <DialogContent>
          <div className="u-marginTop20">
            {itemList.map((item, index) => (
              <div className={classes.itemContainer} key={index}>
                <TypographyCustom type="165" className={classes.quantityContainer}>
                  x{item.quantity}
                </TypographyCustom>
                <div className={classes.itemNameContainer}>
                  <TypographyCustom type="165">{item.name}</TypographyCustom>
                  <TypographyCustom type="126" className={classes.itemOptions}>
                    {item.isProduct && (
                      <ProductContent fromMenu={false} product={item} type={Type.BIG} />
                    )}

                    {item.isMenu && (
                      /* menu products */
                      <div>
                        {item.selectedProducts.map((product) => {
                          let productHasUnavailableValue = false
                          forEach(product.options || [], (option) => {
                            forEach(option.values, (value) => {
                              if (value.quantity > 0 && !isEmpty(value.unavailabilityReasons)) {
                                productHasUnavailableValue = true
                              }
                            })
                          })

                          return (
                            product.isValid &&
                            (!isEmpty(product.unavailabilityReasons) ||
                              productHasUnavailableValue) &&
                            product.quantity > 0 && (
                              <React.Fragment>
                                <span className={classes.grey500}>{product.name}</span>
                                <ProductContent fromMenu product={product} type={Type.BIG} />
                              </React.Fragment>
                            )
                          )
                        })}
                      </div>
                    )}
                  </TypographyCustom>
                </div>
              </div>
            ))}
          </div>
        </DialogContent>
        <div className={classes.footer}>
          <div className="u-overflowHidden">
            <Button
              size="large"
              label={tr('order.cart_diff.yes_change_duedate')}
              className={classNames('u-floatRight', classes.bigButtonMobile)}
              onClick={this.handleRemoveItemsFromCart}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

CartDiffItemsDialog.DialogRootProps = {
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

const mapStateToProps = (state) => ({
  itemList: getItemList(state),
})

export default compose(
  connect(mapStateToProps, {
    hideCartDiffDialog,
    deleteCartDiffItemList,
  }),
  withStyles(styles)
)(CartDiffItemsDialog)
