import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Mustard = props => (
  <SvgIcon {...props}>
    <path
      d="M52.937,4.539V3.1h.354V0H40.885V3.1h.354V4.539a6.311,6.311,0,0,0-1.317,3.874,6.41,6.41,0,0,0,.052.816l.962,7.456h12.3L54.2,9.229a6.408,6.408,0,0,0,.052-.816A6.311,6.311,0,0,0,52.937,4.539ZM41.947,1.032H52.229V2.064H41.947Zm.24,4,.114-.141V3.1h9.575v1.8l.114.141a5.371,5.371,0,0,1,.976,1.831H41.21A5.37,5.37,0,0,1,42.187,5.034Zm-.314,10.62L41.7,14.277H52.481L52.3,15.653ZM53.149,9.1l-.535,4.144H41.562L41.027,9.1a5.4,5.4,0,0,1-.044-.688q0-.26.025-.516h12.16q.024.256.025.516A5.4,5.4,0,0,1,53.149,9.1Z"
      transform="translate(-35 4)"
    />
    <path
      d="M166.223,303.274a.688.688,0,1,0,.707.688A.7.7,0,0,0,166.223,303.274Z"
      transform="translate(-156 -290)"
    />
    <path
      d="M274.224,303.274a.688.688,0,1,0,.707.688A.7.7,0,0,0,274.224,303.274Z"
      transform="translate(-260 -290)"
    />
    <path
      d="M220.223,271.274a.688.688,0,1,0,.708.688A.7.7,0,0,0,220.223,271.274Z"
      transform="translate(-208 -259)"
    />
  </SvgIcon>
)
Mustard = pure(Mustard)
Mustard.displayName = 'Mustard'
Mustard.muiName = 'SvgIcon'

export default Mustard
