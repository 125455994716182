import forEach from 'lodash/forEach'

import { createFormatter, createSubObjectFormatter } from 'pmt-utils/format'
import { getForLocale } from 'pmt-utils/locale'

export const formatTexts = (texts, props) => {
  let textsForCurrentLocale = {}

  forEach(texts, ({ type, locales }) => {
    const textForLocale = getForLocale(locales, props.locale)?.text || ''
    textsForCurrentLocale[type] = textForLocale
  })

  return textsForCurrentLocale
}

export const formatCustomTexts = createSubObjectFormatter('texts', createFormatter(formatTexts))
