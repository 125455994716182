import RegisterApi from '../api/calls/RegisterApi'

import {
  createApiCallAction,
  createApiEnumAction,
  // createTestApiCallAction,
} from '../api/utils'

export const RegisterUserAction = createApiEnumAction('REGISTRATION::REGISTER')

export const RegisterIncognitoUserAction = createApiEnumAction('REGISTRATION::REGISTER_INCOGNITO')

export const RegisterUserLightAction = createApiEnumAction('REGISTRATION::REGISTER_USER_LIGHT')

// export const registerUserTest = (user) => createTestApiCallAction(
//   RegisterUserAction,
//   true,
//   {
//     data: { user },
//   }
// )

export const registerUser = (user, props) =>
  createApiCallAction(RegisterUserAction, RegisterApi.postUser(user), {
    user,
    props,
  })

export const registerAsIncognito = () =>
  createApiCallAction(RegisterIncognitoUserAction, RegisterApi.postIncognitoUser())

export const registerUserLight = (user, props) =>
  createApiCallAction(RegisterUserLightAction, RegisterApi.postUserLightUser(user), {
    user,
    props,
  })
