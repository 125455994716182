import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import classNames from 'classnames'

import { withStyles } from 'pmt-ui/styles'
import withWidth, { isWidthDown } from 'pmt-ui/utils/withWidth'
import Hidden from 'pmt-ui/Hidden'
import Add from 'pmt-ui/svg-icons/content/add'

import Button from '../../../components/Button'
import SubmitBtnText from './SubmitBtnText'

const styles = (theme) => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  rootDialog: {
    margin: 0,
    borderRadius: 0,
  },
  cancelBtn: {
    // same as submitBtn marginLeft
    marginRight: theme.spacing(1),
  },
  submitBtn: {
    [theme.breakpoints.up('md')]: {
      // same as cancelBtn marginRight
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  submitBtnMobile: {
    borderRadius: 0,
    width: '100%',
  },
})

const Actions = ({
  appConfigPreset,
  fromDialog,
  orderProduct,
  isMenuMode,
  isEditMode,
  isMobile,
  classes,
  onClose,
  onSubmit,
  width,
}) =>
  // see https://zpl.io/b6QwNnP
  (orderProduct.hasOptions || isWidthDown('sm', width)) && (
    <div
      className={classNames(classes.root, {
        [classes.rootDialog]: fromDialog && isWidthDown('sm', width),
      })}
    >
      <Hidden smDown>
        <Button
          variant="outlined"
          onClick={onClose}
          classes={{ root: classes.cancelBtn }}
          size="large"
          color="secondary"
        >
          {tr('order.global.cancel')}
        </Button>
      </Hidden>

      <Button
        disabled={!orderProduct.isValid || !orderProduct.available}
        onClick={onSubmit}
        classes={{
          root: classNames(classes.submitBtn, {
            [classes.submitBtnMobile]: fromDialog && isWidthDown('sm', width),
          }),
        }}
        size="large"
        icon={!isEditMode && !isMenuMode && <Add />}
      >
        <SubmitBtnText
          appConfigPreset={appConfigPreset}
          orderProduct={orderProduct}
          isMenuMode={isMenuMode}
          isEditMode={isEditMode}
        />
      </Button>
    </div>
  )

export default compose(withStyles(styles), withWidth())(Actions)
