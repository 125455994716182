import Immutable from 'immutable'

import {
  FetchUserUserAccountAction,
  FetchUserUserAccountsAction,
  UpdateUserAccountAction,
  UserAccountAction,
} from './actions'

import { createSimpleReducer } from '../redux'

export * from './actions'
export * from './selectors'
export * from './components'
export * from './middlewares'

// Example of restaurant data
// {
//   cards: null,
//   isFetching: false,
//   lastUpdated: null,
//   error: null,
// }

const DEFAULT = Immutable.fromJS({})

// TODO: since this is persisted, we persist 'isFetching' to false in some cases, so we never
// try to load the user accounts. The front display an infinite loading.
// we must set isFetching to false when rehydrating the state.
// I think this bug exists on some other reducers
// solutions?
// - do not persist the root reducer, but persist each reducer, this would allow us to configure each
// reducer persistance independantly.
// - we should make reducers config to be persisted on the reducer file not on entities, via a configuration
// - if the front wants to disable the behaviour, it can disable the config from entities.
// - For now: we force the loading of the user accounts data when we arrive on the payment page, to
// avoid having the infinite loading
export const userUserAccountsReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case UserAccountAction.RESET:
      return DEFAULT

    case FetchUserUserAccountsAction.REQUEST:
      return state.merge({
        [action.data.userId]: {
          list: null,
          isFetching: true,
          lastUpdated: null,
          error: null,
        },
      })

    case FetchUserUserAccountsAction.SUCCESS:
      return state
        .merge({
          [action.data.userId]: {
            isFetching: false,
            lastUpdated: new Date(),
            error: null,
          },
        })
        .setIn(
          [action.data.userId, 'list'],
          (action.response || []).reduce((acc, userAccount) => {
            acc[userAccount.id] = {
              data: userAccount,
              isFetching: false,
              lastUpdated: new Date(),
              error: null,
            }
            return acc
          }, {})
        )

    case FetchUserUserAccountsAction.FAILURE:
      return state.merge({
        [action.data.userId]: {
          list: null,
          isFetching: false,
          lastUpdated: new Date(),
          error: action.error,
        },
      })

    case FetchUserUserAccountAction.REQUEST:
      return state.mergeIn([action.data.userId, 'list'], {
        // we set data to its existing state because we don't want to lose data here
        [action.data.accountId]: {
          ...(state.get(action.data.userId).get('list', {})[action.data.accountId] || {}),
          ...{
            isFetching: true,
            error: null,
          },
        },
      })

    case FetchUserUserAccountAction.SUCCESS:
    case UpdateUserAccountAction.SUCCESS:
      return state.setIn([action.data.userId, 'list'], {
        [action.data.accountId]: {
          data: action.response,
          isFetching: false,
          lastUpdated: new Date(),
          error: null,
        },
      })

    case FetchUserUserAccountAction.FAILURE:
      return state.setIn([action.data.userId, 'list'], {
        [action.data.accountId]: {
          data: null,
          isFetching: false,
          lastUpdated: new Date(),
          error: action.error,
        },
      })

    default:
      return state
  }
}

//
//
//

export const updateUserAccountReducer = createSimpleReducer(UpdateUserAccountAction)
