import find from 'lodash/find'
import isNil from 'lodash/isNil'
import invariant from 'invariant'

//
// {
//   type: ModalType.ORDER_PLUGIN_PRODUCT_VIEW,
//   view: ProductDialog,
// }
//

let _dialogs = []
export const registerDialogs = (dialogs) => {
  _dialogs = dialogs
}

export const getDialogs = (dialogs) => (
  _dialogs
)

/**
 * @param  {string} dialogType DialogType of the dialog to retrive
 * @return {DialogConfiguration} the configuration for the given DialogType
 */
export const getDialogViewForType = (dialogType) => {
  const dialog = find(_dialogs, (dialog) => dialogType === dialog.type)

  invariant(!isNil(dialog), 'dialog ' + dialogType + ' not registered')

  return dialog.view
}
