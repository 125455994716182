import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import ui from 'pmt-modules/reduxUi'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { EventManager, SendEventContainer } from 'pmt-modules/event'
import { withAppConfig } from 'pmt-modules/appConfig'
import { getRoute, redirectTo, replaceWith } from 'pmt-modules/routing'
import {
  haveAuthCredentials,
  AuthMode,
  getAuthCookie,
  getUserLightCookie,
  getIncognitoCookie,
} from 'pmt-modules/auth'
import { registerAsIncognito } from 'pmt-modules/registration'
import { getItemListFromCart } from 'pmt-modules/cart'
import { getErrorOrderPost, isFetchingOrderPost } from 'pmt-modules/orderPost'
import { OrderPluginUrlCheckerContainer, withOrderProperties } from 'pmt-modules/orderPlugin'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import withRestaurantsGroupMine from 'pmt-modules/restaurantsGroup/components/withRestaurantsGroup'
import { getAuthenticatedUser } from 'pmt-modules/authenticatedUser/selectors'
import UserType from 'pmt-modules/user/constants/UserType'

import { getQueryParam } from 'pmt-utils/url'

import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'

import OrderPage from '../../components/OrderPage'

import { LoginViews } from './constants'
import View from './View'

/**
 * @specs N/A
 */
@withAppConfig
@withOrderProperties
@withRestaurant
@withRestaurantsGroupMine()
@withScrollToTop()
class LoginPage extends React.Component {
  constructor(props) {
    super(props)

    // user already have correct credentials, he has nothing to do here
    if (
      this.isCorrectlyAuthenticated(
        this.props.appConfig.authentication,
        this.props.authenticatedUser
      )
    ) {
      this.redirectCorrectlyAuthenticatedUser(this.props)
    }
  }

  componentWillReceiveProps(nextProps) {
    // specific when accessToken is passed as query param
    // auth credentials have not been saved in cookie yet
    if (
      this.isCorrectlyAuthenticated(nextProps.appConfig.authentication, nextProps.authenticatedUser)
    ) {
      this.redirectCorrectlyAuthenticatedUser(nextProps)
    }
  }

  isCorrectlyAuthenticated = (authenticationSettings, authenticatedUser) => {
    // cf. getAuthorizationFromModeAndCookies
    if (!authenticatedUser) {
      // you need to wait that the user is fetched before redirecting
      return false
    }
    if (authenticationSettings.mode === AuthMode.NORMAL) {
      if (getAuthCookie() != null) {
        return authenticatedUser.type === UserType.NORMAL
      } else if (authenticationSettings.allowIncognito && getIncognitoCookie()) {
        return authenticatedUser.type === UserType.INCOGNITO
      }
    } else if (authenticationSettings.mode === AuthMode.LIGHT) {
      if (getAuthCookie() != null) {
        return authenticatedUser.type === UserType.NORMAL
      } else if (getUserLightCookie() != null) {
        return authenticatedUser.type === UserType.LIGHT
      } else if (authenticationSettings.allowIncognito && getIncognitoCookie()) {
        return authenticatedUser.type === UserType.INCOGNITO
      }
    } else if (
      authenticationSettings.mode === AuthMode.INCOGNITO_ONLY &&
      getIncognitoCookie() != null
    ) {
      return authenticatedUser.type === UserType.INCOGNITO
    }
    return false
  }

  redirectCorrectlyAuthenticatedUser = ({
    cartItemList,
    frontAppConfig,
    orderProperties,
    redirectTo,
    replaceWith,
    errorOrderPost,
    isFetchingOrderPost,
  }) => {
    const redirectToParam = getQueryParam('redirectTo')
    if (!isEmpty(redirectToParam)) {
      replaceWith(decodeURIComponent(redirectToParam))
    } else if (!isNil(cartItemList) && !isEmpty(cartItemList)) {
      if (frontAppConfig.display.paymentPage) {
        redirectTo(
          orderProperties.isDelivery
            ? getRoute('ORDER__DELIVERY_ADDRESS')
            : getRoute('ORDER__PAYMENT')
        )
      } else {
        if (!isFetchingOrderPost && errorOrderPost) {
          // user was asked to login after putting items in their cart
          // now they are logged in but still stucked on the login page
          // because something went wrong during the automatic postOrder who followed the successful login
          // => we sent them to the Cart page, so they can try to post the order again
          redirectTo(getRoute('ORDER__CART'))
        }

        // if the user has haveAuthCredentials=true, and there is no paymentPage to display :
        // the next step is to send the order, and then redirect to confirm page
        // this is done by the following worklow :
        // - on the AuthUserAction.SUCCESS, the middleware does a fetchUserMe with isFromAuth=true
        // - on the GetUserMeAction.SUCCESS, the middleware will do a postOrderOrRedirect (because isFromAuth=true)
        // - on the PostOrderAction.SUCCESS, the middleware will redirect to confirm page
        // so here, we just stay on the login page, until this workflow happens
      }
    } else if (!isNil(orderProperties.restaurant)) {
      redirectTo(getRoute('ORDER__CATALOG'), {
        restaurantId: orderProperties.restaurant.id,
      })
    } else {
      redirectTo(getRoute('ORDER__STORE_LOCATOR'))
    }
  }

  handleToggleView = (newView) => {
    this.props.updateUI({
      view: newView,
    })
    window.scrollTo(0, 0)
  }

  render() {
    const {
      appConfig,
      isFetchingRestaurantsGroup,
      restaurantsGroup,
      restaurant,
      isFetchingRestaurant,
      cartItemList,
      location,
      ui,
      haveAuthCredentials,
      orderProperties,
      width,
    } = this.props

    return (
      <OrderPage
        headerProps={{
          displayTitle: isWidthUp('md', width),
        }}
        orderProperties={orderProperties}
        restaurant={restaurant}
        location={location}
      >
        <OrderPluginUrlCheckerContainer location={location} checkApiConsumer>
          <SendEventContainer
            send={orderProperties && !isNil(orderProperties.restaurant)}
            event={EventManager.Events.ON_PAGE_LOADED}
            eventProps={() => ({
              items: cartItemList,
              restaurant: orderProperties.restaurant,
              dueDate: orderProperties.dueDate,
            })}
          >
            <View
              appConfig={appConfig}
              restaurantsGroup={restaurantsGroup}
              isFetching={isFetchingRestaurantsGroup || isFetchingRestaurant}
              isFetchingRestaurant={isFetchingRestaurant}
              onToggleView={this.handleToggleView}
              options={ui}
              haveAuthCredentials={haveAuthCredentials}
              orderProperties={orderProperties}
              restaurant={restaurant}
              cartItemList={cartItemList}
            />
          </SendEventContainer>
        </OrderPluginUrlCheckerContainer>
      </OrderPage>
    )
  }
}

const mapStateToProps = (state) => ({
  haveAuthCredentials: haveAuthCredentials(state),
  cartItemList: getItemListFromCart(state),
  isFetchingOrderPost: isFetchingOrderPost(state),
  errorOrderPost: getErrorOrderPost(state),
  authenticatedUser: getAuthenticatedUser(state),
})

export default compose(
  ui({
    state: {
      view: LoginViews.CHOICE_TO_DO,
    },
  }),
  connect(mapStateToProps, {
    redirectTo,
    replaceWith,
    registerAsIncognito,
    getAuthenticatedUser,
  }),
  withWidth()
)(LoginPage)
