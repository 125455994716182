import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'

import { getOrderProperties } from 'pmt-modules/orderFront'

import { formatCatalog } from './format'
import { getFirstSelectableCategory, getCategoryFromCatalog } from './utils'

const getCatalogEntity = state => state.entities.catalog

const getCatalogDataOnProps = (state, props) => (!isNil(props) ? props.catalog : null)
const getCategoryIdOnProps = (state, props) => props.categoryId
const getParentCategoriesOnProps = (state, props) => props.parentCategories

export const getCatalogForMode = createSelector(
  [getCatalogEntity, getOrderProperties],
  (catalog, orderProperties) => {
    if (isNil(catalog)) {
      return null
    }
    return catalog.get(String(orderProperties.mode), null)
  }
)

export const getCatalogData = createSelector([getCatalogForMode], catalogForMode => {
  if (catalogForMode) {
    const catalog = catalogForMode.get('data', null)
    if (catalog) {
      return catalog.toJS()
    }
  }
  return null
})

export const getErrorCatalog = createSelector([getCatalogForMode], catalogForMode => {
  const catalog = catalogForMode ? catalogForMode.get('error', null) : null
  return isNil(catalog) ? null : catalog.toJS()
})

export const isFetchingCatalog = createSelector(
  [getCatalogForMode],
  catalogForMode => (catalogForMode ? catalogForMode.get('isFetching', false) : false)
)

export const getCatalogFormatted = createSelector([getCatalogData], catalogData => {
  if (isNil(catalogData)) {
    return null
  }

  return formatCatalog(catalogData)
})

export const makeGetCategory = () =>
  createSelector(
    [getCatalogDataOnProps, getCategoryIdOnProps, getParentCategoriesOnProps],
    (catalogData, categoryId, parentCategories) => {
      if (isNil(catalogData) || isNil(categoryId)) {
        return null
      }

      return getCategoryFromCatalog(catalogData, categoryId, parentCategories)
    }
  )

export const makeGetFirstSelectableCategory = () =>
  createSelector(
    [getCatalogDataOnProps],
    catalogData => (isNil(catalogData) ? null : getFirstSelectableCategory(catalogData))
  )

export const getSelectedCategory = createSelector([getCatalogEntity], catalog => {
  const selectedCategory = catalog ? catalog.get('selectedCategory', null) : null

  if (!isNil(selectedCategory)) {
    return selectedCategory.toJS()
  }

  return null
})

export const getParentCategories = createSelector([getCatalogEntity], catalog => {
  const parentCategories = catalog ? catalog.get('parentCategories', []) : []

  return isEmpty(parentCategories) ? [] : parentCategories.toJS()
})

export const getCatalogLocale = createSelector(
  [getCatalogForMode],
  catalog => (catalog ? catalog.get('locale', null) : null)
)
