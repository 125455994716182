//
// Entry point of any PayMyTable app.
// The front application is configured via the `appOptions` with
// * -> required
//
// - *middlewares: middlewares to use
// - devMiddlewares: middlewares to use only on devlopment
// - prodMiddlewares: middlewares to use only on production
// - *entities: entities to use on the store
// - *routes: routes to use (array of definitions)
// - environment: configuration of the current environment. Must contains:
//  - Logger
//    - SENTRY_URL
// - forms (TODO)
// - theme (TODO)
// - dialogs (TODO)
//

import React from 'react'
import { hot } from 'react-hot-loader'

import Immutable from 'immutable'
import invariant from 'invariant'

import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'

import createBrowserHistory from 'history/createBrowserHistory'

import ApiManager from 'pmt-modules/api/ApiManager'
import { Events, EventManager } from 'pmt-modules/event'
import { initializeAnalytics } from 'pmt-modules/analytics'

import configureRootReducer from './utils/configureRootReducer'

import {
  setCurrentEnv,
  __VERSION__,
  __APP_GIT_COMMIT__,
  __SDK_GIT_COMMIT__,
  __APP_GIT_BRANCH__,
  __SDK_GIT_BRANCH__,
} from '../environment'

import configureStore from './store/configureStore'

import Logger from 'pmt-utils/logger'

import { unregisterServiceWorker } from './registerServiceWorker'
import App from './App'

import { initFrontSessionId } from 'pmt-modules/frontSession'
import { setDefaultAppConfig } from 'pmt-modules/appConfig'
import { registerStore } from 'pmt-modules/store'
import { registerForms, getFormsDefaultState } from 'pmt-modules/form'
import { registerSearches } from 'pmt-modules/search'
import { registerStatisticsConfig } from 'pmt-modules/statistics'
import { registerDialogs } from 'pmt-modules/dialog'
import { registerTheme, getRegisteredMuiTheme } from 'pmt-modules/theme'
import { setDataCatcherSettings } from 'pmt-modules/dataCatcher/config'
import { verifyDataCatcherConfiguration } from 'pmt-modules/dataCatcher/verifications'
import { registerHistory, registerRoutes, registerRoutingEnum } from 'pmt-modules/routing'
import { registerEntities } from 'pmt-modules/reducers'

import { configureI18n } from 'pmt-modules/i18n'

import configRoutes from 'app/config/routes'

// import css
import './styles/index.scss'

class ApplicationView extends React.Component {
  constructor(props) {
    super(props)

    initFrontSessionId()

    const appOptions = props.appOptions

    appOptions.routes = configRoutes

    //
    // environment
    // must be handle in first
    //
    const environment = appOptions.environment
    setCurrentEnv(environment)

    setDefaultAppConfig(appOptions.appConfig)

    //
    // events
    //
    if (!isNil(appOptions.events)) {
      invariant(
        !isNil(appOptions.events.events) && !isNil(appOptions.events.listeners),
        'missing events or listners on appOptions events'
      )

      EventManager.registerEvents(appOptions.events.events)
      EventManager.addListeners(appOptions.events.listeners)
    }

    //
    // Firebase
    // must be before the `configureRootReducer`
    //
    if (process.env.FEATURE_FIREBASE) {
      if (!isNil(appOptions.firebase)) {
        const initializeFirebase = require('pmt-modules/firebase').initializeFirebase

        // we took the whole config and keep only the one for the current ENV
        appOptions.firebase = appOptions.firebase[appOptions.environment.ENV]

        initializeFirebase(appOptions)
      }
    }

    //
    // configure history
    //

    const history = createBrowserHistory()

    registerHistory(history)

    history.listen(location =>
      EventManager.dispatch(Events.ON_HISTORY_CHANGE, {
        location,
      })
    )

    //
    //
    //

    appOptions.rootReducer = configureRootReducer(appOptions)

    //
    // forms
    //

    const forms = appOptions.forms
    registerForms(forms)

    //
    // searches
    //

    const searches = appOptions.searches
    registerSearches(searches)

    //
    // statistics
    //

    const statistics = appOptions.statistics
    registerStatisticsConfig(statistics)

    //
    // dialogs
    //

    const dialogs = appOptions.dialogs
    registerDialogs(dialogs)

    //
    // Configure theme
    //

    registerTheme(appOptions.theme)

    //
    // Configure data catcher module
    //

    if (!isNil(appOptions.dataCatcher)) {
      verifyDataCatcherConfiguration(appOptions)
      setDataCatcherSettings(appOptions.dataCatcher)
    }

    //
    // configure store
    //

    // -- calculate preloadedState
    const preloadedState = appOptions.preloadedState || {}

    // get forms default state
    preloadedState.form = getFormsDefaultState()

    // set theme
    preloadedState.ux = {
      ...preloadedState.ux,
      theme: {
        current: Immutable.fromJS(getRegisteredMuiTheme()),
      },
    }

    registerEntities(appOptions.entities)

    // set on appOptions
    appOptions.preloadedState = preloadedState

    const store = configureStore(appOptions)
    registerStore(store)

    //
    // routes
    //
    registerRoutes(appOptions.routes)
    registerRoutingEnum(appOptions.routingEnum)

    //
    // I18n
    //

    configureI18n(appOptions.i18n)

    //
    // Analytics
    //
    if (!isNil(appOptions.analytics)) {
      appOptions.analytics = appOptions.analytics[appOptions.environment.ENV]
      initializeAnalytics(appOptions)
    }

    //
    // configure api manager
    //

    const getApiManagerOptions = appOptions.getApiManagerOptions
    invariant(isFunction(getApiManagerOptions), 'appOptions getApiManagerOptions is required')
    ApiManager.setGetApiManagerOptions(getApiManagerOptions)

    //
    // Configure logger
    //

    const loggerConfig = environment.Logger

    // add app version to have more info
    loggerConfig.APP_VERSION = __VERSION__
    loggerConfig.ENV = appOptions.environment.ENV
    loggerConfig.__APP_GIT_COMMIT__ = __APP_GIT_COMMIT__
    loggerConfig.__SDK_GIT_COMMIT__ = __SDK_GIT_COMMIT__
    loggerConfig.__APP_GIT_BRANCH__ = __APP_GIT_BRANCH__
    loggerConfig.__SDK_GIT_BRANCH__ = __SDK_GIT_BRANCH__

    Logger.configure(loggerConfig)
    environment.logger = loggerConfig

    // Dispatch app init event with appOptions
    EventManager.dispatch(Events.ON_APP_INIT, { appOptions })

    //
    // Unregister service worker for now since it causes white pages due to
    // an 'Uncaught SyntaxError: Unexpected token <' error
    //
    unregisterServiceWorker()

    this.store = store
  }

  render() {
    return <App store={this.store} />
  }
}

export default hot(module)(ApplicationView)
