//
// This module store global configuration and data for the order plugin,
// that don't requires their own module / reducer
//
import Immutable from 'immutable'

import { OrderSettingsAction } from './actions'

export * from './actions'
export * from './constants'
export * from './format'
export * from './selectors'
export * from './utils'
export * from './middlewares'

const DEFAULT = Immutable.fromJS({})

export const orderSettingsReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case OrderSettingsAction.SET_ORDER_SETTINGS:
      return state.merge(action.orderSettings)

    case OrderSettingsAction.RESET_ORDER_SETTINGS:
      return state.set({})

    default:
      return state
  }
}
