import React from 'react'

import classNames from 'classnames'
import { withStyles } from 'pmt-ui/styles'

import { Sticky } from 'pmt-ui/Sticky'
import SideMenu from './SideMenu'
import Actions from './Actions'

const styles = theme => ({
  root: {
    // do not put padding / margin here because of sticky
    [theme.breakpoints.up('lg')]: {
      margin: `0 ${theme.spacing(6)}px`,
    },
    [theme.breakpoints.down('lg')]: {
      margin: `0 ${theme.spacing(4)}px`,
    },
  },
  stickyPart: {
    padding: `${theme.spacing(2)}px 0`,
  },
  menuPartLeftAreaSticky: {
    ...theme.pmt.appConfig.menuPartLeftAreaSticky,
    // do not put padding / margin here because of sticky
  },
})

/**
 *
 */
const LeftArea = ({ appConfigPreset, classes, orderMenu, isEditMode, onSubmit }) => (
  <div className={classes.root}>
    <Sticky>
      {({ isSticky, style }) => (
        <div
          style={style}
          className={classNames(classes.stickyPart, {
            [classes.menuPartLeftAreaSticky]: isSticky,
          })}
        >
          <SideMenu orderMenu={orderMenu} onSubmit={onSubmit} />

          <Actions
            appConfigPreset={appConfigPreset}
            orderMenu={orderMenu}
            onSubmit={onSubmit}
            isEditMode={isEditMode}
          />
        </div>
      )}
    </Sticky>
  </div>
)

export default withStyles(styles)(LeftArea)
