import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { OrderMode } from 'pmt-modules/order'

import Grid from 'pmt-ui/Grid'
import Hidden from 'pmt-ui/Hidden'
import IconOrderMode from 'pmt-ui/Icon/IconOrderMode'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import { emphasize } from 'pmt-utils/theme'

import BlockContainer from '../../components/BlockContainer'
import { ButtonSquare } from '../../components/Button'

const styles = (theme) => ({
  button: {
    color: theme.palette.primary.main,
    margin: '0 auto',
    marginTop: theme.spacing(2),
    '&:hover': {
      background: emphasize(theme.palette.primary.main, 0.85),
      borderColor: emphasize(theme.palette.primary.main, 0.85),
    },
  },
  blockContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(13),
    paddingBottom: theme.spacing(13),
    borderRadius: theme.shape.border.radius.main,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      marginTop: 0,
      borderRadius: 0,
      minHeight: 'calc(100vh - 48px)',
    },
  },
  modesWrapper: {
    maxWidth: 500,
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
})

const View = ({ classes, appConfig, onSelectMode }) => (
  <Grid spacing={0} className={classes.view} container justify="center">
    <BlockContainer type={BlockContainer.Type.WHITE} className={classes.blockContainer}>
      <Hidden xsDown>
        <TypographyCustom type="226" align="center" className="u-marginBottom40">
          {tr('order.mode.choose_mode')}
        </TypographyCustom>
      </Hidden>
      <Hidden smUp>
        <TypographyCustom type="206" align="center" className="u-marginBottom20">
          {tr('order.mode.choose_mode')}
        </TypographyCustom>
      </Hidden>
      <Grid container justify="center" className={classes.modesWrapper} spacing={0}>
        {appConfig.order.modes.map((mode, index) => (
          <ButtonSquare
            key={index}
            classes={{
              root: classes.button,
            }}
            icon={<IconOrderMode type={mode} />}
            label={
              OrderMode.DELIVERY === mode
                ? tr('order.global.delivery')
                : OrderMode.ON_SITE === mode
                ? tr('order.global.on_site')
                : tr('order.global.take_away')
            }
            onClick={() => onSelectMode(parseInt(mode, 10))}
          />
        ))}
      </Grid>
    </BlockContainer>
  </Grid>
)

export default withStyles(styles)(View)
