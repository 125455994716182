import React from 'react'
import PropTypes from 'prop-types'

import ButtonBase from 'pmt-ui/ButtonBase'
import { withStyles } from 'pmt-ui/styles'

const styles = (theme) => ({
  root: {
    width: 150,
    height: 150,
    display: 'flex',
    fontSize: 20,
    flexDirection: 'column',
    borderRadius: theme.shape.border.radius.content,
    boxShadow: 'none',
    borderStyle: 'solid',
    borderWidth: theme.shape.border.width.default,
    borderColor: theme.shape.border.color.default,
    backgroundColor: theme.pmt.colors.white,
    transition: 'background-color 0.35s ease',
    '&$disabled': {
      backgroundColor: theme.palette.text.divider,
    },
    '&:hover': {
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: theme.palette.grey[300],
      },
      '&$disabled': {
        backgroundColor: theme.palette.text.divider,
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: theme.palette.grey[300],
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: 125,
      height: 100,
    },
  },
  keyboardFocused: {},
  disabled: {
    color: theme.palette.action.disabled,
  },
  icon: {
    height: 55,
    width: 70,
    [theme.breakpoints.down('xs')]: {
      width: 50,
      height: 40,
    },
  },
  label: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0.5),
      fontSize: 14,
    },
  },
})

const ButtonSquare = ({ classes, label, icon, onClick }) => (
  <ButtonBase className={classes.root} onClick={onClick}>
    {React.cloneElement(icon, {
      className: classes.icon,
    })}
    <span className={classes.label}>{label}</span>
  </ButtonBase>
)

ButtonSquare.propTypes = {
  colors: PropTypes.object,
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
}

export default withStyles(styles)(ButtonSquare)
