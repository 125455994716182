import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import compose from 'recompose/compose'

import LoyaltyIcon from '@material-ui/icons/Loyalty'

import { tr } from 'pmt-modules/i18n'
import { EventManager } from 'pmt-modules/event'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { duplicateItemOnCart, removeFromCart, deleteFromCart, editItem } from 'pmt-modules/cart'
import { getOrderPreview } from 'pmt-modules/orderPreview'

import Type from './Type'

import { withStyles } from 'pmt-ui/styles'
import Price from 'pmt-ui/Price'
import Tooltip from 'pmt-ui/Tooltip'
import { TypographyCustom } from 'pmt-ui/Typography'
import ContentAdd from 'pmt-ui/svg-icons/content/add'
import ContentRemove from 'pmt-ui/svg-icons/content/remove'
import Trash from 'pmt-ui/svg-icons/pmt/Trash'
import CartIconEmpty from 'pmt-ui/svg-icons/pmt/CartIconEmpty'

import { existsOnArray } from 'pmt-utils/array'

import Chip from '../Chip'
import CartModifierTag from '../CartModifier/CartModifierTag'

import ProductContent from './components/ProductContent'

const styles = (theme) => ({
  containerActionQuantity: {
    display: 'flex',
    alignItems: 'center',
  },
  // common styles for both small and big cart
  iconContainer: {
    width: 25,
    height: 25,
    borderRadius: theme.shape.border.radius.quantityButton,
    backgroundColor: theme.pmt.colors.greyLightBackground,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.pmt.colors.greyLightBackgroundHover,
    },
  },
  iconContainerPrimary: {},
  iconContainerTrash: {},
  cartContentIcon: {
    width: 18,
    height: 18,
    padding: 4,
    color: theme.pmt.colors.black,
  },
  cartContentIconPrimary: {},
  cartContentIconTrash: {},
  cartContentText: {
    width: 30,
  },
  deleteSection: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  deleteIconContainer: {
    height: 18,
    lineHeight: '24px',
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  divider: {
    width: '100%',
    height: 0,
    borderBottom: `1px solid ${theme.pmt.colors.grey300}`,
    display: 'none',
  },
  cartIcon: {
    display: 'block',
    width: 65,
    height: 50,
    marginLeft: 10,
    color: theme.palette.primary.main,
  },

  menuProductOption: {
    marginLeft: theme.spacing(1),
  },

  nameAndOptionsGrid: {
    [theme.breakpoints.down('xs')]: {
      order: -1,
      flexBasis: '100%',
      paddingLeft: '0 !important',
      paddingBottom: theme.spacing(2),
    },
  },

  // styles for big type
  bigQuantityGrid: {},
  bigNameAndOptionsGrid: {
    paddingLeft: 30,
    lineHeight: '21px',
  },
  bigPriceGrid: {
    width: 85,
  },
  bigNameFont: {},
  bigTotalPriceFont: {},
  bigMathPriceFont: {},
  bigMenuProductFont: {
    marginBottom: 5,
  },

  // styles for small type
  smallQuantityGrid: {},
  smallNameAndOptionsGrid: {
    paddingLeft: 10,
  },
  smallNameFont: {},
  nameLoyalty: {
    marginBottom: 4,
  },
  smallPriceGrid: {
    width: 85,
  },
  smallMenuProductFont: {
    marginBottom: 5,
  },
  smallMathPriceFont: {},
  cartModifierContainer: {
    overflow: 'hidden',
  },
  cartModifierChip: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    borderRadius: 6,
    padding: '5px 8px',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '14px',
    height: 'auto',
    display: 'inline-block',
    textTransform: 'capitalize',
  },
  modifiers: {
    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  modifier: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  loyalty: {
    color: theme.palette.primary.main,
    width: 16,
    height: 16,
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  rowItem: {
    borderBottom: `1px solid ${theme.shape.border.color.default}`,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
  },
  price: {
    justifyContent: 'flex-end',
  },
})

const ItemQuantity = ({
  classes,
  item,
  enabledQuantityChange,
  deleteFromCart,
  removeFromCart,
  duplicateItemOnCart,
}) => (
  <div className={`${classes.containerActionQuantity}`}>
    {enabledQuantityChange && (
      <React.Fragment>
        {item.quantity === 1 ? (
          <span
            className={classNames(
              'u-flexCenter u-justifyContentCenter',
              classes.iconContainer,
              classes.iconContainerTrash
            )}
          >
            <Trash
              viewBox="0 0 512 512"
              className={classNames(
                'u-cursorPointer',
                classes.cartContentIcon,
                classes.cartContentIconTrash
              )}
              onClick={() => {
                deleteFromCart(item)
              }}
            />
          </span>
        ) : (
          <span
            className={classNames(
              'u-flexCenter u-justifyContentCenter',
              classes.iconContainer,
              classes.iconContainerPrimary
            )}
          >
            <ContentRemove
              className={classNames('u-cursorPointer', classes.cartContentIcon)}
              onClick={() => {
                removeFromCart(item)
              }}
            />
          </span>
        )}
      </React.Fragment>
    )}
    <TypographyCustom
      component="span"
      type="146"
      align="center"
      className={classNames(classes.cartContentText)}
    >
      {item.quantity}
    </TypographyCustom>
    {enabledQuantityChange && (
      <span
        className={classNames(
          'u-flexCenter u-justifyContentCenter',
          classes.iconContainer,
          classes.iconContainerPrimary
        )}
      >
        <ContentAdd
          className={classNames('u-cursorPointer', classes.cartContentIcon)}
          onClick={() => {
            duplicateItemOnCart(item)
          }}
        />
      </span>
    )}
  </div>
)
ItemQuantity.propTypes = {
  classes: PropTypes.object.isRequired,
  enabledQuantityChange: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  deleteFromCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  duplicateItemOnCart: PropTypes.func.isRequired,
}

const ItemNameAndOptions = ({
  classes,
  type,
  item,
  onEditItem,
  enabledClickableItems,
  modifiersFromCart,
}) => {
  let hasComplexModifier = false
  forEach(item.modifierIds, (modifierId) => {
    if (existsOnArray(modifiersFromCart, ({ id, isComplex }) => id === modifierId && isComplex)) {
      hasComplexModifier = true
    }
  })

  return (
    <React.Fragment>
      <TypographyCustom
        type={type === Type.BIG ? '166' : '166'}
        className={classNames(classes[`${type}NameFont`], {
          'u-cursorPointer': enabledClickableItems,
        })}
        onClick={enabledClickableItems && onEditItem}
      >
        {hasComplexModifier && (
          <LoyaltyIcon className={classNames(classes.loyalty, classes.nameLoyalty)} />
        )}
        {item.name}
      </TypographyCustom>
      {item.hasCartModifier && (
        <div className={classes.cartModifierContainer}>
          {item.cartModifiers.list.map((cartModifier, index) => (
            <CartModifierTag
              cartModifier={cartModifier}
              chipElement={
                <Chip
                  key={index}
                  label={cartModifier.tag}
                  classes={{ root: classes.cartModifierChip }}
                />
              }
              key={index}
            />
          ))}
        </div>
      )}
      <div
        className={classNames('u-marginTop5', {
          'u-cursorPointer': enabledClickableItems,
        })}
        onClick={enabledClickableItems && onEditItem}
      >
        {item.isProduct && <ProductContent fromMenu={false} product={item} type={type} />}

        {item.isMenu && (
          /* menu products */
          <TypographyCustom
            component="div"
            type={126}
            className={classNames(classes[`${type}OptionFont`], classes.grey500)}
          >
            {item.selectedProducts.map((product, index) => (
              <React.Fragment key={index}>
                <span className={classes[`${type}MenuProductFont`]}>{product.name}</span>
                <ProductContent fromMenu product={product} type={type} />
              </React.Fragment>
            ))}
          </TypographyCustom>
        )}
      </div>
    </React.Fragment>
  )
}
ItemNameAndOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
}

const ItemPrice = ({ classes, type, item }) => (
  <div className="u-floatRight">
    <TypographyCustom
      component="div"
      type={166}
      align="right"
      className={classes[`${type}TotalPriceFont`]}
    >
      <Price value={item.totalPriceWithQuantityFormatted} className={classes.price} />
    </TypographyCustom>

    {item.quantity > 1 && (
      <TypographyCustom
        type={126}
        align="right"
        className={classNames(classes[`${type}MathPriceFont`], classes.grey500)}
      >
        {item.quantity}x{item.totalPriceFormatted}
      </TypographyCustom>
    )}
  </div>
)
ItemPrice.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
}

@withRestaurant
class CartItemListView extends React.PureComponent {
  render() {
    const {
      restaurant,
      classes,
      type,
      itemList,
      enabledQuantityChange,
      enabledClickableItems,
      deleteFromCart,
      removeFromCart,
      duplicateItemOnCart,
      editItem,
      fromRoute,
      appConfigPreset,
      orderPreview,
      orderModifiers,
    } = this.props

    // we don't want to display prices for preset other than order plugin
    // https://www.notion.so/paymytable/Finalisation-du-Menu-Digital-2228f83526ba4e8897619991c1f9c393
    const shouldDisplayPrices = appConfigPreset.isPluginOrder

    const modifiers = orderModifiers || orderPreview?.modifiers || []

    return (
      <React.Fragment>
        {isEmpty(itemList) ? (
          <div className={'u-flexCenter u-justifyContentSpaceBetween'}>
            <div>
              <TypographyCustom type="226" component="p">
                {tr('order.menu.cart.not_hungry')}
              </TypographyCustom>
              <TypographyCustom type="166" ccomponent="p" className={classNames('u-marginTop10')}>
                {tr('order.menu.cart.empty')}
              </TypographyCustom>
            </div>
            <CartIconEmpty className={classes.cartIcon} />
          </div>
        ) : (
          <React.Fragment>
            <div className={classes.modifiers}>
              {modifiers.map(
                (modifier) =>
                  (modifier.isComplex || !modifier.isAutomatic) && (
                    <Tooltip
                      key={modifier.id}
                      title={modifier.description}
                      placement="bottom-start"
                    >
                      <TypographyCustom type="167" className={classes.modifier}>
                        <LoyaltyIcon className={classes.loyalty} />
                        {modifier.name}
                      </TypographyCustom>
                    </Tooltip>
                  )
              )}
            </div>
            {itemList.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className={`u-overflowHidden u-paddingTop20 u-paddingBottom20 u-flex ${classes.rowItem}`}
                  >
                    <div className={classes[`${type}QuantityGrid`]}>
                      <ItemQuantity
                        classes={classes}
                        type={type}
                        item={item}
                        deleteFromCart={(item) => {
                          deleteFromCart(item)
                          EventManager.dispatch(EventManager.Events.ON_CART_ITEM_DELETE, {
                            item,
                            restaurant,
                          })
                        }}
                        removeFromCart={(item) => {
                          removeFromCart(item)
                          EventManager.dispatch(EventManager.Events.ON_CART_ITEM_DECREASE, {
                            item,
                          })
                        }}
                        duplicateItemOnCart={(item) => {
                          duplicateItemOnCart(item)
                          EventManager.dispatch(EventManager.Events.ON_CART_ITEM_INCREASE, {
                            item,
                          })
                        }}
                        enabledQuantityChange={enabledQuantityChange}
                      />
                    </div>
                    <div
                      className={classNames(
                        classes[`${type}NameAndOptionsGrid`],
                        classes.nameAndOptionsGrid,
                        'u-flex1'
                      )}
                    >
                      <ItemNameAndOptions
                        classes={classes}
                        modifiersFromCart={modifiers}
                        type={type}
                        item={item}
                        onEditItem={() => {
                          EventManager.dispatch(EventManager.Events.ON_CART_ITEM_DETAIL, {
                            item,
                          })
                          editItem(item, {
                            restaurantId: restaurant.id,
                            fromRoute,
                          })
                        }}
                        enabledClickableItems={enabledClickableItems}
                      />
                    </div>
                    {shouldDisplayPrices && (
                      <div className={classes[`${type}PriceGrid`]}>
                        <ItemPrice classes={classes} type={type} item={item} />
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )
            })}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

CartItemListView.defaultProps = {
  enabledQuantityChange: true,
  enabledClickableItems: true,
}

CartItemListView.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  itemList: PropTypes.array.isRequired,
  deleteFromCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  enabledQuantityChange: PropTypes.bool,
  enabledClickableItems: PropTypes.bool,
  duplicateItemOnCart: PropTypes.func.isRequired,
  /**
   * the route (RoutingEnum) from where this component is displayed.
   */
  fromRoute: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  orderPreview: getOrderPreview(state),
})

const CartItemList = compose(
  withStyles(styles),
  connect(mapStateToProps, {
    duplicateItemOnCart,
    removeFromCart,
    deleteFromCart,
    editItem,
  })
)(CartItemListView)

CartItemList.Type = Type

export default CartItemList
