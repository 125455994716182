import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { tr } from 'pmt-modules/i18n'
import { AppConfigContainer } from 'pmt-modules/appConfig'
import { addModifierCode } from 'pmt-modules/orderFront/actions'
import {
  getOrderPreview,
  getOrderPreviewVerifications,
  isFetchingOrderPreview,
  getVerificationsModifiersReasonLabel,
} from 'pmt-modules/orderPreview'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'

import CloseIcon from '@material-ui/icons/Close'
import LoyaltyIcon from '@material-ui/icons/Loyalty'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import Tooltip from 'pmt-ui/Tooltip'
import { TypographyCustom } from 'pmt-ui/Typography'

import Button, { ButtonLink } from 'app/components/Button'

import ContentAdd from 'pmt-ui/svg-icons/content/add'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0, 0),
  },
  card: {
    position: 'relative',
    //background: theme.pmt.colors.greyLightBackground,
  },
  title: {
    marginTop: theme.spacing(-1),
  },
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: 0,
    marginTop: theme.spacing(2),
  },
  form: {},
  modifiers: {
    marginTop: theme.spacing(1),
  },
  modifier: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  loyalty: {
    color: theme.palette.primary.main,
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
  },
  containerPromoCode: {
    marginRight: 0,
    width: '90%',
    marginBottom: theme.spacing(3),
  },
  iconPromo: {
    width: '15px !important',
    height: '15px !important',
    padding: 0,
    color: theme.pmt.colors.white,
    background: theme.palette.primary.main,
    borderRadius: '50%',
  },
}))

const PromoCode = ({
  addModifierCode,
  isFetchingOrderPreview,
  orderPreview,
  orderPreviewVerifications,
}) => {
  const classes = useStyles()
  const [showField, setShowField] = useState(false)
  const [code, setCode] = useState('')

  useEffect(() => {
    // reset code after success
    if (!isFetchingOrderPreview) {
      setCode('')
    }
  }, [isFetchingOrderPreview])

  return (
    <AppConfigContainer>
      {({ frontAppConfig }) => {
        if (!frontAppConfig?.display?.promoCode) {
          return null
        }

        return (
          <div className={classes.root}>
            <div className={classes.modifiers}>
              {orderPreview?.modifiers.map(
                (modifier) =>
                  modifier.code && (
                    <Tooltip
                      key={modifier.id}
                      title={modifier.description}
                      placement="bottom-start"
                    >
                      <div className={classes.modifier}>
                        <LoyaltyIcon className={classes.loyalty} />
                        <TypographyCustom type="167" skipColor>
                          {modifier.name}
                        </TypographyCustom>
                      </div>
                    </Tooltip>
                  )
              )}
            </div>

            {!showField ? (
              <ButtonLink
                icon={<ContentAdd className={classes.iconPromo} />}
                label={
                  <TypographyCustom type="146" skipColor>
                    {tr('order.payment.promo_code.show')}
                  </TypographyCustom>
                }
                onClick={() => setShowField(true)}
              />
            ) : (
              <Card variant="outlined" className={classes.card}>
                <LoadingBlockWrapper show={isFetchingOrderPreview} />

                <CardHeader
                  className={classes.header}
                  action={
                    <IconButton
                      aria-label={tr('order.payment.promo_code.hide')}
                      onClick={() => setShowField(false)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                  title={
                    <TypographyCustom type="146" className={classes.title}>
                      {tr('order.payment.promo_code.title')}
                    </TypographyCustom>
                  }
                />
                <CardContent className={classes.content}>
                  <form
                    className={classes.form}
                    onSubmit={(e) => {
                      e.preventDefault()
                      e.stopPropagation()

                      if (!isEmpty(code)) {
                        addModifierCode(code)
                      }
                    }}
                  >
                    <TextField
                      autoFocus
                      label={tr('order.payment.promo_code')}
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      helperText={
                        !orderPreviewVerifications?.modifiers.succeeded &&
                        getVerificationsModifiersReasonLabel(
                          orderPreviewVerifications?.modifiers.reason
                        )
                      }
                      className={classes.containerPromoCode}
                      variant="outlined"
                      error={!orderPreviewVerifications?.modifiers.succeeded}
                    />
                    <Button color="primary" size="large" type="submit">
                      {tr('order.payment.promo_code.apply')}
                    </Button>
                  </form>
                </CardContent>
              </Card>
            )}
          </div>
        )
      }}
    </AppConfigContainer>
  )
}

const mapStateToProps = (state) => ({
  orderPreview: getOrderPreview(state),
  isFetchingOrderPreview: isFetchingOrderPreview(state),
  orderPreviewVerifications: getOrderPreviewVerifications(state),
})

export default connect(mapStateToProps, {
  addModifierCode,
})(PromoCode)
