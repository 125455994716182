import {
  API_CALL,
  TYPES,
  REQUEST,
  DATA,
  API_CALL_TEST,
  REQUEST_IS_SUCCESS,
  SUCCESS_DATA,
  FAILURE_DATA,
} from './constants'

export const createUserLightBasic = email => `Basic ${btoa(`user_light_${email}:`)}`

export const createIncognitoBasic = userId => `Basic ${btoa(`incognito_${userId}:`)}`

export const createRestaurantBasic = (restaurantId, password) =>
  `Basic ${btoa(`restaurant_${restaurantId}:${password}`)}`

/**
 * returns true if the given `action` is an API_CALL action.
 */
export const isActionApiCall = action => {
  const apiCall = action[API_CALL]

  return typeof apiCall !== 'undefined'
}
/**
 * returns true if the given `action` is an API_CALL_TEST action.
 */
export const isActionApiCallTest = action => {
  const apiCallTest = action[API_CALL_TEST]

  return typeof apiCallTest !== 'undefined'
}

/**
 * Create an action for an api call.
 * See middleware `api`
 *
 * @param  {Object} typeEnum    the enum that define the action. Create with `createApiEnumAction`
 * @param  {Object} request     the request data. Ex: `ApiRestaurant.getRestaurant(restaurantId)`
 * @param  {Object} [data=null] data to attach to all the actions. Ex: { restaurantId }
 * @return {ApiAction}             ApiAction
 */
export const createApiCallAction = (typeEnum, request, data = null) => {
  return {
    type: API_CALL, // type is mandatory by redux-thunk
    [API_CALL]: {
      [TYPES]: [typeEnum.REQUEST, typeEnum.SUCCESS, typeEnum.FAILURE],
      [REQUEST]: request,
      [DATA]: data,
    },
  }
}

/**
 * Create an action for an api call.
 * See middleware `api`
 *
 * @param  {Object} typeEnum    the enum that define the action. Create with `createApiEnumAction`
 * @param  {boolean} isSuccess  should the mocked request be successfull or not ?
 * @param  {Object} mockData  data to attach to all the actions. Can contains the subobjects:
 *                                - data: the data to attach to each action of the api call
 *                                (REQUEST, SUCCESS, FAILURE)
 *                                - response: the data to attach to the success response action
 *                                - error: the data to attach to the failure response action
 * @return {ApiAction}             ApiAction
 */
export const createTestApiCallAction = (typeEnum, isSuccess, mockData = null) => ({
  type: API_CALL_TEST, // type is mandatory by redux-thunk
  [API_CALL_TEST]: {
    [TYPES]: [typeEnum.REQUEST, typeEnum.SUCCESS, typeEnum.FAILURE],
    [REQUEST]: null, // TODO: handle request for tests ?
    [DATA]: mockData.data,
    [REQUEST_IS_SUCCESS]: isSuccess,
    [SUCCESS_DATA]: mockData.response,
    [FAILURE_DATA]: mockData.error,
  },
})

/**
 * Create an enum to use for an Api request action
 */
export const createApiEnumAction = prefix => ({
  REQUEST: `${prefix}::REQUEST`,
  SUCCESS: `${prefix}::SUCCESS`,
  FAILURE: `${prefix}::FAILURE`,
})
