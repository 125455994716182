import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import OptionView from './OptionView'

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 20px)',
      margin: '0 auto',
    },
  },
})

const OptionsView = ({ orderProduct, classes, onSelectOptionValue, onUnselectOptionValue }) => (
  <div className={classes.root}>
    {orderProduct.options.map((option) => (
      <OptionView
        key={option.id}
        option={option}
        onSelectOptionValue={onSelectOptionValue}
        onUnselectOptionValue={onUnselectOptionValue}
      />
    ))}
  </div>
)

export default withStyles(styles)(OptionsView)
