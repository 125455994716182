import Immutable from 'immutable'

import { GeolocationAction } from './actions'

export * from './actions'
export * from './selectors'
export * from './constants'

const DEFAULT = Immutable.fromJS({
  // id: {
  //   origin: null,
  //   address: null,
  //   geolocationAddress: null,
  //   latitude: null,
  //   longitude: null,
  //   isFetching: false,
  //   error: null,
  // }
})

export const geolocationReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case GeolocationAction.INIT:
      return state.merge({
        [action.id]: {
          origin: action.origin,
          isFetching: true,
          latitude: null,
          longitude: null,
          address: null,
          computeAddress: null,
          computeAddressExtra: null,
          error: null,
        },
      })

    case GeolocationAction.SUCCESS:
      return state.mergeDeep({
        [action.id]: {
          isFetching: false,
          latitude: action.latitude,
          longitude: action.longitude,
          error: null,
        },
      })

    case GeolocationAction.FAILURE:
      return state.mergeDeep({
        [action.id]: {
          isFetching: false,
          error: action.error,
        },
      })

    case GeolocationAction.SET_ADDRESS:
      return state.mergeDeep({
        [action.id]: {
          address: action.address,
        },
      })

    case GeolocationAction.SET_COMPUTE_ADDRESS:
      return state.mergeDeep({
        [action.id]: {
          computeAddress: action.computeAddress,
        },
      })

    case GeolocationAction.SET_COMPUTE_ADDRESS_EXTRA:
      return state.mergeDeep({
        [action.id]: {
          computeAddressExtra: action.computeAddressExtra,
        },
      })

    case GeolocationAction.SET_COORDINATES:
      return state.mergeDeep({
        [action.id]: {
          latitude: action.latitude,
          longitude: action.longitude,
        },
      })

    case GeolocationAction.RESET_COORDINATES:
      return state.mergeDeep({
        [action.id]: {
          latitude: null,
          longitude: null,
        },
      })

    case GeolocationAction.RESET:
      return state.mergeDeep({
        [action.id]: {
          address: null,
          computeAddress: null,
          computeAddressExtra: null,
          latitude: null,
          longitude: null,
          origin: null,
        },
      })

    case GeolocationAction.RESET_FULL:
      return Immutable.fromJS({})

    case GeolocationAction.RESET_ERROR:
      return state.mergeDeep({
        [action.id]: {
          error: null,
        },
      })

    default:
      return state
  }
}
