import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'

import { redirectTo, getRoute } from 'pmt-modules/routing'
import { setSelectedCategory, setParentCategories } from 'pmt-modules/orderPlugin/actions'

import { EventManager } from 'pmt-modules/event'

import { withStyles } from 'pmt-ui/styles'

import CategoryBreadcrumb from './CategoryBreadcrumb'

const styles = (theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
})

// https://zpl.io/Vq6xzkN
const CategoriesBreadcrumb = ({
  setSelectedCategory,
  setParentCategories,
  selectedCategory,
  parentCategories = [],
  restaurant,
  redirectTo,
  classes,
}) =>
  !selectedCategory || !isArray(parentCategories) || isEmpty(parentCategories) ? null : (
    <React.Fragment>
      <div className={classes.root}>
        {parentCategories.map((parentCategory, index) => (
          <CategoryBreadcrumb
            // need index since a category can be a child of itself
            key={`${index}_${selectedCategory.id}`}
            category={parentCategory}
            isSelected={false}
            parentCategories={parentCategories.slice(0, index)}
            onSelect={(category, parents) => {
              EventManager.dispatch(EventManager.Events.ON_CATALOG_CATEGORY_SELECT, {
                category,
              })

              setParentCategories(parents)
              redirectTo(getRoute('ORDER__CATEGORY'), {
                restaurantId: restaurant.id,
                categoryId: category.id,
              })
            }}
          />
        ))}
        <CategoryBreadcrumb
          // need 'selected' since a category can be a child of itself
          key={`selected_${selectedCategory.id}`}
          category={selectedCategory}
          isSelected
          onSelect={(category, parents) => {
            EventManager.dispatch(EventManager.Events.ON_CATALOG_CATEGORY_SELECT, {
              category,
            })
            setParentCategories(parents)
            redirectTo(getRoute('ORDER__CATEGORY'), {
              restaurantId: restaurant.id,
              categoryId: category.id,
            })
          }}
          parentCategories={parentCategories}
        />
      </div>
    </React.Fragment>
  )

const mapStateToProps = (state, props) => ({})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    setSelectedCategory,
    setParentCategories,
    redirectTo,
  })
)(CategoriesBreadcrumb)
