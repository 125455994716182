import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { getQueryApiConsumer } from 'pmt-modules/environment'
import PaymentType from 'pmt-modules/global/constants/PaymentType'
import { PaymentMethodsAllowed } from 'pmt-modules/orderSettings'
import { getRoute } from 'pmt-modules/routing'

import { formatPriceWithCurrency } from 'pmt-utils/currency'
import { getQueryParam, getAbsolutePath } from 'pmt-utils/url'

const generateCreditCardPayment = ({ amount, selectedCreditCard }) => {
  // generate query params to be used through async payment callback url
  const asyncPaymentSecureCallbackUrlQueryParams = {}
  const apiVersion = getQueryParam('apiVersion')
  if (!isEmpty(apiVersion)) {
    asyncPaymentSecureCallbackUrlQueryParams.apiVersion = apiVersion
  }

  const simulate = getQueryParam('simulate')
  if (!isEmpty(simulate)) {
    asyncPaymentSecureCallbackUrlQueryParams.simulate = simulate
  }

  const apiConsumer = getQueryApiConsumer()
  if (!isEmpty(apiConsumer)) {
    asyncPaymentSecureCallbackUrlQueryParams.apiConsumer = apiConsumer
  }

  return {
    amount,
    tips: 0,
    cardId: get(selectedCreditCard, 'id', null),
    type: PaymentType.ORDER,
    method: PaymentMethodsAllowed.CREDIT_CARD,
    supportAsyncPayment: true,
    asyncPaymentData: {
      redirectUrl: getAbsolutePath(
        getRoute('ORDER__PAYMENT__VERIFICATION'),
        asyncPaymentSecureCallbackUrlQueryParams
      ),
    },
  }
}

const generateUserAccountPayment = ({ orderPreview, orderPreviewVerifications }) => {
  if (
    get(orderPreview, 'userAccountPayment', null) &&
    get(orderPreviewVerifications, 'userAccount', null)
  ) {
    const userAccount = orderPreviewVerifications.userAccount

    return {
      userAccountId: orderPreview.userAccountPayment.userAccountId,
      amountFromAccount: orderPreview.totalPrice - userAccount.maxGrantableAmount,
      amountFromAccountFormatted: formatPriceWithCurrency(
        orderPreview.totalPrice - userAccount.maxGrantableAmount
      ),
      grantAmount: userAccount.maxGrantableAmount,
      grantLabel: userAccount.grantLabel,
      grantTax: orderPreview.userAccountPayment.grantTax,
    }
  }

  return null
}

const generateTRDPayment = ({ amount, selectedCreditCards }) => {
  // generate query params to be used through async payment callback url
  const asyncPaymentSecureCallbackUrlQueryParams = {}

  const apiVersion = getQueryParam('apiVersion')
  if (!isEmpty(apiVersion)) {
    asyncPaymentSecureCallbackUrlQueryParams.apiVersion = apiVersion
  }

  const simulate = getQueryParam('simulate')
  if (!isEmpty(simulate)) {
    asyncPaymentSecureCallbackUrlQueryParams.simulate = simulate
  }

  const apiConsumer = getQueryApiConsumer()
  if (!isEmpty(apiConsumer)) {
    asyncPaymentSecureCallbackUrlQueryParams.apiConsumer = apiConsumer
  }

  return {
    amount,
    tips: 0,
    cardId: get(selectedCreditCards, 'id', null),
    type: PaymentType.ORDER,
    method: PaymentMethodsAllowed.TRD,
    supportAsyncPayment: true,
    asyncPaymentData: {
      redirectUrl: getAbsolutePath(
        getRoute('ORDER__PAYMENT__VERIFICATION'),
        asyncPaymentSecureCallbackUrlQueryParams
      ),
    },
  }
}

export const generateOrderData = ({
  orderProperties,
  orderFrontDatas,
  orderPreview,
  orderPreviewVerifications,
  selectedCreditCard,
}) => {
  if (
    isEmpty(orderFrontDatas) ||
    (orderProperties.isDelivery && isNil(orderProperties.deliveryAddress))
  ) {
    return null
  }

  const data = cloneDeep(orderFrontDatas)

  data.tableNumber = orderProperties.tableNumber
  data.posCheckId = orderProperties.posCheckId
  data.posCheckGuid = orderProperties.posCheckGuid

  // display breath analyzer banner for french restaurants, following country policy.
  data.withFrenchBreathAnalyzer = orderProperties.restaurant?.address?.country === 'FR'

  data.address = null
  if (orderProperties.isDelivery) {
    data.address = orderProperties.deliveryAddress
  }

  // TODO: paymentMethod is reset by ???
  switch (orderProperties.paymentMethod) {
    case PaymentMethodsAllowed.CREDIT_CARD: // 2
      data.payment = generateCreditCardPayment({
        amount: orderProperties.totalCartPriceAndFees,
        orderFrontDatas,
        selectedCreditCard,
      })
      break

    case PaymentMethodsAllowed.USER_ACCOUNT: // 4
      data.userAccountPayment = generateUserAccountPayment({
        orderPreview,
        orderPreviewVerifications,
      })
      break

    case PaymentMethodsAllowed.TRD: // 8
      data.payment = generateTRDPayment({
        amount: orderProperties.totalCartPriceAndFees,
        orderFrontDatas,
      })
      break

    default:
  }

  return data
}
