import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isNil from 'lodash/isNil'

import { FormControlLabel } from 'pmt-ui/Form'
import Checkbox from 'pmt-ui/Checkbox'

const Cgu = ({ label, value, onChange }) => (
    // TODO: remove label and add text with a link to the cgu
    <FormControlLabel
      className="u-sizeFullWidth"
      label={
        isNil(label)
          ? tr('global.register.accept_terms', {
              fr: "J'accepte les CGU",
              context: '',
              desc: 'Label prompting for legal terms',
            })
          : label
      }
      control={
        <Checkbox
          color="primary"
          checked={value}
          onChange={(event, isInputChecked) => onChange(isInputChecked)}
        />
      }
    />
  )

  export default Cgu