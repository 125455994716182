import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { getOrderSettings } from 'pmt-modules/appConfig'
import { getErrorOrderPost, postOrder } from 'pmt-modules/orderPost'
import { ORDER_MINIMUM_AMOUNT } from 'pmt-modules/order'
import {
  getIsFetchingPostCreditCardRegistrationType,
  getDatasPostCreditCardRegistrationType,
  getPostPspDatasCard,
  getPostPspDatasError,
  getPostPspDatasIsFetching,
  getDeleteUserCardIsFetching,
  getStatusSendUserCreditCardToPsp,
  postUserCreditCardRegistrationType,
  resetUserCreditCardToPspSend,
} from 'pmt-modules/creditCard'
import { getItemListFromCart } from 'pmt-modules/cart'
import { EventManager } from 'pmt-modules/event'
import {
  getOrderPreview,
  isFetchingOrderPreview,
  getOrderPreviewError,
} from 'pmt-modules/orderPreview'
import { PaymentMethodsAllowed } from 'pmt-modules/orderSettings'

import Card, { CardContent } from 'pmt-ui/Card'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import Grid from 'pmt-ui/Grid'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import ActionInfoOutline from 'pmt-ui/svg-icons/action/info-outline'

import MessageBlock from '../../../components/MessageBlock'
import Button from '../../../components/Button'

import CreditCardPayment from './CreditCardPayment'
import IrlPayment from './IrlPayment'
import UserAccountPayment from './UserAccountPayment'
import TRDPayment from './TRDPayment'

const styles = (theme) => ({
  paymentMethods: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  card: {
    border: `${theme.shape.border.width.default}px solid ${theme.shape.border.color.default}`,
  },
  cardContent: {
    padding: `${theme.spacing(3)}px !important`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1.5)}px !important`,
    },
  },
  warningContainer: {
    maxWidth: 380,
    margin: '0 auto',
  },
  loadingBlock: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      background: theme.pmt.colors.greyBackground,
    },
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  separator: {
    borderTop: `${theme.shape.border.width.default}px solid ${theme.shape.border.color.default}`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  lineHeight53: {
    lineHeight: '53px',
  },
  lineHeight36: {
    lineHeight: '36px',
  },
  lineHeight48: {
    lineHeight: '48px',
  },
  gridContainer: {
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  cgv: {
    marginBottom: theme.spacing(1),
    color: theme.pmt.colors.grey97,
  },
  link: {
    textDecoration: 'underline',
  },
})
class PaymentMethods extends React.PureComponent {
  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.statusSendUserCreditCardToPsp.failure ||
        nextProps.statusSendUserCreditCardToPsp.cancel ||
        nextProps.statusSendUserCreditCardToPsp.success) &&
      nextProps.ui.showCreditCardForm
    ) {
      this.props.updateUI({ showCreditCardForm: false })
    }

    if (isNil(this.props.orderProperties.paymentMethod) && !isNil(nextProps.currentOrderSettings)) {
      if (nextProps.currentOrderSettings.isCreditCardPaymentOnlyAllowed) {
        nextProps.setPaymentMethod(PaymentMethodsAllowed.CREDIT_CARD)
      } else if (nextProps.currentOrderSettings.isIrlPaymentOnlyAllowed) {
        nextProps.setPaymentMethod(PaymentMethodsAllowed.IRL)
      } else if (nextProps.currentOrderSettings.isTRDPaymentOnlyAllowed) {
        nextProps.setPaymentMethod(PaymentMethodsAllowed.TRD)
      } else if (nextProps.currentOrderSettings.isUserAccountPaymentOnlyAllowed) {
        nextProps.setPaymentMethod(PaymentMethodsAllowed.USER_ACCOUNT)
      }
    }
  }

  handlePostOrder = () => {
    const { postOrder, orderData } = this.props

    EventManager.dispatch(EventManager.Events.ON_PAYMENT_SUBMIT, {
      items: this.props.itemListFromCart,
      restaurant: this.props.restaurant,
    })
    postOrder(orderData)
  }

  render() {
    const {
      errorPostOrder,
      orderProperties,
      postUserCreditCardRegistrationType,
      datasPostCreditCardRegistrationType,
      postPspDatasCard,
      errorPostPspDatas,
      postPspDatasIsFetching,
      isFetchingPostCreditCardRegistrationType,
      statusSendUserCreditCardToPsp,
      isFetchingDeleteCreditCard,
      isFetchingCreditCards,
      isFetchingOrderPreview,
      creditCards,
      selectedCreditCard,
      orderPreview,
      orderPreviewError,
      onToggleCreditCard,
      onClickDeleteCreditCard,
      onResetUserCard,
      orderAppConfig,
      resetUserCreditCardToPspSend,
      setPaymentMethod,
      currentOrderSettings,
      restaurant,
      user,
      totalCartPriceAndFees,
      amountToPay,
      orderData,
      formIsValid,
      classes,
      ui,
      updateUI,
      canRegisterCardWithName,
    } = this.props

    const isEmptyCreditCards = isEmpty(creditCards)
    const isOnlyOneCreditCard = creditCards && creditCards.length === 1

    return (
      <div className={classes.paymentMethods}>
        {!currentOrderSettings || isFetchingCreditCards ? (
          <LoadingBlock
            show
            classes={{
              loadingBlock: classes.loadingBlock,
            }}
          />
        ) : (
          <React.Fragment>
            <Card className={`u-cursorPointer ${classes.card}`} elevation={0}>
              <CardContent classes={{ root: classes.cardContent }}>
                {currentOrderSettings.isUserAccountPaymentAllowed && (
                  <UserAccountPayment
                    isFetchingOrderPreview={isFetchingOrderPreview}
                    orderData={orderData}
                    orderPreview={orderPreview}
                    orderPreviewError={orderPreviewError}
                    orderProperties={orderProperties}
                    setPaymentMethod={setPaymentMethod}
                  />
                )}

                {currentOrderSettings.isCreditCardPaymentAllowed &&
                  totalCartPriceAndFees > ORDER_MINIMUM_AMOUNT && (
                    <React.Fragment>
                      {/* display only if we have user account payment above */}
                      {currentOrderSettings.isUserAccountPaymentAllowed && (
                        <hr className={classes.separator} />
                      )}
                      <CreditCardPayment
                        isFetching={isFetchingCreditCards || isFetchingDeleteCreditCard}
                        isEmptyCreditCards={isEmptyCreditCards}
                        isOnlyOneCreditCard={isOnlyOneCreditCard}
                        postUserCreditCardRegistrationType={postUserCreditCardRegistrationType}
                        datasPostCreditCardRegistrationType={datasPostCreditCardRegistrationType}
                        postPspDatasIsFetching={postPspDatasIsFetching}
                        postPspDatasCard={postPspDatasCard}
                        isFetchingPostCreditCardRegistrationType={
                          isFetchingPostCreditCardRegistrationType
                        }
                        statusSendUserCreditCardToPsp={statusSendUserCreditCardToPsp}
                        creditCards={creditCards}
                        selectedCreditCard={selectedCreditCard}
                        onToggleCreditCard={onToggleCreditCard}
                        onClickDeleteCreditCard={onClickDeleteCreditCard}
                        onResetUserCard={onResetUserCard}
                        resetUserCreditCardToPspSend={resetUserCreditCardToPspSend}
                        setPaymentMethod={setPaymentMethod}
                        restaurant={restaurant}
                        user={user}
                        amountToPay={amountToPay}
                        formIsValid={formIsValid}
                        orderData={orderData}
                        ui={ui}
                        updateUI={updateUI}
                        orderProperties={orderProperties}
                        canRegisterCardWithName={canRegisterCardWithName}
                      />
                    </React.Fragment>
                  )}

                {currentOrderSettings.isTRDPaymentAllowed && <hr className={classes.separator} />}

                {currentOrderSettings.isTRDPaymentAllowed && (
                  <TRDPayment
                    restaurant={restaurant}
                    user={user}
                    amountToPay={amountToPay}
                    formIsValid={formIsValid}
                    orderData={orderData}
                    ui={ui}
                    updateUI={updateUI}
                    orderProperties={orderProperties}
                    setPaymentMethod={setPaymentMethod}
                  />
                )}

                {currentOrderSettings.isIrlPaymentAllowed && <hr className={classes.separator} />}

                {currentOrderSettings.isIrlPaymentAllowed && (
                  <React.Fragment>
                    <IrlPayment
                      setPaymentMethod={setPaymentMethod}
                      orderAppConfig={orderAppConfig}
                      orderData={orderData}
                      orderProperties={orderProperties}
                    />
                  </React.Fragment>
                )}
              </CardContent>
            </Card>
            {!isNil(errorPostOrder) &&
              isNil(errorPostPspDatas) &&
              !statusSendUserCreditCardToPsp.failure &&
              !ui.showCreditCardForm && (
                <div className="u-marginTop30">
                  <ErrorBlock
                    error={errorPostOrder}
                    mode={ErrorBlock.Mode.CUSTOM}
                    customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
                  />
                </div>
              )}
            {(!orderProperties.payment.isCreditCardMethod ||
              (!ui.showCreditCardForm && !isEmptyCreditCards) ||
              !restaurant.hasPaymentWithCardPrint) && (
              <Grid spacing={2} container className={classes.gridContainer}>
                {!isEmpty(restaurant.legal.cgvUrl) && (
                  <Grid item xs={12}>
                    <TypographyCustom
                      type="144"
                      className={classes.cgv}
                      dangerouslySetInnerHTML={{
                        __html: tr('order.payment.cgv', {
                          linkBegin: `<a class="${classes.link}" href="${restaurant.legal.cgvUrl}" target="_blank" rel="noopener noreferrer">`,
                          linkEnd: '</a>',
                        }),
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} />
                <Grid item xs={12} sm={6}>
                  <Button
                    type="button"
                    size="large"
                    classes={{ root: 'u-sizeFullWidth' }}
                    disabled={!orderProperties.payment.paymentMethodIsSelectedAndValid}
                    label={
                      !orderProperties.payment.isIrlMethod && totalCartPriceAndFees > 0
                        ? tr('order.payment.pay_amount', { amount: amountToPay })
                        : tr('order.payment.finalize_order')
                    }
                    onClick={this.handlePostOrder}
                  />
                </Grid>
              </Grid>
            )}
            {!currentOrderSettings.isIrlPaymentAllowed &&
              totalCartPriceAndFees < ORDER_MINIMUM_AMOUNT && (
                <div className="u-marginTop20 u-textAlignCenter">
                  <ActionInfoOutline className={classNames(classes.iconInfo, classes.grey500)} />
                  <TypographyCustom
                    type="204"
                    align="center"
                    className={classNames(classes.warningContainer, classes.grey500)}
                  >
                    {tr('order.payment.minimum_to_order')}
                  </TypographyCustom>
                </div>
              )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  itemListFromCart: getItemListFromCart(state),
  isFetchingDeleteCreditCard: getDeleteUserCardIsFetching(state),
  datasPostCreditCardRegistrationType: getDatasPostCreditCardRegistrationType(state),
  orderAppConfig: getOrderSettings(state),
  postPspDatasIsFetching: getPostPspDatasIsFetching(state),
  postPspDatasCard: getPostPspDatasCard(state),
  errorPostPspDatas: getPostPspDatasError(state),
  isFetchingPostCreditCardRegistrationType: getIsFetchingPostCreditCardRegistrationType(state),
  statusSendUserCreditCardToPsp: getStatusSendUserCreditCardToPsp(state),
  errorPostOrder: getErrorOrderPost(state),
  isFetchingOrderPreview: isFetchingOrderPreview(state),
  orderPreview: getOrderPreview(state),
  orderPreviewError: getOrderPreviewError(state),
})

export default compose(
  connect(mapStateToProps, {
    resetUserCreditCardToPspSend,
    postUserCreditCardRegistrationType,
    postOrder,
  }),
  withStyles(styles)
)(PaymentMethods)
