import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'

import { addToArray } from 'pmt-utils/array'
import { createFormatter } from 'pmt-utils/format'
import { formatPriceWithCurrency } from 'pmt-utils/currency'

import { formatOptions as formatProductOptions } from './options'
import { CatalogItemType } from '../constants'

const formatProductData = product => {
  product.isProduct = true
  product.isMenu = false
  product.isCategory = false
  product.type = CatalogItemType.PRODUCT

  product.priceFormatted = formatPriceWithCurrency(product.price)
  product.additionalPriceFormatted = formatPriceWithCurrency(product.additionalPrice)

  product.comment = product.comment || ''
  product.hasComment = !isEmpty(product.comment)

  // used to know if we need to display the item directly on the detail view dialog
  product.isComplexItem = product.options.length > 0 || product.reclaimLaterEnabled

  // image
  product.hasImage = !isEmpty(product.image)

  return product
}

const formatOptions = product => {
  product.hasOptions = !isEmpty(product.options)
  product.options = formatProductOptions(product.options)
  return product
}

const formatDietaryInfo = product => {
  const formattedAllergens = [...get(product, 'dietaryInfo.allergens', [])]
  product.hasAllergens = !isEmpty(formattedAllergens)
  product.hasOptionWithAllergens = false

  // TODO: opti move to options formatting
  // then search for one option with hasAllergens to true ?
  forEach(product.options, option => {
    option.hasAllergens = option.hasAllergens || false
    forEach(option.values, value => {
      if (!isEmpty(get(value, 'dietaryInfo.allergens'))) {
        addToArray(formattedAllergens, get(value, 'dietaryInfo.allergens', []))
        product.hasAllergens = true
        product.hasOptionWithAllergens = true
        option.hasAllergens = true
      }
    })
  })

  product.formattedAllergens = uniq(formattedAllergens)

  return product
}

export const formatProduct = createFormatter(formatProductData, formatOptions, formatDietaryInfo)
