import React from 'react'

import moment from 'moment'
import range from 'lodash/range'

import { withStyles } from 'pmt-ui/styles'

import { InputLabel } from 'pmt-ui/Input'
import ListChooser from 'pmt-ui/ListChooser'

import { anyToDate } from 'pmt-utils/date'

const styles = {
  root: {},
  elements: {
    display: 'flex',
    flexDirection: 'row',
  },
  element: {
    display: 'flex',
    flexGrow: '1',
    maxWidth: 50,
    textAlign: 'center',
  },
}

const Day = ({ day, className, onChange }) => (
  <ListChooser
    title="Choisir le jour"
    label="Jour"
    value={day}
    options={range(1, 31 + 1).map(day => ({
      label: day,
      value: day,
    }))}
    onChange={onChange}
    className={className}
  />
)

const Month = ({ month, className, onChange }) => (
  <ListChooser
    title="Choisir le mois"
    label="Mois"
    value={month}
    options={range(1, 12 + 1).map(month => ({
      label: month,
      value: month,
    }))}
    onChange={onChange}
    className={className}
  />
)

const Year = ({ minYear, maxYear, year, className, onChange }) => (
  <ListChooser
    title={`Choisir l'année`}
    label="Année"
    value={year}
    options={range(minYear, maxYear)
      .reverse()
      .map(year => ({
        label: year,
        value: year,
      }))}
    onChange={onChange}
    className={className}
  />
)

class InlineDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.calculateState(props)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.calculateState(nextProps))
  }

  calculateState(props) {
    const { value, options } = props

    let date = value
    if (!date && options.defaultValue) {
      date = options.defaultValue
    }

    date = anyToDate(date)

    const day = date.date()
    const month = date.month() + 1 // index based 0
    const year = date.year()

    let minYear = 1900
    if (options.minDate) {
      minYear = anyToDate(options.minDate).year()
    }

    let maxYear = 2100
    if (options.maxDate) {
      maxYear = anyToDate(options.maxDate).year()
    }

    return {
      day,
      month,
      year,
      minYear,
      maxYear,
    }
  }

  handleChange = (day, month, year) => {
    const date = moment()
      .startOf('day')
      .date(day)
      .month(month - 1)
      .year(year)
    this.props.onChange(date)
  }

  render() {
    const { classes, label, required } = this.props
    const { day, month, year, minYear, maxYear } = this.state
    try {
      return (
        <div className={classes.root}>
          <InputLabel
          // focused // force focus to be on top mode
          >
            {label}
            {/* handle required field */}
            {required && <span>&thinsp;*</span>}
          </InputLabel>
          <div className={classes.elements}>
            <Day
              className={classes.element}
              day={day}
              onChange={day => this.handleChange(day, month, year)}
            />
            <Month
              className={classes.element}
              month={month}
              onChange={month => this.handleChange(day, month, year)}
            />
            <Year
              className={classes.element}
              minYear={minYear}
              maxYear={maxYear}
              year={year}
              onChange={year => this.handleChange(day, month, year)}
            />
          </div>
        </div>
      )
    } catch (e) {
      console.error(e)
    }
  }
}

export default withStyles(styles)(InlineDate)
