import React from 'react'
import PropTypes from 'prop-types'
import { findOnArray } from 'pmt-utils/array'

/**
 * State machine function as child component that handles state machine and custom transitions
 * configuration between states.
 *
 * TODO: change state.current if prop default change
 */
class StateMachine extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      current: props.default,
    }

    this.timeout = null
  }

  transitionTo = to => {
    this.setState({ current: to })

    //
    // Handle custom transitions
    //

    const transitions = this.props.transitions
    if (transitions) {
      const transition = findOnArray(transitions, transition => transition.state === to)

      // there is a custom transition for the state we go to
      if (transition) {
        if (transition.to && transition.duration) {
          this.runTransitionTo(transition)
        }
      }
    }
  }

  runTransitionTo = transition => {
    // custom transition to a specific state, after x ms
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      this.transitionTo(transition.to)
    }, transition.duration)
  }

  render() {
    return this.props.children({
      currentState: this.state.current,
      transitionTo: this.transitionTo,
    })
  }
}

StateMachine.defaultProps = {
  transitions: null,
}

StateMachine.propTypes = {
  default: PropTypes.string.isRequired,
  states: PropTypes.object.isRequired,

  /**
   * Object that defines specific behavior for transitions
   *
   * examples:
   *
   * - move to state `to` after
   * {
   *    state: 'STATE_A',
   *    to: 'STATE_B',
   *    duration: 100, // 100 ms
   * }
   */
  transitions: PropTypes.array,
}

export default StateMachine
