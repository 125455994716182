import {
  FetchUserUserAccountAction,
  fetchUserUserAccount,
  UpdateUserAccountAction,
} from './actions'

import {
  redirectToUserPage,
} from './../proCreateUser'

import {
  createMiddleware,
} from '../redux'

const TOKEN_EXPIRATION_DELAY = 840000 // 14 minutes

/**
* Intercepts FetchUserUserAccountAction.SUCCESS action.
* The data received contains a user account
*/
const fetchUserAccountMiddleware = createMiddleware(
  FetchUserUserAccountAction.SUCCESS,
  ({ dispatch, action }) => {
    if (action.data.refreshToken) {
      setTimeout(() => {
        dispatch(fetchUserUserAccount(action.data.userId, action.data.accountId, action.data.refreshToken))
      }, TOKEN_EXPIRATION_DELAY);
    }
  }
)

const updatedUserAccountReducer = createMiddleware(
  UpdateUserAccountAction.SUCCESS,
  ({ action, dispatch }) => {
    const { response } = action

    const userId = response.userId

    //
    // if the use has a company we redirect to the card on the company, otherwise, we display the
    // default user card
    //
    let route = redirectToUserPage(response.restaurantsGroupId, userId, response)

    dispatch(route)
  }
)

export const userAccountMiddlewares = [
  fetchUserAccountMiddleware,
]

export const boUserAccountMiddlewares = [
  updatedUserAccountReducer,
]
