import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { TypographyCustom } from 'pmt-ui/Typography'

const GeneralDatasCard = ({ orderProperties, dueDate, orderAppConfig, restaurant, classes }) => (
  <div className="u-marginBottom40">
    <div className="u-marginBottom10">
      <TypographyCustom type="145" component="p">
        {orderProperties.isDelivery ? (
          tr('order.global.delivery')
        ) : orderProperties.isOnSite ? (
          <React.Fragment>
            {tr('order.global.on_site')}
            {orderAppConfig.hasTableNumber && (
              <Fragment>
                &nbsp;-&nbsp;
                {tr('order.global.on_site_table_number', {
                  tableNumber: orderProperties.tableNumber,
                })}
              </Fragment>
            )}
          </React.Fragment>
        ) : (
          tr('order.global.take_away')
        )}
      </TypographyCustom>
      {orderProperties.isDelivery && (
        <React.Fragment>
          <TypographyCustom type="144" component="p" className={classes.grey500}>
            {orderProperties.deliveryAddress.formattedAddress}
          </TypographyCustom>
          {!isNil(orderProperties.deliveryAddress.complement) && (
            <TypographyCustom
              type="144"
              component="p"
              className={classNames(classes.grey500, classes.italic)}
            >
              {orderProperties.deliveryAddress.complement}
            </TypographyCustom>
          )}
        </React.Fragment>
      )}
    </div>
    {!orderProperties.isOnSite || !orderAppConfig.hasTableNumber ? (
      <React.Fragment>
        <div className="u-marginBottom10">
          <TypographyCustom type="145" component="p">
            {restaurant.name}
          </TypographyCustom>
          {!orderProperties.isDelivery && (
            <TypographyCustom
              type="124"
              component="p"
              className={classNames(classes.grey500, 'u-marginTop5')}
            >
              {restaurant.address.street}{' '}
              {`${restaurant.address.postCode} ${restaurant.address.city}`}
            </TypographyCustom>
          )}
        </div>
        <TypographyCustom type="126" component="p">
          {dueDate}
        </TypographyCustom>
      </React.Fragment>
    ) : (
      <TypographyCustom type="145" component="p" className={classes.grey500}>
        {tr('order.confirm.on_site.information')}
      </TypographyCustom>
    )}
  </div>
)

export default GeneralDatasCard
