import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import Hidden from '@material-ui/core/Hidden'

import { withStyles } from 'pmt-ui/styles'
import Card, { CardMedia } from 'pmt-ui/Card'
import { TypographyCustom } from 'pmt-ui/Typography'

import Button from '../Button'

const styles = (theme) => ({
  root: {
    cursor: 'pointer',
    overflow: 'initial',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px 10px 20px 10px',
    margin: '0 auto',
    position: 'relative',
    maxWidth: 370,
    boxShadow: '0px 1px 2px rgb(25 8 0 / 10%)',
    borderColor: 'rgba(25,8,0,.05)',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 23,
    '&:hover': {
      '& $image': {
        transform: 'scale(1.1)',
      },
      '& $imageMask': {
        opacity: 0.1,
      },
    },
  },
  imageContainer: {
    position: 'relative',
    aspectRatio: 1.75,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderWidth: theme.shape.border.width.thin,
    borderStyle: 'solid',
    borderColor: 'rgba(25,8,0,.05)',
    borderRadius: theme.shape.border.radius.itemCatalog - 5,
    transform: 'translateZ(0)',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      float: 'left',
    },
  },
  imageMask: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'black',
    opacity: 0,
    transition: 'opacity 0.35s ease',
  },
  image: {
    width: '100%',
    height: '100%',
    transition: 'transform 0.35s ease',
  },
  placeholder: {
    opacity: 0.3,
    width: 'auto',
    height: 'auto',
    maxWidth: 110,
    maxHeight: 110,
  },
  textContainer: {
    height: 160,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      height: 90,
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
  name: {
    width: '100%',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0`,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 30px)',
      height: 'auto',
      minHeight: 16,
      maxHeight: 35,
      marginTop: 0,
      padding: 0,
    },
  },
  categoryIcon: {
    width: 30,
    height: 30,
    color: theme.palette.primary.main,
  },
  buttonSee: {
    borderRadius: 10,
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  unavailableLayout: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.6)',
  },
  unavailableText: {
    color: theme.pmt.colors.white,
  },
  unavailableOpacity: {
    opacity: 0.6,
  },
  unavailableLabel: {
    position: 'absolute',
    top: -theme.spacing(1),
    right: theme.spacing(2),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    color: theme.pmt.colors.white,
    background: theme.pmt.colors.darkGrey,
    borderRadius: 3,
  },
})

const CategoryCard = ({ category, restaurant, isMobile, onClick, classes }) => {
  // category = {...category, image: 'https://picsum.photos/300/200'}
  return (
    <Card
      className={classes.root}
      onClick={(e) => {
        if (category.available) {
          onClick(e)
        }
      }}
    >
      <Hidden xsDown={isEmpty(category.image)}>
        <div className={classes.imageContainer}>
          {!isEmpty(category.image) ? (
            <React.Fragment>
              <CardMedia className={classes.image} image={category.image} title={category.name} />
              <div className={classes.imageMask}></div>
            </React.Fragment>
          ) : (
            <img
              src={restaurant.logo}
              alt={category.name}
              className={classNames(classes.image, classes.placeholder)}
            />
          )}
          {!category.available && (
            <div className={classes.unavailableLayout}>
              <TypographyCustom type="167" className={classes.unavailableText}>
                {tr('order.catalog.unavailable')}
              </TypographyCustom>
            </div>
          )}
        </div>
      </Hidden>
      <div
        className={classNames(classes.textContainer, {
          [classes.unavailableOpacity]: !category.available,
        })}
      >
        {!category.available && isMobile && (
          <TypographyCustom type="127" className={classes.unavailableLabel}>
            {tr('order.catalog.unavailable')}
          </TypographyCustom>
        )}
        <TypographyCustom type="167" align={'center'} className={classes.name}>
          {category.name}
        </TypographyCustom>
        <Button
          variant="outlined"
          label={tr('order.global.see')}
          className={classes.buttonSee}
          disabled={!category.available}
        />
      </div>
    </Card>
  )
}

export default withStyles(styles)(CategoryCard)
