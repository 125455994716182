import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Egg = props => (
  <SvgIcon {...props}>
    <path
      d="M3.4,14.458a5.778,5.778,0,0,1-1.768-1.444A7.19,7.19,0,0,1,0,8.406C0,3.928,2.6,0,5.567,0s5.568,3.928,5.568,8.406A7.188,7.188,0,0,1,9.5,13.013a5.773,5.773,0,0,1-1.769,1.444,4.6,4.6,0,0,1-4.335,0ZM.9,8.406c0,3.071,2.137,5.667,4.667,5.667s4.668-2.6,4.668-5.667C10.235,4.492,8.01.928,5.567.928S.9,4.492.9,8.406Zm2.323,3.2A5.052,5.052,0,0,1,2.1,8.405H3A4.1,4.1,0,0,0,3.9,11a2.235,2.235,0,0,0,1.662.911v.928A3.1,3.1,0,0,1,3.223,11.6Z"
      transform="translate(7 4)"
    />
  </SvgIcon>
)
Egg = pure(Egg)
Egg.displayName = 'Egg'
Egg.muiName = 'SvgIcon'

export default Egg
