import ApiEndpoints from '../config/ApiEndpoints'

const RestaurantsGroupApi = {
  getRestaurantsGroup: (restaurantsGroupId, options = {}) => {
    const headers = {}

    if (options.withAuthorization === false) {
      headers.Authorization = null
    }

    return {
      endpoint: ApiEndpoints.RESTAURANTS_GROUP,
      type: 'GET',
      headers,
      params: {
        restaurantsGroupId,
      },
      query: {
        loadChildren: options.loadChildren || false,
      },
    }
  },

  postRestaurantsGroup: body => ({
    endpoint: ApiEndpoints.RESTAURANTS_GROUPS,
    type: 'POST',
    body,
  }),

  searchUser: ({ body, params }) => {
    return {
      endpoint: ApiEndpoints.RESTAURANTS_GROUP_SEARCH_USERS,
      type: 'POST',
      params,
      body: {
        query: body.query,
        companyId: body.companyId,
      },
    }
  },

  putGroupStoreList: (groupId, storesIds = []) => ({
    endpoint: ApiEndpoints.RESTAURANTS_GROUP_RESTAURANTS,
    type: 'PUT',
    body: {
      restaurantsGroupId: groupId,
      restaurantsIds: storesIds,
    },
  }),

  putGroupGroupList: (groupId, childrenIds = []) => ({
    endpoint: ApiEndpoints.RESTAURANTS_GROUP_HIERARCHY,
    type: 'PUT',
    body: {
      parentRestaurantsGroupId: groupId,
      childrenIds,
    },
  }),

  patchGroup: (groupId, body) => ({
    endpoint: ApiEndpoints.RESTAURANTS_GROUP,
    type: 'PATCH',
    params: {
      restaurantsGroupId: groupId,
    },
    body,
  }),
}

export default RestaurantsGroupApi
