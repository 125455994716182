import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'

import { withForm, FormType } from 'pmt-modules/form'
import { getAllergensOptions } from 'pmt-modules/user/constants'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import ScrollableAnchor from 'pmt-ui/ScrollableAnchor'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import { emphasize } from 'pmt-utils/theme'

import Button from '../../../../components/Button'
import FlexBlock from '../../../../components/FlexBlock'

const styles = (theme) => ({
  container: {
    borderColor: theme.shape.border.color.default,
    borderWidth: theme.shape.border.width.default,
    borderStyle: 'solid',
    borderRadius: theme.shape.border.radius.secondaryContent,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      border: 'none',
      borderRadius: 0,
      paddingLeft: 0,
      paddingRight: 0,
      borderBottomWidth: theme.shape.border.width.default,
      borderBottomColor: theme.shape.border.color.default,
      borderBottomStyle: 'solid',
      marginBottom: '0px !important',
    },
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2.5),
    opacity: 0.8,
  },
  allergenWrapper: {
    overflow: 'hidden',
    maxWidth: 1000,
  },
  allergen: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '24%',
    marginBottom: theme.spacing(0.5),
    cursor: 'pointer',
    border: `2px solid ${theme.shape.border.color.default}`,
    borderRadius: 8,
    float: 'left',
    '&:nth-child(4n + 2), &:nth-child(4n + 3)': {
      marginLeft: '1%',
      marginRight: '1%',
    },
    [theme.breakpoints.down('md')]: {
      width: '49%',
      '&:nth-child(4n + 2), &:nth-child(4n + 3)': {
        marginLeft: 0,
        marginRight: 0,
      },
      '&:nth-child(even) ': {
        marginLeft: '2%',
      },
    },
  },
  allergenActive: {
    backgroundColor: emphasize(theme.palette.primary.main, 0.85),
    border: `2px solid ${emphasize(theme.palette.primary.main, 0.85)}`,
  },
  allergenIcon: {
    marginLeft: 5,
    width: 25,
    height: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.primary,
    '& svg': {
      fontSize: 25,
    },
  },
  submit: {
    float: 'right',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  },
})

class DietaryInfo extends React.PureComponent {
  toggleAllergen = (a) => {
    const formData = this.props.formData
    formData.allergens.indexOf(a) >= 0
      ? formData.allergens.splice(formData.allergens.indexOf(a), 1)
      : formData.allergens.push(a)

    this.props.onChange({ ...this.props.formData, ...formData })
  }

  hasChanges = () =>
    !isEqual(
      this.props.user.dietaryPreferences.allergens.sort(),
      this.props.formData.allergens.sort()
    )

  render() {
    const {
      anchor,
      classes,
      formData,
      formType,
      handleOnClickBlock,
      isFetchingPutUserMe,
      onSubmit,
      opened,
      submittedForm,
    } = this.props

    return (
      <ScrollableAnchor id={anchor}>
        <div>
          <FlexBlock
            isFlex
            classes={{ root: `u-marginBottom25 ${classes.container}` }}
            title={tr('order.account.dietary_info.title')}
            content={
              <React.Fragment>
                <TypographyCustom type="165" className={classes.subtitle}>
                  {tr('order.account.dietary_info.allergens.title')}
                </TypographyCustom>

                <LoadingBlockWrapper show={isFetchingPutUserMe && submittedForm === formType} />
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    onSubmit({
                      formData: {
                        dietaryPreferences: formData,
                      },
                      formType,
                    })
                  }}
                >
                  <div className={classes.allergenWrapper}>
                    {getAllergensOptions().map(
                      (a) =>
                        a.icon && (
                          <div
                            onClick={() => this.toggleAllergen(a.value)}
                            className={classNames(classes.allergen, {
                              [classes.allergenActive]: formData.allergens.indexOf(a.value) >= 0,
                            })}
                          >
                            <TypographyCustom type="146">{a.label}</TypographyCustom>
                            <div className={classes.allergenIcon}>{a.icon}</div>
                          </div>
                        )
                    )}
                  </div>
                  <Button
                    size="large"
                    type="submit"
                    label={tr('order.profile.personnal_informations.submit')}
                    classes={{ root: classes.submit }}
                    disabled={
                      (isFetchingPutUserMe && submittedForm === formType) || !this.hasChanges()
                    }
                  />
                </form>
              </React.Fragment>
            }
            handleOnClick={handleOnClickBlock}
            opened={opened}
          />
        </div>
      </ScrollableAnchor>
    )
  }
}

export default compose(withForm(FormType.USER_PROFILE_DIETARY), withStyles(styles))(DietaryInfo)
