import isEmpty from 'lodash/isEmpty'

import { createFormatter, createListFormatter } from 'pmt-utils/format'
import { formatPriceWithCurrency } from 'pmt-utils/currency'

const totalPriceFormatter = (orderProduct, props) => {
  // Note: we use || 0 just in the following cases:
  // - menu has broken null info
  // - unit tests does not give the data since it is not required for the test, but required here
  //   to format

  orderProduct.totalPriceFormatted = formatPriceWithCurrency(orderProduct.totalPrice || 0)

  orderProduct.totalPriceWithQuantity =
    // re-use current totalPriceWithQuantity: the order preview can change the totalPrice (for
    // example for promotion), we need to use the order preview data.
    orderProduct.totalPriceWithQuantity ||
    // in some unit tests contexts, the totalPriceWithQuantity has not been calculated yet
    (orderProduct.totalPrice || 0) * (orderProduct.quantity || 0)

  orderProduct.totalPriceWithQuantityFormatted = formatPriceWithCurrency(
    orderProduct.totalPriceWithQuantity || 0
  )
  orderProduct.additionalPriceFormatted = formatPriceWithCurrency(orderProduct.additionalPrice || 0)

  orderProduct.unitPriceFormatted = formatPriceWithCurrency(orderProduct.unitPrice || 0)

  orderProduct.basePriceFormatted = formatPriceWithCurrency(orderProduct.basePrice || 0)

  orderProduct.priceFormatted = formatPriceWithCurrency(orderProduct.price)

  let selectedOptions = []
  orderProduct.options.forEach(option => {
    let selectedOption = {}
    let optionValues = []
    option.values.forEach(value => {
      if (value.quantity > 0) {
        optionValues.push(value)
      }
    })

    if (!isEmpty(optionValues)) {
      selectedOption.values = optionValues
      selectedOptions.push(selectedOption)
    }
  })

  orderProduct.optionsSelected = selectedOptions

  return orderProduct
}

export const orderProductFormatter = createFormatter(totalPriceFormatter)

export const orderProductsFormatter = createListFormatter(orderProductFormatter)
