import find from 'lodash/find'
import isNil from 'lodash/isNil'
import isFunction from 'lodash/isFunction'
import invariant from 'invariant'

let _searches = []
export const registerSearches = (searches) => {
  _searches = searches
}

export const getSearches = (searches) => (
  _searches
)

/**
 * @param  {string} searchType SearchType of the search to retrive
 * @return {SearchConfiguration} the configuration for the given SearchType
 */
export const getSearch = (searchType) => {
  const search = find(_searches, (search) => searchType === search.type)

  invariant(!isNil(search), 'search ' + searchType + ' not registered')

  return search
}

export const getFormatter = (searchType) => {
  const search = getSearch(searchType)

  const formatter = search.options.formatter

  invariant((isNil(formatter) || isFunction(formatter)), 'search ' + searchType + ' has no formatter')

  return formatter
}

export const getSearchApiCallFunc = (searchType) => {
  const search = getSearch(searchType)

  const apiCallFunc = search.apiCallFunc
  invariant(isFunction(apiCallFunc), 'search ' + searchType + ' has no apiCallFunc')

  return apiCallFunc
}
