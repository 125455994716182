import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'

import { resetOrderFrontFull } from 'pmt-modules/orderFront'
import { OrderPropertiesContainer, hideOrderModeDisabledDialog } from 'pmt-modules/orderPlugin'

import { makeStyles } from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'

import Button from 'app/components/Button'

import { TypographyCustom } from 'pmt-ui/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dialogTitle: {
    padding: 0,
    marginBottom: theme.spacing(3),
  },
}))

const OrderModeDisabledDialog = ({ hideOrderModeDisabledDialog, resetOrderFrontFull }) => {
  const classes = useStyles()

  return (
    <OrderPropertiesContainer>
      {({ orderProperties }) => (
        <div className={classes.root}>
          <DialogTitle classes={{ root: classes.dialogTitle }}>
            <TypographyCustom type="226">{tr('order.mode_disabled.dialog.title')}</TypographyCustom>
            <TypographyCustom type="164" className="u-marginTop5">
              {tr(`order.mode_disabled.dialog.content.mode.${orderProperties.mode}`)}
            </TypographyCustom>
          </DialogTitle>
          <DialogActions>
            <Button
              label={tr('order.mode_disabled.dialog.redirect')}
              onClick={() => {
                hideOrderModeDisabledDialog()
              }}
            />
          </DialogActions>
        </div>
      )}
    </OrderPropertiesContainer>
  )
}

// Dialog root properties
OrderModeDisabledDialog.DialogRootProps = {
  // we want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, { hideOrderModeDisabledDialog, resetOrderFrontFull })(
  OrderModeDisabledDialog
)
