import isNil from 'lodash/isNil'
import invariant from 'invariant'

import createMiddleware from 'pmt-modules/redux/createMiddleware'
import { EventManager } from 'pmt-modules/event'
import { getCurrentTheme, changeCurrentTheme } from 'pmt-modules/theme'

import Logger from 'pmt-utils/logger'
import { createMuiThemeWithPmtRGroupColors } from 'pmt-utils/theme/theme'

import { GetAppConfigAction } from './actions'
import { mergeAppConfigWithDefault } from './utils'

const getAppConfigSuccess = createMiddleware(
  GetAppConfigAction.SUCCESS,
  ({ action, getState, dispatch }) => {
    const appConfig = mergeAppConfigWithDefault(action.response.settings)

    EventManager.dispatch(EventManager.Events.ON_GET_APP_CONFIG_SUCCESS, {
      appConfig,
      // Note: we could send the appConfig without the default (the one given by the API)
    })

    // register kioskInteractor module regarding what has been set in app config
    if (process.env.FEATURE_KIOSK) {
      if (!isNil(appConfig.front.kiosk)) {
        const registerKioskInteractor = require('pmt-modules/kioskInteractor/config')
          .registerKioskInteractor
        registerKioskInteractor(appConfig.front.kiosk)
      }
    }

    if (action.response.settings && action.response.settings.front) {
      const frontSettings = action.response.settings.front
      if (frontSettings && !isNil(frontSettings.theme)) {
        const currentTheme = getCurrentTheme(getState())
        const newTheme = createMuiThemeWithPmtRGroupColors(currentTheme, frontSettings.theme)

        // if apiConsumer setting has extra custom css, we spread it in theme.pmt.appConfig
        // this will then be used inside components
        // (e.g. PartView title stickyness on custom integration with fixed header)
        if (!isNil(frontSettings.theme.customCss)) {
          invariant(!isNil(newTheme.pmt.appConfig), 'theme.pmt.appConfig should be defined')

          newTheme.pmt.appConfig = {
            ...newTheme.pmt.appConfig,
            ...frontSettings.theme.customCss,
          }
        }

        dispatch(changeCurrentTheme(newTheme))
      } else {
        Logger.warn(
          'ApiConsumer settings theme',
          `no theme found for api consumer ${action.response.name} ${action.response.id}`
        )
      }
    }
  }
)

export const appConfigMiddlewares = [getAppConfigSuccess]
