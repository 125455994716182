import { LOCATION_CHANGE } from 'pmt-modules/routing'

import Immutable from 'immutable'

import { getDefaultBasicToken } from './utils'

import { AuthProAction } from './actions'

export * from './utils'
export * from './actions'
export * from './selectors'
export * from './middlewares'

const DEFAULT = Immutable.fromJS({
  // will contains the oauth2 AccessToken
  // the mobileApp can pass an AccessToken
  auth: getDefaultBasicToken(),
  isFetching: false,
  error: null,

  // for Refresh.
  refreshSucceded: false,
})

export const authProReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge({
        error: null,
      })

    case AuthProAction.REQUEST:
      return state.merge({
        isFetching: true,
        auth: null,
        error: null,
      })

    case AuthProAction.SUCCESS:
      return state.merge({
        isFetching: false,
        auth: action.data.authorization,
        refreshSucceded: true,
        error: null,
      })

    case AuthProAction.FAILURE:
      return state.merge({
        isFetching: false,
        auth: null,
        error: action.error,
      })

    default:
      return state
  }
}
