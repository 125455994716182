import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { ForgotPasswordFormView } from 'pmt-modules/forgotPassword/forms/forgotPassword'
import { Link, getRoute } from 'pmt-modules/routing'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import Grid from 'pmt-ui/Grid'
import LockOutline from 'pmt-ui/svg-icons/action/lock-outline'

import BlockContainer from '../../components/BlockContainer'
import Button from '../../components/Button'
import MessageBlock from '../../components/MessageBlock'

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
    padding: `${theme.spacing(2)}px 0`,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  mainContainer: {
    borderRadius: theme.shape.border.radius.main,
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
    },
  },
  caption: {
    color: theme.pmt.status.inactive,
    margin: `${theme.spacing(2)}px 0`,
    lineHeight: '18px',
  },
  error: {
    margin: `${theme.spacing(2)}px 0`,
  },
  link: {
    display: 'block',
    margin: `${theme.spacing(8)}px 0`,
    color: theme.palette.primary.light,
  },
  lockIcon: {
    width: 118,
    height: 118,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  success: {
    margin: `${theme.spacing(7)}px 0`,
  },
})

const View = ({
  classes,
  isFetching,
  formIsValid,
  forgotPasswordData,
  forgotPasswordError,
  onSubmit,
}) => (
  <div className={classes.root}>
    <BlockContainer
      className={`u-relative ${classes.mainContainer}`}
      type={BlockContainer.Type.WHITE}
    >
      <LoadingBlockWrapper show={isFetching} />

      <Grid spacing={2} container justify="center">
        <Grid item xs={10} sm={8} md={4} lg={3}>
          <LockOutline className={classes.lockIcon} />

          <TypographyCustom align="center" type="226">
            {tr('order.forgot_password.title')}
          </TypographyCustom>

          {forgotPasswordData ? (
            <div className={classes.success}>
              <TypographyCustom align="center" type="164">
                {tr('order.forgot_password.success')}
              </TypographyCustom>
              <TypographyCustom align="center" type="167">
                {forgotPasswordData.username}
              </TypographyCustom>
            </div>
          ) : (
            <React.Fragment>
              <div className={classes.caption}>
                <TypographyCustom skipColor className="u-lineHeight18" align="center" type="144">
                  {tr('order.forgot_password.no_worries.part1')}
                </TypographyCustom>
                <TypographyCustom skipColor className="u-lineHeight18" align="center" type="144">
                  {tr('order.forgot_password.no_worries.part2')}
                </TypographyCustom>
              </div>

              <div className={classes.error}>
                <ErrorBlock
                  error={forgotPasswordError}
                  mode={ErrorBlock.Mode.CUSTOM}
                  customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
                />
              </div>

              <form
                onSubmit={(event) => {
                  event.preventDefault()
                  onSubmit()
                }}
              >
                <ForgotPasswordFormView />
                <Button
                  size="large"
                  type="submit"
                  label={tr('order.forgot_password.submit')}
                  disabled={!formIsValid || isFetching}
                  classes={{ root: classes.submit }}
                />
              </form>
            </React.Fragment>
          )}

          <Link className={classes.link} to={getRoute('LOGIN')}>
            {tr('order.forgot_password.back_to_login')}
          </Link>
        </Grid>
      </Grid>
    </BlockContainer>
  </div>
)

export default withStyles(styles)(View)
