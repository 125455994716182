import Logger from 'pmt-utils/logger'
import { MINUTE_AS_MS } from 'pmt-utils/date'

import { DeliveryTimeType } from '../constants'

export const getDeliveryTime = (deliverySettings, deliveryDistanceTime = MINUTE_AS_MS * 15) => {
  switch (deliverySettings.deliveryTimeSettings.settingsType) {
    case DeliveryTimeType.FIXED:
      return deliverySettings.deliveryTimeSettings.fixTimeSettings.time

    case DeliveryTimeType.ADAPTABLE:
      return (
        deliverySettings.deliveryTimeSettings.adaptableTimeSettings.deliveryManArrivalDuration +
        deliveryDistanceTime
      )

    default:
      Logger.warn(
        'DELIVERY_TIME_ERROR',
        `DeliveryTimeType is not known from us`,
        deliverySettings.deliveryTimeSettings.settingsType
      )
      return deliverySettings.deliveryTimeSettings.fixTimeSettings.time || 60
  }
}
