import isEmpty from 'lodash/isEmpty'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import forEach from 'lodash/forEach'
import sortBy from 'lodash/sortBy'
import isNil from 'lodash/isNil'

import { createFormatter, createListFormatter, createDeepFormatter } from 'pmt-utils/format'

import { FrontCateringHistoryType, GroupType } from '../constants'

export const formatLevel = rGroup => {
  rGroup.isFirstLevel = rGroup.level === 1
  rGroup.hasChildren = !isEmpty(rGroup.childrenIds)

  return rGroup
}

export const formatType = rGroup => {
  rGroup.isTypeUser = rGroup.type === GroupType.USER
  rGroup.isTypeOther = rGroup.type === GroupType.OTHER

  return rGroup
}

export const formatFrontSettings = rGroup => {
  rGroup.frontSettings = rGroup.frontSettings || {}

  // catering
  rGroup.frontSettings.catering = rGroup.frontSettings.catering || {}

  // catering history type
  rGroup.frontSettings.catering.historyType =
    rGroup.frontSettings.catering.historyType || FrontCateringHistoryType.PAY_MY_TABLE
  rGroup.frontSettings.catering.isHistoryTypePayMyTable =
    FrontCateringHistoryType.PAY_MY_TABLE === rGroup.frontSettings.catering.historyType
  rGroup.frontSettings.catering.isHistoryTypeThirdParty =
    FrontCateringHistoryType.THIRD_PARTY === rGroup.frontSettings.catering.historyType

  return rGroup
}

export const sortSubRestaurantsGroups = rGroup => {
  if (!isNil(rGroup.children)) {
    rGroup.children = sortBy(rGroup.children, [rGroup => rGroup.name.toLowerCase()], ['desc'])
  }

  return rGroup
}

export const formatForgottenPasswordLink = rGroup => {
  rGroup.forgottenPasswordLink =
    !isNil(rGroup.userSettings) &&
    !isNil(rGroup.userSettings.managementSettings) &&
    !isNil(rGroup.userSettings.managementSettings.passwordRecoveryUrl)
      ? rGroup.userSettings.managementSettings.passwordRecoveryUrl
      : null

  return rGroup
}

const formatChildrenRestaurants = rGroup => {
  let list = []
  forEach(rGroup.children || [], child => {
    list.push(child.restaurantsIds)
  })
  list = uniq(flatten(list))

  if (!rGroup.restaurantsIds) {
    debugger
  }
  rGroup.ownedRestaurantsIds = rGroup.restaurantsIds.filter(id => list.indexOf(id) < 0)

  rGroup.hasRestaurants = !isEmpty(rGroup.restaurantsIds)

  return rGroup
}

export const formatRestaurantsGroup = createFormatter(
  formatLevel,
  formatType,
  sortSubRestaurantsGroups,
  formatForgottenPasswordLink,
  formatFrontSettings,
  formatChildrenRestaurants
)

export const formatRestaurantsGroupList = createListFormatter(formatRestaurantsGroup)

export const formatRestaurantsGroupWithChildren = createDeepFormatter(
  formatRestaurantsGroup,
  'children'
)
