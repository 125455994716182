//
// Listing of possible payment errors.
//

const PaymentAsyncErrors = {
  200: 'global.post_payment.error.200',
  201: 'global.post_payment.error.201',
  202: 'global.post_payment.error.202',
  203: 'global.post_payment.error.203',
}

const PaymentErrorsList = {
  101: 'global.post_payment.error.101',
  106: 'global.post_payment.error.106',
  107: 'global.post_payment.error.107',
  109: 'global.post_payment.error.109',
  110: 'global.post_payment.error.110',
  112: 'global.post_payment.error.112',
  113: 'global.post_payment.error.113',
  115: 'global.post_payment.error.115',
  116: 'global.post_payment.error.116',
  117: 'global.post_payment.error.117',
  118: 'global.post_payment.error.118',
  119: 'global.post_payment.error.119',
  121: 'global.post_payment.error.121',
  124: 'global.post_payment.error.124',
  401: 'global.post_payment.error.401',
  411: 'global.post_payment.error.411',

  // user canceled async payment
  // TODO: change i18n, this is not a 3DS in some cases: payment via iframe (TRD)
  499: 'global.post_payment.error.custom.499',

  ...PaymentAsyncErrors,
  default: 'global.post_payment.error.default',
}

export const PaymentPdfErrorsList = {
  ...PaymentErrorsList,
  default: 'global.get_payment_pdf.error.default',
}

export default PaymentErrorsList
