import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Fish = props => (
  <SvgIcon {...props}>
    <path
      d="M15,3.58l0-.441-.441-.012c-.1,0-.2,0-.3,0A11.015,11.015,0,0,0,7.665,5.3a2.571,2.571,0,0,0-3.384.221l-.324.324L5.511,7.395a11.017,11.017,0,0,0-1.533,2.653,10.874,10.874,0,0,0-.778,3.4,2.574,2.574,0,0,0-2.876.526L0,14.3l3.637,3.637.324-.324a2.575,2.575,0,0,0,.53-2.865,10.923,10.923,0,0,0,3.55-.786,11.05,11.05,0,0,0,2.57-1.464l1.676,1.676.324-.324a2.57,2.57,0,0,0,.137-3.488,10.976,10.976,0,0,0,1.446-2.532A10.914,10.914,0,0,0,15,3.58ZM5.3,5.886A1.659,1.659,0,0,1,6.921,5.9q-.22.194-.431.4t-.384.4ZM3.591,16.591l-2.25-2.25a1.656,1.656,0,0,1,2.25,2.25ZM4.1,13.84a10.036,10.036,0,0,1,3.033-6.89A10.31,10.31,0,0,1,8.95,5.531a5.929,5.929,0,0,0,3.536,3.606,10.341,10.341,0,0,1-1.445,1.771A10.1,10.1,0,0,1,4.1,13.84Zm8.349-1.813a1.647,1.647,0,0,1-.206.8l-.923-.923q.186-.167.366-.344.24-.236.464-.484A1.643,1.643,0,0,1,12.447,12.027Zm.509-3.7a5.011,5.011,0,0,1-3.189-3.25,10.106,10.106,0,0,1,4.312-1.041A9.976,9.976,0,0,1,12.955,8.331Z"
      transform="translate(4.623 1.751)"
    />
  </SvgIcon>
)
Fish = pure(Fish)
Fish.displayName = 'Fish'
Fish.muiName = 'SvgIcon'

export default Fish
