export const UnavailabilityReason = {
  ORDER_MODE: 'Order mode not available for this item',
  OPENING_HOURS: 'Opening hours not available for this item',
  AVAILABILITY_DATES: 'Dates not available for this item',
  PRODUCTS_NOT_AVAILABLE_FOR_MENU_PART: "Menu's part is not available",
  STOCK: 'Item has no stock',
  EMPTY: 'Category is empty of product / menu / subcategory',
  EMPTY_SUBCATEGORIES: 'Category only contains empty subcategories',
  ALL_ITEMS_SHOULD_NOT_BE_DISPLAYED: 'All items should not be displayed',
  ONE_MANDATORY_OPTION_NOT_AVAILABLE: "One product's mandatory option is not available",
}

export const CatalogItemType = {
  CATEGORY: 0,
  MENU: 1,
  PRODUCT: 2,
}

/**
 * Defines all the possible type values for a CartModifier's action
 */
export const CartModifierActionType = {
  DISCOUNT_AMOUNT: 'DISCOUNT_AMOUNT',
  DISCOUNT_PERCENTAGE: 'DISCOUNT_PERCENTAGE',
}

export const CartModifierTargetType = {
  /**
   * a list of products, menus and/or categories
   */
  SPECIFIC_ITEMS: 'SPECIFIC_ITEMS',

  /**
   * all but a list of products, menus and/or categories
   */
  ALL_ITEMS_EXCEPT: 'ALL_ITEMS_EXCEPT',

  /**
   * on the shipping cost
   */
  SHIPPING_COST: 'SHIPPING_COST',

  /**
   * on the total of the cart
   */
  TOTAL: 'TOTAL',
}
