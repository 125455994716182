import forEach from 'lodash/forEach'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { stopIterationValues } from '../constants'

const containsStopIteration = object => {
  let _return = false

  forEach(object, (_obj, key) => {
    if (stopIterationValues.indexOf(key) !== -1) {
      _return = key
    }
  })

  return _return
}

export const reduceAppConfigTexts = (texts, locale) => {
  const _locale = locale

  const recursiveCall = object => {
    let finalObject = object

    for (let key in object) {
      const _obj = object[key]
      const hasKey = containsStopIteration(_obj)

      // n'a pas de fr ou en
      if (!hasKey) {
        // objet n'est pas vide
        if (size(_obj) > 0) {
          finalObject = { ...finalObject, [key]: recursiveCall(_obj) }

          // objet vide
        } else {
          finalObject = { ...finalObject, [key]: null }
        }

        // fr ou en présent
      } else {
        // variable existe avec la locale
        if (!isUndefined(_obj[_locale])) {
          finalObject = { ...finalObject, [key]: _obj[_locale] }

          // variable n'existe pas
        } else {
          finalObject = { ...finalObject, [key]: null }
        }
      }
    }

    return finalObject
  }

  return recursiveCall(texts)
}

export const formatTexts = (texts, locale) => reduceAppConfigTexts(texts, locale)
