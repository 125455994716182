import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { setTableNumber } from 'pmt-modules/orderPlugin'

import NumberField from 'pmt-ui/NumberField'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import Button from '../Button'

const styles = (theme) => ({
  inputTable: {},
  errorMessage: {
    color: theme.pmt.colors.red800,
  },
  closeButton: {
    borderRadius: 10,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      borderColor: theme.palette.primary.dark,
    },
  },
})

const Type = {
  BIG: 'big',
  SMALL: 'small',
}

class TableNumberInput extends React.PureComponent {
  handleSetTableNumber = (tableNumber) => {
    this.props.setTableNumber(tableNumber)
  }

  render() {
    const { tableNumber, type, handleCloseInput, displayError, classes } = this.props

    return (
      <React.Fragment>
        <TypographyCustom type="204">
          <NumberField
            value={!isNil(tableNumber) ? tableNumber : ''}
            max={999}
            onChange={(event, value) => {
              this.handleSetTableNumber(value)
            }}
            label={tr('order.store_locator.table_number.label')}
            placeholder={tr('order.store_locator.table_number.placeholder')}
            classes={{ textField: classes.inputTable }}
            helperText={tr('order.store_locator.table_number.example')}
          />
        </TypographyCustom>
        {isNil(tableNumber) && displayError && (
          <TypographyCustom
            type="164"
            className={classNames('u-marginTop20', classes.errorMessage)}
          >
            {tr('order.error_message.tableNumber_missing')}
          </TypographyCustom>
        )}
        {type === Type.SMALL && (
          <Button
            classes={{ root: classNames(classes.closeButton, 'u-marginTop10') }}
            disabled={isNil(tableNumber)}
            onClick={handleCloseInput}
            label={tr('order.global.close')}
          />
        )}
      </React.Fragment>
    )
  }
}

TableNumberInput.defaultProps = {
  type: Type.BIG,
  handleCloseInput: null,
  displayError: false,
}

const mapStateToProps = (state, props) => ({})
const TableNumberInputContainer = compose(
  connect(mapStateToProps, { setTableNumber }),
  withStyles(styles)
)(TableNumberInput)
TableNumberInputContainer.Type = Type

export default TableNumberInputContainer
