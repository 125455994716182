import each from 'lodash/each'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

export const recursiveFindItemFromCategory = (category, id, type) => {
  let arrayItem = []

  if (!category) {
    return arrayItem
  }

  if (category.items) {
    arrayItem = category.items.filter(item => item.id === id && item.type === type)
  }

  if (isEmpty(arrayItem)) {
    if (category.categories) {
      let foundFromSubcategories = null
      each(category.categories, category => {
        foundFromSubcategories = recursiveFindItemFromCategory(category, id, type)
        if (!isNil(foundFromSubcategories)) {
          return false
        }
      })

      if (!isNil(foundFromSubcategories)) {
        return foundFromSubcategories
      }
    } else {
      return null
    }
  } else {
    return arrayItem.pop()
  }
}

export const findItemFromSuggestion = (suggestion, id, type) => {
  const arrayItem = suggestion.suggestedItems.filter(suggestedItem => suggestedItem.id === id && suggestedItem.type === type)

  return isEmpty(arrayItem) ? null : arrayItem.pop()
}
