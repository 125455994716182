import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { withAppConfig } from 'pmt-modules/appConfig'
import { resetOrderFrontFull } from 'pmt-modules/orderFront'
import {
  fromApp,
  getAppConfigFrontSettings,
  OrderPluginUrlCheckerContainer,
  resetOrderPlugin,
  withOrderProperties,
} from 'pmt-modules/orderPlugin'
import { getDataOrderPost } from 'pmt-modules/orderPost'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { redirectTo, redirectToExternal, getRoute } from 'pmt-modules/routing'
import { withUserMe } from 'pmt-modules/userMe'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'
import PaymentAsyncRedirectTutorialDialog from "pmt-ui/PaymentAsyncRedirectTutorialDialog"

import OrderPage from '../../../components/OrderPage'

/**
 * @specs N/A
 */
@withAppConfig
@withOrderProperties
@withUserMe
@withRestaurant
@withScrollToTop()
class AsyncFormPage extends React.Component {
  constructor(props) {
    super(props)

    // SECURITY
    // if the order data is null, it means that one mandatory information
    // to access the payment page is missing
    if (isNil(props.orderData)) {
      props.resetOrderFrontFull()
      if (this.hasRedirectUriDefined() && this.props.from === fromApp.WEBAPP) {
        window.location.href = props.frontSettings.redirectUri
      } else {
        props.resetOrderPlugin()
        props.redirectTo(getRoute('ORDER__STORE_LOCATOR'))
      }
    }

    this.form = React.createRef()
  }

  hasRedirectUriDefined = () => this.props.frontSettings && this.props.frontSettings.redirectUri

  render() {
    const { restaurant, location, redirectToExternal, orderProperties, orderData } = this.props

    return (
      orderData && (
        <OrderPage orderProperties={orderProperties} restaurant={restaurant} location={location}>
          <div style={{ minHeight: '100%', height: '100%' }}>
            <LoadingBlockWrapper show />

            {orderData.payment.asyncPaymentData.httpMethod === 'POST' && (
              <OrderPluginUrlCheckerContainer location={location} verifyMode>
                <form
                  method="POST"
                  action={orderData.payment.asyncPaymentData.pspRedirectUrl}
                  ref={this.form}
                >
                  {Object.keys(orderData.payment.asyncPaymentData.fields || []).map((key) => (
                    <input
                      key={key}
                      type="hidden"
                      name={key}
                      value={orderData.payment.asyncPaymentData.fields[key]}
                    />
                  ))}
                </form>
              </OrderPluginUrlCheckerContainer>
            )}
          </div>
          <PaymentAsyncRedirectTutorialDialog 
            onSubmit={() => {
              switch (orderData.payment.asyncPaymentData.httpMethod) {

                case 'GET':
                  redirectToExternal(orderData.payment.asyncPaymentData.pspRedirectUrl)
                  break

                case 'POST':
                  orderData && this.form.current.submit()
                  break

                default:
              }
            }}
            label1={restaurant.isPspMangoPay ? tr('PaymentAsyncRedirectTutorialDialog.explain.3ds.1') : tr('PaymentAsyncRedirectTutorialDialog.explain.redirection.1')}
            label2={restaurant.isPspMangoPay ? tr('PaymentAsyncRedirectTutorialDialog.explain.3ds.2') : tr('PaymentAsyncRedirectTutorialDialog.explain.redirection.2')}
          />
        </OrderPage>
      )
    )
  }
}

const mapStateToProps = (state) => ({
  frontSettings: getAppConfigFrontSettings(state),
  orderData: getDataOrderPost(state),
})

export default connect(mapStateToProps, {
  resetOrderFrontFull,
  resetOrderPlugin,
  redirectTo,
  redirectToExternal,
})(AsyncFormPage)
