import Immutable from 'immutable'

import { GetCatalogAction, CatalogAction, RefreshCatalogAction } from './actions'

export * from './actions'
export * from './constants'
export * from './components'
export * from './format'
export * from './selectors'
export * from './utils'

const DEFAULT = Immutable.fromJS({
  /**
   * each order mode has its own catalog, that should look like this
   * [mode]: {
   *  data: null,
   *  isFetching: false,
   *  error: null,
   *  locale: null,
   * }
   */
  // handle category movements
  selectedCategory: null,
  parentCategories: null,
})

export const catalogReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case GetCatalogAction.REQUEST:
      return state.mergeIn([String(action.data.mode)], {
        data: null,
        isFetching: true,
        lastUpdated: null,
        error: null,
        locale: action.data.locale,
      })

    case RefreshCatalogAction.SUCCESS:
    case GetCatalogAction.SUCCESS:
      return state.mergeIn([String(action.data.mode)], {
        data: Immutable.fromJS(action.response),
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
        locale: action.data.locale,
      })

    case RefreshCatalogAction.FAILURE:
    case GetCatalogAction.FAILURE:
      return state.mergeIn([String(action.data.mode)], {
        data: null,
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
        locale: action.data.locale,
      })

    case GetCatalogAction.RESET:
      return state.set(DEFAULT)

    case RefreshCatalogAction.REQUEST:
      return state
        .mergeIn([String(action.data.mode)], { isFetching: true })
        .mergeIn([String(action.data.mode)], { locale: action.data.locale })

    case CatalogAction.SET_SELECTED_CATEGORY:
      return state.merge({
        selectedCategory: action.category || null,
      })

    case CatalogAction.SET_PARENT_CATEGORIES:
      return state.merge({
        parentCategories: action.parentCategories || [],
      })

    default:
      return state
  }
}
