import { createApiEnumAction } from 'pmt-modules/api/utils'
import { getPublicUrl } from 'pmt-modules/environment'
import { getStore } from 'pmt-modules/store'
import { createAction } from '../redux'

export const I18nFetchingLocale = createApiEnumAction('I18n::FETCHING_LOCALE')

export const retrieveMessages = languageParam => {
  const locale = languageParam === 'debug' ? 'en' : languageParam
  const url = `${getPublicUrl()}/i18n/${locale}.json`

  getStore().dispatch({
    type: I18nFetchingLocale.REQUEST,
    locale: locale,
  })

  fetch(url, {
    method: 'get',
  })
    .then(response => {
      response.text().then(text => {
        if (response.ok) {
          getStore().dispatch({
            type: I18nFetchingLocale.SUCCESS,
            locale: locale,
            phrases: JSON.parse(text),
          })
        } else {
          getStore().dispatch({
            type: I18nFetchingLocale.FAILURE,
            locale: locale,
          })
        }
      })
    })
    .catch(err => {
      getStore().dispatch({
        type: I18nFetchingLocale.FAILURE,
        locale: locale,
      })
    })
}

//
//
//

export const CHOOSE_LOCALE = 'I18n::CHOOSE_LOCALE'

export const chooseLocale = locale => createAction(CHOOSE_LOCALE, { locale })
