import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { updateUI } from './actions'
import { getUi } from './selectors'
import { generateKeyForComponent } from './utils'

class UiContainer extends React.PureComponent {
  constructor(props) {
    super(props)

    this.uiState = {}
    this.key = generateKeyForComponent(props.WrappedComponent)
  }

  componentWillMount() {
    let state = {
      ...this.props.uiState.state,
    }

    Object.keys(state).forEach(k => {
      if (typeof state[k] === 'function') {
        state[k] = state[k](this.props)
      }
    })

    this.props.updateUI(this.key, state)
  }

  componentWillReceiveProps({ ui, uiState }) {
    ui = get(ui, this.key, {})
    this.uiState = {
      ...uiState.state,
      ...ui,
    }
  }

  updateUI = state => {
    this.props.updateUI(this.key, state)
  }

  render() {
    const { WrappedComponent, ...otherProps } = this.props

    return <WrappedComponent {...otherProps} ui={this.uiState} updateUI={this.updateUI} />
  }
}

const mapStateToProps = state => ({
  ui: getUi(state),
})

export default connect(
  mapStateToProps,
  {
    updateUI,
  }
)(UiContainer)
