import React from 'react'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { tr } from 'pmt-modules/i18n'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import Grid from 'pmt-ui/Grid'
import Hidden from 'pmt-ui/Hidden'
import { withStyles } from 'pmt-ui/styles'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'

import BlockContainer from '../../components/BlockContainer'
import ErrorLayout from '../../components/OrderPage/components/ErrorLayout'
import ErrorBlock from 'pmt-ui/ErrorBlock'

import GeneralDatasCard from './components/GeneralDatasCard'
import PaymentDatasCard from './components/PaymentDatasCard'
import Button, { ButtonLink } from '../../components/Button'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

const styles = (theme) => ({
  blockContainer: {
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(2),
    borderRadius: theme.shape.border.radius.secondaryContent,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderRadius: 0,
    },
  },
  customConfirmTitle: {
    color: theme.palette.primary.main,
    margin: theme.spacing(3, 0),
  },
  paddingTopBig: {
    paddingTop: theme.spacing(20),
  },
  maxWidthContainer: {
    maxWidth: 340,
    margin: '0 auto',
  },
  cardContent: {
    padding: `${theme.spacing(2)}px !important`,
  },
  icon: {
    float: 'left',
    width: 18,
    marginRight: 10,
  },
  iconGreen: {
    color: theme.pmt.colors.green800,
  },
  iconWarning: {
    color: theme.pmt.status.warning,
  },
  iconText: {
    float: 'left',
    width: 'calc(100% - 28px)',
    lineHeight: '24px',
  },
  restaurantLogo: {
    maxWidth: 180,
    borderWidth: theme.shape.border.width.thin,
    borderStyle: 'solid',
    borderColor: theme.shape.border.color.default,
    borderRadius: theme.shape.border.radius.content,
  },
  hr: {
    border: 0,
    borderBottom: `1px solid ${theme.shape.border.color.default}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  italic: {
    fontStyle: 'italic',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  mainColor: {
    color: theme.palette.text.primary,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  return: {
    marginTop: theme.spacing(6),
    textAlign: 'center',
  },
  downloadBtn: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  containerDetailOrder: {
    borderWidth: theme.shape.border.width.thin,
    borderStyle: 'solid',
    borderColor: theme.shape.border.color.default,
    borderRadius: theme.shape.border.radius.content,
    padding: theme.spacing(3),
    position: 'relative',
    marginTop: 15,
  },
  iconCloseDetail: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
})

const View = ({
  isIncognito,
  appConfigPreset,
  classes,
  frontSettings,
  isFetchingRestaurant,
  paymentPdfLink,
  errorPaymentPdfLink,
  orderAppConfig,
  postedOrderData,
  restaurant,
  onRedirect,
  route,
  isFromWebApp,
}) => {
  const tmpStrReplace = (str) =>
    // eslint-disable-next-line no-template-curly-in-string
    str.replace('${restaurant.phonePub}', postedOrderData.orderProperties.restaurant?.phonePub)

  return (
    <Grid spacing={5} className={classes.view} container justify="center">
      <Hidden smDown>
        <Grid item md={8}>
          <BlockContainer
            className={classNames(classes.blockContainer, 'u-flexColumn', 'u-flexCenter')}
          >
            {isFetchingRestaurant || isNil(restaurant) ? (
              <LoadingBlockWrapper show />
            ) : (
              <React.Fragment>
                {restaurant.logo && (
                  <img
                    src={`${restaurant.logo}`}
                    className={classes.restaurantLogo}
                    alt={`${restaurant.name}`}
                  />
                )}
                <TypographyCustom
                  component="p"
                  type="226"
                  align="center"
                  className={classNames('u-marginTop10 u-marginBottom20')}
                >
                  {tr('order.global.header.title')}
                </TypographyCustom>
                <TypographyCustom component="p" type="306" align="center">
                  {restaurant.name}
                </TypographyCustom>
              </React.Fragment>
            )}
          </BlockContainer>
        </Grid>
      </Hidden>
      <Grid item xs={12} md={4}>
        <BlockContainer className={classes.blockContainer}>
          <Grid spacing={0} container xs={12}>
            <Grid item xs={12}>
              <TypographyCustom type="226" className="u-marginBottom20">
                {tr('order.confirm.title')}
                &nbsp;!
              </TypographyCustom>

              <CustomTextsContainer>
                {({ texts }) =>
                  !isNil(texts) &&
                  ((!isNil(texts.order_ON_SITE_instructions) &&
                    postedOrderData.orderProperties.isOnSite) ||
                    (!isNil(texts.order_TAKE_AWAY_instructions) &&
                      postedOrderData.orderProperties.isTakeAway) ||
                    (!isNil(texts.order_DELIVERY_instructions) &&
                      postedOrderData.orderProperties.isDelivery)) && (
                    <TypographyCustom
                      type="167"
                      color="primary"
                      className={classes.customConfirmTitle}
                    >
                      {postedOrderData.orderProperties.isOnSite &&
                        tmpStrReplace(texts.order_ON_SITE_instructions)}
                      {postedOrderData.orderProperties.isTakeAway &&
                        tmpStrReplace(texts.order_TAKE_AWAY_instructions)}
                      {postedOrderData.orderProperties.isDelivery &&
                        tmpStrReplace(texts.order_DELIVERY_instructions)}
                    </TypographyCustom>
                  )
                }
              </CustomTextsContainer>

              {isFetchingRestaurant ? (
                <div className="u-relative">
                  <LoadingBlockWrapper show />
                </div>
              ) : isNil(restaurant) ? (
                <ErrorLayout error={tr('order.confirm.error.required_data')} />
              ) : (
                <React.Fragment>
                  <GeneralDatasCard
                    restaurant={restaurant}
                    orderAppConfig={orderAppConfig}
                    orderProperties={postedOrderData.orderProperties}
                    dueDate={postedOrderData.orderData.dueDateFormatted}
                    classes={classes}
                  />
                  <PaymentDatasCard
                    appConfigPreset={appConfigPreset}
                    route={route}
                    cartData={postedOrderData.cartData}
                    cartItems={postedOrderData.cartItems}
                    orderProperties={postedOrderData.orderProperties}
                    orderData={postedOrderData.orderData}
                    restaurant={restaurant}
                    classes={classes}
                  />

                  {isIncognito && postedOrderData.orderData.paymentId && (
                    <React.Fragment>
                      {paymentPdfLink && (
                        <div className={classes.downloadBtn}>
                          <Button icon={<PictureAsPdfIcon />} href={paymentPdfLink} target="_blank">
                            {tr('order.confirm.download_receipt')}
                          </Button>
                        </div>
                      )}

                      <ErrorBlock error={errorPaymentPdfLink} />
                    </React.Fragment>
                  )}

                  <div className={classes.return}>
                    <ButtonLink onClick={onRedirect}>
                      {frontSettings.redirectUri && isFromWebApp
                        ? tr('order.confirm.return_to_web_customer')
                        : tr('order.confirm.return_to_site')}
                    </ButtonLink>
                  </div>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </BlockContainer>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(View)
