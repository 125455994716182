import Immutable from 'immutable'
import invariant from 'invariant'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import isUndefined from 'lodash/isUndefined'

/**
 * Generate a simple reducer with basic REQUEST / SUCCESS / FAILURE
 * @param  {string} actionType  Action to be reduced
 * @return {Immutable}          Updated state
 */

const DEFAULT_STATE = Immutable.fromJS({
  data: null,
  isFetching: false,
  error: null,
})

const onRequest = (state, action, getProp) =>
  state.mergeIn([getProp(action)], {
    data: null,
    isFetching: true,
    error: null,
    actionData: action.data,
  })

const onSuccess = (state, action, getProp) =>
  state.mergeIn([getProp(action)], {
    data: action.response,
    isFetching: false,
    error: null,
  })

const onFailure = (state, action, getProp) =>
  state.mergeIn([getProp(action)], {
    data: null,
    isFetching: false,
    error: action.error,
  })

// options:
//  - override: { onSuccess: }
const createReducerPerObject = (actionType, getProp, reducer = null, options = {}) => (
  state = DEFAULT_STATE,
  action
) => {
  switch (action.type) {
    case actionType.REQUEST:
      return (options?.override?.onRequest || onRequest)(state, action, getProp)

    case actionType.SUCCESS:
      return (options?.override?.onSuccess || onSuccess)(state, action, getProp)

    case actionType.FAILURE:
      return (options?.override?.onFailure || onFailure)(state, action, getProp)

    default:
      if (reducer !== null) {
        invariant(
          isFunction(reducer) || isObject(reducer),
          'reducer must be a function or an object'
        )
        let res
        if (isFunction(reducer)) {
          res = reducer(state, action)
        } else {
          const func = reducer[action.type]
          if (!func) {
            // no reducer function found for the action type.
            return state
          }
          res = func(state, action)
        }
        invariant(!isUndefined(res), 'reducer returned undefined')
        return res
      }
      return state
  }
}

createReducerPerObject.DEFAULT_STATE = DEFAULT_STATE

export default createReducerPerObject
