import React from 'react'

import Grid from 'pmt-ui/Grid'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import Hidden from 'pmt-ui/Hidden'

import SideSummary from '../../components/SideSummary'

import PaymentBlock from './components/PaymentBlock'

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px 0`,
    },
  },
  container: {
    position: 'relative',
  },
  gridInverse: {
    flexDirection: 'row-reverse',
  },
})

const View = ({
  classes,
  isFetching,
  isFetchingCreditCards,
  user,
  orderProperties,
  restaurant,
  creditCards,
  selectedCreditCard,
  onToggleCreditCard,
  onClickDeleteCreditCard,
  orderPreviewVerifications,
  onResetUserCard,
  canRegisterCardWithName,
}) => (
  <div className={classes.root}>
    <div className={classes.container}>
      <LoadingBlockWrapper show={isFetching} />
      <Grid container spacing={5} className={classes.gridInverse}>
        <Hidden smDown>
          <Grid item xs={12} md={4} lg={3}>
            <SideSummary
              orderProperties={orderProperties}
              restaurant={restaurant}
              user={user}
              cartResumeEnabled
              userInformationsEnabled
              userProfileModificationEnabled
              dueDateModificationEnabled={false}
            />
          </Grid>
        </Hidden>

        <Grid item xs={12} md={8} lg={9}>
          <PaymentBlock
            user={user}
            restaurant={restaurant}
            orderProperties={orderProperties}
            isFetchingCreditCards={isFetchingCreditCards}
            creditCards={creditCards}
            selectedCreditCard={selectedCreditCard}
            onToggleCreditCard={onToggleCreditCard}
            onClickDeleteCreditCard={onClickDeleteCreditCard}
            orderPreviewVerifications={orderPreviewVerifications}
            onResetUserCard={onResetUserCard}
            canRegisterCardWithName={canRegisterCardWithName}
          />
        </Grid>
      </Grid>
    </div>
  </div>
)

export default withStyles(styles)(View)
