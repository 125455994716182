import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'
import compose from 'recompose/compose'

import { EventManager } from 'pmt-modules/event'
import { setSelectedCategory, setParentCategories } from 'pmt-modules/orderPlugin'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

// import Carousel from 'pmt-ui/Carousel'
import PriorityNavbar from './PriorityNavbar'

const marginTabCarousel = 25

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  categoryCarousel: {
    background: theme.pmt.colors.white,
    borderTopLeftRadius: theme.shape.border.radius.main,
    borderTopRightRadius: theme.shape.border.radius.main,
    [theme.breakpoints.down('sm')]: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
  categoryName: {
    width: '100%',
    minHeight: 17,
    maxHeight: 38,
    cursor: 'pointer',
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textAlign: 'center',
    opacity: 0.6,
    transition: 'opacity 0.35s ease',
    '&:hover': {
      opacity: 1,
    },
  },
  categoryNameActive: {
    opacity: 1,
  },
  tabRoot: {
    height: 60,
    paddingTop: 10,
    textTransform: 'initial',
    [theme.breakpoints.down('sm')]: {
      height: 50,
      paddingTop: 0,
    },
  },
  tab: {
    padding: 0,
    marginRight: marginTabCarousel,
    maxWidth: 'unset',
    '&:lastChild': {
      marginRight: 0,
    },
  },
})

class Categories extends React.PureComponent {
  handleCategoryChange = (value) => {
    const category = this.props.categories.filter((category) => category.id === value).pop()
    EventManager.dispatch(EventManager.Events.ON_CATALOG_CATEGORY_SELECT, {
      category,
    })

    this.props.setParentCategories([])
    this.props.redirectTo(getRoute('ORDER__CATEGORY'), {
      restaurantId: this.props.restaurant.id,
      categoryId: category.id,
    })
  }

  render() {
    const { categories, classes, selectedCategory } = this.props

    return (
      <div className={classes.categoryCarousel}>
        {!isNil(selectedCategory) && (
          <PriorityNavbar
            items={categories}
            getLabel={(category) => (
              <TypographyCustom
                type={146}
                className={
                  !isNil(selectedCategory) && selectedCategory.id === category.id
                    ? `${classes.categoryName} ${classes.categoryNameActive}`
                    : `${classes.categoryName}`
                }
              >
                {category.name}
              </TypographyCustom>
            )}
            getValue={(category) => category.id}
            selectedItem={selectedCategory}
            onChange={this.handleCategoryChange}
          />
        )}
        {/* {!isNil(selectedCategory) && (
          <Carousel
            elements={categories}
            getLabel={(category) => (
              <TypographyCustom
                type={146}
                className={
                  !isNil(selectedCategory) && selectedCategory.id === category.id
                    ? `${classes.categoryName} ${classes.categoryNameActive}`
                    : `${classes.categoryName}`
                }
              >
                {category.name}
              </TypographyCustom>
            )}
            getValue={(category) => category.id}
            selectedElement={selectedCategory}
            onChange={this.handleCategoryChange}
            classes={{ root: classes.tabRoot, tab: classes.tab }}
            marginTab={marginTabCarousel}
          />
        )} */}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    setSelectedCategory,
    setParentCategories,
    redirectTo,
  })
)(Categories)
