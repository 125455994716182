import Immutable from 'immutable'

import { GetAppDataAction } from './actions'

export * from './actions'
export * from './selectors'
export * from './middlewares'

/**
 * The data given by the API to display the 'authorize app' view.
 */
const DEFAULT_APP_DATA = Immutable.fromJS({
  isFetching: false,
  data: null,
  error: null,
})

export const appDataReducer = (state = DEFAULT_APP_DATA, action) => {
  switch (action.type) {
    case GetAppDataAction.REQUEST:
      return state.merge({
        isFetching: true,
        data: null,
      })

    case GetAppDataAction.SUCCESS:
      return state.merge({
        isFetching: false,
        data: action.response,
        error: null,
      })

    case GetAppDataAction.FAILURE:
      return state.merge({
        isFetching: false,
        data: null,
        error: action.error,
      })

    default:
      return state
  }
}
