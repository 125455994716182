import React from 'react'

import OrderPropertiesContainer from './OrderPropertiesContainer'

/**
 * Add the OrderPropertiesContainer as HOC for the given component
 */
const withOrderProperties = OrderPropertiesWrappedComponent => props => (
  <OrderPropertiesContainer
    OrderPropertiesWrappedComponent={OrderPropertiesWrappedComponent}
    {...props}
  />
)

export default withOrderProperties
