import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Edit = props => (
  <SvgIcon viewBox="0 0 10 10" {...props}>
    <path
      d="M8.493,5.154l2.354,2.354L5.354,13H3V10.646L8.493,5.153Zm.785-.785,1.177-1.177a.555.555,0,0,1,.785,0l1.57,1.57a.555.555,0,0,1,0,.785L11.631,6.723Z"
      transform="translate(-3 -3.029)"
    />
  </SvgIcon>
)
Edit = pure(Edit)
Edit.displayName = 'Edit'
Edit.muiName = 'SvgIcon'

export default Edit
