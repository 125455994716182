//
// Note: this file should never be import directly. Use a getter via getters.js
//

import merge from 'lodash/merge'

import defaultEnv from './defaultEnv'

//
// The currentEnv is set by the front.
// It is given to our `Application` that call `setCurrentEnv`
//
let _currentEnv = {}

export const setCurrentEnv = (currentEnv) => {
  _currentEnv = merge({}, defaultEnv, currentEnv)
}

export const getEnv = () => _currentEnv
