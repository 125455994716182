import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'

import { EventManager } from 'pmt-modules/event'
import { getOrderProperties } from 'pmt-modules/orderPlugin'

import { withStyles } from 'pmt-ui/styles'
import { CardItem } from 'pmt-ui/Card'
import ContentAdd from 'pmt-ui/svg-icons/content/add'
import ActionDone from 'pmt-ui/svg-icons/action/done'
import StateMachine from 'pmt-ui/StateMachine'

import Button from '../../../components/Button'
import Chip from '../../../components/Chip'
import CartModifierTag from '../../../components/CartModifier/CartModifierTag'
import CardItemOrder from '../../../components/Card/CardItemOrder'

const styles = (theme) => {
  return {
    cartModifierChip: {
      marginBottom: theme.spacing(0.5),
      cursor: 'pointer',
      borderRadius: 6,
      padding: '5px 8px',
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '14px',
      height: 'auto',
      textTransform: 'capitalize',
    },
    cartModifierUnavailable: {
      opacity: 0.2,
    },
    cartModifierHasBorder: {
      border: `1px solid`,
    },
    buttonAdd: {
      borderRadius: 10,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      border: `2px solid ${theme.palette.primary.main}`,
      '&:hover': {
        borderColor: theme.palette.primary.dark,
      },
    },
  }
}

const State = {
  NORMAL: 'NORMAL',
  ADDING_TO_CART: 'ADDING_TO_CART',
}

const Item = ({
  classes,
  item,
  isCategory,
  restaurant,
  selectedCategory,
  onClickCard,
  orderProperties,
  addToCartFromShortcut,
  openMenu,
  openProduct,
}) => (
  <StateMachine
    states={State}
    default={State.NORMAL}
    transitions={[
      {
        state: State.ADDING_TO_CART,
        to: State.NORMAL,
        duration: 1000,
      },
    ]}
  >
    {({ currentState, transitionTo }) => (
      <CardItem
        type={<CardItemOrder />}
        // give item even if it is not used, to simplify debug on react dev tools
        item={item}
        description={item.description}
        image={item.image}
        restaurantLogo={!isNil(restaurant) ? restaurant.logo : null}
        name={item.name}
        price={item.priceFormatted}
        hasCartModifier={item.hasCartModifier}
        basePrice={item.hasCartModifier ? item.basePriceFormatted : null}
        cartModifierTag={(cartModifier, index, hasBorder = false) => (
          <CartModifierTag
            cartModifier={cartModifier}
            chipElement={
              <Chip
                key={index}
                label={cartModifier.tag}
                noBorder
                classes={{
                  root: classNames(classes.cartModifierChip, {
                    [classes.cartModifierUnavailable]: !item.available,
                    [classes.cartModifierHasBorder]: hasBorder,
                  }),
                }}
              />
            }
            key={index}
            overEventsEnabled={false}
          />
        )}
        isAvailable={item.available}
        isCategory={isCategory}
        onClickCard={() => {
          onClickCard(item)
        }}
        buttonAdd={
          <Button
            size="small"
            classes={{
              root: classes.buttonAdd,
            }}
            icon={currentState === State.NORMAL ? <ContentAdd /> : <ActionDone />}
            label={tr(currentState === State.NORMAL ? 'order.catalog.add' : 'order.catalog.added')}
            onClick={(e) => {
              e.stopPropagation()

              if (item.available) {
                if (item.isComplexItem) {
                  if (item.isProduct) {
                    EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_DETAIL, {
                      item,
                      category: selectedCategory,
                      restaurant,
                    })
                    openProduct(item, {
                      restaurantId: restaurant.id,
                      showDialog: false,
                    })
                  } else if (item.isMenu) {
                    EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_DETAIL, {
                      item,
                      category: selectedCategory,
                      restaurant,
                    })
                    openMenu(item, {
                      restaurantId: restaurant.id,
                    })
                  }
                } else {
                  transitionTo(State.ADDING_TO_CART)
                  if (!item.hasSuggestion) {
                    EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT, {
                      dueDate: orderProperties.dueDate,
                      item,
                      category: selectedCategory,
                      restaurant,
                    })
                  }
                  addToCartFromShortcut(item)
                }
              }
            }}
          />
        }
      />
    )}
  </StateMachine>
)

Item.defaultProps = {
  isCategory: false,
}

const mapStateToProps = (state) => ({
  orderProperties: getOrderProperties(state),
})

export default compose(withStyles(styles), connect(mapStateToProps, {}))(Item)
