import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import RestaurantsGroupApi from 'pmt-modules/api/calls/RestaurantsGroupApi'

//
//
//

export const GetRestaurantsGroupAction = createApiEnumAction('RESTAURANTSGROUP::GET')

const DEFAULT_OPTIONS = {
  // default false, to avoid missing it.
  withAuthorization: false,
}

export const PostRestaurantsGroupAction = createApiEnumAction('RESTAURANTSGROUP::POST')
export const postRestaurantsGroup = restaurantsGroup =>
  createApiCallAction(
    PostRestaurantsGroupAction,
    RestaurantsGroupApi.postRestaurantsGroup(restaurantsGroup),
    {
      restaurantsGroup,
    }
  )

/**
 *
 * @param {string|number} restaurantsGroupId can either be a string ('mine') or a number
 * @param {object} options
 */
export const fetchRestaurantsGroup = (restaurantsGroupId, options = DEFAULT_OPTIONS) =>
  createApiCallAction(
    GetRestaurantsGroupAction,
    RestaurantsGroupApi.getRestaurantsGroup(restaurantsGroupId, options),
    {
      restaurantsGroupId,
      options,
    }
  )

export const PutGroupStoreListAction = createApiEnumAction('GROUP::PUT::STORE_LIST')

export const putGroupStoreList = (groupId, storesIds) =>
  createApiCallAction(
    PutGroupStoreListAction,
    RestaurantsGroupApi.putGroupStoreList(groupId, storesIds),
    {
      groupId,
      storesIds,
    }
  )

export const PutGroupGroupListAction = createApiEnumAction('GROUP::PUT::GROUP_LIST')

export const putGroupChildrenGroupList = (parentGroupId, childrenIds) =>
  createApiCallAction(
    PutGroupGroupListAction,
    RestaurantsGroupApi.putGroupGroupList(parentGroupId, childrenIds),
    {
      parentGroupId,
      childrenIds,
    }
  )

export const PatchGroupAction = createApiEnumAction('GROUP::PATCH::GROUP')

export const patchGroup = (defaultData, formData) => {
  if (process.env.FEATURE_BO) {
    const compare = require('fast-json-patch').compare
    const body = compare(defaultData, formData)

    return createApiCallAction(
      PatchGroupAction,
      RestaurantsGroupApi.patchGroup(defaultData.id, body),
      {
        restaurantsGroupId: defaultData.id,
        defaultData,
        formData,
        body,
      }
    )
  }
}
