import ProAuthApi from '../api/calls/ProAuthApi'

import { createApiCallAction } from '../api/utils'
import { createApiEnumAction } from '../api/utils'
import createAction from '../redux/createAction'

//
//
//

export const AuthProAction = createApiEnumAction('AUTH::PRO::AUTHENTICATE')

/**
 *
 * @param  {string} username
 * @param  {string} password
 * @param  {string} props     additional props that can be used by the middlewares.
 *                            - redirectTo: will redirect to the given url after the login success
 */
export const authenticatePro = (username, password, props) => {
  const base64Credentials = btoa(`pro_${username}:${password}`)
  const authorization = `Basic ${base64Credentials}`

  return createApiCallAction(AuthProAction, ProAuthApi.postBasicPassword(authorization), {
    username,
    password,
    props,
    authorization,
  })
}

//
//
//

export const LOGOUT_PRO = 'AUTH::PRO::LOGOUT'

export const logoutPro = () => createAction(LOGOUT_PRO, {})
