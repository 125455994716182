import { createSelector } from 'reselect'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'

import Logger from 'pmt-utils/logger'

/**
 * Generate a simple selector with basic isFetching / getData / getErrors
 * @param  {function} getState  Function to retrieve entity from state
 *                              state => state.entities.ENTITY_NAME
 * @param  {function} formatData  Callback function to format data
 * @return {object}             Object with 3 selectors
 *                              isFetching / getData / getErrors
 * TODO: rename to `createSimpleSelectors`
 */
const createSimpleSelector = (getState, formatData = null) => ({
  isFetching: createSelector(
    [getState],
    dataState => (isNil(dataState) ? false : dataState.get('isFetching') || false)
  ),

  getData: createSelector([getState], dataState => {
    if (isNil(dataState)) {
      return null
    }

    const data = dataState.get('data')

    if (isNil(data)) {
      return null
    }

    if (!data.toJS) {
      // Can happen when the API did not sent back a JSON object.
      throw new Error('Invalid data on createSimpleSelector, toJS does not exists: ' + data)
    }

    const dataJs = data.toJS()

    // add action payload data if exists
    const actionData = dataState.get('actionData', null)

    return formatData !== null && typeof formatData === 'function'
      ? formatData(dataJs, { data: dataJs, actionData: actionData ? actionData.toJS() : null })
      : dataJs
  }),

  getError: createSelector([getState], dataState => {
    if (isNil(dataState)) {
      return null
    }

    const error = dataState.get('error')

    if (isNil(error)) {
      return null
    }

    if (!isFunction(error.toJS)) {
      Logger.warn('Create Simple Selector', 'data is not immutable', { error })
    }

    return error.toJS ? error.toJS() : error
  }),
})

export default createSimpleSelector
