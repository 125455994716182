import { tr } from 'pmt-modules/i18n'
import React from 'react'
import DialogContainer from 'pmt-ui/DialogContainer'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import LockerIcon from './LockerIcon'
import { getPublicUrl } from 'pmt-modules/environment'

const useStyles = makeStyles(theme => ({
  icons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    marginTop: theme.spacing(4),
    fontSize: 18,
    lineHeight: '26px',

    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
  btn: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    padding: '10px 16px',
    fontSize: '1.125rem',
    fontWeight: 600,
  },
}))

function PaymentAsyncRedirectTutorialDialog({ onSubmit, label1, label2 }) {
  const classes = useStyles()

  return (
    <DialogContainer defaultOpen title={''} disableDefaultOnClose>
      <div className={classes.icons}>
        <LockerIcon width={66} height={66} />
        <img
          src={`${getPublicUrl()}/img/CreditCardsIcon.png`}
          width={90}
          height={60}
          alt="credit cards"
        />
      </div>
      <div className={classes.text}>
        <p>{label1}</p>
        <p>{label2}</p>
      </div>

      <Button
        className={classes.btn}
        variant="contained"
        color="primary"
        fullWidth
        onClick={onSubmit}
      >
        {tr('PaymentAsyncRedirectTutorialDialog.confirm')}
      </Button>
    </DialogContainer>
  )
}

export default PaymentAsyncRedirectTutorialDialog
