import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { withRestaurantsGroupMine } from 'pmt-modules/restaurantsGroup/components'
import UserAccountsContainer from '../UserAccountsContainer'

import View from './View'

const defaultBalanceLabel = 'global.user_account.my_credit'

@withRestaurantsGroupMine()
class UserAccountRemainingView extends React.PureComponent {
  render() {
    const {
      userId,
      forceUpdate = false,
      dislayLinkToTopUp = false,
      balanceLabel = defaultBalanceLabel,
      restaurantsGroup,
    } = this.props

    return (
      <UserAccountsContainer userId={userId} forceUpdate={forceUpdate}>
        {props => (
          <View
            restaurantsGroup={restaurantsGroup}
            dislayLinkToTopUp={dislayLinkToTopUp}
            balanceLabel={tr(balanceLabel)}
            {...props}
          />
        )}
      </UserAccountsContainer>
    )
  }
}

export default UserAccountRemainingView
