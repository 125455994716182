import isNil from 'lodash/isNil'

import { findOnArray } from 'pmt-utils/array'

export const removeItemsFromUpsellings = (upsellingCategory, itemsToRemove) => {
  upsellingCategory.items = upsellingCategory.items.filter(item =>
    isNil(findOnArray(itemsToRemove, itemToRemove => itemToRemove.id === item.id))
  )

  return upsellingCategory
}
