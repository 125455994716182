import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { createSimpleSelector } from 'pmt-modules/redux'

import { formatPayment } from './format'

const getPostPaymentData = state => state.entities.postPayment
const getPaymentData = state => state.entities.payment
const getItems = state => getPaymentData(state).getIn(['payment', 'items'])

export const getPayment = createSelector([getPaymentData], paymentData =>
  formatPayment(paymentData.get('payment').toJS())
)

const getIdOnProps = (state, props) => props.id

export const getItemWithId = createSelector([getItems, getIdOnProps], (items, id) => {
  const item = items.get(id)

  if (isNil(item)) {
    return null
  }

  return item.toJS()
})

export const getPostPayment = createSelector([getPostPaymentData], postPaymentData => {
  const data = postPaymentData.get('data')

  if (isNil(data)) {
    return null
  }

  return data.toJS()
})

export const getPaymentError = createSelector([getPostPaymentData], postPaymentData => {
  const error = postPaymentData.get('error')

  if (isNil(error)) {
    return null
  }

  return error.toJS()
})

export const getPostPaymentAtTableData = createSelector([getPostPayment], postPayment => {
  return postPayment
})

export const isFetchingPostPaymentAtTable = createSelector([getPostPaymentData], postPaymentData =>
  postPaymentData.get('isFetching')
)

// TODO: unused? or on kiosk?
const postPaymentEmailSelectors = createSimpleSelector(state => state.entities.postPaymentEmail)

export const isFetchingPostPaymentEmail = postPaymentEmailSelectors.isFetching
export const getDataPostPaymentEmail = postPaymentEmailSelectors.getData
export const getErrorPostPaymentEmail = postPaymentEmailSelectors.getError
