import capitalize from 'lodash/capitalize'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import padStart from 'lodash/padStart'
import isNil from 'lodash/isNil'
import merge from 'lodash/merge'
import moment from 'moment'
import { tr } from 'pmt-modules/i18n'

import {
  formatSlots,
  getForDay,
  getNextClosingHour,
  getRestaurantAvailabilities,
} from 'pmt-modules/orderSettings'
import {
  DELAY_TO_WARN_CLOSING_HOUR,
  getMsFromHour,
  getMsFromMinute,
  isToday,
  getPmtDayIndex,
  todayWithHour,
} from 'pmt-utils/date'

import { createFormatter } from 'pmt-utils/format'

const formatData = (
  data,
  {
    orderFrontProperties,
    openingHours,
    orderAppConfig,
    orderPreviewVerifications,
    selectedCreditCard,
  }
) => {
  data = merge(data, cloneDeep(orderFrontProperties))

  const orderParamsForMode = orderAppConfig.orderParamsForModes[data.mode]

  // keep onSite at the moment, maybe we'll refactor that later
  data.mustChooseTableNumber = data.isOnSite && orderAppConfig.hasTableNumber
  data.isMissingTableNumber = data.mustChooseTableNumber && isEmpty(String(data.tableNumber))

  data.canOrderUsingSlots = orderParamsForMode?.canOrderUsingSlots || false
  data.isMissingSlot = data.canOrderUsingSlots && !data.isAsap && isNil(data.slot)

  data.canOrderUsingAsap = orderParamsForMode?.canOrderUsingAsap || false
  data.forceAsap = data.canOrderUsingAsap && !data.canOrderUsingSlots

  if (!isNil(get(data, 'deliveryAddress.id', null))) {
    data.deliveryAddress.formattedAddress = `${data.deliveryAddress.street}, ${
      data.deliveryAddress.postCode
    } ${data.deliveryAddress.city}, ${data.deliveryAddress.country}`
  } else {
    data.deliveryAddress = null
  }

  data.dueDateFormatted = null
  if (!isNil(data.dueDate)) {
    const date = isToday(data.dueDate)
      ? tr('global.today')
      : moment(data.dueDate).format('dddd D MMM')

    data.dueDateFormatted = tr(`order.slots.dueDate.${data.mode}`, {
      date,
      dateCapitalized: capitalize(date),
      time: moment(data.dueDate).format('HH:mm'),
    })
  }

  let finalDueDate = null
  if (!isNil(data.dueDate) || data.isAsap) {
    finalDueDate = data.isAsap ? moment() : moment(data.dueDate)
  }

  data.closingHour = null
  data.closingHourFormatted = null
  if (!isNil(finalDueDate) && isToday(finalDueDate)) {
    const now = moment()
    const dueDateOpeningHours = getForDay(openingHours, getPmtDayIndex(now))
    if (!isEmpty(dueDateOpeningHours)) {
      const hoursInMs =
        getMsFromHour(parseInt(now.format('HH'), 10)) +
        getMsFromMinute(parseInt(now.format('mm'), 10))

      data.closingHour = getNextClosingHour(hoursInMs, dueDateOpeningHours)

      if (data.closingHour) {
        const nowAndDelaysCumulated = moment().add(DELAY_TO_WARN_CLOSING_HOUR, 'ms')
        const lastTimeToOrder = todayWithHour(data.closingHour - data.delay)
        const time = todayWithHour(data.closingHour + data.deliveryTime)

        if (data.delay < data.closingHour) {
          data.closingHourFormatted = {
            show: nowAndDelaysCumulated.isAfter(lastTimeToOrder),
            time: {
              time,
              hourFormatted: padStart(time.hours(), 2, '0'),
              minuteFormatted: padStart(time.minutes(), 2, '0'),
            },
            lastTimeToOrder: {
              time: lastTimeToOrder,
              hourFormatted: padStart(lastTimeToOrder.hours(), 2, '0'),
              minuteFormatted: padStart(lastTimeToOrder.minutes(), 2, '0'),
            },
          }
        }
      }
    }
  }

  //
  // payment
  //

  // TODO: some or oneOf
  const hasAPaymentMethodSelected = [
    data.payment.isCreditCardMethod,
    data.payment.isIrlMethod,
    data.payment.isUserAccountMethod,
    data.payment.isTRDMethod,
  ].some(paymentMethod => paymentMethod === true)

  const creditCardPaymentMethodIsSelectedAndValid =
    data.payment.isCreditCardMethod && selectedCreditCard

  const hasPaymentWithCardPrint = orderFrontProperties?.restaurant?.hasPaymentWithCardPrint

  const userAccountPaymentMethodIsSelectedAndValid =
    data.payment.isUserAccountMethod &&
    get(orderPreviewVerifications, 'userAccount.succeeded', false) === true

  const trdPaymentMethodIsSelectedAndValid = data.payment.isTRDMethod

  const paymentMethodIsSelectedAndValid =
    hasAPaymentMethodSelected &&
    (!data.payment.isCreditCardMethod ||
      (!hasPaymentWithCardPrint || creditCardPaymentMethodIsSelectedAndValid)) &&
    (!data.payment.isUserAccountMethod || userAccountPaymentMethodIsSelectedAndValid) &&
    (!data.payment.isTRDMethod || trdPaymentMethodIsSelectedAndValid)

  data.payment = {
    ...data.payment,
    hasAPaymentMethodSelected,
    paymentMethodIsSelectedAndValid,
    userAccountPaymentMethodIsSelectedAndValid,
    creditCard: data.creditCard || null,
    // creditCard: selectedCreditCard || null,
  }

  //
  // slots
  //

  data.slots = null
  if (!isNil(data.mode) && !isNil(data.delay) && !isNil(data.restaurant)) {
    data.slots = formatSlots(
      getRestaurantAvailabilities(data.restaurant, data.mode).availabilities,
      data.slot // selectedSlot
    )
  }

  // TODO: check if still necessay ... feels a bit awkward
  // catalogDataOnprops.categories
  //     .filter(category => category.id === firstSelectedCategory.id)
  //     .pop()

  return data
}

export const formatOrderProperties = createFormatter(formatData)
