import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'

import { hasTokenExpired, willTokenExpireSoon } from 'pmt-modules/auth/utils'

import { createMiddleware } from 'pmt-modules/redux'
import { fromMs } from 'pmt-utils/date'
import { isRefreshingOauthToken } from 'pmt-modules/auth/selectors'
import { RefreshAction, refreshAction } from 'pmt-modules/auth/refreshToken/actions'
import { queueActionForRefreshToken } from 'pmt-modules/auth/refreshToken/preAttemptRefresh'
import { getOauthClient } from 'pmt-modules/appConfig/selectors'
import attemptRefresh from 'pmt-modules/auth/refreshToken/attemptRefresh'
import { hasAuthEnabled } from 'pmt-modules/environment'
import { API_CALL, TYPES } from '../constants'

export { getAuthorization as getOauthBearerToken } from 'pmt-modules/auth/utils'

export const handleAccessToken = ({ request, action, dispatch, next, getState }) => {
  // check if access token has expired or is going to expire soon
  // handleExpiredToken will throw an exception when token as expired that we catch here
  // in order to stop propagation but will continue otherwise
  if (
    hasAuthEnabled() &&
    (request.headers.authorization !== null || action[API_CALL][TYPES][0] === RefreshAction.REQUEST)
  ) {
    const { getAuth } = require('pmt-modules/auth/selectors')
    const authData = request.authData || getAuth()

    const shouldStopPropagation = handleExpiredToken({
      request,
      action,
      getState,
      dispatch,
      next,
      authData,
    })
    if (shouldStopPropagation) {
      return createMiddleware.STOP_PROPAGATION
    }

    //
    // add oauth refresh token middleware
    //
    request.attemptRefresh = attemptRefresh
    request.attemptRefreshOptions = {
      // This is the current access token. If the user is not authenticated yet
      // this can be null or undefined
      token: !isNil(authData) ? authData.refresh_token : null,
    }
  } else {
    request.attemptRefresh = null
  }
}

/**
 * check if access token has expired or is going to expire soon
 * refresh it if needed and then process to
 *
 * @returns {bool} wether it should stop propagation or not
 */
const handleExpiredToken = ({ request, action, getState, dispatch, next, authData }) => {
  const oauthClient = getOauthClient(getState())

  if (!isNull(authData)) {
    const refreshToken = authData.refresh_token
    const expiration = fromMs(authData.expiresAt)

    // if current action is not a refresh action,
    // nor a getAppConfig action (meaning we're fetching oauth clientId/clientSecret)
    if (action[API_CALL][TYPES][0] !== RefreshAction.REQUEST) {
      if (hasTokenExpired(expiration)) {
        // if token has already expired or is going to expire within 2 minutes
        // we stack pending actions and call refresh token
        // stacked actions will be unstacked after successful resfresh
        queueActionForRefreshToken({
          action,
          dispatch,
          next,
          oauthClient,
          refreshToken,
        })

        // stop middleware propgation for it not to next any other action
        return true
      } else if (willTokenExpireSoon(expiration)) {
        // token is going to expire soon, we still have time to dispatch a refresh token action
        // in the background in order to optimize answers time
        // thus, we dispatch a refreshAction without any callback needed
        if (!isRefreshingOauthToken(getState())) {
          dispatch(
            refreshAction(refreshToken, oauthClient, {
              then: null,
            })
          )
        }
      }
    }
  }

  return false
}
