/**
 * constant used to switch between views on mobile devices
 */
export const LoginViews = {
  /**
   * Used on small screen only (on large screen, we display the 3 features on the same view, so the user doesn't have to choose first)
   *
   * This is the first screen of the login/register feature :
   * the user will have to choose if he wants to login, register, or continue without account creation (= as incognito)
   *
   * handled by AuthenticationChoiceForm
   */
  CHOICE_TO_DO: 'CHOICE_TO_DO',

  /**
   * handled by LoginForm
   */
  LOGIN: 'LOGIN',

  /**
   * handled by RegisterForm
   */
  REGISTER: 'REGISTER',

  /**
   * Used on small screen only, when the user chose "continue without account creation"
   * it allows to display the CGU checkbox, and make it mandatory to check it to continue
   *
   * On large screen, we do no need a specific view, 
   * as the link is displayed on the Register form, and CGU checkbox of the register form is used to enable/disable the "continue as incognito" link
   *
   * handled by IncognitoForm
   */
  INCOGNITO: 'INCOGNITO',
}
