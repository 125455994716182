import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Celery = props => (
  <SvgIcon {...props}>
    <path
      d="M14.86,4.78a3.863,3.863,0,0,0-.853-.99,4.943,4.943,0,0,0,.013-2.475l-.067-.268L13.685.98A4.991,4.991,0,0,0,12.471.83,4.917,4.917,0,0,0,11.21.993,3.863,3.863,0,0,0,10.22.14L9.986,0,9.751.137A3.8,3.8,0,0,0,8.324,1.632L1.043,8.913a3.567,3.567,0,0,0,5.044,5.044l7.278-7.278a3.81,3.81,0,0,0,1.5-1.429L15,5.014ZM1.779,13.221A1.763,1.763,0,0,1,2.3,11.893L8.615,5.574a3.747,3.747,0,0,0,.374.436,3.789,3.789,0,0,0,.436.375L3.107,12.7A1.763,1.763,0,0,1,1.779,13.221ZM1.7,9.566l6.2-6.2A3.749,3.749,0,0,0,8.153,4.73L1.644,11.24a2.7,2.7,0,0,0-.613.944A2.645,2.645,0,0,1,1.7,9.566ZM5.434,13.3a2.646,2.646,0,0,1-2.619.665,2.7,2.7,0,0,0,.945-.613l6.508-6.508a3.733,3.733,0,0,0,1.363.256h0Zm8.185-7.947A2.812,2.812,0,1,1,9.642,1.381a2.968,2.968,0,0,1,.338-.292,2.982,2.982,0,0,1,.282.239,2.907,2.907,0,0,1,.39.46l.2.29.332-.113a3.984,3.984,0,0,1,1.287-.212,4.064,4.064,0,0,1,.713.063,4,4,0,0,1-.149,2l-.113.332.29.2a2.908,2.908,0,0,1,.46.39,2.977,2.977,0,0,1,.239.282A2.961,2.961,0,0,1,13.619,5.357Z"
      transform="translate(4 3)"
    />
  </SvgIcon>
)
Celery = pure(Celery)
Celery.displayName = 'Celery'
Celery.muiName = 'SvgIcon'

export default Celery
