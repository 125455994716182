import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'

import { createMiddleware } from 'pmt-modules/redux'
import { getFrontSessionId, FRONT_SESSION_ID_HEADER } from 'pmt-modules/frontSession'
import { getApiUrl, getApiConsumer, isBo, isKiosk } from 'pmt-modules/environment'

import { getAppConfig } from 'pmt-modules/appConfig/selectors'
import { createIncognitoBasic, createUserLightBasic } from 'pmt-modules/api/utils'
import { AuthMode } from 'pmt-modules/auth'
import { getUserLightCookie, getIncognitoCookie } from 'pmt-modules/auth/utils'
import { isActionApiCall } from '../utils'
import { API_CALL, REQUEST } from '../constants'

const getFinalApiUrl = (requestUrl = null) => {
  if (!isEmpty(requestUrl)) {
    return requestUrl
  }

  return getApiUrl()
}

const getAuthorization = state => {
  if (isBo()) {
    const { getBasicToken } = require('pmt-modules/authPro/selectors')
    return getBasicToken(state)
  } else if (isKiosk()) {
    const {
      getBasicToken: getRestaurantBasicToken,
    } = require('pmt-modules/authRestaurant/selectors')
    return getRestaurantBasicToken(state)
  } else {
    const authenticationSettings = getAppConfig(state)?.authentication
    if (authenticationSettings && !isNil(authenticationSettings.mode)) {
      return getAuthorizationFromModeAndCookies(authenticationSettings)
    } else {
      // default behaviour
      return getOauthToken()
    }
  }
}

const getOauthToken = () => {
  const { getOauthBearerToken } = require('./oauthUtils')
  const accessToken = getOauthBearerToken()
  if (accessToken) {
    return accessToken
  }
  return null
}

const getAuthorizationFromModeAndCookies = authenticationSettings => {
  if (authenticationSettings.mode === AuthMode.NORMAL) {
    if (getOauthToken() != null) {
      return getOauthToken()
    } else if (authenticationSettings.allowIncognito && getIncognitoCookie()) {
      return createIncognitoBasic(getIncognitoCookie())
    }
  } else if (authenticationSettings.mode === AuthMode.LIGHT) {
    if (getOauthToken() != null) {
      return getOauthToken()
    } else if (getUserLightCookie() != null) {
      return createUserLightBasic(getUserLightCookie().email)
    } else if (authenticationSettings.allowIncognito && getIncognitoCookie()) {
      return createIncognitoBasic(getIncognitoCookie())
    }
  } else if (
    authenticationSettings.mode === AuthMode.INCOGNITO_ONLY &&
    getIncognitoCookie() != null
  ) {
    return createIncognitoBasic(getIncognitoCookie())
  }
  return null
}

/**
 * middleware to update the  api calls data
 */
export const requestConfigurationMiddleware = createMiddleware(
  isActionApiCall,
  ({ getState, dispatch, next, action }) => {
    const apiCall = action[API_CALL]

    const state = getState()
    const request = apiCall[REQUEST]

    //
    // update headers
    // Note: nil headers will be removed by the ApiManager.
    //

    const headers = {
      'api-consumer': getApiConsumer(),
      [FRONT_SESSION_ID_HEADER]: getFrontSessionId(),
    }

    const authorization = getAuthorization(state)
    if (
      authorization !== null &&
      // verify we don't override config, handle both writing
      isUndefined(headers.authorization) &&
      isUndefined(headers.Authorization)
    ) {
      headers.authorization = authorization
    }

    // add param `withoutAuth` to force authorization to be null
    if (request.withoutAuth === true) {
      // handle both writing
      headers.authorization = null
      headers.Authorization = null
    }

    // merge our default headers, priority given to the request's headers.
    request.headers = merge(headers, request.headers)

    //
    // set url
    //
    request.url = getFinalApiUrl(request.url)

    if (!isBo() && !isKiosk()) {
      const { handleAccessToken } = require('./oauthUtils')
      handleAccessToken({ request, action, dispatch, next, getState })
    }

    //
    // set data
    //

    action[API_CALL][REQUEST] = request

    return next(action)
  }
)
