import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'

import { EventManager } from 'pmt-modules/event'
import { PaymentMethodsAllowed } from 'pmt-modules/orderSettings'
import { UserAccountsContainer } from 'pmt-modules/userAccount'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

import ErrorBlock from 'pmt-ui/ErrorBlock'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import BlockContainer from '../../../components/BlockContainer'
import MessageBlock from '../../../components/MessageBlock'

const styles = (theme) => ({
  account: {
    display: 'flex',
    position: 'relative',
    padding: theme.spacing(2),
    overflow: 'hidden',
    marginTop: theme.spacing(1),
    minHeight: 64,
  },
  detail: {
    width: '100%',
    color: theme.pmt.colors.grey91,
  },
  detailSub: {
    float: 'left',
    marginBottom: theme.spacing(2),
  },
  infos: {
    marginTop: theme.spacing(0.5),
  },
  checkbox: {
    float: 'left',
    padding: 0,
    marginRight: theme.spacing(1),
  },
  deduction: {
    color: theme.palette.primary.main,
    float: 'right',
  },
})

const UserAccountPayment = ({
  classes,
  isFetchingOrderPreview,
  orderPreview,
  orderPreviewError,
  orderProperties,
  setPaymentMethod,
}) => (
  <Fragment>
    <FormControlLabel
      onClick={() => {
        EventManager.dispatch(EventManager.Events.ON_PAYMENT_MODE_SELECT, {
          paymentMode: PaymentMethodsAllowed.USER_ACCOUNT,
        })
        setPaymentMethod(PaymentMethodsAllowed.USER_ACCOUNT)
      }}
      control={
        <Radio
          color="primary"
          name="userAccountPayment"
          checked={orderProperties.payment.isUserAccountMethod}
          value="userAccountPayment"
        />
      }
      label={
        <TypographyCustom type={167}>{tr('order.payment.user_account.label')}</TypographyCustom>
      }
    />
    {orderProperties.payment.isUserAccountMethod && (
      <BlockContainer type={BlockContainer.Type.GREY} classes={{ root: classes.account }}>
        <UserAccountsContainer userId="me" forceUpdate>
          {({ isFetchingUserAccounts, userAccount }) => (
            <Fragment>
              {(isFetchingUserAccounts ||
                isFetchingOrderPreview ||
                !userAccount ||
                (!orderPreview && !orderPreviewError)) && <LoadingBlockWrapper show size={25} />}
              {userAccount && orderPreview && orderPreview.userAccountPayment && (
                <div className={classes.detail}>
                  <div className="u-overflowHidden">
                    <div className="u-floatLeft">
                      <TypographyCustom type="144">
                        {tr('order.payment.user_account.balance_deduction')}
                      </TypographyCustom>
                      <TypographyCustom type="124" className={classes.infos} skipColor>
                        {tr('order.payment.user_account.balance', {
                          amount: userAccount.amountFormatted,
                        })}
                      </TypographyCustom>
                    </div>
                  </div>
                </div>
              )}
              {orderPreviewError && (
                <ErrorBlock
                  error={orderPreviewError}
                  mode={ErrorBlock.Mode.CUSTOM}
                  customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
                />
              )}
            </Fragment>
          )}
        </UserAccountsContainer>
      </BlockContainer>
    )}
  </Fragment>
)

export default withStyles(styles)(UserAccountPayment)
