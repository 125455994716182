import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { CreditCardsContainer } from 'pmt-modules/creditCard'

import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'
import Card, { CardContent } from 'pmt-ui/Card'
import { TypographyCustom } from 'pmt-ui/Typography'
import Delete from 'pmt-ui/svg-icons/action/delete'
import ScrollableAnchor from 'pmt-ui/ScrollableAnchor'

import FlexBlock from '../../../../components/FlexBlock'

const styles = (theme) => ({
  container: {
    borderColor: theme.shape.border.color.default,
    borderWidth: theme.shape.border.width.default,
    borderStyle: 'solid',
    borderRadius: theme.shape.border.radius.secondaryContent,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      border: 'none',
      borderRadius: 0,
      paddingLeft: 0,
      paddingRight: 0,
      borderBottomWidth: theme.shape.border.width.default,
      borderBottomColor: theme.shape.border.color.default,
      borderBottomStyle: 'solid',
      marginBottom: '0px !important',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: theme.pmt.colors.grey100,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)}px !important`,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  creditCardName: {
    marginRight: theme.spacing(5),
    width: 150,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  creditCardNumber: {
    marginBottom: theme.spacing(1),
  },
  creditCardDelete: {
    width: 24,
    height: 30,
    cursor: 'pointer',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 'calc(50% - 15px)',
      right: 10,
    },
  },
  containerGridPayment: {
    marginTop: theme.spacing(2),
  },
  containerCreditCard: {
    boxShadow: 'none',
    borderRadius: theme.shape.border.radius.content,
    position: 'relative',
  },
  containerGridCreditCard: {
    marginBottom: theme.spacing(1.5),
  },
})

const PaymentMethods = ({ user, handleOnClickBlock, anchor, opened, classes }) => (
  <ScrollableAnchor id={anchor}>
    <div>
      <FlexBlock
        isFlex
        classes={{ root: `u-marginBottom25 ${classes.container}` }}
        title={tr('order.account.payment_methods.title')}
        content={
          <CreditCardsContainer userId={user && user.id}>
            {({
              isFetchingCreditCards,
              creditCards,
              isFetchingDeleteCreditCard,
              onClickDeleteCreditCard,
            }) => (
              <Grid container spacing={0} classes={{ root: classes.containerGridPayment }}>
                {isNil(creditCards) || isFetchingCreditCards || isFetchingDeleteCreditCard ? (
                  <Grid item xs={12}>
                    <LoadingBlock show />
                  </Grid>
                ) : isEmpty(creditCards) ? (
                  <Grid item xs={12}>
                    <TypographyCustom type="144">
                      {tr('order.account.payment_methods.no_cards')}
                    </TypographyCustom>
                  </Grid>
                ) : (
                  <React.Fragment>
                    {creditCards.map((creditCard, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        classes={{ root: classes.containerGridCreditCard }}
                      >
                        <Card classes={{ root: classes.containerCreditCard }}>
                          <CardContent className={classes.cardContent}>
                            <TypographyCustom type="166" className={classes.creditCardName}>
                              {creditCard.name}
                            </TypographyCustom>
                            <div>
                              <TypographyCustom type="145" className={classes.creditCardNumber}>
                                {creditCard.number}
                              </TypographyCustom>
                              <TypographyCustom type="147">
                                {tr('order.payment.credit_card.expire', {
                                  date: creditCard.expirationDateFormatted,
                                })}
                              </TypographyCustom>
                            </div>
                            <Delete
                              className={classes.creditCardDelete}
                              onClick={() => {
                                onClickDeleteCreditCard(creditCard)
                              }}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </React.Fragment>
                )}
              </Grid>
            )}
          </CreditCardsContainer>
        }
        handleOnClick={handleOnClickBlock}
        opened={opened}
      />
    </div>
  </ScrollableAnchor>
)

export default withStyles(styles)(PaymentMethods)
