import isNil from 'lodash/isNil'

import { createMiddleware } from 'pmt-modules/redux'
import { redirectTo } from 'pmt-modules/routing'

import { PostTopUpAction, resetTopUp } from './actions'

const postedCreditCardMiddleware = createMiddleware(
  PostTopUpAction.SUCCESS,
  ({ dispatch, next, action }) => {
    // TODO: move to kioskTopUp middleware, when we'll create it
    // used on top up kiosk to redirect to payment confirmation
    if (!isNil(action.data.options) && action.data.options.redirectTo) {
      const redirectToParams = action.data.options.redirectToParams || {}
      dispatch(redirectTo(action.data.options.redirectTo, redirectToParams))
    }

    next(action)
    return dispatch(resetTopUp(action.data.userAccount.id))
  }
)

export const topUpMiddlewares = [postedCreditCardMiddleware]
