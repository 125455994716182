import isNil from 'lodash/isNil'

import { formatPriceWithCurrency } from 'pmt-utils/currency'
import { createFormatter } from 'pmt-utils/format'

export const formatUserAccount = data => {
  if (!isNil(data.userAccount)) {
    data.userAccount.amountFormatted = formatPriceWithCurrency(data.userAccount.amount)
    if (!isNil(data.userAccount.subsidies)) {
      data.userAccount.subsidies.grantAmountFormatted = formatPriceWithCurrency(
        data.userAccount.subsidies.grantAmount
      )
    }
  }

  return data
}

export const formatVerifications = createFormatter(formatUserAccount)
