import isEmpty from 'lodash/isEmpty'

import transformOrderItemForApi from './transformOrderItemForApi'

const transformOrderProductOptionValueForApi = ({
  id,
  name,
  baseAdditionalPrice,
  additionalPrice,
  dietaryInfo,
  orderId,
}) => ({
  id: id,
  name: name,
  baseAdditionalPrice: baseAdditionalPrice || 0,
  additionalPrice: additionalPrice || 0,
  dietaryInfo,
  orderId,
})

const transformOrderProductOptionValuesForApi = values =>
  values
    // keep only selected values
    .filter(value => value.quantity > 0)
    .map(value => transformOrderProductOptionValueForApi(value))

const transformOrderProductOptionForApi = option => {
  const values = transformOrderProductOptionValuesForApi(option.values)
  if (isEmpty(values)) {
    return false
  }

  return {
    id: option.id,
    name: option.name,
    orderId: option.orderId,
    values,
  }
}

const transformOrderProductOptionsForApi = options => {
  let optionsToReturn = []
  // keep only valid options
  options.filter(value => value.isValid).forEach(option => {
    const optionTransformed = transformOrderProductOptionForApi(option)
    if (optionTransformed) {
      optionsToReturn.push(optionTransformed)
    }
  })

  return optionsToReturn
}
/**
 * Transform OrderProduct data for the API.
 *
 * - options:
 *    - isMenuMode {boolean}
 */
const transformOrderProductForApi = (orderProduct, options) => ({
  ...transformOrderItemForApi(orderProduct, options),
  sequence: orderProduct.sequence || 0,
  reclaimLater: orderProduct.reclaimLater || false,
  options: transformOrderProductOptionsForApi(orderProduct.options),
  baseAdditionalPrice: orderProduct.baseAdditionalPrice,
  additionalPrice: orderProduct.additionalPrice,
  // we don't have to handle the tax, only POS
  tax: null,
})

export default transformOrderProductForApi
