import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { getAuth } from 'pmt-modules/auth/selectors'
import { getRoute, redirectTo, goBack } from 'pmt-modules/routing'
import { logout } from 'pmt-modules/auth/actions'
import { fetchUserMe, getUserMe, isFetchingUserMe } from 'pmt-modules/userMe'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import Account from 'pmt-ui/svg-icons/action/account'
import ArrowDropDown from 'pmt-ui/svg-icons/navigation/arrow-drop-down'
import Menu, { MenuItem } from 'pmt-ui/Menu'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'
import { ButtonLink } from '../../Button'

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  login: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  icon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
    fill: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
  },
})

class User extends React.Component {
  constructor(props) {
    super(props)

    // we can't use withUserMe here since, we may not have auth info yet
    if (!this.props.user && this.props.auth && !this.props.isFetchingMe) {
      this.props.fetchUserMe()
    }
  }

  state = {
    anchorEl: null,
  }

  handleRedirectToAccount = () => {
    this.props.redirectTo(getRoute('ORDER__USER__ACCOUNT'), {
      userId: this.props.user.id,
    })
  }

  handleLogout = () => {
    this.props.logout()
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleClickOnLogin = () => {
    this.props.logout() // clean incognito or userlight cookie, the user wants to do a real login
    this.props.redirectTo(getRoute('LOGIN'))
  }

  render() {
    const { anchorEl } = this.state
    const { auth, classes, isFetchingMe, user, width } = this.props

    if (isFetchingMe) {
      return null
    }

    return (
      <div className={classes.root}>
        {!auth || !user ? (
          <ButtonLink
            className={classNames(classes.wrapper, classes.link)}
            onClick={this.handleClickOnLogin}
          >
            <Account className={classes.icon} />
            <TypographyCustom
              type={isWidthUp('sm', width) ? 146 : 126}
              className={classes.login}
              skipColor
              component="span"
            >
              {tr('order.global.login')}
            </TypographyCustom>
          </ButtonLink>
        ) : (
          <React.Fragment>
            <div
              className={classes.wrapper}
              aria-owns={anchorEl ? 'user-menu' : null}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              <Account className={classes.icon} />
              {isWidthUp('sm', width) && (
                <TypographyCustom type="146" skipColor component="span">
                  {user.fullName}
                </TypographyCustom>
              )}
              <ArrowDropDown />
            </div>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
              anchorReference="anchorEl"
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={this.handleRedirectToAccount}>
                {tr('order.global.account')}
              </MenuItem>
              <MenuItem onClick={this.handleLogout}>{tr('order.global.logout')}</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  auth: getAuth(),
  isFetchingMe: isFetchingUserMe(state),
  user: getUserMe(state),
})

export default compose(
  withStyles(styles),
  withWidth(),
  connect(mapStateToProps, {
    fetchUserMe,
    redirectTo,
    goBack,
    logout,
  })
)(User)
