import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Wheat = props => (
  <SvgIcon {...props}>
    <path
      d="M18.161,5.306,17.352,4.5l-1.3,1.3-2.594-.285,3.3-3.3L15.952,1.4l-3.3,3.3L12.363,2.11l1.3-1.3L12.855,0,2.985,9.871l.446,4.051L0,17.352l.809.809,3.43-3.43,4.051.446ZM7.194,10.158,6.909,7.564,8.6,5.874l.285,2.594Zm2.5-.881,2.594.285-1.69,1.69L8,10.967Zm5.321-2.442-1.69,1.69L10.73,8.24l1.69-1.69Zm-3.4-1.094-1.69,1.69L9.636,4.837l1.69-1.69Zm-7.43,4.551L5.872,8.6l.285,2.594-1.69,1.69Zm1.094,3.4L6.966,12l2.594.285-1.69,1.69Z"
      transform="translate(4 3)"
    />
  </SvgIcon>
)
Wheat = pure(Wheat)
Wheat.displayName = 'Wheat'
Wheat.muiName = 'SvgIcon'

export default Wheat
