import React from 'react'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import CatalogContainer from './CatalogContainer'

const getRestaurantIdOnProps = props => {
  if (!isNil(props.routeParams) && !isEmpty(props.routeParams.restaurantId)) {
    return props.routeParams.restaurantId
  }
  if (!isNil(props.params) && !isEmpty(props.params.restaurantId)) {
    return props.params.restaurantId
  }

  return get(props, 'orderProperties.restaurantId', null)
}

/**
 * Add the CatalogContainer as HOC for the given component
 * Requirements
 * - restaurantId in props or routeParams
 */
const withCatalog = CatalogWrappedComponent => {
  return props => (
    <CatalogContainer
      CatalogWrappedComponent={CatalogWrappedComponent}
      restaurantId={getRestaurantIdOnProps(props)}
      {...props}
    />
  )
}

export default withCatalog
