import React from 'react'

import clsx from 'clsx'

import { withStyles } from 'pmt-ui/styles'
import TextField from 'pmt-ui/TextField'

const styles = theme => ({
  numberFieldContainer: {
    display: 'inline-flex',
    position: 'relative',
  },
  textFieldWithSymbol: {
    '& > input': {
      width: 'calc(100% - 10px)',
    },
  },
  textField: {
    display: 'block',
  },
  numberFieldSymbol: {
    position: 'absolute',
    top: 24,
    right: 0,
  },
})

const NumberField = ({
  value,
  classes,
  className,
  symbol,
  onChange,
  min,
  max,
  pattern,
  step,
  withFixedDecimals = false,
  autoComplete = null,
  ...otherProps
}) => (
  <div
    className={clsx(
      classes.numberFieldContainer,
      className,
      otherProps.fullWidth && 'u-sizeFullWidth'
    )}
  >
    <TextField
      inputProps={{
        autoComplete,
        pattern: pattern || '\\d*',
        step: step || '1',
        className: clsx(classes.textField, symbol && classes.textFieldWithSymbol),
      }}
      value={value}
      type="number"
      /* InputProps={{
        inputProps: {
          className: clsx(
            classes.textField,
            symbol && classes.textFieldWithSymbol
          ),
        },
      }} */
      onChange={event => {
        let value = event.target.value ? parseFloat(event.target.value, 10) : ''
        value = min !== null && min > value ? min : value
        value = max !== null && max < value ? max : value
        // if value has fixed decimals, render a fixed value, otherwise render it as is
        // this is to be able to backspace on decimals
        value =
          withFixedDecimals && (value.toString().split('.')[1] || []).length > 2
            ? value.toFixed(withFixedDecimals)
            : value
        onChange(event, value)
      }}
      {...otherProps}
    />
    {symbol && <span className={classes.numberFieldSymbol}>{symbol}</span>}
  </div>
)

export default withStyles(styles)(NumberField)
