import Immutable from 'immutable'

import { TopUpAction, PostTopUpAction } from './actions'

import { DEFAULT_TOP_UP } from '../constants'

export * from './actions'
export * from './middlewares'
export * from './selectors'

const DEFAULT = Immutable.fromJS({})

export const topUpReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case TopUpAction.INIT_TOP_UP:
      return state.setIn(
        [action.userAccountId],
        Immutable.fromJS({
          isFetching: false,
          error: null,
          data: DEFAULT_TOP_UP(),
        })
      )

    case TopUpAction.RESET:
      return state.setIn(
        [action.userAccountId],
        Immutable.fromJS({
          isFetching: false,
          error: null,
          data: DEFAULT_TOP_UP(),
        })
      )

    case PostTopUpAction.REQUEST:
      return state.mergeIn([action.data.userAccount.id], {
        isFetching: true,
        error: null,
        created: false,
      })

    case PostTopUpAction.SUCCESS:
      return state
        .mergeIn([action.data.userAccount.id], {
          isFetching: false,
          error: null,
          created: true,
        })
        .mergeIn([action.data.userAccount.id, 'data'], {
          ...action.response,
          isUsingEmpotency: action.responseData.status === 200,
        })

    case PostTopUpAction.FAILURE:
      state = state.mergeIn([action.data.userAccount.id], {
        isFetching: false,
        error: action.error,
      })
      if (action.error.body) {
        state = state.mergeIn([action.data.userAccount.id], {
          data: action.error.body,
        })
      }
      return state

    case TopUpAction.SELECT_AMOUNT:
      return state.setIn([action.userAccountId, 'data', 'amount'], action.amount)

    case TopUpAction.UNSELECT_AMOUNT:
      return state.setIn([action.userAccountId, 'data', 'amount'], 0)

    case TopUpAction.CLICK_OTHER_AMOUNT:
      return state.setIn([action.userAccountId, 'data', 'otherAmountClicked'], true)

    default:
      return state
  }
}
