import cloneDeep from 'lodash/cloneDeep'
import ApiEndpoints from '../config/ApiEndpoints'
import UserCardApi from './UserCardApi'
import UserErrors from '../errors/UserErrors'

import { cleanForApi } from 'pmt-utils/phone'
import { saveUserLightCookie } from '../../auth/utils'
import UserType from 'pmt-modules/user/constants/UserType'

const RegisterApi = {
  postUser: user => {
    const newUser = cloneDeep(user)
    newUser.phone = cleanForApi((newUser.phone || '').replace(/ /g, ''))

    return {
      endpoint: ApiEndpoints.REGISTER,
      type: 'POST',
      body: newUser,
      query: {
        sendRegistrationEmail: false,
        sendPhoneVerificationCode: false,
      },
      errorHandler: UserErrors.POST_USER,
    }
  },

  postIncognitoUser: () => {
    const user = { userType: UserType.INCOGNITO }
    return {
      endpoint: ApiEndpoints.REGISTER,
      type: 'POST',
      body: user,
      query: {
        sendRegistrationEmail: false,
        sendPhoneVerificationCode: false,
      },
      header: {
        authorization: null,
      },
      errorHandler: UserErrors.POST_USER,
    }
  },

  postUserLightUser: user => {
    user.userType = UserType.LIGHT
    return {
      endpoint: ApiEndpoints.REGISTER,
      type: 'POST',
      body: user,
      query: {
        sendRegistrationEmail: false,
        sendPhoneVerificationCode: false,
      },
      header: {
        authorization: null,
      },
    }
  },

  postUserLightWithCreditCard: (user, card) => {
    user.userType = UserType.LIGHT
    return {
      endpoint: ApiEndpoints.REGISTER,
      type: 'POST',
      body: user,
      query: {
        sendRegistrationEmail: false,
        sendPhoneVerificationCode: false,
      },
      header: {
        authorization: null,
      },
      then: (previousOptions, json) => {
        saveUserLightCookie(user)
        return UserCardApi.postUserLightUserCard(card, user)
      },
    }
  },
}

export default RegisterApi
