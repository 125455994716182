import { tr } from 'pmt-modules/i18n'
import { notEmpty, isValidEmail } from 'pmt-modules/form/validation'

import createForm from 'pmt-modules/form/createForm'

import FormType from 'pmt-modules/form/FormType'

export ForgotPasswordFormView from './ForgotPasswordFormView'

// in DEV environment, prefill form with the following data
const devData = {
  username: '',
}

const defaultData = {
  username: null,
}

const validationRules = () => ({
  username: [
    [notEmpty, tr('global.register.form.validation.required.email')],
    [isValidEmail, tr('global.register.form.validation.email_invalid')],
  ],
})

export default createForm(FormType.FORGOT_PASSWORD, validationRules, defaultData, devData)
