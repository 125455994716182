import { createMiddleware } from '../redux'

import {
  GetRestaurantAction,
} from './actions'

import { getCurrentTheme, changeCurrentTheme } from '../theme'

import { createMuiThemeWithPmtRestaurantColors } from 'pmt-utils/theme/theme'

/**
* Intercepts GET restaurant and update theme if succeeded
* Otherwise just sends action to next middleware
*
* @returns {Function}
*/
export const restaurantThemeMiddleware = createMiddleware(
  GetRestaurantAction.SUCCESS,
  ({ getState, dispatch, next, action }) => {

    const currentTheme = getCurrentTheme(getState())
    const newTheme = createMuiThemeWithPmtRestaurantColors(currentTheme, action.response.colors)

    next(changeCurrentTheme(newTheme))
  }
)
