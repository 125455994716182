import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { fromApp } from 'pmt-modules/orderPlugin'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import Grid from 'pmt-ui/Grid'
import Hidden from 'pmt-ui/Hidden'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import { isWidthUp } from 'pmt-ui/utils/withWidth'
import { TypographyCustom } from 'pmt-ui/Typography'
import ActionInfoOutline from 'pmt-ui/svg-icons/action/info-outline'

import { Categories, ItemList, SideInformation } from './components'
import BlockContainer from '../../components/BlockContainer'
import CategoriesBreadcrumb from './components/CategoriesBreadcrumb'
import MobileCartButton from './components/MobileCartButton'
import MessageBlock from '../../components/MessageBlock'
import Button from '../../components/Button'

const styles = (theme) => ({
  root: {
    // overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px 0`,
    },
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  iconInfo: {
    width: 30,
    height: 30,
    marginBottom: theme.spacing(1),
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  loadingBlock: {
    padding: '50px 0',
  },
  error: {
    textAlign: 'center',
    height: '100%',
    '&> button': {
      marginTop: theme.spacing(3),
    },
  },
  textFooter: {
    marginTop: theme.spacing(5),
    color: theme.pmt.colors.grey600,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    whiteSpace: 'pre-line', // to display \n as new lines
    lineHeight: '1.5',
  },
  containerCategory: {
    borderBottomLeftRadius: theme.shape.border.radius.main,
    borderBottomRightRadius: theme.shape.border.radius.main,
    [theme.breakpoints.down('sm')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  containerCategoryWithoutBreadcrumb: {
    borderRadius: theme.shape.border.radius.main,
    [theme.breakpoints.down('sm')]: {
      margin: 20,
    },
  },
})

const View = ({
  appConfigPreset,
  classes,
  isFetchingCatalog,
  isFetchingRestaurant,
  catalog,
  catalogError,
  restaurantError,
  restaurant,
  route,
  onRedirectToCart,
  selectedCategory,
  parentCategories,
  isSubCategory,
  from,
  width,
  orderProperties,
  handleRedirectToStoreLocator,
}) => (
  <div className={classes.root}>
    <Grid spacing={2} container>
      <Grid item container direction="column" spacing={0} xs={12} md={7} lg={8} wrap="nowrap">
        {!isFetchingRestaurant && restaurant && !isFetchingCatalog && catalog && (
          <React.Fragment>
            {selectedCategory ? (
              <Categories
                catalog={catalog}
                categories={catalog.categories}
                restaurant={restaurant}
                selectedCategory={selectedCategory}
                parentCategories={parentCategories}
              />
            ) : (
              <Categories
                categories={catalog.categories}
                restaurant={restaurant}
                selectedCategory={null}
              />
            )}
          </React.Fragment>
        )}
        <BlockContainer
          className={classNames(
            'u-flex1',
            // this conditions represent the cases in which the breadcrumb is shown
            !(
              (isFetchingRestaurant || isNil(restaurant) || isFetchingCatalog || isNil(catalog)) &&
              isNil(restaurantError) &&
              isNil(catalogError)
            ) &&
              ((isNil(restaurant) && !isFetchingRestaurant && restaurantError) ||
                (isNil(catalog) && !isFetchingCatalog && catalogError) ||
                selectedCategory)
              ? classes.containerCategory
              : classes.containerCategoryWithoutBreadcrumb
          )}
          type={isWidthUp('md', width) ? BlockContainer.Type.WHITE : BlockContainer.Type.LIGHTGREY}
          mobilePadding={isSubCategory ? 0 : '10px 0'}
        >
          {(isFetchingRestaurant || isNil(restaurant) || isFetchingCatalog || isNil(catalog)) &&
          isNil(restaurantError) &&
          isNil(catalogError) ? (
            <LoadingBlock
              show
              classes={{
                loadingBlock: classes.loadingBlock,
              }}
            />
          ) : isNil(restaurant) && !isFetchingRestaurant && restaurantError ? (
            <div className={classNames('u-flexCenter', classes.error)}>
              <ErrorBlock
                error={restaurantError}
                mode={ErrorBlock.Mode.CUSTOM}
                customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
              />
            </div>
          ) : isNil(catalog) && !isFetchingCatalog && catalogError ? (
            <div className={classNames('u-flexColumn u-flexCenter', classes.error)}>
              <ErrorBlock
                error={catalogError}
                mode={ErrorBlock.Mode.CUSTOM}
                customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
              />
              <Button onClick={handleRedirectToStoreLocator}>
                {tr('order.global.choose_restaurant')}
              </Button>
            </div>
          ) : selectedCategory ? (
            <React.Fragment>
              <div className={classes.content}>
                <CategoriesBreadcrumb
                  selectedCategory={selectedCategory}
                  parentCategories={parentCategories}
                  restaurant={restaurant}
                />
                <ItemList
                  items={selectedCategory.items}
                  subCategories={selectedCategory.categories}
                  catalog={catalog}
                  restaurant={restaurant}
                  selectedCategory={selectedCategory}
                  parentCategories={parentCategories}
                />
              </div>
              <CustomTextsContainer>
                {({ texts }) =>
                  !isNil(texts) &&
                  !isNil(texts.ORDER__CATALOG__MENU__FOOTER) && (
                    <TypographyCustom type="124" className={classes.textFooter}>
                      {texts.ORDER__CATALOG__MENU__FOOTER}
                    </TypographyCustom>
                  )
                }
              </CustomTextsContainer>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="u-marginTop60 u-marginBottom60 u-textAlignCenter">
                <ActionInfoOutline className={classNames(classes.iconInfo, classes.grey500)} />
                <TypographyCustom type="207" align="center" className={classes.grey500}>
                  {tr('order.menu.no_item_in_selected_category')}
                </TypographyCustom>
              </div>
            </React.Fragment>
          )}
        </BlockContainer>
      </Grid>

      <Hidden smDown>
        <Grid item md={5} lg={4}>
          <SideInformation
            orderProperties={orderProperties}
            restaurant={restaurant}
            enableModifyTableNumber={from !== fromApp.WEBAPP}
            // cartModifiers={catalog ? catalog.cartModifiers : []}
            onRedirectToCart={onRedirectToCart}
            route={route}
          />
        </Grid>
      </Hidden>
      <MobileCartButton appConfigPreset={appConfigPreset} onRedirectToCart={onRedirectToCart} />
    </Grid>
  </div>
)

export default withStyles(styles)(View)
