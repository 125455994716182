import isEmpty from 'lodash/isEmpty'
import { PspType } from 'pmt-modules/restaurant/constants'
import { isProd } from 'pmt-modules/environment'

// Note: all of this is duplicated on the API, maybe the API should serialize those for audit permissions?

export const getPspBoUrl = psp => {
  switch (psp) {
    case PspType.PAYGREEN:
      if (isProd()) {
        return 'https://paygreen.fr'
      }
      return 'https://sandbox.paygreen.fr'

    case PspType.MANGOPAY:
      if (isProd()) {
        return 'https://dashboard.mangopay.com'
      }
      return 'https://dashboard.sandbox.mangopay.com'

    case PspType.SAFERPAY:
      // TODO: we can have saferpay tests on production..
      if (isProd()) {
        return 'https://www.saferpay.com/BO/'
      }
      return 'https://test.saferpay.com/BO/'

    default:
      return ''
  }
}

export const formatPspShopUrl = (psp, pspRestaurantId) => {
  if (isEmpty(pspRestaurantId)) {
    return ''
  }
  switch (psp) {
    case PspType.MANGOPAY:
      return getPspBoUrl(psp) + `/User/${pspRestaurantId}/Details`

    case PspType.PAYGREEN:
      return getPspBoUrl(psp) + `/shop/switch-current/id/${pspRestaurantId}`

    default:
      return ''
  }
}

export const formatPspUserUrl = pspUserId => {
  if (isEmpty(pspUserId)) {
    return ''
  }
  // TODO: we do not have the user's psp for now.. Maybe we could trick:
  // only MangoPay has a pspUserId for now. For PayGreen we generate a big string. It could help
  // differentiate both.
  if (pspUserId && pspUserId.length < 15) {
    // same as formatPspShopUrl
    return getPspBoUrl(PspType.MANGOPAY) + `/User/${pspUserId}/Details`
  }
  return ''
}

export const formatPspTransactionUrl = (psp, transactionId) => {
  switch (psp) {
    case PspType.PAYGREEN:
      return getPspBoUrl(psp) + '/transactions/show-details/id/' + transactionId

    case PspType.MANGOPAY:
      return getPspBoUrl(psp) + '/PayIn/' + transactionId

    case PspType.SAFERPAY:
      return getPspBoUrl(psp) + '/Commerce/JournalDetail?gxid=' + transactionId

    default:
      return ''
  }
}

export const formatPspRefundUrl = (psp, refundId) => {
  switch (psp) {
    case PspType.PAYGREEN:
      return getPspBoUrl(psp) + '/transactions/show-details/id/' + refundId

    case PspType.MANGOPAY:
      return getPspBoUrl(psp) + '/Refund/' + refundId

    case PspType.SAFERPAY:
      return getPspBoUrl(psp) + '/Commerce/JournalDetail?gxid=' + refundId

    default:
      return ''
  }
}
