import React from 'react'
import ui from 'pmt-modules/reduxUi'
import isNil from 'lodash/isNil'

import compose from 'recompose/compose'

import PartView from './PartView'
import { goToAnchor } from 'pmt-ui/ScrollableAnchor'
import { withStyles } from 'pmt-ui/styles'

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
    '&>:first-child': {
      paddingTop: '0px !important',
      borderTop: 'none',
    },
  },
})

class PartsView extends React.PureComponent {
  constructor(props) {
    super(props)

    // for each part, we add a product to the menu
    // if only one product in part, no option, and min === max === 1
    props.orderMenu.parts.forEach((part) => {
      if (
        part.min === 1 &&
        part.min === part.max &&
        part.products.length === 1 &&
        isNil(part.categories) &&
        part.nbChosenValues === 0
      ) {
        const productSelected = part.products[0]
        if (!productSelected.hasOptions) {
          props.onAddToCartFromShortcut(part, part, productSelected)
        }
      }
    })
  }

  render() {
    const {
      orderMenu,
      restaurant,
      classes,
      onSelectOrderProduct,
      onUnselectOrderProduct,
      onEditOrderProduct,
      onAddToCartFromShortcut,
      onSelectCategory,
      onReturnToPreviousPartCategory,
      ui,
      updateUI,
    } = this.props

    return (
      <div className={classes.root}>
        {orderMenu.parts.map((part) => (
          <PartView
            orderMenu={orderMenu}
            key={part.id}
            part={part}
            currentPart={orderMenu.currentPart}
            restaurant={restaurant}
            onSelectOrderProduct={onSelectOrderProduct}
            onUnselectOrderProduct={onUnselectOrderProduct}
            onEditOrderProduct={onEditOrderProduct}
            onAddToCartFromShortcut={onAddToCartFromShortcut}
            onSelectCategory={onSelectCategory}
            onReturnToPreviousPartCategory={onReturnToPreviousPartCategory}
            // on desktop, the parts are always opened.
            // on mobile, we chose the one to display
            isOpen={ui.openedPart === part.id}
            onOpen={(toggleAnchor = true) => {
              updateUI({
                // toggle opened part
                openedPart: ui.openedPart === part.id ? null : part.id,
              })
              if (toggleAnchor) {
                goToAnchor(part.anchor, true)
              }
            }}
          />
        ))}
      </div>
    )
  }
}

export default compose(
  ui({
    state: {
      // on mobile mode, we toggle the part to display
      // by default, no part is shown
      openedPart: null,
    },
  }),
  withStyles(styles)
)(PartsView)
