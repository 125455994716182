import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Back from 'pmt-ui/svg-icons/action/back'

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
    },
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'opacity 0.35s ease',
    '&:hover': {
      opacity: 0.6,
    },
  },
  icon: {
    marginRight: theme.spacing(1.5),
    height: 10,
    width: 'auto',
  },
})

const BannerView = ({ classes, isFromCart, returnLabel = false, onRedirectToPreviousPage }) => (
  <div className={classes.root} onClick={onRedirectToPreviousPage}>
    <TypographyCustom type="226" skipColor>
      <span className={classes.content}>
        <Back className={classes.icon} />
        {returnLabel
          ? returnLabel
          : !isFromCart
          ? tr('order.global.back_to_catalog')
          : tr('order.global.back_to_cart')}
      </span>
    </TypographyCustom>
  </div>
)

export default withStyles(styles)(BannerView)
