import { createSelector } from 'reselect'
import invariant from 'invariant'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { getFormatter } from './config'

const getStatisticsState = state => state.statistics
const getStatisticsGroupType = (state, props) => {
  invariant(!isEmpty(props.groupType), `Missing 'groupType' prop`)
  return props.groupType
}
const getStatisticsType = (state, props) => {
  invariant(!isEmpty(props.type), `Missing 'type' prop`)
  return props.type
}

const makeGetStatisticsGroupTypeData = () =>
  createSelector([getStatisticsState, getStatisticsGroupType], (statisticsState, groupType) => {
    if (isNil(statisticsState) || isNil(statisticsState.get(groupType))) {
      return null
    }

    return statisticsState.get(groupType)
  })

export const makeGetStatisticsOptions = () =>
  createSelector([makeGetStatisticsGroupTypeData()], statisticsData => {
    if (isNil(statisticsData) || isNil(statisticsData.get('options'))) {
      return null
    }

    return statisticsData.get('options').toJS()
  })

export const makeGetStatisticsConfig = () =>
  createSelector([makeGetStatisticsGroupTypeData()], statisticsData => {
    if (isNil(statisticsData) || isNil(statisticsData.get('config'))) {
      return null
    }

    return statisticsData.get('config').toJS()
  })

// returns the items for the stats
export const makeGetStatisticsItems = () =>
  createSelector(
    [
      makeGetStatisticsGroupTypeData(),
      getStatisticsGroupType,
      getStatisticsType,
      makeGetStatisticsOptions(),
    ],
    (groupStatisticsState, groupType, type, options) => {
      if (isNil(groupStatisticsState)) {
        return null
      }

      const statisticsData = groupStatisticsState.get(type)

      if (isNil(statisticsData) || isNil(statisticsData.get('data'))) {
        return null
      }

      if (isNil(statisticsData) || isNil(statisticsData.get('isFetching'))) {
        return false
      }

      const isFetching = statisticsData.get('isFetching')

      // if we are fetching the data, we don't want to return data:
      // the options has changed, but not the data, so we would call the formatter with inconsistant
      // data.
      // avoid not necessary computing, that could lead to crashed due to the inconsistant data
      if (isFetching) {
        return null
      }

      const dataState = statisticsData.get('data')

      if (!dataState) {
        return null
      }

      const itemsState = dataState.get('items')

      let data = null
      if (!itemsState) {
        // some requests, like Top up balance for day does not have items list but directly a json.
        // we need to use this data
        data = dataState.toJS()
      } else {
        // otherwise use items
        data = itemsState.toJS()
      }

      // look for a formatter
      const formatter = getFormatter(groupType, type)
      if (formatter) {
        return formatter(data, options)
      }

      return data
    }
  )

export const makeIsFetchingStatistics = () =>
  createSelector(
    [makeGetStatisticsGroupTypeData(), getStatisticsType],
    (groupStatisticsState, type) => {
      if (isNil(groupStatisticsState)) {
        return null
      }

      const statisticsData = groupStatisticsState.get(type)

      if (isNil(statisticsData) || isNil(statisticsData.get('isFetching'))) {
        return false
      }

      return statisticsData.get('isFetching')
    }
  )

export const makeGetStatisticsError = () =>
  createSelector(
    [makeGetStatisticsGroupTypeData(), getStatisticsType],
    (groupStatisticsState, type) => {
      if (isNil(groupStatisticsState)) {
        return null
      }

      const statisticsData = groupStatisticsState.get(type)

      if (isNil(statisticsData) || isNil(statisticsData.get('error'))) {
        return null
      }

      return statisticsData.get('error').toJS()
    }
  )
