import React from 'react'

import OrderRestaurantListContainer from 'pmt-modules/orderFront/container/OrderRestaurantListContainer'
import GeolocationContainer from 'pmt-modules/geolocation/container/GeolocationContainer'

import Grid from 'pmt-ui/Grid'
import Hidden from 'pmt-ui/Hidden'
import { withStyles } from 'pmt-ui/styles'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

import WhereSection from './components/WhereSection'
import WhenSection from './components/WhenSection'

const styles = theme => ({
  gridContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
})

/**
 * @mockup N/A
 */
const View = ({
  orderProperties,
  restaurant,
  restaurantError,
  handleSetTableNumber,
  handleMenuRedirection,
  googleMapURL,
  ui,
  updateUI,
  classes,
}) => (
  <div className="u-overflowHidden">
    <GeolocationContainer>
      {({ geolocationCoordinates }) => (
        <OrderRestaurantListContainer
          mode={orderProperties.mode}
          isDelivery={orderProperties.isDelivery}
          lat={geolocationCoordinates.latitude}
          lng={geolocationCoordinates.longitude}
        >
          {({
            loadRestaurantList,
            isFetchingRestaurantList,
            restaurantListData,
            restaurantListError,
          }) => (
            <Grid spacing={2} container className={classes.gridContainer}>
              <Hidden mdUp>
                <LoadingBlockWrapper show={isFetchingRestaurantList} />
              </Hidden>

              <WhereSection
                ui={ui}
                updateUI={updateUI}
                orderProperties={orderProperties}
                restaurantError={restaurantError}
                restaurant={restaurant}
                loadRestaurantList={loadRestaurantList}
                isFetchingRestaurantList={isFetchingRestaurantList}
                restaurantListData={restaurantListData}
                restaurantListError={restaurantListError}
                googleMapURL={googleMapURL}
                loadingElement={<div />}
              />
              <WhenSection
                ui={ui}
                updateUI={updateUI}
                orderProperties={orderProperties}
                restaurant={restaurant}
                handleMenuRedirection={handleMenuRedirection}
                handleSetTableNumber={handleSetTableNumber}
                isFetchingRestaurantList={isFetchingRestaurantList}
              />
            </Grid>
          )}
        </OrderRestaurantListContainer>
      )}
    </GeolocationContainer>
  </div>
)

export default withStyles(styles)(View)
