import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'
import { authenticateUser, getAuthError, isFetchingAuth } from 'pmt-modules/auth'
import { registerAsIncognito } from 'pmt-modules/registration'
import { LoginFormView } from 'pmt-modules/auth/forms/login'
import { EventManager } from 'pmt-modules/event'
import { getAppConfig } from 'pmt-modules/appConfig'
import { FormType, withForm } from 'pmt-modules/form'
import { getAppConfigFrontSettings } from 'pmt-modules/orderPlugin'
import { isFetchingOrderPost, getErrorOrderPost } from 'pmt-modules/orderPost'
import { isFetchingUserMe } from 'pmt-modules/userMe'

import ErrorBlock from 'pmt-ui/ErrorBlock'
import LabelDivider from 'pmt-ui/LabelDivider'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import Typography, { TypographyCustom } from 'pmt-ui/Typography'

import Button, { ButtonLink } from '../../../components/Button'
import MessageBlock from '../../../components/MessageBlock'
import { LoginViews } from '../constants'

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(2.5),
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  allowIncognitoButton: {
    marginTop: theme.spacing(4),
    float: 'right',
    padding: 10,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 0,
    },
  },
  forgottenPassword: {
    color: theme.palette.primary.light,
    fontStyle: 'normal',
    textDecoration: 'underline',
    fontWeight: 500,
  },
  switchView: {
    clear: 'both',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  subheader: {
    color: theme.pmt.colors.grey500,
    marginBottom: theme.spacing(2),
  },
  signUpButton: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
})

class LoginForm extends React.PureComponent {
  handleSubmitLogin = () => {
    const {
      authenticateUser,
      formData,
      cartItemList,
      restaurant,
      clientId,
      clientSecret,
    } = this.props

    EventManager.dispatch(EventManager.Events.ON_LOGIN_SUBMIT_LOGIN, {
      items: cartItemList,
      restaurant,
    })

    authenticateUser(formData.username, formData.password, { clientId, clientSecret })
  }

  render() {
    const {
      authError,
      cartItemList,
      classes,
      forgottenPasswordLink,
      formIsValid,
      frontSettings,
      isFetchingAuth,
      isFetchingUserMe,
      isFetchingOrderPost,
      orderPostError,
      orderProperties,
      onToggleView,
      allowIncognito,
    } = this.props

    return (
      <React.Fragment>
        <TypographyCustom className="u-marginBottom20" type="226">
          {tr('order.login.already_customer')}
        </TypographyCustom>
        <CustomTextsContainer>
          {({ texts }) =>
            !isNil(texts) &&
            !isNil(texts.ORDER__LOGIN__SUBHEADER) && (
              <Typography variant="subtitle1" className={classes.subheader}>
                {texts.ORDER__LOGIN__SUBHEADER}
              </Typography>
            )
          }
        </CustomTextsContainer>

        <LoadingBlockWrapper show={isFetchingAuth || isFetchingUserMe || isFetchingOrderPost} />

        <ErrorBlock
          error={authError || orderPostError}
          mode={ErrorBlock.Mode.CUSTOM}
          customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
        />

        <form
          onSubmit={(e) => {
            e.preventDefault()
            this.handleSubmitLogin()
          }}
        >
          <LoginFormView
            classes={{
              forgottenPassword: classes.forgottenPassword,
            }}
            forgottenPasswordLink={forgottenPasswordLink}
            orderPlugin={false}
          />
          <Button
            type="submit"
            size="large"
            disabled={!formIsValid || isFetchingAuth || isFetchingUserMe || isFetchingOrderPost}
            classes={{ root: classes.button }}
            label={tr(
              frontSettings.display.paymentPage ||
                isEmpty(cartItemList) ||
                orderProperties.isDelivery
                ? 'order.login.connect_button'
                : 'order.login.connect_button_and_order'
            )}
          />
        </form>

        <div className={classes.switchView}>
          <LabelDivider label={tr('order.login.or')} />
          <Button
            className={classes.signUpButton}
            variant="outlined"
            size="large"
            onClick={() => onToggleView(LoginViews.REGISTER)}
            label={tr('order.login.create_account')}
          />
          {allowIncognito && (
            <React.Fragment>
              <div className={classes.allowIncognitoButton}>
                <ButtonLink
                  component="a"
                  onClick={(e) => {
                    e.stopPropagation()
                    onToggleView(LoginViews.INCOGNITO)
                  }}
                  label={tr('global.register.continue_as_incognito')}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  authError: getAuthError(state),
  frontSettings: getAppConfigFrontSettings(state),
  isFetchingAuth: isFetchingAuth(state),
  isFetchingOrderPost: isFetchingOrderPost(state),
  isFetchingUserMe: isFetchingUserMe(state),
  orderPostError: getErrorOrderPost(state),
  allowIncognito: getAppConfig(state).authentication.allowIncognito,
})

export default compose(
  withStyles(styles),
  withForm(FormType.LOGIN),
  connect(mapStateToProps, {
    authenticateUser,
    registerAsIncognito,
  })
)(LoginForm)
