import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import ui from 'pmt-modules/reduxUi'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import { StickyContainer } from 'pmt-ui/Sticky'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import Button from 'pmt-ui/Button'
import Grid from 'pmt-ui/Grid'
import Hidden from 'pmt-ui/Hidden'

import BlockContainer from '../../../components/BlockContainer'
import MessageBlock from '../../../components/MessageBlock'
import BannerView from '../../../components/BannerView'

import LeftBlock from './components/LeftBlock'
import ProfileForm from './components/ProfileForm'
import DietaryInfo from './components/DietaryInfo'
import PaymentMethods from './components/PaymentMethods'
import DeliveryAddresses from './components/DeliveryAddresses'
// import PastOrders from './components/PastOrders'

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
    background: theme.pmt.colors.white,
    borderRadius: theme.shape.border.radius.secondaryContent,
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px 0`,
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      marginTop: 0,
    },
  },
  blockContainerMain: {
    height: '100%',
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.mobile.container.paddingHorizontal,
      paddingRight: theme.mobile.container.paddingHorizontal,
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  gridChild: {
    paddingTop: 0,
    paddingBottom: 0,
    maxWidth: 1000,
  },
  hr: {
    width: '90%',
    margin: '0 auto',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderTop: `1px solid ${theme.pmt.colors.grey97}`,
  },
  buttonDisconnect: {
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    marginBottom: theme.spacing(3),
    textTransform: 'initial',
  },
  buttonDisconnectTypo: {
    lineHeight: 1,
  },
})

const BlockType = {
  PROFILE_FORM: 'PROFILE_FORM',
  DIETARY_INFO: 'DIETARY_INFO',
  PAYMENT_METHODS: 'PAYMENT_METHODS',
  DELIVERY_ADDRESS: 'DELIVERY_ADDRESS',
  PAST_ORDERS: 'PAST_ORDERS',
}
class View extends React.PureComponent {
  handleOnClickBlock = (type) => {
    if (type === this.props.ui.openedBlock) {
      this.props.updateUI({
        openedBlock: null,
      })
    } else {
      this.props.updateUI({
        openedBlock: type,
      })
    }
  }

  render() {
    const {
      classes,
      appConfig,
      frontAppConfig,
      itemListFromCart,
      hasPhoneRequired,
      restaurantsGroup,
      isFetching,
      isFetchingPutUserMe,
      errors,
      onSubmit,
      user,
      ui,
      handleLogout,
      handleGoBack,
      submittedForm,
    } = this.props

    return (
      <div className={`${classes.root}`}>
        <BannerView
          onRedirectToPreviousPage={handleGoBack}
          isFromCart={true}
          returnLabel={tr('order.global.return_order')}
        />
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <BlockContainer
              className={classNames(classes.blockContainerMain, 'u-relative', 'u-overflowHidden')}
            >
              {!isNil(user) ? (
                <React.Fragment>
                  <LoadingBlockWrapper show={isFetching} className="u-padding40" />
                  <ErrorBlock
                    error={errors}
                    mode={ErrorBlock.Mode.CUSTOM}
                    customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
                  />
                  <TypographyCustom type="227" className={classes.title}>
                    {tr('order.profile.my_account')}
                  </TypographyCustom>
                  <StickyContainer>
                    <Grid container spacing={5}>
                      <Hidden mdDown>
                        <Grid item xs={3} className={classes.gridChild}>
                          <LeftBlock
                            appConfig={appConfig}
                            frontAppConfig={frontAppConfig}
                            handleLogout={handleLogout}
                            isFlex={false}
                            blockType={BlockType}
                          />
                        </Grid>
                      </Hidden>
                      <Grid item xs={12} lg={9} className={classes.gridChild}>
                        <ProfileForm
                          frontAppConfig={frontAppConfig}
                          user={user}
                          itemListFromCart={itemListFromCart}
                          defaultData={{
                            email: user.email,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            phone: user.phone,
                            marketing: {
                              allowContact: user.marketing.allowContact,
                            },
                          }}
                          hasPhoneRequired={hasPhoneRequired}
                          forgottenPasswordLink={
                            !isNil(restaurantsGroup) ? restaurantsGroup.forgottenPasswordLink : null
                          }
                          isFetching={isFetching}
                          isFetchingPutUserMe={isFetchingPutUserMe}
                          onSubmit={onSubmit}
                          anchor={BlockType.PROFILE_FORM}
                          handleOnClickBlock={() => this.handleOnClickBlock(BlockType.PROFILE_FORM)}
                          opened={ui.openedBlock === BlockType.PROFILE_FORM}
                          submittedForm={submittedForm}
                        />
                        <DietaryInfo
                          anchor={BlockType.DIETARY_INFO}
                          defaultData={user.dietaryPreferences}
                          handleOnClickBlock={() => this.handleOnClickBlock(BlockType.DIETARY_INFO)}
                          isFetchingPutUserMe={isFetchingPutUserMe}
                          onSubmit={onSubmit}
                          opened={ui.openedBlock === BlockType.DIETARY_INFO}
                          submittedForm={submittedForm}
                          user={user}
                        />
                        <PaymentMethods
                          user={user}
                          isFetchingPutUserMe={isFetchingPutUserMe}
                          anchor={BlockType.PAYMENT_METHODS}
                          handleOnClickBlock={() =>
                            this.handleOnClickBlock(BlockType.PAYMENT_METHODS)
                          }
                          opened={ui.openedBlock === BlockType.PAYMENT_METHODS}
                        />
                        {appConfig.order.modesFormatted.hasDelivery && (
                          <DeliveryAddresses
                            user={user}
                            anchor={BlockType.DELIVERY_ADDRESS}
                            handleOnClickBlock={() =>
                              this.handleOnClickBlock(BlockType.DELIVERY_ADDRESS)
                            }
                            opened={ui.openedBlock === BlockType.DELIVERY_ADDRESS}
                          />
                        )}
                        {/* <PastOrders
                          rGroupId={!isNil(restaurantsGroup) ? restaurantsGroup.id : null}
                          user={user}
                          anchor={BlockType.PAST_ORDERS}
                          handleOnClickBlock={() => this.handleOnClickBlock(BlockType.PAST_ORDERS)}
                          opened={ui.openedBlock === BlockType.PAST_ORDERS}
                        /> */}
                        <Hidden lgUp>
                          <div className="u-textAlignCenter u-marginTop25">
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.buttonDisconnect}
                              onClick={handleLogout}
                            >
                              <TypographyCustom
                                type="164"
                                skipColor
                                className={classes.buttonDisconnectTypo}
                              >
                                {tr('order.global.logout')}
                              </TypographyCustom>
                            </Button>
                          </div>
                        </Hidden>
                      </Grid>
                    </Grid>
                  </StickyContainer>
                </React.Fragment>
              ) : (
                <LoadingBlockWrapper show />
              )}
            </BlockContainer>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default compose(
  ui({
    state: {
      openedBlock: null,
    },
  }),
  withStyles(styles)
)(View)
