import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Milk = props => (
  <SvgIcon {...props}>
    <path
      d="M59.439,3.135V0h-9.9V3.135L46.87,6.694V18.886H62.106V6.694ZM55.9,6.3H48.622l1.791-2.391h7.275Zm2.374-5.136V2.746H50.7V1.168Zm-10.233,6.3H55.6V17.718H48.038Zm12.9,10.245H56.773V7.083L58.855,4.3l2.082,2.779Z"
      transform="translate(-43 3)"
    />
  </SvgIcon>
)
Milk = pure(Milk)
Milk.displayName = 'Milk'
Milk.muiName = 'SvgIcon'

export default Milk
