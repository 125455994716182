import { createSelector } from 'reselect'

const getState = state => state.entities.appSecurity

export const getAppSecurityError = createSelector([getState], state => {
  const error = state.get('error', null)

  if (!error) {
    return null
  }

  return error.toJS()
})
