import React from 'react'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'

import invariant from 'invariant'

import { compose } from 'redux'
import withWidth from 'pmt-ui/utils/withWidth'

import PopoverView from './PopoverView'
import DialogView from './DialogView'

import ListChooserType from './ListChooserType'
import Body from './components/Body'
import Header from './components/Header'

let Fuse = null
if (process.env.FEATURE_FUSE) {
  Fuse = require('pmt-utils/fuse').default
}

const showAsPopoverBreakpoints = ['md', 'lg', 'xl']

class Content extends React.Component {
  state = {
    query: '',
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.open !== this.props.open ||
      nextProps.value !== this.props.value ||
      nextProps.options !== this.props.options ||
      nextProps.isFetching !== this.props.isFetching ||
      nextState.query !== this.state.query ||
      nextProps.paging !== this.props.paging
    )
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.open && nextProps.open) {
      // reset query if open the dialog
      this.setState({
        query: '',
      })
    }
  }

  getForType(type, viewProps) {
    switch (type) {
      case ListChooserType.POPOVER:
        return <PopoverView {...viewProps} />

      case ListChooserType.DIALOG:
        return <DialogView {...viewProps} />

      default:
        invariant(false, `invalid ListChooserType ${type}`)
    }
  }

  handleQueryChange = event => {
    this.setState({
      query: event.target.value,
    })

    if (this.props.onQueryChange) {
      this.props.onQueryChange(event.target.value)
    }
  }

  render() {
    const props = this.props

    let values = props.values || props.value

    // transform to array
    if (!isArray(values)) {
      values = [values]
    }

    let filteredOptions = props.options

    if (this.state.query.length > 0 && !this.props.disableFiltering) {
      const fuseOptions = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        findAllMatches: false,
        distance: 50,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ['label', 'value', 'search'],
      }

      // see https://sentry.io/share/issue/6924fc536e934857ba95ee033a45311e/
      if (!isEmpty(props.options)) {
        const fuse = new Fuse(props.options, fuseOptions) // "list" is the item array
        filteredOptions = fuse.search(this.state.query)
      }
    }

    const viewProps = {
      open: props.open,
      anchorEl: props.anchorEl,
      onClose: props.onClose,
      header: props.title || props.label ? <Header title={props.title || props.label} /> : null,
      content: (
        <Body
          values={values}
          hasOptions={!isEmpty(props.options)}
          filteredOptions={filteredOptions}
          comparator={props.comparator}
          query={this.state.query}
          onSelect={props.onSelect}
          handleQueryChange={this.handleQueryChange}
          isFetching={props.isFetching}
          disableSearch={props.disableSearch}
          paging={props.paging}
          onLoadMore={props.onLoadMore}
          multiple={props.multiple}
          viewType={props.viewType}
        />
      ),
    }

    const type = props.type

    if (type !== null) {
      return this.getForType(type, viewProps)
    } else {
      // on desktop, display as popover
      if (showAsPopoverBreakpoints.indexOf(props.width) !== -1) {
        return this.getForType(ListChooserType.POPOVER, viewProps)
      }

      return this.getForType(ListChooserType.DIALOG, viewProps)
    }
  }
}

Content.propTypes = {
  /**
   *
   */
  open: PropTypes.bool.isRequired,

  /**
   * Dom node of the element that displayed the content
   */
  anchorEl: PropTypes.object,

  /**
   * An optionnal title
   * @type {[type]}
   */
  title: PropTypes.node,

  /**
   * Value or array of values that has been selected
   */
  value: PropTypes.any,

  multiple: PropTypes.bool.isRequired,

  /**
   * Disable filtering of the list using the query state
   * Set to true when the query / options filtering are handled by the parent.
   */
  disableFiltering: PropTypes.bool,

  /**
   * Optionnal
   *
   * function that receive the option and returns the view to display for this option
   * If not specified we display the option.label
   */
  optionRenderer: PropTypes.func,

  /**
   * Array of options to display
   * {
   *    label: 'label', // used for search and to be display if there is no optionRenderer prop
   *    value: 0, // the value of the option
   * }
   */
  options: PropTypes.array,

  /**
   * Function called when an option is selected.
   * Take the option.value as parameter
   */
  onSelect: PropTypes.func.isRequired,

  /**
   * Function called when the user request to close the dialog
   */
  onClose: PropTypes.func.isRequired,

  /**
   * See ViewType
   */
  viewType: PropTypes.string,
}

export default compose(withWidth())(Content)
