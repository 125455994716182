import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from 'pmt-ui/styles'

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {},
}

class IconLabel extends React.Component {
  static propTypes = {
    icon: PropTypes.element.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      .isRequired,
  }

  render() {
    const { icon, label, spacing = 15, classes } = this.props

    return (
      <div className={classes.root}>
        {icon}
        <span style={{ marginLeft: spacing }} className={classes.label}>
          {label}
        </span>
      </div>
    )
  }
}

export default withStyles(styles)(IconLabel)
