import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Account = props => (
  <SvgIcon {...props}>
    <g transform="translate(-20 -14)">
      <path
        d="M21.5,22H4V20a5.249,5.249,0,0,1,5.468-5h6.561A5.249,5.249,0,0,1,21.5,20Z"
        transform="translate(16 13)"
      />
      <circle cx="6" cy="6" r="6" transform="translate(23 14)" />
    </g>
  </SvgIcon>
)
Account = pure(Account)
Account.displayName = 'Account'
Account.muiName = 'SvgIcon'

export default Account
