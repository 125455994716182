import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { formatCheck } from './format'

const getCheckData = state => state.entities.check
const getEntries = state => getCheckData(state).getIn(['check', 'entries'])

export const getCheckError = createSelector([getCheckData], checkData => {
  const error = checkData.get('error')
  if (isNil(error)) {
    return null
  }
  return error.toJS()
})

export const isFetchingCheck = createSelector([getCheckData], checkData =>
  checkData.get('isFetching', false)
)

export const getCheck = createSelector([getCheckData], checkData => {
  const check = checkData.get('check')

  if (isNil(check)) {
    return null
  }

  return formatCheck(check.toJS())
})

const getIdOnProps = (state, props) => props.id

export const getEntryWithId = createSelector([getEntries, getIdOnProps], (entries, id) => {
  const check = entries.find((value, key) => value.get('id') === id)

  if (isNil(check)) {
    return null
  }

  return check.toJS()
})
