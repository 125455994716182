import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { withAppConfig } from 'pmt-modules/appConfig'
import { getAuthorizeAppData } from 'pmt-modules/authApp'
import { EventManager, SendEventContainer } from 'pmt-modules/event'
import { resetFullGeolocation } from 'pmt-modules/geolocation'
import { getRoute, redirectTo } from 'pmt-modules/routing'
import { resetOrderFront, resetOrderFrontFull } from 'pmt-modules/orderFront'
import {
  getOrderProperties,
  OrderPluginUrlCheckerContainer,
  resetOrderPlugin,
  getAppConfigFrontSettings,
  fromApp,
} from 'pmt-modules/orderPlugin'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { resetCart } from 'pmt-modules/cart'
import { withUserMe } from 'pmt-modules/userMe'
import { resetCatalog } from 'pmt-modules/catalog'
import { getAuthenticatedUser } from 'pmt-modules/authenticatedUser/selectors'
import {
  isFetchingPaymentPdfLink,
  fetchPaymentPdfLink,
  getDataPaymentPdfLink,
  resetPaymentPdfLink,
  getErrorPaymentPdfLink,
} from 'pmt-modules/payment/pdf'
import UserType from 'pmt-modules/user/constants/UserType'

import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'

import { getCallbackUri, CALLBACK_EVENTS } from 'pmt-utils/url'

import OrderPage from '../../components/OrderPage'
import View from './View'

/**
 * @specs N/A
 */
@withRestaurant
@withUserMe
@withAppConfig
@withScrollToTop()
class ConfirmPage extends React.Component {
  constructor(props) {
    super(props)

    if (isEmpty(get(props, 'orderProperties.postedOrderData', null))) {
      props.redirectTo(
        props.appConfig.order.modes.length > 1
          ? getRoute('ORDER__MODE')
          : getRoute('ORDER__STORE_LOCATOR')
      )
    }

    this.props.resetPaymentPdfLink()
  }

  componentDidMount() {
    this.props.resetCatalog()
    this.props.resetFullGeolocation()
    this.props.resetCart(false)
    this.props.resetOrderFront()
    this.props.resetOrderPlugin()
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.authenticatedUser &&
      nextProps.authenticatedUser.type === UserType.INCOGNITO &&
      nextProps.orderProperties?.postedOrderData?.orderData?.paymentId &&
      (this.props.orderProperties?.postedOrderData?.orderData?.paymentId !==
        nextProps.orderProperties.postedOrderData.orderData.paymentId ||
        isNil(this.props.paymentPdfLink)) &&
      !this.props.isFetchingPaymentPdfLink &&
      !nextProps.isFetchingPaymentPdfLink &&
      !nextProps.errorPaymentPdfLink
    ) {
      this.props.fetchPaymentPdfLink(nextProps.orderProperties.postedOrderData.orderData.paymentId)
    }
  }

  hasRedirectUriDefined = () => this.props.frontSettings && this.props.frontSettings.redirectUri

  handleRedirect = () => {
    EventManager.dispatch(EventManager.Events.ON_CONFIRM_CALLBACK)
    this.props.resetOrderFrontFull()

    if (!isNil(this.props.authorizeAppData)) {
      window.location.href = getCallbackUri(
        this.props.authorizeAppData.uri,
        CALLBACK_EVENTS.NEW_ORDER
      )
    } else if (
      this.hasRedirectUriDefined() &&
      this.props.orderProperties?.postedOrderData?.orderProperties?.from === fromApp.WEBAPP
    ) {
      window.location.href = this.props.frontSettings.redirectUri
    } else {
      this.props.redirectTo(getRoute('ORDER__STORE_LOCATOR'))
    }
  }

  render() {
    const {
      authenticatedUser,
      appConfigPreset,
      frontSettings,
      restaurant,
      isFetchingRestaurant,
      paymentPdfLink,
      errorPaymentPdfLink,
      location,
      orderAppConfig,
      orderProperties,
      me,
      width,
    } = this.props

    if (isEmpty(get(orderProperties, 'postedOrderData', null))) {
      return null
    }

    return (
      <OrderPage
        headerProps={{
          displayTitle: isWidthUp('md', width),
          displayUser: true,
        }}
        orderProperties={orderProperties}
        restaurant={restaurant}
        location={location}
      >
        <OrderPluginUrlCheckerContainer location={location} verifyMode>
          <SendEventContainer
            send={
              orderProperties &&
              !isNil(orderProperties.postedOrderData.orderProperties.restaurant) &&
              !isNil(me) &&
              !isNil(orderProperties.postedOrderData.orderData)
            }
            event={EventManager.Events.ON_PAGE_LOADED}
            eventProps={() => ({
              order: orderProperties.postedOrderData.orderData,
              items: orderProperties.postedOrderData.cartItems,
              restaurant: orderProperties.restaurant,
              dueDate: orderProperties.postedOrderData.orderData.dueDate,
              user: me.email,
            })}
          >
            <View
              isIncognito={authenticatedUser && authenticatedUser.type === UserType.INCOGNITO}
              appConfigPreset={appConfigPreset}
              frontSettings={frontSettings}
              isFetchingRestaurant={isFetchingRestaurant}
              paymentPdfLink={paymentPdfLink}
              errorPaymentPdfLink={errorPaymentPdfLink}
              orderAppConfig={orderAppConfig}
              postedOrderData={orderProperties.postedOrderData}
              restaurant={restaurant}
              onRedirect={this.handleRedirect}
              route={this.props.route}
              isFromWebApp={
                orderProperties?.postedOrderData?.orderProperties?.from === fromApp.WEBAPP
              }
            />
          </SendEventContainer>
        </OrderPluginUrlCheckerContainer>
      </OrderPage>
    )
  }
}

const mapStateToProps = (state, props) => ({
  authorizeAppData: getAuthorizeAppData(state),
  frontSettings: getAppConfigFrontSettings(state),
  orderProperties: getOrderProperties(state),
  authenticatedUser: getAuthenticatedUser(state),
  isFetchingPaymentPdfLink: isFetchingPaymentPdfLink(state),
  errorPaymentPdfLink: getErrorPaymentPdfLink(state),
  paymentPdfLink: getDataPaymentPdfLink(state)?.signedUrl,
})

export default compose(
  connect(mapStateToProps, {
    redirectTo,
    fetchPaymentPdfLink,
    resetPaymentPdfLink,
    resetCart,
    resetCatalog,
    resetFullGeolocation,
    resetOrderFront,
    resetOrderFrontFull,
    resetOrderPlugin,
  }),
  withWidth()
)(ConfirmPage)
