import isEmpty from 'lodash/isEmpty'
import { generateUUID } from 'pmt-utils/uuid'
import { getFromSession, saveToSession } from 'pmt-modules/storage'

//
// The front can define a unique "session" id for the current user session and send it on the
// 'p-front-session-id' header to the API.
// It allows the API to link different API calls to a single front session.
//

// generate front session id for the session
// It is saved on the sessionStorage: The sessionStorage object stores data for only one session
// (the data is deleted when the browser tab is closed).
let _frontSessionId = null

const SessionStorageKey = 'P_FRONT_SESSSION_ID'

export const FRONT_SESSION_ID_HEADER = 'p-front-session-id'

export const initFrontSessionId = () => {
  _frontSessionId = getFromSession(SessionStorageKey)

  if (isEmpty(_frontSessionId)) {
    _frontSessionId = `${Date.now()}_${generateUUID()}`
    saveToSession(SessionStorageKey, _frontSessionId)
  }
}

export const getFrontSessionId = () => _frontSessionId
