import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import forEach from 'lodash/forEach'
import isEqualWith from 'lodash/isEqualWith'
import { findOnArray } from 'pmt-utils/array'
import isUndefined from 'lodash/isUndefined'

const areProductOptionValuesEqual = (optionToCheck, optionToCompare) => {
  const toCheckValues = optionToCheck.values
  const toCompareValues = optionToCompare.values

  if (toCompareValues.length !== toCheckValues.length) {
    return false
  }

  const isEqual = isEqualWith(
    toCheckValues,
    toCompareValues,
    (optionValuesToCheck, optionValuesToCompare) => {
      //
      // verify each option value
      //
      let i = 0
      let length = optionValuesToCheck.length
      while (i < length) {
        const optionValueToCheck = optionValuesToCheck[i]
        const optionValueToCompare = optionValuesToCompare[i]
        const valueIsEqual = optionValueToCheck.id === optionValueToCompare.id

        if (!valueIsEqual) {
          return false
        }
        ++i
      }
      return true
    }
  )

  return isEqual
}

const areProductOptionsEqual = (productToCheck, productToCompare) => {
  if (productToCheck.options.length !== productToCompare.options.length) {
    return false
  }

  let haveSameOptions = true
  forEach(productToCheck.options, optionToCheck => {
    const optionFound = findOnArray(
      productToCompare.options,
      optionToCompare => optionToCheck.id === optionToCompare.id
    )

    if (isUndefined(optionFound)) {
      haveSameOptions = false
      return false // quit loop
    }

    haveSameOptions = areProductOptionValuesEqual(optionToCheck, optionFound)

    if (!haveSameOptions) {
      return false // quit loop
    }
  })

  return haveSameOptions
}

const areProductModifiersEqual = (productToCheck, productToCompare) => {
  return isEqual(sortBy(productToCheck.modifierIds), sortBy(productToCompare.modifierIds))
}

const areSameApiProduct = (productToCheck, productToCompare) => {
  const checkOptions =
    (productToCheck.options || []).length > 0 || (productToCompare.options || []).length > 0

  return (
    productToCheck.id === productToCompare.id &&
    productToCheck.comment === productToCompare.comment &&
    productToCheck.price === productToCompare.price &&
    areProductModifiersEqual(productToCheck, productToCompare) &&
    (!checkOptions || areProductOptionsEqual(productToCheck, productToCompare))
  )
}

export default areSameApiProduct
