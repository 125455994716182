import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'

import GoogleMapsScriptJS from 'pmt-modules/google/components/GoogleMapsScriptJS'

import {
  getGoogleMapApiUrl,
  getGoogleMapApiVersion,
  getGoogleMapApiKey,
} from 'pmt-modules/environment'

import GoogleApi from 'pmt-utils/google/GoogleApi'

class GoogleMapsApiContainer extends React.Component {
  state = {
    googleMapURL: '',
  }

  componentWillMount() {
    if (isEmpty(this.state.googleMapURL)) {
      const googleMapURL = GoogleApi({
        apiKey: getGoogleMapApiKey(),
        libraries: ['places'],
        version: getGoogleMapApiVersion(),
        url: getGoogleMapApiUrl(),
      })

      this.setState({
        googleMapURL,
      })
    }
  }

  render() {
    const { children, loadingElement } = this.props

    /*
     googleMapURL is a variable set in parents components (see index.js)
     if we do not have it we can't display the content because it needs it
   */
    // TODO: loading or error?
    if (this.state.googleMapURL === '') {
      return <div />
      /* return (
              <BlockContainer type={BlockContainer.Type.WHITE} className={classes.gridContainer}>
                <LoadingBlock show />
              </BlockContainer>
            ) */
    }

    return (
      <GoogleMapsScriptJS
        googleMapURL={this.state.googleMapURL}
        loadingElement={loadingElement || <div />}
      >
        {() => (isFunction(children) ? children() : children)}
      </GoogleMapsScriptJS>
    )
  }
}

GoogleMapsApiContainer.propTypes = {
  /**
   * loading to display while loading the google map script. Default: <div />
   */
  loadingBlock: PropTypes.any,
}

export default GoogleMapsApiContainer
