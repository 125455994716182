import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'

import { failureGeolocation, getGeolocationIsFetching } from 'pmt-modules/geolocation'
import { getAddressElementsFromGooglePlace } from 'pmt-utils/google'

class GoogleMapsReverseGeocoding extends React.Component {
  state = {
    geocoder: new window.google.maps.Geocoder(),
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isNil(nextProps.geolocationLatitude) &&
      !isNil(nextProps.geolocationLongitude) &&
      nextProps.geolocationLatitude !== this.props.geolocationLatitude &&
      nextProps.geolocationLongitude !== this.props.geolocationLongitude
    ) {
      this.reverseGeocode(nextProps.geolocationLatitude, nextProps.geolocationLongitude)
    }
  }

  reverseGeocode = (lat, lng) => {
    if (!this.props.reverseGeocoding) {
      return
    }

    if (!isNil(this.state.geocoder)) {
      const self = this

      this.state.geocoder.geocode(
        { location: { lat, lng } },
        // https://developers.google.com/maps/documentation/javascript/examples/geocoding-reverse
        // we set results[0] because we are interested in that result, not the [1]
        (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              // send callback success
              self.props.successCallback({
                addressElements: getAddressElementsFromGooglePlace(results[0]),
                types: results[0].types,
                geometry: results[0].geometry || null,
              })
            } else {
              self.props.failureGeolocation(
                self.props.geolocationId,
                tr('global.geolocation.no_address_found')
              )
            }
          } else {
            self.props.failureGeolocation(
              self.props.geolocationId,
              tr('global.geolocation.error.status', { status })
            )
          }
        }
      )
    }
  }

  render() {
    const { children } = this.props

    return children
  }
}

GoogleMapsReverseGeocoding.defaultProps = {
  geolocationLatitude: null,
  geolocationLongitude: null,
  /**
   * Set this to false to avoid reverse geocoding. Used when editing an address.
   */
  reverseGeocoding: false,
}

GoogleMapsReverseGeocoding.propTypes = {
  geolocationLatitude: PropTypes.number,
  geolocationLongitude: PropTypes.number,

  reverseGeocoding: PropTypes.bool,
}

const mapStateToProps = (state, props) => ({
  geolocationIsFetching: getGeolocationIsFetching(state, props),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  failureGeolocation: bindActionCreators(failureGeolocation, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleMapsReverseGeocoding)
