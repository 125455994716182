import { createMuiPalette } from './palette'

/**
 *
 * ```
 * const currentTheme = getCurrentTheme(getState())
 * const newTheme = createMuiThemeWithPmtRestaurantColors(currentTheme, action.response.colors)
 *
 * next(changeCurrentTheme(newTheme))
 * ```
 *
 * @param  {object} currentTheme current mui theme
 * @param  {object} colors       restaurant colors settings
 */
export const createMuiThemeWithPmtRestaurantColors = (currentTheme, colors) => {
  const newPalette = createMuiPalette(currentTheme.palette, {
    primary: colors.actionBackgroundColor,
    secondary: colors.actionBackgroundColor,
    text: {
      primary: colors.fontColor,
    }
  })

  const newTheme = {
    ...currentTheme,
    palette: newPalette,
    pmt: {
      ...currentTheme.pmt,
      loading: {
        ...currentTheme.pmt.loading,
        color: colors.actionBackgroundColor,
      }
    }
  }

  return newTheme
}

/**
 *
 * ```
 * const currentTheme = getCurrentTheme(getState())
 * const newTheme = createMuiThemeWithPmtRGroupColors(currentTheme, action.response.theme)
 *
 * next(changeCurrentTheme(newTheme))
 * ```
 *
 * @param  {object} currentTheme current mui theme
 * @param  {object} theme        rGroup theme configuration
 */
export const createMuiThemeWithPmtRGroupColors = (currentTheme, theme) => {
  let newPalette = null
  if (!theme) {
    newPalette = createMuiPalette(currentTheme.palette)
  } else {
    newPalette = createMuiPalette(currentTheme.palette, {
      primary: theme.mainBackgroundColor,
      secondary: theme.mainBackgroundColor,
    })
  }

  const newTheme = {
    ...currentTheme,
    palette: newPalette,
    pmt: {
      ...currentTheme.pmt,
      loading: {
        ...currentTheme.pmt.loading,
        color: theme.mainBackgroundColor,
      }
    }
  }

  return newTheme
}
