import moment from 'moment'

import { getCookie, setCookie, deleteCookie } from 'pmt-modules/storage'

import { getQueryParam } from 'pmt-utils/url'

import {
  ACCESS_TOKEN_DELAY_REFRESH,
  ACCESS_TOKEN_DELAY_REFRESH_SOON,
  REFRESH_TOKEN_LIFETIME,
  INCOGNITO_TOKEN_LIFETIME,
  StorageItem,
} from '../constants'

let _clientId = null

const getCookieKey = () => `${StorageItem.ACCESS_TOKEN}::${_clientId}`

export const setClientId = clientId => (_clientId = clientId)

export const saveAccessToken = token => {
  setCookie(getCookieKey(), JSON.stringify(token), REFRESH_TOKEN_LIFETIME)
}

export const removeAccessToken = () => {
  deleteCookie(getCookieKey())
}

export const getAuthCookie = () => {
  try {
    return JSON.parse(getCookie(getCookieKey()))
  } catch (e) {
    return null
  }
}

/**
 * default access token can be defined if is passed as GET query parameter
 * otherwise, we take the cookie access token
 */
export const getAuthorization = () => {
  if (getQueryParam('access_token')) {
    return `Bearer ${getQueryParam('access_token')}`
  }

  const cookie = getAuthCookie()

  if (cookie && cookie.access_token) {
    return `Bearer ${cookie.access_token}`
  }

  return null
}

/**
 * Check if token has expired or will expire within a short delay
 */
export const hasTokenExpired = (expirationMoment, now = moment()) =>
  now.isAfter(expirationMoment) ||
  moment(now)
    .add(ACCESS_TOKEN_DELAY_REFRESH, 'ms')
    .isAfter(expirationMoment)

/**
 * Check if token has expired or will expire within a  relative short delay
 */
export const willTokenExpireSoon = (expirationMoment, now = moment()) =>
  moment(now)
    .add(ACCESS_TOKEN_DELAY_REFRESH_SOON, 'ms')
    .isAfter(expirationMoment)

let _restaurantsGroupId = null

export const setRestaurantsGroupId = restaurantsGroupId =>
  (_restaurantsGroupId = restaurantsGroupId)

const getUserLightCookieKey = () => `ul::${_restaurantsGroupId}`

const getIncognitoCookieKey = () => `incognito::${_restaurantsGroupId}`

export const saveUserLightCookie = user => {
  setCookie(getUserLightCookieKey(), JSON.stringify(user), INCOGNITO_TOKEN_LIFETIME)
}

export const saveIncognitoCookie = userId => {
  setCookie(getIncognitoCookieKey(), JSON.stringify(userId), INCOGNITO_TOKEN_LIFETIME)
}

export const removeUserLight = () => {
  deleteCookie(getUserLightCookieKey())
}

export const removeIncognitoCookie = () => {
  deleteCookie(getIncognitoCookieKey())
}

export const getUserLightCookie = () => {
  try {
    return JSON.parse(getCookie(getUserLightCookieKey()))
  } catch (e) {
    return null
  }
}

export const getIncognitoCookie = () => {
  try {
    return JSON.parse(getCookie(getIncognitoCookieKey()))
  } catch (e) {
    return null
  }
}
