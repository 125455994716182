import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Back = props => (
  <SvgIcon viewBox="0 0 14 9" {...props}>
    <path
      d="M20056.563-871.755l-3.268-3.268h0l-.018-.017a1,1,0,0,1-.025-1.353c.018-.02.037-.04.057-.059l3.254-3.255a.991.991,0,0,1,1.41,0,1,1,0,0,1,0,1.415l-1.561,1.561h9.5a1,1,0,0,1,1,1,1,1,0,0,1-1,1h-9.5l1.559,1.561a1,1,0,0,1,0,1.415.977.977,0,0,1-.705.292A.98.98,0,0,1,20056.563-871.755Z"
      transform="translate(-20053 880.001)"
    />
  </SvgIcon>
)
Back = pure(Back)
Back.displayName = 'Back'
Back.muiName = 'SvgIcon'

export default Back
