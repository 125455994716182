import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { TypographyCustom } from 'pmt-ui/Typography'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'

const TableNumber = ({ classes, orderProperties, width }) =>
  orderProperties.tableNumber && (
    <div className={classes.root}>
      <TypographyCustom type={isWidthUp('sm', width) ? 164 : 144} component="div" skipColor>
        {tr('order.menu.table_number.catch_phrase', {tableNumber: orderProperties.tableNumber})}
      </TypographyCustom>
    </div>
  )

export default withWidth()(TableNumber)
