import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

const get = state => state.entities.authenticatedUser

export const getAuthenticatedUser = createSelector([get], authenticatedUser => {
  if (isNil(authenticatedUser)) {
    return null
  }

  return authenticatedUser.toJS()
})
