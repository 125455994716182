import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { haveAuthCredentials, getAuth } from 'pmt-modules/auth'
import { withCatalog } from 'pmt-modules/catalog'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { EventManager, SendEventContainer } from 'pmt-modules/event'
import { withAppConfig } from 'pmt-modules/appConfig'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { withUserMe } from 'pmt-modules/userMe'
import {
  CreditCardsContainer,
  PspRegisterUserCardType,
  getIsFetchingPostCreditCardRegistrationType,
  getSendUserCreditCardToPspIsFetching,
  getPostPspDatasIsFetching,
  resetUserCreditCardToPspSend,
} from 'pmt-modules/creditCard'
import { getItemListFromCart } from 'pmt-modules/cart'
import { resetOrderFrontFull } from 'pmt-modules/orderFront'
import {
  withOrderProperties,
  OrderPluginUrlCheckerContainer,
  getOrderData,
  getAppConfigFrontSettings,
  resetOrderPlugin,
  fromApp,
} from 'pmt-modules/orderPlugin'
import { isFetchingOrderPost } from 'pmt-modules/orderPost'
import { getOrderPreviewVerifications } from 'pmt-modules/orderPreview'
import { getAuthenticatedUser } from 'pmt-modules/authenticatedUser/selectors'
import UserType from 'pmt-modules/user/constants/UserType'

import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'

import OrderPage from '../../components/OrderPage'
import View from './View'

/**
 * @specs N/A
 */
@withAppConfig
@withUserMe
@withOrderProperties
@withRestaurant
@withCatalog
@withScrollToTop()
class PaymentPage extends React.PureComponent {
  constructor(props) {
    super(props)
    // TODO: only if there is data to avoid unecessary page re-render
    props.resetUserCreditCardToPspSend()
  }

  // we need to have the restaurant to check order data
  // if we don't, it will return null every time
  componentWillReceiveProps(nextProps) {
    // SECURITY
    // user is not connected or
    // if the order data is null, it means that one mandatory information
    // to access the payment page is missing
    if (
      (isNil(nextProps.orderData) && !isNil(nextProps.orderProperties.restaurant)) ||
      !nextProps.haveAuthCredentials ||
      isNil(nextProps.orderProperties.restaurantId) ||
      (this.props.isFetchingRestaurant && !nextProps.isFetchingRestaurant && !nextProps.restaurant)
    ) {
      nextProps.resetOrderFrontFull()
      if (this.hasRedirectUriDefined() && nextProps.orderProperties.from === fromApp.WEBAPP) {
        window.location.href = nextProps.frontSettings.redirectUri
      } else {
        nextProps.resetOrderPlugin()
        nextProps.redirectTo(getRoute('ORDER__STORE_LOCATOR'))
      }
    }
  }

  hasRedirectUriDefined = () => this.props.frontSettings && this.props.frontSettings.redirectUri

  render() {
    const {
      isFetchingPostCreditCardRegistrationType,
      isFetchingSendUserCreditCardToPsp,
      isFetchingPostPspDatas,
      isFetchingOrderPost,
      restaurant,
      authenticatedUser,
      cartItemList,
      location,
      orderPreviewVerifications,
      orderProperties,
      width,
      me,
    } = this.props

    const canRegisterCardWithName = authenticatedUser && authenticatedUser.type === UserType.NORMAL

    return (
      <OrderPage
        headerProps={{
          displayTitle: isWidthUp('md', width),
        }}
        orderProperties={orderProperties}
        restaurant={restaurant}
        location={location}
      >
        <OrderPluginUrlCheckerContainer location={location} verifyMode>
          <CreditCardsContainer userId={authenticatedUser && authenticatedUser.id} storeId={restaurant && restaurant.id}>
            {({
              isFetchingCreditCards,
              creditCards,
              selectedCreditCard,
              onToggleCard,
              onClickDeleteCreditCard,
              onResetUserCard,
            }) => (
              <SendEventContainer
                send={
                  orderProperties &&
                  !isNil(orderProperties.restaurant) &&
                  !isNil(authenticatedUser && authenticatedUser.email)
                }
                event={EventManager.Events.ON_PAGE_LOADED}
                eventProps={() => ({
                  items: cartItemList,
                  restaurant: orderProperties.restaurant,
                  dueDate: orderProperties.dueDate,
                  user: authenticatedUser && authenticatedUser.email,
                })}
              >
                <View
                  isFetching={
                    !restaurant ||
                    !authenticatedUser ||
                    (authenticatedUser.type === UserType.NORMAL && !me) ||
                    isFetchingSendUserCreditCardToPsp ||
                    isFetchingPostPspDatas ||
                    isFetchingOrderPost ||
                    (isFetchingPostCreditCardRegistrationType &&
                      restaurant.pspRegisterUserCardType === PspRegisterUserCardType.WEB_SERVICES)
                  }
                  isFetchingCreditCards={isFetchingCreditCards}
                  user={authenticatedUser}
                  orderPreviewVerifications={orderPreviewVerifications}
                  orderProperties={orderProperties}
                  restaurant={restaurant}
                  creditCards={creditCards}
                  selectedCreditCard={selectedCreditCard}
                  onToggleCreditCard={onToggleCard}
                  onClickDeleteCreditCard={onClickDeleteCreditCard}
                  onResetUserCard={onResetUserCard}
                  canRegisterCardWithName={canRegisterCardWithName}
                />
              </SendEventContainer>
            )}
          </CreditCardsContainer>
        </OrderPluginUrlCheckerContainer>
      </OrderPage>
    )
  }
}

const mapStateToProps = (state, props) => ({
  haveAuthCredentials: haveAuthCredentials(state),
  auth: getAuth(),
  authenticatedUser: getAuthenticatedUser(state),

  isFetchingPostCreditCardRegistrationType: getIsFetchingPostCreditCardRegistrationType(state),
  isFetchingSendUserCreditCardToPsp: getSendUserCreditCardToPspIsFetching(state),
  isFetchingPostPspDatas: getPostPspDatasIsFetching(state),
  isFetchingOrderPost: isFetchingOrderPost(state),

  orderData: getOrderData(state),
  orderPreviewVerifications: getOrderPreviewVerifications(state),
  frontSettings: getAppConfigFrontSettings(state),
  cartItemList: getItemListFromCart(state),
})

export default compose(
  connect(mapStateToProps, {
    resetUserCreditCardToPspSend,
    resetOrderFrontFull,
    resetOrderPlugin,
    redirectTo,
  }),
  withWidth()
)(PaymentPage)
