import isNull from 'lodash/isNull'

import OrderErrors from '../errors/OrderErrors'
import ApiEndpoints from '../config/ApiEndpoints'

const OrderApi = {
  /**
   * @param data see 'selectors getOrderData'
   */
  post: (data, options) => {
    /**
     * set authorization headers only if specified
     * (this is used in user light case)
     * otherwise this will be set in /config/getApiManagerOptions.js
     */
    let headers = {}
    if (options && options.authorization) {
      headers.authorization = options.authorization
    }

    return {
      endpoint: ApiEndpoints.ORDERS,
      headers,
      query: {
        // if true, the order will be accepted only if it comes fully paid, considering the potential grant amount (see **)
        requireFullPayment: !isNull(data.payment),
        // if true, the carts modifiers (aka promotions) available will be applied on the order (see ***)
        applyCartModifiers: true,
        // if false, the order won't be pushed to the POS and will remain local to our system (as some kind of notification to us)
        pushToPos: true,
      },
      type: 'POST',
      body: data,
      errorHandler: OrderErrors.POST,
    }
  },

  getDetail: orderId => ({
    endpoint: ApiEndpoints.ORDER,
    type: 'GET',
    query: {
      loadPayment: true,
      loadCheck: true,
    },
    params: {
      orderId,
    },
  }),

  getRestaurantOnGoing: restaurantId => ({
    endpoint: ApiEndpoints.ORDERS_ON_GOING,
    type: 'GET',
    query: {
      restaurantId,
      v2: true,
    },
  }),

  getRestaurantHistory: (restaurantId, fromDate, toDate) => ({
    endpoint: ApiEndpoints.ORDERS_HISTORY,
    type: 'GET',
    query: {
      restaurantId,
      fromTime: fromDate,
      toTime: toDate,
      v2: true,
      filterBy: 'dueDate',
    },
  }),

  putOrder: order => ({
    endpoint: ApiEndpoints.ORDER,
    headers: {
      'restaurant-id': order.restaurantId,
    },
    type: 'PUT',
    params: {
      orderId: order.id,
    },
    body: order,
  }),
}

export default OrderApi
