import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { getAsyncPayment } from 'pmt-modules/asyncPayment'
import { withAppConfig } from 'pmt-modules/appConfig'
import { resetOrderFrontFull } from 'pmt-modules/orderFront'
import {
  fromApp,
  getAppConfigFrontSettings,
  OrderPluginUrlCheckerContainer,
  resetOrderPlugin,
  withOrderProperties,
} from 'pmt-modules/orderPlugin'
import { getDataOrderPost } from 'pmt-modules/orderPost'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { withUserMe } from 'pmt-modules/userMe'

import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'

import OrderPage from '../../../components/OrderPage'
import View from './View'

/**
 * @specs N/A
 */
@withAppConfig
@withUserMe
@withOrderProperties
@withRestaurant
@withScrollToTop()
class PaymentVerificationPage extends React.Component {
  constructor(props) {
    super(props)

    // SECURITY
    // if the order data is null, it means that one mandatory information
    // to access the payment page is missing
    if (isNil(props.orderData)) {
      console.log("PaymentVerificationPage without orderData => reset and redirect")
      props.resetOrderFrontFull()
      if (this.hasRedirectUriDefined() && this.props.orderProperties.from === fromApp.WEBAPP) {
        window.location.href = props.frontSettings.redirectUri
      } else {
        props.resetOrderPlugin()
        props.redirectTo(getRoute('ORDER__STORE_LOCATOR'))
      }
    } else if (!isNil(props.orderData.payment)) {
      props.getAsyncPayment(props.orderData.payment.id)
    } else {
      props.redirectTo(getRoute('ORDER__PAYMENT'))
    }
  }

  hasRedirectUriDefined = () => this.props.frontSettings && this.props.frontSettings.redirectUri

  render() {
    const { restaurant, location, orderProperties } = this.props

    return (
      <OrderPage orderProperties={orderProperties} restaurant={restaurant} location={location}>
        <OrderPluginUrlCheckerContainer location={location} verifyMode>
          <View />
        </OrderPluginUrlCheckerContainer>
      </OrderPage>
    )
  }
}

const mapStateToProps = (state) => ({
  frontSettings: getAppConfigFrontSettings(state),
  orderData: getDataOrderPost(state),
})

export default connect(mapStateToProps, {
  resetOrderFrontFull,
  resetOrderPlugin,
  redirectTo,
  getAsyncPayment,
})(PaymentVerificationPage)
