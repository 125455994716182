import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'
import isNull from 'lodash/isNull'
import isNil from 'lodash/isNil'

import { isAppSecurityError } from 'pmt-modules/appSecurity'
import { shouldUseAppConfig } from 'pmt-modules/environment'

import { fetchAppConfig } from '../actions'
import {
  getAppConfig,
  isFetchingAppConfig,
  getAppConfigError,
  getFrontSettings,
  getOrderSettings,
  getUserSettings,
  getSecuritySettings,
  getAppConfigPreset,
} from '../selectors'

import { withStyles } from 'pmt-ui/styles'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'
import IconError from 'pmt-ui/svg-icons/alert/error'
import Typography from 'pmt-ui/Typography'

import Logger from 'pmt-utils/logger'

/**
 * @specs N/A
 *
 * A HOC that fetch the app config and pass it to the children.
 *
 * Requirements:
 * -
 *
 * see `withAppConfig`
 *
 */

const styles = theme => ({
  wrapper: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
  },
  loadingBlock: {
    background: theme.pmt.colors.white,
    padding: theme.spacing(4),
    minHeight: 400,
  },
  circularProgress: {
    color: theme.pmt.loading.color,
  },
  errorLayout: {
    background: theme.pmt.colors.grey91,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.pmt.colors.white,
  },
  errorIcon: {
    fontSize: 128,
    margin: `${theme.spacing(6)}px auto`,
  },
  errorTitle: {
    whiteSpace: 'pre-line',
    color: theme.pmt.colors.white,
    textAlign: 'center',
  },
})

const Loading = ({ classes }) => (
  <div className={classes.wrapper}>
    <LoadingBlock
      show
      classes={{
        loadingBlock: classes.loadingBlock,
        circularProgress: classes.circularProgress,
      }}
    />
  </div>
)

class AppConfigContainer extends React.PureComponent {
  constructor(props) {
    super(props)

    // avoid loading when we already have an error to avoid infinite loop
    if (isNull(this.props.appConfig) && isNull(this.props.error)) {
      this.loadAppConfig(this.props)
    }
  }

  loadAppConfig(props) {
    if (shouldUseAppConfig()) {
      props.fetchAppConfig()
    }
  }

  render() {
    const {
      AppConfigWrappedComponent,
      isFetchingAppConfig,
      isFetchingI18nPhrases,
      appConfig,
      classes,
      error,
      children,
      ...other
    } = this.props

    if (isFetchingI18nPhrases) {
      return <Loading classes={classes} />
    }

    if (!shouldUseAppConfig()) {
      if (isFunction(children)) {
        return children()
      }
      return children
    }

    // while we are fetching the appConfig, we return a loader
    // so the user doesn't think it isn't loading anything
    // we don't want to display the loading if the appConfig is null because of an appSecurity error
    // we want to display the children, that will display the error
    if (isFetchingAppConfig || (isNil(appConfig) && !isAppSecurityError(error))) {
      // couldn't retrieve the app config and it is not an app security error.
      // mostly because of an invalid api consumer
      if (error && !isAppSecurityError(error)) {
        Logger.error('API consumer', 'Invalid API consumer', this.props)
        return (
          <div className={classes.errorLayout}>
            <IconError className={classes.errorIcon} />
            <Typography variant="body2" className={classes.errorTitle}>
              {error.localizedMessage || error.message}
            </Typography>
          </div>
        )
      }
      return <Loading classes={classes} />
    }
    if (isNil(AppConfigWrappedComponent)) {
      return !isFunction(children)
        ? React.cloneElement(children, {
            isFetchingAppConfig,
            appConfig,
            ...other,
          })
        : children({
            isFetchingAppConfig,
            appConfig,
            ...other,
          })
    }

    return (
      <AppConfigWrappedComponent
        AppConfigWrappedComponent={null}
        appConfig={appConfig}
        isFetchingAppConfig={isFetchingAppConfig}
        {...other}
      />
    )
  }
}

AppConfigContainer.propTypes = {
  fetchAppConfig: PropTypes.func.isRequired,
  isFetchingAppConfig: PropTypes.bool,
  appConfig: PropTypes.object,
}

const mapStateToProps = (state, props) => {
  return {
    appConfig: getAppConfig(state),
    appConfigPreset: getAppConfigPreset(state),
    frontAppConfig: getFrontSettings(state),
    orderAppConfig: getOrderSettings(state),
    userAppConfig: getUserSettings(state),
    securityAppConfig: getSecuritySettings(state),
    isFetchingAppConfig: isFetchingAppConfig(state),
    error: getAppConfigError(state),
  }
}

export default compose(
  connect(
    mapStateToProps,
    {
      fetchAppConfig,
    }
  ),
  withStyles(styles)
)(AppConfigContainer)
