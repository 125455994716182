let _reducers = {}

export const registerReducers = reducers => {
  _reducers = reducers
}

export const getReducers = () => _reducers

export const registerEntities = entities => {
  _reducers = {
    ..._reducers,
    entities,
  }
}

export const getEntities = () => _reducers.entities
