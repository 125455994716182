import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let AvChange = props => (
  <SvgIcon {...props}>
    <path
      d="M13.025,13.711a6.654,6.654,0,1,1,1.1-1.218L12,8.667h2a5.333,5.333,0,1,0-1.64,3.848Z"
      transform="translate(3.709 3.333)"
    />
  </SvgIcon>
)
AvChange = pure(AvChange)
AvChange.displayName = 'AvChange'
AvChange.muiName = 'SvgIcon'

export default AvChange
