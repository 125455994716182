import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import isNil from 'lodash/isNil'
import get from 'lodash/get'

import { EventManager } from 'pmt-modules/event'
import { getAppConfigFrontSettings } from 'pmt-modules/orderPlugin'
import { getOrderPreview, getOrderPreviewVerifications } from 'pmt-modules/orderPreview'

import { TypographyCustom } from 'pmt-ui/Typography'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import ActionCheckCircle from 'pmt-ui/svg-icons/action/check-circle'
import ActionInfoOutline from 'pmt-ui/svg-icons/action/info-outline'
import ToggleContainer from 'pmt-ui/ToggleContainer'
import ActionInfo from 'pmt-ui/svg-icons/action/info'
import CloseIcon from '@material-ui/icons/Close'

import { formatPriceWithCurrency } from 'pmt-utils/currency'

import { CartItemList, CartFooter } from '../../../components/Cart'
import { ButtonLink } from '../../../components/Button'

const PaymentDatasCard = ({
  appConfigPreset,
  orderProperties,
  frontSettings,
  cartItems,
  cartData,
  orderData,
  orderPreview,
  restaurant,
  orderPreviewVerifications,
  route,
  classes,
}) => (
  <div className="u-marginBottom40">
    {frontSettings.display.totalPrices && (
      <TypographyCustom type="144" component="p" className="u-marginBottom15">
        {tr('order.confirm.total')}
        <TypographyCustom type="144" component="span" className="u-floatRight">
          {formatPriceWithCurrency(
            // totalPrice contains the admission fees amount
            orderData.totalPrice -
              get(orderPreviewVerifications, 'userAccount.subsidies.grantAmount', 0)
          )}
        </TypographyCustom>
      </TypographyCustom>
    )}

    {orderData.totalPrice > 0 && (
      <React.Fragment>
        {orderProperties.payment.isCreditCardMethod ||
        orderProperties.payment.isUserAccountMethod ? (
          <div className="u-overflowHidden">
            <ActionCheckCircle className={classNames(classes.icon, classes.iconGreen)} />
            <TypographyCustom type="145" component="p" className={classes.iconText}>
              {tr(`order.confirm.already_paid.${orderProperties.paymentMethod}`)}
            </TypographyCustom>
          </div>
        ) : (
          orderProperties.payment.isIrlMethod &&
          (frontSettings.display.totalPrices ? (
            <div className="u-overflowHidden">
              <ActionInfoOutline className={classNames(classes.icon, classes.iconWarning)} />
              <TypographyCustom type="145" component="p" className={classes.iconText}>
                {tr(`order.confirm.left_to_pay.${orderProperties.mode}`, {
                  amount: orderData.totalPriceFormatted,
                })}
              </TypographyCustom>
            </div>
          ) : (
            <div className="u-overflowHidden">
              <ActionInfoOutline className={classNames(classes.icon, classes.iconWarning)} />
              <TypographyCustom type="145" component="p" className={classes.iconText}>
                {tr(`order.confirm.total_to_pay.${orderProperties.mode}`)}
              </TypographyCustom>
            </div>
          ))
        )}
      </React.Fragment>
    )}

    <ToggleContainer defaultOpen={false}>
      {({ isOpen, toggle }) =>
        isOpen ? (
          <React.Fragment>
            <div className={classes.containerDetailOrder}>
              {!isNil(cartItems) ? (
                <React.Fragment>
                  <CloseIcon
                    fontSize="small"
                    onClick={() => {
                      toggle()
                    }}
                    className={classes.iconCloseDetail}
                  />
                  <CartItemList
                    enabledClickableItems={false}
                    enabledQuantityChange={false}
                    fromRoute={route}
                    itemList={cartItems}
                    type={CartItemList.Type.SMALL}
                    appConfigPreset={appConfigPreset}
                    orderModifiers={orderData.modifiers}
                  />
                  <CartFooter
                    comment={cartData.comment}
                    displayMinimumPricesForFree={false}
                    enabledSubmitCart={false}
                    fees={orderData.fees}
                    itemList={cartItems}
                    minimumPrice={cartData.minimumPrice}
                    minimumPriceFormatted={cartData.minimumPriceFormatted}
                    restaurant={restaurant}
                    orderProperties={orderProperties}
                    totalCartPrice={cartData.totalCartPrice}
                    totalCartPriceFormatted={cartData.totalCartPriceFormatted}
                    totalCartPriceAndFeesFormatted={orderProperties.totalCartPriceAndFeesFormatted}
                    type={CartFooter.Type.SMALL}
                    orderModifiers={orderData.modifiers}
                  />
                </React.Fragment>
              ) : (
                <LoadingBlock
                  show
                  classes={{
                    loadingBlock: classNames('u-marginTop20', classes.loadingBlock),
                    circularProgress: classes.progress,
                  }}
                />
              )}
            </div>
          </React.Fragment>
        ) : (
          <div className="u-textAlignCenter u-marginTop15">
            <ButtonLink
              classes={{
                root: classNames(classes.mainColor, classes.cursorPointer),
              }}
              disabled={isNil(orderProperties.mode)}
              onClick={() => {
                EventManager.dispatch(EventManager.Events.ON_CONFIRM_ORDER_DETAIL)
                toggle()
              }}
              icon={<ActionInfo />}
              label={tr('order.confirm.order_detail')}
            />
          </div>
        )
      }
    </ToggleContainer>
  </div>
)

const mapStateToProps = (state, props) => ({
  frontSettings: getAppConfigFrontSettings(state),
  orderPreview: getOrderPreview(state),
  orderPreviewVerifications: getOrderPreviewVerifications(state),
})

export default connect(mapStateToProps, {})(PaymentDatasCard)
