import React from 'react'

import isNil from 'lodash/isNil'
import isFunction from 'lodash/isFunction'
import Dialog, { DialogContent, DialogTitle, DialogActions } from 'pmt-ui/Dialog'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  dialogActions: {},
  dialogContent: {},
  dialogTitle: {
    textAlign: 'center',
  },
}))

const DialogView = ({ classes, title, content, actions, dialogProps = {}, onClose }) => (
  <Dialog open fullWidth onClose={onClose} {...dialogProps}>
    {title && (
      <DialogTitle className={classes.dialogTitle}>
        {isFunction(title) ? title({ onClose }) : title}
      </DialogTitle>
    )}
    <DialogContent className={classes.dialogContent}>
      {isFunction(content) ? content({ onClose }) : content}
    </DialogContent>
    {!isNil(actions) && (
      <DialogActions className={classes.dialogActions}>
        {isFunction(actions) ? actions({ onClose }) : actions}
      </DialogActions>
    )}
  </Dialog>
)

const DialogContainer = ({
  classes = {},
  children,
  title,
  button,
  actions,
  dialogProps,
  defaultOpen = false,
  disableDefaultOnClose = false,
  onClose,
}) => {
  classes = { ...useStyles(), ...classes }

  const [showView, setShowView] = React.useState(defaultOpen || false)

  return (
    <React.Fragment>
      {showView && (
        <DialogView
          classes={classes}
          title={title}
          content={children}
          actions={actions}
          dialogProps={dialogProps}
          onClose={e => {
            if (!disableDefaultOnClose) {
              if (e) {
                // can have no event when submitted from actions
                e.stopPropagation() // otherwise it uses the parent onClick that setShowView(true)
              }
              setShowView(false)
            }
            onClose && onClose(e)
          }}
        />
      )}

      {!isNil(button)
        ? !isFunction(button)
          ? React.cloneElement(button, {
              onClick: () => {
                setShowView(true)
              },
            })
          : button({
              onShow: () => setShowView(true),
            })
        : null}
    </React.Fragment>
  )
}

export default DialogContainer
