import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'
import invariant from 'invariant'

const createMapFormatter = itemFormatter => (mapObject, props) => {
  invariant(
    isNil(props) || typeof props === 'object',
    `createMapFormatter: format props must be an object not ${props}`
  )

  const res = {}
  forEach(mapObject || {}, (item, key) => {
    res[key] = itemFormatter(item, props)
  })

  return res
}

export default createMapFormatter
