import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'
import { formatPriceWithCurrency } from 'pmt-utils/currency'

export const formatSubsidy = subsidy => {
  subsidy.grantAmountFormatted = formatPriceWithCurrency(subsidy.grantAmount)
  return subsidy
}

const formatSubsidiesData = subsidies => {
  subsidies.grantAmountFormatted = formatPriceWithCurrency(subsidies.grantAmount)
  subsidies.hasSubsidies = subsidies.subsidies.length > 0
  return subsidies
}

const formatSubsidies = createFormatter(
  formatSubsidiesData,
  createSubObjectFormatter('subsidies', createListFormatter(formatSubsidy))
)

const formatUserAccountPaymentData = userAccountPayment => {
  userAccountPayment.grantAmountFormatted = formatPriceWithCurrency(userAccountPayment.grantAmount)

  return userAccountPayment
}

export const formatUserAccountPayment = createFormatter(
  formatUserAccountPaymentData,
  createSubObjectFormatter('subsidies', formatSubsidies)
)
