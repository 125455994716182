import { tr } from 'pmt-modules/i18n'
import React from 'react'
import clsx from 'clsx'

import NumberField from 'pmt-ui/NumberField'
import { InputAdornment } from 'pmt-ui/Input'
import { withStyles } from 'pmt-ui/styles'
import { isProd } from 'pmt-modules/environment'

import TopUpContainer from '../TopUpContainer'
import AmountCircle from '../amountCircle'

const styles = theme => ({
  amounts: {
    textAlign: 'center',
    display: 'flex',
  },
  otherAmount: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  otherAmountLink: {
    display: 'block',
    marginTop: theme.spacing(3),
  },
})

const defaultAmountsToPropose = [20, 30, 50, 70]

const TopUpChooseAmountView = ({
  amountsToPropose = defaultAmountsToPropose,
  amountSize = 70,
  classes,
  displayOtherAmount = true,
  userId,
  userAccount,
}) => (
  <TopUpContainer userId={userId} userAccount={userAccount}>
    {({ topUp, topUpIsInit, onUnselectAmount, onSelectAmount, onClickOtherAmount }) => (
      <div>
        <div className={clsx('u-justifyContentSpaceAround', classes.amounts)}>
          {amountsToPropose.map(amount => {
            const isSelected = topUpIsInit ? topUp.amount === amount : 0
            return (
              <AmountCircle
                key={amount}
                value={amount}
                size={amountSize}
                isSelected={isSelected}
                onClick={() => (isSelected ? onUnselectAmount(amount) : onSelectAmount(amount))}
              />
            )
          })}
        </div>
        {displayOtherAmount && (
          <div id="otherAmount" className={classes.otherAmount}>
            {!(topUpIsInit && topUp.otherAmountClicked) ? (
              <span className={classes.otherAmountLink} onClick={onClickOtherAmount}>
                {tr('global.top_up.other_amount', {
                  fr: 'Autre montant',
                  context: '',
                  desc: 'Amount label for top up used for user to pick his own amount',
                })}
              </span>
            ) : (
              <div className="u-width60 u-inlineBlock u-textAlignCenter">
                <NumberField
                  fullWidth
                  pattern="\d*"
                  min={0}
                  // on other envs, we allow greater values, to be able to choose a specific amount
                  // which lead to an error on mangopay side, allowing us to test some errors.
                  max={isProd() ? 99 : 1000}
                  value={parseInt(topUp.amount, 10)}
                  onChange={(event, value) => onSelectAmount(value)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    )}
  </TopUpContainer>
)

export default withStyles(styles)(TopUpChooseAmountView)
