import { createAction } from '../redux'

export const OrderFrontAction = {
  POST_ORDER: 'ORDER_FRONT::POST_ORDER',
  SELECT_MODE: 'ORDER_FRONT::SELECT_MODE',
  SET_RESTAURANT_ID: 'ORDER_FRONT::SET_RESTAURANT_ID',
  SET_IDEMPOTENCY_KEY: 'ORDER_FRONT::SET_IDEMPOTENCY_KEY',
  SET_PAYMENT_METHOD: 'ORDER_FRONT::SET_PAYMENT_METHOD',
  SET_DUE_DATE: 'ORDER_FRONT::SET_DUE_DATE',
  DISPLAY_UPSELLING: 'ORDER_FRONT::DISPLAY_UPSELLING',
  RESET: 'ORDER_FRONT::RESET',
  RESET_FULL: 'ORDER_FRONT::RESET_FULL',
  SET_LOCALE: 'ORDER_FRONT::SET_LOCALE',
  SAVE_POSTED_ORDER_DATA: 'ORDER_FRONT::SAVE_POSTED_ORDER_DATA',
  SET_PAGER_ID: 'ORDER_FRONT::SET_PAGER_ID',
  ADD_MODIFIER_CODE: 'ORDER_FRONT::ADD_MODIFIER_CODE',
}

export const postOrder = (orderData, options) =>
  createAction(OrderFrontAction.POST_ORDER, { orderData, options })

export const setIdempotencyKey = idempotencyKey =>
  createAction(OrderFrontAction.SET_IDEMPOTENCY_KEY, { idempotencyKey })

export const setDueDate = dueDate => createAction(OrderFrontAction.SET_DUE_DATE, { dueDate })

export const selectMode = mode => createAction(OrderFrontAction.SELECT_MODE, { mode })

export const setRestaurantId = restaurantId =>
  createAction(OrderFrontAction.SET_RESTAURANT_ID, { restaurantId })

export const setPaymentMethod = paymentMethod =>
  createAction(OrderFrontAction.SET_PAYMENT_METHOD, { paymentMethod })

export const resetOrderFront = () => createAction(OrderFrontAction.RESET)

export const resetOrderFrontFull = () => createAction(OrderFrontAction.RESET_FULL)

export const displayUpselling = upsellingCategory =>
  createAction(OrderFrontAction.DISPLAY_UPSELLING, { upsellingCategory })

export const setLocale = locale => createAction(OrderFrontAction.SET_LOCALE, { locale })

export const savePostedOrderData = data =>
  createAction(OrderFrontAction.SAVE_POSTED_ORDER_DATA, {
    data,
  })

export const setPagerId = pagerId => createAction(OrderFrontAction.SET_PAGER_ID, { pagerId })

export const addModifierCode = code => createAction(OrderFrontAction.ADD_MODIFIER_CODE, { code })
