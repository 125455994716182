import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { getUserSettings, getOauthClient } from 'pmt-modules/appConfig'
import { postAuthorizeApp } from 'pmt-modules/authApp'
import { AuthUserAction } from 'pmt-modules/auth'
import { getItemListFromCart } from 'pmt-modules/cart/selectors'
import createMiddleware from 'pmt-modules/redux/createMiddleware'
import { setPaymentMethod } from 'pmt-modules/orderFront'
import { postOrder } from 'pmt-modules/orderPost'
import { PaymentMethodsAllowed } from 'pmt-modules/orderSettings'
import { fieldsType } from 'pmt-modules/registration/forms/register/constants'
import { getRoute, replaceWith, goBack, redirectTo } from 'pmt-modules/routing'
import { PutUserMeAction, fetchUserMe, GetUserMeAction } from 'pmt-modules/userMe'

import { getQueryParam } from 'pmt-utils/url'

import { getAppConfigFrontSettings, getOrderData, getOrderProperties } from '../selectors'

const redirectAfterUserAction = (dispatch, getState) => {
  const cartItemList = getItemListFromCart(getState())
  const orderProperties = getOrderProperties(getState())

  if (!isNil(cartItemList) && !isEmpty(cartItemList)) {
    dispatch(
      redirectTo(
        orderProperties.isDelivery
          ? getRoute('ORDER__DELIVERY_ADDRESS')
          : getRoute('ORDER__PAYMENT')
      )
    )
  } else if (!isNil(orderProperties.restaurant)) {
    dispatch(
      redirectTo(getRoute('ORDER__CATALOG'), {
        restaurantId: orderProperties.restaurant.id,
      })
    )
  } else {
    dispatch(redirectTo(getRoute('ORDER__STORE_LOCATOR')))
  }
}

const postOrderOrRedirect = (dispatch, getState) => {
  const frontSettings = getAppConfigFrontSettings(getState())
  const orderProperties = getOrderProperties(getState())
  const orderData = getOrderData(getState())

  if (!isNil(orderData)) {  

   
    if (frontSettings.display.paymentPage) {
      if (orderProperties.isDelivery) {
        dispatch(redirectTo(getRoute('ORDER__DELIVERY_ADDRESS')))
      } else {
        dispatch(redirectTo(getRoute('ORDER__PAYMENT')))
      }
    } else {
      // not displaying paymentPage
      // so we can now send the order
      dispatch(setPaymentMethod(PaymentMethodsAllowed.IRL)) // this doesn't change the orderData we use for postOrder, so the API receive paymentMethod=null
      if (orderProperties.isDelivery) {
        dispatch(redirectTo(getRoute('ORDER__DELIVERY_ADDRESS')))
      } else {
        dispatch(postOrder(orderData))
      }
    }

  } else {
    // this is a very specific behavior used for Newrest/LoungeUp integration
    // login page is set as home, so once user is connected, we redirect him to store locator
    if (frontSettings.home === 'LOGIN') {
      dispatch(redirectTo(getRoute('HOME')))
    } else {
      dispatch(goBack())
    }
  }
}

/**
 * Successfull user authentication
 * we fetch the user right after login in order to retrieve its profile,
 * this will trigger a check for missing info, and eventually get his delivery addresses
 */
const authSuccess = createMiddleware(AuthUserAction.SUCCESS, ({ dispatch, getState }) => {
  dispatch(
    fetchUserMe({
      isFromAuth: true, // used to know if is made from auth request to do appropriate response
    })
  )
})

/**
 * Successfull get user me
 * check for mandatory fields on order plugin and redirect to profile
 * otherwise, check if user comes from auth and post order or redirect to appropriate page
 * regarding display app config setting
 */
const getUserMeSuccess = createMiddleware(
  GetUserMeAction.SUCCESS,
  ({ action, dispatch, getState, next }) => {
    const props = action.data.props || {}
    const { id, phone } = action.response
    const userAppConfig = getUserSettings(getState())
    const hasPhoneRequired = find(
      userAppConfig.registration.form.fields,
      ({ required, type }) => required && type === fieldsType.PHONE
    )

    // used by LoungeUp for Newrest
    // if a callbackUri query param is set, this means only an access token has been provided
    // so we postAuthorize to retrieve the formatted redirected uri with code and state
    // and send it back to LoungeUp
    const callbackUri = getQueryParam('callbackUri')
    const state = getQueryParam('state')
    const oauth2 = getOauthClient(getState())
    if (!isEmpty(callbackUri) && !isEmpty(oauth2) && !isEmpty(state)) {
      dispatch(
        postAuthorizeApp({
          isAuthorized: true,
          redirect_uri: callbackUri,
          state,
          client_id: oauth2.clientId,
          response_type: 'code',
        })
      )
    }

    if (hasPhoneRequired && isEmpty(phone)) {
      next(
        replaceWith(
          getRoute('USER_PROFILE'),
          {
            userId: id,
          },
          {
            isFromAuth: props.isFromAuth,
          }
        )
      )
    } else if (props.isFromAuth) {
      postOrderOrRedirect(dispatch, getState)
    }
  }
)

/**
 * Intercepts update user SUCCESS action
 */
const updateProfileSuccess = createMiddleware(
  PutUserMeAction.SUCCESS,
  ({ action, dispatch, getState, next }) => {
    const isFromAuth = getQueryParam('isFromAuth')
    const isFromProfile = action.data.props && action.data.props.isFromProfile

    if (!isNil(isFromAuth)) {
      postOrderOrRedirect(dispatch, getState)
    } else if (isFromProfile) {
      redirectAfterUserAction(dispatch, getState)
    }
  }
)

export const userOrderPluginMiddlewares = [authSuccess, getUserMeSuccess, updateProfileSuccess]
