import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Shellfish = props => (
  <SvgIcon {...props}>
    <path
      d="M14.825,35.965A8.684,8.684,0,0,0,0,42.105v.28l5.159,3.6V48.4h7.05V45.99l5.159-3.6v-.28A8.627,8.627,0,0,0,14.825,35.965Zm-5.6,9.208V34.514a7.579,7.579,0,0,1,4.48,1.873L9.828,45.172Zm-1.681,0L3.667,36.388a7.579,7.579,0,0,1,4.48-1.873V45.172ZM1.079,41.829a7.574,7.574,0,0,1,1.779-4.613l3.508,7.957h-.5Zm5.154,5.493V46.247h4.9v1.074Zm5.27-2.149H11l3.508-7.957a7.574,7.574,0,0,1,1.779,4.613Z"
      transform="translate(3.215 -30.421)"
    />
  </SvgIcon>
)
Shellfish = pure(Shellfish)
Shellfish.displayName = 'Shellfish'
Shellfish.muiName = 'SvgIcon'

export default Shellfish
