import { tr } from 'pmt-modules/i18n'
import isEmpty from 'lodash/isEmpty'

import { isBo } from 'pmt-modules/environment'
import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'

import { getCountryNameForCode } from 'pmt-utils/country'

import { formatDate } from 'pmt-utils/date'

import { formatPhoneNumber } from 'pmt-utils/phone'
import { formatPspUserUrl } from 'pmt-modules/psp/format'

import { formatUserUserAccounts } from '../../userAccount/format'

import { formatDietaryPreferences } from './dietaryPreferences'

import { formatMarketing } from './marketing'

import {
  DEFAULT_BIRTHDATE,
  DEFAULT_DIETARY_PREFERENCES,
  getGenderLabel,
  Gender,
} from '../constants'

export const formatEmail = user => {
  user.hasEmail = !isEmpty(user.email)
  return user
}

export const formatGender = user => {
  user.genderLabel = getGenderLabel(user.gender || Gender.NONE)
  return user
}

export const formatBirthdate = user => {
  user.isDefaultBirthdate = user.birthdate === DEFAULT_BIRTHDATE
  user.birthdateFormatted = formatDate(user.birthdate, 'DD/MM/YYYY')

  return user
}

const formatUserDates = user => {
  user.creationDateFormatted =
    user.creationDate === 0 ? tr('global.not_available') : formatDate(user.creationDate)

  user.lastActivityDateFormatted = formatDate(user.lastActivityDate)
  return user
}

const formatPhone = user => {
  user.phoneFormatted =
    user.phone === null ? tr('global.not_available') : formatPhoneNumber(user.phone)

  return user
}

const formatName = user => {
  // api returns a fullName, but it is an odd and old value..
  if (user.firstName && user.lastName) {
    user.fullName = `${user.firstName} ${user.lastName}`
  }
  user.displayName = user.fullName || tr('user.anonymous')
  return user
}

export const formatLocales = user => {
  user.nationalityLabel = getCountryNameForCode(user.nationality)
  user.countryOfResidenceLabel =
    user.countryOfResidence === null
      ? tr('global.not_available')
      : getCountryNameForCode(user.countryOfResidence)
  user.countryLabel = getCountryNameForCode(user.country)

  return user
}

/**
 * All users have the following as default dietary preferences, but not the older ones
 */
const createDietaryIfNull = user => {
  if (!user.dietaryPreferences) {
    user.dietaryPreferences = DEFAULT_DIETARY_PREFERENCES
  }

  return user
}

const formatPsp = user => {
  if (isBo()) {
    // TODO: make pspUserId accessible on API for AuditPermissions
    user.pspUserUrl = formatPspUserUrl(user.pspUserId)
  }
  return user
}

export const formatUser = createFormatter(
  formatEmail,
  formatBirthdate,
  formatGender,
  formatPhone,
  formatUserDates,
  formatUserUserAccounts,
  formatName,
  formatLocales,
  createDietaryIfNull,
  formatPsp,
  // sub objects
  createSubObjectFormatter('dietaryPreferences', formatDietaryPreferences),
  createSubObjectFormatter('marketing', formatMarketing),
  user => {
    user.isIncognito = user.userType === 'INCOGNITO'
    return user
  }
)

export const formatUserList = createListFormatter(formatUser)
