import React, { Fragment } from 'react'
import clsx from 'clsx'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import Table, { TableBody, TableCell, TableRow } from 'pmt-ui/Table'

import { makeStyles, withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  table: {
    marginBottom: 6,
  },
  allergenIcon: {
    color: theme.pmt.colors.grey97,
  },
  allergenActive: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.text.primary,
    margin: 'auto',
  },
  tableCell: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
    width: 90,
    minWidth: 90,
    [theme.breakpoints.down('sm')]: {
      width: 55,
      minWidth: 55,
    },
  },
  alignBottom: {
    verticalAlign: 'bottom',
  },
  cellRow: {
    minWidth: 300,
    // borderBottom: 'none',
    [theme.breakpoints.down('sm')]: {
      minWidth: 200,
    },
  },
  productRowIsOdd: {
    backgroundColor: theme.pmt.colors.greyBackground,
  },
}))

const CustomTableCell = withStyles((theme) => ({
  body: {
    fontSize: 16,
    fontWeight: 600,
    minWidth: 300,
    verticalAlign: 'bottom',
  },
}))(TableCell)

/**
 * @specs N/A
 */
const DietaryInfoDialog = ({ item, allergens }) => {
  const classes = useStyles()
  let productRowIsEven = true
  let allergensDisplayed = false

  const renderItem = ({ item, allergens, depth = 2, showAllergens = false }) => (
    <Fragment>
      {/* item */}
      {!isEmpty(get(item, 'dietaryInfo.allergens', [])) && showAllergens && (
        <TableRow style={{ height: 36 }}>
          <CustomTableCell />
          {renderAllergens({ allergens })}
        </TableRow>
      )}
      {renderRow({ item, allergens, depth })}

      {/* Menu parts */}
      {item.parts &&
        (item.parts || []).map(
          (part) =>
            part.hasAllergens && (
              <Fragment>
                <TableRow key={`parts-${part.id}`} style={{ height: 80 }}>
                  <CustomTableCell
                    style={{
                      paddingLeft: 4 * depth * 3,
                    }}
                  >
                    {part.name}
                  </CustomTableCell>
                  {renderAllergens({ allergens })}
                </TableRow>
                {
                  (productRowIsEven =
                    true &&
                    (part.products || []).map((product, index) =>
                      renderItem({ allergens, item: product, depth: depth })
                    ))
                }

                {/* part categories */}
                {renderPartCategory({
                  classes,
                  categories: part.categories,
                  depth: depth + 1,
                })}
              </Fragment>
            )
        )}

      {/* Product options */}
      {(item.options || []).map(
        (option) =>
          option.hasAllergens && (
            <Fragment>
              {option.values && (
                <Fragment>
                  <TableRow style={{ height: showAllergens ? 80 : 36 }}>
                    {depth === 2 ? (
                      <Fragment>
                        <CustomTableCell
                          style={{
                            paddingLeft: 4 * (depth + 1) * 3,
                          }}
                        >
                          {option.name}
                        </CustomTableCell>
                        {showAllergens && renderAllergens({ allergens })}
                      </Fragment>
                    ) : (
                      <CustomTableCell
                        component="th"
                        scope="row"
                        colSpan={allergens.length + 1}
                        style={{
                          paddingLeft: 4 * (depth + 1) * 3,
                        }}
                      >
                        {option.name}
                      </CustomTableCell>
                    )}
                  </TableRow>
                  {
                    (productRowIsEven =
                      true &&
                      (option.values || []).map((v) => {
                        return renderRow({ allergens, item: v, depth: depth + 1 })
                      }))
                  }
                </Fragment>
              )}
            </Fragment>
          )
      )}
    </Fragment>
  )

  const renderPartCategory = ({ categories, depth = 2 }) =>
    categories &&
    categories.map((category) => (
      <Fragment>
        {(category.products || []).map((item) => renderItem({ allergens, item, depth }))}
        {(category.categories || []).map((subCategory) =>
          renderPartCategory({ categories: subCategory.categories, depth })
        )}
      </Fragment>
    ))

  const renderRow = ({ allergens, item, depth = 2 }) => {
    if (!isEmpty(get(item, 'dietaryInfo.allergens', []))) {
      productRowIsEven = !productRowIsEven
      return (
        <TableRow key={item.id} className={{ [classes.productRowIsOdd]: !productRowIsEven }}>
          <TableCell className={classes.cellRow} style={{ paddingLeft: 4 * depth * 3 }}>
            {item.name}
          </TableCell>
          {allergens.map((a) => (
            <TableCell key={`${item.id}-${a.value}`} classes={{ root: classes.tableCell }}>
              <div
                className={clsx(classes.allergen, {
                  [classes.allergenActive]:
                    item.dietaryInfo && (item.dietaryInfo.allergens || []).indexOf(a.value) >= 0,
                })}
              ></div>
            </TableCell>
          ))}
        </TableRow>
      )
    }
  }

  const renderAllergens = ({ allergens }) => {
    let displayAllergensIcons = false
    if (allergensDisplayed === false) {
      displayAllergensIcons = true
      allergensDisplayed = true
    }
    return allergens.map((a) => (
      <TableCell classes={{ root: clsx(classes.tableCell, classes.alignBottom) }}>
        {displayAllergensIcons && <div className={classes.allergenIcon}>{a.icon}</div>}
        {a.label}
      </TableCell>
    ))
  }

  return (
    <Table className={classes.table}>
      <TableBody>{renderItem({ item, allergens, classes, showAllergens: true })}</TableBody>
    </Table>
  )
}

export default DietaryInfoDialog
