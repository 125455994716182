import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { formatUser } from 'pmt-modules/user/format'
import { createSimpleSelector } from 'pmt-modules/redux'

const getUserMeData = state => state.entities.userMe

export const isFetchingUserMe = createSelector([getUserMeData], userMeData => {
  if (isNil(userMeData)) {
    return false
  }

  return userMeData.get('isFetching')
})

export const getUserMe = createSelector([getUserMeData], userMeData => {
  if (isNil(userMeData)) {
    return null
  }
  const userMe = userMeData.get('userMe')

  if (isNil(userMe)) {
    return null
  }
  return formatUser(userMe.toJS())
})

const putUserMe = createSimpleSelector(state => state.entities.putUserMe)

export const isFetchingPutUserMe = putUserMe.isFetching
export const getDataputUserMe = putUserMe.getData
export const getErrorputUserMe = putUserMe.getError
