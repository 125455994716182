import moment from 'moment'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { isSameDay, getPmtDayIndex, getMsFromHour, getMsFromMinute, getDateWithHoursAndMinutes } from 'pmt-utils/date'
import { getDeliveryTime } from 'pmt-modules/orderSettings'
import { generateHoursForDay, getForDay } from './openingHours'
import { getMaxTimeAheadAsDaysOnMode } from './mode'
import { OrderMode } from 'pmt-modules/order/constants'

export const generateSlotsForDate = (
  openingHours,
  date,
  manufactureDelay = null,
  deliveryTime = 0
) => {
  const isOpen = !isEmpty(getForDay(openingHours, getPmtDayIndex(date)))

  let finalSlots = []
  if (isOpen) {
    const slots = getSlotsForDay(openingHours, getPmtDayIndex(date), deliveryTime)

    // remove past slots
    finalSlots = slots.filter(slot => {
  
      // /!\ if you change this part:
      // test the cases with summer time changing at 3am (when ordering D-1 for D, and D for D - D being the day with time change)
      const dateWithSlot = getDateWithHoursAndMinutes(date, slot.value)

      // current slot is after now + manufacture + delivery delays
      // TODO: atm we check for the end of day, but later we might want to
      // move extra slots to the next day
      return (
        dateWithSlot.isAfter(moment().add(manufactureDelay + deliveryTime, 'ms')) &&
        dateWithSlot.isBefore(moment(date).endOf('day'))
      )
    })
  }

  return {
    date: moment(date).startOf('day'),
    slots: finalSlots,
  }
}

/**
 * Note: moment.js .day() use sunday as first day.
 * We use monday as first day on the API
 *
 * @param numberOfDays: 0 is just today 2 is today and the 2 following days
 */
export const generateSlotsForNextDays = (
  startDay,
  openingHours,
  numberOfDays,
  manufactureDelay = null,
  deliveryTime = 0
) => {
  const slotsDates = []

  for (let i = 0; i < numberOfDays + 1; i++) {
    slotsDates.push(generateSlotsForDate(openingHours, startDay, manufactureDelay, deliveryTime))

    // reset to start of day
    startDay = startDay.add(1, 'days').startOf('day')
  }

  // remove days with no slots
  const slots = slotsDates.filter(slotDate => !isEmpty(slotDate.slots))

  return slots
}

/**
 * Warning: moment.js week start at sunday, not monday
 */
export const getSlotsForDay = (openingHours, dayIndex, deliveryTime = 0) =>
  generateHoursForDay(openingHours, dayIndex, deliveryTime)

export const getRestaurantAvailabilities = (restaurant, mode) => {
  const manufactureDelay = restaurant.orderSettings.manufactureDelay
  if (isNil(manufactureDelay)) {
    return null
  }

  let deliveryTime = 0
  if (mode === OrderMode.DELIVERY) {
    deliveryTime = getDeliveryTime(restaurant.orderSettings.deliverySettings)
  }

  let numberOfDays = getMaxTimeAheadAsDaysOnMode(
    mode,
    restaurant.orderSettings.onSiteSettings,
    restaurant.orderSettings.takeAwaySettings,
    restaurant.orderSettings.deliverySettings
  )

  // manufactureDelay is in milliseconds, transform it in days
  const manufactureDelayAsDays = manufactureDelay / 60 / 60 / 24 / 1000
  if (manufactureDelayAsDays > 0) {
    numberOfDays = numberOfDays - manufactureDelayAsDays
  }

  const availabilities = generateSlotsForNextDays(
    moment().add(manufactureDelay, 'ms'), // start calculation of slots for current date + manufacture delay
    restaurant.orderSettings.openingHours,
    numberOfDays,
    manufactureDelay,
    deliveryTime
  )

  return {
    restaurant,
    availabilities,
  }
}

export const isDueDateValid = (slotsList, dueDate, isAsap) => {
  if (isAsap || isNil(dueDate)) {
    return true
  }

  let isValid = false
  const dueDateSlot =
    getMsFromHour(parseInt(dueDate.format('HH'), 10)) +
    getMsFromMinute(parseInt(dueDate.format('mm'), 10))

  slotsList.forEach(slotList => {
    if (isSameDay(slotList.date, dueDate)) {
      slotList.slots.forEach(slot => {
        if (dueDateSlot === slot.value) {
          isValid = true
        }
      })
    }
  })

  return isValid
}
