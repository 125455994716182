import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import { FormGroup, FormControlLabel } from 'pmt-ui/Form'
import { TypographyCustom } from 'pmt-ui/Typography'
import Radio from 'pmt-ui/Radio'

import Checkbox from '../../../components/Checkbox'

const ButtonType = {
  ROUND: 'ROUND',
  SQUARE: 'SQUARE',
}

const styles = (theme) => ({
  grey300: {
    color: theme.pmt.colors.grey300,
  },
})

const getButton = (type, value, disabled, onSelect) => {
  // for now, we can select a value only once, so we consider it as checked if the quantity is 1
  const checked = value.quantity === 1

  switch (type) {
    case ButtonType.ROUND:
      return (
        <Radio checked={checked} onClick={onSelect} value="" color="primary" disabled={disabled} />
      )

    case ButtonType.SQUARE:
      return (
        <Checkbox
          checked={checked}
          onChange={onSelect}
          value=""
          color="primary"
          disabled={disabled}
        />
      )

    default:
      return null
  }
}

const getLabel = (value, disabled) => {
  const labelStyle = {
    marginTop: 18,
  }

  const additionalPriceStyle = {
    fontSize: 12,
    lineHeight: '18px',
    height: '18px',
    fontWeight: 400,
    opacity: 0.5,
  }

  return (
    <TypographyCustom type="144" skipColor={disabled} component="div" style={labelStyle}>
      {value.name}
      <div style={additionalPriceStyle}>
        {value.additionalPriceFormatted !== '0,00 €' &&
          value.additionalPriceFormatted !== '€0.00' &&
          value.additionalPriceFormatted}
      </div>
      {value.stock === 0 && ` (${tr('order.catalog.unavailable')})`}
    </TypographyCustom>
  )
}

const OptionValue = ({ value, buttonType, disabled, onSelect }) => (
  <FormGroup row>
    <FormControlLabel
      control={getButton(buttonType, value, disabled, onSelect)}
      label={getLabel(value, disabled)}
      disabled={disabled}
    />
  </FormGroup>
)

OptionValue.ButtonType = ButtonType

export default withStyles(styles)(OptionValue)
