import { authenticateUser, saveIncognitoCookie } from 'pmt-modules/auth'
import { createMiddleware } from 'pmt-modules/redux'
import { EventManager } from 'pmt-modules/event'

import { RegisterUserAction, RegisterIncognitoUserAction } from './actions'
import { RegisterUserLightAction } from '.'
import { removeIncognitoCookie, saveUserLightCookie } from '../auth'
import { getQueryParam } from 'pmt-utils/url'
import { replaceWith } from 'pmt-modules/routing'
import isEmpty from 'lodash/isEmpty'

/**
 * Intercepts RegisterUserAction action. Redirects to login screen while running the oauth2
 * authentication flow.
 */
const registeredSuccessMiddleware = createMiddleware(
  RegisterUserAction.SUCCESS,
  ({ action, dispatch, next }) => {
    EventManager.dispatch(EventManager.Events.ON_REGISTER_SUCCESS, {
      user: action.response.email,
    })

    // oauth login
    const { email, password } = action.data.user
    dispatch(authenticateUser(email, password, action.data.props))
    // we do not handle redirection-after-registration here, because it will be done by the middleware of AuthUserAction.SUCCESS
  }
)

const registeredIncognitoSuccessMiddleware = createMiddleware(
  RegisterIncognitoUserAction.SUCCESS,
  ({ getState, action, dispatch, next }) => {
    saveIncognitoCookie(action.response.id)
    // if we registered as Incognito, it means we were not already authenticated (as "incognito" authentication is proposed only when not already authenticated)
    // so there is no cookies to remove

    // we handle a `redirectTo` parameter to go back on the page that asked the user to logged in
    redirectToRedirectToParamIfExists(dispatch)
    // else : maybe the user doesn't come from login page, for example because the mode is "incognito only"
    // the redirection will then depend from the front (order plugin, web customer)
    // so it will be handled in specific middlewares
    // cf. orderPlugin/middlewares
  }
)

const registeredUserLightSuccessMiddleware = createMiddleware(
  RegisterUserLightAction.SUCCESS,
  ({ action, dispatch, next }) => {
    saveUserLightCookie(action.response)
    // maybe the user was incognito, and asked to create an account => now he is registered, he's not incognito anymore
    removeIncognitoCookie()

    redirectToRedirectToParamIfExists(dispatch)
  }
)

// we handle a `redirectTo` parameter to go back on the page that asked the user to logged in
const redirectToRedirectToParamIfExists = dispatch => {
  const redirectToParam = getQueryParam('redirectTo')
  if (!isEmpty(redirectToParam)) {
    dispatch(replaceWith(decodeURIComponent(redirectToParam)))
  }
}

export const registrationMiddlewares = [
  registeredSuccessMiddleware,
  registeredIncognitoSuccessMiddleware,
  registeredUserLightSuccessMiddleware,
]
