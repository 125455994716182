import Immutable from 'immutable'

import { SuggestionActions } from './actions'

export * from './actions'
export * from './constants'
export * from './selectors'

let DEFAULT_STATE = Immutable.fromJS({
  orderItem: null,
})

export const suggestionReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SuggestionActions.CREATE:
      return state.merge({
        orderItem: action.orderItem,
      })

    default:
      return state
  }
}
