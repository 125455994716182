import { createSelector } from 'reselect'
import isNil from 'lodash/isNil'

import { formatOrders } from '../order/format'

const getUserOrders = state => state.entities.userOrders
const getUserOrderList = createSelector(
  [ getUserOrders ],
  (userOrders) => {
    if (!userOrders) {
      return null
    }
    return userOrders.get('list')
  }
)

export const getUserOrdersList = createSelector(
  [ getUserOrderList ],
  (userOrdersList) => {
    if (!userOrdersList) {
      return null
    }

    const userOrdersListData = userOrdersList.get('data')

    if (!userOrdersListData) {
      return null
    }

    return formatOrders(userOrdersListData.toJS())
  }
)

export const makeGetUserOrdersListPagination = () => createSelector(
  [ getUserOrders ],
  (userOrders) => {
    if (isNil(userOrders)) {
      return null
    }

    const pagination = userOrders.getIn(['list', 'pagination'])

    if (!pagination) {
      return null
    }

    return pagination.toJS()
  }
)

export const isFetchingUserOrdersList  = createSelector(
  [ getUserOrderList ],
  (userOrdersList) => {
    return userOrdersList.get('isFetching') || false
  }
)
