import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { getAppConfig } from 'pmt-modules/appConfig'
import { authenticateUser, getAuthError, isFetchingAuth } from 'pmt-modules/auth'
import { getErrorOrderPost, isFetchingOrderPost } from 'pmt-modules/orderPost'
import { registerAsIncognito, isFetchingRegister, getRegisterError } from 'pmt-modules/registration'
import { isFetchingUserMe } from 'pmt-modules/userMe'

import ErrorBlock from 'pmt-ui/ErrorBlock'
import LabelDivider from 'pmt-ui/LabelDivider'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import Cgu from 'pmt-ui/Cgu'

import Button from '../../../components/Button'
import MessageBlock from '../../../components/MessageBlock'
import { LoginViews } from '../constants'

const styles = (theme) => ({
  allowIncognitoButton: {
    marginTop: theme.spacing(4),
    float: 'right',
    padding: 10,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 0,
    },
  },
  switchView: {
    clear: 'both',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  signUpButton: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  connectButton: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
})

class IncognitoForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      acceptCgu: false,
    }
  }

  handleKeepIncognito = () => {
    this.props.registerAsIncognito()
  }

  render() {
    const {
      classes,
      authError,
      isFetchingRegister,
      isFetchingAuth,
      isFetchingUserMe,
      isFetchingOrderPost,
      orderPostError,
      registerError,
      onToggleView,
      cguLabel,
    } = this.props

    return (
      <React.Fragment>
        <LoadingBlockWrapper show={isFetchingRegister || isFetchingAuth || isFetchingUserMe || isFetchingOrderPost} />

        <ErrorBlock
          error={registerError || authError || orderPostError}
          mode={ErrorBlock.Mode.CUSTOM}
          customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
        />

        <div className={classes.switchView}>
          <Cgu
            label={cguLabel}
            value={this.state.acceptCgu}
            onChange={(newAcceptCgu) => this.setState({ acceptCgu: newAcceptCgu })}
          />

          <Button
            disabled={!this.state.acceptCgu}
            className={classes.signUpButton}
            size="large"
            onClick={() => this.handleKeepIncognito()}
            label={tr('global.register.continue_as_incognito')}
          />
        </div>

        <LabelDivider label={tr('order.login.or')} className="u-marginTop20" />

        <Button
          className={classes.signUpButton}
          variant="outlined"
          size="large"
          onClick={() => onToggleView(LoginViews.REGISTER)}
          label={tr('order.login.create_account')}
        />

        <LabelDivider label={tr('order.login.or')} className="u-marginTop20" />

        <Button
          className={classes.connectButton}
          variant="outlined"
          size="large"
          onClick={() => onToggleView(LoginViews.LOGIN)}
          label={tr('order.login.connect_button')}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  authError: getAuthError(state),
  isFetchingAuth: isFetchingAuth(state),
  isFetchingOrderPost: isFetchingOrderPost(state),
  isFetchingUserMe: isFetchingUserMe(state),
  isFetchingRegister: isFetchingRegister(state),
  orderPostError: getErrorOrderPost(state),
  registerError: getRegisterError(state),
  allowIncognito: getAppConfig(state).authentication.allowIncognito,
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    authenticateUser,
    registerAsIncognito,
  })
)(IncognitoForm)
