import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { withStyles } from 'pmt-ui/styles'

const styles = (theme) => ({
  poweredBy: {
    width: '100%',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.pmt.colors.grey500,
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      bottom: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
    },
  },
})

const Footer = ({ classes }) => (
  <div className={classes.poweredBy}>
    {tr('order.footer.powered', {
      fr: 'Powered by PayMyTable',
      context: '',
      desc: 'Order plugin footer text',
    })}
  </div>
)

export default withStyles(styles)(Footer)
