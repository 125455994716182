import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'

const getDialogState = state => state.ux.dialog

/**
 * at the moment, we only display one dialog at a time so we select the last one requested
 * an idea for future developments : handle a priority system instead of always the last one
 */
export const getDialog = createSelector([getDialogState], dialogState => {
  const dialogs = dialogState.get('list').toJS()

  if (!isEmpty(dialogs)) {
    const dialog = dialogs[dialogs.length - 1]
    return dialog.type ? dialog : null
  }

  return {}
})
