/**
 * Inspired by https://stackoverflow.com/questions/35623656/how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions/35641680
 */

import { createAction } from '../redux'

import { ModalType } from './constants/ModalType'

export const DialogAction = {
  SHOW_DIALOG: 'DIALOG::SHOW',
  HIDE_DIALOG: 'DIALOG::HIDE',
  HIDE_DIALOG_BACK: 'DIALOG::HIDE_BACK',
  RESET: 'DIALOG::RESET',
}

export const createShowDialogAction = (dialogType, dialogProps, callback = null) => dispatch => {
  if (callback !== null) {
    callback()
  }
  return dispatch({
    type: DialogAction.SHOW_DIALOG,
    dialogType,
    dialogProps,
  })
}

export const createHideDialogAction = dialogType => dispatch => {
  return dispatch(hideDialogAction(dialogType))
}

// clean a specific dialog
export const hideDialogAction = dialogType => createAction(DialogAction.HIDE_DIALOG, { dialogType })

// clean all openened dialogs
export const resetDialogAction = () => createAction(DialogAction.RESET)

// this dialog is used on web customer
// to choose the divider that will be affected to an entry
export const displayChooseEntryDividerDialog = entry =>
  createShowDialogAction(ModalType.PAYMENT_CHOOSE_ENTRY_DIVIDER, { id: entry.id })

// this dialog is used on web customer
// to choose the divider that will be affected to an entry
export const displayGenerousPaymentDialog = ({
  checkId,
  restaurantId,
  amount,
  outstandingBalance,
}) =>
  createShowDialogAction(ModalType.PAYMENT_GENEROUS_PAYMENT, {
    checkId,
    restaurantId,
    amount,
    outstandingBalance,
  })

// this dialog is used on order plugin
// warns the user that his carts is going to be deleted if he comes back to store locator
export const displayDeleteCartDialog = () => createShowDialogAction(ModalType.DELETE_CART, {})

export const displayCartDiffItemsDialog = () =>
  createShowDialogAction(ModalType.CART_DIFF_ITEMS, {})

export const displayCartModifierDialog = cartModifiers =>
  createShowDialogAction(ModalType.CART_MODIFIER_VIEW, { cartModifiers })

export const hideCartModifierDialog = () => createHideDialogAction(ModalType.CART_MODIFIER_VIEW)
