import { tr } from 'pmt-modules/i18n'
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Card, { CardContent, CardMedia } from 'pmt-ui/Card'
import Price from 'pmt-ui/Price'
import { TypographyCustom } from 'pmt-ui/Typography'
import PlaceholderLogo from 'pmt-ui/svg-icons/maps/restaurant'
import { withStyles } from 'pmt-ui/styles'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'
import { compose } from 'redux'

const styles = (theme) => ({
  root: {
    position: 'relative',
    maxWidth: 370,
    width: '100%',
    cursor: 'pointer',
    padding: `10px 10px 20px 10px`,
    borderWidth: theme.shape.border.width.thin,
    borderStyle: 'solid',
    borderColor: 'rgba(25,8,0,.05)',
    borderRadius: theme.shape.border.radius.itemCatalog,
    boxShadow: '0px 1px 2px rgba(25, 8, 0, 0.1)',
  },
  rootHoverable: {
    '&:hover': {
      '& $media': {
        transform: 'scale(1.1)',
      },
      '& $mediaMask': {
        opacity: 0.1,
      },
    },
  },
  mediaMask: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'black',
    opacity: 0,
    transition: 'opacity 0.35s ease',
  },
  rootCategory: {
    padding: 5,
    borderRadius: theme.shape.border.radius.subCategory,
    '&:hover': {
      '& $categoryMedia': {
        transform: 'scale(1.1)',
      },
      '& $mediaMask': {
        opacity: 0.1,
      },
      '& $categoryName': {
        opacity: 0.6,
      },
    },
  },
  content: {
    padding: `${theme.spacing(2)}px`,
    paddingBottom: `0px !important`,
  },
  contentProductAndMenu: {
    height: 137,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  contentPlaceholderHidden: {
    maxHeight: 190,
    minHeight: 140,
    height: 'auto',
    paddingTop: theme.spacing(1),
  },
  mediaContainer: {
    overflow: 'hidden',
    borderWidth: theme.shape.border.width.thin,
    borderStyle: 'solid',
    borderColor: 'rgba(25,8,0,.05)',
    borderRadius: theme.shape.border.radius.itemCatalog - 5,
    transform: 'translateZ(0)',
  },
  media: {
    aspectRatio: 1.75,
    opacity: 1,
    backgroundSize: 'cover',
    cursor: 'pointer',
    transition: 'transform 0.35s ease',
  },
  mediaUnavailable: {
    // eslint-disable-next-line
    filter:
      "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale\")",
    // eslint-disable-next-line
    filter: 'grayscale(100%)' /* IE6-9 */,
    WebkitFilter: 'grayscale(100%)',
    opacity: 0.6,
  },
  mediaPlaceholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.pmt.colors.itemPlaceholderBackground,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.pmt.colors.itemPlaceholderBackgroundSecondary,
    },
    [theme.breakpoints.down('xs')]: {
      aspectRatio: 2.7,
    },
  },
  mediaRestaurantLogo: {
    // https://paymytable.atlassian.net/browse/PP-158
    // maxHeight: '80px',
    width: '120px',
    opacity: 0.3,
  },
  mediaPlaceholderLogo: {
    // https://paymytable.atlassian.net/browse/PP-158
    height: '80px',
    width: '80px',
    opacity: 0.3,
  },
  categoryContent: {
    height: 60,
    padding: 0,
  },
  categoryMediaContainer: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 4,
    height: '100%',
    width: 70,
    flexShrink: 0,
  },
  categoryMedia: {
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.border.radius.subCategory / 2,
    transition: 'transform 0.35s ease',
  },
  categoryName: {
    paddingLeft: 10,
    paddingRight: 10,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textTransform: 'capitalize',
    transition: 'opacity 0.35s ease',
  },
  title: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    minHeight: 25,
    maxHeight: 50,
    color: theme.palette.text.main,
  },
  itemInfo: {
    overflow: 'hidden',
  },
  description: {
    maxHeight: 40,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    opacity: 0.5,
  },
  unavailable: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  unavailableWhite: {
    background: theme.pmt.overlay.background,
    cursor: 'default',
  },
  unselectable: {
    pointerEvents: 'initial',
    cursor: 'default',
  },
  unavailableChipContainer: {
    position: 'relative',
    zIndex: 1,
    cursor: 'default',
    backgroundColor: theme.pmt.colors.white,
  },
  unavailableChip: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 6,
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  unavailableChipWithoutImage: {
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: 6,
    opacity: 0.4,
  },
  white: {
    color: theme.pmt.colors.white,
  },
  priceAndButtonContainer: {
    // since we make all cards to have the same size, we need the bottom of the card to be
    // absolute to the bottom of the card
    display: 'flex',
    flexDirection: 'initial',
    verticalAlign: 'middle',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  extraInfos: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: `100%`,
    padding: '5% 5% 0',
  },
  extraInfosPlaceholder: {
    paddingTop: theme.spacing(1),
  },
  extraInfosWithoutImage: {
    position: 'relative',
    justifyContent: 'flex-start',
    padding: `0 0 ${theme.spacing(1)}px`,
  },
  menuChip: {
    padding: '5px 8px',
    borderRadius: 6,
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 600,
    backgroundColor: theme.pmt.colors.white,
    textTransform: 'capitalize',
  },
  menuChipWithoutImage: {
    border: `1px solid ${theme.palette.text.primary}`,
  },
  cartModifierContainer: {
    left: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '&:not(:first-child)': {
      marginLeft: 5,
    },
  },
  grey300: {
    color: theme.pmt.colors.grey300,
  },
  baseValue: {
    marginLeft: 0,
    marginTop: 0,
    fontSize: '0.6em',
    textDecoration: 'line-through',
  },
  hr: {
    display: 'none',
  },
})

const CardItemOrder = ({
  classes,
  image,
  name,
  item,
  description,
  price,
  hasCartModifier,
  basePrice,
  cartModifierTag,
  isCategory,
  isSelectableAndDisabled,
  onClickCard,
  displayAddButtonIcon,
  buttonAdd,
  buttonElement,
  restaurantLogo,
  isAvailable,
  ui,
  updateUI,
  width,
  isMenu,
  ...props
}) => {
  // we don't want to display the placeholder on mobile (i.e. xs)
  // see: https://www.notion.so/paymytable/Finalisation-du-Menu-Digital-2228f83526ba4e8897619991c1f9c393
  const isPlaceholderHidden = !isWidthUp('sm', width) && !image

  const ItemExtraDisplay = ({ hasImage = false }) => (
    <React.Fragment>
      {item.isMenu || item.hasCartModifier || !isAvailable ? (
        <div
          className={clsx(classes.extraInfos, {
            [classes.extraInfosWithoutImage]: !hasImage,
          })}
        >
          {!isAvailable && (
            <div className={classes.unavailableChipContainer}>
              <TypographyCustom
                component="p"
                align="center"
                type="126"
                className={clsx(classes.unavailableChip, {
                  [classes.unavailableChipWithoutImage]: !hasImage,
                })}
                skipColor
              >
                {tr('order.catalog.unavailable')}
              </TypographyCustom>
            </div>
          )}
          {isAvailable && (item.isMenu || item.hasCartModifier) && (
            <React.Fragment>
              {item.isMenu && (
                <span
                  className={clsx(classes.menuChip, {
                    [classes.menuChipWithoutImage]: !hasImage,
                  })}
                >
                  {tr('global.card_item.menu_chip')}
                </span>
              )}
              {item.hasCartModifier && (
                <div className={classes.cartModifierContainer}>
                  {item.cartModifiers.list[0] &&
                    cartModifierTag(item.cartModifiers.list[0], 0, !hasImage)}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      ) : (
        !hasImage && <div className={classes.extraInfosPlaceholder}></div>
      )}
    </React.Fragment>
  )

  return isCategory ? (
    <Card
      className={clsx(classes.root, classes.rootCategory, 'u-relative')}
      onClick={isAvailable && onClickCard}
    >
      <CardContent className={clsx(classes.content, classes.categoryContent, 'u-flexCenter u-')}>
        {image && (
          <div className={classes.categoryMediaContainer}>
            <div
              className={clsx(
                classes.categoryMedia,
                {
                  [classes.mediaUnavailable]: !isAvailable,
                },
                'u-flex0'
              )}
              style={{
                background: `url('${image}') no-repeat center center`,
                backgroundSize: 'cover',
              }}
            />
            <div className={classes.mediaMask}></div>
          </div>
        )}
        <TypographyCustom type="126" className={classes.categoryName}>
          {name}
        </TypographyCustom>
      </CardContent>
      {(!isAvailable || isSelectableAndDisabled) && (
        <div
          className={clsx(classes.unavailable, classes.unavailableWhite, {
            [classes.unselectable]: !isAvailable,
          })}
        />
      )}
    </Card>
  ) : (
    <Card
      className={clsx(classes.root, 'u-relative', {
        [classes.rootHoverable]: isAvailable && !isSelectableAndDisabled,
      })}
      onClick={isAvailable && onClickCard}
    >
      {!isPlaceholderHidden && (
        <React.Fragment>
          <div className={`${classes.mediaContainer} u-relative`}>
            {image ? (
              <CardMedia
                className={
                  !isAvailable ? `${classes.media} ${classes.mediaUnavailable}` : classes.media
                }
                image={image}
                title={name}
              />
            ) : (
              // default image
              // see https://paymytable.atlassian.net/browse/PP-158
              <div className={clsx(classes.media, classes.mediaPlaceholder)}>
                {restaurantLogo ? (
                  <img
                    className={classes.mediaRestaurantLogo}
                    src={restaurantLogo}
                    alt="restauant logo"
                  />
                ) : (
                  <PlaceholderLogo className={classes.zHiffrnLogo} />
                )}
              </div>
            )}
            <div className={classes.mediaMask}></div>
            <ItemExtraDisplay hasImage />
          </div>
        </React.Fragment>
      )}
      <CardContent
        className={clsx(classes.content, classes.contentProductAndMenu, {
          [classes.contentPlaceholderHidden]: isPlaceholderHidden,
        })}
      >
        <div className={classes.itemInfo}>
          {isPlaceholderHidden && (
            <div>
              <ItemExtraDisplay />
            </div>
          )}
          {name && (
            <TypographyCustom
              align="left"
              type="166"
              className={`${classes.title} u-marginBottom5`}
            >
              {name}
            </TypographyCustom>
          )}
          {description && (
            <TypographyCustom type="124" className={classes.description}>
              {description}
            </TypographyCustom>
          )}
        </div>
        <div className={`${classes.priceAndButtonContainer} u-marginTop10`}>
          <div className={classes.price} onClick={onClickCard}>
            {price && !isMenu && (
              <TypographyCustom component="div" type="185">
                {({ className }) => (
                  <Price
                    value={price}
                    hasCartModifier={hasCartModifier}
                    baseValue={basePrice}
                    className={className}
                    classes={{ baseValue: classes.baseValue, hr: classes.hr }}
                    column={true}
                  />
                )}
              </TypographyCustom>
            )}
          </div>
          {/* -- BUTTON ADD */}
          {isAvailable && buttonAdd ? buttonAdd : null}
          {/* -- */}
        </div>
      </CardContent>
      {(!isAvailable || isSelectableAndDisabled) && (
        <div
          className={clsx(classes.unavailable, classes.unavailableWhite, {
            [classes.unselectable]: !isAvailable,
          })}
        />
      )}
    </Card>
  )
}

CardItemOrder.propTypes = {
  title: PropTypes.string,

  // description of item, not set if category
  description: PropTypes.string,

  // set product/menu availability
  isAvailable: PropTypes.bool,

  // used in menu part to know if product is not selectable anymore
  isSelectableAndDisabled: PropTypes.bool,

  buttonAdd: PropTypes.any,
}

CardItemOrder.defaultProps = {
  isAvailable: true,
  isSelectableAndDisabled: false,

  buttonAdd: null,
}

export default compose(withStyles(styles), withWidth())(CardItemOrder)
