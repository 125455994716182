// DEFAULT is used on order plugin store locator and when we do not need a specific id.
// specific ID is used when we generate a user address. See order plugin components SearchAddress.
export const DEFAULT_GEOLOCATION_ID = 'DEFAULT_GEOLOCATION_ID'

export const GeolocationType = {
  GEOCODING: 'GEOCODING',
  REVERSE_GEOCODING: 'REVERSE_GEOCODING',
}

export const GeolocationApi = {
  GOOGLE_PLACES_API: 'PlacesApi',
  GOUV_FR_ADRESSE_API: 'AdresseGouvFr',
}
