import { getFromStorage, removeFromStorage, saveToStorage } from '../../storage'

const StorageItem = {
  RESTAURANT_ID: 'STORAGE::RESTAURANT_ID',
  API_CONSUMER: 'STORAGE::API_CONSUMER',
  BASIC_TOKEN: 'STORAGE::BASIC_TOKEN',
}

export const saveBasicToken = token => {
  saveToStorage(StorageItem.BASIC_TOKEN, token)
}

export const getDefaultBasicToken = () => getFromStorage(StorageItem.BASIC_TOKEN, null)

export const getAuthorization = () => getDefaultBasicToken()

export const removeBasicToken = () => removeFromStorage(StorageItem.BASIC_TOKEN)

export const getDefaultApiConsumer = () => getFromStorage(StorageItem.API_CONSUMER, null)

export const saveApiConsumer = apiConsumer => {
  saveToStorage(StorageItem.API_CONSUMER, apiConsumer)
}

export const removeRestaurantId = () => removeFromStorage(StorageItem.RESTAURANT_ID)

export const getDefaultRestaurantId = () => getFromStorage(StorageItem.RESTAURANT_ID, null)

export const saveRestaurantId = restaurantId => {
  saveToStorage(StorageItem.RESTAURANT_ID, restaurantId)
}

export const removeApiConsumer = () => removeFromStorage(StorageItem.API_CONSUMER)
