import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import keys from 'lodash/keys'
import moment from 'moment'

import { getMsFromHour, getMsFromMinute } from 'pmt-utils/date'
import { findOnArray } from 'pmt-utils/array'
import {
  getDayKey,
  getDayIndexForKey,
  getI18nDayNameForKey,
  getI18nDayShortNameForKey,
  formatHour,
  todayWithHour,
} from 'pmt-utils/date'

const formatOpeningHours = (openingHours, manufactureDelay) =>
  (openingHours || []).map(openingHour => ({
    ...openingHour,
    // FIXME: the formatted hour is displayed with one hour in more (11-23 instead of 10-22)
    // if we don't use todayWithHour
    formattedOpening: formatHour(todayWithHour(openingHour.opening)),
    formattedClosing: formatHour(todayWithHour(openingHour.closing)),
    closingWithManufactureDelay: openingHour.closing - manufactureDelay,
    formattedClosingWithManufactureDelay: formatHour(
      todayWithHour(openingHour.closing - manufactureDelay)
    ),
  }))

/**
 * Transform openingHours to openingHoursList, an array
 *
 * Entry:
 *
 * ```
 * {
 * "openingHours": {
 *   "monday": [
 *     {
 *       "opening": 0,
 *       "closing": 12000,
 *     }
 *   ]
 * ```
 *
 * Result:
 *
 * ```
 * {
 *   "openingHoursList": [
 *      {
 *        "dayKeyName": "monday",
 *        "dayName": "lundi",
 *        "day": 1,
 *        "isCurrentDay": true,
 *        "openingHours": [
 *          {
 *            "opening": 0,
 *            "closing": 12000,
 *            "formattedOpening": "09:00",
 *            "formattedClosing": "20:00"
 *          }
 *        ],
 *      }
 *   ]
 * }
 * ```
 */
const formatOpeningHoursList = (openingHoursArray, manufactureDelay) => {
  const today = moment()
  const currentDayName = getDayKey(today)

  return keys(openingHoursArray).map(dayKeyName => ({
    dayKeyName: dayKeyName,
    dayName: getI18nDayNameForKey(dayKeyName),
    dayNameShort: getI18nDayShortNameForKey(dayKeyName),
    dayIndex: getDayIndexForKey(dayKeyName),
    openingHours: formatOpeningHours(openingHoursArray[dayKeyName], manufactureDelay),
    isCurrentDay: currentDayName === dayKeyName,
  }))
}

const getNextOpening = (openingHoursList, date, maxNumberToCheck = 7) => {
  const i = date.day()
  const day = findOnArray(openingHoursList, day => day.dayIndex === i)

  if (!isNil(day) && !isEmpty(day.openingHours)) {
    return {
      date,
      // get first opening of the day
      nextOpeningHour: day.openingHours[0],
      ...day,
    }
  }

  // only check next opening day for a week
  if (maxNumberToCheck > 0) {
    return getNextOpening(openingHoursList, date.add(1, 'd'), --maxNumberToCheck)
  }

  return null
}

/**
 * Require `formatOpeningHoursList`
 * Warning: data can either be the restaurant or directly orderSettings
 */
export const formatRestaurantOpeningHours = data => {
  if (!data.manufactureDelay) {
    data.manufactureDelay = 0
  }
  const delayAsHours = data.manufactureDelay / 1000 / 60 / 60

  data.openingHoursList = formatOpeningHoursList(data.openingHours, data.manufactureDelay)
  const now = moment()

  data.willOpenLaterToday = false
  data.isCurrentlyOpen = false
  data.nextOpening = null

  // opening hours have not been defined yet, don't bother to proceed
  if (isEmpty(data.openingHoursList)) {
    return data
  }

  const currentDay = findOnArray(data.openingHoursList, openingHour => openingHour.isCurrentDay)
  // don't check currentDay opening if manufacture delay goes beyond anyway
  if (delayAsHours < 24 && !isNil(currentDay) && !isEmpty(currentDay.openingHours)) {
    const hours = now.format('HH')
    const minutes = now.format('mm')
    const seconds = now.format('ss')
    const nowToCompare = getMsFromHour(hours) + getMsFromMinute(minutes) + seconds * 1000

    currentDay.openingHours.forEach(openingHour => {
      // Check for currentDay if we are in a range of opening hours
      if (
        nowToCompare >= openingHour.opening &&
        nowToCompare <= openingHour.closing - data.manufactureDelay
      ) {
        data.currentOpeningHour = openingHour
        data.isCurrentlyOpen = true
      }
      // Check for currentDay if we are in a range of opening hours later today, and we do not have
      // set it yet. This will avoid overriding when restaurant have multiple openingHours.
      else if (openingHour.opening > nowToCompare && !data.nextOpening) {
        data.willOpenLaterToday = true
        data.nextOpening = {
          date: now,
          nextOpeningHour: openingHour,
          ...currentDay,
        }
      }
    })
  }

  if (isEmpty(data.nextOpening)) {
    // we don't have a nextOpening for today, so we start looking for next day
    // BUT manufacture delay might be > 1 day, if so, we start directly at this point
    let nextDay = null
    if (delayAsHours >= 24) {
      nextDay = moment().add(delayAsHours, 'hours')
    } else {
      nextDay = moment()
        .add(1, 'days')
        .startOf('day')
    }

    data.nextOpening = getNextOpening(data.openingHoursList, nextDay)
  }

  return data
}
