import { createFormatter } from 'pmt-utils/format'
import { formatPrice, formatPriceWithCurrency } from 'pmt-utils/currency'

const formatQuantity = item => {
  let quantityFormatted = ''

  if (item.share.enabled) {
    quantityFormatted = `${item.share.multiplier}/${item.share.divider}`
  } else {
    quantityFormatted = item.quantity
  }

  item.quantityFormatted = quantityFormatted
  return item
}

// TODO: handle currency
const formatItemPrice = item => {
  item.totalFormatted = formatPrice(item.total)
  item.unitPriceFormatted = formatPrice(item.unitPrice)
  item.totalFormattedWithCurrency = formatPriceWithCurrency(item.total)
  item.absoluteTotalFormattedWithCurrency = formatPriceWithCurrency(item.absoluteTotal)
  item.unitPriceFormattedWithCurrency = formatPriceWithCurrency(item.unitPrice)
  item = formatQuantity(item)
  return item
}

const formatEntry = createFormatter(formatItemPrice)

export const formatItemsMap = payment => {
  const items = payment.items || {}
  Object.keys(items).map(key => {
    const item = items[key]
    const formattedItem = formatEntry(item)
    items[formattedItem.id] = formattedItem
    return null
  })

  payment.items = items
  return payment
}
