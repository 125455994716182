import { updateArrayObject } from 'pmt-utils/array'

import { orderProductModifier } from 'pmt-modules/orderProduct/modifiers'
import { orderMenuModifier } from 'pmt-modules/orderMenu/modifiers'
import transformOrderMenuForStore from './transformOrderMenuForStore'

import Logger from 'pmt-utils/logger'

// check if an item (from cart) exists in cart
export const areSameItemFromCart = (productToCheck, productToCompare) => {
  return productToCheck.orderId === productToCompare.orderId
}

// update an item on the itemCartList
//
export const updateItem = (itemCartList, item) => {
  // first we apply the modifiers on the item
  let itemToUpdate = item
  if (itemToUpdate.isMenu) {
    itemToUpdate = transformOrderMenuForStore(orderMenuModifier(item))
  } else if (itemToUpdate.isProduct) {
    itemToUpdate = orderProductModifier(item)
  }

  return updateArrayObject(
    itemCartList,
    itemToUpdate,
    itemFromCart =>
      // we must consider that the item we update has the same orderId, and do not call
      // areSameItemFromCart
      itemFromCart.type === itemToUpdate.type && itemFromCart.orderId === itemToUpdate.orderId
  )
}

// remove from item cart's list the selected item
export const deleteItemFromCart = (itemCartList, item) => {
  const itemCartListCleaned = itemCartList.filter(
    itemFromCart => itemFromCart.orderId !== item.orderId
  )

  if (itemCartListCleaned.length === itemCartList.length) {
    Logger.warn('CART_ERROR', `Item to remove from cart not found. ID: ${item.id}`)
  }

  return itemCartListCleaned
}
