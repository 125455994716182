import React from 'react'

import clsx from 'clsx'

import { withStyles } from 'pmt-ui/styles'

import Avatar from 'pmt-ui/Avatar'
import CheckIcon from 'pmt-ui/svg-icons/navigation/check'
import EditIcon from 'pmt-ui/svg-icons/editor/mode-edit'

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  separator: {
    border: `0.5px solid ${theme.pmt.separator[400]}`,
    width: '100%',
  },
  separatorRight: {
    right: 0,
    left: '50%',
    marginLeft: 10,
  },
  separatorLeft: {
    left: 0,
    right: '50%',
    marginRight: 10,
  },
  title: {
    lineHeight: '20px',
  },
  current: {},
  avatar: {
    marginRight: 10,
  },
  avatarCurrent: {
    marginRight: 10,
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  avatarCheck: {
    backgroundColor: theme.palette.primary.main,
  },
})

/**
 * https://material.io/guidelines/components/steppers.html#steppers-types-of-steps
 */
const StepIcon = ({ step, index }) => {
  if (step.done) {
    if (step.editable) {
      //
      // Editable steps allow users to return later to edit a step. These are ideal for workflows
      // that involve editing steps within a session.
      //
      return <EditIcon />
    }

    //
    // Non-editable steps cannot be edited once completed.
    // Non-editable steps should be used when:
    // Users cannot edit a step later
    // Step editing poses a distraction risk to form completion
    //
    return <CheckIcon />
  }

  if (step.icon) {
    return step.icon
  }

  return <span>{index}</span>
}

const StepAvatar = ({ step, isCurrent, classes, index }) => (
  <Avatar
    className={clsx(classes.avatar, {
      [classes.avatarCurrent]: isCurrent,
      [classes.avatarCheck]: step.done || false,
    })}
  >
    <StepIcon step={step} index={index} />
  </Avatar>
)

const Step = ({ step, isCurrent, classes, index, isLast, onClick }) => (
  <div
    className={clsx(classes.root, {
      [classes.current]: isCurrent,
    })}
    onClick={onClick}
  >
    {index !== 0 && <div className={clsx(classes.separator, classes.separatorLeft)} />}

    <StepAvatar isCurrent={isCurrent} classes={classes} step={step} index={index} />

    <div className={classes.title}>{step.title}</div>

    {!isLast && <div className={clsx(classes.separator, classes.separatorRight)} />}
  </div>
)

export default withStyles(styles)(Step)
