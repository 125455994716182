import { createSelector } from 'reselect'

import { getCurrentLanguageWithoutRegionCode } from 'pmt-modules/i18n'
import { createSimpleSelector } from 'pmt-modules/redux'

import { formatCustomTexts } from './format'

const customTextsSelectors = createSimpleSelector(state => state.entities.customTexts)

export const isFetchingCustomTexts = customTextsSelectors.isFetching
export const getCustomTextsData = createSelector(
  [customTextsSelectors.getData, getCurrentLanguageWithoutRegionCode],
  (customTexts, locale) => formatCustomTexts(customTexts, { locale })
)

export const getCustomTextsError = customTextsSelectors.getError
