import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'

import { EventManager } from 'pmt-modules/event'
import { withForm, FormType } from 'pmt-modules/form'
import GeolocationContainer from 'pmt-modules/geolocation/container/GeolocationContainer'
import {
  getGeolocationComputeAddress,
  getGeolocationComputeAddressExtra,
} from 'pmt-modules/geolocation/selectors'
import {
  postUserAddress,
  getCreatedUserAddress,
  isFetchingUserAddressPost,
  isAddressTypeValid,
} from 'pmt-modules/userAddress'
import AddUserAddressFormView from 'pmt-modules/userAddress/forms/add/AddUserAddressFormView'
import {
  getAppConfigFrontSettings,
  getOrderProperties,
  resetDeliveryAddress,
} from 'pmt-modules/orderPlugin'

import { getAddressElementsFromGooglePlace } from 'pmt-utils/google'

import { TypographyCustom } from 'pmt-ui/Typography'
import { CircularProgress } from 'pmt-ui/Progress'
import MapsMyLocation from 'pmt-ui/svg-icons/maps/my-location'

import Address from '../../../components/Address'
import SearchAddress from '../../../components/SearchAddress'
import Button, { ButtonLink } from '../../../components/Button'

class NewAddress extends React.Component {
  state = {
    shouldReverseGeocoding: false,
  }

  constructor(props) {
    super(props)

    if (!isNil(props.geolocationComputeAddress)) {
      this.handleAddressEdition({
        addressElements: props.geolocationComputeAddress,
        types: props.geolocationComputeAddressExtra,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.createdUserAddress !== this.props.createdUserAddress &&
      !isNil(nextProps.createdUserAddress)
    ) {
      // a new address has been successfully created, hide the form
      this.props.updateUI({
        showAddressForm: false,
      })
    }
  }

  resetAddressFormDatas = () => {
    this.props.onChange({
      ...this.props.formData,
      street: null,
      postCode: null,
      city: null,
      country: null,
    })
  }

  onSelect = () => {
    if (!this.props.ui.showAddressForm) {
      this.props.updateUI({
        showAddressForm: true,
        edit: {
          showAddressForm: false,
        },
      })
      this.props.resetDeliveryAddress()
    }
  }

  handleAddressEdition = ({ addressElements, types }) => {
    if (isAddressTypeValid(types)) {
      if (!isNil(addressElements)) {
        const addressComponents = addressElements

        if (isNil(addressComponents)) {
          this.resetAddressFormDatas()
          this.props.updateUI({
            addressLocationNotGood: true,
          })
          return false
        }

        const { street, postCode, city, country } = addressComponents

        this.props.updateUI({
          addressLocationNotGood: false,
        })
        this.props.onChange({
          ...this.props.formData,
          street,
          postCode,
          city,
          country,
        })

        return true
      }
      return false
    }

    this.resetAddressFormDatas()
    this.props.updateUI({
      addressLocationNotGood: true,
    })
    return false
  }

  handleSubmitcreatedUserAddress = () => {
    const { restaurant } = this.props
    const { city, complement, country, name, postCode, street } = this.props.formData

    this.props.postUserAddress(
      this.props.user.id,
      {
        city,
        complement,
        country,
        name,
        postCode,
        street,
      },
      {
        restaurant,
      }
    )
  }

  render() {
    const {
      frontSettings,
      orderProperties,
      isFetchingUserAddressPost,
      ui,
      updateUI,
      classes,
      formData,
      formErrors,
      formIsValid,
      onChange,
    } = this.props

    return (
      <React.Fragment>
        <TypographyCustom type="164" component="h2" className={classes.subtitle}>
          {tr('order.addresses.new_address')}
        </TypographyCustom>
        <Address
          type={Address.Type.LABEL}
          label={tr('order.addresses.form.add.title')}
          selected={ui.showAddressForm}
          className={classNames(classes.subContainerWidth, 'u-marginBottom10')}
          onSelect={this.onSelect}
        />
        {ui.showAddressForm && (
          <form
            onSubmit={e => {
              e.preventDefault()
              this.handleSubmitcreatedUserAddress()
            }}
          >
            <div className={classes.subContainerWidth}>
              <GeolocationContainer>
                {({
                  geolocationId,
                  geolocateUser,
                  geolocationAddress,
                  geolocationComputeAddress,
                  geolocationError,
                  geolocationLatitude,
                  geolocationLongitude,
                  geolocationOrigin,
                  isFetchingGeolocation,
                  resetGeolocation,
                  setGeolocationAddress,
                  setGeolocationComputeAddress,
                  setGeolocationComputeAddressExtra,
                  setGeolocationCoordinates,
                }) => {
                  const handleReverseGeocodingSuccess = ({ addressElements, types }) => {
                    if (this.handleAddressEdition({ addressElements, types })) {
                      setGeolocationComputeAddress(addressElements)
                      setGeolocationComputeAddressExtra(types)
                      setGeolocationAddress(null)
                    }
                    // reverse geocoding is done, we reset its state
                    this.setState({ shouldReverseGeocoding: false })
                  }

                  const handleSelectLocation = autocomplete => {
                    const place = autocomplete.getPlace()

                    if (isUndefined(place.geometry)) {
                      return
                    }
                    const lat = place.geometry.location.lat()
                    const lng = place.geometry.location.lng()

                    const addressElements = getAddressElementsFromGooglePlace(place)

                    setGeolocationCoordinates(lat, lng)
                    setGeolocationComputeAddress(addressElements)
                    setGeolocationComputeAddressExtra(place.types)
                    setGeolocationAddress(null)

                    this.handleAddressEdition({addressElements, types: place.types})
                  }

                  return (
                    <React.Fragment>
                      <SearchAddress
                        classes={{ geoSuggestBlock: 'u-marginTop20' }}
                        label={tr('order.addresses.form.add.labels.address')}
                        error={ui.addressLocationNotGood}
                        errorText={tr('order.addresses.form.error_message.address')}
                        geolocationAddress={geolocationAddress}
                        geolocationComputeAddress={geolocationComputeAddress}
                        geolocationError={geolocationError}
                        geolocationId={geolocationId}
                        geolocationLatitude={geolocationLatitude}
                        geolocationLongitude={geolocationLongitude}
                        geolocationOrigin={geolocationOrigin}
                        onChangeCallback={event => {
                          setGeolocationAddress(event.target.value)
                          if (!isNil(geolocationComputeAddress)) {
                            setGeolocationComputeAddress(null)
                            setGeolocationComputeAddressExtra(null)
                          }

                          if (ui.addressLocationNotGood) {
                            updateUI({ addressLocationNotGood: false })
                          }
                        }}
                        reverseGeocoding={this.state.shouldReverseGeocoding}
                        reverseGeocodingSuccessCallback={handleReverseGeocodingSuccess}
                        resetAddressCallback={() => {
                          this.resetAddressFormDatas()
                          resetGeolocation()
                        }}
                        selectLocationCallback={handleSelectLocation}
                        countryRestriction={frontSettings.geoApiCountryRestriction}
                      />
                      <ButtonLink
                        label={tr('order.store_locator.geolocate_me')}
                        disabled={isFetchingGeolocation}
                        icon={isFetchingGeolocation ? <CircularProgress /> : <MapsMyLocation />}
                        classes={{ root: classes.geolocButton }}
                        onClick={() => {
                          this.setState({ shouldReverseGeocoding: true })
                          EventManager.dispatch(
                            EventManager.Events.ON_DELIVERY_ADDRESS_ADD_GEOLOCATION
                          )
                          geolocateUser()
                        }}
                      />
                    </React.Fragment>
                  )
                }}
              </GeolocationContainer>
              <AddUserAddressFormView
                formData={formData}
                formErrors={formErrors}
                onChange={onChange}
                labels={{
                  complement: tr('order.addresses.form.add.labels.complement'),
                  name: tr('order.addresses.form.add.labels.name'),
                }}
                placeholders={{
                  complement: tr('order.addresses.form.add.placeholders.complement'),
                  name: tr('order.addresses.form.add.placeholders.name'),
                }}
                classes={{
                  formContainer: classes.subContainerWidth,
                  inputStyles: classes.textField,
                  inputStreet: classes.hidden,
                  inputPostCode: classes.hidden,
                  inputCity: classes.hidden,
                  inputCountry: classes.hidden,
                }}
              />
              <TypographyCustom
                type="124"
                component="p"
                className={classNames('u-marginTop5', classes.subtitle)}
              >
                {tr('order.addresses.form.add.save_address_message')}
              </TypographyCustom>
              {get(orderProperties, 'deliveryAddress.errorMessage', null) && (
                <TypographyCustom
                  type="144"
                  component="p"
                  className={classNames('u-marginTop5', classes.errorMessage)}
                >
                  {tr('order.addresses.form.add.error.address_far')}
                </TypographyCustom>
              )}
              <div className="u-overflowHidden">
                <Button
                  type="submit"
                  size="large"
                  disabled={!formIsValid || isFetchingUserAddressPost}
                  classes={{ root: classNames(classes.bigButton, 'u-floatRight u-marginTop15') }}
                  label={tr(
                    frontSettings.display.paymentPage
                      ? 'order.addresses.form.add.submit_button'
                      : 'order.addresses.form.add.submit_button_and_order'
                  )}
                />
              </div>
            </div>
          </form>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => ({
  frontSettings: getAppConfigFrontSettings(state),
  isFetchingUserAddressPost: isFetchingUserAddressPost(state),
  createdUserAddress: getCreatedUserAddress(state),
  orderProperties: getOrderProperties(state),
  geolocationComputeAddress: getGeolocationComputeAddress(state, props),
  geolocationComputeAddressExtra: getGeolocationComputeAddressExtra(state, props),
})

export default compose(
  withForm(FormType.ADD_USER_ADDRESS),
  connect(mapStateToProps, {
    resetDeliveryAddress,
    postUserAddress,
  })
)(NewAddress)
