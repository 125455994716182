import clsx from 'clsx'
import invariant from 'invariant'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'

const styles = theme => ({
  search: {
    position: 'relative',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 300,
    },
  },
})

class Search extends React.Component {
  state = {
    query: '',
  }

  handleChange = value => {
    this.setState({
      query: value,
    })

    this.props.onChange(value)
  }

  render() {
    const { className, label, classes, searchError } = this.props

    if (!this.props.show) {
      return <span />
    }

    invariant(!isEmpty(label), 'Search must have a label')

    return (
      <div className={clsx(classes.search, className)}>
        <TextField
          // type="search"
          // this might be more html5 but force an "ugly" native reset icon
          fullWidth
          placeholder={label}
          value={this.state.query}
          onChange={e => this.handleChange(e.target.value)}
          error={searchError?.localizedMessage}
          helperText={searchError?.localizedMessage}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => this.handleChange('')}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    )
  }
}

Search.defaultProps = {
  label: '',
}

Search.propTypes = {
  show: PropTypes.bool.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(Search)
