import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import { LoadingBlockWrapper, LoadingBlock } from 'pmt-ui/LoadingBlock'
import ActionInfoOutline from 'pmt-ui/svg-icons/action/info-outline'

import SlotsView from '../../../components/Slots'

const styles = theme => ({
  date: {
    borderBottom: `1px solid ${theme.pmt.colors.grey300}`,
    cursor: 'pointer',
  },
  dateLabel: {
    display: 'block',
    padding: `${theme.spacing(2)}px 0`,
    lineHeight: '30px',
  },
  dateIcon: {
    float: 'right',
    width: 35,
    height: 35,
    color: theme.palette.primary.main,
  },
  slotContainer: {
    overflow: 'hidden',
    height: 0,
    opacity: 0,
    transition: 'opacity 0.3s ease-in',
  },
  slotContainerSelected: {
    height: 'auto',
    padding: theme.spacing(2),
    paddingTop: 0,
    opacity: 1,
  },
  slot: {
    float: 'left',
    width: 50,
    padding: '0.35rem 0.5rem',
    margin: '5px 5px 0 0',
    textAlign: 'center',
    fontSize: 12,
    border: `1px solid ${theme.pmt.colors.grey500}`,
    borderRadius: 3,
    userSelect: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  slotSelected: {
    background: theme.palette.primary.main,
    color: theme.pmt.colors.white,
    '&:hover': {
      color: theme.pmt.colors.white,
    },
  },
  slotAsap: {
    width: '160px !important',
  },
  buttonAvailability: {
    width: 140,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  hideDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  lineHeight48: {
    lineHeight: '48px',
  },
  warningContainer: {
    maxWidth: 380,
    margin: '0 auto',
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  iconInfo: {
    width: 30,
    height: 30,
    marginBottom: theme.spacing(1),
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
})

class AvailabilitySlots extends React.PureComponent {
  render() {
    const {
      handleMenuRedirection,
      isFetchingRestaurantList,
      orderProperties,
      restaurant,
      classes,
    } = this.props

    return (
      <div className="u-relative u-marginTop20">
        {isFetchingRestaurantList ? (
          <LoadingBlockWrapper show={isFetchingRestaurantList}>
            <LoadingBlock show={isFetchingRestaurantList} />
          </LoadingBlockWrapper>
        ) : isNil(restaurant) || isNil(orderProperties.restaurant) ? (
          <div className="u-marginTop60 u-textAlignCenter">
            <ActionInfoOutline className={classNames(classes.iconInfo, classes.grey500)} />
            <TypographyCustom
              type="204"
              align="center"
              className={classNames(classes.warningContainer, classes.grey500)}
            >
              {tr('order.store_locator.slot.access_warning_restaurant')}
            </TypographyCustom>
          </div>
        ) : (
          <SlotsView
            hideSubmit={
              // this should never happen, keep it for legacy until proven useless
              isFetchingRestaurantList || isNil(restaurant) || isNil(orderProperties.restaurant)
            }
            onSubmit={handleMenuRedirection}
            type={SlotsView.Type.BIG}
            showSlots={true}
            orderProperties={orderProperties}
          />
        )}
      </div>
    )
  }
}

export default withStyles(styles)(AvailabilitySlots)
