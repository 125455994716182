import React from 'react'
import ui from 'pmt-modules/reduxUi'
import isFunction from 'lodash/isFunction'

import Tooltip from 'pmt-ui/Tooltip'

const CartModifierTag = ({ cartModifier, chipElement, key, overEventsEnabled }) => (
  <Tooltip key={key} title={cartModifier.name} placement="bottom">
    {isFunction(chipElement) ? chipElement(cartModifier.tag, key) : chipElement}
  </Tooltip>
)

export default CartModifierTag
