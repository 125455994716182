import find from 'lodash/find'
import isNil from 'lodash/isNil'
import isFunction from 'lodash/isFunction'
import invariant from 'invariant'

// array of statistics groups (see createGroupStatistics)
let _statistics = []

export const registerStatisticsConfig = statistics => {
  _statistics = statistics
}

export const getStatisticsConfig = () => _statistics

/**
 * @param  {string} statisticsGroupType StatisticsGroupType
 * @return {SearchConfiguration} the configuration for the given StatisticsGroupType
 */
export const getGroupStatistics = statisticsGroupType => {
  const statistics = find(_statistics, statistics => statisticsGroupType === statistics.type)

  invariant(!isNil(statistics), 'statistics group ' + statisticsGroupType + ' not registered')

  return statistics
}

/**
 * @param  {string} statisticsGroupType StatisticsGroupType
 * @param  {string} statisticsType  type of the statistics to retrive
 * @return {SearchConfiguration} the configuration for the given StatisticsGroupType
 */
export const getStatistics = (statisticsGroupType, statisticsType) => {
  const group = getGroupStatistics(statisticsGroupType)
  const groupStatistics = find(group.statistics, statistics => statisticsType === statistics.type)

  invariant(
    !isNil(groupStatistics),
    'statistics ' + statisticsType + ' not registered for group ' + statisticsGroupType
  )

  return groupStatistics
}

export const getFormatter = (statisticsGroupType, statisticsType) => {
  const statistics = getStatistics(statisticsGroupType, statisticsType)

  const formatter = statistics.formatter

  invariant(
    isNil(formatter) || isFunction(formatter),
    'statistics ' + statisticsType + ' has no formatter'
  )

  return formatter
}

export const getStatisticsApiCallFunc = (statisticsGroupType, statisticsType) => {
  const statistics = getStatistics(statisticsGroupType, statisticsType)

  const apiCallFunc = statistics.apiCallFunc
  invariant(isFunction(apiCallFunc), 'statistics ' + statisticsType + ' has no apiCallFunc')

  return apiCallFunc
}
