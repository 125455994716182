import ApiEndpoints from '../config/ApiEndpoints'
import AuthAppErrors from '../errors/AuthAppErrors'

/**
 *
 */
const AuthApi = {
  getAuthorize: query => {
    return {
      type: 'GET',
      withoutAuth: true,
      headers: {
        // remove Authorization to avoid bugs when the token is not valid anymore.
        // we don't need to be authorize to get app info.
        // Note: Must be a 'a' as first letter
        authorization: '',
      },
      query,
      endpoint: ApiEndpoints.OAUTH_AUTHORIZE_APP,
      errorHandler: AuthAppErrors.getAuthorize,
    }
  },

  /**
   * OAuh2 app authorization request
   *
   * @param  {string} props. Required:
   *                         - client_id
   *                        Optionnal:
   *                         - api-consumer
   *                         - state
   *                         - redirect_uri
   */
  postAuthorize: props => {
    const { apiConsumer, ...bodyProps } = props

    return {
      type: 'POST',
      body: bodyProps,
      headers: {},
      endpoint: ApiEndpoints.OAUTH_AUTHORIZE_APP,
      errorHandler: AuthAppErrors.postAuthorize,
    }
  },
}

export default AuthApi
