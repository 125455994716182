import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'
import { AuthMode } from 'pmt-modules/auth/constants'

import withWidth from 'pmt-ui/utils/withWidth'
import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'

import BlockContainer from '../../components/BlockContainer'
import Breadcrumb from '../../components/Breadcrumb'
import SideSummary from '../../components/SideSummary'

import { LoginViews } from './constants'
import { LoginForm, RegisterForm, UserLightForm, AuthenticationChoiceForm } from './components'
import { ButtonLink } from '../../components/Button'
import IncognitoForm from './components/IncognitoForm'

const splitViewBreakpoints = ['xs', 'sm']

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px 0`,
    },
  },
  gridInverse: {
    flexDirection: 'row-reverse',
  },
  height100: {
    height: '100%',
  },
  border: {
    borderRadius: theme.shape.border.radius.secondaryContent,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },
  footer: {
    borderTop: `1px solid ${theme.pmt.colors.grey500}`,
    paddingTop: theme.spacing(2),
    color: theme.pmt.colors.grey600,
    fontStyle: 'italic',
    textAlign: 'justify',
  },
  privacyContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  link: {
    fontSize: 15,
    paddingLeft: 3,
  },
})

const getBreadCrumb = (authMode) => {
  let data = [
    {
      name: 'CATALOG',
      active: true,
      hiddenMobile: true,
    },
    {
      name: 'CART',
      active: true,
    },
    {
      name: 'INFORMATIONS',
      active: true,
      current: true,
    },
    {
      name: 'DELIVERY_ADDRESS',
    },
  ]

  if (authMode !== AuthMode.LIGHT) {
    data = [
      ...data,
      {
        name: 'PAYMENT',
        active: false,
      },
    ]
  }

  return data
}

const CguLabel = ({ restaurant, classes }) => (
  <TypographyCustom type="164" className={classes.privacyContainer}>
    {tr('global.register.accept_terms_clickable.first')}
    {restaurant && !isEmpty(restaurant.legal.cguUrl) ? (
      <ButtonLink
        component="a"
        classes={{
          root: classes.link,
        }}
        onClick={(e) => {
          e.stopPropagation()
          window.open(restaurant.legal.cguUrl)
        }}
        label={tr('global.register.accept_terms_clickable.second')}
      />
    ) : (
      <span>
        &nbsp;
        {tr('global.register.accept_terms_clickable.second')}
      </span>
    )}
  </TypographyCustom>
)

const View = ({
  appConfig,
  classes,
  isFetching,
  isFetchingRestaurant,
  onToggleView,
  options,
  orderProperties,
  restaurantsGroup,
  restaurant,
  cartItemList,
  width,
}) => (
  <div className={classes.root}>
    <Grid container spacing={5} className={classes.gridInverse}>
      {(isFetchingRestaurant || !isNil(restaurant)) && !isEmpty(cartItemList) && (
        <Grid item xs={12} md={4} lg={3}>
          <SideSummary
            orderProperties={orderProperties}
            restaurant={restaurant}
            cartResumeEnabled
            dueDateModificationEnabled={false}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={(isFetchingRestaurant || !isNil(restaurant)) && !isEmpty(cartItemList) ? 8 : 12}
        lg={(isFetchingRestaurant || !isNil(restaurant)) && !isEmpty(cartItemList) ? 9 : 12}
      >
        <BlockContainer
          className={classNames(classes.height100, 'u-relative', classes.border)}
          type={BlockContainer.Type.WHITE}
        >
          {isFetching ? (
            <LoadingBlock show />
          ) : (
            <React.Fragment>
              <Breadcrumb
                className="u-marginBottom30"
                elements={getBreadCrumb(appConfig.authentication.mode)}
              />
              <Grid spacing={2} container justify="space-between">
                {appConfig.authentication.mode === AuthMode.LIGHT ? (
                  <Grid item xs={12} md={12}>
                    <UserLightForm allowIncognito={appConfig.authentication.allowIncognito} />
                  </Grid>
                ) : (
                  <React.Fragment>
                    {splitViewBreakpoints.indexOf(width) !== -1 &&
                      options.view === LoginViews.CHOICE_TO_DO && (
                        <Grid item xs={12} md={5}>
                          <AuthenticationChoiceForm
                            onToggleView={onToggleView}
                            allowIncognito={appConfig.authentication.allowIncognito}
                            cguLabel={<CguLabel restaurant={restaurant} classes={classes} />}
                          />
                        </Grid>
                      )}
                    {splitViewBreakpoints.indexOf(width) !== -1 &&
                      options.view === LoginViews.INCOGNITO && (
                        <Grid item xs={12} md={5}>
                          <IncognitoForm
                            onToggleView={onToggleView}
                            allowIncognito={appConfig.authentication.allowIncognito}
                            cguLabel={<CguLabel restaurant={restaurant} classes={classes} />}
                          />
                        </Grid>
                      )}
                    {(splitViewBreakpoints.indexOf(width) === -1 ||
                      options.view === LoginViews.LOGIN) && (
                      <Grid item xs={12} md={5}>
                        <LoginForm
                          restaurant={restaurant}
                          orderProperties={orderProperties}
                          clientId={appConfig.authentication.oauth2.clientId}
                          clientSecret={appConfig.authentication.oauth2.clientSecret}
                          forgottenPasswordLink={restaurantsGroup.forgottenPasswordLink}
                          cartItemList={cartItemList}
                          onToggleView={onToggleView}
                          cguLabel={<CguLabel restaurant={restaurant} classes={classes} />}
                        />
                      </Grid>
                    )}
                    {(splitViewBreakpoints.indexOf(width) === -1 ||
                      options.view === LoginViews.REGISTER) && (
                      <Grid item xs={12} md={5}>
                        <RegisterForm
                          orderProperties={orderProperties}
                          restaurant={restaurant}
                          clientId={appConfig.authentication.oauth2.clientId}
                          clientSecret={appConfig.authentication.oauth2.clientSecret}
                          cartItemList={cartItemList}
                          onToggleView={onToggleView}
                          allowIncognito={appConfig.authentication.allowIncognito}
                          cguLabel={<CguLabel restaurant={restaurant} classes={classes} />}
                          incognitoInOneStep={splitViewBreakpoints.indexOf(width) === -1}
                        />
                      </Grid>
                    )}
                    {options.view === LoginViews.REGISTER && (
                      <Grid item xs={12}>
                        <CustomTextsContainer>
                          {({ texts }) =>
                            !isNil(texts) &&
                            !isNil(texts.ORDER__LOGIN__FOOTER) && (
                              <TypographyCustom type="124" className={classes.footer}>
                                {texts.ORDER__LOGIN__FOOTER}
                              </TypographyCustom>
                            )
                          }
                        </CustomTextsContainer>
                      </Grid>
                    )}
                  </React.Fragment>
                )}
              </Grid>
            </React.Fragment>
          )}
        </BlockContainer>
      </Grid>
    </Grid>
  </div>
)

export default compose(withStyles(styles), withWidth())(View)
