import React from 'react'

import { Route, Switch } from 'react-router-dom'
import map from 'lodash/map'
import isNil from 'lodash/isNil'
import invariant from 'invariant'

import Layout from 'pmt-ui/Layout'

import { getQueryFromUri } from 'pmt-utils/url'

import devRoutes from './devRoutes'

//
// Take the appOptions provide by the projet and create the routes
//

// Allows to handle refacto from react-router v3 to v4, where routeParams does not long exists.
const createRouteContent = route => ({ location, ...props }) => {
  const locationFinal = { ...location, query: getQueryFromUri(location.search) }
  return React.createElement(route.handler, {
    ...props,
    location: locationFinal,
    route: route.definition,
    routeParams: props.match.params,
    params: props.match.params,
  })
}

const generateRoute = route => {
  invariant(!isNil(route.definition), `definition does not exists for ${route.handler}`)
  invariant(!isNil(route.handler), `handler does not exists for ${route.definition.name}`)

  if (!route.definition.disable) {
    return (
      <Route
        exact
        name={route.definition.name}
        path={route.definition.path}
        component={createRouteContent(route)}
        key={route.definition.name}
      />
    )
  }

  return null
}

const configureRoutes = ({ routes }) => {
  // do not keep disabled routes
  const routesToGenerate = routes.filter(route => !route.disabled)

  return (
    <Layout>
      <Switch>
        {/* Routes created on pmt-modules/dev/pages that are globals and usefull in development */}
        {map(devRoutes, generateRoute)}

        {map(routesToGenerate, generateRoute)}
      </Switch>
    </Layout>
  )
}

export default configureRoutes
