
/**
 * The different features handled by PayMyTable.
 * Multiple usages, such as restaurant's features, notification settings, etc.
 */
const Feature = {
  PAYMENT: 1,

  ORDER: 2,

  BOOKING: 4,

  NEWS: 8,

  REVIEW: 16,
}

export default Feature
