import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { resetOrderFrontFull } from 'pmt-modules/orderFront'
import {
  hideUnavailableDueDateDialog,
  OrderPropertiesContainer,
  resetDayAndSlot,
  setDueDateAsap,
  resetDueDate,
} from 'pmt-modules/orderPlugin'
import OrderRestaurantContextContainer from 'pmt-modules/orderRestaurantContext/container/OrderRestaurantContextContainer'
import RestaurantContainer from 'pmt-modules/restaurant/components/RestaurantContainer'
import { getRoute, redirectTo } from 'pmt-modules/routing'

import { DialogActions, DialogTitle } from 'pmt-ui/Dialog'
import Button from 'app/components/Button'
import AlertButton from 'pmt-ui/AlertButton'
import LoadingContainer from 'pmt-ui/LoadingContainer'
import { makeStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dialogTitle: {
    padding: 0,
    marginBottom: theme.spacing(3),
  },
}))

const UnavailableDueDateDialog = ({
  hideUnavailableDueDateDialog,
  redirectTo,
  resetOrderFrontFull,
  resetDayAndSlot,
  resetDueDate,
  setDueDateAsap,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <OrderPropertiesContainer>
        {({ restaurantId }) => (
          <LoadingContainer
            container={<RestaurantContainer restaurantId={restaurantId} forceRefresh />}
            error="restaurantError"
            show={({ restaurant, isFetchingRestaurant }) => !restaurant || isFetchingRestaurant}
          >
            {({ restaurant }) => (
              <OrderRestaurantContextContainer restaurantId={restaurant.id}>
                {({ orderContext }) =>
                  !orderContext ? null : (
                    <Fragment>
                      <DialogTitle classes={{ root: classes.dialogTitle }}>
                        <TypographyCustom type="226">
                          {tr('order.unavailable_due_date.title')}
                        </TypographyCustom>
                        <TypographyCustom type="164" className="u-marginTop5">
                          {!orderContext.canAsap && !orderContext.canPreOrder
                            ? tr('order.unavailable_due_date.content.is_closed')
                            : tr('order.unavailable_due_date.content.out_of_range')}
                        </TypographyCustom>
                      </DialogTitle>
                      <DialogActions>
                        {/* ASAP */}
                        {orderContext.canAsap && (
                          <Button
                            label={tr('order.unavailable_due_date.choose_asap')}
                            onClick={() => {
                              setDueDateAsap()
                              resetDueDate()
                              hideUnavailableDueDateDialog()
                              // TODO: choose asap
                            }}
                          />
                        )}

                        {/* Choose new slot */}
                        {orderContext.canPreOrder && (
                          <Button
                            label={tr('order.unavailable_due_date.choose_pre_order')}
                            onClick={() => {
                              resetDueDate()
                              resetDayAndSlot()
                              hideUnavailableDueDateDialog()
                              // TODO: clean date to automatically display choose slots
                            }}
                          />
                        )}

                        {/* Give up the order */}
                        <AlertButton
                          button={<Button />}
                          alertTitle={tr('order.unavailable_due_date.give_up.alert.title')}
                          alertContent={tr('order.unavailable_due_date.give_up.alert.content')}
                          label={tr('order.unavailable_due_date.give_up')}
                          onAgree={() => {
                            resetOrderFrontFull()
                            hideUnavailableDueDateDialog()
                            redirectTo(getRoute('ORDER__STORE_LOCATOR'))
                            // TODO:
                          }}
                        />
                      </DialogActions>
                    </Fragment>
                  )
                }
              </OrderRestaurantContextContainer>
            )}
          </LoadingContainer>
        )}
      </OrderPropertiesContainer>
    </div>
  )
}
// Dialog root properties
UnavailableDueDateDialog.DialogRootProps = {
  // we want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, {
  hideUnavailableDueDateDialog,
  resetDayAndSlot,
  resetOrderFrontFull,
  resetDueDate,
  setDueDateAsap,
  redirectTo,
})(UnavailableDueDateDialog)
