import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import { createAction } from 'pmt-modules/redux'
import CatalogApi from 'pmt-modules/api/calls/CatalogApi'
import { ModalType, createShowDialogAction, createHideDialogAction } from 'pmt-modules/dialog'

export const GetCatalogAction = {
  ...createApiEnumAction('CATALOG::GET'),
  RESET: 'CATALOG::RESET',
}

export const fetchCatalog = (restaurantId, dueDate, mode, options) =>
  createApiCallAction(GetCatalogAction, CatalogApi.getCatalog(restaurantId, dueDate, mode), {
    restaurantId,
    dueDate,
    mode,
    ...options,
  })

export const resetCatalog = () => createAction(GetCatalogAction.RESET, {})

export const CatalogAction = {
  SET_SELECTED_CATEGORY: 'CATALOG::SET_SELECTED_CATEGORY',
  RESET_SELECTED_CATEGORY: 'CATALOG::RESET_SELECTED_CATEGORY',
  SET_PARENT_CATEGORIES: 'CATALOG::SET_PARENT_CATEGORIES',
}

/**
 *
 * @param {*} category
 */
export const selectCategory = category =>
  createAction(CatalogAction.SET_SELECTED_CATEGORY, {
    category,
  })
export const resetSelectedCategory = () =>
  createAction(CatalogAction.SET_SELECTED_CATEGORY, {
    category: [],
  })

/**
 *
 * @param {*} parentCategories
 */
export const setParentCategories = parentCategories =>
  createAction(CatalogAction.SET_PARENT_CATEGORIES, {
    parentCategories,
  })
export const resetParentCategories = () =>
  createAction(CatalogAction.SET_PARENT_CATEGORIES, {
    parentCategories: [],
  })

/**
 * @param {*} upsellingCategory item pending to be add to cart
 */
export const showUpsellingDialog = upsellingCategory =>
  createShowDialogAction(ModalType.UPSELLING_VIEW, {
    upsellingCategory,
  })

export const hideUpsellingDialog = () => createHideDialogAction(ModalType.UPSELLING_VIEW)

export const RefreshCatalogAction = createApiEnumAction('CATALOG::REFRESH')

export const refreshCatalog = (restaurantId, dueDate, mode, options) =>
  createApiCallAction(RefreshCatalogAction, CatalogApi.getCatalog(restaurantId, dueDate, mode), {
    restaurantId,
    dueDate,
    mode,
    ...options,
  })

/**
 * @param {*} item item
 */
export const showDietaryInfoDialog = item =>
  createShowDialogAction(ModalType.DIETARY_INFO, {
    item,
  })

export const hideDietaryInfoDialog = () => createHideDialogAction(ModalType.DIETARY_INFO)
