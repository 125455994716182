import { createSimpleSelector } from '../redux'

//
//
//

const createUserPostUserSelectors = createSimpleSelector(
  state => state.entities.userCreation.user
)

export const isFetchingPostUser = createUserPostUserSelectors.isFetching
export const getDataPostUser = createUserPostUserSelectors.getData
export const getErrorPostUser = createUserPostUserSelectors.getError
