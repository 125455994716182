import { tr } from 'pmt-modules/i18n'
import React from 'react'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'

import TextField from 'pmt-ui/TextField'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  formContainer: {},
  inputStyles: {
    width: '100%',
  },
  inputStreet: {},
  inputPostCode: {},
  inputCity: {},
  inputCountry: {},
  inputComplement: {},
  inputName: {},
})

class EditUserAddressFormView extends React.Component {
  render() {
    const { classes, formData, formErrors, onChange, labels, placeholders } = this.props

    return (
      <div className={classes.formContainer}>
        <TextField
          id="street"
          type="text"
          className={clsx(classes.inputStyles, classes.inputStreet)}
          label={
            !isUndefined(labels.street) ? labels.street : tr('global.form.user.address.street')
          }
          placeholder={
            !isUndefined(placeholders.street)
              ? placeholders.street
              : tr('global.form.user.address.street')
          }
          value={formData.street || ''}
          error={formData.street !== null && !isEmpty(formErrors.street.message)}
          onChange={event => onChange({ ...formData, street: event.target.value })}
        />
        <TextField
          id="postCode"
          type="text"
          className={clsx(classes.inputStyles, classes.inputPostCode)}
          label={
            !isUndefined(labels.postCode)
              ? labels.postCode
              : tr('global.form.user.address.postCode')
          }
          placeholder={
            !isUndefined(placeholders.postCode)
              ? placeholders.postCode
              : tr('global.form.user.address.postCode')
          }
          value={formData.postCode || ''}
          error={formData.postCode !== null && !isEmpty(formErrors.postCode.message)}
          onChange={event => onChange({ ...formData, postCode: event.target.value })}
        />
        <TextField
          id="city"
          type="text"
          className={clsx(classes.inputStyles, classes.inputCity)}
          label={!isUndefined(labels.city) ? labels.city : tr('global.form.user.address.city')}
          placeholder={
            !isUndefined(placeholders.city)
              ? placeholders.city
              : tr('global.form.user.address.city')
          }
          value={formData.city || ''}
          error={formData.city !== null && !isEmpty(formErrors.city.message)}
          onChange={event => onChange({ ...formData, city: event.target.value })}
        />
        <TextField
          id="country"
          type="text"
          className={clsx(classes.inputStyles, classes.inputCountry)}
          label={
            !isUndefined(labels.country) ? labels.country : tr('global.form.user.address.country')
          }
          placeholder={
            !isUndefined(placeholders.country)
              ? placeholders.country
              : tr('global.form.user.address.country')
          }
          value={formData.country || ''}
          error={formData.country !== null && !isEmpty(formErrors.country.message)}
          onChange={event => onChange({ ...formData, country: event.target.value })}
        />
        <TextField
          id="complement"
          type="text"
          className={clsx(classes.inputStyles, classes.inputComplement)}
          label={
            !isUndefined(labels.complement)
              ? labels.complement
              : tr('global.form.user.address.complement')
          }
          placeholder={
            !isUndefined(placeholders.complement)
              ? placeholders.complement
              : tr('global.form.user.address.complement')
          }
          value={formData.complement || ''}
          error={formData.complement !== null && !isEmpty(formErrors.complement.message)}
          onChange={event => onChange({ ...formData, complement: event.target.value })}
        />
        <TextField
          id="name"
          type="text"
          className={clsx(classes.inputStyles, classes.inputName)}
          label={!isUndefined(labels.name) ? labels.name : tr('global.form.user.address.name')}
          placeholder={
            !isUndefined(placeholders.name)
              ? placeholders.name
              : tr('global.form.user.address.name')
          }
          value={formData.name || ''}
          error={formData.name !== null && !isEmpty(formErrors.name.message)}
          onChange={event => onChange({ ...formData, name: event.target.value })}
        />
      </div>
    )
  }
}

EditUserAddressFormView.defaultProps = {
  labels: {},
  placeholders: {},
}

export default withStyles(styles)(EditUserAddressFormView)
