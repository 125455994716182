import DataCatcherType from './DataCatcherType'

import { isDataCatcherEnabledForType } from './config'

import FormType from 'pmt-modules/form/FormType'

export * from './actions'
export * from './middlewares'
export DataCatcherType from './DataCatcherType'

//
// This modules allows to catch data from an action, and to update other state data.
// It is used for example to catch user email on login / password recovery, and put this email
// on the login / password recovery form data, to pre-fill the forms.
//
// Note: You MUST add dataCatcherMiddlewares to the front middlewares config
//

// TODO: reducer should be defined on the front config.
export const dataCatcherFormReducer = (state, action) => {
  switch (action.dataCatcherType) {
    // Used for web customer
    case DataCatcherType.USER_FORM_EMAIL:
      if (!isDataCatcherEnabledForType(DataCatcherType.USER_FORM_EMAIL)) {
        return state
      }

      return state
        .updateIn([FormType.LOGIN, 'formData'], formData => formData.set('username', action.value))
        .updateIn([FormType.FORGOT_PASSWORD, 'formData'], formData =>
          formData.set('username', action.value)
        )

    // Used for backoffice
    case DataCatcherType.PRO_FORM_EMAIL:
      if (!isDataCatcherEnabledForType(DataCatcherType.PRO_FORM_EMAIL)) {
        return state
      }

      return state
        .updateIn([FormType.PRO_LOGIN, 'formData'], formData =>
          formData.set('username', action.value)
        )
        .updateIn([FormType.PRO_FORGOT_PASSWORD, 'formData'], formData =>
          formData.set('username', action.value)
        )

    default:
      return state
  }
}
