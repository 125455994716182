import { SdkRoutingEnum, Route } from 'pmt-modules/routing'

const RoutingEnum = {
  ...SdkRoutingEnum,

  ORDER__MODE: new Route('ORDER::MODE', '/mode', false),

  ORDER__STORE_LOCATOR: new Route('ORDER::STORE_LOCATOR', '/store-locator', false),

  ORDER__CATALOG: new Route('ORDER::CATALOG', '/restaurant/:restaurantId/catalog', false),

  ORDER__CATEGORY: new Route(
    'ORDER::CATEGORY',
    '/restaurant/:restaurantId/catalog/:categoryId',
    false
  ),

  ORDER__MENU: new Route('ORDER::MENU', '/restaurant/:restaurantId/catalog/menus/:menuId', false),

  ORDER__PRODUCT: new Route(
    'ORDER::PRODUCT',
    '/restaurant/:restaurantId/catalog/products/:productId',
    false
  ),

  ORDER__CART: new Route('ORDER::CART', '/cart', false),

  ORDER__USER__ACCOUNT: new Route('ORDER::USER::ACCOUNT', '/users/:userId/account', true),

  ORDER__DELIVERY_ADDRESS: new Route('ORDER::DELIVERY_ADDRESS', '/delivery-address', false),

  ORDER__PAYMENT: new Route('ORDER::PAYMENT', '/payment', true),

  PAYMENT__ASYNC__FORM: new Route('PAYMENT__ASYNC__FORM', '/payment/async/form'),

  ORDER__PAYMENT__VERIFICATION: new Route(
    'ORDER::PAYMENT::VERIFICATION',
    '/payment/verification',
    true
  ),

  ORDER__PAYMENT__SUCCESS: new Route('ORDER::PAYMENT::SUCCESS', '/payment/success', true),

  ORDER__PAYMENT__FAILURE: new Route('ORDER::PAYMENT::FAILURE', '/payment/failure', true),

  ORDER__PAYMENT__CANCEL: new Route('ORDER::PAYMENT::CANCEL', '/payment/cancel', true),

  ORDER__CONFIRM: new Route('ORDER::CONFIRM', '/confirm', true),
}

export default RoutingEnum
