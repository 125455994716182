import Immutable, { List } from 'immutable'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { sortArray } from 'pmt-utils/array'

import { PostOrderAction } from 'pmt-modules/orderPost'
import { PostPaymentAction } from 'pmt-modules/payment'
import { PostTopUpAction } from 'pmt-modules/topUp'

import {
  FetchUserCreditCardsAction,
  UserCreditCardAction,
  PostUserCreditCardRegistrationTypeAction,
  DeleteUserCreditCardsAction,
  SendUserCreditCardToPspAction,
  PostPspDatasAction,
} from './actions'

import { InvalidCardResponseCode } from './constants'

// Example of restaurant data
// {
//   cards: null,
//   isFetching: false,
//   lastUpdated: null,
//   error: null,
// }

export * from './components'
export * from './constants'
export * from './actions'
export * from './selectors'
export * from './middlewares'
export * from './utils'

const DEFAULT = Immutable.fromJS({
  list: null,
  isFetching: false,
  lastUpdated: null,
  error: null,
})

export const userCreditCardsReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case FetchUserCreditCardsAction.REQUEST:
      return state.mergeIn([action.data.userId], {
        list: null,
        isFetching: true,
        lastUpdated: null,
        error: null,
      })

    case FetchUserCreditCardsAction.SUCCESS:
      return state
        .mergeIn([action.data.userId], {
          isFetching: false,
          lastUpdated: new Date(),
          error: null,
        })
        .updateIn([action.data.userId, 'list'], list => {
          // FIXME: why the response is an object and not an array ?
          const cards = Object.keys(action.response).map(key => action.response[key])
          return List(sortArray(cards, card => -card.creationDate))
        })

    case PostPspDatasAction.SUCCESS:
      return state.updateIn([action.data.userId, 'list'], creditCards => {
        if (isNil(creditCards)) {
          return List.of(action.response)
        }

        return creditCards.push(action.response)
      })

    case DeleteUserCreditCardsAction.SUCCESS:
      return state.updateIn([action.data.userId, 'list'], () => {
        const cards = action.data.creditCardsList.filter(creditCard => {
          return creditCard.id !== action.data.creditCard.id
        })
        return List(cards)
      })

    case FetchUserCreditCardsAction.FAILURE:
      return state.mergeIn([action.data.userId], {
        list: null,
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
      })

    case PostOrderAction.FAILURE:
    case PostPaymentAction.FAILURE:
    case PostTopUpAction.FAILURE:
      if (get(action, 'error.code', false) === InvalidCardResponseCode) {
        const userId = get(action, 'data.orderData.userId', 'me')
        const cardId =
          get(action, 'data.orderData.payment.cardId', null) ||
          get(action, 'data.card.id', null) ||
          get(action, 'data.selectedCreditCard.id', null)

        return state.updateIn(
          [userId, 'list'],
          creditCards => creditCards?.filter(creditCard => creditCard.id !== cardId) ?? []
        )
      }
      return state

    default:
      return state
  }
}

export const userSelectedCreditCardsReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case UserCreditCardAction.SELECT_CARD:
      return state
        .updateIn([action.userId, 'list'], list => {
          if (!list) {
            return List.of(action.creditCard)
          }

          // remove if already exists, then add it.
          return list
            .remove(
              list.findIndex(creditCard => creditCard && action.creditCard.id === creditCard.id)
            )
            .push(action.creditCard)
        })
        .updateIn(['list'], list => {
          if (!list) {
            return List.of(action.creditCard)
          }

          // remove if already exists, then add it.
          return list
            .remove(
              list.findIndex(creditCard => creditCard && action.creditCard.id === creditCard.id)
            )
            .push(action.creditCard)
        })

    case UserCreditCardAction.UNSELECT_CARD:
      return state
        .updateIn([action.userId, 'list'], list => {
          if (!list) {
            return List()
          }

          return list.remove(list.findIndex(creditCard => action.creditCard.id === creditCard.id))
        })
        .updateIn(['list'], list => {
          if (!list) {
            return List()
          }

          return list.remove(list.findIndex(creditCard => action.creditCard.id === creditCard.id))
        })

    case UserCreditCardAction.RESET_CARD:
      return state
        .updateIn([action.userId, 'list'], list => {
          return List()
        })
        .updateIn(['list'], list => {
          return List()
        })

    case FetchUserCreditCardsAction.SUCCESS:
      const updateOnFetchCreditCards = (state, action) => {
        const creditCards = sortArray(action.response, card => -card.creationDate)

        if (!isEmpty(creditCards)) {
          return state
            .setIn([action.data.userId, 'list'], Immutable.fromJS([creditCards[0]]))
            .setIn(['list'], Immutable.fromJS([creditCards[0]]))
        }
        return state
      }
      return updateOnFetchCreditCards(state, action)

    case DeleteUserCreditCardsAction.SUCCESS:
      const updateOnDeleteCreditCard = (state, action) => {
        const creditCards = action.data.creditCardsList.filter(
          creditCard => creditCard.id !== action.data.creditCard.id
        )

        if (!isEmpty(creditCards)) {
          return state
            .setIn([action.data.userId, 'list'], Immutable.fromJS([creditCards[0]]))
            .setIn(['list'], Immutable.fromJS([creditCards[0]]))
        }

        return state
      }
      return updateOnDeleteCreditCard(state, action)

    default:
      return state
  }
}

const DEFAULT_POST_USER_CREDIT_CARD_REGISTRATION_TYPE = Immutable.fromJS({
  datas: null,
  isFetching: false,
  error: null,
})

export const postUserCreditCardRegistrationTypeReducer = (
  state = DEFAULT_POST_USER_CREDIT_CARD_REGISTRATION_TYPE,
  action
) => {
  switch (action.type) {
    case PostUserCreditCardRegistrationTypeAction.REQUEST:
      return state
        .set('datas', null)
        .set('error', null)
        .set('isFetching', true)

    case PostUserCreditCardRegistrationTypeAction.SUCCESS:
      return state
        .set('datas', action.response)
        .set('error', null)
        .set('isFetching', false)

    case PostUserCreditCardRegistrationTypeAction.FAILURE:
      return state
        .set('datas', null)
        .set('error', action.error)
        .set('isFetching', false)

    case PostUserCreditCardRegistrationTypeAction.RESET_ERROR:
      return state.set('error', null)

    default:
      return state
  }
}

const DEFAULT_SEND_USER_CREDIT_CARD_TO_PSP = Immutable.fromJS({
  datas: null,
  card: null,
  pspDatas: null,
  error: null,
  isFetching: false,
  success: false,
  failure: false,
  cancel: false,
})

export const sendUserCreditCardToPspReducer = (
  state = DEFAULT_SEND_USER_CREDIT_CARD_TO_PSP,
  action
) => {
  switch (action.type) {
    case SendUserCreditCardToPspAction.REQUEST:
      return state.merge({
        card: action.data.creditCard,
        pspDatas: action.data.pspDatas,
        datas: null,
        isFetching: true,
        error: null,
      })

    case SendUserCreditCardToPspAction.SUCCESS:
      return state
        .set('isFetching', false)
        .set('error', null)
        .set('datas', action.response)

    case SendUserCreditCardToPspAction.FAILURE:
      return state
        .set('isFetching', false)
        .set('datas', null)
        .set('error', action.error)

    case SendUserCreditCardToPspAction.SEND_SUCCESS:
      return state
        .set('success', true)
        .set('failure', false)
        .set('cancel', false)

    case SendUserCreditCardToPspAction.SEND_FAILURE:
      return state
        .set('success', false)
        .set('failure', true)
        .set('cancel', false)

    case SendUserCreditCardToPspAction.SEND_CANCEL:
      return state
        .set('success', false)
        .set('failure', false)
        .set('cancel', true)

    case SendUserCreditCardToPspAction.SEND_RESET:
      return state
        .set('success', false)
        .set('failure', false)
        .set('cancel', false)

    default:
      return state
  }
}

const DEFAULT_POST_PSP_DATAS = Immutable.fromJS({
  card: null,
  body: null,
  error: null,
  isFetching: false,
})

export const postPspDatasReducer = (state = DEFAULT_POST_PSP_DATAS, action) => {
  switch (action.type) {
    case PostPspDatasAction.REQUEST:
      return state.merge({
        body: action.data.body,
        isFetching: true,
        error: null,
      })

    case PostPspDatasAction.SUCCESS:
      return state
        .set('isFetching', false)
        .set('error', null)
        .set('card', action.response)

    case PostPspDatasAction.FAILURE:
      return state.set('isFetching', false).set('error', action.error)

    case PostPspDatasAction.RESET_ERROR:
      return state.set('error', null)

    default:
      return state
  }
}

const DEFAULT_DELETE_USER_CREDIT_CARD = Immutable.fromJS({
  card: null,
  error: null,
  isFetching: false,
})

export const deleteUserCreditCardReducer = (state = DEFAULT_DELETE_USER_CREDIT_CARD, action) => {
  switch (action.type) {
    case DeleteUserCreditCardsAction.REQUEST:
      return state.merge({
        card: null,
        isFetching: true,
        error: null,
      })

    case DeleteUserCreditCardsAction.SUCCESS:
      return state.merge({
        card: action.data.creditCard,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case DeleteUserCreditCardsAction.FAILURE:
      return state.merge({
        card: null,
        isFetching: false,
        error: action.error,
      })

    default:
      return state
  }
}
