import isEqualWith from 'lodash/isEqualWith'
import filter from 'lodash/filter'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import sortBy from 'lodash/sortBy'
import { CatalogItemType } from 'pmt-modules/catalog/constants'
import { findOnArray } from 'pmt-utils/array'

// get values and returns the values chosen (quantity > 0) sorted by id
const getChosenOptionValues = values =>
  sortBy(filter(values, value => value.quantity !== 0), [value => value.id])

export const areProductOptionValuesEqual = (optionToCheck, optionToCompare) => {
  // this function is used for cart (formatted order product)
  // and for reconciliation (not formatted product coming from API)
  // so we base the comparison on length and not on formatted added props "nbChosenValues"
  if (optionToCheck.values.length !== optionToCompare.values.length) {
    return false
  }

  const toCheckValues = getChosenOptionValues(optionToCheck.values)
  const toCompareValues = getChosenOptionValues(optionToCompare.values)

  if (toCompareValues.length !== toCheckValues.length) {
    return false
  }

  const isEqual = isEqualWith(
    toCheckValues,
    toCompareValues,
    (optionValuesToCheck, optionValuesToCompare) => {
      //
      // verify each option value
      //
      let i = 0
      let length = optionValuesToCheck.length
      while (i < length) {
        const optionValueToCheck = optionValuesToCheck[i]
        const optionValueToCompare = optionValuesToCompare[i]
        const valueIsEqual =
          optionValueToCheck.id === optionValueToCompare.id &&
          optionValueToCheck.quantity === optionValueToCompare.quantity

        if (!valueIsEqual) {
          return false
        }
        ++i
      }
      return true
    }
  )

  return isEqual
}

// check if products have same options selected
export const areProductOptionsEqual = (productToCheck, productToCompare) => {
  if (productToCheck.options.length !== productToCompare.options.length) {
    return false
  }

  let haveSameOptions = true
  productToCheck.options.forEach(optionToCheck => {
    if (haveSameOptions) {
      const optionFound = findOnArray(productToCompare.options, optionToCompare => {
        return (
          optionToCheck.id === optionToCompare.id &&
          optionToCheck.nbChosenValues === optionToCompare.nbChosenValues
        )
      })

      if (isNil(optionFound)) {
        haveSameOptions = false
        return false // quit loop
      } else {
        haveSameOptions = areProductOptionValuesEqual(optionToCheck, optionFound)

        if (!haveSameOptions) {
          return false // quit loop
        }
      }
    }
  })

  return haveSameOptions
}

export const areProductModifiersEqual = (productToCheck, productToCompare) => {
  return isEqual(sortBy(productToCheck.modifierIds), sortBy(productToCompare.modifierIds))
}

/**
 * check if a product (not from the cart) exists in cart
 *
 * /!\ The data could not be the same from where the call is made (cart or order preview)
 * For example, from the cart, we do not have the selectedProducts
 */
const areSameProduct = (productToCheck, productToCompare) => {
  if (productToCheck.type !== CatalogItemType.PRODUCT) {
    return false
  }

  const checkOptions = productToCheck.options.length > 0 || productToCompare.options.length > 0
  return (
    productToCheck.id === productToCompare.id &&
    productToCheck.comment === productToCompare.comment &&
    productToCheck.price === productToCompare.price &&
    productToCheck.reclaimLater === productToCompare.reclaimLater &&
    areProductModifiersEqual(productToCheck, productToCompare) &&
    (!checkOptions || areProductOptionsEqual(productToCheck, productToCompare))
  )
}

export default areSameProduct
