import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Sesame = props => (
  <SvgIcon {...props}>
    <path
      d="M4.36,10.092c0-1.547,1-2.975,2.889-4.127L7.5,5.811l.252.154c1.889,1.153,2.888,2.58,2.888,4.127A3.059,3.059,0,0,1,7.5,13.056,3.059,3.059,0,0,1,4.36,10.092Zm.928,0A2.155,2.155,0,0,0,7.5,12.18a2.155,2.155,0,0,0,2.212-2.088A4.178,4.178,0,0,0,7.5,6.86,4.178,4.178,0,0,0,5.288,10.092Zm6.048-4.049A7.276,7.276,0,0,1,8.2,5.234L7.932,5.1l.015-.283C8.06,2.7,8.87,1.171,10.29.4A3.284,3.284,0,0,1,11.858,0,3.191,3.191,0,0,1,14.58,1.482,2.878,2.878,0,0,1,13.43,5.531a4.322,4.322,0,0,1-2.094.512Zm-.582-4.887a4.118,4.118,0,0,0-1.86,3.425,6.083,6.083,0,0,0,2.442.586h0a3.363,3.363,0,0,0,1.63-.395,2.027,2.027,0,0,0,.81-2.852A2.248,2.248,0,0,0,11.858.876,2.312,2.312,0,0,0,10.754,1.156ZM1.57,5.531A2.878,2.878,0,0,1,.42,1.482,3.191,3.191,0,0,1,3.142,0,3.284,3.284,0,0,1,4.71.4c1.42.774,2.23,2.3,2.343,4.425l.015.283L6.8,5.234a7.277,7.277,0,0,1-3.137.809A4.322,4.322,0,0,1,1.57,5.531ZM1.224,1.92a2.027,2.027,0,0,0,.81,2.852,3.365,3.365,0,0,0,1.63.395A6.078,6.078,0,0,0,6.106,4.58a4.118,4.118,0,0,0-1.86-3.424,2.312,2.312,0,0,0-1.1-.28A2.248,2.248,0,0,0,1.224,1.92Z"
      transform="translate(4.542 6.168)"
    />
  </SvgIcon>
)
Sesame = pure(Sesame)
Sesame.displayName = 'Sesame'
Sesame.muiName = 'SvgIcon'

export default Sesame
