import React from 'react'
import PropTypes from 'prop-types'

import Button from 'pmt-ui/Button'
import { withStyles, withTheme } from 'pmt-ui/styles'

const styles = (theme) => ({
  root: {
    padding: 0,
    textTransform: 'initial',
    fontSize: 16,
    minHeight: 0,
    minWidth: 0,
    lineHeight: '16px',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  sizeLarge: {
    fontSize: 18,
    fontWeight: 700,
  },
  text: {},
})

// ButtonLink - used for main action for order plugin
// created to format style applied in one file
// and not everywhere a primary button has to be set
const ButtonLink = ({ children, classes, color, label, icon, theme, ...otherProps }) => (
  <Button
    variant="text"
    color={color}
    classes={classes}
    disableRipple
    disableFocusRipple
    {...otherProps}
  >
    {icon &&
      React.cloneElement(icon, {
        style: {
          marginRight: label || children ? theme.spacing(1) : 0,
          width: 20,
          height: 20,
        },
      })}
    {children || label}
  </Button>
)

ButtonLink.defaultProps = {
  color: 'primary',
}

ButtonLink.propTypes = {
  color: PropTypes.string,
  // same as 'children'
  label: PropTypes.string,
  // same as 'label'
  children: PropTypes.any,
}

export default withStyles(styles)(withTheme(ButtonLink))
