import {
  createApiCallAction,
  createApiEnumAction,
} from '../api/utils'

import UserApi from '../api/calls/UserApi'

export const FetchUserOrdersListAction = createApiEnumAction('USER::ORDERS::GET')

export const fetchUserOrdersList = (rGroupId, userId, cursor = '', limit = 50) => createApiCallAction(
  FetchUserOrdersListAction,
  UserApi.getOrdersList(rGroupId, userId, cursor, limit),
  {
    rGroupId,
    userId,
    cursor,
    limit,
  }
)
