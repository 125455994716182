import { createSelector } from 'reselect'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { findOnArray } from 'pmt-utils/array'

import { OrderMode } from 'pmt-modules/order/constants'

import { getGeolocationCoordinates } from 'pmt-modules/geolocation/selectors'
import {
  getRestaurantListData,
  getRestaurantList,
  getAllRestaurants,
} from 'pmt-modules/restaurant/selectors'
import { formatRestaurant } from 'pmt-modules/restaurant/format'

import { formatRestaurantList } from 'pmt-modules/restaurant/format'
import { formatOrderFrontProperties, formatOrderFrontData } from './format'

import { getOrderSettings as getAppConfigOrderSettings } from 'pmt-modules/appConfig'
import { getUserMe } from 'pmt-modules/userMe'
import { getCartData, getItemListForApi } from 'pmt-modules/cart/selectors'
import { getOrderPreview, getOrderPreviewVerifications } from 'pmt-modules/orderPreview'
import { getOrderSettingsData } from 'pmt-modules/orderSettings'

const getOrderFrontState = state => state.entities.orderFront

const getOrderFront = createSelector([getOrderFrontState], state => {
  return state ? state.toJS() : state
})

const getRestaurant = createSelector(
  [getAllRestaurants, getOrderFront],
  (restaurantList, orderFront) => {
    const restaurant = findOnArray(
      restaurantList,
      restaurant => restaurant.id === orderFront.restaurantId
    )

    if (!restaurant) {
      return null
    }

    return formatRestaurant(restaurant, {
      mode: orderFront.mode,
    })
  }
)

export const getMode = createSelector([getOrderFront], orderFront => orderFront.mode)

/**
 * Get all the props that we might need for our order fronts
 */
export const getOrderProperties = createSelector(
  [
    getRestaurant,
    getOrderFront,
    getCartData,
    getAppConfigOrderSettings,
    getOrderSettingsData,
    getOrderPreview,
    getOrderPreviewVerifications,
  ],
  (
    restaurant,
    orderFront,
    cartData,
    orderAppConfig,
    orderSettings,
    orderPreview,
    orderPreviewVerifications
  ) => {
    if (isNil(orderFront)) {
      return null
    }

    orderFront.restaurant = restaurant

    return formatOrderFrontProperties(orderFront, {
      cartData,
      orderAppConfig,
      orderSettings,
      orderPreview,
      orderPreviewVerifications,
    })
  }
)

/**
 * Create a basic order body data for the API.
 * See postOrder action.
 *
 * Payment data, along with other specific data you may want to send
 * should be defined in each specific order front formatter
 */
export const getOrderFrontDatas = createSelector(
  [getOrderProperties, getItemListForApi, getUserMe, getCartData],
  (orderFrontProperties, itemListForApi, userMe, cartData) =>
    formatOrderFrontData({
      orderFrontProperties,
      itemListForApi,
      userMe,
      comment: cartData.comment,
    })
)

//
//
//

const getOrderIsDeliveryOnProps = (state, props) => {
  return isNil(props) ? false : props.isDelivery
}

const getModeOnProps = (state, props) => {
  return isNil(props) ? null : props.mode
}

export const makeGetOrderRestaurantListData = () =>
  createSelector(
    [
      getRestaurantListData,
      getRestaurantList,
      getGeolocationCoordinates,
      getOrderIsDeliveryOnProps,
      getModeOnProps,
    ],
    (restaurantListData, restaurantList, geolocationCoordinates, orderIsDelivery, mode) => {
      if (isEmpty(restaurantList)) {
        return []
      }

      let restaurantListFormatted = formatRestaurantList(restaurantList, {
        latitude: geolocationCoordinates.latitude,
        longitude: geolocationCoordinates.longitude,
        mode,
      })

      if (!isNil(mode)) {
        restaurantListFormatted = restaurantListFormatted.filter(restaurant => {
          return (
            (restaurant.orderSettings.onSiteSettings.enabled && mode === OrderMode.ON_SITE) ||
            (restaurant.orderSettings.takeAwaySettings.enabled && mode === OrderMode.TAKE_AWAY) ||
            (restaurant.orderSettings.deliverySettings.enabled && mode === OrderMode.DELIVERY)
          )
        })
      }

      if (orderIsDelivery) {
        restaurantListFormatted = restaurantListFormatted.filter(restaurant => {
          return (
            restaurant.orderSettings.deliverySettings.maxRangeInMeters >= restaurant.geoPt.distance
          )
        })
      }

      return {
        ...restaurantListData,
        restaurants: restaurantListFormatted,
      }
    }
  )
