import React from 'react'

import Grid from 'pmt-ui/Grid'
import { withStyles } from 'pmt-ui/styles'

import SideSummary from '../../components/SideSummary'

import DeliveryBlock from './components/DeliveryBlock'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px 0`,
    },
  },
  gridInverse: {
    flexDirection: 'row-reverse',
  },
})

const View = ({ classes, orderProperties, restaurant, isFetchingUser, user }) => (
  <div className={classes.root}>
    <Grid container spacing={0} className={classes.gridInverse}>
      <Grid item xs={12} md={4} lg={3}>
        <SideSummary
          orderProperties={orderProperties}
          restaurant={restaurant}
          cartResumeEnabled
          userInformationsEnabled
        />
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <DeliveryBlock
          orderProperties={orderProperties}
          restaurant={restaurant}
          isFetchingUser={isFetchingUser}
          user={user}
        />
      </Grid>
    </Grid>
  </div>
)

export default withStyles(styles)(View)
