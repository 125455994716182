import React from 'react'

import last from 'lodash/last'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import classNames from 'classnames'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import { findOnArray } from 'pmt-utils/array'

import ArrowRightIcon from 'pmt-ui/svg-icons/hardware/keyboard-arrow-right'
import Select from 'pmt-ui/Select'
import { MenuItem } from 'pmt-ui/Menu'

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '&:first-child': {
      marginLeft: 24,
    },
  },
  name: {
    lineHeight: '16px', // height of the Icon
  },
  formControl: {
    minWidth: 40,
  },
  icon: {
    width: 16,
    height: 16,
  },
  parent: {
    color: theme.palette.primary.main,
    transition: 'opacity 0.35s ease',
    '&:hover': {
      opacity: 0.6,
    },
  },
  separator: {
    padding: `1px ${theme.spacing(2)}px 0`,
  },
  pointer: {
    cursor: 'pointer',
  },
  selectRoot: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxWidth: 150,
    transition: 'opacity 0.35s ease',
    '&:hover': {
      opacity: 0.6,
    },
  },
})

const SimpleCategory = ({ category }) => <span>{category.name}</span>

const SelectedCategory = ({ onSelect, category, parentCategories, classes }) => {
  if (isEmpty(parentCategories)) {
    return <SimpleCategory category={category} />
  }

  const otherCategories = last(parentCategories).categories // we only want the categories that are alongside our current category, plus our current
  // category

  // there is no category or one subcategory, so no need to display a Select
  if (isEqual(otherCategories.length, 1)) {
    return <SimpleCategory category={category} />
  }

  return (
    <Select
      // Note: we need to use the id as value, otherwise the 'selected' prop on MenuItem is set to
      // false, since Select can't compare objects.
      value={category && category.id}
      // the event value contains the 'id', we need to find on the array the corresponding category
      onChange={(event) =>
        onSelect(findOnArray(otherCategories, event.target.value), parentCategories)
      }
      // trick to display our current category name as value
      // we don't take any argument since it would be the value (category.id)
      renderValue={() => (
        <TypographyCustom type="146" component="span">
          {category.name}
        </TypographyCustom>
      )}
      native={false}
      disableUnderline={true}
      variant={'outlined'}
      className={classes.pointer}
      classes={{ root: classes.selectRoot }}
    >
      {otherCategories.map((otherCategory, index) => (
        <MenuItem
          // it could have same category multiple times (should not, but we ensure we don't
          // have a bug if it happens)
          key={`${index}_${otherCategory.id}`}
          // must put id
          value={otherCategory.id}
          //selected={otherCategory.id === category.id}
        >
          {otherCategory.name}
        </MenuItem>
      ))}
    </Select>
  )
}

// https://zpl.io/Vq6xzkN
const CategoryBreadcrumb = ({ category, isSelected, parentCategories = [], onSelect, classes }) => (
  <div className={classes.root}>
    <TypographyCustom
      type="146"
      component="div"
      className={classNames(classes.name, classes.pointer, {
        [classes.parent]: !isSelected,
      })}
      onClick={() => {
        if (!isSelected) {
          onSelect(category, parentCategories)
        }
      }}
    >
      {isSelected ? (
        <SelectedCategory
          onSelect={onSelect}
          category={category}
          parentCategories={parentCategories}
          classes={classes}
        />
      ) : (
        <SimpleCategory category={category} />
      )}
    </TypographyCustom>

    {!isSelected && (
      // TODO: icon
      <span className={classes.separator}>
        <ArrowRightIcon className={classes.icon} />
      </span>
    )}
  </div>
)

export default withStyles(styles)(CategoryBreadcrumb)
