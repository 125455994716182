import { tr } from 'pmt-modules/i18n'
import StatusChip from 'pmt-ui/StatusChip'

export const ApiConsumerStatus = {
  CREATED: 'CREATED',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  ARCHIVED: 'ARCHIVED',
}

export const getApiConsumerStatusLabel = status => {
  switch (status) {
    case ApiConsumerStatus.CREATED:
      return tr('global.apiConsumer.status.label.CREATED')
    case ApiConsumerStatus.ENABLED:
      return tr('global.apiConsumer.status.label.ENABLED')
    case ApiConsumerStatus.DISABLED:
      return tr('global.apiConsumer.status.label.DISABLED')
    case ApiConsumerStatus.ARCHIVED:
      return tr('global.apiConsumer.status.label.ARCHIVED')
    default:
      return status
  }
}

export const getApiConsumerStatusType = status => {
  switch (status) {
    case ApiConsumerStatus.CREATED:
      return StatusChip.Status.INFO
    case ApiConsumerStatus.ENABLED:
      return StatusChip.Status.VALID
    case ApiConsumerStatus.DISABLED:
      return StatusChip.Status.WARNING
    case ApiConsumerStatus.ARCHIVED:
      return StatusChip.Status.INACTIVE
    default:
      return status
  }
}

//
//
//

export const ApiConsumerPreset = {
  PLUGIN_ORDER: 'PLUGIN_ORDER',

  ORDER_THIRD_PARTY: 'ORDER_THIRD_PARTY',

  DIGITAL_MENU: 'DIGITAL_MENU',

  PLUGIN_BOOKING: 'PLUGIN_BOOKING',

  /**
   * top up, badge, history
   */
  WEB_CUSTOMER_CATERING: 'WEB_CUSTOMER_CATERING',

  WEB_CUSTOMER_PAYMENT: 'WEB_CUSTOMER_PAYMENT',

  WEB_CUSTOMER_OAUTH_AUTHORIZE: 'WEB_CUSTOMER_OAUTH_AUTHORIZE',

  WEB_CUSTOMER_TOP_UP: 'WEB_CUSTOMER_TOP_UP',

  WEB_CUSTOMER_ORDER: 'WEB_CUSTOMER_ORDER',

  // unused for now
  MOBILE_APP: 'MOBILE_APP',

  KIOSK_ORDER: 'KIOSK_ORDER',

  KIOSK_TOP_UP: 'KIOSK_TOP_UP',

  // unused for now
  KIOSK_ORDER_TOP_UP: 'KIOSK_ORDER_TOP_UP',

  KIOSK_SELF_CHECKOUT: 'KIOSK_SELF_CHECKOUT',

  // TODO: is it still used?
  TABLET_PAYMENT_ORDER: 'TABLET_PAYMENT_ORDER',

  POS: 'POS',

  BACKOFFICE: 'BACKOFFICE',

  /**
   * web global website (Feedback, etc).
   */
  WEB_GLOBAL: 'WEB_GLOBAL',

  /**
   * For external scripts / APIs etc used by PMT. Better than using the OTHER preset which gives us
   * no indication of the api consumer goal.
   */
  SCRIPT: 'SCRIPT',

  /**
   * For external scripts / APIs etc used by external. Better than using the OTHER preset which
   * gives us no indication of the api consumer goal.
   */
  THIRD_PARTY: 'THIRD_PARTY',

  /**
   * Used mostly for:
   *
   * <ul>
   *   <li>dev purposes, to avoid forcing the preset creation to implement a new feature.
   *   <li>very specific api consumers that does not have a preset.
   * </ul>
   */
  OTHER: 'OTHER',
}

export const getApiConsumerPresetLabel = preset => {
  switch (preset) {
    case ApiConsumerPreset.PLUGIN_ORDER:
      return tr('global.apiConsumer.preset.label.PLUGIN_ORDER')
    case ApiConsumerPreset.ORDER_THIRD_PARTY:
      return tr('global.apiConsumer.preset.label.ORDER_THIRD_PARTY')
    case ApiConsumerPreset.DIGITAL_MENU:
      return tr('global.apiConsumer.preset.label.DIGITAL_MENU')
    case ApiConsumerPreset.PLUGIN_BOOKING:
      return tr('global.apiConsumer.preset.label.PLUGIN_BOOKING')
    case ApiConsumerPreset.WEB_CUSTOMER_CATERING:
      return tr('global.apiConsumer.preset.label.WEB_CUSTOMER_CATERING')
    case ApiConsumerPreset.WEB_CUSTOMER_PAYMENT:
      return tr('global.apiConsumer.preset.label.WEB_CUSTOMER_PAYMENT')
    case ApiConsumerPreset.WEB_CUSTOMER_OAUTH_AUTHORIZE:
      return tr('global.apiConsumer.preset.label.WEB_CUSTOMER_OAUTH_AUTHORIZE')
    case ApiConsumerPreset.WEB_CUSTOMER_TOP_UP:
      return tr('global.apiConsumer.preset.label.WEB_CUSTOMER_TOP_UP')
    case ApiConsumerPreset.WEB_CUSTOMER_ORDER:
      return tr('global.apiConsumer.preset.label.WEB_CUSTOMER_ORDER')
    case ApiConsumerPreset.MOBILE_APP:
      return tr('global.apiConsumer.preset.label.MOBILE_APP')
    case ApiConsumerPreset.KIOSK_ORDER:
      return tr('global.apiConsumer.preset.label.KIOSK_ORDER')
    case ApiConsumerPreset.KIOSK_TOP_UP:
      return tr('global.apiConsumer.preset.label.KIOSK_TOP_UP')
    case ApiConsumerPreset.KIOSK_ORDER_TOP_UP:
      return tr('global.apiConsumer.preset.label.KIOSK_ORDER_TOP_UP')
    case ApiConsumerPreset.KIOSK_SELF_CHECKOUT:
      return tr('global.apiConsumer.preset.label.KIOSK_SELF_CHECKOUT')
    case ApiConsumerPreset.TABLET_PAYMENT_ORDER:
      return tr('global.apiConsumer.preset.label.TABLET_PAYMENT_ORDER')
    case ApiConsumerPreset.POS:
      return tr('global.apiConsumer.preset.label.POS')
    case ApiConsumerPreset.BACKOFFICE:
      return tr('global.apiConsumer.preset.label.BACKOFFICE')
    case ApiConsumerPreset.WEB_GLOBAL:
      return tr('global.apiConsumer.preset.label.WEB_GLOBAL')
    case ApiConsumerPreset.SCRIPT:
      return tr('global.apiConsumer.preset.label.SCRIPT')
    case ApiConsumerPreset.THIRD_PARTY:
      return tr('global.apiConsumer.preset.label.THIRD_PARTY')
    case ApiConsumerPreset.OTHER:
      return tr('global.apiConsumer.preset.label.OTHER')
    default:
      return preset
  }
}

export const getApiConsumerPresetExplaination = preset => {
  switch (preset) {
    case ApiConsumerPreset.PLUGIN_ORDER:
      return tr('global.apiConsumer.preset.explaination.PLUGIN_ORDER')
    case ApiConsumerPreset.ORDER_THIRD_PARTY:
      return tr('global.apiConsumer.preset.explaination.ORDER_THIRD_PARTY')
    case ApiConsumerPreset.DIGITAL_MENU:
      return tr('global.apiConsumer.preset.explaination.DIGITAL_MENU')
    case ApiConsumerPreset.PLUGIN_BOOKING:
      return tr('global.apiConsumer.preset.explaination.PLUGIN_BOOKING')
    case ApiConsumerPreset.WEB_CUSTOMER_CATERING:
      return tr('global.apiConsumer.preset.explaination.WEB_CUSTOMER_CATERING')
    case ApiConsumerPreset.WEB_CUSTOMER_PAYMENT:
      return tr('global.apiConsumer.preset.explaination.WEB_CUSTOMER_PAYMENT')
    case ApiConsumerPreset.WEB_CUSTOMER_OAUTH_AUTHORIZE:
      return tr('global.apiConsumer.preset.explaination.WEB_CUSTOMER_OAUTH_AUTHORIZE')
    case ApiConsumerPreset.WEB_CUSTOMER_TOP_UP:
      return tr('global.apiConsumer.preset.explaination.WEB_CUSTOMER_TOP_UP')
    case ApiConsumerPreset.WEB_CUSTOMER_ORDER:
      return tr('global.apiConsumer.preset.explaination.WEB_CUSTOMER_ORDER')
    case ApiConsumerPreset.MOBILE_APP:
      return tr('global.apiConsumer.preset.explaination.MOBILE_APP')
    case ApiConsumerPreset.KIOSK_ORDER:
      return tr('global.apiConsumer.preset.explaination.KIOSK_ORDER')
    case ApiConsumerPreset.KIOSK_TOP_UP:
      return tr('global.apiConsumer.preset.explaination.KIOSK_TOP_UP')
    case ApiConsumerPreset.KIOSK_ORDER_TOP_UP:
      return tr('global.apiConsumer.preset.explaination.KIOSK_ORDER_TOP_UP')
    case ApiConsumerPreset.KIOSK_SELF_CHECKOUT:
      return tr('global.apiConsumer.preset.explaination.KIOSK_SELF_CHECKOUT')
    case ApiConsumerPreset.TABLET_PAYMENT_ORDER:
      return tr('global.apiConsumer.preset.explaination.TABLET_PAYMENT_ORDER')
    case ApiConsumerPreset.POS:
      return tr('global.apiConsumer.preset.explaination.POS')
    case ApiConsumerPreset.BACKOFFICE:
      return tr('global.apiConsumer.preset.explaination.BACKOFFICE')
    case ApiConsumerPreset.WEB_GLOBAL:
      return tr('global.apiConsumer.preset.explaination.WEB_GLOBAL')
    case ApiConsumerPreset.SCRIPT:
      return tr('global.apiConsumer.preset.explaination.SCRIPT')
    case ApiConsumerPreset.THIRD_PARTY:
      return tr('global.apiConsumer.preset.explaination.THIRD_PARTY')
    case ApiConsumerPreset.OTHER:
      return tr('global.apiConsumer.preset.explaination.OTHER')
    default:
      return ''
  }
}

//
//
//

export const Front = {
  PLUGIN_ORDER: 'PLUGIN_ORDER',
  DIGITAL_MENU: 'DIGITAL_MENU',
  KIOSK: 'KIOSK',
  WEB_CUSTOMER: 'WEB_CUSTOMER',
  PLUGIN_BOOKING: 'PLUGIN_BOOKING',
  WEB_GLOBAL: 'WEB_GLOBAL',
  BACKOFFICE: 'BACKOFFICE',
  POS: 'POS',
  SCRIPT: 'SCRIPT',
  TABLET: 'TABLET',
  THIRD_PARTY: 'THIRD_PARTY',
  OTHER: 'OTHER',
}

export const getFrontlabel = front => {
  // TODO:
  return front
}

//
//
//

const getFrontPresets = () => ({
  [Front.KIOSK]: [
    ApiConsumerPreset.KIOSK_ORDER,
    ApiConsumerPreset.KIOSK_ORDER_TOP_UP,
    ApiConsumerPreset.KIOSK_SELF_CHECKOUT,
    ApiConsumerPreset.KIOSK_TOP_UP,
  ],
  [Front.WEB_CUSTOMER]: [
    ApiConsumerPreset.WEB_CUSTOMER_CATERING,
    ApiConsumerPreset.WEB_CUSTOMER_OAUTH_AUTHORIZE,
    ApiConsumerPreset.WEB_CUSTOMER_PAYMENT,
    ApiConsumerPreset.WEB_CUSTOMER_TOP_UP,
    ApiConsumerPreset.WEB_CUSTOMER_ORDER,
  ],
  [Front.PLUGIN_ORDER]: [ApiConsumerPreset.PLUGIN_ORDER, ApiConsumerPreset.WEB_CUSTOMER_ORDER],
  [Front.DIGITAL_MENU]: [ApiConsumerPreset.DIGITAL_MENU],
  [Front.PLUGIN_BOOKING]: [ApiConsumerPreset.PLUGIN_BOOKING],
  [Front.WEB_GLOBAL]: [ApiConsumerPreset.WEB_GLOBAL],
  [Front.BACKOFFICE]: [ApiConsumerPreset.BACKOFFICE],
  [Front.POS]: [ApiConsumerPreset.POS],
  [Front.SCRIPT]: [ApiConsumerPreset.SCRIPT],
  [Front.TABLET]: [ApiConsumerPreset.TABLET],
  [Front.THIRD_PARTY]: [ApiConsumerPreset.THIRD_PARTY, ApiConsumerPreset.ORDER_THIRD_PARTY],
  [Front.OTHER]: [ApiConsumerPreset.OTHER],
})

export const getPresetsForFront = front => {
  return getFrontPresets()[front]
}

//
//
//

export const CheckAccessSecurityMode = {
  POS_CHECK_ID: 'POS_CHECK_ID',
  TABLE_NUMBER: 'TABLE_NUMBER',
}

export const getCheckAccessSecurityModeLabel = mode => {
  switch (mode) {
    case CheckAccessSecurityMode.POS_CHECK_ID:
      return tr('global.apiConsumer.checkAccessSecurityMode.POS_CHECK_ID')
    case CheckAccessSecurityMode.TABLE_NUMBER:
      return tr('global.apiConsumer.checkAccessSecurityMode.TABLE_NUMBER')
    default:
      return mode
  }
}

export const PagerCaptureModes = {
  NUMERIC_PAD: 'NUMERIC_PAD',
  SCANNER_2D: 'SCANNER_2D',
  CONTACTLESS: 'CONTACTLESS',
}
