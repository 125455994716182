import { createSelector } from 'reselect'

import { OrderMode } from 'pmt-modules/order'

import { formatPaymentMethodsAllowed } from './format'

const getOrderSettings = state => state.entities.orderSettings

const getModeOnProps = (state, props) => props.orderMode

export const getOrderSettingsData = createSelector([getOrderSettings], orderSettings => {
  const orderSettingsData = orderSettings.toJS()

  return orderSettingsData
})

export const makeGetCurrentOrderSettings = () =>
  createSelector([getOrderSettings, getModeOnProps], (orderSettings, mode) => {
    const orderSettingsData = orderSettings.toJS()

    let currentOrderSettings = null
    switch (mode) {
      case OrderMode.ON_SITE:
        currentOrderSettings = orderSettingsData.onSiteSettings
        break

      case OrderMode.TAKE_AWAY:
        currentOrderSettings = orderSettingsData.takeAwaySettings
        break

      case OrderMode.DELIVERY:
        currentOrderSettings = orderSettingsData.deliverySettings
        break

      default:
        return null
    }

    return formatPaymentMethodsAllowed(currentOrderSettings)
  })

export const getOpeningHours = createSelector([getOrderSettings], orderSettings => {
  const orderSettingsData = orderSettings.toJS()

  return orderSettingsData.openingHours
})
