import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

// return isAutofillable for each menu part
const recursiveCheckPartIsAutofillable = (partCategory, hasFoundProduct) => {
  let isAutofillable = true

  if (!isEmpty(partCategory.categories)) {
    partCategory.categories.forEach(partCategory => {
      const result = recursiveCheckPartIsAutofillable(partCategory, hasFoundProduct)
      isAutofillable = result.isAutofillable
      hasFoundProduct = result.hasFoundProduct
    })
  }

  if (isAutofillable) {
    // if it is still fillable, we check that the product in the part has no options
    if (isAutofillable && !isNil(partCategory.products)) {
      if (hasFoundProduct) {
        isAutofillable = false
      } else {
        hasFoundProduct = true
        if (partCategory.products.length > 1) {
          isAutofillable = false
        } else {
          partCategory.products.forEach(product => {
            if (!isEmpty(product.options)) {
              isAutofillable = false
            }
          })
        }
      }
    }
  }

  return {
    isAutofillable,
    hasFoundProduct,
  }
}

const isMenuAutofillable = menu => {
  let isAutofillable = true

  menu.parts.forEach(part => {
    // we consider fillable a menu that has already a maximum product selected
    // or a part that has a maximum of 1 and only one product inside
    if (isAutofillable) {
      if (
        part.nbChosenValues !== part.max &&
        (part.min !== 1 || part.max !== 1 || part.products.length > 1)
      ) {
        isAutofillable = false
      } else if (part.nbChosenValues !== part.max) {
        const result = recursiveCheckPartIsAutofillable(part, false)
        isAutofillable = result.isAutofillable
      }
    }
  })

  return isAutofillable
}

export default isMenuAutofillable
