import ApiEndpoints from '../config/ApiEndpoints'
import AuthErrors from '../errors/AuthErrors'

/**
 *
 */
const AuthApi = {
  /**
   * OAuh2 token request with password grant type.
   * @param  {string} username      the username (email)
   * @param  {string} password      the password
   * @param  {string} client_id     the oauth2 client public id
   * @param  {string} client_secret the oauth2 client secret
   */
  postAuthPassword: (username, password, client_id, client_secret) => {
    return {
      type: 'POST',
      isForm: true,
      body: {
        grant_type: 'password',
        client_id,
        client_secret,
        username,
        password,
      },
      headers: {
        authorization: null, // force override global authorization
      },
      endpoint: ApiEndpoints.OAUTH_TOKEN,
      errorHandler: AuthErrors.POST,
    }
  },

  postRefreshToken: (refresh_token, client_id, client_secret) => {
    return {
      type: 'POST',
      isForm: true,
      body: {
        grant_type: 'refresh_token',
        client_id,
        client_secret,
        refresh_token,
      },
      headers: {
        authorization: null, // force override global authorization
      },
      endpoint: ApiEndpoints.OAUTH_TOKEN,
      errorHandler: AuthErrors.POST,
    }
  },
}

export default AuthApi
