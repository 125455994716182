import * as Sentry from '@sentry/browser'

import isString from 'lodash/isString'
import isNil from 'lodash/isNil'

import { getFrontSessionId } from 'pmt-modules/frontSession'

//import { getStore } from 'pmt-modules/store'

// contains current logged in user info
let _userData = null

let _loggerConfig = {
  SENTRY_URL: '',

  DISPLAY_REPORT_DIALOG: true,

  APP_VERSION: '',
}

export default class Logger {
  static LoggerLevel = {
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
  }

  //
  // Define the different contexts
  //

  static UserContext = {
    ANONYMOUS: 'ANONYMOUS',
    USER: 'USER',
  }

  static ProContext = {
    ANONYMOUS: 'ANONYMOUS',
    PRO: 'PRO',
  }

  static RestaurantContext = {
    ANONYMOUS: 'ANONYMOUS',
    RESTAURANT: 'RESTAURANT',
  }

  // https://github.com/getsentry/sentry-javascript/issues/2027
  static isSetup() {
    const hub = Sentry.getCurrentHub()
    if (!isNil(hub)) {
      return !isNil(hub.getClient())
    }
    return false
  }

  //
  //
  //

  /**
   * Configure sentry logging
   * @param  {[type]} config [description]
   * @return {[type]}        [description]
   */
  static configure(config) {
    _loggerConfig = config

    // Do not send errors to sentry if we are on localhost.
    if (document.location.hostname === 'localhost') {
      return
    }

    if (!Logger.isSetup()) {
      Sentry.init({
        dsn: _loggerConfig.SENTRY_URL,
        debug: true,
        // see https://docs.sentry.io/clients/javascript/config/
        release: _loggerConfig.APP_VERSION,
        environment: _loggerConfig.ENV,
        // Enables/disables automatic collection of breadcrumbs. default: true
        autoBreadcrumbs: {
          xhr: true, // XMLHttpRequest
          console: true, // console logging
          dom: true, // DOM interactions, i.e. clicks/typing
          location: true, // url changes, including pushState/popState
          sentry: true, // sentry events
        },
        // Maximum number of levels that normalization algorithm will traverse in objects and arrays.
        normalizeDepth: 11,
        // see https://docs.sentry.io/clients/javascript/tips/
        ignoreErrors: [
          // fetch
          // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
          //'TypeError: Failed to fetch',
          'TypeError: NetworkError when attempting to fetch resource.',
          'TypeError: Cancelled',
          // Random plugins/extensions
          'top.GLOBALS',
          // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
          'originalCreateNotification',
          'canvas.contentDocument',
          // Facebook borked
          'fb_xd_fragment',
        ],
        ignoreUrls: [
          // Facebook flakiness
          /graph\.facebook\.com/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
        ],
      })

      Sentry.setTag('appCommit', _loggerConfig.__APP_GIT_COMMIT__)
      Sentry.setTag('sdkCommit', _loggerConfig.__SDK_GIT_COMMIT__)
      Sentry.setTag('sdkBranch', _loggerConfig.__SDK_GIT_BRANCH__)
      Sentry.setTag('appBranch', _loggerConfig.__APP_GIT_BRANCH__)
      Sentry.setTag('frontSessionId', getFrontSessionId())
    }
  }

  static notImplemented(key, detail = '', extra = {}) {
    console.error(key, detail, extra)

    if (Logger.isSetup()) {
      const message = `[${key}] ${Logger.formatObject(detail)}`
      Sentry.withScope(scope => {
        scope.setLevel(Logger.LoggerLevel.WARNING)
        scope.setExtra('userData', _userData)
        scope.setExtra('extra', extra)
        Sentry.captureMessage(message)
      })

      Sentry.showReportDialog()
    }
  }

  static info(key, detail, extra = {}) {
    if (Logger.isSetup()) {
      const message = `[${key}] ${Logger.formatObject(detail)}`
      Sentry.withScope(scope => {
        scope.setLevel(Logger.LoggerLevel.INFO)
        scope.setExtra('userData', _userData)
        scope.setExtra('extra', extra)
        Sentry.captureMessage(message)
      })
    }
  }

  static warn(key, detail, extra = {}) {
    Logger.warning(key, detail, extra)
  }

  static warning(key, detail, extra = {}) {
    console.warn(key, detail)

    if (Logger.isSetup()) {
      const message = `[${key}] ${Logger.formatObject(detail)}`
      Sentry.withScope(scope => {
        scope.setLevel(Logger.LoggerLevel.WARNING)
        scope.setExtra('userData', _userData)
        scope.setExtra('extra', extra)
        Sentry.captureMessage(message)
      })
    }
  }

  static error(key, detail, extra = {}) {
    console.error(key, detail, extra)

    if (Logger.isSetup()) {
      const message = `[${key}] ${Logger.formatObject(detail)}`
      Sentry.withScope(scope => {
        scope.setLevel(Logger.LoggerLevel.ERROR)
        scope.setExtra('userData', _userData)
        scope.setExtra('extra', extra)
        Sentry.captureMessage(message)
      })
    }
  }

  /**
   * Called when an exception is thrown during an action.
   *
   * See crashReporter.js
   *
   * @param  {[type]} err    exception thrown
   * @param  {[type]} action action dispatched when the error occurred
   */
  static reduxException(err, action) {
    console.error('action exception', err)

    if (Logger.isSetup()) {
      Sentry.withScope(scope => {
        scope.setLevel(Logger.LoggerLevel.ERROR)
        scope.setExtra('action', {
          response: action.response,
        })
        scope.setExtra('userData', _userData)
        Sentry.captureException(err)
      })

      Logger.runReportDialog()
    }
  }

  static formatObject(object) {
    if (!isString(object)) {
      try {
        return JSON.stringify(object)
      } catch (e) {}
    }

    return object
  }

  static componentDidCatch(error, info) {
    if (Logger.isSetup()) {
      Sentry.withScope(scope => {
        // see https://docs.sentry.io/platforms/javascript/#extra-context
        scope.setExtra('info', info)
        scope.setExtra('userData', _userData)
        Sentry.captureException(error)
      })

      Logger.runReportDialog()
    }
  }

  /**
   * Log an exception to the console and send it to our tracker (raven).
   * @see https://github.com/getsentry/raven-js/blob/master/docs/integrations/react.rst
   *
   * @param ex
   * @param context
   */
  static logException(ex, context) {
    if (Logger.isSetup()) {
      Sentry.withScope(scope => {
        // see https://docs.sentry.io/platforms/javascript/#extra-context
        scope.setExtra('context', context)
        scope.setExtra('userData', _userData)
        Sentry.captureException(ex)
      })

      Logger.runReportDialog()
    }
    /* eslint no-console:0 */
    if (window.console && console.error) {
      console.error(ex)
    }
  }

  static runReportDialog() {
    if (_loggerConfig.DISPLAY_REPORT_DIALOG === true) {
      const data = {
        eventId: Logger.lastEventId(),
        name: _userData ? `${_userData.firstName} ${_userData.lastName}` : '',
        email: _userData ? _userData.email : '',
        comments: '',
      }

      // do not show if already displayed
      // Note: in most cases, the dialog is ask to show multiple times in a row. In this case the
      // following code does not work.
      const sentryClass = 'sentry-error-embed-wrapper'
      const classes = document.getElementsByClassName(sentryClass)
      if (classes && classes.length > 0) {
        return
      }

      // see https://docs.getsentry.com/hosted/clients/javascript/usage/#user-feedback
      Sentry.showReportDialog(data)

      // put some default data on report dialog, need to wait for the dialog to appear.
      // Note: we wait for ~1s, it is completely arbitrary and some browsers don't display the
      // dialog before this timeout ends.
      // In this case, we attempt one retry.
      const prefillReportDialogData = (data, shouldRetryIfNotLoadedYet = false) => {
        const nameElem = document.getElementById('id_name')
        const emailElem = document.getElementById('id_email')
        const idElem = document.getElementById('id_comments')

        // if all elements are null, the modal isn't display yet.
        if (isNil(idElem) && isNil(nameElem) && isNil(emailElem)) {
          // not loaded yet, should we retry ?
          if (shouldRetryIfNotLoadedYet === true) {
            setTimeout(() => {
              // false to avoid infinite loop
              prefillReportDialogData(data, false)
            }, 1200)
          }
        } else {
          if (!isNil(nameElem) && !isNil(data.name)) {
            nameElem.value = data.name
          }

          if (!isNil(emailElem) && !isNil(data.email)) {
            emailElem.value = data.email
          }

          if (!isNil(idElem) && !isNil(data.comments)) {
            idElem.value = data.comments
          }
        }
      }

      // first try after 1s
      setTimeout(() => {
        prefillReportDialogData(data, true)
      }, 1000) // 1s
    }
  }

  //
  //
  //

  /**
   * https://docs.sentry.io/platforms/javascript/#capturing-the-user
   * @param see Logger.UserContext
   * @param userId
   */
  static setUserContext(userContext, userData = {}) {
    if (Logger.isSetup()) {
      _userData = userData

      Sentry.configureScope(scope => {
        scope.setUser({
          userContext,
          id: userData.id,
          email: userData.email,
          restaurantsGroup: userData.restaurantsGroup,
          name: `${userData.firstName} ${userData.lastName}`,
        })
      })
    }
  }

  /**
   * https://docs.sentry.io/platforms/javascript/#capturing-the-user
   * @param see Logger.UserContext
   * @param userId
   */
  static setProContext(proContext, proData) {
    _userData = proData

    if (Logger.isSetup()) {
      Sentry.configureScope(scope => {
        scope.setUser({
          userContext: proContext,
          id: proData.id,
          email: proData.email,
          name: `${proData.firstName} ${proData.lastName}`,
        })
      })
    }
  }

  /**
   * https://docs.sentry.io/platforms/javascript/#capturing-the-user
   * @param see Logger.UserContext
   * @param userId
   */
  static setRestaurantContext(restaurantContext, restaurantData) {
    _userData = restaurantData

    if (Logger.isSetup()) {
      Sentry.configureScope(scope => {
        scope.setUser({
          userContext: restaurantContext,
          restaurantId: restaurantData.restaurantId,
        })
      })
    }
  }

  //
  // Raven utils
  //

  static clearUserContext() {
    // TODO: do we really want to clear the user data here?
    _userData = null

    if (Logger.isSetup()) {
      // https://docs.sentry.io/platforms/javascript/#unsetting-context
      Sentry.configureScope(scope => scope.clear())
    }
  }

  static lastEventId() {
    if (Logger.isSetup()) {
      return Sentry.lastEventId()
    }
    return null
  }

  //
  // utils
  //

  /**
   * Get a strack trice as an object.
   * @type {[type]}
   */
  static getStackTrace = () => {
    const obj = {}

    if (Error.captureStackTrace) {
      Error.captureStackTrace(obj, Logger.getStackTrace)
      return obj.stack
    }

    return null
  }
}
