import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { tr } from 'pmt-modules/i18n'
import GeolocationContainer from 'pmt-modules/geolocation/container/GeolocationContainer'
import { EventManager, SendEventContainer } from 'pmt-modules/event'
import { setRestaurantId } from 'pmt-modules/orderFront'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import ActionInfoOutline from 'pmt-ui/svg-icons/action/info-outline'
import RestaurantRow from './RestaurantRow'

const styles = (theme) => ({
  restaurantList: {
    position: 'relative',
    maxHeight: 'calc(100vh - 460px)',
    minHeight: 130,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'none',
    },
  },
  infoContainer: {
    maxWidth: 860,
    margin: '0 auto',
    lineHeight: '1.6em',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  warningContainer: {
    maxWidth: 380,
    margin: '0 auto',
    lineHeight: '1.6em',
    paddingBottom: theme.spacing(2),
  },
  infoText: {
    lineHeight: 1.3,
  },
  list: {
    marginRight: theme.spacing(1),
    '& div:last-child': {
      '& hr': {
        display: 'none',
      },
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginTop: 4,
    },
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  iconInfo: {
    width: 30,
    height: 30,
    marginBottom: theme.spacing(1),
  },
})

const RestaurantList = ({
  isFetching = false,
  restaurantListData,
  restaurantSelected,
  setRestaurantId,
  orderProperties,
  classes,
}) => (
  <div className={classes.restaurantList}>
    <GeolocationContainer>
      {({ geolocationAddress, geolocationComputeAddress }) => (
        <SendEventContainer
          event={EventManager.Events.ON_RESTAURANT_LIST_SUCCESS}
          eventProps={() => ({
            address: geolocationAddress,
            computeAddress: geolocationComputeAddress,
            list: restaurantListData?.restaurants || [],
          })}
        >
          <React.Fragment>
            <div className={classes.list}>
              {(restaurantListData?.restaurants || []).map((restaurant) => (
                <RestaurantRow
                  key={restaurant.id}
                  restaurant={restaurant}
                  isSelected={!isNil(restaurantSelected) && restaurantSelected.id === restaurant.id}
                  restaurantSelected={restaurantSelected}
                  onSelect={() => {
                    // already selected
                    if (!isNil(restaurantSelected) && restaurantSelected.id === restaurant.id) {
                      return
                    }
                    EventManager.dispatch(EventManager.Events.ON_STORE_LOCATOR_RESTAURANT_SELECT, {
                      restaurant: restaurant,
                      mode: orderProperties.mode,
                    })
                    setRestaurantId(restaurant.id)
                  }}
                />
              ))}
            </div>

            {/* spec: https://www.notion.so/paymytable/Am-liorer-l-affichage-des-r-sultats-du-store-locator-2669fffd951f483280655bcbb2124fc6# */}
            {restaurantListData?.geoPtIsLowAccuracy && !isEmpty(restaurantListData?.restaurants) && (
              <div className={classes.infoContainer}>
                <div className="u-textAlignCenter">
                  <ActionInfoOutline className={classNames(classes.iconInfo, classes.grey500)} />
                  <TypographyCustom
                    type="164"
                    align="center"
                    className={classNames(classes.grey500, classes.infoText)}
                  >
                    {tr('order.store_locator.restaurant.geo_pt_low_accuracy')}
                  </TypographyCustom>
                </div>
              </div>
            )}

            {isEmpty(restaurantListData?.restaurants) && !isFetching && (
              <div className="u-textAlignCenter">
                <ActionInfoOutline className={classNames(classes.iconInfo, classes.grey500)} />
                <TypographyCustom
                  type="164"
                  align="center"
                  className={classNames(
                    classes.warningContainer,
                    classes.grey500,
                    classes.infoText
                  )}
                >
                  {restaurantListData?.isTypeUseIp &&
                    restaurantListData?.addressFromRequest &&
                    tr('order.store_locator.restaurant.not_found', {
                      address: restaurantListData.addressFromRequest,
                    })}

                  {restaurantListData?.isTypeGivenGeoPt &&
                    geolocationComputeAddress &&
                    tr('order.store_locator.restaurant.not_found', {
                      address: geolocationComputeAddress.formattedAddress,
                    })}

                  {((restaurantListData?.isTypeUseIp && !restaurantListData?.addressFromRequest) ||
                    (!restaurantListData?.isTypeUseIp && !geolocationComputeAddress) ||
                    restaurantListData?.isTypeNoGeoPt) &&
                    tr('order.store_locator.restaurant.not_found.no_address')}
                </TypographyCustom>
              </div>
            )}
          </React.Fragment>
        </SendEventContainer>
      )}
    </GeolocationContainer>
  </div>
)

const mapStateToProps = (state) => ({})

export default compose(
  connect(mapStateToProps, {
    setRestaurantId,
  }),
  withStyles(styles)
)(RestaurantList)
