import { createAction } from 'pmt-modules/redux'

import CheckApi from 'pmt-modules/api/calls/CheckApi'
import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import { createRedirectToAction, getRoute } from 'pmt-modules/routing'

export const GetCheckWithTableNumberAction = createApiEnumAction(
  'PAYMENT::CHECK::GET_WITH_TABLE_NUMBER'
)

export const redirectToPayment = restaurantId =>
  createRedirectToAction(getRoute('PAYMENT_TABLE_NUMBER'), { restaurantId })

export const fetchCheckWithTableNumber = (restaurantId, tableNumber) =>
  createApiCallAction(
    GetCheckWithTableNumberAction,
    CheckApi.getCheckWithTableNumber(restaurantId, tableNumber),
    {
      restaurantId,
      tableNumber,
    }
  )

//
//
//

export const CheckAction = {
  RESET: 'CHECK::RESET',
}

export const resetCheck = () => createAction(CheckAction.RESET)

export const GetCheckWithPosCheckIdAction = createApiEnumAction(
  'PAYMENT::CHECK::GET_WITH_POS_CHECK_ID'
)

export const fetchCheckWithPosCheckId = (restaurantId, posCheckId) =>
  createApiCallAction(
    GetCheckWithPosCheckIdAction,
    CheckApi.getCheckWithPosCheckId(restaurantId, posCheckId),
    {
      restaurantId,
      posCheckId,
    }
  )

export const GetCheckAction = createApiEnumAction('PAYMENT::CHECK::GET')
export const GetRefreshCheckAction = createApiEnumAction('PAYMENT::CHECK::GET_REFRESH')

export const fetchCheck = (restaurantId, checkId) =>
  createApiCallAction(GetCheckAction, CheckApi.getCheck(restaurantId, checkId), {
    restaurantId,
    checkId,
  })

export const fetchRefreshCheck = (restaurantId, checkId, isFromCheck = false) =>
  createApiCallAction(GetRefreshCheckAction, CheckApi.getCheck(restaurantId, checkId), {
    restaurantId,
    checkId,
    isFromCheck,
  })

export const GetCheckWithCodeAction = createApiEnumAction('PAYMENT::CHECK::GET_WITH_CODE')

export const fetchCheckWithCode = (restaurantId, code) =>
  createApiCallAction(GetCheckWithCodeAction, CheckApi.getCheckWithCode(restaurantId, code), {
    restaurantId,
    code,
  })

export const MergeCheckWithCodeAction = createApiEnumAction('PAYMENT::CHECK::MERGE_WITH_CODE')

export const mergeCheckWithCode = (restaurantId, posCheckId, code) =>
  createApiCallAction(
    MergeCheckWithCodeAction,
    CheckApi.putMergeChecks(restaurantId, posCheckId, code),
    {
      restaurantId,
      posCheckId,
      code,
    }
  )

export const redirectToPay = (restaurantId, checkId) =>
  createRedirectToAction(getRoute('PAYMENT_PAY'), { restaurantId, checkId })

export const redirectToCheckTotality = (restaurantId, checkId) =>
  createRedirectToAction(getRoute('PAYMENT_PAY'), { restaurantId, checkId })

export const redirectToCheckShare = (restaurantId, checkId) =>
  createRedirectToAction(getRoute('PAYMENT_SHARE'), { restaurantId, checkId })

export const redirectToCheckChoose = (restaurantId, checkId) =>
  createRedirectToAction(getRoute('PAYMENT_CHOOSE'), { restaurantId, checkId })
