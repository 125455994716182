import React from 'react'
import PropTypes from 'prop-types'

import invariant from 'invariant'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { getQueryParam } from 'pmt-utils/url'
import { updateStatisticsOptions, initStatisticsConfig } from '../actions'
import { makeGetStatisticsOptions, makeGetStatisticsConfig } from '../selectors'
import { updatePageQueries, Query } from 'pmt-modules/routing'
import { statisticsOptionsAreEqual, getQueryParamsConfig, getPeriodDates } from '../utils'
import { QueryParamType, StatisticsPeriod } from '../constants'

/**
 * @specs N/A
 *
 * A HOC that give a restaurants group statistics
 *
 * Requirements:
 * - groupType defined in ../StatisticsGroupType
 *
 * see `withRestaurantNews`
 */
class StatisticsGroupContainer extends React.Component {
  constructor(props) {
    super(props)

    const config = this.props.defaultConfig

    const keysThatRunApiReload = this.getKeysThatRunApiReload(config)

    // build options
    // we take the default options and the options in the url
    const options = {
      ...this.props.defaultOptions,
      ...this.getUrlOptions(getQueryParamsConfig(config)),
    }

    // handle period

    const periodDates = getPeriodDates(
      options.period,
      // default dates when choosing custom, we want the current dates
      {
        fromTime: options.fromTime,
        toTime: options.toTime,
      }
    )
    options.fromTime = periodDates.fromTime
    options.toTime = periodDates.toTime

    // in some cases we can have a toTime on the url but not the fromTime (or the inverse). It
    // could lead to invalid time range so we modify it here
    if (options.fromTime.isAfter(options.toTime)) {
      options.fromTime = moment(options.toTime)
        .subtract(1, 'month')
        .startOf('day')
    }

    config.keysThatRunApiReload = keysThatRunApiReload

    this.handleInitConfigAndOptions(config, options)
  }

  componentDidUpdate(prevProps) {
    if (!statisticsOptionsAreEqual(this.props.config, prevProps.options, this.props.options)) {
      // options has changed, we want to update the url

      // when options changes, we put them on the url
      const queryParams = {}

      getQueryParamsConfig(this.props.config).forEach(queryParamConfig => {
        let value = this.props.options[queryParamConfig.name]

        switch (queryParamConfig.type) {
          case QueryParamType.DATE:
            value = value.format(queryParamConfig.dateFormat)
            break

          case queryParamConfig.ARRAY:
            value = value.join(',')
            break

          default:
          // nothing to format
        }

        if (value !== null && value.length > 0) {
          queryParams[queryParamConfig.name] = value
        } else {
          queryParams[queryParamConfig.name] = Query.REMOVE_ME
        }
      })

      // if the period defined is not CUSTOM, we do not need to set the fromTime / toTime
      if (!isEmpty(queryParams['period']) && queryParams['period'] !== StatisticsPeriod.CUSTOM) {
        queryParams.fromTime = Query.REMOVE_ME
        queryParams.toTime = Query.REMOVE_ME
      }

      this.props.updatePageQueries(queryParams)
    }
  }

  // list of the keys that must run the api reload of the stats
  // we always have a fromTime and a toTime
  getKeysThatRunApiReload(config) {
    return [
      // put fromTime and toTime as keysThatRunApiReload by default
      'fromTime',
      'toTime',
      'period',
      ...(config && config.keysThatRunApiReload ? config.keysThatRunApiReload : []),
    ]
  }

  /**
   * Returns the options from the url.
   * For now we handle only the options register on config.keysThatRunApiReload
   */
  getUrlOptions(queryParamsConfig) {
    const urlOptions = {}

    queryParamsConfig.forEach(queryParamConfig => {
      let queryParam = getQueryParam(queryParamConfig.name)
      if (queryParam) {
        // transform dates
        switch (queryParamConfig.type) {
          case QueryParamType.DATE:
            // Note: for a date, the config must add a dateFormat
            invariant(
              !isEmpty(queryParamConfig.dateFormat),
              `'dateFormat' config requied for '${queryParamConfig.name}'`
            )
            queryParam = moment(queryParam, queryParamConfig.dateFormat).startOf('day')

            // queryParamConfig accept an endOfDay bool for date QueryParamType.DATE
            if (queryParamConfig.endOfDay === true) {
              queryParam = queryParam.endOf('day')
            }
            break

          case QueryParamType.INTEGER:
            queryParam = parseInt(queryParam, 10)
            break

          case QueryParamType.ARRAY:
            // an array on url is a string where each item is seperate with a ','
            queryParam = queryParam.split(',')
            if (queryParamConfig.subType && queryParamConfig.subType === QueryParamType.INTEGER) {
              queryParam = queryParam.map(param => parseInt(param, 10))
            }
            break

          default:
          // no transformation required
        }

        urlOptions[queryParamConfig.name] = queryParam
      }
    })

    // if the period defined is not CUSTOM, we will need to regenerate the fromTime / toTime
    if (!isEmpty(urlOptions['period']) && urlOptions['period'] !== StatisticsPeriod.CUSTOM) {
      urlOptions.fromTime = null
      urlOptions.toTime = null
    }

    return urlOptions
  }

  handleInitConfigAndOptions = (config, options) => {
    // Note: we could use options as defaultOptions but options contains the url default params
    // and it would block us from reset all the filters

    const defaultOptions = {
      ...this.props.defaultOptions,
      // copy default generated data on constructo
      fromTime: options.fromTime,
      toTime: options.toTime,
      period: options.period,
    }

    this.props.initStatisticsConfig(this.props.groupType, {
      ...config,
      defaultOptions: { ...defaultOptions },
    })

    this.props.updateStatisticsOptions(this.props.groupType, {
      ...options,
    })
  }

  handleChangeOptions = options => {
    this.props.updateStatisticsOptions(this.props.groupType, options)
  }

  handleChangeDateRange = dateRange => {
    if (
      this.props.options.fromTime.valueOf() !== dateRange[0].valueOf() ||
      this.props.options.toTime.valueOf() !== dateRange[1].valueOf()
    ) {
      this.handleChangeOptions({
        ...this.props.options,
        period: StatisticsPeriod.CUSTOM,
        fromTime: dateRange[0],
        toTime: dateRange[1],
      })
    }
  }

  render() {
    const { children, config, options } = this.props

    if (!config || !options) {
      // wait for default options to be processed
      return null
    }

    return children({
      options,
      config,
      onChangeOptions: this.handleChangeOptions,
    })
  }
}

StatisticsGroupContainer.defaultProps = {}

StatisticsGroupContainer.propTypes = {
  children: PropTypes.func.isRequired,

  groupType: PropTypes.string.isRequired,

  defaultOptions: PropTypes.object,

  config: PropTypes.object,
}

const mapStateToProps = (state, props) => {
  const getOptions = makeGetStatisticsOptions()
  const getConfig = makeGetStatisticsConfig()

  return {
    options: getOptions(state, props),
    config: getConfig(state, props),
  }
}

StatisticsGroupContainer.QueryParamType = QueryParamType

export default connect(
  mapStateToProps,
  {
    initStatisticsConfig,
    updateStatisticsOptions,
    updatePageQueries,
  }
)(StatisticsGroupContainer)
