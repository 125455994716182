import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'

import { EventManager } from 'pmt-modules/event'
import { addOrderMenuToCart, addOrderProductToCart, addToCartFromShortcut } from 'pmt-modules/cart'
import { getOrderProperties } from 'pmt-modules/orderPlugin'
import { hideUpsellingDialog } from 'pmt-modules/catalog'
import { openProduct } from 'pmt-modules/orderProduct'
import { openMenu } from 'pmt-modules/orderMenu'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'

import { CardItem } from 'pmt-ui/Card'
import { DialogActions, DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import ActionDone from 'pmt-ui/svg-icons/action/done'
import ContentAdd from 'pmt-ui/svg-icons/content/add'
import Grid from 'pmt-ui/Grid'
import StateMachine from 'pmt-ui/StateMachine'
import CloseIcon from 'pmt-ui/svg-icons/navigation/close'

import Button from '../../../components/Button'
import Chip from '../../../components/Chip'
import CartModifierTag from '../../../components/CartModifier/CartModifierTag'
import CardItemOrder from '../../../components/Card/CardItemOrder'

const styles = (theme) => ({
  paper: {
    borderRadius: 28,
  },
  dialogTitle: {
    float: 'left',
    width: 'calc(100% - 40px)',
    marginTop: 50,
  },
  closeArea: {
    float: 'right',
    cursor: 'pointer',
  },
  closeBtn: {
    background: theme.pmt.colors.grey100,
    cursor: 'pointer',
    width: 34,
    height: 34,
    padding: 7,
    borderRadius: 10,
    margin: 1,
  },
  cardsCentered: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialogActions: {
    cursor: 'pointer',
    justifyContent: 'center',
    margin: `${theme.spacing(2)}px 0`,
    padding: `${theme.spacing(2)}px 0`,
    zIndex: 1,
  },
  closeAction: {
    borderColor: theme.palette.text.primary,
    color: theme.palette.text.primary,
    opacity: 0.4,
    padding: '12px 24px',
    transition: 'opacity 0.3s ease',
    '&:hover': {
      opacity: 0.2,
      borderColor: theme.palette.text.primary,
    },
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cartModifierChip: {
    marginBottom: theme.spacing(0.5),
    cursor: 'pointer',
    borderRadius: 6,
    padding: '5px 8px',
    fontSize: 12,
    lineHeight: '14px',
    height: 'auto',
    textTransform: 'capitalize',
  },
  cartModifierUnavailable: {
    opacity: 0.2,
  },
  buttonAdd: {
    borderRadius: 10,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.pmt.colors.white,
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      borderColor: theme.palette.primary.dark,
    },
  },
})

const State = {
  NORMAL: 'NORMAL',
  ADDING_TO_CART: 'ADDING_TO_CART',
}

/**
 * @specs N/A
 */
@withRestaurant
class UpsellingDialog extends React.Component {
  handleOpenItem = (item, options) => {
    const { hideUpsellingDialog, openMenu, openProduct } = this.props

    hideUpsellingDialog()

    if (item.isProduct) {
      openProduct(item, options)
    } else if (item.isMenu) {
      openMenu(item, options)
    }
  }

  hideUpsellingDialog = () => {
    this.props.hideUpsellingDialog()
  }

  render() {
    const {
      classes,
      addToCartFromShortcut,
      orderProperties,
      restaurant,
      upsellingCategory,
    } = this.props

    return (
      <React.Fragment>
        <DialogTitle disableTypography>
          <TypographyCustom type="226" skipColor className={classes.dialogTitle}>
            {upsellingCategory.description}
          </TypographyCustom>
          <div className={classes.closeArea} onClick={this.hideUpsellingDialog}>
            <CloseIcon className={classes.closeBtn} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid spacing={2} container className={classes.container}>
            {upsellingCategory.items.map(
              (item) =>
                item.available && (
                  <Grid
                    key={`item-${item.id}`}
                    item
                    xs={12}
                    md={
                      upsellingCategory.items.length === 1
                        ? 12
                        : upsellingCategory.items.length === 2
                        ? 6
                        : 4
                    }
                    className={classes.cardsCentered}
                  >
                    <StateMachine
                      states={State}
                      default={State.NORMAL}
                      transitions={[
                        {
                          state: State.ADDING_TO_CART,
                          to: State.NORMAL,
                          duration: 1000,
                        },
                      ]}
                    >
                      {({ currentState, transitionTo }) => (
                        <CardItem
                          type={<CardItemOrder />}
                          // give item even if it is not used, to simplify debug on react dev tools
                          item={item}
                          description={item.description}
                          image={item.image}
                          restaurantLogo={restaurant.logo}
                          name={item.name}
                          price={item.priceFormatted}
                          isAvailable={item.available}
                          isCategory={item.isCategory}
                          hasCartModifier={item.hasCartModifier}
                          basePrice={item.hasCartModifier ? item.basePriceFormatted : null}
                          cartModifierTag={(cartModifier, index, hasBorder = false) => (
                            <CartModifierTag
                              cartModifier={cartModifier}
                              chipElement={
                                <Chip
                                  key={index}
                                  label={cartModifier.tag}
                                  noBorder={!hasBorder}
                                  classes={{
                                    root: classNames(classes.cartModifierChip, {
                                      [classes.cartModifierUnavailable]: !item.available,
                                    }),
                                  }}
                                />
                              }
                              key={index}
                              overEventsEnabled={false}
                            />
                          )}
                          onClickCard={() => {
                            if (item.available) {
                              EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_DETAIL, {
                                item,
                                restaurant,
                                category: {
                                  name: 'Upselling',
                                },
                              })
                              this.hideUpsellingDialog()
                              this.handleOpenItem(item, {
                                restaurantId: restaurant.id,
                              })
                            }
                          }}
                          buttonAdd={
                            <Button
                              variant="contained"
                              size="small"
                              classes={{
                                root: classes.buttonAdd,
                              }}
                              icon={currentState === State.NORMAL ? <ContentAdd /> : <ActionDone />}
                              label={tr(
                                currentState === State.NORMAL
                                  ? 'order.catalog.add'
                                  : 'order.catalog.added'
                              )}
                              onClick={(e) => {
                                e.stopPropagation()

                                if (item.available) {
                                  this.hideUpsellingDialog()

                                  if (item.isComplexItem) {
                                    this.handleOpenItem(item, {
                                      restaurantId: restaurant.id,
                                    })
                                  } else {
                                    transitionTo(State.ADDING_TO_CART)
                                    EventManager.dispatch(
                                      EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT,
                                      {
                                        dueDate: orderProperties.dueDate,
                                        item,
                                        restaurant,
                                      }
                                    )
                                    addToCartFromShortcut(item)
                                  }
                                }
                              }}
                            />
                          }
                        />
                      )}
                    </StateMachine>
                  </Grid>
                )
            )}
          </Grid>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            classes={{ root: classes.closeAction }}
            variant="outlined"
            label={tr('order.suggestions.no_thanks')}
            onClick={this.hideUpsellingDialog}
          />
        </DialogActions>
      </React.Fragment>
    )
  }
}

// Dialog root properties
UpsellingDialog.DialogRootProps = {
  // we don't want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

UpsellingDialog.DialogStyles = (theme) => ({
  paper: {
    // fix weird bug on Safari
    zIndex: 100,
    // -- for IE
    '-ms-overflow-style': '-ms-autohiding-scrollbar',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      maxHeight: '100%',
      margin: 0,
      borderRadius: 0,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 1100,
      // -- for IE
      width: '100%',
      borderRadius: 28,
    },
  },
})

const mapStateToProps = (state, props) => ({
  orderProperties: getOrderProperties(state),
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    addOrderProductToCart,
    addOrderMenuToCart,
    hideUpsellingDialog,
    addToCartFromShortcut,
    openMenu,
    openProduct,
  })
)(UpsellingDialog)
