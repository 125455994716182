import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { getOrderProperties } from 'pmt-modules/orderFront'

import { fetchCatalog } from '../../actions'
import {
  getErrorCatalog,
  isFetchingCatalog,
  getCatalogLocale,
  getCatalogFormatted,
} from '../../selectors'

/**
 * @specs N/A
 *
 * A HOC that give fetch the restaurant's news and pass it to the children
 *
 * Requirements:
 * - restaurantId
 *
 * see `withCatalog`
 */
class CatalogContainer extends React.Component {
  constructor(props) {
    super(props)

    this.loadCatalog(props)
  }

  componentWillReceiveProps(nextProps) {
    this.loadCatalog(nextProps)
  }

  shouldUpdateCatalog = ({
    catalog,
    catalogLocale,
    isFetching,
    orderFrontProperties,
    restaurantId,
    catalogError,
  }) => {
    if (isFetching || !isNil(catalogError)) {
      return false
    }

    const { mode, locale, dueDate } = orderFrontProperties

    if (!isNil(mode) && !isNil(restaurantId)) {
      if (isNil(catalog)) {
        return true
      }

      if (
        catalog.restaurantId !== restaurantId ||
        catalog.mode !== mode ||
        catalogLocale !== locale ||
        // user changed his dueDate after fetching catalog
        (!isNil(dueDate) && catalog.dueDate !== dueDate)
      ) {
        return true
      }
    }

    return false
  }

  loadCatalog(props) {
    const { mode, locale, dueDate } = props.orderFrontProperties

    if (this.shouldUpdateCatalog(props)) {
      props.fetchCatalog(props.restaurantId, dueDate, mode, {
        locale,
      })
    }
  }

  render() {
    const { isFetching, catalog, catalogError, CatalogWrappedComponent, ...otherProps } = this.props

    return (
      <CatalogWrappedComponent
        isFetchingCatalog={isFetching}
        catalog={catalog}
        catalogError={catalogError}
        {...otherProps}
      />
    )
  }
}

CatalogContainer.propTypes = {
  fetchCatalog: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  catalog: PropTypes.object,
  catalogError: PropTypes.object,
}

const mapStateToProps = state => ({
  catalog: getCatalogFormatted(state),
  catalogError: getErrorCatalog(state),
  catalogLocale: getCatalogLocale(state),
  isFetching: isFetchingCatalog(state),
  orderFrontProperties: getOrderProperties(state),
})

export default connect(
  mapStateToProps,
  {
    fetchCatalog,
  }
)(CatalogContainer)
