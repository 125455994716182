import { createAction } from 'pmt-modules/redux'

export const UiAction = {
  UPDATE: 'UI::UPDATE',
}

export const updateUI = (key, data) =>
  createAction(UiAction.UPDATE, {
    key,
    data,
  })
