import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Grid from 'pmt-ui/Grid'
import Hidden from 'pmt-ui/Hidden'
import InfoOutlineIcon from 'pmt-ui/svg-icons/action/info-outline'
// import WheatIcon from 'pmt-ui/svg-icons/food/wheat'
import Price from 'pmt-ui/Price'
import PlaceholderLogo from 'pmt-ui/svg-icons/maps/restaurant'
import Add from 'pmt-ui/svg-icons/content/add'

import Button /*, { ButtonLink } */ from '../../../components/Button'
import TipWithIcon from '../../../components/TipWithIcon'
import Chip from '../../../components/Chip'
import CartModifierTag from '../../../components/CartModifier/CartModifierTag'

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(4)}px`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
  },
  imageContainer: {
    position: 'relative',
    width: 280,
    aspectRatio: 1.75,
    borderRadius: theme.shape.border.radius.secondaryContent,
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      maxWidth: 348,
      width: 'calc(100% - 30px)',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  placeHolderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.pmt.colors.itemPlaceholderBackground,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.pmt.colors.itemPlaceholderBackgroundSecondary,
    },
  },
  mediaRestaurantLogo: {
    height: '120px',
    opacity: 0.3,
  },
  mediaPlaceholderLogo: {
    height: '80px',
    width: '100%',
    opacity: 0.3,
  },
  menuChip: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    padding: '5px 8px',
    borderRadius: 6,
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 600,
    backgroundColor: theme.pmt.colors.white,
    textTransform: 'capitalize',
  },
  nameArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    color: theme.palette.primary.light,
    lineHeight: '1.2em',
  },
  description: {
    opacity: 0.5,
    lineHeight: '1.5em',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 30px)',
    },
  },
  allergen: {
    marginTop: theme.spacing(1),
    justifyContent: 'flex-start',
    '& svg': {
      marginRight: `5px !important`,
      opacity: 0.5,
    },
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  infoArea: {
    [theme.breakpoints.up('md')]: {
      // align price to bottom
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  actionsArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  addToCartTip: {
    color: theme.palette.primary.light,
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(2),
      textAlign: 'right',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      maxWidth: 320,
      float: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      paddingBottom: theme.spacing(1),
    },
  },
  addToCartTipIcon: {
    marginTop: -14,
  },
  addToCartTipText: {},
  priceContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  price: {
    float: 'left',
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  baseValue: {
    textDecoration: 'line-through',
  },
  hr: {
    display: 'none',
  },
  cartModifierContainer: {
    marginBottom: 5,
  },
  cartModifierChip: {
    marginRight: theme.spacing(0.5),
    cursor: 'pointer',
    borderRadius: 6,
    padding: '5px 8px',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '14px',
    height: 'auto',
    display: 'inline',
    float: 'none',
    textTransform: 'capitalize',
  },
  tipWithIcon: {
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(2),
      maxWidth: 320,
      float: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
      textAlign: 'left',
    },
  },
  submitBtn: {
    marginBottom: theme.spacing(1),
  },
  mobileAdd: {
    // same as Product#Header
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      minWidth: theme.spacing(5),
      minHeight: theme.spacing(5),
    },
  },
})

const HeaderView = ({
  appConfigPreset,
  orderMenu,
  restaurantLogo,
  isEditMode,
  classes,
  onSubmit,
  showDietaryInfoDialog,
}) => {
  return (
    <Grid spacing={2} container className={classes.root}>
      {/** PICTURE AREA
       * on desktop we always display this Grid item
       * on mobile, we display it only if there is an image, to avoid an empty block with padding
       */}
      <Hidden smDown={!orderMenu.hasImage}>
        <Grid item xs={12} md={3}>
          <div className={classes.imageContainer}>
            {orderMenu.hasImage ? (
              <div
                className={classes.backgroundImage}
                style={{ backgroundImage: `url(${orderMenu.image})` }}
              />
            ) : !isEmpty(restaurantLogo) ? (
              <div className={classes.placeHolderContainer}>
                <img
                  className={classes.mediaRestaurantLogo}
                  src={restaurantLogo}
                  alt={orderMenu.name}
                />
              </div>
            ) : (
              <div className={classes.placeHolderContainer}>
                <PlaceholderLogo className={classes.mediaPlaceholderLogo} />
              </div>
            )}
            <span className={classes.menuChip}>Menu</span>
          </div>
        </Grid>
      </Hidden>

      {/* INFO AREA */}
      <Grid item xs={12} md={5} className={classes.infoArea}>
        <div>
          <div className={classes.nameArea}>
            {/* name */}
            <TypographyCustom type="226" component="h1" className={classes.name}>
              {orderMenu.name}
            </TypographyCustom>
          </div>

          {/* description */}
          <TypographyCustom
            type="124"
            component="h2"
            className={`${classes.description} u-marginTop10`}
          >
            {orderMenu.description}
          </TypographyCustom>
          {/* orderMenu.hasAllergens && (
            <ButtonLink
              onClick={showDietaryInfoDialog}
              className={classes.allergen}
              icon={<WheatIcon />}
              color="default"
            >
              <TypographyCustom type="146">{tr('order.dietary_info.title')}</TypographyCustom>
            </ButtonLink>
          ) */}
        </div>
        {/* Price */}
        <TypographyCustom type="226" className={classes.priceContainer}>
          <Price
            value={orderMenu.priceFormatted}
            hasCartModifier={orderMenu.hasCartModifier}
            baseValue={orderMenu.hasCartModifier ? orderMenu.basePriceFormatted : null}
            classes={{ root: classes.price, baseValue: classes.baseValue, hr: classes.hr }}
          />
          {orderMenu.hasCartModifier && (
            <div className={classes.cartModifierContainer}>
              {orderMenu.cartModifiers.list.map((cartModifier, index) => (
                <CartModifierTag
                  cartModifier={cartModifier}
                  chipElement={
                    <Chip
                      key={index}
                      label={cartModifier.tag}
                      classes={{ root: classes.cartModifierChip }}
                    />
                  }
                  key={index}
                />
              ))}
            </div>
          )}
        </TypographyCustom>
      </Grid>

      {/* ACTIONS AREA */}
      <Grid item xs={12} md={4} className={classes.actionsArea}>
        <div className={classes.actionsContainer}>
          <Hidden smDown>
            <Button
              disabled={!orderMenu.isValid || !orderMenu.available}
              onClick={onSubmit}
              size="large"
              icon={!isEditMode && <Add />}
              classes={{ root: classes.submitBtn }}
              fullWidth={true}
            >
              {tr(
                !isEditMode
                  ? appConfigPreset.isDigitalMenu
                    ? 'order.catalog.add_to_cart_digital_menu'
                    : 'order.catalog.add_to_cart_with_price'
                  : 'order.catalog.update_with_price',
                {
                  price: orderMenu.totalPriceFormatted,
                }
              )}
            </Button>
          </Hidden>

          <TypographyCustom
            type="164"
            className={classNames({
              'u-invisible': orderMenu.isValid,
            })}
          >
            <TipWithIcon
              icon={<InfoOutlineIcon />}
              label={tr('order.menu.products.select_cart')}
              classes={{
                root: classes.tipWithIcon,
              }}
            />
          </TypographyCustom>
        </div>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(HeaderView)
