import React from 'react'
import compose from 'recompose/compose'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { withStyles } from 'pmt-ui/styles'
import withWidth, { isWidthDown } from 'pmt-ui/utils/withWidth'
import { DialogContent, DialogTitle, DialogActions } from 'pmt-ui/Dialog'
import Grid from 'pmt-ui/Grid'
import CloseIcon from 'pmt-ui/svg-icons/navigation/close'

import Actions from './Actions'
import Comment from './Comment'
import Header from './Header'
import OptionsView from './OptionsView'
import ReclaimLater from './ReclaimLater'

const styles = (theme) => {
  const rootStyle = {
    overflow: 'auto',
    padding: `0 ${theme.spacing(6)}px ${theme.spacing(6)}px`,

    [theme.breakpoints.down('xs')]: {
      padding: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
  }

  return {
    root: {},
    // different style when there is options
    rootWithOptions: {
      ...rootStyle,
    },
    rootWithoutOptions: {
      ...rootStyle,
    },
    closeArea: {
      float: 'right',
      cursor: 'pointer',
    },
    closeBtn: {
      background: theme.pmt.colors.grey100,
      cursor: 'pointer',
      width: 34,
      height: 34,
      padding: 7,
      borderRadius: 10,
      margin: 1,
    },
    dialogActions: {
      padding: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px`,
      justifyContent: 'center',
      zIndex: 1,
    },
    dialogActionsMobile: {
      padding: 0,
      margin: 0,
    },
    comment: {
      [theme.breakpoints.up('md')]: {
        width: '100%',
        maxWidth: 700,
        marginRight: theme.spacing(5),
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 20px)',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    actionsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    actions: {
      width: 'auto',
      marginTop: theme.spacing(5),
    },
  }
}

const View = ({
  classes,
  appConfigPreset,
  orderAppConfig,
  fromDialog = true,
  orderProduct,
  isMenuMode,
  isEditMode,
  onSelectOptionValue,
  onUnselectOptionValue,
  onSubmit,
  onClose,
  restaurant,
  setReclaimLater,
  showDietaryInfoDialog,
  width,
}) => {
  const isMobile = isWidthDown('sm', width)

  return (
    <React.Fragment className={classes.root}>
      {fromDialog && (
        <DialogTitle>
          {/* close area */}
          <Grid spacing={0} container>
            <Grid item xs={12} className="u-overflowHidden u-padding0">
              <div className={classes.closeArea} onClick={onClose}>
                <CloseIcon className={classes.closeBtn} />
              </div>
            </Grid>
          </Grid>
        </DialogTitle>
      )}
      <DialogContent
        classes={{
          root: classNames(
            {
              [classes.rootWithOptions]: orderProduct.hasOptions,
              [classes.rootWithoutOptions]: !orderProduct.hasOptions,
            },
            classes.root
          ),
        }}
      >
        <React.Fragment>
          <Header
            appConfigPreset={appConfigPreset}
            orderProduct={orderProduct}
            isMenuMode={isMenuMode}
            isEditMode={isEditMode}
            isMobile={isMobile}
            onSubmit={onSubmit}
            onClose={onClose}
            restaurantLogo={!isNil(restaurant) ? restaurant.logo : null}
            showDietaryInfoDialog={showDietaryInfoDialog}
            fromDialog={fromDialog}
          />
          <OptionsView
            orderProduct={orderProduct}
            onSelectOptionValue={onSelectOptionValue}
            onUnselectOptionValue={onUnselectOptionValue}
          />
          <ReclaimLater orderProduct={orderProduct} setReclaimLater={setReclaimLater} />
          <div className={classes.actionsContainer}>
            <Comment
              orderAppConfig={orderAppConfig}
              comment={orderProduct.comment}
              classes={{ root: classes.comment }}
            />
            {!isMobile && (
              <Actions
                appConfigPreset={appConfigPreset}
                fromDialog={fromDialog}
                orderProduct={orderProduct}
                isMenuMode={isMenuMode}
                isEditMode={isEditMode}
                isMobile={isMobile}
                onSubmit={onSubmit}
                onClose={onClose}
                classes={{ root: classes.actions }}
              />
            )}
          </div>
        </React.Fragment>
      </DialogContent>
      {isMobile && (
        <DialogActions
          classes={{
            root: classNames(classes.dialogActions, {
              [classes.dialogActionsMobile]: fromDialog && isMobile,
            }),
          }}
        >
          <Actions
            appConfigPreset={appConfigPreset}
            fromDialog={fromDialog}
            orderProduct={orderProduct}
            isMenuMode={isMenuMode}
            isEditMode={isEditMode}
            isMobile={isMobile}
            onSubmit={onSubmit}
            onClose={onClose}
          />
        </DialogActions>
      )}
    </React.Fragment>
  )
}

export default compose(withWidth(), withStyles(styles))(View)
