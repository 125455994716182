import Route from '../model/Route'

/**
 * Enum that contains:
 * - our global routes: dev routes and generice routes.
 * - our projects routes: TODO: refactor. Those routes should be put on app/config/routingEnum
 *
 * The `handler` component for each route is define on `app/config/routes` (NEVER define an handler
 * here)
 */
const SdkRoutingEnum = {
  //
  // Generic
  //

  HOME: new Route('HOME', '/', true),

  LOGIN: new Route('LOGIN', '/login', false),

  LOGOUT: new Route('LOGOUT', '/logout', false),

  REGISTER: new Route('REGISTER', '/register', false),

  FORGOT_PASSWORD: new Route('FORGOT_PASSWORD', '/forgot-password', false),

  // TODO: to implement
  RELOAD: new Route('USER::RELOAD', '/reload'),

  USER_PROFILE: new Route('USER::PROFILE', '/users/:userId/profile'),

  // generic 404 page
  // https://stackoverflow.com/questions/32128978/react-router-no-not-found-route
  PAGE_NOT_FOUND: new Route('PAGE_NOT_FOUND', '*'),

  // fake page, used to display a loading page while loading the real page chunk
  PAGE_LOADING: new Route('PAGE_LOADING', '/page-loading'),

  //
  // development pages
  // Those pages are defined on pmt-modules/dev/pages and are available only in development.
  //

  DEV_THEME: new Route('DEV::THEME', '/dev/theme'),

  DEV_STYLEGUIDE: new Route('DEV::STYLEGUIDE', '/dev/styleguide'),

  DEV_TEST: new Route('DEV::TEST', '/dev/test'),
}

export default SdkRoutingEnum
