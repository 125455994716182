import { createAction } from '../redux'

export const DataCatcherAction = {
  POST_CATCHED_DATA: 'DATA_CATCHER::POST_CATCHED_DATA',
}

/**
 *
 * @param  {DataCatcherType} dataCatcherType
 * @param  {any} value  catched data value
 */
export const postCatchedData = (dataCatcherType, value) => createAction(
  DataCatcherAction.POST_CATCHED_DATA,
  {
    dataCatcherType,
    value,
  }
)
