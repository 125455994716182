import createForm from 'pmt-modules/form/createForm'
import { notEmpty } from 'pmt-modules/form/validation'
import FormType from 'pmt-modules/form/FormType'

// in DEV environment, prefill form with the following data
const devData = {}

const defaultData = {
  street: null,
  postCode: null,
  city: null,
  country: null,
  complement: null,
  name: null,
}

// TODO: I18n
const validationRules = {
  street: [[notEmpty, 'global.addUserAddress.validation.street']],
  postCode: [[notEmpty, 'global.addUserAddress.validation.postCode']],
  city: [[notEmpty, 'global.addUserAddress.validation.city']],
  country: [[notEmpty, 'global.addUserAddress.validation.country']],
  name: [[notEmpty, 'global.addUserAddress.validation.name']],
}

export default createForm(FormType.ADD_USER_ADDRESS, validationRules, defaultData, devData, {})
