import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getOrderProduct } from '../selectors'

/**
 * @specs N/A
 *
 * A HOC that returns the selected Product from store
 *
 * Requirements:
 * - catalog
 *
 */
class SelectedProductContainer extends React.PureComponent {
  render() {
    const { children, ...otherProps } = this.props

    return children({
      ...otherProps,
    })
  }
}

SelectedProductContainer.propTypes = {
  catalog: PropTypes.object.isRequired,

  // children MUST be a function
  children: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => {
  return {
    orderProduct: getOrderProduct(state),
  }
}

export default connect(mapStateToProps, {})(SelectedProductContainer)
