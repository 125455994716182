import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Crab = props => (
  <SvgIcon {...props}>
    <path
      d="M14.291,11.768v-.887a2.433,2.433,0,0,0,.619-3.89L13.157,5.238a2.433,2.433,0,0,0-3.44,3.44l1.753,1.752a2.416,2.416,0,0,0,1.72.712c.045,0,.09,0,.135,0v.021l-1.925.927a4.242,4.242,0,0,0-7.178,0L2.3,11.16V11.14c.045,0,.09,0,.135,0h0a2.416,2.416,0,0,0,1.72-.712L5.905,8.679a2.433,2.433,0,0,0-3.44-3.44L.712,6.991a2.434,2.434,0,0,0,.619,3.89v.887l2.47,1.19a4.211,4.211,0,0,0-.208.911H1.155v.966H3.594a4.253,4.253,0,0,0,.207.912L1.6,16.8l.419.871,2.2-1.06.514.814L3.283,19.254l.755.6,1.524-1.911h4.5l1.524,1.911.755-.6L10.884,17.43l.514-.814,2.2,1.059.419-.871-2.2-1.058a4.254,4.254,0,0,0,.208-.912h2.439v-.966H12.029a4.211,4.211,0,0,0-.208-.911ZM10.4,8a1.468,1.468,0,0,1-.284-1.674l2.39,2.39-.7.7Zm2.152,2.037,1.321-1.321L10.8,5.638a1.468,1.468,0,0,1,1.674.284l1.753,1.752a1.467,1.467,0,0,1-1.674,2.358ZM5.222,8,3.811,9.406l-.7-.7,2.39-2.39A1.468,1.468,0,0,1,5.222,8ZM1.4,7.674,3.148,5.922a1.468,1.468,0,0,1,1.674-.284L1.749,8.711,3.07,10.032a1.46,1.46,0,0,1-.637.145h0A1.466,1.466,0,0,1,1.4,7.674Zm6.415,3.4a3.28,3.28,0,0,1,2.849,4.9l-.634,1H5.6l-.634-1a3.28,3.28,0,0,1,2.849-4.9Z"
      transform="translate(4 0)"
    />
  </SvgIcon>
)
Crab = pure(Crab)
Crab.displayName = 'Crab'
Crab.muiName = 'SvgIcon'

export default Crab
