import { createAction } from '../redux'
import { createApiCallAction, createApiEnumAction } from '../api/utils'

import { generateUUID } from 'pmt-utils/uuid'
import { getStatisticsApiCallFunc } from './config'

export const GetStatisticsAction = createApiEnumAction('ORDER_STATS::GET')

export const getStatistics = (statisticsGroupType, statisticsType, props) =>
  createApiCallAction(
    GetStatisticsAction,
    getStatisticsApiCallFunc(statisticsGroupType, statisticsType)(props),
    {
      statisticsGroupType,
      statisticsType,
      props,
      requestId: generateUUID(),
    }
  )

export const StatisticsAction = {
  UPDATE: 'STATISTICS::UPDATE',
  INIT_CONFIG: 'STATISTICS::INIT_CONFIG',
}

export const updateStatisticsOptions = (statisticsGroupType, options) =>
  createAction(StatisticsAction.UPDATE, {
    statisticsGroupType,
    options,
  })

export const initStatisticsConfig = (statisticsGroupType, config) =>
  createAction(StatisticsAction.INIT_CONFIG, {
    statisticsGroupType,
    config,
  })
