import { EventManager } from 'pmt-modules/event'

EventManager.registerEvents({
  //
  // Dispatch the app config for the current api consumer
  //
  // Data:
  // - appConfig: the app config for the current api consumer with default values set on it
  //
  // Note: we could send the appConfig without the default (the one given by the API)
  // (see appConfig/middlewares#getAppConfigSuccess)
  //
  ON_GET_APP_CONFIG_SUCCESS: 'APP_CONFIG::GET_APP_CONFIG::SUCCESS',
})
