import { createApiCallAction, createApiEnumAction } from '../api/utils'

import UserAddressApi from '../api/calls/UserAddressApi'

export const FetchUserAddressListAction = createApiEnumAction('USER::USER_ADDRESS::GET')

export const fetchUserAddressList = userId =>
  createApiCallAction(FetchUserAddressListAction, UserAddressApi.getList(userId), {
    userId,
  })

export const PostUserAddressAction = createApiEnumAction('USER::USER_ADDRESS::POST')

export const postUserAddress = (userId, address, { restaurant = null }) =>
  createApiCallAction(PostUserAddressAction, UserAddressApi.post(userId, address), {
    userId,
    address,
    restaurant,
  })

export const EditUserAddressAction = createApiEnumAction('USER::USER_ADDRESS::EDIT')

export const editUserAddress = (userId, addressId, address) =>
  createApiCallAction(EditUserAddressAction, UserAddressApi.edit(userId, addressId, address), {
    userId,
    addressId,
    address,
  })

export const DeleteUserAddressAction = createApiEnumAction('USER::USER_ADDRESS::DELETE')

export const deleteUserAddress = (userId, addressId) =>
  createApiCallAction(DeleteUserAddressAction, UserAddressApi.delete(userId, addressId), {
    userId,
    addressId,
  })
