import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'

import { getCategoryFromCatalog, getProductFromCatalog } from 'pmt-modules/catalog/utils'

import { findOnArray } from 'pmt-utils/array'

import { makeGetSelectedProduct, getOrderProduct } from '../selectors'
import { openProduct } from '../actions'

/**
 * @specs N/A
 *
 * A HOC that returns the selected Product from store
 *
 * Requirements:
 * - catalog
 *
 */
class SelectedProductContainerId extends React.PureComponent {
  constructor(props) {
    super(props)

    if (!isNil(props.catalog)) {
      this.openProductFromProps(props)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.catalog, nextProps.catalog) && !isNil(nextProps.catalog)) {
      this.openProductFromProps(nextProps)
    }
  }

  openProductFromProps = props => {
    let product = null

    if (isNil(props.selectedProduct)) {
      if (!isNil(props.selectedCategory)) {
        const category = getCategoryFromCatalog(props.catalog, props.selectedCategory.id)
        if (category) {
          product = findOnArray(category.items, product => product.id === this.props.productId)
        } else {
          return
        }
        // no category selected, we need to find the product from each category
      } else {
        product = getProductFromCatalog(props.catalog, this.props.productId)
      }

      if (isNil(product)) {
        // redirectTo somewhere when product not found
        return
      }

      props.openProduct(product, {
        restaurantId: props.catalog.restaurantId,
        showDialog: null,
      })
    }
  }

  render() {
    const { children, ...otherProps } = this.props

    return children({
      // does not really tell if we are loading the product (handled by withCatalog)
      // could be a 404
      isLoadingProduct: isNil(otherProps.selectedProduct),
      ...otherProps,
    })
  }
}

SelectedProductContainerId.propTypes = {
  catalog: PropTypes.object,

  // children MUST be a function
  children: PropTypes.func.isRequired,
}

const makeMapStateToProps = () => {
  const getSelectedProduct = makeGetSelectedProduct()

  const mapStateToProps = (state, props) => {
    return {
      selectedProduct: getSelectedProduct(state, props),
      orderProduct: getOrderProduct(state),
    }
  }
  return mapStateToProps
}

export default connect(makeMapStateToProps, {
  openProduct,
})(SelectedProductContainerId)
