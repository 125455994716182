import kebabCase from 'lodash/kebabCase'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { orderProductsFormatter } from 'pmt-modules/orderProduct/format'

import { createFormatter, createListFormatter } from 'pmt-utils/format'

import { getPartSelectedProducts } from '../utils'

// generate and anchor to be used with links
// Used to scroll to the part when selecting the part from a menu
const formatAnchor = part => {
  part.anchor = kebabCase(part.name)

  return part
}

const formatSelectedItemsString = part => {
  const itemsNames = []

  part.selectedProducts.forEach(item => {
    itemsNames.push(item.name)
  })
  part.selectedItemsString = itemsNames.join(', ')

  return part
}

// add all selected products to the part from the part and all the part under
const recursiveOrderProductsFormatter = (partCategory, props) => {
  if (!isEmpty(partCategory.categories)) {
    partCategory.categories = partCategory.categories.map(category => {
      return recursiveOrderProductsFormatter(category, props)
    })
  }

  if (!isNil(partCategory.products)) {
    partCategory.products = orderProductsFormatter(partCategory.products, props)
  }
  partCategory.selectedProducts = getPartSelectedProducts(partCategory)

  return partCategory
}

export const formatOrderMenuPart = createFormatter(
  recursiveOrderProductsFormatter,
  formatAnchor,
  formatSelectedItemsString
)

export const formatOrderMenuParts = createListFormatter(formatOrderMenuPart)
