import React from 'react'
import PropTypes from 'prop-types'
import isNull from 'lodash/isNull'
import { connect } from 'react-redux'

import { getQueryParam } from 'pmt-utils/url'

import { fetchUserMe } from '../../actions'
import { getUserMe, isFetchingUserMe } from '../../selectors'

import { getAuth, isFetchingAuth } from '../../../auth'

/**
 * @specs N/A
 *
 * A HOC that fetch the current logged in user and pass it to the children.
 *
 * Requirements:
 * -
 *
 * see `withUserMe`
 *
 */
class UserMeContainer extends React.Component {
  constructor(props) {
    super(props)

    if (isNull(props.me)) {
      this.loadUserMe(props)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (nextProps.userId !== props.userId || (props.isFetchingAuth && !nextProps.isFetchingAuth)) {
      this.loadUserMe(nextProps)
    }
  }

  loadUserMe(props) {
    if (
      !props.isFetchingMe &&
      !props.isFetchingAuth &&
      (props.auth != null || getQueryParam('access_token'))
    ) {
      props.fetchUserMe()
    }
  }

  render() {
    const {
      UserMeWrappedComponent,
      children,
      isFetchingMe,
      isFetchingAuth,
      me,
      ...other
    } = this.props

    if (UserMeWrappedComponent) {
      return (
        <UserMeWrappedComponent
          UserMeWrappedComponent={null}
          userId={'me'}
          isFetchingMe={isFetchingMe || isFetchingAuth}
          user={me}
          me={me}
          {...other}
        />
      )
    }

    return children({
      userId: 'me',
      isFetchingMe: isFetchingMe || isFetchingAuth,
      me,
      user: me,
    })
  }
}

UserMeContainer.propTypes = {
  fetchUserMe: PropTypes.func.isRequired,
  isFetchingMe: PropTypes.bool,
  isFetchingAuth: PropTypes.bool,
  me: PropTypes.object,
}

const mapStateToProps = (state, props) => {
  return {
    me: getUserMe(state, props),
    isFetchingMe: isFetchingUserMe(state, props),
    isFetchingAuth: isFetchingAuth(state),
    auth: getAuth(state),
  }
}

export default connect(
  mapStateToProps,
  {
    fetchUserMe,
  }
)(UserMeContainer)
