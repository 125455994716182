import indexOf from 'lodash/indexOf'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'

const CreditCardType = {
  AMEX: 3,
  VISA: 4,
  MASTER_CARD: 5,
}

/**
 * Checks if the given string represents a number that passes the Luhn Checksum
 * https://gist.github.com/ShirtlessKirk/2134376
 * @param number
 * @return true if the number does pass the checksum, else false
 */
export const isCardNumberValid = number => {
  number = String(number)
  // clean before doing anything
  number = number.replace(/[ ]+/g, '')

  let len = number.length,
    mul = 0,
    prodArr = [[0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]],
    sum = 0

  while (len--) {
    sum += prodArr[mul][parseInt(number.charAt(len), 10)]
    mul ^= 1
  }

  return sum % 10 === 0 && sum > 0
}

export const isCardSupported = cardNumber =>
  indexOf(values(CreditCardType), parseInt(String(cardNumber).charAt(0), 10)) > -1

export const isCardSecurityCodeValid = securityCode =>
  parseInt(securityCode, 10) >= 0 &&
  parseInt(securityCode, 10) <= 999 &&
  securityCode.toString().length === 3

export const isCardNameValid = () => (obj, data) => !data.saveForLater || !isEmpty(obj)
