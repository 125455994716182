import includes from 'lodash/includes'
import replace from 'lodash/replace'

import { Currencies } from 'pmt-utils/currency'

import ApiEndpoints from '../config/ApiEndpoints'
import MangoPayErrors from '../errors/MangoPayErrors'
import UserCardErrors from '../errors/UserCardErrors'
import { createUserLightBasic } from '../utils'

const getCleanNumber = cardNumber => replace(cardNumber, /\s/g, '')

const UserCardApi = {
  getUserCards: (userId, storeId) => ({
    endpoint: ApiEndpoints.USER_CARDS,
    type: 'GET',
    params: {
      userId,
    },
    query: storeId ? { storeId } : {}
  }),

  postMeUserCardRegistrationType: (userId, restaurantId, data, options = {}, authorization) => ({
    type: 'POST',
    body: {
      restaurantId,
      urlSuccess: options.urlSuccess || null,
      urlFailure: options.urlFailure || null,
      urlCancel: options.urlCancel || null,
    },
    headers: authorization ? { authorization } : {},
    endpoint: ApiEndpoints.ME_CARD_REGISTRATION_TYPE,
    errorHandler: UserCardErrors.POST_REGISTRATION_TYPE,
  }),

  /**
   * Send datas to PSP
   * so it so it creates it on its side
   */
  sendCreditCardToPsp: ({ url, method, body }, authorization = null) => ({
    type: method,
    isExtern: true,
    isForm: true,
    body,
    headers: authorization
      ? { authorization, 'Content-Type': 'plain/text' }
      : { 'Content-Type': 'plain/text' },
    url,
    errorHandler: UserCardErrors.SEND_TO_PSP,
  }),

  /**
   * Send datas received from PSP to the API
   * so it can link the created credit card from PSP to the one in the API
   */
  postPspDatas: (restaurantId, pspRegistrationId, card, data, authorization = null) => ({
    endpoint: ApiEndpoints.ME_CARD_V2,
    type: 'POST',
    headers: authorization ? { authorization } : {},
    body: {
      currency: card ? card.currency : Currencies.EUR,
      name: card ? card.name : '',
      saveForLater: card ? card.saveForLater : true,
      restaurantId,
      pspRegistrationId,
      data,
    },
    errorHandler: UserCardErrors.POST_PSP,
  }),

  postUserLightUserCard: (card, user) =>
    UserCardApi.postMeUserCard(
      {
        name: user.email,
        currency: Currencies.EUR,
        ...card,
      },
      createUserLightBasic(user.email)
    ),

  deleteMeUserCard: (userId, cardId) => ({
    type: 'DELETE',
    params: {
      userId,
      cardId,
    },
    endpoint: ApiEndpoints.USER_CARDS_DELETE,
  }),

  //
  // ------- DEPRECATED FROM HERE -------
  // these are former v1 API registration card
  // still used for user light payment
  //

  /**
   * last call to be made
   * authorization headers set from postMeUserCard request
   */
  finalizeCard: (registrationData, registrationId, card, authorization) => ({
    endpoint: ApiEndpoints.ME_CARD,
    type: 'POST',
    headers: authorization ? { authorization } : {},
    body: {
      registrationId,
      registrationData,
      saveForLater: card.saveForLater,
    },
    errorHandler: UserCardErrors.POST,
  }),

  /**
   * we send the registration to mango pay directly,
   * this does not go through our API
   */
  mangoPayRegistration: (
    url,
    accessKeyRef,
    registrationId,
    registrationData,
    card,
    authorization
  ) => ({
    url: url,
    type: 'POST',
    isExtern: true,
    isForm: true,
    body: {
      cardNumber: getCleanNumber(card.number),

      /**
       * should be formated as MMYY where MM are the 2 digits of the month (from 01 to 12) and YY are the 2 last digits of the year.
       */
      cardExpirationDate: `${
        card.expirationDateMonth < 10 ? '0' + card.expirationDateMonth : card.expirationDateMonth
      }${String(card.expirationDateYear).substring(String(card.expirationDateYear).length - 2)}`,

      cardCvx: card.cvv,

      data: registrationData,

      accessKeyRef: accessKeyRef,
    },
    headers: {
      // Accept: 'plain/text',
    },
    then: (previousOptions, text) => {
      //
      // The mangopay api returns a 201 but with an errorCode inside..
      //
      if (includes(text, 'errorCode=')) {
        // get errorCode int
        const errorCode = replace(text, 'errorCode=', '')

        const apiError = {
          code: errorCode.trim(),
          message: 'MangoPay error',
          localizedMessage: MangoPayErrors.registerCreditCard(errorCode),
          response: {
            ok: false,
            status: 400,
          },
        }
        previousOptions.failure(apiError)
        return null // stop the chain of requests
      }

      return UserCardApi.finalizeCard(text, registrationId, card, authorization)
    },
  }),

  /**
   * set authorization headers only if specified (this is used in user light case)
   * otherwise this will be set in pmt-modules/api/middlewares/requestConfigurationMiddleware.js
   */
  postMeUserCard: (card, authorization = null) => ({
    type: 'POST',
    body: {
      currency: card.currency,
      name: card.name,
      saveForLater: card.saveForLater,
    },
    headers: authorization ? { authorization } : {},
    endpoint: ApiEndpoints.ME_CARD_REGISTRATION,
    errorHandler: UserCardErrors.POST,
    then: (previousOptions, json) =>
      UserCardApi.mangoPayRegistration(
        json.url,
        json.accessKeyRef,
        json.id,
        json.data,
        card,
        previousOptions.headers.authorization
      ),
  }),
}

export default UserCardApi
