import React from 'react'
import PropTypes from 'prop-types'

import isNull from 'lodash/isNull'

/**
 * Container that handle an `open` state to be toggled
 *
 */
class ToggleContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: !isNull(props.defaultOpen) ? props.defaultOpen : props.open,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.setState({
        open: nextProps.open,
      })
    }
  }

  handleOpen = () => {
    this.setState({
      open: true,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    return this.props.children({
      isOpen: this.state.open,
      open: this.handleOpen,
      close: this.handleClose,
      toggle: this.handleToggle,
    })
  }
}

ToggleContainer.defaultProps = {
  open: false,

  defaultOpen: null,
}

ToggleContainer.propTypes = {
  /**
   * Default value for the 'open'.
   * The state is updated with this prop when changed
   * Default: false
   */
  open: PropTypes.bool,

  /**
   * Default value for the 'open'.
   * The state is not updated with this prop when changed
   * Default: false
   */
  defaultOpen: PropTypes.bool,
}

export default ToggleContainer
