//
// Note: this file should never be import directly. Use a getter via getters.js
//

import find from 'lodash/find'
import merge from 'lodash/merge'
import isNil from 'lodash/isNil'

const Env = {
  COMMON: {
    VERSION: '0.0.1',

    Logger: {
      SENTRY_URL: 'https://47de7b8f07214aa4b8516ce30a81fbe5@sentry.io/481680',
      DISPLAY_REPORT_DIALOG: true,
    },

    // enable auth feature for this project
    HAS_AUTH_ENABLED: true,
    SHOULD_USE_APP_CONFIG: true,

    IS_ORDER_PLUGIN: true,

    IS_LOCAL: false,
    IS_DEV: false,
    IS_QA: false,
    IS_PREPROD: false,
    IS_PROD: false,

    Google: {
      Map: {
        API_URL: 'https://maps.googleapis.com/maps/api/js',

        API_VERSION: '3.30',

        API_KEY: 'AIzaSyCatd-vZ7uBOKTkfyLJKQ28uszfsmhdTwA',
      },
    },
  },

  LOCAL: {
    ENV: 'DEV',

    HOSTNAME: 'localhost',
    BASE_URL: /localhost/,
    API_URL: 'http://localhost:8080/rest',
    API_CONSUMER: 'Basic NTY0NDIzOTAwNjMzNDk3NjpBT29VeGs5N0JFdTV5Zw==',

    IS_LOCAL: true,
  },

  DEV: {
    ENV: 'DEV',

    HOSTNAME: 'order2.pluginweb.dev.paymytable.com',
    BASE_URL: /localhost|order2.pluginweb.dev.paymytable.com/,
    API_URL: 'https://paymytabledev.appspot.com/rest',
    API_CONSUMER: 'Basic NTY5NzczNzU4ODczNjAwMDpJaURQMFd4T3NKUWg=',
    // to use payzen payment
    // API_CONSUMER: 'Basic NTY2MTA2OTk2ODE0NjQzMjpBSVlLdllYU1l4czA',
    // to use paygreen payment,
    // API_CONSUMER: 'Basic NjIwMDU3MDg0NzE2NjQ2NDpBS1pCVV8yYWlucms',

    IS_DEV: true,
  },

  QA: {
    ENV: 'DEV',

    HOSTNAME: 'order2.pluginweb.qa1.paymytable.com',
    BASE_URL: /order2.pluginweb.qa[0-9]{1}.paymytable.com/,
    API_URL: 'https://paymytabledev.appspot.com/rest',
    API_CONSUMER: 'Basic NTY5NzczNzU4ODczNjAwMDpJaURQMFd4T3NKUWg=',
    // to use payzen payment
    // API_CONSUMER: 'Basic NTY2MTA2OTk2ODE0NjQzMjpBSVlLdllYU1l4czA',
    // to use paygreen payment,
    // API_CONSUMER: 'Basic NjIwMDU3MDg0NzE2NjQ2NDpBS1pCVV8yYWlucms',

    IS_DEV: true,
    IS_QA: true,
  },

  PQA: {
    ENV: 'PQA',

    HOSTNAME: 'order2.pluginweb.pqa.paymytable.com',
    BASE_URL: /order2.pluginweb.pqa.paymytable.com/,
    API_URL: 'https://paymytablepreprod.appspot.com/rest',
    API_CONSUMER: 'Basic NjI2NDg0NDc2ODUwOTk1MjpHODl3S3ZYRUJ6MjA',
    // to use payzen payment
    // API_CONSUMER: 'Basic NTY2MTA2OTk2ODE0NjQzMjpBSVlLdllYU1l4czA',
    // to use paygreen payment,
    // API_CONSUMER: 'Basic ',

    IS_PREPROD: true,
  },

  PREPROD: {
    ENV: 'PREPROD',

    HOSTNAME: 'order2.pluginweb.preprod.paymytable.com',
    BASE_URL: /order2.pluginweb.preprod.paymytable.com/,
    API_URL: 'https://paymytable-eu-preprod.ew.r.appspot.com/rest',
    // API_CONSUMER: 'Basic NjI2NDg0NDc2ODUwOTk1MjpHODl3S3ZYRUJ6MjA',
    API_CONSUMER: 'Basic NDgwMjEzNjgxNDY0OTM0NDpBTXNlcnFEb2FzM3g',

    IS_PREPROD: true,
  },

  OVOSODO_DIGITAL_MENU: {
    ENV: 'OVOSODO',
    HOSTNAME: 'www.digital-menu.paymytable-plugin.ovosodo.net',
    // HOSTNAME: 'digital-menu-zdp-react-81.ovosodo.dev',
    BASE_URL: /digital-menu.paymytable-plugin.ovosodo.net/,
    // BASE_URL: /digital-menu-zdp-react-81.ovosodo.dev/,

    API_URL: 'https://order-dot-paymytable-eu.ew.r.appspot.com/rest',

    API_CONSUMER: 'Basic NTA2Mjc5MjQwMzIyMjUyODpBTGFGX0hSNFRzOUs',

    IS_PREPROD: true,
  },

  OVOSODO_CLICK_COLLECT: {
    ENV: 'OVOSODO',
    HOSTNAME: 'www.click-collect.paymytable-plugin.ovosodo.net',
    // HOSTNAME: 'click-collect-zdp-react-81.ovosodo.dev',
    BASE_URL: /click-collect.paymytable-plugin.ovosodo.net/,
    // BASE_URL: /click-collect-zdp-react-81.ovosodo.dev/,

    API_URL: 'https://order-dot-paymytable-eu.ew.r.appspot.com/rest',

    API_CONSUMER: 'Basic NDUyNzQwNzg4NTQ1MTI2NDpLaFBPZzRLREdxWTg',

    IS_PREPROD: true,
  },

  OVOSODO_DELIVERY: {
    ENV: 'OVOSODO',
    HOSTNAME: 'www.delivery.paymytable-plugin.ovosodo.net',
    // HOSTNAME: 'delivery-zdp-react-81.ovosodo.dev',
    BASE_URL: /delivery.paymytable-plugin.ovosodo.net/,
    // BASE_URL: /delivery-zdp-react-81.ovosodo.dev/,

    API_URL: 'https://order-dot-paymytable-eu.ew.r.appspot.com/rest',

    API_CONSUMER: 'Basic NTU4NDk4MjI0NDU4OTU2ODpBTWdxc3hMZEt0TUU',

    IS_PREPROD: true,
  },

  OVOSODO_ORDER_AT_TABLE: {
    ENV: 'OVOSODO',
    HOSTNAME: 'www.order-at-table.paymytable-plugin.ovosodo.net',
    // HOSTNAME: 'order-at-table-zdp-react-81.ovosodo.dev',
    BASE_URL: /order-at-table.paymytable-plugin.ovosodo.net/,
    // BASE_URL: /order-at-table-zdp-react-81.ovosodo.dev/,

    API_URL: 'https://order-dot-paymytable-eu.ew.r.appspot.com/rest',

    API_CONSUMER: 'Basic NTY3OTA3NDEwNjQwODk2MDpBS0RJaFplMm1XMEM',

    IS_PREPROD: true,
  },

  OVOSODO_PAYMENT_AT_TABLE: {
    ENV: 'OVOSODO',
    HOSTNAME: 'www.payment-at-table.paymytable-plugin.ovosodo.net',
    // HOSTNAME: 'payment-at-table-zdp-react-81.ovosodo.dev',
    BASE_URL: /payment-at-table.paymytable-plugin.ovosodo.net/,
    // BASE_URL: /payment-at-table-zdp-react-81.ovosodo.dev/,

    API_URL: 'https://order-dot-paymytable-eu.ew.r.appspot.com/rest',

    API_CONSUMER: 'Basic NDY1ODQ5MDI4NzMyNTE4NDpBSjhaQVlqMzNkdlc',

    IS_PREPROD: true,
  },

  PRODUCTION: {
    ENV: 'PROD',

    HOSTNAME: 'order2.pluginweb.paymytable.com',
    BASE_URL: /order2.pluginweb.paymytable.com/,
    API_URL: 'https://order-dot-paymytable-eu.ew.r.appspot.com/rest',
    API_CONSUMER: '',

    IS_PROD: true,
  },
}

let predicate = null

const hostname = window.location.hostname

if (!isNil(window.globalEnvironmentSettings)) {
  predicate = (config) => window.globalEnvironmentSettings.env === config.ENV
} else {
  predicate = (config) => (!isNil(config.BASE_URL) ? hostname.match(config.BASE_URL) : null)
}

let currentEnv = find(Env, predicate)

// for QA, we need to set the right URL (qa1, qa2, qa3) in hostname in order to get the right fr.json/en.json files
if (currentEnv.IS_QA) {
  currentEnv.HOSTNAME = hostname
}

if (process.env.__DEV__) {
  // currentEnv = Env.DEV
  // currentEnv = Env.PREPROD
  // currentEnv = Env.OVOSODO_DIGITAL_MENU
  currentEnv = Env.OVOSODO_CLICK_COLLECT
  // currentEnv = Env.OVOSODO_DELIVERY
  // currentEnv = Env.OVOSODO_ORDER_AT_TABLE
  // currentEnv = Env.OVOSODO_PAYMENT_AT_TABLE
}

console.log('[CONFIGURATION] ', currentEnv.API_URL, currentEnv.HOSTNAME)

export default merge({}, Env.COMMON, currentEnv)
