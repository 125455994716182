import createAction from '../redux/createAction'
import OrderPreviewApi from 'pmt-modules/api/calls/OrderPreviewApi'
import {
  createApiCallAction,
  createApiEnumAction,
  createTestApiCallAction,
} from 'pmt-modules/api/utils'

import { transformCartForApi } from './utils/transformForApi/transformCartForApi'

export const PostOrderPreviewAction = {
  ...createApiEnumAction('ORDER_PREVIEW::POST'),
  RESET: 'ORDER_PREVIEW::RESET',
  SET_MODIFICATION_DATE: 'ORDER_PREVIEW::SET_MODIFICATION_DATE',
}

export const postOrderPreview = (orderPreview, catalog, options = {}) => {
  const sentOrderPreview = transformCartForApi(orderPreview)

  return createApiCallAction(
    PostOrderPreviewAction,
    OrderPreviewApi.post(sentOrderPreview, options),
    {
      currentCart: orderPreview,
      sentOrderPreview,
      catalog,
      options,
    }
  )
}

export const postOrderPreviewFixture = (orderPreview, catalog) => {
  const sentOrderPreview = transformCartForApi(orderPreview)

  return createTestApiCallAction(PostOrderPreviewAction, true, {
    data: {
      currentCart: orderPreview,
      sentOrderPreview,
      catalog,
    },
    response: sentOrderPreview,
  })
}

export const setOrderPreviewModificationDate = modificationDate =>
  createAction(PostOrderPreviewAction.SET_MODIFICATION_DATE, {
    modificationDate,
  })

export const resetOrderPreview = () => createAction(PostOrderPreviewAction.RESET)
