import ApiEndpoints from '../config/ApiEndpoints'

const TemplateApi = {
  getCustomTextsContainer: customTextsContainerId => ({
    endpoint: ApiEndpoints.CUSTOMS_TEXTS_CONTAINER,
    type: 'GET',
    params: {
      containerId: customTextsContainerId,
    },
  }),

  postCustomTextsForGroup: restaurantsGroupId => ({
    endpoint: ApiEndpoints.CUSTOMS_TEXTS,
    type: 'POST',
    query: {
      restaurantsGroupId,
    },
    body: { texts: [] },
  }),

  postCustomTextsForRestaurant: restaurantId => ({
    endpoint: ApiEndpoints.CUSTOMS_TEXTS,
    type: 'POST',
    query: {
      restaurantId,
    },
    body: { texts: [] },
  }),

  putCustomTextsContainer: customTextsContainer => ({
    endpoint: ApiEndpoints.CUSTOMS_TEXTS_CONTAINER,
    type: 'PUT',
    params: {
      containerId: customTextsContainer.id,
    },
    body: { ...customTextsContainer },
  }),

  getEmailData: (template, restaurantsGroupId) => ({
    endpoint: ApiEndpoints.TEMPLATE_EMAIL_DATA,
    type: 'POST',
    query: {
      template,
      restaurantsGroupId,
    },
    body: {}, // empty to use API templates data.
  }),
}

export default TemplateApi
