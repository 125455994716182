import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'

import { hideRestaurantDisabledDialog } from 'pmt-modules/orderPlugin'

import { makeStyles } from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import Button from 'app/components/Button'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 300,
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

const RestaurantDisabledDialog = ({ hideRestaurantDisabledDialog }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <DialogTitle>{tr('order.catalog.restaurant_disabled.dialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {tr(`order.catalog.restaurant_disabled.dialog.content`)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          label={tr('order.catalog.restaurant_disabled.dialog.close')}
          onClick={hideRestaurantDisabledDialog}
        />
      </DialogActions>
    </div>
  )
}

// Dialog root properties
RestaurantDisabledDialog.DialogRootProps = {
  // we want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, { hideRestaurantDisabledDialog })(RestaurantDisabledDialog)
