import ApiEndpoints from '../config/ApiEndpoints'
import OrderErrors from '../errors/OrderErrors'

const OrderPreviewApi = {
  post: (
    orderPreview,
    {
      applyCartModifiers = true,
      getAdmissionFeesAmount = true,
      getMaxGrantableAmount = true,
      verifyUserAccount = true,
    }
  ) => ({
    endpoint: ApiEndpoints.ORDER_PREVIEW,
    query: {
      applyCartModifiers,
      getAdmissionFeesAmount,
      getMaxGrantableAmount,
      verifyUserAccount,
    },
    type: 'POST',
    body: orderPreview,
    errorHandler: OrderErrors.POST,
  }),
}

export default OrderPreviewApi
