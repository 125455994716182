import React from 'react'

import AppConfigContainer from './AppConfigContainer'

/**
 * Add the AppConfigContainer as HOC for the given component
 */
const withAppConfig = AppConfigWrappedComponent => props => (
  <AppConfigContainer
    AppConfigWrappedComponent={AppConfigWrappedComponent}
    {...props}
  />
)

export default withAppConfig
