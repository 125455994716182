import invariant from 'invariant'
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import { generateUUID } from 'pmt-utils/uuid'

import { CatalogItemType } from 'pmt-modules/catalog/constants'
import { getCardModifierIdsFromCartModifierList } from 'pmt-modules/catalog/utils'
import { orderProductModifier } from '../modifiers'

// set initial properties to product that will be used for all treatments
// = create an orderProduct
// props:
// - isFromMenu {boolean} true if we create the order product for a menu
// - partId {string} Part id
const createOrderProductFromProduct = (product, props = {}) => {
  const { options } = product

  // spread to keep item formatted data
  const orderProduct = cloneDeep(product)

  orderProduct.isOrderProduct = true

  orderProduct.orderId = generateUUID()
  orderProduct.type = CatalogItemType.PRODUCT

  orderProduct.grantable = false
  orderProduct.modifierIds = []

  if (orderProduct.hasCartModifier && !props.isMenuMode) {
    orderProduct.modifierIds = getCardModifierIdsFromCartModifierList(
      orderProduct.cartModifiers.list
    )
  }

  orderProduct.sequence = product.defaultSequence

  // ignore, handled by POS only
  orderProduct.tax = null

  // POS only
  orderProduct.categoryId = null
  orderProduct.categoryName = null

  orderProduct.options = (options || []).map(option => {
    option.orderId = generateUUID()

    option.values = (option.values || []).map(value => {
      value.orderId = generateUUID()
      // set a quantity to 0, that means the value has not been selected
      value.quantity = 0
      value.baseAdditionalPrice = value.additionalPrice
      return value
    })

    return option
  })

  if (props.isMenuMode !== true) {
    const price = product.price

    orderProduct.basePrice = product.hasCartModifier ? product.basePrice : price
    orderProduct.price = price
    orderProduct.unitPrice = price
    orderProduct.additionalPrice = 0
    orderProduct.baseAdditionalPrice = 0
    orderProduct.totalPriceWithQuantity = orderProduct.unitPrice

    orderProduct.quantity = 1
  } else {
    // when in a menu
    const additionalPrice = product.additionalPrice || 0

    orderProduct.basePrice = additionalPrice
    orderProduct.price = additionalPrice
    orderProduct.unitPrice = additionalPrice
    orderProduct.baseAdditionalPrice = product.additionalPrice
    orderProduct.additionalPrice = additionalPrice
    orderProduct.totalPriceWithQuantity = additionalPrice

    orderProduct.quantity = 0

    invariant(!isNil(props.partId), 'product from menu needs a partId prop.')
    orderProduct.fromPart = props.partId
    invariant(!isNil(props.partCategoryId), 'product from menu needs a partCategoryId prop.')
    orderProduct.fromCategory = props.partCategoryId
  }

  // call orderProductModifier to set all the data from the default data created above
  return orderProductModifier(orderProduct)
}

/*
 * formatted product datas model in cart
  {
    orderId: generateUUID(),
    id: product.id,
    name: product.name,
    isValid: true,
    options: [
      {
        id: "option.id",
        name: "option.name",
        nbChosenValues: 1,
        hasReachedMaxQuantity: true,
        hasReachedMinQuantity: true,
        values: [
          {
            quantity: 1,
            id: "optionChild.id",
            name: "optionChild.name",
            additionalPrice: optionChild.additionalPrice,
          },
          etc...
        ],
      },
      etc...
    ],
    availabilityHours: product.availabilityHours,
    price: unitPrice,
    totalPrice: unitPrice * quantity,
    quantity: quantity,
    comment: product.comment,
    type: product.type,
    hasOptions: true,
  }
*/

export default createOrderProductFromProduct
