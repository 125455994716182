import { createSelector } from 'reselect'

//
// selectors for the currentTheme and defaultThemes reducers.
//

const getThemeOnState = (state) => state.ux.theme

const getCurrentThemeOnState = (state) => getThemeOnState(state).current

export const getCurrentTheme = createSelector(
  [ getCurrentThemeOnState ],
  (theme) => theme ? theme.toJS() : null
)
