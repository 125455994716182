import isUndefined from 'lodash/isUndefined'
import isEqual from 'lodash/isEqual'
import isEqualWith from 'lodash/isEqualWith'
import each from 'lodash/each'
import sortBy from 'lodash/sortBy'

import { findOnArray } from 'pmt-utils/array'

import areSameApiProduct from './areSameApiProduct'

// TODO: unit tests

export const areMenuModifiersEqual = (menuToCheck, menuToCompare) => {
  return isEqual(sortBy(menuToCheck.modifierIds || []), sortBy(menuToCompare.modifierIds || []))
}

// check if products contained in parts are equal
export const areMenuPartProductsEqual = (partToCheck, partToCompare) => {
  const toCheckValues = partToCheck.products
  const toCompareValues = partToCompare.products

  if (toCompareValues.length !== toCheckValues.length) {
    return false
  }

  const isEqual = isEqualWith(
    toCheckValues,
    toCompareValues,
    (productsToCheck, productsToCompare) => {
      //
      // verify each option value
      //
      let i = 0
      let length = productsToCheck.length
      while (i < length) {
        const productToCheck = productsToCheck[i]
        const productToCompare = productsToCompare[i]
        const productIsEqual = areSameApiProduct(productToCheck, productToCompare)

        if (!productIsEqual) {
          return false
        }
        ++i
      }
      return true
    }
  )

  return isEqual
}

// check if menu parts are equal
export const areMenuPartsEqual = (menuToCheck, menuToCompare) => {
  if (menuToCheck.parts.length !== menuToCompare.parts.length) {
    return false
  }

  let haveSameParts = true
  each(menuToCheck.parts, partToCheck => {
    let partFound = findOnArray(
      menuToCompare.parts,
      partToCompare => partToCheck.id === partToCompare.id
    )

    if (isUndefined(partFound)) {
      haveSameParts = false
      return false // quit loop
    }

    haveSameParts = areMenuPartProductsEqual(partToCheck, partFound)
    if (!haveSameParts) {
      return false // quit loop
    }
  })

  return haveSameParts
}

const areSameApiMenu = (menuToCheck, menuToCompare) => {
  return (
    menuToCheck.id === menuToCompare.id &&
    areMenuPartsEqual(menuToCheck, menuToCompare) &&
    areMenuModifiersEqual(menuToCheck, menuToCompare)
  )
}

export default areSameApiMenu
