import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  primaryColor: {
    fill: theme.palette.primary.main,
  },
  textColor: {
    fill: theme.palette.text.primary,
  },
})

let CartIconEmpty = props => {
  const { classes } = props

  return (
    <SvgIcon {...props} viewBox="0 0 54 43.817">
      <g transform="translate(-612 -64.5)">
        <path
          d="M40.767,43.317H11.232A2.025,2.025,0,0,1,9.3,41.9L3.573,21.678a2.026,2.026,0,0,1,1.933-2.633H46.494a2.025,2.025,0,0,1,1.932,2.633L42.7,41.9A2.024,2.024,0,0,1,40.767,43.317Z"
          transform="translate(613 64)"
          fill="#fff"
        />
        <path
          d="M40.767,44.317H11.232A3.012,3.012,0,0,1,8.346,42.2l-.008-.027L2.614,21.963a3.026,3.026,0,0,1,2.892-3.918H46.494a3.025,3.025,0,0,1,2.891,3.918L43.653,42.2A3.012,3.012,0,0,1,40.767,44.317ZM10.258,41.611a1.02,1.02,0,0,0,.974.706H40.767a1.02,1.02,0,0,0,.974-.706l5.731-20.232a1.025,1.025,0,0,0-.978-1.333H5.506a1.026,1.026,0,0,0-.979,1.333l.008.027Z"
          transform="translate(613 64)"
          className={classes.textColor}
        />
        <path
          d="M0,15.213a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414L13.506-.707a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414L.707,14.92A1,1,0,0,1,0,15.213Z"
          transform="translate(619.227 65.5)"
          className={classes.textColor}
        />
        <path
          d="M13.957,14.957a1,1,0,0,1-.707-.293L-.707.707a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0L14.664,13.25a1,1,0,0,1-.707,1.707Z"
          transform="translate(644.533 65.5)"
          className={classes.textColor}
        />
        <path
          d="M2.97,11.656a1,1,0,0,1-.963-.732L-.963.269A1,1,0,0,1-.269-.963,1,1,0,0,1,.963-.269L3.934,10.387a1,1,0,0,1-.964,1.269Z"
          transform="translate(626.848 90.854)"
          className={classes.textColor}
        />
        <path
          d="M0,11.656a1,1,0,0,1-.269-.037,1,1,0,0,1-.695-1.232L2.008-.269A1,1,0,0,1,3.24-.963,1,1,0,0,1,3.934.269L.963,10.925A1,1,0,0,1,0,11.656Z"
          transform="translate(648.223 90.854)"
          className={classes.textColor}
        />
        <path
          d="M0,11.656a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V10.656A1,1,0,0,1,0,11.656Z"
          transform="translate(639 90.854)"
          className={classes.textColor}
        />
        <path
          d="M50.141,21.739H1.858A1.859,1.859,0,0,1,0,19.88V15.374a1.859,1.859,0,0,1,1.858-1.859H50.141A1.859,1.859,0,0,1,52,15.374V19.88a1.859,1.859,0,0,1-1.859,1.859"
          transform="translate(613 64)"
          className={classes.primaryColor}
        />
        <path
          d="M50.141,22.739H1.858A2.862,2.862,0,0,1-1,19.88V15.374a2.862,2.862,0,0,1,2.858-2.859H50.141A2.862,2.862,0,0,1,53,15.374V19.88A2.862,2.862,0,0,1,50.141,22.739ZM1.858,14.515A.859.859,0,0,0,1,15.374V19.88a.859.859,0,0,0,.858.859H50.141A.86.86,0,0,0,51,19.88V15.374a.86.86,0,0,0-.859-.859Z"
          transform="translate(613 64)"
          className={classes.textColor}
        />
      </g>
    </SvgIcon>
  )
}
CartIconEmpty = pure(CartIconEmpty)
CartIconEmpty.displayName = 'CartIconEmpty'
CartIconEmpty.muiName = 'SvgIcon'

export default withStyles(styles)(CartIconEmpty)
