import React, { Component } from 'react'
import PropTypes from 'prop-types'

import each from 'lodash/each'
import cloneDeep from 'lodash/cloneDeep'
import isArray from 'lodash/isArray'
import moment from 'moment'

import { anyToDate } from 'pmt-utils/date'

// import theme
// airbnb.css
// confetti.css
// dark.css
// light.css
// material_blue.css
// material_green.css
// material_orange.css
// material_red.css

import 'flatpickr/dist/themes/airbnb.css'

import CalendarMode from './CalendarMode'

export CalendarMode from './CalendarMode'


const hooks = [
  'onOpen',
  'onMonthChange',
  'onYearChange',
  'onReady',
  'onValueUpdate',
  'onDayCreate'
]

const hooksToHandle = [
  'onChange',
  'onClose',
]

/**
 * see https://github.com/coderhaoxin/react-flatpickr/blob/master/lib/index.js
 * @type {Object}
 *
 *
 */
class Calendar extends Component {
  static propTypes = {
    defaultDate: PropTypes.string,

    /**
     * Can contains all the flatpickr options and hooks
     * - https://chmln.github.io/flatpickr/options/
     */
    options: PropTypes.object,

    //
    // https://chmln.github.io/flatpickr/events/#hooks
    //

    onChange: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onMonthChange: PropTypes.func,
    onYearChange: PropTypes.func,
    onReady: PropTypes.func,
    onValueUpdate: PropTypes.func,
    onDayCreate: PropTypes.func,

    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
      PropTypes.number
    ]),
    children: PropTypes.any
  }

  static defaultProps = {
    options: {}
  }

  componentDidMount() {
    this.onReceiveProps(this.props, false)
  }

  componentWillReceiveProps(props) {
    this.onReceiveProps(props, true)
  }

  onChange = (selectedDates, dateStr, instance) => {
    if (this.props.onChange) {
      this.notifyCalendarChangement(selectedDates, dateStr, instance, this.props.onChange)
    }
  }

  onClose = (selectedDates, dateStr, instance) => {
    if (this.props.onClose) {
      this.node.blur && this.node.blur()
      this.notifyCalendarChangement(selectedDates, dateStr, instance, this.props.onClose)
    }
  }

  notifyCalendarChangement = (selectedDates, dateStr, instance, callback) => {
    // mode can be: single, multiple, range
    // default: single
    if (this.props.options.mode && this.props.options.mode !== CalendarMode.SINGLE) {
      callback(selectedDates.map(date => moment(date)))
    } else {
      callback(moment(selectedDates[0]))
    }
  }

  onReceiveProps(props, isUpdate) {
    // we mutate the options, so we clone it first
    let options = cloneDeep(props.options)

    if (!isUpdate) {
      // add creation default options
      options = {
        ...options,
      }
    }

    // Add prop hooks to options
    each(hooks, hook => {
      if (props[hook]) {
        options[hook] = props[hook]
      }
    })

    // register the hooks we handle on this component
    each(hooksToHandle, hook => {
      options[hook] = this[hook]
    })

    //
    // Transform dates, we handle moment dates, but flatpickr handle only javascript date.
    //

    if (options.minDate) {
      options.minDate = anyToDate(props.options.minDate).toDate().startOf('day').toDate()
    }

    if (options.maxDate) {
      options.maxDate = anyToDate(props.options.maxDate).toDate().endOf('day').toDate()
    }

    if (options.defaultDate) {
      options.defaultDate = anyToDate(props.options.defaultDate).startOf('day').toDate()
    }

    if (!this.flatpickr) {
      // initialize flatpickr object
      if (process.env.FEATURE_FLATPICKR) {
        const Flatpickr = require('flatpickr')
        this.flatpickr = new Flatpickr(this.node, options)
      } else {
        throw new Error('Missing FEATURE_FLATPICKR feature flag')
      }
    } else {
      const optionsKeys = Object.getOwnPropertyNames(options)

      for (let index = optionsKeys.length - 1; index >= 0; index--) {
        const key = optionsKeys[index]
        let value = options[key]

        // Hook handlers must be set as an array
        if (hooks.indexOf(key) !== -1 && !isArray(value)) {
          value = [value]
        }

        if (hooksToHandle.indexOf(key) !== -1 && !isArray(value)) {
          value = [value]
        }

        this.flatpickr.set(key, value)
      }
    }

    if (props.hasOwnProperty('value')) {
      let values = null
      if (!isArray(props.value)) {
        values = anyToDate(props.value).toDate()
      } else if (isArray(props.value)) {
        values = props.value.map((value, index) => {
          // force end of day to the selected end day
          // Note: not seem to work, or work only on update, not for the first (defalut) value
          // displayed
          if (index === 1) {
            if (this.props.options.mode === CalendarMode.RANGE && value) {
              return anyToDate(value).endOf('day').toDate()
            }
          }

          return anyToDate(value).toDate()
        })
      }

      // setDate(date, triggerChange, dateStrFormat)#
      // Sets the current selected date(s) todate, which can be a date string, a Date, or anArray
      // of the Dates.
      // Optionally, pass true as the second argument to force any onChange events to fire. And if
      // you’re passing a date string with a format other than your dateFormat, provide a
      // dateStrFormat e.g. "m/d/Y".
      this.flatpickr.setDate(values, false)
    }

    if (props.open) {
      this.flatpickr.open()
    }

  }

  componentWillUnmount() {
    this.flatpickr.destroy()
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { open, options, defaultDate, value, children, ...props } = this.props

    // Don't pass hooks to dom node
    each(hooks, hook => {
      delete props[hook]
    })

    return options.wrap
      ? (
        <div
          {...props}
          ref={node => { this.node = node }}
        >
          {children}
        </div>
      )
      : (
        <input
          {...props}
          style={{
            visibility: 'hidden',
            width: 0,
            height: 0,
          }}
          ref={node => { this.node = node }}
        />
      )
  }
}

Calendar.Mode = CalendarMode

export default Calendar
