import { tr } from 'pmt-modules/i18n'
import isEmpty from 'lodash/isEmpty'

import { filterObject } from 'pmt-utils/object'

import RestaurantErrors from 'pmt-modules/api/errors/RestaurantErrors'

import ApiEndpoints from '../config/ApiEndpoints'

const RestaurantApi = {
  /**
   * There is no endoint to authenticate restaurant
   * so we just make a call with authorization and apiConsumer
   */
  authRestaurant: (restaurantId, authorization, apiConsumer) => {
    return {
      type: 'GET',
      params: {
        restaurantId,
      },
      headers: {
        authorization,
        'api-consumer': apiConsumer,
      },
      endpoint: ApiEndpoints.RESTAURANT,
    }
  },

  getRestaurant: (restaurantId, loadOrderSettings = true) => ({
    type: 'GET',
    params: {
      restaurantId,
    },
    query: {
      loadOrderSettings,
    },
    headers: {},
    endpoint: ApiEndpoints.RESTAURANT,
    errorHandler: RestaurantErrors.GET,
  }),

  /**
   * To be chained with a request (.after param, see HistoryApi)
   *
   * @param  {object} data             the previous api call json data. We add restaurantIds and restaurants on it
   * @param  {Array} restaurantIds    the restaurantIds to retrieve
   * @param  {Array}  [restaurants=[]] the restaurants already retrieved
   */
  getRestaurants: (data, restaurantIds, restaurants = []) => {
    const restaurantRequest = RestaurantApi.getRestaurant(restaurantIds[0])

    data.restaurantIds = restaurantIds.slice(1)
    data.restaurants = restaurants

    restaurantRequest.data = data

    restaurantRequest.then = (previousOptions, json) => {
      const data = previousOptions.data
      const restaurants = data.restaurants
      restaurants.push(json)

      if (isEmpty(data.restaurantIds)) {
        // set data to return
        // restaurantIds is empty, we recreate it
        data.restaurantIds = restaurants.map(restaurant => restaurant.id)
        data.restaurants = restaurants
        previousOptions.success(data)
        return null
      } else {
        return RestaurantApi.getRestaurants(data, data.restaurantIds, restaurants)
      }
    }

    return restaurantRequest
  },

  postRestaurant: data => {
    return {
      type: 'POST',
      body: data,
      endpoint: ApiEndpoints.RESTAURANTS,
      errorHandler: RestaurantErrors.POST,
    }
  },

  getRestaurantList: props => {
    const defaultOptions = {
      loadOrderSettings: true,
      orderByDistance: true,
      limit: 10,
    }

    let filteredQuery = filterObject(props.query, null)

    return {
      type: 'GET',
      query: {
        ...defaultOptions,
        ...filteredQuery,
        returnRestaurantsGeoPtList: true,
      },
      endpoint: ApiEndpoints.RESTAURANT_LIST,
      errorHandler: RestaurantErrors.GET_LIST,
    }
  },

  /**
   * options - it can contain orderByDistance / loadOrderSettings attributes. Default values if not set is true
   */
  getRestaurantListByGeoPt: (latitude, longitude, options) => {
    const defaultOptions = {
      orderByDistance: true,
      loadOrderSettings: true,
      limit: 10,
    }

    const props = {
      query: {
        latitude,
        longitude,
        ...defaultOptions,
        ...options,
      },
      errorHandler: RestaurantErrors.GET_LIST,
    }

    return RestaurantApi.getRestaurantList(props)
  },

  getRestaurantUserAccounts: (
    userId,
    identifier = null,
    loadEnabledUserAccountsOnly = true,
    loadUserData = true
  ) => {
    return {
      type: 'GET',
      endpoint: ApiEndpoints.RESTAURANT_USER_ACCOUNTS,
      params: {
        userId,
        identifier,
        loadEnabledUserAccountsOnly,
        loadUserData,
      },
    }
  },

  getRestaurantsByIds: props => ({
    type: 'POST',
    endpoint: '/restaurants-groups/:restaurantsGroupId/restaurants/search',
    params: props.params,
    body: props.body,
  }),

  getKycDocumentsStatus: (restaurantId, documents) => ({
    type: 'POST',
    endpoint: ApiEndpoints.RESTAURANT_DOCUMENTS_KYC,
    params: {
      restaurantId,
    },
    body: {
      documents,
    },
    errorHandler: () => {
      return {
        // NO_PSP_IN_RESTAURANT
        101: tr('global.restaurant.document.errors.NO_PSP_IN_RESTAURANT'),
        20000: tr('global.restaurant.document.errors.NO_PSP_IN_RESTAURANT'),
        // NO_ACQUIRER_IN_RESTAURANT
        20001: tr('global.restaurant.document.errors.NO_ACQUIRER_IN_RESTAURANT'),
        // EMPTY_PSP_ID_IN_RESTAURANT
        20002: tr('global.restaurant.document.errors.EMPTY_PSP_ID_IN_RESTAURANT'),
      }
    },
  }),

  getCheckup: payload => ({
    type: 'POST',
    endpoint: ApiEndpoints.RESTAURANT_CHECKUP,
    body: payload,
  }),
}

export default RestaurantApi
