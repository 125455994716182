import forgotPassword from 'pmt-modules/forgotPassword/forms/forgotPassword'
import login from 'pmt-modules/auth/forms/login'
import register from 'pmt-modules/registration/forms/register'
import userLight from 'pmt-modules/registration/forms/userLight'
import userProfile from 'pmt-modules/user/forms/profile'
import userProfileDietary from 'pmt-modules/user/forms/profile/dietary'
import addUserAddress from 'pmt-modules/userAddress/forms/add'
import editUserAddress from 'pmt-modules/userAddress/forms/edit'
import CreditCardForm from 'pmt-modules/creditCard/forms/create'

const forms = [
  forgotPassword,
  login,
  register,
  userLight,
  userProfile,
  addUserAddress,
  editUserAddress,
  CreditCardForm,
  userProfileDietary,
]

export default forms
