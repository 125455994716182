import Immutable from 'immutable'

import { PostAuthorizeAppAction } from './actions'

export * from './actions'
export * from './middlewares'
export * from './selectors'

/**
 * the data given by the API once the user authorized (or not) the app.
 */
const DEFAULT_AUTHORIZE_DATA = Immutable.fromJS({
  isFetching: false,
  data: null,
  error: null,
})

export const authorizeAppReducer = (state = DEFAULT_AUTHORIZE_DATA, action) => {
  switch (action.type) {
    case PostAuthorizeAppAction.REQUEST:
      return state.merge({
        isFetching: true,
        data: null,
      })

    case PostAuthorizeAppAction.SUCCESS:
      return state.merge({
        isFetching: false,
        data: action.response,
        error: null,
      })

    case PostAuthorizeAppAction.FAILURE:
      return state.merge({
        isFetching: false,
        data: null,
        error: action.error,
      })

    default:
      return state
  }
}
