/**
 * https://gist.github.com/auser/1d55aa3897f15d17caf21dc39b85b663#file-googleapi-js-L1-Lundefined
 */

export const GoogleApi = function(opts) {
  opts = opts || {}

  const apiKey = opts.apiKey;
  const libraries = opts.libraries || [];
  const client = opts.client;
  const URL = opts.url || 'https://maps.googleapis.com/maps/api/js';

  const googleVersion = opts.version || '3.25';

  let channel = null;
  let language = null;
  let region = null;

  const url = () => {
    let url = URL;
    let params = {
      key: apiKey,
      // callback: 'CALLBACK_NAME',
      libraries: libraries.join(','),
      client: client,
      v: googleVersion,
      channel: channel,
      language: language,
      region: region
    }

    let paramStr = Object.keys(params)
        .filter(k => !!params[k])
        .map(k => `${k}=${params[k]}`).join('&');

    const res = `${url}?${paramStr}`;

    return res;
  }

  return url();
}

export default GoogleApi;
