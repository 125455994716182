import React from 'react'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { getDefaultRestaurantId } from 'pmt-modules/authRestaurant'

import { getQueryParam } from 'pmt-utils/url'

import RestaurantContainer from './RestaurantContainer'

const getRestaurantIdOnProps = props => {
  const queryParam = getQueryParam('restaurantId')
  if (!isEmpty(queryParam)) {
    return queryParam
  }
  if (!isNil(props.routeParams) && !isEmpty(props.routeParams.restaurantId)) {
    return props.routeParams.restaurantId
  }
  if (!isNil(props.params) && !isEmpty(props.params.restaurantId)) {
    return props.params.restaurantId
  }

  return (
    getDefaultRestaurantId() ||
    get(props, 'orderProperties.restaurantId', props.restaurantId || null)
  )
}

/**
 * Add the RestaurantContainer as HOC for the given component
 * Requirements
 * - restaurantId in props or routeParams
 */
const withRestaurant = RestaurantWrappedComponent => {
  return props => (
    <RestaurantContainer
      RestaurantWrappedComponent={RestaurantWrappedComponent}
      {...props}
      // keep restaurantId at bottom to ensure overriding any eventual prop
      // this also allows changing restaurant on the fly \o/
      restaurantId={getRestaurantIdOnProps(props)}
    />
  )
}

export default withRestaurant
