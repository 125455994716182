import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { redirectTo, getRoute } from 'pmt-modules/routing'
import { FormType, withForm } from 'pmt-modules/form'
import { getOrderData, getAppConfigFrontSettings } from 'pmt-modules/orderPlugin'
import { getAppConfig } from 'pmt-modules/appConfig'
import {
  registerUserLight,
  registerAsIncognito,
  isFetchingRegister,
  getRegisterError,
} from 'pmt-modules/registration'
import { isFetchingOrderPost, getErrorOrderPost } from 'pmt-modules/orderPost'
import { UserLightFormView } from 'pmt-modules/registration/forms/userLight'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import Grid from 'pmt-ui/Grid'
import Hidden from 'pmt-ui/Hidden'
import Ask from 'pmt-ui/svg-icons/action/ask'
import Cgu from 'pmt-ui/Cgu'

import Button from '../../../components/Button'
import MessageBlock from '../../../components/MessageBlock'

const styles = (theme) => ({
  title: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(4),
    float: 'right',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  allowIncognitoButton: {
    marginTop: theme.spacing(4),
    float: 'right',
    padding: 10,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 0,
    },
  },
  buttonsGrid: {
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginTop: 0,
    },
  },
  caption: {
    display: 'flex',
    color: theme.pmt.status.inactive,
    marginTop: 20,
  },
  captionWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  captionTitle: {
    marginBottom: theme.spacing(1),
  },
  iconAsk: {
    width: '1em',
    height: '1em',
    marginRight: 7,
  },
  divider: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${theme.shape.border.color.default}`,
      marginBottom: theme.spacing(3),
    },
  },
  incognitoButtonsGrid: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginTop: 0,
    },
  },
  incognitoLabel: {
    color: theme.pmt.status.inactive,
  },
  incognitoCgu: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
})

class UserLightForm extends React.PureComponent {
  constructor(props) {
    super(props)

    // SECURITY
    // on user light mode, login page is specifically made to post order
    // if the order data is null, it means that one mandatory information
    // to access this page is missing
    if (isNil(props.orderData)) {
      if (this.hasRedirectUriDefined()) {
        window.location.href = props.frontSettings.redirectUri
      } else {
        props.resetOrderPlugin()
        props.redirectTo(getRoute('ORDER__STORE_LOCATOR'))
      }
    }
  }

  hasRedirectUriDefined = () => this.props.frontSettings && this.props.frontSettings.redirectUri

  handleSubmitLogin = () => {
    this.props.registerUserLight(this.props.formData)
  }

  handleKeepIncognito = () => {
    this.props.registerAsIncognito()
  }

  render() {
    const {
      classes,
      formIsValid,
      formData,
      isFetchingOrder,
      isFetchingRegister,
      orderPostError,
      registerError,
      allowIncognito,
      onChange,
    } = this.props

    return (
      <React.Fragment>
        <TypographyCustom className={classes.title} type="284">
          {tr('order.login.your_infos')}
        </TypographyCustom>

        <LoadingBlockWrapper show={isFetchingOrder || isFetchingRegister} />

        <Grid spacing={2} container>
          {allowIncognito ? (
            <React.Fragment>
              <Grid item xs={12} md={5} lg={5}>
                <TypographyCustom type="207" className={classes.subtitle} skipColor>
                  {tr('order.login.user_light.title')}
                </TypographyCustom>
                {!isNil(orderPostError || registerError) && (
                  <ErrorBlock
                    error={orderPostError || registerError}
                    mode={ErrorBlock.Mode.CUSTOM}
                    customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
                  />
                )}
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.handleSubmitLogin()
                  }}
                >
                  <Grid container>
                    <UserLightFormView />

                    <div className={classes.caption}>
                      <Ask className={classes.iconAsk} />
                      <div className={classes.captionWrapper}>
                        <TypographyCustom type="167" className={classes.captionTitle} skipColor>
                          {tr('order.login.user_light.caption.title')}
                        </TypographyCustom>
                        <TypographyCustom type="144" skipColor>
                          {tr('order.login.user_light.caption.text')}
                        </TypographyCustom>
                      </div>
                    </div>

                    <Grid container className={classes.buttonsGrid}>
                      <Grid item lg={4}>
                        <Button
                          size="large"
                          type="submit"
                          label={tr('order.login.user_light.button')}
                          disabled={!formIsValid}
                          classes={{ root: classes.button }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid item xs={12} md={1} className={classes.divider} />
              <Grid item xs={12} md={5} lg={5}>
                <TypographyCustom type="207" className={classes.subtitle}>
                  {tr('global.register.continue_as_incognito.title')}
                </TypographyCustom>
                <Grid container>
                  <TypographyCustom type="144" className={classes.incognitoLabel}>
                    {tr('global.register.continue_as_incognito.text')}
                  </TypographyCustom>
                </Grid>
                <Grid container className={classes.incognitoButtonsGrid}>
                  <Grid item lg={6} className={classes.incognitoCgu}>
                    <Cgu
                      value={formData.acceptContinueAsIncognitoCgu}
                      onChange={(acceptContinueAsIncognitoCgu) =>
                        onChange({ ...formData, acceptContinueAsIncognitoCgu })
                      }
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <Button
                      size="large"
                      label={tr('global.register.continue_as_incognito.button')}
                      disabled={!formData.acceptContinueAsIncognitoCgu}
                      classes={{ root: classes.button }}
                      onClick={(e) => {
                        e.stopPropagation()
                        this.handleKeepIncognito()
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Hidden smDown>
                <Grid item md={5}>
                  <div className={classes.caption}>
                    <Ask className={classes.iconAsk} />
                    <div className={classes.captionWrapper}>
                      <TypographyCustom type="167" className={classes.captionTitle} skipColor>
                        {tr('order.login.user_light.caption.title')}
                      </TypographyCustom>
                      <TypographyCustom type="144" skipColor>
                        {tr('order.login.user_light.caption.text')}
                      </TypographyCustom>
                    </div>
                  </div>
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid item md={1} />
              </Hidden>
              <Grid item xs={12} md={5} lg={5}>
                {!isNil(orderPostError || registerError) && (
                  <ErrorBlock
                    error={orderPostError || registerError}
                    mode={ErrorBlock.Mode.CUSTOM}
                    customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
                  />
                )}

                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.handleSubmitLogin()
                  }}
                >
                  <Grid container>
                    <Grid item md={11}>
                      <UserLightFormView />
                    </Grid>

                    <Grid container className={classes.buttonsGrid}>
                      <Grid item lg={4}>
                        <Button
                          size="large"
                          type="submit"
                          label={tr('order.login.user_light.button')}
                          disabled={!formIsValid}
                          classes={{ root: classes.button }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  frontSettings: getAppConfigFrontSettings(state),
  isFetchingOrder: isFetchingOrderPost(state),
  isFetchingRegister: isFetchingRegister(state),
  orderData: getOrderData(state),
  orderPostError: getErrorOrderPost(state),
  registerError: getRegisterError(state),
  allowIncognito: getAppConfig(state).authentication.allowIncognito,
})

export default compose(
  withStyles(styles),
  withForm(FormType.USER_LIGHT),
  connect(mapStateToProps, {
    registerUserLight,
    registerAsIncognito,
    redirectTo,
  })
)(UserLightForm)
