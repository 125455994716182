import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

import AsyncPaymentApi from 'pmt-modules/api/calls/AsyncPaymentApi'

/**
 * Finalize an async payment
 */
export const GetAsyncPaymentAction = createApiEnumAction('GetAsyncPaymentAction')

/**
 *
 * @param {long} paymentId Payment id
 * @param {Object} options
 * @param {int} retry increased at each getAsyncPayment. this is used to retry with increasing time
 * between each get.
 * @returns
 */
export const getAsyncPayment = (paymentId, options = {}, retry = 0) =>
  createApiCallAction(
    GetAsyncPaymentAction,
    AsyncPaymentApi.getAsyncPayment(paymentId, options, retry),
    {
      paymentId,
      ...options,
      // must be after spread of the options, which contains the old retry value
      retry,
    }
  )
