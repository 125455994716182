import React from 'react'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { tr } from 'pmt-modules/i18n'
import { EventManager } from 'pmt-modules/event'
import { EPaymentMode, PspRegisterUserCardType } from 'pmt-modules/creditCard'

import { getPublicUrl } from 'pmt-modules/environment'
import { PaymentMethodsAllowed } from 'pmt-modules/orderSettings'
import { getRoute } from 'pmt-modules/routing'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import Delete from 'pmt-ui/svg-icons/action/delete'
import CloseIcon from 'pmt-ui/svg-icons/navigation/close'

import { getAbsolutePath } from 'pmt-utils/url'

import BlockContainer from '../../../components/BlockContainer'
import Button from '../../../components/Button'
import Checkbox from '../../../components/Checkbox'

import AddCreditCardFormView from './AddCreditCardFormView'

const styles = (theme) => ({
  creditCardContainerPadding: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.border.radius.content,
    background: theme.pmt.colors.greyLightBackground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  creditCardNumberContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  creditCardInfoContainer: {
    flexGrow: 1,
    marginLeft: 10,
  },
  cvv: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left !important',
    },
  },
  nameContainer: {
    width: 190,
  },
  name: {
    marginTop: 0,
  },
  iconDeleteContainer: {
    height: 48,
    lineHeight: '48px',
    paddingTop: 11,
  },
  iconCreditCard: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  expiry: {
    opacity: 0.5,
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  lineHeight53: {
    lineHeight: '53px',
  },
  payWithNewCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  colorPrimary: {
    color: theme.palette.text.primary,
  },
  addCreditCardRoot: {
    width: '100%',
    border: '1px solid rgba(25,8,0,.30)',
    borderRadius: 5,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:hover': {
      background: 'none',
      border: '1px solid rgba(25,8,0,.30)',
    },
  },
  addCreditCardLabel: {
    color: theme.palette.text.primary,
  },
})

class CreditCardPayment extends React.Component {
  constructor(props) {
    super(props)

    if (
      props.creditCards &&
      props.creditCards.length === 1 &&
      props.restaurant.hasPaymentWithCardPrint
    ) {
      props.onToggleCreditCard(true, props.creditCards[0])
    }

    // user has no credit card and payment method is already selected (ie. only available)
    if (props.isEmptyCreditCards && props.orderProperties.payment.isCreditCardMethod) {
      this.openCreditCardForm()
    }
  }

  componentWillReceiveProps(nextProps) {
    // user selects credit card method but has no credit cards yet
    if (
      !this.props.orderProperties.payment.isCreditCardMethod &&
      nextProps.orderProperties.payment.isCreditCardMethod &&
      nextProps.isEmptyCreditCards
    ) {
      this.openCreditCardForm()
    }

    // user already had at least one credit card and click on adding a new one
    // or delete his cards, list becomes empty so we want to display form
    if (
      (isNil(this.props.datasPostCreditCardRegistrationType) &&
        !isNil(nextProps.datasPostCreditCardRegistrationType) &&
        !nextProps.ui.showCreditCardForm) ||
      (!this.props.isEmptyCreditCards && nextProps.isEmptyCreditCards)
    ) {
      if (
        !this.props.isFetchingPostCreditCardRegistrationType &&
        !nextProps.isFetchingPostCreditCardRegistrationType
      ) {
        this.openCreditCardForm()
      }
      nextProps.updateUI({ showCreditCardForm: true })
    }

    // hide credit card form after a success full POST
    if (
      this.props.postPspDatasIsFetching &&
      !nextProps.postPspDatasIsFetching &&
      !isNil(nextProps.postPspDatasCard) &&
      nextProps.ui.showCreditCardForm
    ) {
      nextProps.updateUI({ showCreditCardForm: false })
    }

    if (
      nextProps.restaurant.hasPaymentWithCardPrint &&
      !this.props.creditCards &&
      nextProps.creditCards &&
      nextProps.creditCards.length === 1
    ) {
      nextProps.onToggleCreditCard(true, nextProps.creditCards[0])
      nextProps.updateUI({ showCreditCardForm: false })
    }
  }

  openCreditCardForm = (shouldResetCards = false) => {
    const { restaurant, user, updateUI, postUserCreditCardRegistrationType } = this.props
    const { pspRegisterUserCardType } = restaurant

    if (!restaurant.hasPaymentWithCardPrint) {
      return
    }

    if (shouldResetCards) {
      this.props.onResetUserCard()
      this.props.resetUserCreditCardToPspSend()
    }

    if (pspRegisterUserCardType !== PspRegisterUserCardType.WEB_SERVICES) {
      postUserCreditCardRegistrationType(user.id, restaurant.id, undefined, {
        urlSuccess: getAbsolutePath(getRoute('ORDER__PAYMENT__SUCCESS')),
        urlFailure: getAbsolutePath(getRoute('ORDER__PAYMENT__FAILURE')),
        urlCancel: getAbsolutePath(getRoute('ORDER__PAYMENT__CANCEL')),
      })
    } else {
      updateUI({ showCreditCardForm: true })
    }
  }

  render() {
    const {
      isFetching,
      isEmptyCreditCards,
      isOnlyOneCreditCard,
      selectedCreditCard,
      creditCards,
      onToggleCreditCard,
      onClickDeleteCreditCard,
      setPaymentMethod,
      // datasPostCreditCardRegistrationType,
      isFetchingPostCreditCardRegistrationType,
      // statusSendUserCreditCardToPsp,
      user,
      restaurant,
      amountToPay,
      ui,
      updateUI,
      orderProperties,
      classes,
      canRegisterCardWithName,
    } = this.props

    const { pspRegisterUserCardType } = restaurant

    return (
      <React.Fragment>
        <FormControlLabel
          onClick={() => {
            EventManager.dispatch(EventManager.Events.ON_PAYMENT_MODE_SELECT, {
              paymentMode: PaymentMethodsAllowed.CREDIT_CARD,
            })
            setPaymentMethod(PaymentMethodsAllowed.CREDIT_CARD)
          }}
          control={
            <Radio
              color="primary"
              name="paymentCreditCard"
              checked={orderProperties.payment.isCreditCardMethod}
              value="paymentCreditCard"
            />
          }
          label={
            // <TypographyCustom type={orderProperties.payment.isCreditCardMethod ? '167' : '164'}>
            <TypographyCustom type={'167'}>
              {restaurant.isPspSaferpay && tr('order.payment.online')}
              {!restaurant.isPspSaferpay && tr('order.payment.credit_card')}
            </TypographyCustom>
          }
        />

        {restaurant.hasPaymentWithCardPrint && orderProperties.payment.isCreditCardMethod && (
          <div className="u-marginTop10">
            <div>
              {isFetching ? (
                <LoadingBlock show />
              ) : (
                <React.Fragment>
                  {!isEmptyCreditCards && (
                    <React.Fragment>
                      {!isNil(creditCards) &&
                        creditCards.map((creditCard, index) => (
                          <BlockContainer
                            key={index}
                            className="u-overflowHidden u-marginTop5"
                            classes={{
                              padding: classes.creditCardContainerPadding,
                            }}
                            onClick={
                              !isOnlyOneCreditCard
                                ? () => {
                                    const toBeSelected =
                                      !selectedCreditCard ||
                                      (selectedCreditCard &&
                                        creditCard.id !== selectedCreditCard.id)
                                    onToggleCreditCard(toBeSelected, creditCard)
                                    updateUI({
                                      showCreditCardForm: false,
                                    })
                                  }
                                : null
                            }
                          >
                            <div className={classNames(classes.lineHeight53)}>
                              <Checkbox
                                color="primary"
                                checked={
                                  selectedCreditCard && selectedCreditCard.id === creditCard.id
                                }
                                disabled={isOnlyOneCreditCard}
                              />
                            </div>
                            <div className={classes.creditCardInfoContainer}>
                              <TypographyCustom type="124">{creditCard.name}</TypographyCustom>
                              <div
                                className={classNames(
                                  'u-marginTop5 u-marginBottom5',
                                  classes.creditCardNumberContainer
                                )}
                              >
                                <div
                                  className={classNames(classes.iconCreditCard, 'u-marginRight10')}
                                >
                                  {creditCard.payMode === EPaymentMode.VISA && (
                                    <img
                                      src={`${getPublicUrl()}/img/ic_VISA.png`}
                                      height="18"
                                      alt="VISA"
                                    />
                                  )}
                                  {creditCard.payMode === EPaymentMode.MASTERCARD && (
                                    <img
                                      src={`${getPublicUrl()}/img/ic_MASTERCARD.png`}
                                      height="18"
                                      alt="MASTERCARD"
                                    />
                                  )}
                                </div>
                                <TypographyCustom type="166">{creditCard.number}</TypographyCustom>
                              </div>

                              <TypographyCustom type="124" className={classNames(classes.expiry)}>
                                {tr('order.payment.credit_card.expire', {
                                  date: creditCard.expirationDateFormatted,
                                })}
                              </TypographyCustom>
                            </div>
                            <div
                              className={classNames(classes.iconDeleteContainer)}
                              onClick={() => {
                                onClickDeleteCreditCard(creditCard)
                              }}
                            >
                              <Delete className={classes.colorPrimary} />
                            </div>
                          </BlockContainer>
                        ))}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              {!ui.showCreditCardForm &&
                ((pspRegisterUserCardType !== PspRegisterUserCardType.WEB_SERVICES &&
                  !isFetchingPostCreditCardRegistrationType) ||
                  (!isEmptyCreditCards &&
                    pspRegisterUserCardType === PspRegisterUserCardType.WEB_SERVICES)) && (
                  <div className="u-marginTop20">
                    <TypographyCustom type="166" align="center">
                      <Button
                        label={tr('order.payment.add_new_credit_card')}
                        onClick={() => this.openCreditCardForm(true)}
                        variant="outlined"
                        classes={{
                          root: classes.addCreditCardRoot,
                          label: classes.addCreditCardLabel,
                        }}
                      />
                    </TypographyCustom>
                  </div>
                )}
            </div>

            {restaurant.hasPaymentWithCardPrint &&
              pspRegisterUserCardType === PspRegisterUserCardType.WEB_SERVICES && (
                <form>
                  {(ui.showCreditCardForm || (isEmptyCreditCards && !isFetching)) && (
                    <React.Fragment>
                      {!isEmptyCreditCards && (
                        <TypographyCustom type="167" className={classes.payWithNewCard}>
                          {tr('order.payment.pay_with_new_card')}
                          <CloseIcon
                            fontSize="small"
                            classes={{ root: 'u-floatRight' }}
                            onClick={() => {
                              if (isOnlyOneCreditCard) {
                                onToggleCreditCard(true, creditCards[0])
                              }
                              updateUI({ showCreditCardForm: false })
                            }}
                          />
                        </TypographyCustom>
                      )}
                      <AddCreditCardFormView
                        updateUI={updateUI}
                        formClasses={{
                          cvv: classes.cvv,
                          nameContainer: classes.nameContainer,
                          name: classes.name,
                        }}
                        restaurantId={restaurant.id}
                        restaurantCgvUrl={restaurant.legal.cgvUrl}
                        userId={user.id}
                        amountToPay={amountToPay}
                        withName={canRegisterCardWithName}
                      />
                    </React.Fragment>
                  )}
                </form>
              )}
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(CreditCardPayment)
