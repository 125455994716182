import find from 'lodash/find'
import isNil from 'lodash/isNil'

let _dataCatcherSettings = null

export const setDataCatcherSettings = dataCatcherSettings => {
  _dataCatcherSettings = dataCatcherSettings
}

export const getDataCatcherSettings = () => _dataCatcherSettings

export const getDataCatcherSettingForType = dataCatcherType =>
  find(getDataCatcherSettings(), setting => setting.dataCatcherType === dataCatcherType)

export const isDataCatcherEnabledForType = dataCatcherType =>
  !isNil(getDataCatcherSettingForType(dataCatcherType))
