import { tr } from 'pmt-modules/i18n'
import React from 'react'

import Price from 'pmt-ui/Price'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import BlockContainer from '../../../components/BlockContainer'
import PromoCode from './PromoCode'

const styles = (theme) => ({
  paymentDetails: {
    padding: 0,
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  leftToPay: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    overflow: 'hidden',
  },
  admissionFeesLabel: {
    overflow: 'hidden',
    lineHeight: '24px',
    color: theme.pmt.colors.grey500,
  },
})

const PaymentDetails = ({ classes, amountToPay }) => (
  <BlockContainer className={classes.paymentDetails}>
    <TypographyCustom type="166" className={classes.leftToPay}>
      {tr('order.payment.left_to_be_paid')}
      <TypographyCustom type="166" className="u-floatRight" skipColor>
        <Price value={amountToPay} />
      </TypographyCustom>
    </TypographyCustom>

    <PromoCode />
  </BlockContainer>
)

export default withStyles(styles)(PaymentDetails)
