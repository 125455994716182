import Application from 'pmt-modules/archi/Application'

import appConfig from './config/appConfig'
import analytics from './config/analytics'
import devMiddlewares from './config/devMiddlewares'
import dialogs from './config/dialogs'
import entities from './config/entities'
import environment from './config/environment'
import events from './config/events'
import forms from './config/forms'
import getApiManagerOptions from './config/getApiManagerOptions'
import i18n from './config/i18n'
import middlewares from './config/middlewares'
import theme from './config/theme'
import routingEnum from './config/routingEnum'

require('pmt-modules/archi/styles/fontPlusJakartaSans.scss')

const appOptions = {
  appConfig,
  analytics,
  devMiddlewares,
  dialogs,
  entities,
  environment,
  events,
  forms,
  getApiManagerOptions,
  i18n,
  middlewares,
  theme,
  routingEnum,
}

Application(appOptions)
