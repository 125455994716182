import isNil from 'lodash/isNil'
import Logger from 'pmt-utils/logger'

import { createMiddleware } from '../redux'

import { createMuiThemeWithPmtRGroupColors } from 'pmt-utils/theme/theme'

import { GetRestaurantsGroupAction } from './actions'
import { getCurrentTheme, changeCurrentTheme } from '../theme'

/**
* Intercepts GET restaurantsGroup and update theme if succeeded
* Otherwise just sends action to next middleware
*
* @returns {Function}
*/
export const restaurantsGroupThemeMiddleware = createMiddleware(
  GetRestaurantsGroupAction.SUCCESS,
  ({ getState, dispatch, next, action }) => {

    if (!isNil(action.response.theme)) {
      const currentTheme = getCurrentTheme(getState())
      const newTheme = createMuiThemeWithPmtRGroupColors(currentTheme, action.response.theme)

      next(changeCurrentTheme(newTheme))
    } else {
      Logger.warn(
        'RGroup theme',
        `no theme found for rgroup ${action.response.name} ${action.response.id}`
      )
    }
  }
)
