import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { formatCreditCards, formatRegistrationType } from './format'

const getCreditCards = state => state.entities.userCreditCards
const getUserIdOnProps = (state, props) => props.userId

/**
 * TODO: there is no need of a 'make' here
 * we should always have a list of credit cards for current user,
 * never more than one at a time
 */
export const makeIsFetchingUserCreditCards = () =>
  createSelector([getUserIdOnProps, getCreditCards], (userId, creditCardsState) => {
    const creditCardsData = creditCardsState.get(userId)
    if (isNil(creditCardsData)) {
      return false
    }

    return creditCardsData.get('isFetching')
  })

export const makeGetUserCreditCardsData = () =>
  createSelector([getUserIdOnProps, getCreditCards], (userId, creditCardsState) => {
    const creditCardsData = creditCardsState.get(userId)
    if (isNil(creditCardsData)) {
      return null
    }
    return creditCardsData.toJS()
  })

export const makeGetUserCreditCards = () =>
  createSelector([getUserIdOnProps, getCreditCards], (userId, creditCardsState) => {
    const creditCardsData = creditCardsState.get(userId)
    if (isNil(creditCardsData)) {
      return null
    }

    let creditCards = creditCardsData.get('list')

    if (isNil(creditCards)) {
      return null
    }

    creditCards = creditCards.toJS()

    creditCards = creditCards.sort((a, b) => a.creationDate < b.creationDate)
    creditCards = creditCards.filter(creditCard => !creditCard.temporary)

    return formatCreditCards(creditCards)
  })

//
// userSelectedCreditCardsReducer
//

const getSelectedCreditCardsState = state => state.entities.userSelectedCreditCards

export const makeGetUserSelectedCreditCards = () =>
  createSelector(
    [getUserIdOnProps, getSelectedCreditCardsState],
    (userId, selectedCreditCardsState) => {
      const creditCardsData = selectedCreditCardsState.get(userId)
      if (isNil(creditCardsData)) {
        return []
      }

      let creditCards = creditCardsData.get('list')

      if (isNil(creditCards)) {
        return []
      }

      creditCards = creditCards.toJS()

      return formatCreditCards(creditCards)
    }
  )

// /!\ we trick here to not use a make* selector, that should be refactor.
export const getUserSelectedCreditCards = createSelector(
  [getSelectedCreditCardsState],
  selectedCreditCardsState => {
    const selectedCreditCards = selectedCreditCardsState.toJS()

    return formatCreditCards(selectedCreditCards.list || [])
  }
)

//
//
//
const getPostUserCreditCardRegistrationTypeState = state =>
  state.entities.postUserCreditCardRegistrationType

export const getIsFetchingPostCreditCardRegistrationType = createSelector(
  [getPostUserCreditCardRegistrationTypeState],
  postCreditCardRegistrationTypeState =>
    postCreditCardRegistrationTypeState.get('isFetching', false)
)

export const getDatasPostCreditCardRegistrationType = createSelector(
  [getPostUserCreditCardRegistrationTypeState],
  postCreditCardRegistrationTypeState =>
    formatRegistrationType(postCreditCardRegistrationTypeState.get('datas', null))
)

export const getErrorPostCreditCardRegistrationType = createSelector(
  [getPostUserCreditCardRegistrationTypeState],
  postCreditCardRegistrationTypeState => postCreditCardRegistrationTypeState.get('error', null)
)

const getSendUserCreditCardToPspState = state => state.entities.sendUserCreditCardToPsp

export const getSendUserCreditCardToPspIsFetching = createSelector(
  [getSendUserCreditCardToPspState],
  sendUserCreditCardToPspState => sendUserCreditCardToPspState.get('isFetching', false)
)

export const getSendUserCreditCardToPspError = createSelector(
  [getSendUserCreditCardToPspState],
  sendUserCreditCardToPspState => sendUserCreditCardToPspState.get('error', null)
)

export const getStatusSendUserCreditCardToPsp = createSelector(
  [getSendUserCreditCardToPspState],
  sendUserCreditCardToPspState => ({
    success: sendUserCreditCardToPspState.get('success', false),
    failure: sendUserCreditCardToPspState.get('failure', false),
    cancel: sendUserCreditCardToPspState.get('cancel', false),
  })
)

const getPostPspDatasState = state => state.entities.postPspDatas

export const getPostPspDatasError = createSelector([getPostPspDatasState], postPspDatasState =>
  postPspDatasState.get('error', null)
)

export const getPostPspDatasIsFetching = createSelector([getPostPspDatasState], postPspDatasState =>
  postPspDatasState.get('isFetching', false)
)

export const getPostPspDatasCard = createSelector([getPostPspDatasState], postPspDatasState =>
  postPspDatasState.get('card', null)
)

const getDeleteUserCreditCardState = state => state.entities.deleteUserCreditCard

export const getDeleteUserCreditCardError = createSelector(
  [getDeleteUserCreditCardState],
  deleteCreditCardState => {
    let error = deleteCreditCardState.get('error')

    if (isNil(error)) {
      return null
    }

    return error.toJS()
  }
)

export const getDeleteUserCardIsFetching = createSelector(
  [getDeleteUserCreditCardState],
  deleteCreditCardState => {
    let isFetching = deleteCreditCardState.get('isFetching')

    if (isNil(isFetching)) {
      return false
    }

    return isFetching
  }
)
