// TODO: i18n.
const UserAccountErrors = {
  EDIT: {
    144: 'global.api.user_account.edit.144',
    501: 'global.api.user_account.edit.501',
    default:
      "Une erreur est survenue lors de la mise à jour du compte de l'utilisateur.",
  },
}

export default UserAccountErrors
