import React from 'react'
import classNames from 'classnames'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import throttle from 'lodash/throttle'

import { tr } from 'pmt-modules/i18n'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import ArrowDownIcon from 'pmt-ui/svg-icons/hardware/keyboard-arrow-down'

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px 0`,
    height: 60,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      height: 50,
    },
  },
  item: {
    lineHeight: '18px',
  },
  priorityItems: {
    display: 'flex',
    height: '100%',
  },
  priorityItem: {
    flexShrink: 0,
    marginRight: 25,
    display: 'flex',
    alignItems: 'center',
    '&:lastChild': {
      marginRight: 0,
    },
  },
  activeItem: {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 3,
      bottom: 0,
      right: 0,
      left: 0,
      background: theme.palette.primary.main,
    },
  },
  extraItemsContainer: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    zIndex: '1',
  },
  extraItemsContainerVisible: {
    '& $extraItems': {
      display: 'flex',
    },
    '& $extraItemsButtonArrow': {
      transform: 'rotate(180deg)',
    },
  },
  extraItemsButton: {
    display: 'flex',
    alignItems: 'center',
    margin: `0 ${theme.spacing(1)}px`,
    cursor: 'pointer',
    opacity: 0.6,
    transition: 'opacity 0.35s ease',
    '&:hover': {
      opacity: 1,
    },
  },
  extraItemsButtonActive: {
    opacity: 1,
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 3,
      bottom: 0,
      right: 0,
      left: 0,
      background: theme.palette.primary.main,
    },
  },
  extraItemsButtonLabel: {},
  extraItemsButtonArrow: {
    width: 25,
    height: 25,
  },
  extraItems: {
    display: 'none',
    flexDirection: 'column',
    position: 'absolute',
    top: 'calc(100% - 12px)',
    right: -theme.spacing(1),
    backgroundColor: theme.pmt.colors.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    borderRadius: 13,
    border: '1px solid rgba(25,8,0,.05)',
    boxShadow: '0px 1px 2px rgba(25, 8, 0, 0.1)',

    [theme.breakpoints.up('md')]: {
      right: '50%',
      transform: 'translateX(50%)',
    },
  },
  extraItem: {
    padding: '12px 10px',
    borderBottom: `1px solid ${theme.shape.border.color.default}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  activeExtraItem: {
    borderBottom: 'none',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 2,
      bottom: 0,
      right: 0,
      left: 0,
      background: theme.palette.primary.main,
    },
  },
  extraItemLabel: {
    width: 'max-content',
    margin: '0 auto',
  },
})

class PriorityNavbar extends React.PureComponent {
  constructor(props) {
    super(props)

    this.updateNavbar = this.updateNavbar.bind(this)

    this.state = {
      priorityItems: null,
      extraItems: null,
      isExtraItemsVisible: false,
      isSelectedItemInExtraItems: false,
    }
  }

  navbarContainerRef = null
  setNavbarContainerRef = (element) => {
    this.navbarContainerRef = element
  }

  navbarRef = null
  setNavbarRef = (element) => {
    this.navbarRef = element
  }

  extraItemsButtonRef = null
  setExtraItemsButtonRef = (element) => {
    this.extraItemsButtonRef = element
  }

  parsePxSizeToFloat = (size) => {
    return parseFloat(size.replace('px', ''))
  }

  getElementInnerWidth = (element) => {
    if (isNil(element)) {
      return null
    }

    const width = element.getBoundingClientRect().width,
      style = window.getComputedStyle(element),
      paddingLeft = this.parsePxSizeToFloat(style.paddingLeft),
      paddingRight = this.parsePxSizeToFloat(style.paddingRight)

    return width - paddingLeft - paddingRight
  }

  getElementOuterWidth = (element) => {
    if (isNil(element)) {
      return null
    }

    const width = element.getBoundingClientRect().width,
      style = window.getComputedStyle(element),
      marginLeft = this.parsePxSizeToFloat(style.marginLeft),
      marginRight = this.parsePxSizeToFloat(style.marginRight),
      borderLeft = this.parsePxSizeToFloat(style.borderLeft),
      borderRight = this.parsePxSizeToFloat(style.borderRight)

    return width + marginLeft + marginRight + borderLeft + borderRight
  }

  calculateNumberOfVisiblePriorityItems(itemsWidths, containerWidth, initialWidth) {
    let totalWidth = initialWidth
    let numberOfVisibleItems = 0

    for (const itemWidth of itemsWidths) {
      if (totalWidth + itemWidth > containerWidth) {
        return numberOfVisibleItems
      } else {
        numberOfVisibleItems++
        totalWidth += itemWidth
      }
    }

    return numberOfVisibleItems
  }

  updateNavbar = () => {
    this.containerWidth = this.getElementInnerWidth(this.navbarContainerRef)
    this.extraItemsButtonWidth = this.extraItemsButtonRef
      ? this.getElementOuterWidth(this.extraItemsButtonRef)
      : 0

    const numberOfVisibleItems = this.calculateNumberOfVisiblePriorityItems(
      this.itemsWidths,
      this.containerWidth,
      this.extraItemsButtonWidth
    )

    const priorityItems = this.props.items.slice(0, numberOfVisibleItems)

    const extraItems = this.props.items.slice(numberOfVisibleItems)

    const isSelectedItemInExtraItems =
      extraItems.filter((item) => item.id === this.props.selectedItem.id).length > 0

    this.setState({
      priorityItems: priorityItems,
      extraItems: extraItems,
      isSelectedItemInExtraItems: isSelectedItemInExtraItems,
    })
  }

  handleExtraItemsVisibility = (e) => {
    e.stopPropagation()

    this.toggleExtraItemsVisibility()

    window.removeEventListener('click', this.handleExtraItemsVisibility)
  }

  toggleExtraItemsVisibility = () => {
    const isExtraItemsVisible = this.state.isExtraItemsVisible

    if (!isExtraItemsVisible) {
      window.addEventListener('click', this.handleExtraItemsVisibility)
    }

    this.setState({
      isExtraItemsVisible: !isExtraItemsVisible,
    })
  }

  componentWillMount() {
    this.setState({
      priorityItems: this.props.items,
    })
  }

  componentDidMount = () => {
    this.itemsWidths = Array.from(this.navbarRef.children).map((element) =>
      this.getElementOuterWidth(element)
    )

    window.addEventListener('resize', throttle(this.updateNavbar, 200))
    this.updateNavbar()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateNavbar())
  }

  handleItemClick = (item, isExtraItem = false) => {
    this.setState({
      isSelectedItemInExtraItems: isExtraItem,
    })
  }

  handleExtraItemsButtonClick = (e) => {
    e.stopPropagation()

    this.toggleExtraItemsVisibility()
  }

  render() {
    const { selectedItem, getLabel, getValue, classes, onChange } = this.props

    const {
      priorityItems,
      extraItems,
      isExtraItemsVisible,
      isSelectedItemInExtraItems,
    } = this.state

    return (
      <div className={classes.root} ref={this.setNavbarContainerRef}>
        <ul className={classes.priorityItems} ref={this.setNavbarRef}>
          {priorityItems?.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                this.handleItemClick(item)
                onChange(getValue(item))
              }}
              className={classNames(classes.item, classes.priorityItem, {
                [classes.activeItem]: selectedItem.id === item.id,
              })}
            >
              {getLabel(item)}
            </li>
          ))}
        </ul>
        {(isNil(extraItems) || !isEmpty(extraItems)) && (
          <div
            className={classNames(classes.extraItemsContainer, {
              [classes.extraItemsContainerVisible]: isExtraItemsVisible,
            })}
          >
            <div
              className={classNames(classes.extraItemsButton, {
                [classes.extraItemsButtonActive]: isSelectedItemInExtraItems,
              })}
              onClick={(e) => this.handleExtraItemsButtonClick(e)}
              ref={this.setExtraItemsButtonRef}
            >
              <TypographyCustom type={146} className={classes.extraItemsButtonLabel}>
                {tr('order.catalog.priority_navbar.extra_items')}
              </TypographyCustom>
              <ArrowDownIcon className={classes.extraItemsButtonArrow} />
            </div>
            <ul className={classes.extraItems}>
              {extraItems?.map((extraItem, index) => (
                <li
                  key={index}
                  onClick={() => {
                    this.handleItemClick(extraItem, true)
                    onChange(getValue(extraItem))
                  }}
                  className={classNames(classes.item, classes.extraItem, {
                    [classes.activeExtraItem]: selectedItem.id === extraItem.id,
                  })}
                >
                  <div className={classes.extraItemLabel}>{getLabel(extraItem)}</div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(PriorityNavbar)
