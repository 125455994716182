/**
 * The list chooser can be displayed in different type of views, defined here.
 *
 * See `ListChooser.type` prop to force the type.
 */
export default {
  /**
   * Display as popover. Default for desktop screens.
   */
  POPOVER: 'ListChooserType::POPOVER',

  /**
   * Display as a dialog. Default for non-desktop screens.
   */
  DIALOG: 'ListChooserType::DIALOG',
}
