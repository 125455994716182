import AuthApi from '../api/calls/AuthApi'
import UserApi from '../api/calls/UserApi'

import { createApiCallAction } from '../api/utils'
import { createApiEnumAction } from '../api/utils'
import createAction from '../redux/createAction'

export const AuthUserAction = createApiEnumAction('AUTH::USER')

/**
 *
 * @param  {string} username
 * @param  {string} password
 * @param  {string} props     additionnal props that can be used by the middlewares.
 *                    - redirectTo: will redirect to the given url after the login success
 *                    - clientId: to override client id used in environnment
 *                    - clientSecret: to override client secret used in environnment
 */
export const authenticateUser = (username, password, props) => {
  const clientId = props.clientId
  const clientSecret = props.clientSecret

  return createApiCallAction(
    AuthUserAction,
    AuthApi.postAuthPassword(username, password, clientId, clientSecret),
    {
      username,
      password,
      props,
      client_id: clientId,
      client_secret: clientSecret,
    }
  )
}

export const ForgotPasswordAction = createApiEnumAction('AUTH::FORGOT_PASSWORD')

export const forgotPassword = username =>
  createApiCallAction(ForgotPasswordAction, UserApi.getRecoverPassword(username), {
    username,
  })

//
//
//

export const LOGOUT_ACTION = 'LOGOUT'

export const logout = props =>
  createAction(LOGOUT_ACTION, {
    ...props,
  })

export const SAVE_RAW_ACCESS_TOKEN_ACTION = 'AUTH::SAVE_RAW_ACCESS_TOKEN'
export const saveRawAccessToken = props =>
  createAction(SAVE_RAW_ACCESS_TOKEN_ACTION, {
    data: props,
  })

export const CLEAN_AUTH_DATA = 'AUTH::CLEAN'
export const cleanAuthData = () => createAction(CLEAN_AUTH_DATA, {})
