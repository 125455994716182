import { tr } from 'pmt-modules/i18n'
import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import Chip from 'pmt-ui/Chip'
import Tooltip from 'pmt-ui/Tooltip'

const useStyles = makeStyles(theme => ({
  chip: {
    borderRadius: 3,
    border: 0,
    margin: 5,
    fontSize: props => (props.size === 'mini' ? 10 : 12),
    height: props => props.size === 'mini' && 16,
  },
}))

/**
 * @specs N/A
 */
const TestChip = ({
  className,
  label = tr('global.restaurant.testMode'),
  variant = 'default',
  size = 'small', // We add the 'mini' size for very small chip
  color = 'primary',
  tooltipText,
}) => {
  const classes = useStyles({ size })

  const chip = (
    <Chip
      variant={variant}
      // mini does not exists on Chip component, only small
      size={size === 'mini' ? 'small' : size}
      label={label}
      color={color}
      className={clsx(classes.chip, className)}
    />
  )

  let toRender = chip

  if (tooltipText) {
    toRender = <Tooltip title={tooltipText}>{chip}</Tooltip>
  }

  return toRender
}

export default TestChip
