import isNil from 'lodash/isNil'
import forEach from 'lodash/forEach'
import { createSelector } from 'reselect'

import { createSimpleSelector } from 'pmt-modules/redux'

import { formatRestaurantsGroupWithChildren } from './format'

const getState = state => state.entities.restaurantsGroup
// handle restaurantsGroupId pass on props as a int, immutable data use keys as strings
const getRestaurantsGroupIdOnProps = (state, props) =>
  props.restaurantsGroupId ? String(props.restaurantsGroupId) : props.restaurantsGroupId

export const getRestaurantsGroupListState = createSelector([getState], state => state.get('list'))

//
//
//

export const getRestaurantsGroupsMap = createSelector(
  [getRestaurantsGroupListState],
  restaurantsGroups => {
    let groups = (restaurantsGroups.toJS ? restaurantsGroups.toJS() : restaurantsGroups) || {}

    const groupsMap = {}
    forEach(groups, (restaurantsGroupData, restaurantsGroupId) => {
      if (restaurantsGroupData.restaurantsGroup) {
        groupsMap[restaurantsGroupId] = formatRestaurantsGroupWithChildren(
          restaurantsGroupData.restaurantsGroup
        )
      }
    })

    return groupsMap
  }
)

//
//
//

export const makeIsFetchingRestaurantsGroup = () => {
  return createSelector(
    [getRestaurantsGroupIdOnProps, getRestaurantsGroupListState],
    (restaurantsGroupId, restaurantsGroupListState) => {
      const restaurantsGroupData = restaurantsGroupListState.get(restaurantsGroupId)
      if (isNil(restaurantsGroupData)) {
        return false
      }

      return restaurantsGroupData.get('isFetching')
    }
  )
}

export const makeGetRestaurantsGroup = () =>
  createSelector(
    [getRestaurantsGroupIdOnProps, getRestaurantsGroupListState],
    (restaurantsGroupId, restaurantsGroupListState) => {
      const restaurantsGroupData = restaurantsGroupListState.get(restaurantsGroupId)
      if (isNil(restaurantsGroupData)) {
        return null
      }

      const restaurantsGroup = restaurantsGroupData.get('restaurantsGroup')

      if (isNil(restaurantsGroup)) {
        return null
      }
      return formatRestaurantsGroupWithChildren(restaurantsGroup.toJS())
    }
  )

export const makeGetRawRestaurantsGroup = () =>
  createSelector(
    [getRestaurantsGroupIdOnProps, getRestaurantsGroupListState],
    (restaurantsGroupId, restaurantsGroupListState) => {
      const restaurantsGroupData = restaurantsGroupListState.get(restaurantsGroupId)
      if (isNil(restaurantsGroupData)) {
        return null
      }

      const restaurantsGroup = restaurantsGroupData.get('restaurantsGroup')

      if (isNil(restaurantsGroup)) {
        return null
      }
      return restaurantsGroup.toJS()
    }
  )

export const makeGetRestaurantsGroupError = () =>
  createSelector(
    [getRestaurantsGroupIdOnProps, getRestaurantsGroupListState],
    (restaurantsGroupId, restaurantsGroupListState) => {
      const restaurantsGroupData = restaurantsGroupListState.get(restaurantsGroupId)
      if (isNil(restaurantsGroupData)) {
        return null
      }

      const error = restaurantsGroupData.get('error')

      return isNil(error) ? null : error.toJS()
    }
  )

//
//
//

export const isFetchingPutGroupStoreList = createSelector([getState], restaurantsGroup => {
  const restaurantsGroupPut = restaurantsGroup.get('putStoreList')
  return restaurantsGroupPut.get('isFetching', false)
})

export const getPutGroupStoreListData = createSelector([getState], restaurantsGroup => {
  const restaurantsGroupPut = restaurantsGroup.get('putStoreList')
  return restaurantsGroupPut.get('data', null)
})

export const isFetchingPutGroupGroupList = createSelector([getState], restaurantsGroup => {
  const restaurantsGroupPut = restaurantsGroup.get('putGroupList')
  return restaurantsGroupPut.get('isFetching', false)
})

//
//
//

export const getPutGroupGroupListData = createSelector([getState], restaurantsGroup => {
  const restaurantsGroupPut = restaurantsGroup.get('putGroupList')
  return restaurantsGroupPut.get('data', null)
})

const postRestaurantsGroupSelectors = createSimpleSelector(
  state => state.entities.postRestaurantsGroup
)

export const isFetchingRestaurantsGroupPost = postRestaurantsGroupSelectors.isFetching
export const getRestaurantsGroupPost = postRestaurantsGroupSelectors.getData
export const getRestaurantsGroupPostError = postRestaurantsGroupSelectors.getError
