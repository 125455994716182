import moment from 'moment'

import { createFormatter, createListFormatter } from 'pmt-utils/format'

import { formatPriceWithCurrency } from 'pmt-utils/currency'

const formatAmount = userAccount => {
  userAccount.amountFormatted = formatPriceWithCurrency(userAccount.amount)
  return userAccount
}

const formatExpirationDateAsHour = userAccount => {
  userAccount.tokenExpirationDateAsHour = moment(userAccount.tokenExpirationDate).format('HH:mm')
  return userAccount
}

export const formatUserAccount = createFormatter(formatAmount, formatExpirationDateAsHour)

export const formatUserAccounts = createListFormatter(formatUserAccount)

export const formatUserUserAccounts = user => {
  user.userAccount = null // default

  if (user.userAccounts && user.userAccounts.length > 0) {
    user.userAccounts = formatUserAccounts(user.userAccounts)

    if (user.userAccounts) {
      user.userAccount = user.userAccounts[0]
    }
  } else {
    // avoid crashes on map array, default value.
    user.userAccounts = []
  }
  return user
}
