import { createAction } from '../redux'

import { ModalType, createHideDialogAction } from '../dialog'

export const CartDiffAction = {
  SET_ITEM_LIST: 'CART_DIFF::SET_ITEM_LIST',
  DELETE_ITEM_LIST: 'CART_DIFF::DELETE_ITEM_LIST',
}

export const setCartDiffItemList = itemList =>
  createAction(CartDiffAction.SET_ITEM_LIST, {
    itemList,
  })

export const deleteCartDiffItemList = () => createAction(CartDiffAction.DELETE_ITEM_LIST, {})

export const hideCartDiffDialog = () => createHideDialogAction(ModalType.CART_DIFF_ITEMS)
