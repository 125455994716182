import head from 'lodash/head'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import cloneDeep from 'lodash/cloneDeep'

export const getFirstSelectableCategory = catalog => {
  return head(catalog.categories)
}

const recursiveGetParentCategory = (categories, parentsCategories) => {
  let parentsCategoriesClone = cloneDeep(parentsCategories)
  const parentCategory = parentsCategoriesClone.shift()

  let parentCategoryFound = categories.filter(category => category.id === parentCategory.id)

  if (
    !isUndefined(parentCategoryFound) &&
    !isEmpty(parentCategoryFound) &&
    !isEmpty(parentsCategoriesClone)
  ) {
    parentCategoryFound = parentCategoryFound.pop()
    parentCategoryFound = recursiveGetParentCategory(
      parentCategoryFound.categories,
      parentsCategoriesClone
    )
    if (!isUndefined(parentCategoryFound)) {
      return parentCategoryFound
    }
  }

  return parentsCategories[0]
}

const recursiveGetCategoryWithId = (categoryId, category, foundCategory = null) => {
  if (!isNil(category.categories) && !isEmpty(category.categories) && isNil(foundCategory)) {
    category.categories.forEach(subCategory => {
      if (subCategory.id === categoryId) {
        foundCategory = subCategory
      }
    })
  }

  if (!isNil(category.categories) && !isEmpty(category.categories) && isNil(foundCategory)) {
    category.categories.forEach(subCategory => {
      if (!isNil(subCategory.categories) && !isEmpty(subCategory.categories)) {
        foundCategory = recursiveGetCategoryWithId(categoryId, subCategory, foundCategory)
      }
    })
  }

  return foundCategory
}

// return either the selected category directly from catalog
// or the selected category going to in each of its parents categories
export const getCategoryFromCatalog = (catalog, categoryId, parentCategories) => {
  if (!isEmpty(parentCategories)) {
    const parentCategoryOfCategorySelected = recursiveGetParentCategory(
      catalog.categories,
      parentCategories
    )

    if (parentCategoryOfCategorySelected.id === categoryId) {
      return parentCategoryOfCategorySelected
    } else if (parentCategoryOfCategorySelected.categories) {
      let category = parentCategoryOfCategorySelected.categories
        .filter(category => category.id === categoryId)
        .pop()

      if (category) {
        return category
      }
    }

    return null
  }

  return recursiveGetCategoryWithId(categoryId, catalog)
}

export const isCategoryEmpty = category => {
  if (isEmpty(category.items) || isUndefined(category.items)) {
    return true
  }

  return false
}
