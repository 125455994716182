import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import ui from 'pmt-modules/reduxUi'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import {
  UserAddressListContainer,
  editUserAddress,
  deleteUserAddress,
  isFetchingUserAddressDelete,
} from 'pmt-modules/userAddress'

import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import ScrollableAnchor from 'pmt-ui/ScrollableAnchor'

import Address from '../../../../components/Address'
import FlexBlock from '../../../../components/FlexBlock'

const styles = (theme) => ({
  container: {
    borderColor: theme.shape.border.color.default,
    borderWidth: theme.shape.border.width.default,
    borderStyle: 'solid',
    borderRadius: theme.shape.border.radius.secondaryContent,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      border: 'none',
      borderRadius: 0,
      paddingLeft: 0,
      paddingRight: 0,
      borderBottomWidth: theme.shape.border.width.default,
      borderBottomColor: theme.shape.border.color.default,
      borderBottomStyle: 'solid',
      marginBottom: '0px !important',
    },
  },
  grid: {
    paddingTop: `${theme.spacing(1)}px !important`,
    paddingBottom: `${theme.spacing(1)}px !important`,
  },
})

const DeliveryAddresses = ({
  user,
  anchor,
  isFetchingUserAddressDelete,
  deleteUserAddress,
  handleOnClickBlock,
  opened,
  ui,
  updateUI,
  classes,
}) => (
  <ScrollableAnchor id={anchor}>
    <div>
      <FlexBlock
        isFlex
        classes={{ root: `u-marginBottom25 ${classes.container}` }}
        title={tr('order.account.delivery_address.title')}
        content={
          <UserAddressListContainer userId={user.id} origin={null} range={null}>
            {({ isFetchingUserAddressList, userAddressList }) => (
              <Grid container spacing={5}>
                {isNil(userAddressList) ||
                isFetchingUserAddressList ||
                isFetchingUserAddressDelete ? (
                  <Grid item xs={12}>
                    <LoadingBlock show />
                  </Grid>
                ) : isEmpty(userAddressList) ? (
                  <Grid item xs={12}>
                    <TypographyCustom type="144">
                      {tr('order.account.delivery_address.no_addresses')}
                    </TypographyCustom>
                  </Grid>
                ) : (
                  <React.Fragment>
                    {userAddressList.map((userAddress, index) => (
                      <Grid key={index} item xs={12} md={6} className={classes.grid}>
                        <Address
                          key={index}
                          label={userAddress.name}
                          labelEdit={tr('order.addresses.form.edit.title')}
                          defaultData={userAddress}
                          address={userAddress}
                          user={user}
                          className={'u-marginBottom10'}
                          showEditAddressForm={
                            userAddress.id === ui.edit.idSelected && ui.edit.showAddressForm
                          }
                          type={Address.Type.ACCOUNT}
                          handleEditAction={() => {
                            updateUI({
                              edit: {
                                idSelected: userAddress.id,
                                showAddressForm: true,
                              },
                            })
                          }}
                          onClose={() => {
                            updateUI({
                              edit: {
                                showAddressForm: false,
                              },
                            })
                          }}
                          handleEditActionSuccess={() => {
                            updateUI({
                              edit: {
                                showAddressForm: false,
                              },
                            })
                          }}
                          handleDeleteAction={() => {
                            deleteUserAddress(user.id, userAddress.id)
                          }}
                        />
                      </Grid>
                    ))}
                  </React.Fragment>
                )}
              </Grid>
            )}
          </UserAddressListContainer>
        }
        handleOnClick={handleOnClickBlock}
        opened={opened}
      />
    </div>
  </ScrollableAnchor>
)

const mapStateToProps = (state) => ({
  isFetchingUserAddressDelete: isFetchingUserAddressDelete(state),
})

export default compose(
  ui({
    state: {
      edit: {
        idSelected: null,
        showEditAddressForm: false,
      },
    },
  }),
  connect(mapStateToProps, {
    editUserAddress,
    deleteUserAddress,
  }),
  withStyles(styles)
)(DeliveryAddresses)
