import { createMiddleware } from '../../redux'

import { redirectToExternal, formatQueryParams, replaceWith, getRoute } from '../../routing'

import { PostAuthorizeAppAction } from './actions'

const authorizedAppMiddleware = createMiddleware(
  PostAuthorizeAppAction.SUCCESS,
  ({ getState, dispatch, next, action }) => {
    //
    // The user authorized the app, so we redirect to the uri
    // The uri contains the `redirect_uri` given by the client, and the query params containing
    // oauth2 data such as
    // - code: the auth code
    // - state: the state given by the front. It allow him to validate the source of the
    //  redirection
    //

    const uri = action.response.uri
    dispatch(redirectToExternal(uri))
  }
)

const deniedAppMiddleware = createMiddleware(
  PostAuthorizeAppAction.FAILURE,
  ({ getState, dispatch, next, action }) => {
    //
    // Even if the user did not allowed the app, we have to call the API to get the
    // right error to return.
    // We can have a failure if:
    // - the user did not authorize the app
    // - the request failed for other reasons
    // - the network was broken. TODO: handle network fails.
    //

    //
    // Can be:
    //  `access_denied` | User chose not to grant your app permissions | Fail gracefully - let the user know you cannot continue, and be respectful of their choice to decline to use your app
    // `unsupported_response_type` | Invalid `response_type` parameter in initial Authorization | Ensure that the `response_type` parameter is one of the allowed values
    // `invalid_scope` | Invalid `scope` parameter in initial Authorization | Ensure that the `scope` parameter is a space-separated list of valid scopes
    // `invalid_request` | There was an issue with the request sent to `/authorize`
    //
    const error = action.error && action.error.body ? action.error.body.error : null
    const state = action.data.props.state
    const uri = formatQueryParams(action.data.props.redirect_uri, {
      error,
      state,
    })

    // Can be `invalid_grant` when the user access_token / refresh_token is invalid.
    // we redirect to the login page
    if (error === 'invalid_grant') {
      // we return a next action here, since we don't want other middlewares to handle the 401
      return next(
        replaceWith(
          getRoute('LOGIN'),
          {},
          {
            redirectTo: window.location.toString(),
          }
        )
      )
    } else {
      dispatch(redirectToExternal(uri))
    }
  }
)

export const authorizeAppMiddlewares = [authorizedAppMiddleware, deniedAppMiddleware]
