import Immutable from 'immutable'
import isNil from 'lodash/isNil'

import {
  RestaurantAction,
  GetRestaurantAction,
  GetRestaurantListAction,
  GetRestaurantListByGeoPtAction,
  RefreshRestaurantAction,
  PostRestaurantAction,
} from './actions'

import { GetHistoryAction } from '../userHistory/actions'

export * from './actions'
export * from './selectors'
export * from './middlewares'
export * from './constants'
export * from './format'

// Example of restaurant data
// {
//   restaurant: null,
//   isFetching: false,
//   lastUpdated: null,
//   error: null,
// }

const DEFAULT = Immutable.fromJS({})

export const restaurantReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case RestaurantAction.SET:
      if (isNil(action.restaurant)) {
        return state
      }

      return state.mergeIn([action.restaurant.id], {
        restaurant: action.restaurant,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case GetRestaurantAction.REQUEST:
      return state.mergeIn([action.data.restaurantId], {
        restaurant: null,
        isFetching: true,
        lastUpdated: null,
        error: null,
      })

    case RefreshRestaurantAction.SUCCESS:
    case GetRestaurantAction.SUCCESS:
      return state.mergeIn([action.response.id], {
        restaurant: action.response,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case GetRestaurantAction.FAILURE:
      return state.mergeIn([action.data.restaurantId], {
        restaurant: null,
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
      })

    case RefreshRestaurantAction.REQUEST:
      return state.mergeIn([action.data.restaurantId, 'isFetching'], true)

    case PostRestaurantAction.REQUEST:
      return state.mergeIn(['post'], {
        data: action.response,
        isFetching: true,
        lastUpdated: null,
        error: null,
      })

    case PostRestaurantAction.SUCCESS:
      return state.mergeIn(['post'], {
        data: action.response,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case PostRestaurantAction.FAILURE:
      return state.mergeIn(['post'], {
        data: null,
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
      })

    case GetRestaurantListAction.REQUEST:
      return state.mergeIn(['list'], {
        data: action.response,
        isFetching: true,
        lastUpdated: null,
        error: null,
      })

    case GetRestaurantListAction.SUCCESS:
      return state.mergeIn(['list'], {
        data: action.response,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case GetRestaurantListAction.FAILURE:
      return state.mergeIn(['list'], {
        data: null,
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
      })

    case GetRestaurantListAction.RESET:
      return state.mergeIn(['list'], {
        data: null,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case GetRestaurantListByGeoPtAction.REQUEST:
      return state.mergeIn(['list'], {
        data: action.response,
        isFetching: true,
        lastUpdated: null,
        error: null,
      })

    case GetRestaurantListByGeoPtAction.SUCCESS:
      return state.mergeIn(['list'], {
        data: action.response,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case GetRestaurantListByGeoPtAction.FAILURE:
      return state.mergeIn(['list'], {
        data: null,
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
      })

    // Should we really make this module dependant of userHistory?
    // another method:
    // middleware on userHistory that dispatch a new action for here
    case GetHistoryAction.SUCCESS:
      if (!action.response.restaurants) {
        return state
      }
      action.response.restaurants.forEach(restaurant => {
        state = state.mergeIn([restaurant.id], {
          restaurant,
          isFetching: false,
          lastUpdated: new Date(),
          error: null,
        })
      })
      return state

    default:
      return state
  }
}
