import PaymentErrorsList from './PaymentErrorsList'
import MangoPayErrors from './MangoPayErrors'

const PaymentAsyncErrors = {
  200: 'global.post_order.error.200',
  201: 'global.post_order.error.201',
  202: 'global.post_order.error.202',
  203: 'global.post_order.error.203',
}

// TODO: I18n
const OrderErrors = {
  POST: {
    //
    // Order errors
    //
    100: 'global.post_order.error.100',
    102: 'global.post_order.error.102',
    103: 'global.post_order.error.103',
    104: 'global.post_order.error.104',
    139: 'global.post_order.error.139',
    141: 'global.post_order.error.141',
    142: 'global.post_order.error.142',
    150: 'global.post_order.error.150',
    151: 'global.post_order.error.151',

    180: 'global.post_order.error.180',

    ...PaymentErrorsList,
    ...PaymentAsyncErrors,
    ...MangoPayErrors.list,

    432: 'global.post_order.error.432',
    default: 'global.post_order.error.default',
  },
}

export default OrderErrors
