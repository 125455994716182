import { getForCurrentLocale } from 'pmt-modules/i18n/'

import { formatPercent, formatPriceWithCurrency } from 'pmt-utils/currency'
import { createFormatter, createListFormatter } from 'pmt-utils/format'

import { FeeSettingStatus, FeeType } from '../constants'

//  {
//   "type": "DELIVERY",
//   "name": [
//     {
//       "locale": "fr-FR",
//       "text": "Frais de livraison"
//     },
//     {
//       "locale": "en-US",
//       "text": "Delivery price"
//     }
//   ],
//   "amountIncludingTax": 2.42,
//   "taxPercent": 20,
//   "taxAmount": 0.4
// }

const formatOrderFee = orderFee => {
  orderFee.isStatusEnabled = orderFee.status === FeeSettingStatus.ENABLED

  orderFee.isTypeDelivery = orderFee.type === FeeType.DELIVERY
  orderFee.isTypeService = orderFee.type === FeeType.SERVICE

  orderFee.nameFormatted = getForCurrentLocale(orderFee.name)
  // TODO: i18n
  orderFee.typeFormatted = orderFee.type
  orderFee.publicDescriptionFormatted = getForCurrentLocale(orderFee.publicDescription)

  orderFee.amountIncludingTaxFormatted = formatPriceWithCurrency(orderFee.amountIncludingTax)

  orderFee.minimumPriceForFreeFormatted = formatPriceWithCurrency(orderFee.minimumPriceForFree)

  orderFee.taxPercentFormatted = formatPercent(orderFee.taxPercent)

  orderFee.taxAmountFormatted = formatPercent(orderFee.taxAmount)

  return orderFee
}

export const formatOrderFees = createFormatter(createListFormatter(formatOrderFee))
