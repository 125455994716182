export const DueDateMode = {
  /**
   * Unknown mode. For old orders.
   */
  UNKNOWN: 'UNKNOWN',
  /**
   * The user has chosen ASAP.
   */
  ASAP: 'ASAP',
  /**
   * The user has chosen a specific slot.
   */
  SLOT: 'SLOT',
}
