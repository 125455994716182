import invariant from 'invariant'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import isNil from 'lodash/isNil'
import { uintColorToHexadecimal, lighten, darken } from './colorManipulator'

//
// Returns an mui color
// An mui color contains all the colors for the defined contrasts (font weight).
// TODO: for now, we set the same color for every constrat..
//
export const createMuiColor = (color, palette) => ({
  main: color,
  light: lighten(color, palette.tonalOffset),
  dark: darken(color, palette.tonalOffset),
  contrastText: palette.getContrastText(color),
})

/**
 * [createMuiTextColor description]
 * @param  {[type]} currentConfig [description]
 * @param  {[type]} newColor      [description]
 * @return {[type]}               [description]
 */
export const createMuiTextColor = (currentConfig, newConfig) => ({
  ...currentConfig,
  primary: !isNil(newConfig.primary)
    ? isNumber(newConfig.primary) ? uintColorToHexadecimal(newConfig.primary) : newConfig.primary
    : currentConfig.primary,
  secondary: !isNil(newConfig.secondary)
    ? isNumber(newConfig.secondary)
      ? uintColorToHexadecimal(newConfig.secondary)
      : newConfig.secondary
    : currentConfig.secondary,
})

/**
 * Each key of the mui `palette` theme can be handled diffently
 * @param  {string} key   key of the `palette` object
 * @param  {string|object} color color as:
 *                        - an hexadecimal value
 *                        - an rgba string value
 */
export const createMuiValueForPalette = (key, currentConfig, newConfig, palette) => {
  switch (key) {
    case 'text':
      return createMuiTextColor(currentConfig, newConfig)

    case 'primary':
      return createMuiColor(newConfig, palette)

    case 'secondary':
      return createMuiColor(newConfig, palette)

    default:
      invariant(false, 'mui palette key not handled')
  }
}

/**
 * Example:
 *
 * ```
 *   const newPalette = createMuiPalette(currentTheme.palette, {
 *     primary: uintColorToHexadecimal(action.response.colors.actionBackgroundColor),
 *     secondary: uintColorToHexadecimal(action.response.colors.actionBackgroundColor),
 *     text: uintColorToHexadecimal(action.response.colors.fontColor),
 *   })
 * ```
 *
 * @param  {[type]} defaultPalette default palette of the theme
 * @param  {[type]} props          object that will override the palette:
 *                                    - key: key to override on the palette
 *                                    - value: the color set.
 *                                      - number, we parse it as hex and follow the same as a string
 *                                      - string, we use `createMuiColor` to create the different
 *                                       contrasts, otherwise we use the
 *                                      value
 * @return {[type]}                [description]
 */
export const createMuiPalette = (defaultPalette, props) => {
  const palette = cloneDeep(defaultPalette)

  forEach(props, (paletteValue, paletteKey) => {
    let value = null

    if (isNumber(paletteValue)) {
      value = uintColorToHexadecimal(paletteValue)
    } else if (isString(paletteValue)) {
      invariant(paletteValue[0] === '#', 'invalid color')
      value = paletteValue
    } else {
      value = paletteValue
    }

    palette[paletteKey] = createMuiValueForPalette(
      paletteKey,
      defaultPalette[paletteKey],
      value,
      defaultPalette
    )
  })

  return palette
}
