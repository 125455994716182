import ApiEndpoints from '../config/ApiEndpoints'

const AppConfigApi = {
  get: () => ({
    endpoint: ApiEndpoints.API_CONSUMER,
    params: {
      apiConsumerId: 'mine',
    },
    query: {
      loadSettings: true,
    },
    headers: {
      authorization: null, // force override global authorization
    },
    type: 'GET',
  }),
}

export default AppConfigApi
