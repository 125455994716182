import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { redirectTo, getRoute } from 'pmt-modules/routing'
import { withAppConfig } from 'pmt-modules/appConfig'
import { EventManager, SendEventContainer } from 'pmt-modules/event'
import { OrderMode } from 'pmt-modules/order'
import { OrderPluginUrlCheckerContainer, setDueDateAsap } from 'pmt-modules/orderPlugin'
import { selectMode, setRestaurantId } from 'pmt-modules/orderFront'

import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'
import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'

import OrderPage from '../../components/OrderPage'
import View from './View'

/**
 * @specs N/A
 */
@withAppConfig
@withScrollToTop()
class OrderModePage extends React.PureComponent {
  handleSelectMode = mode => {
    EventManager.dispatch(EventManager.Events.ON_MODE_SELECT, {
      mode,
    })
    this.props.selectMode(mode)
    if (mode === OrderMode.ON_SITE && this.props.orderAppConfig.hasTableNumber) {
      this.props.setDueDateAsap()
    }
    this.props.setRestaurantId(null)
    this.props.redirectTo(getRoute('ORDER__STORE_LOCATOR'))
  }

  render() {
    const { appConfig, location, width } = this.props

    return (
      <OrderPage
        headerProps={{
          displayHeader: isWidthUp('md', width),
          displayTitle: true,
          displayUser: true,
        }}
        location={location}
      >
        <OrderPluginUrlCheckerContainer
          location={location}
          verifyMode
          verifyRestaurantId
          verifyAddress
          verifyIsAsap
          verifyTableNumber
        >
          <SendEventContainer event={EventManager.Events.ON_PAGE_LOADED}>
            <View appConfig={appConfig} onSelectMode={this.handleSelectMode} />
          </SendEventContainer>
        </OrderPluginUrlCheckerContainer>
      </OrderPage>
    )
  }
}

const mapStateToProps = (state, props) => ({})

export default compose(
  connect(
    mapStateToProps,
    {
      redirectTo,
      selectMode,
      setRestaurantId,
      setDueDateAsap,
    }
  ),
  withWidth()
)(OrderModePage)
