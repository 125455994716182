import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'
import compose from 'recompose/compose'

import PhoneIcon from '@material-ui/icons/Phone'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Arrow from 'pmt-ui/svg-icons/action/arrow'
import ActionInfoOutline from 'pmt-ui/svg-icons/action/info-outline'

import TableNumberForm from './TableNumberForm'
import AvailabilitySlots from './AvailabilitySlots'
import withWidth, { isWidthDown } from 'pmt-ui/utils/withWidth'

const styles = (theme) => ({
  warningContainer: {
    maxWidth: 380,
    margin: '0 auto',
  },
  selectRestaurant: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: 'relative',
  },
  asapContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  iconInfo: {
    width: 30,
    height: 30,
    marginBottom: theme.spacing(1),
  },
  iconArrow: {
    width: 40,
    height: 40,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  closed: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  closedContent: {
    marginTop: theme.spacing(0.5),
    textAlign: 'center',
  },
  phoneNumber: {
    marginTop: theme.spacing(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.primary,

    '& > svg': {
      marginRight: theme.spacing(),
    },
  },
})

const WhenContent = ({
  restaurant,
  orderProperties,
  orderContext,
  handleSetTableNumber,
  width,
  handleMenuRedirection,
  isFetchingRestaurantList,
  classes,
}) => {
  // no restaurant selected yet
  if (!orderContext) {
    return (
      <div className={classes.selectRestaurant}>
        <Arrow className={classes.iconArrow} />
        <TypographyCustom type="226" align="center" className={classes.warningContainer}>
          {tr('order.store_locator.when_content.access_warning_restaurant')}
        </TypographyCustom>
      </div>
    )
  }

  // restaurant is selected but order is closed
  if (orderContext && !orderContext.canOrder) {
    return (
      <div className={classes.closed}>
        <ActionInfoOutline className={classes.iconInfo} />
        <TypographyCustom type="206" className={classes.closedContent}>
          {tr('order.store_locator.restaurant.currently_closed')}
        </TypographyCustom>

        {orderContext.nextOpening ? (
          <TypographyCustom type="204" className={classes.closedContent}>
            {orderContext.nextOpening.isCurrentDay
              ? tr('order.store_locator.restaurant.closed_today_until', {
                  hour: orderContext.nextOpening.nextOpeningHour.formattedOpening,
                })
              : tr('order.store_locator.restaurant.closed_until', {
                  day: orderContext.nextOpening.dayName,
                  hour: orderContext.nextOpening.nextOpeningHour.formattedOpening,
                })}
          </TypographyCustom>
        ) : (
          restaurant.phonePub && (
            <TypographyCustom type="204" className={classes.closedContent}>
              {tr('order.store_locator.restaurant.contact_phone')}
              <a href={`tel:${restaurant.phonePub}`}>
                <div className={classes.phoneNumber}>
                  <PhoneIcon />
                  {restaurant.phonePub}
                </div>
              </a>
            </TypographyCustom>
          )
        )}
      </div>
    )
  }

  return (
    <Fragment>
      {orderProperties.mustChooseTableNumber && (
        // user have to fill a table number
        <div>
          <TypographyCustom type={isWidthDown('sm', width) ? 247 : 226}>
            {tr('order.store_locator.table_number')}
          </TypographyCustom>
          <TableNumberForm
            isFetchingRestaurantList={isFetchingRestaurantList}
            restaurant={restaurant}
            handleSetTableNumber={(tableNumber) => {
              handleSetTableNumber(tableNumber)
            }}
            handleMenuRedirection={handleMenuRedirection}
          />
        </div>
      )}

      {orderProperties.canOrderUsingSlots && (
        // user have to choose a slot
        <div>
          <TypographyCustom type={isWidthDown('sm', width) ? 247 : 226}>
            {tr('order.store_locator.when')}
          </TypographyCustom>
          <AvailabilitySlots
            isFetchingRestaurantList={isFetchingRestaurantList}
            orderProperties={orderProperties}
            restaurant={restaurant}
            handleMenuRedirection={handleMenuRedirection}
          />
        </div>
      )}

      {!orderProperties.canOrderUsingSlots &&
        !orderProperties.mustChooseTableNumber &&
        orderProperties.canOrderUsingAsap && (
          // when user can order using asap only just display a text depending of its order mode
          <React.Fragment>
            <TypographyCustom type="226">{tr('order.store_locator.when')}</TypographyCustom>
            <div className={classes.asapContainer}>
              <ActionInfoOutline className={classes.iconInfo} />

              <TypographyCustom type="204" align="center" className={classes.warningContainer}>
                {tr(`order.store_locator.when.mode.${orderProperties.mode}.delay`, {
                  delay: orderProperties.delayAndDeliveryFormattedAsMin,
                })}
              </TypographyCustom>
            </div>
          </React.Fragment>
        )}
    </Fragment>
  )
}

export default compose(withStyles(styles), withWidth())(WhenContent)
