import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let OnSiteOder = props => (
  <SvgIcon {...props} width="54.833" height="64.276" viewBox="0 0 54.833 64.276">
    <path
      d="M53.5,20.461,54.713,12h-8.8l1.209,8.461a8.312,8.312,0,1,0,6.384,0"
      transform="translate(-31.698 -5.58)"
      fill="#190800"
    />
    <path
      d="M74.835,37H30.229C27.893,37,26,39.2,26,41.91v7.8H79.064v-7.8c0-2.712-1.893-4.91-4.229-4.91"
      transform="translate(-25.116 -6.132)"
    />
    <path
      d="M80.433,51.081H25.6V42.394c0-3.2,2.294-5.794,5.114-5.794H75.32c2.82,0,5.114,2.6,5.114,5.794ZM27.369,49.313h51.3V42.394c0-2.22-1.5-4.026-3.345-4.026H30.714c-1.844,0-3.345,1.806-3.345,4.026Z"
      transform="translate(-25.6 -6.616)"
      fill="#190800"
    />
    <path
      d="M2.211,0H21.444a2.211,2.211,0,0,1,2.211,2.211V7.927a2.211,2.211,0,0,1-2.211,2.211H2.211A2.211,2.211,0,0,1,0,7.927V2.211A2.211,2.211,0,0,1,2.211,0Z"
      transform="translate(20.166 20.73)"
      fill="#fff"
    />
    <path
      d="M2.211,0H21.444a2.211,2.211,0,0,1,2.211,2.211V7.927a2.211,2.211,0,0,1-2.211,2.211H2.211A2.211,2.211,0,0,1,0,7.927V2.211A2.211,2.211,0,0,1,2.211,0Z"
      transform="translate(20.166 20.73)"
      opacity="0.049"
    />
    <path
      d="M2.7-.4H21.928a3.1,3.1,0,0,1,3.1,3.1V8.411a3.1,3.1,0,0,1-3.1,3.1H2.7a3.1,3.1,0,0,1-3.1-3.1V2.7A3.1,3.1,0,0,1,2.7-.4ZM21.928,9.738a1.328,1.328,0,0,0,1.327-1.327V2.7a1.328,1.328,0,0,0-1.327-1.327H2.7A1.328,1.328,0,0,0,1.369,2.7V8.411A1.328,1.328,0,0,0,2.7,9.738Z"
      transform="translate(19.682 20.245)"
      fill="#190800"
    />
    <path
      d="M56.277,6.327a2.9,2.9,0,0,0,.391-1.438,2.926,2.926,0,0,0-4.025-2.72,2.918,2.918,0,0,0-5.64,0,2.926,2.926,0,0,0-4.025,2.72,2.9,2.9,0,0,0,.391,1.438,2.929,2.929,0,0,0,1.565,5.408h9.779a2.929,2.929,0,0,0,1.565-5.408"
      transform="translate(-31.209 0.553)"
    />
    <path
      d="M55.015,12.59H45.236a3.481,3.481,0,0,1-2.261-6.133,3.362,3.362,0,0,1-.248-1.265,3.49,3.49,0,0,1,3.486-3.486,3.413,3.413,0,0,1,.752.084,3.467,3.467,0,0,1,6.319,0,3.414,3.414,0,0,1,.752-.084,3.49,3.49,0,0,1,3.486,3.486,3.362,3.362,0,0,1-.248,1.265,3.481,3.481,0,0,1-2.261,6.133Zm-8.8-9.779a2.384,2.384,0,0,0-2.381,2.381,2.344,2.344,0,0,0,.319,1.165.553.553,0,0,1-.185.739,2.376,2.376,0,0,0,1.269,4.388h9.779A2.376,2.376,0,0,0,56.284,7.1a.553.553,0,0,1-.185-.739,2.344,2.344,0,0,0,.319-1.165,2.373,2.373,0,0,0-3.267-2.206.553.553,0,0,1-.738-.369,2.365,2.365,0,0,0-4.573,0,.553.553,0,0,1-.738.369A2.386,2.386,0,0,0,46.214,2.811Z"
      transform="translate(-31.511 0.25)"
      fill="#190800"
    />
    <path
      d="M16.385,2.567H.908a1.658,1.658,0,0,1,0-3.317H16.385a1.658,1.658,0,0,1,0,3.317Z"
      transform="translate(18.77 61.709)"
      fill="#190800"
    />
    <path
      d="M.908,18.655A1.658,1.658,0,0,1-.75,17V.551a1.658,1.658,0,1,1,3.317,0V17A1.658,1.658,0,0,1,.908,18.655Z"
      transform="translate(26.508 43.93)"
      fill="#190800"
    />
  </SvgIcon>
)
OnSiteOder = pure(OnSiteOder)
OnSiteOder.displayName = 'OnSiteOder'
OnSiteOder.muiName = 'SvgIcon'

export default OnSiteOder
