import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

const getAppDataState = state => state.entities.authApp.appData

export const getAppDataError = createSelector(
  [getAppDataState],
  authDataState => {
    const error = authDataState.get('error')
    if (isNil(error)) {
      return null
    }
    return error.toJS()
  }
)

export const appDataIsFetching = createSelector(
  [getAppDataState],
  authDataState => {
    return authDataState.get('isFetching')
  }
)

export const getAppData = createSelector([getAppDataState], authDataState => {
  const data = authDataState.get('data')

  if (isNil(data)) {
    return null
  }

  return data.toJS()
})
