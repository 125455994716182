import { tr } from 'pmt-modules/i18n'
import React from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'

import { TypographyCustom } from 'pmt-ui/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(2),
    },
    borderBottom: `1px solid ${theme.shape.border.color.default}`,
  },
}))

const ReclaimLater = ({ orderProduct, setReclaimLater }) => {
  const classes = useStyles()

  if (!orderProduct.reclaimLaterEnabled) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <TypographyCustom type="207">{tr('order.product.reclaimLater.title')}</TypographyCustom>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={orderProduct.reclaimLater}
            onChange={(e) => setReclaimLater(e.target.checked)}
          />
        }
        label={
          <TypographyCustom type="164" component="div">
            {tr('order.product.reclaimLater.label')}
          </TypographyCustom>
        }
      />
    </div>
  )
}

export default ReclaimLater
