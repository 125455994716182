import React from 'react'

import isEmpty from 'lodash/isEmpty'

import { withForm, FormType } from 'pmt-modules/form'

import TextField from 'pmt-ui/TextField'

const ForgotPasswordForm = withForm(FormType.FORGOT_PASSWORD)(
  ({ formData, formErrors, onChange }) => (
    <div>
      <TextField
        type="email"
        name="Email"
        label="Email"
        className="u-sizeFullWidth"
        value={formData.username}
        onChange={event => onChange({ ...formData, username: event.target.value })}
        helperText={formData.username !== null && formErrors.username.message}
        error={formData.username !== null && !isEmpty(formErrors.username.message)}
      />
    </div>
  )
)

export default ForgotPasswordForm
