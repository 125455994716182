import { tr } from 'pmt-modules/i18n'
import invariant from 'invariant'
import merge from 'lodash/merge'
import omitBy from 'lodash/omitBy'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import isNil from 'lodash/isNil'
import isFunction from 'lodash/isFunction'

import { getApiUrl } from '../environment'
import { getStore } from '../store'

import GlobalErrors from './errors/GlobalErrors'

import Logger from 'pmt-utils/logger'
import { getQueryParam } from 'pmt-utils/url'

const Error = {
  NO_INTERNET: 'NO_INTERNET',
  UNKNOWN: 'UNKNOWN',
}

/**
 * This function will merge object of headers together.
 * We need this function to handle different cases on headers names, so `authorization` and
 * `Authorization` are considered the same header.
 *
 * @param  {object} headersBase
 * @param  {object} headers2
 * @return {object}          a new object with the headers merge
 */
const mergeHeaders = (headersBase, headersToMerge) => {
  // clone in order to not brake the reference
  let headers = { ...headersBase }

  for (let headersToMergeKey in headersToMerge) {
    let found = false
    for (let headerKey in headers) {
      if (headersToMergeKey.toLowerCase() === headerKey.toLowerCase()) {
        headers[headerKey] = headersToMerge[headersToMergeKey]
        found = true
      }
    }

    if (!found) {
      headers[headersToMergeKey] = headersToMerge[headersToMergeKey]
    }
  }

  return headers
}

function ApiError(code, message) {
  return {
    code,
    message,
  }
}

function formatEndpoint(endpoint, params = null) {
  if (isNull(params)) {
    return endpoint || ''
  }

  invariant(!isNil(endpoint), `endpoint is nil`)

  let formattedEnpoint = template(endpoint, params)

  function template(template, data) {
    return template.replace(/:(\w*)/g, (m, key) => {
      return data.hasOwnProperty(key) ? data[key] : ''
    })
  }

  return formattedEnpoint
}

// https://stackoverflow.com/questions/316781/how-to-build-query-string-with-javascript/34209399#34209399
function formatQueryParams(parameters) {
  let qs = ''
  for (let key in parameters) {
    let value = parameters[key]
    qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&'
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1) // chop off last '&'
    return `?${qs}`
  }

  return ''
}

class ApiManager {
  ContentTypes = {
    FORM_URL_ENCODE: 'application/x-www-form-urlencoded',
  }

  /**
   * Must be set by calling `setGetApiManagerOptions`
   *
   * A middleware used in case of error.
   * Must return true if the middleware have taking care of the error.
   * Use to handle 401, 500, etc.
   *
   * @param error
   * @param res If undefined, the request have encounter a connexion error.
   * @type Function
   *
   * Example of middleware:
   *
   * ```
   * errorMiddleware: (error, response, json, success, failure) => {
   * if (!isNil(res)) {
   *       if (res.statusCode === 401) {
   *         console.error('[API] 401, redirect to login')
   *         return true
   *       }
   *
   *       if (res.statusCode === 500) {
   *         console.log(res)
   *         Logger.error('API 500', res)
   *         // redirect to error 500 page
   *         return true
   *       }
   *     }
   *     return false
   *   }
   * ```
   *
   * errorMiddleware: (error, res, success: Function, failure: Function) => { return false },
   *
   * Function that must return an object.
   *
   * Example:
   * {
   *  Authorization:  'toto',
   *  OtherHeader: 'toto42'
   * }
   *
   * Note that the headers set to the request's params have the priority over the headers given
   * by the middleware.
   *
   * @type Function
   *
   * headersMiddleware: () => { return {} },
   *
   * @type string
   * apiUrl: '',
   */
  getApiManagerOptions = null

  setGetApiManagerOptions(func) {
    this.getApiManagerOptions = func
  }

  //
  // ------------- Tools
  //

  /**
   * remove undefined or null headers
   */
  getHeaders(headersParam) {
    const optionsHeadersMiddleware = this.getApiManagerOptions(
      getStore().getState()
    ).headersMiddleware()

    let headers = mergeHeaders(optionsHeadersMiddleware, headersParam)
    return omitBy(headers, isNil)
  }

  handleSuccess(previousRequestOptions, response, data, success, failure) {
    if (!previousRequestOptions.then) {
      success(data, response)

      if (isFunction(previousRequestOptions.onSuccessCallback)) {
        previousRequestOptions.onSuccessCallback(data, response)
      }

      return
    }

    //
    // Chained request
    //

    const requestOptions = previousRequestOptions.then(previousRequestOptions, data)

    // requestOptions can be null. when the the functions call the previousRequestOptions.failure itself, it
    // returns null to stop the chain
    if (requestOptions === null) {
      return
    }

    // set root requestOptions success and failure
    requestOptions.success = success
    requestOptions.failure = failure

    this.runFetch(requestOptions.type, requestOptions)
  }

  /**
   * Handle an HTTP response.
   * Calls the success callback in case of success
   * Calls the failure callback in case of error and if the errorMiddleware does not handle the error.
   *
   * @param error
   * @param res
   * @param success the closure called on success. Take a json object as parameter.
   * @param failure the closure called on failure. Take an ApiError as parameter.
   */
  handleResponse(requestOptions, error, response, success, failure, fetchRequestData) {
    if (!isNil(response)) {
      if (response.status === 204) {
        // whatever the content-type, a 204 means success without response body
        this.handleSuccess(requestOptions, response, {}, success, failure)
      } else {
        const contentType = response.headers.get('content-type')
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response
            .json()
            .then(
              json => {
                if (response.ok) {
                  this.handleSuccess(requestOptions, response, json, success, failure)
                } else {
                  console.log('fetch success, response json not ok')
                  this.handleError(requestOptions, response, null, json, success, failure)
                }
              },
              data => {
                // In case we get a 204 (
                // e.g. : when deleting a user address
                // We need to handle the answer as a success and not ignoring it
                if (requestOptions.type === 'DELETE' && response.ok) {
                  this.handleSuccess(requestOptions, response, {}, success, failure)
                } else {
                  console.log('fetch success, response data not ok')
                  this.handleError(requestOptions, response, null, {}, success, failure)
                }
              }
            )
            .catch(e => {
              console.log('fetch error')
              console.error(e)
              this.handleError(requestOptions, response, e, null, success, failure)
            })
        } else {
          response
            .text()
            .then(text => {
              if (response.ok) {
                this.handleSuccess(requestOptions, response, text, success, failure)
              } else {
                console.log('fetch success, response text is not ok')
                this.handleError(requestOptions, response, null, text, success, failure)
              }
            })
            .catch(e => {
              console.log('fetch success, response text catched')
              this.handleError(requestOptions, response, e, null, success, failure)
            })
        }
      }
    } else {
      // TODO: find a way to know it has been cancelled
      const isCancelled = false //error && error.name && error.name === 'AbortError'
      // console.log({isCancelled })
      if (!isCancelled) {
        console.log('fetch catched error:')
        console.error(error)
        if (fetchRequestData.retry < 5) {
          // wait seconds before retrying.
          const timeouts = [2, 3, 4, 5, 10]
          console.log('Retrying')
          setTimeout(() => {
            this.runFetch(
              fetchRequestData.method,
              fetchRequestData.options,
              fetchRequestData.retry + 1
            )
          }, (timeouts[fetchRequestData.retry] || 10) * 1000)
        } else {
          console.log('Aborting')
          // retry.
          this.handleError(requestOptions, null, error, null, success, failure)
        }
      }
    }
  }

  callFailure(requestOptions, error, apiError, failure) {
    if (isFunction(requestOptions.onFailure)) {
      const res = requestOptions.onFailure(requestOptions, apiError)
      if (res !== false) {
        // returns true if handled
        if (res !== true) {
          // not true, we have an object defining a request
          const newRequestOptions = res
          // set root requestOptions success and failure
          newRequestOptions.success = requestOptions.success
          newRequestOptions.failure = failure
          this.run(newRequestOptions)
        }
        return
      }
    }

    if (isFunction(requestOptions.onFailureCallback)) {
      requestOptions.onFailureCallback(apiError)
    }

    failure(apiError)
  }

  handleError(requestOptions, response, error, json, success, failure) {
    let apiError = null

    if (isNil(json)) {
      // no internet connexion / no response
      apiError = ApiError(Error.NO_INTERNET, 'No internet connectivity')
      apiError.localizedMessage = tr('global.api.global_error.no_internet')
    } else {
      apiError = this.createApiError(requestOptions, json, response)
    }

    apiError.body = json
    apiError.response = response
    this.callFailure(requestOptions, error, apiError, failure)
  }

  /**
   * Parse the HTTP response body to create an ApiError object.
   *
   *
   * @param responseBody The HTTP response body.
   * @returns {ApiError} A populate ApiError object.
   */
  createApiError(requestOptions, json, response) {
    // An errorHandler can be:
    // - an object:
    //  ```
    //   {
    //     101: '', // api error code and message (localized)
    //     default: '', // default value to use. not mandatory
    //   }
    //  ```
    // - function
    //  ```
    //    (requestOptions, json) => {
    //    }
    //  ```
    //  - requestOptions is the option object set on our Api* file, (and modify by the ApiManager)
    //  - json is the api response data (could be null / not json)
    let localizedMessage = 'Something went wrong, please try again'
    const error = ApiError(Error.UNKNOWN, '')
    const errorHandler = requestOptions && requestOptions.errorHandler

    if (!isNil(json)) {
      error.code = json.code
      error.message = json.message
      error.localizedMessage = localizedMessage
      error.extra = json.extra
      error.errorCode = json.errorCode
      error.httpStatus = json.httpStatus
      error.timestamp = json.timestamp

      // Temporary fix to handle oauth authentication failure..
      // for now the API gives the code 100. But it can mess with other code 100
      // TODO: remove once the API is up with the new error code (1015)
      if (!isEmpty(error.message) && error.message.indexOf('Oauth authentication failure') !== -1) {
        error.localizedMessage = GlobalErrors[1015]
        return error
      }

      // get localizedMessage
      localizedMessage = json.message

      /**
       * Find the localized message for the json error.
       * We use the GlobalErrors, then the returned localizedMessage if any, then the defined errorHandler.
       *
       * @param  {[type]} errorHandler   defined errorHandler for the request
       * @param  {[type]} requestOptions request options
       * @param  {[type]} json           error json
       */
      const findLocalizedMessage = (errorHandler, requestOptions, json, response) => {
        // find global error
        let globalLocalizedMessage = GlobalErrors[json.code]

        if (!isEmpty(globalLocalizedMessage)) {
          return tr(globalLocalizedMessage)
        }

        if (!isEmpty(json.localizedMessage)) {
          return json.localizedMessage
        }

        // use error handler
        if (errorHandler) {
          if (isFunction(errorHandler)) {
            const errorHandlerFuncResult = errorHandler(requestOptions, json, response)
            // if return an object of errors ({ 100: '', 200: ''})
            if (!isString(errorHandlerFuncResult)) {
              return errorHandlerFuncResult[json.code] || errorHandlerFuncResult['default']
            }
            return errorHandlerFuncResult
          }

          return errorHandler[json.code] || errorHandler['default']
        }

        return null
      }

      let foundLocalizedMessage = findLocalizedMessage(errorHandler, requestOptions, json, response)

      if (!isNull(foundLocalizedMessage)) {
        localizedMessage = foundLocalizedMessage
      }
    } else {
      if (isFunction(errorHandler)) {
        localizedMessage = errorHandler(requestOptions, null)
      } else if (errorHandler) {
        localizedMessage = errorHandler['default'] || localizedMessage
      }
    }

    error.localizedMessage = localizedMessage

    return error
  }

  /**
   *
   * @param statusCode the response status code
   * @returns {boolean} True if the status code indicate a successful response
   */
  isSuccessResponse(statusCode) {
    return statusCode >= 200 && statusCode < 300
  }

  //
  // ------------- Requests tools for the API
  //

  /**
   * @param  {Object} request An object that represent a request:
   * {
   *  type: PUT, POST, UPDATE, DELETE, GET
   *  success: success callback (param: JSON)
   *  failure: failure callback (param: ApiError)
   *  endpoint: the endpoint,
   *  params: the url parameters to set on the endpoint,
   *  query: the url query params,
   *  body: the body data to be send as json,
   *  headers: an object of headers,
   *  isForm: true if the body to send is not json but a x-www-form-urlencoded form. We transform the
   *   body given as an object to a form data.
   *  isMultipart: true if the body to send is a multipart. The given body have to be a javascript
   *   object
   *  errorHandler: A function / object ({ 100: '', default: ''} ) to retrieve the message to attach
   *  on the error. See `createApiError` for more info
   *  isExtern is the request external to our API? must be used with an `url` parameter
   *  url: the url to use instead of the baseUrl + endpoint
   *  then: function (previousOptions, json|data) to chain requests
   *  after: function () to chain requests
   *  updateBody: function (data, body), use it when using the `after` param. It allows you to update
   *   the body set on the request data with the data of the previous request (defined by `after`)
   *  onFailure: function (error): boolean|object custom handler. returns false if custom handler
   *   doest not handled the error. Otherwise can returns true or an object defining a new request
   * }
   */
  run(request) {
    try {
      switch (request.type) {
        case 'GET':
          this.get(request)
          break
        case 'POST':
          this.post(request)
          break
        case 'UPDATE':
        case 'PUT':
          this.put(request)
          break
        case 'PATCH':
          this.patch(request)
          break
        case 'DELETE':
          this.delete(request)
          break
        default:
          Logger.error(
            'api',
            `unknown type ${
              request.type
            }. Verify your defined a 'type' on your api call configuration.`
          )
      }
    } catch (e) {
      console.error(e)
    }
  }

  get(options) {
    this.runFetch('GET', options)
  }

  delete(options) {
    this.runFetch('DELETE', options)
  }

  post(options) {
    this.runFetch('POST', options)
  }

  put(options) {
    this.runFetch('PUT', options)
  }

  patch(options) {
    this.runFetch('PATCH', options)
  }

  getFinalApiUrl(defaultUrl = null) {
    if (!isEmpty(defaultUrl)) {
      return defaultUrl
    }

    return getApiUrl()
  }

  runFetch(method, options, retry = 0) {
    const { url, isExtern, after, query, endpoint, params, success, failure } = options
    // get otherOptions where we remove the headers, that cause problems whene merging the
    // default options with this options (override headers, add null headers that are removed
    // on getHeaders)
    const { headers, ...otherOptions } = options

    // Handle `after` param, the current request have to be run after the one describe by the
    // `after` function param.
    //
    if (!isNil(after)) {
      // get request to made before the current request
      const previousRequestOptions = after()
      // attach a success
      previousRequestOptions.success = json => {
        // run current
        this.run({
          ...options,
          body: !isNil(options.updateBody) ? options.updateBody(json, options.body) : options.body,
          after: null, // avoid infinite loop
        })
      }
      previousRequestOptions.failure = failure

      // run the request to made before the current request
      this.run(previousRequestOptions)
      return // do not continue
    }

    // in case of external call, we use the url set on the options, instead of the base url /
    // endpoint / params.
    const finalUrl = isExtern
      ? `${this.getFinalApiUrl(url)}${formatEndpoint(endpoint, params)}${formatQueryParams(query)}`
      : `${this.getFinalApiUrl(url)}${formatEndpoint(endpoint, params)}${formatQueryParams(query)}`

    const defaultHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    if (!isExtern) {
      const simulateHeader = getQueryParam('simulate')
      if (simulateHeader) {
        defaultHeaders.simulate = simulateHeader
      }
    }

    const defaultOptions = {
      method: method,
      mode: 'cors',
      cache: 'default',
      headers: mergeHeaders(
        defaultHeaders,
        // do not set default headers for external request
        isExtern ? {} : this.getHeaders(headers)
      ),
    }

    // merge otherOptions (options with bad data removed, such as headers that don't have to
    // be merge here since we merge it above)
    const finalOptions = merge(defaultOptions, otherOptions)

    // handle form
    if (options.isForm) {
      finalOptions.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'

      // see https://github.com/github/fetch/issues/263
      // Note: This does not handle nested javascript objects
      finalOptions.body = Object.keys(finalOptions.body)
        .map(key => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(finalOptions.body[key])
        })
        .join('&')
    } else if (options.isMultipart) {
      // create form data from json object
      const formData = new FormData()
      for (let key in finalOptions.body) {
        formData.append(key, finalOptions.body[key])
      }
      finalOptions.body = formData
    } else if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
      // post
      finalOptions.body = JSON.stringify(finalOptions.body)
    }

    fetch(finalUrl, finalOptions)
      .then(response => {
        this.handleResponse(finalOptions, null, response, success, failure, {
          method,
          options,
          retry,
        })
      })
      .catch(error => {
        this.handleResponse(finalOptions, error, null, success, failure, {
          method,
          options,
          retry,
        })
      })
  }
}

let _apiManagerInstance = new ApiManager()

export default _apiManagerInstance
