import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Ask = props => (
  <SvgIcon {...props}>
    <g xmlns="http://www.w3.org/2000/svg" transform="translate(1.000000, 0.000000)">
      <path
        d="M11,0.00728571429 C4.92479167,0.00728571429 0,4.61228571 0,10.293 C0,15.2568351 3.76024944,19.3996522 8.76255983,20.3662096 C10.5416667,20.5714286 12.5250042,23.5714286 13.2916667,23.5714286 C14.3955794,23.5714286 13.1698412,20.4270821 14.175141,20.1441399 C18.702349,18.8699549 22,14.9414842 22,10.293 C22,4.61228571 17.0752083,0.00728571429 11,0.00728571429 Z M11,18.9184286 C5.93725,18.9184286 1.83333333,15.0805714 1.83333333,10.3465714 C1.83333333,5.61257143 5.93725,1.77514286 11,1.77514286 C16.06275,1.77514286 20.1666667,5.61257143 20.1666667,10.3465714 C20.1666667,15.0805714 16.06275,18.9184286 11,18.9184286 Z"
        id="Shape"
      />
      <g id="Group" transform="translate(7.333333, 4.285714)">
        <path
          d="M3.10841667,9.405 C2.31870833,9.405 1.92408333,9.78428571 1.92408333,10.5424286 C1.92408333,10.9088571 2.02629167,11.1938571 2.23025,11.397 C2.43420833,11.6001429 2.72708333,11.7025714 3.10841667,11.7025714 C3.49020833,11.7025714 3.78354167,11.598 3.99025,11.3897143 C4.19695833,11.1814286 4.301,10.899 4.301,10.5424286 C4.301,10.1807143 4.19879167,9.90042857 3.99483333,9.702 C3.790875,9.50357143 3.49525,9.405 3.10841667,9.405 Z"
          id="Shape"
        />
        <path
          d="M6.11370833,1.035 C5.495875,0.552857143 4.66079167,0.311571429 3.60845833,0.311571429 C2.56208333,0.311571429 1.57208333,0.514285714 0.635708333,0.914142857 C0.110458333,1.13528571 0.346041667,1.63671429 0.346958333,1.63928571 L0.556875,2.05028571 C0.556875,2.05028571 0.804833333,2.44328571 1.33925,2.25 C1.34475,2.24828571 1.34658333,2.24914286 1.351625,2.247 C1.582625,2.15657143 1.816375,2.07257143 2.05379167,1.99842857 C2.48875,1.863 2.96129167,1.79485714 3.471875,1.79485714 C4.035625,1.79485714 4.46783333,1.908 4.76895833,2.13385714 C5.07008333,2.35971429 5.21995833,2.68371429 5.21995833,3.10585714 C5.21995833,3.46757143 5.12691667,3.77871429 4.94175,4.04014286 C4.75658333,4.30157143 4.34454167,4.668 3.705625,5.13985714 C3.16891667,5.53157143 2.792625,5.91085714 2.57766667,6.27728571 C2.36270833,6.64414286 2.25545833,7.104 2.25545833,7.65642857 C2.23483333,8.05842857 2.78254167,8.13857143 2.78254167,8.13857143 L3.11345833,8.13857143 C3.11345833,8.13857143 3.74320833,8.142 3.83945833,7.63457143 C3.84083333,7.62771429 3.843125,7.62557143 3.8445,7.61871429 C3.8665,7.29728571 3.94533333,7.03114286 4.087875,6.82757143 C4.257,6.58628571 4.59204167,6.28242857 5.09116667,5.91557143 C5.67645833,5.48871429 6.0885,5.142 6.32729167,4.87585714 C6.56608333,4.60971429 6.74529167,4.32985714 6.86354167,4.03585714 C6.98179167,3.74185714 7.04091667,3.40671429 7.04091667,3.03 C7.04,2.18271429 6.73154167,1.51714286 6.11370833,1.035 Z"
          id="Shape"
        />
      </g>
    </g>
  </SvgIcon>
)
Ask = pure(Ask)
Ask.displayName = 'Ask'
Ask.muiName = 'SvgIcon'

export default Ask
