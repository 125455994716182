import isNil from 'lodash/isNil'

import { createFormatter, createListFormatter } from 'pmt-utils/format'
import { getDistanceFromLatLon } from 'pmt-utils/geoloc'
import { isUndefined } from 'util'

// props.range must be in km
// since the utils function returns km
const formatInRangeFlag = (address, props) => {
  if (!isNil(props) && !isUndefined(props.origin) && !isUndefined(props.range)) {
    const {
      origin,
      range,
    } = props

    address.isInRange = true
    const rangeFromOrigin = getDistanceFromLatLon(origin.latitude, origin.longitude, address.geoPt.latitude, address.geoPt.longitude)
    if (rangeFromOrigin > range) {
      address.isInRange = false
    }
  }

  return address
}

export const formatUserAddress = createFormatter(
  formatInRangeFlag,
)

export const formatUserAddressList = createListFormatter(formatUserAddress)
