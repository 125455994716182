import isNil from 'lodash/isNil'

const AuthErrors = {
  POST: (requestOptions, json, response) => {
    if (json.error) {
      // refresh_token has expired
      if (
        !isNil(requestOptions.attemptRefresh) &&
        json.error === 'invalid_grant' &&
        !isNil(response) &&
        response.status === 400
      ) {
        return 'global.login.error_refresh_token'
      }

      switch (json.error) {
        case 'invalid_grant':
          return 'global.login.global_error'

        default:
      }
    }

    return 'global.login.global_error'
  },
}

export default AuthErrors
