import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Nut = props => (
  <SvgIcon {...props}>
    <path
      d="M18.166,7.546a5.954,5.954,0,0,0-1.755-4.238L14.858,1.755a5.995,5.995,0,0,0-9.944,2.4,6.044,6.044,0,0,0-.276,2.175A5.993,5.993,0,0,0,5.993,18.166h2.2a6,6,0,0,0,5.9-4.941,6,6,0,0,0,4.077-5.679ZM15.616,4.1a4.919,4.919,0,0,1,.6.727,3,3,0,0,1-2.09-.886,3.019,3.019,0,0,1-.884-2.056,4.912,4.912,0,0,1,.821.662ZM5.993,7.3h2.2a4.875,4.875,0,0,1,.938.091,3.025,3.025,0,0,1-4.181.023A4.869,4.869,0,0,1,5.993,7.3Zm2.2,9.738h-2.2A4.869,4.869,0,0,1,3.8,7.828a4.146,4.146,0,0,0,6.489-.048,4.869,4.869,0,0,1-2.1,9.263Zm5.99-5.059a6,6,0,0,0-5.99-5.8h-2.2c-.08,0-.159,0-.238.005A4.87,4.87,0,0,1,10.62,1.124a4.88,4.88,0,0,1,1.521.241,4.142,4.142,0,0,0,1.19,3.373,4.114,4.114,0,0,0,2.919,1.216,4.169,4.169,0,0,0,.515-.032,4.875,4.875,0,0,1-2.588,6.061Z"
      transform="translate(3 4)"
    />
  </SvgIcon>
)
Nut = pure(Nut)
Nut.displayName = 'Nut'
Nut.muiName = 'SvgIcon'

export default Nut
