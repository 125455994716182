import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { default as MuiLink } from '@material-ui/core/Link'
import Route from '../../model/Route'
import { getRoute } from '../../config'
import { generatePathWithParams } from '../../actions'

/**
 * Abstract the react-router-dom Route to handle our `Route` definition.
 */
class Link extends React.Component {
  static defaultProps = {
    to: PropTypes.oneOfType([PropTypes.objectOf(Route), PropTypes.string]).isRequired,
  }

  render() {
    const {
      href,
      to,
      className = '',
      params = {},
      query = {},
      children,
      newTab,
      target,
      anchor,
      ...otherProps
    } = this.props

    if (href) {
      return (
        <a
          href={href + (anchor ? `#${anchor}` : '')}
          className={`link ${className}`}
          target={newTab ? '_blank' : target}
          {...otherProps}
        >
          {children}
        </a>
      )
    }

    return (
      <ReactRouterLink
        to={generatePathWithParams(to, params, query, { anchor })}
        className={`link ${className}`}
        target={newTab ? '_blank' : target}
        {...otherProps}
      >
        {children}
      </ReactRouterLink>
    )
  }
}

// Shortcut to retrieve a route config (to use in the `to` prop)
Link.getRoute = getRoute

Link.Mui = ({ ...rest }) => {
  return <MuiLink component={Link} {...rest} />
}

export default Link
