import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  fetchUserCreditCards,
  onSelectUserCard,
  onUnselectUserCard,
  resetUserCard,
  displayAddCreditCardDialog,
  deleteCreditCard,
} from '../actions'

import {
  makeGetUserCreditCards,
  makeIsFetchingUserCreditCards,
  makeGetUserSelectedCreditCards,
  getDeleteUserCardIsFetching,
} from '../selectors'
import { getAuthenticatedUser } from 'pmt-modules/authenticatedUser/selectors'
import UserType from 'pmt-modules/user/constants/UserType'

/**
 * Container that fetch the credit cards for the given `userId` and pass it to the children.
 * The `children` must be a `Function as child component`.
 *
 * Requirements:
 * - userId, can be 'me'
 * - storeId (optional) - if we want to filter the cards by the store's psp
 *
 */
class CreditCardsContainer extends React.Component {
  constructor(props) {
    super(props)
    if (!props.isFetchingCreditCards && props.userId) {
      this.loadUserCreditCards(props)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (nextProps.userId !== props.userId || nextProps.storeId !== props.storeId) {
      this.loadUserCreditCards(nextProps)
    }
  }

  loadUserCreditCards(props) {
    if (props.userId && props.authenticatedUser.type === UserType.NORMAL) {
      props.fetchUserCreditCards(props.userId, props.storeId)
    }
  }

  render() {
    const {
      isFetchingCreditCards,
      creditCards,
      selectedCreditCards,
      isFetchingDeleteCreditCard,
      userId,
      storeId,
      children,
      ...otherProps
    } = this.props

    return children({
      isFetchingCreditCards,
      selectedCreditCards,
      selectedCreditCard: selectedCreditCards[0] || null,
      creditCards,
      isFetchingDeleteCreditCard,
      userId,
      storeId,
      // TODO: rename onDisplayAddCreditCardDialog
      onAddCreditCard: () => {
        this.props.displayAddCreditCardDialog(this.props.userId)
      },
      onClickDeleteCreditCard: card => {
        this.props.deleteCreditCard(this.props.userId, this.props.creditCards, card)
      },
      onToggleCard: (isSelected, card) => {
        isSelected
          ? this.props.onSelectUserCard(this.props.userId, card)
          : this.props.onUnselectUserCard(this.props.userId, card)
      },
      onResetUserCard: () => {
        this.props.resetUserCard(this.props.userId)
      },
      ...otherProps,
    })
  }
}

CreditCardsContainer.propTypes = {
  fetchUserCreditCards: PropTypes.func.isRequired,
  isFetchingCreditCards: PropTypes.bool,
  creditCards: PropTypes.array,

  // children MUST be a function
  children: PropTypes.func.isRequired,
}

const makeMapStateToProps = () => {
  const getCreditCards = makeGetUserCreditCards()
  const isFetchingCreditCards = makeIsFetchingUserCreditCards()
  const getUserSelectedCreditCards = makeGetUserSelectedCreditCards()

  const mapStateToProps = (state, props) => {
    return {
      creditCards: getCreditCards(state, props),
      isFetchingCreditCards: isFetchingCreditCards(state, props),
      isFetchingDeleteCreditCard: getDeleteUserCardIsFetching(state),
      selectedCreditCards: getUserSelectedCreditCards(state, props),
      authenticatedUser: getAuthenticatedUser(state, props),
    }
  }
  return mapStateToProps
}

export default connect(
  makeMapStateToProps,
  {
    fetchUserCreditCards,
    onSelectUserCard,
    onUnselectUserCard,
    resetUserCard,
    displayAddCreditCardDialog,
    deleteCreditCard,
  }
)(CreditCardsContainer)
