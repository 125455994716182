import { dataCatcherMiddleware } from './middlewares'

import invariant from 'invariant'

import { __DEV__ } from 'pmt-modules/environment'
import { existsOnArray } from 'pmt-utils/array'

/**
 * Verify that the app options are correctly set for this module
 */
export const verifyDataCatcherConfiguration = appOptions => {
  if (__DEV__) {
    // verify that the middleware is set on the configuration
    const exists = existsOnArray(appOptions.middlewares, middleware => {
      return middleware === dataCatcherMiddleware
    })

    invariant(exists, 'dataCatcherMiddlewares must be add to the middleware config')
  }
}
