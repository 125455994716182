import React from 'react'
import { connect } from 'react-redux'

import { redirectTo, getRoute } from 'pmt-modules/routing'

/**
 * @specs N/A
 */
class HomePage extends React.Component {
  constructor(props) {
    super(props)

    props.redirectTo(getRoute('ORDER__MODE'))
  }

  render() {
    return <div />
  }
}

const mapStateToProps = (state, props) => {
  return {}
}

export default connect(
  mapStateToProps,
  {
    redirectTo,
  }
)(HomePage)
