// TODO: fix crash if import with {}. see ../actions
import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import AuthApi from 'pmt-modules/api/calls/AuthApi'
import { createAction } from 'pmt-modules/redux'

export const RefreshTokenFlowAction = {
  RETRY_API_CALL: 'REFRESH_TOKEN_PROMISE::RETRY_API_CALL',
  DONE: 'REFRESH_TOKEN_PROMISE::DONE',
}

// export const setAccessToken = ({ access_token, expires_in }) => ({
//   type: RefreshTokenFlowAction.SET,
//   access_token,
//   expires_in,
// })

/**
 * Action used to re-run an action.
 * Previously, we just runned the action directly, but we didn't have a redux log, so now we use
 * this action, that will be logged and handle by our middleware
 */
export const retryApiCallAction = actionToRetry =>
  createAction(RefreshTokenFlowAction.RETRY_API_CALL, {
    actionToRetry,
  })

//
//
//

export const RefreshAction = createApiEnumAction('AUTH::REFRESH')

/**
 *
 * @param  {string} refreshToken  the refreshToken to use
 * @param  {object} oauthClient   the oauthClient to use with
 *                                  - client_id
 *                                  - client_secret
 * @param  {[type]} props         props to use
 *                                - then: function called by the middlewares handling this action.
 *                                  it notify the `attemptRefresh` of the response.
 *                                  the `attemptRefresh` will:
 *                                    - success: re-run the api calls that failed after updating the
 *                                    access_token
 *                                    - failure: redirect to the login page
 *                                    for both it will remove the refresh promise
 *                                    the `then` function take an object with:
 *                                      - error: boolean
 *                                      - response: the refresh token api call response (contains
 *                                      the token data)
 *
 */
export const refreshAction = (refreshToken, oauthClient, props) => {
  return createApiCallAction(
    RefreshAction,
    AuthApi.postRefreshToken(
      refreshToken,
      oauthClient.clientId,
      oauthClient.clientSecret,
      props
    ),
    {
      refresh_token: refreshToken,
      props,
      client_id: oauthClient.clientId,
      client_secret: oauthClient.clientSecret,
    }
  )
}

export const refreshActionDone = () => ({
  type: RefreshTokenFlowAction.DONE,
})
