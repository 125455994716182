import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { getAuthCookie, getUserLightCookie, getIncognitoCookie } from './utils'

const getAuthData = state => state.entities.auth

export const getAuthError = createSelector([getAuthData], authData => {
  const error = authData.get('error', null)
  return isNil(error) ? null : error.toJS()
})

export const isFetchingAuth = createSelector([getAuthData], authData =>
  authData.get('isFetching', false)
)

export const getRefreshSucceded = createSelector([getAuthData], authData =>
  authData.get('refreshSucceded')
)

export const isRefreshingOauthToken = createSelector(
  [getAuthData, isFetchingAuth],
  (authData, isFetchingAuth) => isFetchingAuth && authData.get('isRefreshing') === true
)

// TODO: should be on utils, those are not selectors:

export const getAuth = () => getAuthCookie()

export const haveAuthCredentials = () =>
  getAuthCookie() !== null || getUserLightCookie() !== null || getIncognitoCookie() !== null

export const getRefreshToken = () => {
  const authCookie = getAuthCookie()
  return isNil(authCookie) ? null : authCookie.refresh_token
}

export const getAccessToken = () => {
  const authCookie = getAuthCookie()
  return isNil(authCookie) ? null : authCookie.access_token
}
