import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment'

import { isWebCustomer } from 'pmt-modules/environment'
import { GetAppConfigAction } from 'pmt-modules/appConfig'
import { createMiddleware } from 'pmt-modules/redux'
import { getRoute, redirectTo, replaceWith } from 'pmt-modules/routing'
import { EventManager } from 'pmt-modules/event'

import { getQueryParam } from 'pmt-utils/url'
import Logger from 'pmt-utils/logger'

import { cleanAuthData } from './actions'
import { removeAccessToken } from './utils'

import { AuthUserAction, LOGOUT_ACTION, SAVE_RAW_ACCESS_TOKEN_ACTION } from './actions'
import { RefreshAction } from './refreshToken/actions'
import { refreshTokenMiddlewares } from './refreshToken/middlewares'
import {
  setClientId,
  saveAccessToken,
  setRestaurantsGroupId,
  removeUserLight,
  removeIncognitoCookie,
} from './utils'

const notifyTokenChanged = token => {
  saveAccessToken(token)
}

/**
 * Intercepts LOGIN action and redirects to dashboard screen if the login succeeded
 * Otherwise just sends action to next middleware
 *
 * @returns {Function}
 */
const authMiddleware = createMiddleware(
  AuthUserAction.SUCCESS,
  ({ getState, dispatch, next, action }) => {
    // first, we calculate the expiration date before saving it to localStorage
    // we subtract 1 minute to take into consideration API response time
    action.response.expiresAt = moment()
      .add(action.response.expires_in, 'ms')
      .subtract(1, 'm')
      .valueOf()

    // notify the token has changed
    notifyTokenChanged(action.response)

    // forget your other "light" authentications, you are now really authenticated !
    removeUserLight()
    removeIncognitoCookie()

    Logger.setUserContext(Logger.UserContext.USER, action.data)

    EventManager.dispatch(EventManager.Events.ON_LOGIN_SUCCESS, {
      user: action.data.username,
    })

    const props = action.data.props || {}

    // get the page where to redirect
    // we handle a `redirectTo` parameter to go back on the page that asked the user to logged in
    const redirectToParam = props.redirectTo || getQueryParam('redirectTo')
    if (!isEmpty(redirectToParam)) {
      dispatch(replaceWith(decodeURIComponent(redirectToParam)))
    }
  }
)

//
// update the token
// middleware that notify `attemptRefresh` that the refresh action succeeded
//
const refreshMiddlewareSuccess = createMiddleware(
  RefreshAction.SUCCESS,
  ({ getState, dispatch, next, action }) => {
    // first, we calculate the expiration date before saving it to localStorage
    // we subtract 1 minute to take into consideration API response time
    action.response.expiresAt = moment()
      .add(action.response.expires_in, 'ms')
      .subtract(1, 'm')
      .valueOf()

    notifyTokenChanged(action.response)

    // we send the response because `attemptRefresh` will re-run the api call, and need to update
    // the access token.
    if (!isNil(action.data.props.then)) {
      action.data.props.then(getState(), true, action.response)
    }
  }
)

//
// middleware that notify `attemptRefresh` that the refresh action failed.
// It will redirect to the login page
//
const refreshMiddlewareFailure = createMiddleware(
  RefreshAction.FAILURE,
  ({ getState, dispatch, next, action }) => {
    // refresh token request has failed, clean everything and redirect to login
    if (
      !get(action, 'error.response.ok', false) &&
      get(action, 'error.response.status', 400) === 400
    ) {
      // clean auth store
      dispatch(cleanAuthData())
      // refresh has failed and store is cleaned, we force redirect to login
      if (isWebCustomer()) {
        return dispatch(
          redirectTo(getRoute('LOGIN'), null, { redirectTo: window.location.toString() })
        )
      } else {
        return dispatch(redirectTo(getRoute('LOGIN')))
      }
    } else if (!isNil(get(action, 'data.props.then', null))) {
      action.data.props.then(getState(), false)
    }
  }
)

const logoutMiddleware = createMiddleware(LOGOUT_ACTION, () => {
  removeAccessToken()
  removeUserLight()
  removeIncognitoCookie()
})

const saveRawAccessTokenMiddleware = createMiddleware(
  SAVE_RAW_ACCESS_TOKEN_ACTION,
  ({ action, next }) => {
    notifyTokenChanged(action.data)

    return next(action)
  }
)

const getAppConfigSuccessMiddleware = createMiddleware(GetAppConfigAction.SUCCESS, ({ action }) => {
  const settings = action.response.settings

  if (
    settings &&
    settings.authentication &&
    settings.authentication.oauth2 &&
    settings.authentication.oauth2.clientId
  ) {
    setClientId(settings.authentication.oauth2.clientId)
  }

  const restaurantsGroupId = action.response.restaurantsGroupId
  setRestaurantsGroupId(restaurantsGroupId)
})

export const authMiddlewares = [
  getAppConfigSuccessMiddleware,
  authMiddleware,
  logoutMiddleware,
  refreshMiddlewareSuccess,
  refreshMiddlewareFailure,
  ...refreshTokenMiddlewares,
  saveRawAccessTokenMiddleware,
]
