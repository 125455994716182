import { createMiddleware } from '../redux'

import { isAppSecurityError } from './utils'
import { SET_API_GENERIC_INVALID, setApiGenericInvalid } from './actions'

const catchApiGenericError = action =>
  // avoid infinite loop
  action.type !== SET_API_GENERIC_INVALID && action.error && isAppSecurityError(action.error)

const apiGenericMiddleware = createMiddleware(catchApiGenericError, ({ dispatch, action }) => {
  dispatch(setApiGenericInvalid(action.error))
})

export const appSecurityMiddlewares = [apiGenericMiddleware]
