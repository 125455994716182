// TODO: I18n
const AuthAppErrors = {
  postAuthorize: {
    // default: 'Erre',
  },
  getAuthorize: {
    default: "Impossible de récupérer l'app",
  },
}

export default AuthAppErrors
