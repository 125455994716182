import { createApiCallAction, createApiEnumAction } from '../../api/utils'

import AuthAppApi from '../../api/calls/AuthAppApi'

export const GetAppDataAction = createApiEnumAction(
  'OAUTH::APP::AUTHORIZE::GET'
)

/**
 * @param  {object} props the GET /authorize props:
 *                        - client_id
 *                        - state
 *                        - redirect_uri
 */
export const fetchAuthorizeApp = props =>
  createApiCallAction(GetAppDataAction, AuthAppApi.getAuthorize(props), {
    props,
  })
