// The ApiConsumer can define multiplee layers of security for an app to be handled by the API
// - appToken
// - geolocation
//
// This module handles the generic errors the API can return because of those security checks.
//

import Immutable from 'immutable'
import { SET_API_GENERIC_INVALID } from './actions'
import { createReducer } from '../redux'

export * from './constants'
export * from './utils'
export * from './middlewares'

const DEFAULT_STATE = Immutable.fromJS({
  error: null,
})

const handleSetGenericInvalid = (state, action) =>
  state.set('error', Immutable.fromJS(action.error))

export const appSecurityReducer = createReducer(DEFAULT_STATE, {
  [SET_API_GENERIC_INVALID]: handleSetGenericInvalid,
})
