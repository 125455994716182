import isNil from 'lodash/isNil'

import { createFormatter } from 'pmt-utils/format'

const formatRedirectUri = (frontSettings, params) => {
  if (!isNil(frontSettings.redirectUri)) {
    let url = frontSettings.redirectUri

    if (url.indexOf(':restaurantId') > -1 && !isNil(params) && !isNil(params.restaurantId)) {
      url = url.replace(':restaurantId', params.restaurantId)
    }

    if (url.indexOf(':tableNumber') > -1 && !isNil(params) && !isNil(params.tableNumber)) {
      url = url.replace(':tableNumber', params.tableNumber)
    }

    if (url.indexOf(':posCheckId') > -1 && !isNil(params) && !isNil(params.posCheckId)) {
      url = url.replace(':posCheckId', params.posCheckId)
    }

    if (url.indexOf(':at') > -1 && !isNil(params) && !isNil(params.at)) {
      url = url.replace(':at', params.at)
    }

    frontSettings.redirectUri = url
  }

  return frontSettings
}

export const formatAppConfigFrontSettings = createFormatter(formatRedirectUri)
