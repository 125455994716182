import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { withAppConfig } from 'pmt-modules/appConfig'
import { EventManager } from 'pmt-modules/event'
import {
  forgotPassword,
  resetForgotPasswordData,
  getForgotPasswordData,
  getForgotPasswordError,
  getForgotPasswordIsFetching,
} from 'pmt-modules/forgotPassword'
import { FormType, withForm } from 'pmt-modules/form'
import { OrderPluginUrlCheckerContainer, withOrderProperties } from 'pmt-modules/orderPlugin'
import { getRoute, redirectTo } from 'pmt-modules/routing'
import { withUserMe } from 'pmt-modules/userMe'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'

import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'

import OrderPage from '../../components/OrderPage'

import View from './View'

@withAppConfig
@withOrderProperties
@withRestaurant
@withUserMe
@withScrollToTop()
class ForgotPasswordPage extends React.PureComponent {
  constructor(props) {
    super(props)

    if (!isNil(props.me)) {
      props.redirectTo(getRoute('ORDER__USER__ACCOUNT'), {
        userId: props.me.id,
      })
    }
  }

  componentWillUnmount() {
    this.props.resetForgotPasswordData()
  }

  handleSubmit = () => {
    EventManager.dispatch(EventManager.Events.ON_FORGOT_PASSWORD_SUBMIT)

    const credentials = this.props.formData
    this.props.forgotPassword(credentials.username)
  }

  render() {
    const {
      location,
      forgotPasswordData,
      forgotPasswordError,
      formIsValid,
      orderProperties,
      restaurant,
      isFetching,
      width,
    } = this.props

    return (
      <OrderPage
        headerProps={{
          displayTitle: isWidthUp('md', width),
          displayUser: true,
        }}
        orderProperties={orderProperties}
        restaurant={restaurant}
        location={location}
      >
        <OrderPluginUrlCheckerContainer location={location}>
          <View
            isFetching={isFetching}
            forgotPasswordData={forgotPasswordData}
            forgotPasswordError={forgotPasswordError}
            formIsValid={formIsValid}
            onSubmit={this.handleSubmit}
          />
        </OrderPluginUrlCheckerContainer>
      </OrderPage>
    )
  }
}

const mapStateToProps = state => ({
  isFetching: getForgotPasswordIsFetching(state),
  forgotPasswordData: getForgotPasswordData(state),
  forgotPasswordError: getForgotPasswordError(state),
})

export default withForm(FormType.FORGOT_PASSWORD)(
  compose(
    connect(
      mapStateToProps,
      {
        redirectTo,
        forgotPassword,
        resetForgotPasswordData,
      }
    ),
    withWidth()
  )(ForgotPasswordPage)
)
