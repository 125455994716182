import {
  createApiEnumAction,
  createApiCallAction,
  //  createTestApiCallAction,
} from '../api/utils'

import isEmpty from 'lodash/isEmpty'

import UserApi from '../api/calls/UserApi'

import { replaceWith, getRoute } from '../routing'

//
//
//

export const CreateUserPostUserAction = createApiEnumAction('CREATE_USER::POST_USER')

/**
 *
 * @param  {[type]} rGroupId
 * @param  {[type]} user
 * @param  {Object} [props={}] can contains:
 *                             - companyId: default company for the user account
 */
export const postUser = (rGroupId, user, props = {}) => {
  const userToSend = user

  // the API allows a pro to create a user without an email, but it must be null, not empty
  if (isEmpty(userToSend.email)) {
    userToSend.email = null
  }

  return createApiCallAction(CreateUserPostUserAction, UserApi.postUser(rGroupId, userToSend), {
    rGroupId,
    userToSend,
    props,
  })
}

// export const postUser = (rGroupId, user) => createTestApiCallAction(
// CreateUserPostUserAction,
// // true,
// false,
// {
//   data: {
//     rGroupId,
//     user,
//   },
//   response: {
//     id: '5666904823824384',
//     ...user
//   },
//   error: {
//     code: '100',
//     message: 'An error occurred',
//   }
// }
// )

//
//
//

export const redirectToUserPage = (rGroupId, userId, userAccount = null): Route => {
  const companyId = userAccount ? userAccount.externalInfo.companyId : null

  //
  // if the use has a company we redirect to the card on the company, otherwise, we display the
  // default user card
  //
  let route = null
  if (!isEmpty(companyId)) {
    route = replaceWith(getRoute('BO_COMPANY_USER_CARD'), {
      companyId,
      userId,
    })
  } else {
    route = replaceWith(getRoute('BO_USER_CARD'), {
      userId,
    })
  }

  return route
}

//
//
//

export const StepActions = {
  REDIRECT_THIRD_STEP: 'REDIRECT::STEP::THIRD',
}

/**
 * Redirect to the third step.
 *
 */
export const redirectToUserCreationThirdStep = (rGroupId, user) =>
  redirectToUserPage(rGroupId, user.id)
