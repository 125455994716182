import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  cents: {
    // fontSize: '0.8em',
  },
  hr: {
    position: 'absolute',
    top: 2,
    height: 1,
    width: '100%',
    background: theme.pmt.colors.grey500,
    border: 'none',
    margin: theme.spacing(1, 0),
  },
  finalValue: {
    float: 'left',
  },
  baseValue: {
    float: 'left',
    position: 'relative',
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    fontSize: '0.8em',
    fontWeight: 400,
    color: theme.pmt.colors.grey500,
  },
  rowReverse: {
    flexDirection: 'row-reverse',
  },
  hrColumn: {
    top: 0,
  },
  column: {
    flexDirection: 'column',
  },
  columnReverse: {
    flexDirection: 'column-reverse',
  },
})

class Price extends React.Component {
  // Render component.
  render() {
    const {
      value,
      hasCartModifier,
      rowReverse,
      column,
      columnReverse,
      baseValue,
      className,
      classes,
    } = this.props

    const values = value.split(',')
    const baseValues = hasCartModifier ? baseValue.split(',') : null

    if (values.length === 1) {
      return (
        <div className={clsx('u-overflowHidden', className, classes.root)}>
          <span>{value}</span>
          {hasCartModifier && <span className={classes.baseValue}>{baseValue}</span>}
        </div>
      )
    } else {
      return (
        <div
          className={clsx('u-overflowHidden', className, classes.root, {
            [classes.rowReverse]: rowReverse,
            [classes.column]: column,
            [classes.columnReverse]: columnReverse,
          })}
        >
          <div className={classes.finalValue}>
            <span>{values[0]}</span>
            <span className={clsx(classes.cents)}>,{values[1]}</span>
          </div>
          {hasCartModifier && (
            <div className={classes.baseValue}>
              <span>{baseValues[0]}</span>
              <span className={clsx(classes.cents)}>,{baseValues[1]}</span>
              <hr
                className={clsx(classes.hr, {
                  [classes.hrColumn]: column || columnReverse,
                })}
              />
            </div>
          )}
        </div>
      )
    }
  }
}

Price.defaultProps = {
  value: '0,00 €',
  baseValue: '0,00 €',
  hasCartModifier: false,
  rowReverse: false,
  column: false,
  columnReverse: false,
}

Price.propTypes = {
  value: PropTypes.string.isRequired,
  hasCartModifier: PropTypes.bool,
  rowReverse: PropTypes.bool,
  column: PropTypes.bool,
  columnReverse: PropTypes.bool,
}

export default withStyles(styles)(Price)
