import { tr } from 'pmt-modules/i18n'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ui from 'pmt-modules/reduxUi'
import compose from 'recompose/compose'
import classNames from 'classnames'
import get from 'lodash/get'
import isNil from 'lodash/isNil'

import { getOrderSettings } from 'pmt-modules/appConfig'
import { EventManager } from 'pmt-modules/event'
import { getGeolocationComputeAddress } from 'pmt-modules/geolocation'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { getOrderProperties, getAppConfigFrontSettings } from 'pmt-modules/orderPlugin'
import { DEFAULT_GEOLOCATION_ID } from 'pmt-modules/geolocation/constants'
import UserType from 'pmt-modules/user/constants/UserType'

import Hidden from 'pmt-ui/Hidden'
import Price from 'pmt-ui/Price'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Tooltip from 'pmt-ui/Tooltip'
import ActionInfoOutline from 'pmt-ui/svg-icons/action/info-outline'
import Edit from 'pmt-ui/svg-icons/action/edit'

import BlockContainer from '../BlockContainer'
import SlotsContainer from '../Slots'
import TipWithIcon from '../TipWithIcon'
import { ButtonLink } from '../Button'

const styles = (theme) => ({
  root: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 500,
    },
  },
  blockContainerPrimary: {
    background: theme.pmt.colors.white,
    borderRadius: theme.shape.border.radius.main,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
    minHeight: '100%',
  },
  deliveryInfoContainer: {
    marginTop: 5,
  },
  iconInfo: {
    float: 'left',
    width: 16,
    height: 16,
  },
  opacity9: {
    opacity: 0.9,
  },
  loadingBlock: {
    background: 'none',
  },
  progress: {},
  white: {},
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  italic: {
    fontStyle: 'italic',
  },
  closingWarningContainer: {
    overflow: 'hidden',
    borderWidth: theme.shape.border.width.default,
    borderColor: theme.shape.border.color.default,
    borderStyle: 'solid',
    borderRadius: theme.shape.border.radius.content,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  tipWithIcon: {
    float: 'left',
    maxWidth: 'none',
    paddingBottom: 0,
    textAlign: 'left',
    color: theme.palette.primary.main,
  },
  tipWithIconText: {
    paddingLeft: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  divider: {
    width: '100%',
    height: 0,
    borderBottom: `1px solid ${theme.pmt.colors.white}`,
  },
  lineHeight20: {
    lineHeight: '20px',
  },
  lineHeight28: {
    lineHeight: '28px',
  },
  containerBorder: {
    /*borderRadius: theme.shape.border.radius.content,
    borderColor: theme.shape.border.color.default,
    borderStyle: 'solid',
    borderWidth: theme.shape.border.width.default,*/
    marginTop: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconModify: {
    color: theme.palette.primary.main,
    width: '10px !important',
    height: '10px !important',
  },
})

const RestaurantInformation = ({
  classes,
  orderProperties,
  restaurant,
  dueDateModificationEnabled,
  modeInformationTooltipEnabled,
  modeInformationText,
  orderAppConfig,
  computeAddress,
  updateUI,
  ui,
}) => (
  <React.Fragment>
    <Hidden smDown>
      <TypographyCustom
        type="226"
        component="h3"
        className={classNames('u-marginBottom20', classes.white)}
      >
        {tr('order.cart.choice')}
      </TypographyCustom>
    </Hidden>
    <React.Fragment>
      {/* ------------------------------------------------------------ */}
      {/* order mode info                                              */}
      {/* ------------------------------------------------------------ */}

      <TypographyCustom
        type="145"
        component="h3"
        className={classNames(classes.deliveryInfoContainer, classes.white, 'u-overflowHidden')}
      >
        <span className="u-floatLeft">
          {orderProperties.isDelivery
            ? tr('order.global.delivery')
            : orderProperties.isOnSite
            ? tr('order.global.on_site')
            : tr('order.global.take_away')}
          {orderProperties.isOnSite &&
            orderAppConfig.hasTableNumber &&
            tr('order.sideSummary.table', {
              tableNumber: orderProperties.tableNumber,
            })}
        </span>

        {modeInformationTooltipEnabled && (
          <Hidden mdUp>
            <Tooltip
              id="tooltip-bottom"
              title={modeInformationText}
              placement="bottom"
              // This trick is for mobile only
              // The tooltip does not react when we simply click it (only long click)
              // So we toggle it by clicking it via redux-ui :D
              onClick={() => {
                updateUI({ showTooltip: !ui.showTooltip })
              }}
              open={ui.showTooltip}
            >
              <ActionInfoOutline className={classNames('u-marginLeft5', classes.iconInfo)} />
            </Tooltip>
          </Hidden>
        )}
      </TypographyCustom>

      {/* ------------------------------------------------------------ */}
      {/* delivery info                                                */}
      {/* ------------------------------------------------------------ */}

      {orderProperties.isDelivery && (
        <React.Fragment>
          <TypographyCustom
            type="124"
            component="p"
            className={classNames('u-marginTop5', classes.white)}
          >
            {!isNil(orderProperties.deliveryAddress) ? (
              <React.Fragment>{orderProperties.deliveryAddress.formattedAddress}</React.Fragment>
            ) : (
              <React.Fragment>
                {computeAddress ? computeAddress.formattedAddress : ''}
              </React.Fragment>
            )}
          </TypographyCustom>
        </React.Fragment>
      )}

      {modeInformationText && (
        <Hidden smDown>
          <TypographyCustom
            type="124"
            component="p"
            className={classNames(
              classes.deliveryInfoContainer,
              classes.opacity9,
              classes.white,
              classes.italic
            )}
          >
            <ActionInfoOutline className={classNames('u-marginRight5', classes.iconInfo)} />
            {modeInformationText}
          </TypographyCustom>
        </Hidden>
      )}
    </React.Fragment>

    <div className={classes.containerBorder}>
      {/* ------------------------------------------------------------ */}
      {/* restaurant name                                              */}
      {/* ------------------------------------------------------------ */}

      <TypographyCustom type="145" component="p" className={classNames(classes.white)}>
        {restaurant.name}
        <Hidden mdUp>
          <br />
          {` ${restaurant.formattedAddress}`}
        </Hidden>
      </TypographyCustom>

      {/* ------------------------------------------------------------ */}
      {/* order with table number                                      */}
      {/* ------------------------------------------------------------ */}

      {(orderProperties.isTakeAway ||
        (orderProperties.isOnSite && !orderProperties.mustChooseTableNumber)) && (
        <Hidden smDown>
          <TypographyCustom
            type="124"
            component="p"
            className={classNames('u-marginTop2', classes.grey500)}
          >
            {({ className }) => (
              <p className={className}>
                {restaurant.address.street}
                <br />
                {`${restaurant.address.postCode} ${restaurant.address.city}`}
              </p>
            )}
          </TypographyCustom>
        </Hidden>
      )}
    </div>

    {/* ------------------------------------------------------------ */}
    {/* slots                                                        */}
    {/* ------------------------------------------------------------ */}

    {(orderProperties.canOrderUsingSlots ||
      (!orderProperties.mustChooseTableNumber && orderProperties.canOrderUsingAsap)) && (
      <div className={classNames(classes.containerBorder, classes.container)}>
        <SlotsContainer
          type={SlotsContainer.Type.SMALL}
          showSlots={!orderProperties.isAsap && isNil(orderProperties.slot)}
          orderProperties={orderProperties}
          dueDateClassnameColor={classes.white}
          modificationEnabled={dueDateModificationEnabled && orderProperties.canOrderUsingSlots}
          classes={{ root: 'u-overflowHidden' }}
          checkCartDiff
        />
      </div>
    )}

    {/* ------------------------------------------------------------ */}
    {/* closing hour                                                 */}
    {/* ------------------------------------------------------------ */}

    {!orderAppConfig.bypassOpeningHours &&
      get(orderProperties, 'closingHourFormatted.show', false) && (
        <div className={classes.closingWarningContainer}>
          <TipWithIcon
            classes={{ root: classes.tipWithIcon, tipText: classes.tipWithIconText }}
            icon={<ActionInfoOutline />}
            label={tr(`order.sideInformations.closingHour.${orderProperties.mode}`, {
              closingHour: orderProperties.closingHourFormatted.time.hourFormatted,
              closingMinute: orderProperties.closingHourFormatted.time.minuteFormatted,
              lastTimeToOrderHour:
                orderProperties.closingHourFormatted.lastTimeToOrder.hourFormatted,
              lastTimeToOrderMinute:
                orderProperties.closingHourFormatted.lastTimeToOrder.minuteFormatted,
            })}
          />
        </div>
      )}
  </React.Fragment>
)

const CartResume = ({ classes, orderProperties }) => (
  <React.Fragment>
    <Hidden smDown>
      <div className={classNames('u-marginTop20 u-marginBottom20', classes.divider)} />
    </Hidden>

    <Hidden smDown>
      <TypographyCustom
        type="226"
        component="h3"
        className={classNames('u-marginBottom20', classes.white)}
      >
        {tr('order.sideSummary.cart')}
      </TypographyCustom>

      {/* ------------------------------------------------------------ */}
      {/* total cart and fees                                          */}
      {/* ------------------------------------------------------------ */}

      <TypographyCustom
        type="145"
        component="div"
        className={classNames(classes.lineHeight20, 'u-marginTop20', classes.white)}
      >
        {tr('order.sideSummary.total')}
        <TypographyCustom type="145" component="div" className="u-floatRight" skipColor>
          <Price value={orderProperties.totalCartPriceAndFeesFormatted} />
        </TypographyCustom>
      </TypographyCustom>
    </Hidden>
  </React.Fragment>
)

const UserInformations = ({
  classes,
  orderProperties,
  user,
  redirectTo,
  userProfileModificationEnabled,
}) => (
  <React.Fragment>
    <Hidden smDown>
      <div
        className={classNames(
          classes.hiddenMobile,
          'u-marginTop20 u-marginBottom20',
          classes.divider
        )}
      />

      <TypographyCustom
        type="226"
        component="h3"
        className={classNames(
          classes.hiddenMobile,
          'u-marginBottom20',
          classes.white,
          classes.center
        )}
      >
        {tr('order.sideSummary.user_informations')}
        {userProfileModificationEnabled && (
          <ButtonLink
            classes={{ root: classNames(classes.white, 'u-floatRight') }}
            disabled={isNil(orderProperties.mode)}
            onClick={() => {
              EventManager.dispatch(EventManager.Events.ON_CHANGE_SLOT)
              redirectTo(getRoute('USER_PROFILE'), {
                userId: user.id,
              })
            }}
            icon={<Edit className={classes.iconModify} />}
            label={
              <TypographyCustom type="146" skipColor>
                {tr('order.global.modify')}
              </TypographyCustom>
            }
          />
        )}
      </TypographyCustom>
      <TypographyCustom
        type="145"
        component="p"
        className={classNames(classes.lineHeight20, 'u-marginTop20', classes.white)}
      >
        {`${user.firstName} ${user.lastName}`}
      </TypographyCustom>
      <TypographyCustom
        type="145"
        component="p"
        className={classNames(classes.lineHeight20, 'u-marginTop5', classes.white)}
      >
        {user.email}
      </TypographyCustom>
      <TypographyCustom
        type="145"
        component="p"
        className={classNames(classes.lineHeight20, 'u-marginTop5', classes.white)}
      >
        {user.phone}
      </TypographyCustom>
    </Hidden>
  </React.Fragment>
)

class SideSummary extends React.PureComponent {
  render() {
    const {
      classes,
      frontSettings,
      orderProperties,
      restaurant,
      user,
      dueDateModificationEnabled,
      cartResumeEnabled,
      userProfileModificationEnabled,
      modeInformationTooltipEnabled,
      modeInformationText,
      orderAppConfig,
      computeAddress,
      updateUI,
      ui,
      redirectTo,
      userInformationsEnabled,
    } = this.props

    return (
      <div className={classes.root}>
        <BlockContainer className={classes.blockContainerPrimary}>
          {!isNil(orderProperties.mode) && !isNil(restaurant) ? (
            <React.Fragment>
              <RestaurantInformation
                classes={classes}
                orderProperties={orderProperties}
                computeAddress={computeAddress}
                restaurant={restaurant}
                dueDateModificationEnabled={dueDateModificationEnabled}
                modeInformationTooltipEnabled={modeInformationTooltipEnabled}
                modeInformationText={modeInformationText}
                orderAppConfig={orderAppConfig}
                updateUI={updateUI}
                ui={ui}
              />

              {cartResumeEnabled && frontSettings.display.totalPrices && (
                <CartResume classes={classes} orderProperties={orderProperties} />
              )}

              {userInformationsEnabled && user && user.type !== UserType.INCOGNITO && (
                <UserInformations
                  classes={classes}
                  user={user}
                  orderProperties={orderProperties}
                  redirectTo={redirectTo}
                  userProfileModificationEnabled={userProfileModificationEnabled}
                />
              )}
            </React.Fragment>
          ) : (
            <LoadingBlock
              show
              classes={{
                loadingBlock: classNames('u-marginTop20', classes.loadingBlock),
                circularProgress: classes.progress,
              }}
            />
          )}
        </BlockContainer>
      </div>
    )
  }
}

SideSummary.defaultProps = {
  dueDateModificationEnabled: true,
  cartResumeEnabled: false,
  modeInformationText: false,
  modeInformationTooltipEnabled: false,
}

SideSummary.propTypes = {
  dueDateModificationEnabled: PropTypes.bool,
  cartResumeEnabled: PropTypes.bool,
  modeInformationTooltipEnabled: PropTypes.bool,
  modeInformationText: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
}

const mapStateToProps = (state, props) => ({
  orderProperties: getOrderProperties(state),
  orderAppConfig: getOrderSettings(state),
  computeAddress: getGeolocationComputeAddress(state, { geolocationId: DEFAULT_GEOLOCATION_ID }),
  frontSettings: getAppConfigFrontSettings(state),
})

export default compose(
  connect(mapStateToProps, {
    redirectTo,
  }),
  ui({
    state: {
      showTooltip: false,
    },
  }),
  withStyles(styles)
)(SideSummary)
