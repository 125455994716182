import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'
import isEqual from 'lodash/isEqual'

import { getCategoryFromCatalog, getMenuFromCatalog } from 'pmt-modules/catalog/utils'

import { findOnArray } from 'pmt-utils/array'

import { makeGetSelectedMenu, getOrderMenu } from '../selectors'
import { selectMenu } from '../actions'

/**
 * @specs N/A
 *
 * A HOC that returns the selected Menu from store
 *
 * Requirements:
 * - catalog
 *
 */
class SelectedMenuContainer extends React.PureComponent {
  constructor(props) {
    super(props)

    if (!isNil(props.catalog)) {
      this.openMenuFromProps(props)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (!isNil(nextProps.catalog) && !isEqual(this.props.catalog, nextProps.catalog)) ||
      this.props.menuId !== nextProps.menuId
    ) {
      this.openMenuFromProps(nextProps)
    }
  }

  openMenuFromProps(props) {
    let menu = null
    if (isNil(props.selectedMenu)) {
      if (!isNil(props.selectedCategory)) {
        const category = getCategoryFromCatalog(props.catalog, props.selectedCategory.id)
        if (category) {
          menu = findOnArray(category.items, menu => menu.id === props.menuId)
        } else {
          // menu not found for category, redirect to somewhere ?
          return
        }
        // no category selected, we need to find the menu from each category
      } else {
        menu = getMenuFromCatalog(props.catalog, props.menuId)
      }

      if (isNil(menu)) {
        // redirectTo somewhere when menu not found
        return
      }

      props.selectMenu(menu, {
        restaurantId: props.catalog.restaurantId,
      })
    }
  }

  render() {
    const { children, ...otherProps } = this.props

    return children({
      // does not really tell if we are loading the menu (handled by withCatalog)
      // could be a 404
      isLoadingMenu: isNil(otherProps.selectedMenu),
      ...otherProps,
    })
  }
}

SelectedMenuContainer.propTypes = {
  catalog: PropTypes.object.isRequired,

  // children MUST be a function
  children: PropTypes.func.isRequired,
}

const makeMapStateToProps = () => {
  const getSelectedMenu = makeGetSelectedMenu()

  const mapStateToProps = (state, props) => {
    return {
      selectedMenu: getSelectedMenu(state, props),
      orderMenu: getOrderMenu(state),
    }
  }
  return mapStateToProps
}

export default connect(makeMapStateToProps, {
  selectMenu,
})(SelectedMenuContainer)
