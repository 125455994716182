import isNil from 'lodash/isNil'

import { getProductFromCatalog, getMenuFromCatalog } from 'pmt-modules/catalog/utils'

export const getUnavailableItemsFromCart = (cartItemList, catalog) => {
  let unavailableItems = []
  cartItemList.forEach(item => {
    let itemFromCatalog = null
    if (item.isProduct) {
      itemFromCatalog = getProductFromCatalog(catalog, item.id)
    } else {
      itemFromCatalog = getMenuFromCatalog(catalog, item.id)
    }

    if (!isNil(itemFromCatalog) && !itemFromCatalog.available) {
      unavailableItems.push(item)
    }
  })

  return unavailableItems
}

export const removeUnavailableItemsFromList = (cartItemList, unavailableItemList) => {
  unavailableItemList.forEach(unavailableItem => {
    cartItemList = cartItemList.filter(item => item.orderId !== unavailableItem.orderId)
  })

  return cartItemList
}
