export const FeeSettingStatus = {
  ENABLED: 'ENABLED',

  DISABLED: 'DISABLED',
}

export const FeeType = {
  DELIVERY: 'DELIVERY',

  SERVICE: 'SERVICE',
}
