import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { getAppConfigFrontSettings } from 'pmt-modules/orderPlugin'

import { TypographyCustom } from 'pmt-ui/Typography'
import Hidden from 'pmt-ui/Hidden'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import { existsOnArray } from 'pmt-utils/array'

import Button from 'app/components/Button'
import Address from 'app/components/Address'

class RegisteredAddresses extends React.Component {
  componentWillUpdate(nextProps) {
    const { selectedDeliveryAddress, userAddressListOutOfRange } = nextProps

    // handle when the address could have been edited and is no longer on the range.
    if (selectedDeliveryAddress) {
      const existsOnOutOfRange = existsOnArray(
        userAddressListOutOfRange,
        address => address.id === selectedDeliveryAddress.id
      )

      if (existsOnOutOfRange) {
        this.props.onResetDeliveryAddress()
      }
    }
  }

  render() {
    const {
      classes,
      // --
      selectedDeliveryAddress,
      onSelectDeliveryAddress,
      onResetDeliveryAddress,
      // --
      frontSettings,
      isFetchingUserAddressList,
      onSubmit,
      userAddressListInRange,
      userAddressListOutOfRange,
      user,
      onEditAddress,
      onCloseEditAddress,
      addressToEdit,
    } = this.props

    return (
      <div className={classes.registered_addresses}>
        <TypographyCustom type="164" component="h2" className={classes.subtitle}>
          {tr('order.addresses.registered_addresses')}
        </TypographyCustom>
        <div className={classes.subContainerWidth}>
          {isFetchingUserAddressList || isNil(userAddressListInRange) ? (
            <LoadingBlock show />
          ) : isEmpty(userAddressListInRange) ? (
            <TypographyCustom component="p" type="144">
              {tr('order.addresses.in_range_missing')}
            </TypographyCustom>
          ) : (
            <React.Fragment>
              {userAddressListInRange.map((address, index) => (
                <Address
                  key={index}
                  label={address.name}
                  labelEdit={tr('order.addresses.form.edit.title')}
                  defaultData={address}
                  address={address}
                  user={user}
                  className={'u-marginBottom10'}
                  selected={
                    !isNil(selectedDeliveryAddress) && selectedDeliveryAddress.id === address.id
                  }
                  showEditAddressForm={addressToEdit && addressToEdit.id === address.id}
                  onSelect={() => {
                    onSelectDeliveryAddress(address)
                  }}
                  onClose={onCloseEditAddress}
                  handleEditAction={() => {
                    onEditAddress(address)
                  }}
                  handleEditActionSuccess={onCloseEditAddress}
                />
              ))}
              <Hidden xsDown>
                <div className="u-overflowHidden">
                  <Button
                    size="large"
                    disabled={isNil(selectedDeliveryAddress)}
                    classes={{ root: classNames(classes.bigButton, 'u-floatRight u-marginTop15') }}
                    label={tr(
                      frontSettings.display.paymentPage
                        ? 'order.global.validate'
                        : 'order.global.validate_and_order'
                    )}
                    onClick={onSubmit}
                  />
                </div>
              </Hidden>
            </React.Fragment>
          )}
        </div>
        {(isFetchingUserAddressList ||
          (!isFetchingUserAddressList && !isEmpty(userAddressListOutOfRange))) && (
          <div className={classNames(classes.subContainerWidth, 'u-marginTop40')}>
            <TypographyCustom
              type="124"
              component="p"
              className={classNames(classes.subtitle, classes.italic)}
            >
              {tr('order.addresses.out_of_zone')}
            </TypographyCustom>
            {isFetchingUserAddressList || isNil(userAddressListOutOfRange) ? (
              <LoadingBlock show />
            ) : (
              <React.Fragment>
                {userAddressListOutOfRange.map((address, index) => (
                  <Address
                    key={index}
                    disabled
                    label={address.name}
                    address={address}
                    className={'u-marginBottom10'}
                    user={user}
                    showEditAddressForm={addressToEdit && addressToEdit.id === address.id}
                    onSelect={onResetDeliveryAddress}
                    onClose={onCloseEditAddress}
                    handleEditAction={() => {
                      onEditAddress(address)
                    }}
                    handleEditActionSuccess={onCloseEditAddress}
                  />
                ))}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  frontSettings: getAppConfigFrontSettings(state),
})

export default connect(mapStateToProps)(RegisteredAddresses)
