import React from 'react'
import classNames from 'classnames'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Button from 'pmt-ui/Button'
import ActionInfoOutline from 'pmt-ui/svg-icons/action/info-outline'

import BlockContainer from '../../BlockContainer'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    flex: 1,
    zIndex: 99,
    [theme.breakpoints.down('sm')]: {
      // keep fixed position so it doesn't take into account relative parent
      // this allows to handle custom header/footer integration
      position: 'fixed',
      top: 0,
      left: 0,
      padding: 0,
      background: theme.pmt.colors.greyBackground,
    },
    // custom css for plugin with header/footer
    ...theme.pmt.appConfig.errorLayout,
  },
  blockContainer: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  error: {
    width: '100%',
    position: 'relative',
  },
  iconInfo: {
    width: 30,
    height: 30,
    marginBottom: theme.spacing(1),
  },
  red800: {
    color: theme.pmt.colors.red800,
  },
  button: {
    position: 'absolute',
    left: '50%',
    bottom: 24,
    transform: 'translateX(-50%)',
  },
})

class ErrorLayout extends React.PureComponent {
  render() {
    const { error, buttonLabel, buttonAction, classes } = this.props

    return (
      <div className={classes.root}>
        <BlockContainer type={BlockContainer.Type.WHITE} className={classes.blockContainer}>
          <div className={classNames(classes.error, 'u-textAlignCenter')}>
            <ActionInfoOutline className={classNames(classes.iconInfo, classes.red800)} />
            <TypographyCustom type="204" align="center" className={classes.red800}>
              {error}
            </TypographyCustom>
          </div>
          {buttonAction && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={buttonAction}
            >
              {buttonLabel}
            </Button>
          )}
        </BlockContainer>
      </div>
    )
  }
}

export default withStyles(styles)(ErrorLayout)
