import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'

import { orderProductFormatter } from 'pmt-modules/orderProduct/format'
import { orderMenuFormatter } from 'pmt-modules/orderMenu/format'

export const formatItem = item => {
  if (item.isProduct) {
    return orderProductFormatter(item)
  } else if (item.isMenu) {
    return orderMenuFormatter(item)
  }
  return item
}
export const formatItemList = createListFormatter(formatItem)

/**
 * we just move the selected products (all the products selected in the main & sub categories of part)
 * to replace the products in the main parts for order preview
 */
export const formatItemForOrderPreview = item => {
  item = formatItem(item)

  if (item.parts) {
    item.parts = item.parts.map(part => {
      part.products = part.selectedProducts
      return part
    })
  }

  return item
}

export const formatItemListForOrderPreview = createListFormatter(formatItemForOrderPreview)

export const cartFormatter = createFormatter(createSubObjectFormatter('itemList', formatItemList))
