import React from 'react'
import PropTypes from 'prop-types'

import { withScriptjs } from 'react-google-maps'

class GoogleMapsScriptJS extends React.Component {
  render() {
    const { children, ...otherProps } = this.props

    return children({
      ...otherProps,
    })
  }
}

GoogleMapsScriptJS.propTypes = {
  googleMapURL: PropTypes.string,
}

export default withScriptjs(GoogleMapsScriptJS)
