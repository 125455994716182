import { createSimpleReducer } from '../redux'

import { combineReducers } from 'redux'

import {
  CreateUserPostUserAction,
} from './actions'

export * from './actions'
export * from './constants'
export * from './middlewares'
export * from './selectors'

const userReducer = createSimpleReducer(CreateUserPostUserAction)

export const userCreationReducer = combineReducers({
  user: userReducer,
})
