import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { getOrderProperties } from '../../selectors'

/**
 * @specs N/A
 *
 * A HOC that give all order's properties
 *
 */
const OrderPropertiesContainer = ({
  OrderPropertiesWrappedComponent,
  orderProperties,
  children,
  ...otherProps
}) =>
  isNil(OrderPropertiesWrappedComponent) ? (
    children({
      orderProperties,
      restaurantId: orderProperties.restaurantId,
      ...otherProps,
    })
  ) : (
    <OrderPropertiesWrappedComponent
      orderProperties={orderProperties}
      restaurantId={orderProperties.restaurantId}
      {...otherProps}
    />
  )

const mapStateToProps = state => ({
  orderProperties: getOrderProperties(state),
})

export default connect(
  mapStateToProps,
  {}
)(OrderPropertiesContainer)
