import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from 'pmt-ui/styles'
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '../svg-icons/navigation/close'
// import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

const styles = theme => ({
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
})

class SnackbarContainer extends React.Component {
  state = {
    open: false,
  }

  handleShow = () => {
    this.setState({
      open: true,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  render() {
    const { message, children, classes, autoHideDuration, action, withCloseAction } = this.props

    let actions = [...(action || [])]
    if (withCloseAction) {
      actions.push(
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={this.handleClose}
        >
          <CloseIcon />
        </IconButton>
      )
    }

    return (
      <React.Fragment>
        {children({
          onShow: this.handleShow,
          open: this.state.open,
        })}

        <Snackbar
          message={message}
          action={action}
          autoHideDuration={autoHideDuration}
          open={this.state.open}
          onClose={this.handleClose}
        />
      </React.Fragment>
    )
  }
}

SnackbarContainer.defaultProps = {
  autoHideDuration: 2000,
  action: null,
  withCloseAction: false,
}

SnackbarContainer.propTypes = {
  message: PropTypes.string.isRequired,

  withCloseAction: PropTypes.bool,
}

export default withStyles(styles)(SnackbarContainer)
