import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import uniq from 'lodash/uniq'

import { createFormatter, createSubObjectFormatter, createListFormatter } from 'pmt-utils/format'
import { addToArray } from 'pmt-utils/array'
import { formatPriceWithCurrency } from 'pmt-utils/currency'

import { formatProduct } from './product'
import { mergeProductsAndMenus } from './item'
import { CatalogItemType } from '../constants'

const orderFromAvailable = (categoryFirst, categorySecond) => {
  return categoryFirst.available === categorySecond.available ? 0 : categoryFirst.available ? -1 : 1
}

const recursiveFormatSubcategoriesProductsAndMenus = category => {
  category.isProduct = false
  category.isMenu = false
  category.isCategory = true
  category.type = CatalogItemType.CATEGORY

  if (!isNil(category.categories) && !isEmpty(category.categories)) {
    category.categories = category.categories.sort((categoryFirst, categorySecond) => {
      return orderFromAvailable(categoryFirst, categorySecond)
    })

    category.categories = category.categories.map(recursiveFormatSubcategoriesProductsAndMenus)
  }

  let categoryTemp = mergeProductsAndMenus(category, formatProduct, formatMenu)

  const itemsLength = !isNil(categoryTemp.items) ? categoryTemp.items.length : 0
  const subcategoriesLength = !isNil(categoryTemp.categories) ? categoryTemp.categories.length : 0
  categoryTemp.totalItemsAndSubcategories = itemsLength + subcategoriesLength

  return categoryTemp
}

export const formatCategory = createFormatter(recursiveFormatSubcategoriesProductsAndMenus)
export const formatCategories = createFormatter(
  createSubObjectFormatter('categories', createListFormatter(formatCategory))
)

// Menu formatters
// we move this code here because a menu part now is like a category,
// so to stop circular depedency, we have to put it here
const formatPartProducts = part => mergeProductsAndMenus(part, formatProduct)

const formatPartCategory = part => {
  if (part.rootCategory) {
    let partFormatted = formatCategories(part.rootCategory, {
      categories: part.rootCategory.categories,
    })
    if (!isNil(partFormatted.categories)) {
      partFormatted.categories = partFormatted.categories.sort((categoryFirst, categorySecond) => {
        return orderFromAvailable(categoryFirst, categorySecond)
      })
    }
    partFormatted.id = part.id
    partFormatted.name = part.name
    partFormatted.mandatory = part.mandatory
    partFormatted.min = part.min
    partFormatted.max = part.max
    partFormatted.additionalPrice = part.additionalPrice
    part = partFormatted
  }

  return part
}

const formatPart = createFormatter(formatPartCategory, formatPartProducts)
export const formatParts = createListFormatter(formatPart)

const formatMenuData = menu => {
  menu.isMenu = true
  menu.isProduct = false
  menu.isCategory = false
  menu.type = CatalogItemType.MENU

  menu.priceFormatted = formatPriceWithCurrency(menu.price)

  // used to know if we need to display the item directly on the detail view dialog
  menu.isComplexItem = true

  menu.hasImage = !isEmpty(menu.image)

  menu.comment = menu.comment || ''
  menu.hasComment = !isEmpty(menu.comment)
  return menu
}

const formatDietaryInfoForPartCategory = categories => {
  const allergens = []
  forEach(categories, category => {
    forEach(category.products, product => {
      if (product.hasAllergens) {
        addToArray(allergens, get(product, 'formattedAllergens', []))
      }
    })

    if (!isEmpty(category.categories)) {
      addToArray(allergens, formatDietaryInfoForPartCategory(category.categories))
    }
  })
  return allergens
}

const formatDietaryInfo = menu => {
  const allergens = [...get(menu, 'dietaryInfo.allergens', [])]
  menu.hasAllergens = !isEmpty(allergens)
  menu.hasPartWithAllergens = false

  forEach(menu.parts, part => {
    part.hasAllergens = false
    forEach(part.products, product => {
      if (product.hasAllergens) {
        part.hasAllergens = true
        menu.hasPartWithAllergens = true
        addToArray(allergens, get(product, 'formattedAllergens', []))
      }
    })

    addToArray(allergens, formatDietaryInfoForPartCategory(part.categories))
  })

  menu.formattedAllergens = uniq(allergens)
  menu.hasAllergens = !isEmpty(menu.formattedAllergens)

  return menu
}

export const formatMenu = createFormatter(
  formatMenuData,
  createSubObjectFormatter('parts', formatParts),
  formatDietaryInfo
)
