import React from 'react'

import UserMeContainer from './index'

/**
 * Add the UserMeContainer as HOC for the given component
 */
const withUserMe = (UserMeWrappedComponent) => {
  return (props) => (
    <UserMeContainer
      UserMeWrappedComponent={UserMeWrappedComponent}
      {...props}
    />
  )
}

export default withUserMe
