import React from 'react'
import clsx from 'clsx'

import './style.scss'

const AmountCircle = ({ value, isSelected, onClick, size = 70 }) => (
  <div
    onClick={onClick}
    className={clsx(`amount__circle borderPrimary size${size}`, {
      backgroundPrimary: isSelected,
      'u-textColorWhite': isSelected,
      colorPrimary: !isSelected,
    })}
  >
    <div className="amount__circle__value">
      {value}
      &euro;
    </div>
  </div>
)

export default AmountCircle
