import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import ui from 'pmt-modules/reduxUi'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { postOrder } from 'pmt-modules/orderPost'
import { EventManager } from 'pmt-modules/event'
import { getItemListFromCart, getCartData } from 'pmt-modules/cart'
import { setPaymentMethod } from 'pmt-modules/orderFront'
import { getOrderPreview } from 'pmt-modules/orderPreview'
import { makeGetCurrentOrderSettings, PaymentMethodsAllowed } from 'pmt-modules/orderSettings'
import { getOrderData } from 'pmt-modules/orderPlugin'

import Grid from 'pmt-ui/Grid'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import BlockContainer from 'app/components/BlockContainer'
import Breadcrumb from 'app/components/Breadcrumb'
import Button from 'app/components/Button'

import PaymentMethods from './PaymentMethods'
import PaymentDetails from './PaymentDetails'
import { getPublicUrl } from 'pmt-modules/environment'

const styles = (theme) => ({
  mainContainer: {
    borderRadius: theme.shape.border.radius.main,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },
  height100: {
    height: '100%',
  },
  alcoolAlert: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',

      '& img': {
        width: '100%',
      },
    },
  },
})

const breadcrumbElements = [
  {
    name: 'CATALOG',
    active: true,
    hiddenMobile: true,
  },
  {
    name: 'CART',
    active: true,
  },
  {
    name: 'INFORMATIONS',
    active: true,
  },
  {
    name: 'DELIVERY_ADDRESS',
    active: true,
  },
  {
    name: 'PAYMENT',
    active: true,
    current: true,
  },
]

class PaymentBlock extends React.PureComponent {
  handlePostOrderOnFreeOrder = () => {
    EventManager.dispatch(EventManager.Events.ON_PAYMENT_SUBMIT, {
      items: this.props.itemListFromCart,
      restaurant: this.props.restaurant,
    })

    const { postOrder, orderData } = this.props

    if (!isNil(orderData)) {
      postOrder(orderData)
    }
  }

  componentDidMount() {
    const { orderProperties, setPaymentMethod } = this.props

    // If total is equal to 0 and we're not in userAccount payment we force IRL payment method
    if (orderProperties.totalCartPriceAndFees === 0 && !orderProperties.isUserAccountMethod) {
      setPaymentMethod(PaymentMethodsAllowed.IRL)
    }
  }

  render() {
    const {
      orderProperties,
      cartData,
      user,
      isFetchingCreditCards,
      creditCards,
      selectedCreditCard,
      orderData,
      setPaymentMethod,
      onToggleCreditCard,
      onClickDeleteCreditCard,
      onResetUserCard,
      restaurant,
      currentOrderSettings,
      classes,
      ui,
      updateUI,
      canRegisterCardWithName,
    } = this.props

    return (
      <BlockContainer
        classes={{ root: classNames(classes.height100, classes.mainContainer, 'u-overflowHidden') }}
      >
        {restaurant && (
          <React.Fragment>
            <Breadcrumb className="u-marginBottom30" elements={breadcrumbElements} />
            <TypographyCustom type="226" component="h2" className="u-marginBottom20">
              {tr('order.payment.title')}
            </TypographyCustom>
            <Grid spacing={5} container>
              <Grid item xs={12} lg={6}>
                <PaymentDetails amountToPay={orderProperties.totalCartPriceAndFeesFormatted} />
              </Grid>
              <Grid item xs={12} lg={6}>
                {orderProperties.totalCartPriceAndFees > 0 ||
                currentOrderSettings.isUserAccountPaymentAllowed ? (
                  <PaymentMethods
                    orderProperties={orderProperties}
                    ui={ui}
                    updateUI={updateUI}
                    isFetchingCreditCards={isFetchingCreditCards}
                    creditCards={creditCards}
                    selectedCreditCard={selectedCreditCard}
                    currentOrderSettings={currentOrderSettings}
                    setPaymentMethod={setPaymentMethod}
                    orderData={orderData}
                    onToggleCreditCard={onToggleCreditCard}
                    onClickDeleteCreditCard={onClickDeleteCreditCard}
                    onResetUserCard={onResetUserCard}
                    restaurant={restaurant}
                    user={user}
                    totalCartPriceAndFees={orderProperties.totalCartPriceAndFees}
                    amountToPay={orderProperties.totalCartPriceAndFeesFormatted}
                    canRegisterCardWithName={canRegisterCardWithName}
                  />
                ) : (
                  <Button
                    type="button"
                    size="large"
                    classes={{ root: 'u-sizeFullWidth' }}
                    label={tr('order.payment.finalize_order')}
                    onClick={this.handlePostOrderOnFreeOrder}
                  />
                )}
              </Grid>
              {orderData.withFrenchBreathAnalyzer && cartData.containsAlcohol && (
                <Grid item xs={12} className={classes.alcoolAlert}>
                  <img
                    src={`${getPublicUrl()}/Soufflez vous saurez_350x120.png`}
                    alt="Éthylotest en vente sur ce site"
                  />
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        )}
      </BlockContainer>
    )
  }
}

const mapStateToProps = (state, props) => {
  const getCurrentOrderSettings = makeGetCurrentOrderSettings()

  return {
    currentOrderSettings: getCurrentOrderSettings(state, {
      orderMode: props.orderProperties.mode,
    }),
    itemListFromCart: getItemListFromCart(state),
    orderPreview: getOrderPreview(state),
    orderData: getOrderData(state),
    cartData: getCartData(state),
  }
}

export default compose(
  ui({
    state: {
      subsidyChecked: false,
      balanceChecked: false,
      showCreditCardForm: false,
    },
  }),
  connect(mapStateToProps, {
    setPaymentMethod,
    postOrder,
  }),
  withStyles(styles)
)(PaymentBlock)
