import React from 'react'

const StepContent = ({ show, children }) => (
  !show ? null : (
    <div>
      {children}
    </div>
  )
)

export default StepContent
