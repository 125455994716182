import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { EventManager, SendEventContainer } from 'pmt-modules/event'
import { withAppConfig } from 'pmt-modules/appConfig'
import { withUserMe } from 'pmt-modules/userMe'
import { getItemListFromCart } from 'pmt-modules/cart'
import { OrderPluginUrlCheckerContainer, withOrderProperties } from 'pmt-modules/orderPlugin'

import GoogleMapsApiContainer from 'pmt-modules/google/components/GoogleMapsApiContainer'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'

import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'

import OrderPage from '../../components/OrderPage'
import View from './View'

/**
 * @specs N/A
 */
@withAppConfig
@withOrderProperties
@withRestaurant
@withUserMe
@withScrollToTop()
class DeliveryAddressPage extends React.Component {
  render() {
    const { restaurant, location, isFetchingUserMe, me, orderProperties, cartItemList } = this.props

    return (
      <OrderPage orderProperties={orderProperties} restaurant={restaurant} location={location}>
        <OrderPluginUrlCheckerContainer location={location} verifyMode>
          <GoogleMapsApiContainer>
            <React.Fragment>
              <SendEventContainer
                send={orderProperties && !isNil(orderProperties.restaurant)}
                event={EventManager.Events.ON_PAGE_LOADED}
                eventProps={() => ({
                  items: cartItemList,
                  restaurant: orderProperties.restaurant,
                  dueDate: orderProperties.dueDate,
                })}
              >
                <View
                  orderProperties={orderProperties}
                  restaurant={restaurant}
                  onSelectMode={this.handleSelectMode}
                  isFetchingUser={isFetchingUserMe}
                  user={me}
                />
              </SendEventContainer>
            </React.Fragment>
          </GoogleMapsApiContainer>
        </OrderPluginUrlCheckerContainer>
      </OrderPage>
    )
  }
}

const mapStateToProps = state => ({
  cartItemList: getItemListFromCart(state),
})

export default connect(
  mapStateToProps,
  {}
)(DeliveryAddressPage)
