import Immutable from 'immutable'
import forEach from 'lodash/forEach'
import isUndefined from 'lodash/isUndefined'

import { cartModifierActions } from 'pmt-modules/cart'
import { createReducer } from 'pmt-modules/redux'

import { isLastPreview } from './utils'
import { PostOrderPreviewAction } from './actions'

export * from './actions'
// export * from './components'
export * from './constants'
export * from './selectors'
export * from './middlewares'

const DEFAULT = Immutable.fromJS({
  sendDate: null,
  modificationDate: null,
  order: null,
  error: null,
  isFetching: false,
  verifications: null,
})

const setIsFetching = state => state.set('isFetching', true)

const setReset = state => state.merge(DEFAULT)

const setRequest = (state, action) => {
  return state.merge({
    sendDate: action.data.currentCart.sendDate,
    isFetching: true,
    error: null,
  })
}

const setSuccess = (state, action) => {
  // if the success date is not the same as the last order preview send,
  // or if the date of sending the post order preview is earlier than the last modification of cart
  // we ignore it

  // we get the send date from the state because if we do it earlier, the value is the previous send date
  // and not the last one
  const sendDate = state.get('sendDate', null)
  const modificationDate = state.get('modificationDate', null)
  if (!isLastPreview(action.data.currentCart.sendDate, sendDate, modificationDate)) {
    return state
  }

  // else we can set the new order preview state with the response
  return state
    .set('verifications', action.response.verifications)
    .set('order', action.response.order)
    .set('error', null)
    .set('isFetching', false)
}

const setFailure = (state, action) => {
  // if the failure date is not the same as the last order preview send,
  // or if the date of sending the post order preview is earlier than the last modification of cart
  // we ignore it
  const sendDate = state.get('sendDate', null)
  const modificationDate = state.get('modificationDate', null)
  if (
    !isUndefined(action.data) &&
    !isLastPreview(action.data.currentCart.sendDate, sendDate, modificationDate)
  ) {
    return state
  }

  // else we can set the new error preview state with the response
  return state
    .set('order', null)
    .set('verifications', null)
    .set('error', action.error || action.response)
    .set('isFetching', false)
}

const setModificationDate = (state, action) =>
  state.set('modificationDate', action.modificationDate)

const getCartActionReducer = () => {
  const reducers = {}
  forEach(cartModifierActions, action => {
    reducers[action] = setIsFetching
  })
  return reducers
}

export const orderPreviewReducer = createReducer(DEFAULT, {
  [PostOrderPreviewAction.RESET]: setReset,
  [PostOrderPreviewAction.REQUEST]: setRequest,
  [PostOrderPreviewAction.SUCCESS]: setSuccess,
  [PostOrderPreviewAction.FAILURE]: setFailure,
  [PostOrderPreviewAction.SET_MODIFICATION_DATE]: setModificationDate,
  ...getCartActionReducer(),
})
