import React from 'react'
import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import { connect } from 'react-redux'

import {
  fetchRestaurant,
  makeGetRestaurant,
  makeGetRestaurantError,
  makeIsFetchingRestaurant,
  makeGetRestaurantFromRestaurantList,
  setRestaurant,
} from '../'

/**
 * @specs N/A
 *
 * A HOC that give fetch the restaurant and pass it to the children
 *
 * Requirements:
 * - restaurantId
 *
 * see `withRestaurant`
 *
 */
class RestaurantContainer extends React.PureComponent {
  constructor(props) {
    super(props)

    // check if we are not already loading a restaurant
    if ((isNull(props.restaurant) || props.forceRefresh) && !props.isFetchingRestaurant) {
      this.loadRestaurant(props)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props

    if (
      nextProps.restaurantId !== props.restaurantId &&
      ((!props.restaurant || props.restaurant.id) && nextProps.restaurantId)
    ) {
      // we check if the new restaurant id exists in the list of the restaurant store
      if (!nextProps.restaurantFromRestaurantList) {
        this.loadRestaurant(nextProps)
      } else {
        // if it does and the id is not the same of the current restaurant,
        // we set it as our new restaurant
        nextProps.setRestaurant(nextProps.restaurantFromRestaurantList)
      }
    }
  }

  loadRestaurant(props) {
    const restaurantId = props.restaurantId

    if (!isNil(restaurantId)) {
      props.fetchRestaurant(restaurantId)
    } else {
      // TODO : display error message?
    }
  }

  render() {
    const {
      RestaurantWrappedComponent,
      children,
      isFetchingRestaurant,
      restaurant,
      restaurantId,
      restaurantError,
      ...otherProps
    } = this.props

    if (isNil(RestaurantWrappedComponent)) {
      return children({
        isFetchingRestaurant,
        restaurant,
        restaurantId,
        restaurantError,
        ...otherProps,
      })
    }

    return (
      <RestaurantWrappedComponent
        isFetchingRestaurant={isFetchingRestaurant}
        restaurant={restaurant}
        restaurantId={restaurantId}
        restaurantError={restaurantError}
        children={children}
        {...otherProps}
      />
    )
  }
}

RestaurantContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  fetchRestaurant: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool,
  isFetchingRestaurant: PropTypes.bool,
  restaurant: PropTypes.object,
  restaurantId: PropTypes.string,
}

const makeMapStateToProps = () => {
  const getRestaurant = makeGetRestaurant()
  const getRestaurantError = makeGetRestaurantError()
  const isFetchingRestaurant = makeIsFetchingRestaurant()
  const getRestaurantFromRestaurantList = makeGetRestaurantFromRestaurantList()

  const mapStateToProps = (state, props) => {
    if (props.restaurant) {
      return {
        restaurant: props.restaurant,
        restaurantError: null,
        isFetchingRestaurant: false,
      }
    }

    return {
      restaurant: getRestaurant(state, props),
      restaurantError: getRestaurantError(state, props),
      isFetchingRestaurant: isFetchingRestaurant(state, props),
      restaurantFromRestaurantList: getRestaurantFromRestaurantList(state, props),
    }
  }
  return mapStateToProps
}

export default connect(
  makeMapStateToProps,
  {
    fetchRestaurant,
    setRestaurant,
  }
)(RestaurantContainer)
