import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Soy = props => (
  <SvgIcon {...props}>
    <path
      d="M3.244,15a5.114,5.114,0,0,1-2.44-.62l-.12-.065L.619,14.2a5.145,5.145,0,0,1-.572-3.136A5.106,5.106,0,0,1,3.563,6.891,5.111,5.111,0,0,1,6.89,3.564,5.106,5.106,0,0,1,14.2.62l.12.065.065.12a5.142,5.142,0,0,1,.572,3.136,5.107,5.107,0,0,1-3.516,4.171A5.111,5.111,0,0,1,8.11,11.439,5.106,5.106,0,0,1,3.244,15ZM8.8,2.151a4.172,4.172,0,0,0-1.09,1.9l-.068.265-.265.068A4.18,4.18,0,0,0,4.387,7.382l-.068.265-.265.068A4.178,4.178,0,0,0,1.37,13.632a4.192,4.192,0,0,0,1.874.443,4.177,4.177,0,0,0,4.042-3.127l.068-.265.265-.068A4.18,4.18,0,0,0,10.613,7.62l.068-.265.265-.068A4.178,4.178,0,0,0,13.63,1.371a4.178,4.178,0,0,0-4.826.78Zm-6,10.045a1.933,1.933,0,1,1,1.367.566A1.92,1.92,0,0,1,2.806,12.2Zm.656-2.078a1.006,1.006,0,1,0,.711-.294A1,1,0,0,0,3.462,10.118Zm2.671-1.25A1.933,1.933,0,1,1,7.5,9.435,1.936,1.936,0,0,1,6.133,8.868ZM6.789,6.79A1.005,1.005,0,1,0,7.5,6.5,1.006,1.006,0,0,0,6.789,6.79ZM9.46,5.541a1.933,1.933,0,1,1,1.367.566A1.935,1.935,0,0,1,9.46,5.541Zm.656-2.078a1.006,1.006,0,1,0,.711-.294A1.007,1.007,0,0,0,10.116,3.463Z"
      transform="translate(4.151 4.141)"
    />
  </SvgIcon>
)
Soy = pure(Soy)
Soy.displayName = 'Soy'
Soy.muiName = 'SvgIcon'

export default Soy
