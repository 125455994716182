import { createMiddleware } from 'pmt-modules/redux'
import { openProduct } from '../orderProduct'

import { OrderMenuAction } from './actions'

const openMenuProductMiddleware = createMiddleware(
  OrderMenuAction.SELECT_PRODUCT,
  ({ dispatch, action }) => {
    if (!action.part.hasReachedMaxQuantity) {
      dispatch(
        openProduct(action.product, {
          isMenuMode: true,
          restaurantId: action.options.restaurantId,
          partId: action.part.id,
          partCategoryId: action.partCategory.id,
          orderProduct: action.orderProduct,
          redirectToMenuPage: false,
        })
      )
    }
  }
)

const openEditMenuProductMiddleware = createMiddleware(
  OrderMenuAction.EDIT_PRODUCT,
  ({ dispatch, action }) => {
    dispatch(
      openProduct(action.orderProduct, {
        isMenuMode: true,
        restaurantId: action.options.restaurantId,
        partId: action.part.id,
        partCategoryId: action.partCategory.id,
        orderProduct: action.orderProduct,
        isEditMode: true,
      })
    )
  }
)

export const orderMenuMiddlewares = [openMenuProductMiddleware, openEditMenuProductMiddleware]
