import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'

import { EventManager } from 'pmt-modules/event'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import AvChange from 'pmt-ui/svg-icons/av/change'
import withWidth from 'pmt-ui/utils/withWidth'

import { ButtonLink } from '../../Button'

const styles = (theme) => ({
  root: {},
  icon: {
    width: '24px !important',
    height: '24px !important',
    marginRight: '2px !important',
  },
})

const ChangeButton = ({ classes, onClick, orderProperties, width }) => (
  <div className={classes.root}>
    <ButtonLink
      classes={{ root: classes.changeButton }}
      disabled={isNil(orderProperties.mode)}
      onClick={() => {
        EventManager.dispatch(EventManager.Events.ON_HEADER_BUTTON_CHANGE, {
          mode: orderProperties.mode,
        })
        onClick()
      }}
      icon={<AvChange classes={{ root: classes.icon }} />}
      label={
        <TypographyCustom type={145} skipColor>
          {tr('order.global.change')}
        </TypographyCustom>
      }
    />
  </div>
)

export default compose(withWidth(), withStyles(styles))(ChangeButton)
