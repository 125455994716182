import { tr } from 'pmt-modules/i18n'
export const RestaurantStatus = {
  CREATED: 'CREATED',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  ARCHIVED: 'ARCHIVED',
}

export const getRestaurantStatusLabel = status => {
  switch (status) {
    case RestaurantStatus.CREATED:
      return tr('global.restaurant.status.CREATED')
    case RestaurantStatus.ENABLED:
      return tr('global.restaurant.status.ENABLED')
    case RestaurantStatus.DISABLED:
      return tr('global.restaurant.status.DISABLED')
    case RestaurantStatus.ARCHIVED:
      return tr('global.restaurant.status.ARCHIVED')
    default:
      return status
  }
}

export const getRestaurantStatusExplainationText = status => {
  switch (status) {
    case RestaurantStatus.CREATED:
      return tr('global.restaurant.status.explain.CREATED')
    case RestaurantStatus.ENABLED:
      return tr('global.restaurant.status.explain.ENABLED')
    case RestaurantStatus.DISABLED:
      return tr('global.restaurant.status.explain.DISABLED')
    case RestaurantStatus.ARCHIVED:
      return tr('global.restaurant.status.explain.ARCHIVED')
    default:
      return status
  }
}

export const PspType = {
  MANGOPAY: 'MANGOPAY',
  PAYZEN: 'PAYZEN',
  PAYGREEN: 'PAYGREEN',
  SAFERPAY: 'SAFERPAY',
  FAKE: 'FAKE',
}

export const getPspLabel = type => {
  switch (type) {
    case PspType.MANGOPAY:
      return 'MangoPay'
    case PspType.PAYGREEN:
      return 'PayGreen'
    case PspType.PAYZEN:
      return 'PayZen'
    case PspType.SAFERPAY:
      return 'Saferpay'
    case PspType.FAKE:
      return 'FAKE'
    default:
      return type
  }
}
