import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { formatTopUp } from '../format'

const getTopUpState = state => state.entities.topUp
const getUserAccountIdOnProps = (state, props) =>
  props.userAccount ? props.userAccount.id : props.userAccountId

export const makeGetTopUpForAccount = () =>
  createSelector([getUserAccountIdOnProps, getTopUpState], (userAccountId, topUpState) => {
    if (isNil(topUpState)) {
      return null
    }
    topUpState = topUpState.toJS()

    const topUpData = topUpState[userAccountId]
    if (isNil(topUpData)) {
      return null
    }

    let topUp = topUpData.data

    if (isNil(topUp)) {
      return null
    }

    return formatTopUp(topUp)
  })

export const makeGetTopUpErrorForAccount = () =>
  createSelector([getUserAccountIdOnProps, getTopUpState], (userAccountId, topUpState) => {
    const topUpData = topUpState.get(userAccountId)
    if (isNil(topUpData)) {
      return null
    }

    const error = topUpData.get('error')
    if (isNil(error)) {
      return null
    }

    return error.toJS()
  })

export const makeIsPostingTopUp = () =>
  createSelector([getUserAccountIdOnProps, getTopUpState], (userAccountId, topUpState) => {
    const topUpData = topUpState.get(userAccountId)
    if (isNil(topUpData)) {
      return false
    }

    const isFetching = topUpData.get('isFetching')
    if (isNil(isFetching)) {
      return false
    }

    return isFetching
  })

export const makeGetOtherAmountClicked = () =>
  createSelector([getUserAccountIdOnProps, getTopUpState], (userAccountId, topUpState) => {
    const topUpData = topUpState.get(userAccountId)
    if (isNil(topUpData)) {
      return null
    }

    let topUp = topUpData.get('data')

    if (isNil(topUp)) {
      return null
    }

    return topUpData.get('otherAmountClicked')
  })
