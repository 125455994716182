import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { EventManager } from 'pmt-modules/event'
import {
  addOrderMenuToCart,
  addOrderProductToCart,
  addToCartFromShortcut,
  setSuspendedDatas,
} from 'pmt-modules/cart'
import { getOrderProperties } from 'pmt-modules/orderPlugin'
import { hideSuggestionDialog } from 'pmt-modules/suggestion'
import { openProduct } from 'pmt-modules/orderProduct'
import {
  openMenu,
  isMenuAutofillable,
  getFilledMenu,
  createOrderMenuFromMenu,
} from 'pmt-modules/orderMenu'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { SuggestionActionType, getSuggestion } from 'pmt-modules/suggestion'

import { CardItem } from 'pmt-ui/Card'
import { DialogActions, DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import { TypographyCustom } from 'pmt-ui/Typography'
import withWidth, { isWidthDown } from 'pmt-ui/utils/withWidth'
import { withStyles } from 'pmt-ui/styles'
import ActionDone from 'pmt-ui/svg-icons/action/done'
import ContentAdd from 'pmt-ui/svg-icons/content/add'
import Grid from 'pmt-ui/Grid'
import StateMachine from 'pmt-ui/StateMachine'
import CloseIcon from 'pmt-ui/svg-icons/navigation/close'

import Button from '../../../components/Button'
import Chip from '../../../components/Chip'
import CartModifierTag from '../../../components/CartModifier/CartModifierTag'
import CardItemOrder from '../../../components/Card/CardItemOrder'

const styles = (theme) => ({
  dialogTitle: {
    float: 'left',
    width: 'calc(100% - 35px)',
  },
  cardsCentered: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeArea: {
    float: 'right',
    cursor: 'pointer',
    height: 35,
    width: 35,
    position: 'absolute',
    top: 15,
    right: 15,
    background: theme.pmt.colors.grey100,
    borderRadius: theme.shape.border.radius.closeIconModal,
  },
  closeBtn: {
    width: 15,
    height: 15,
  },
  dialogActions: {
    cursor: 'pointer',
    justifyContent: 'center',
    margin: 0,
    padding: `${theme.spacing(2)}px 0`,
    borderTop: `1px solid ${theme.shape.border.color.default}`,
    zIndex: 1,
  },
  actionTypeContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  actionTypeLabelWrapper: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      ...theme.pmt.mixins.textEllipsis,
    },
  },
  actionTypeLabel: {
    fontWeight: 700,
  },
  cartModifierChip: {
    marginBottom: theme.spacing(0.5),
    cursor: 'pointer',
    borderRadius: 6,
    padding: '5px 8px',
    fontSize: 12,
    lineHeight: '14px',
    height: 'auto',
    textTransform: 'capitalize',
  },
  cartModifierUnavailable: {
    opacity: 0.2,
  },
  buttonAdd: {
    borderRadius: 10,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      borderColor: theme.palette.primary.dark,
    },
  },
})

const State = {
  NORMAL: 'NORMAL',
  ADDING_TO_CART: 'ADDING_TO_CART',
}

/**
 * @specs N/A
 */
@withRestaurant
class SuggestionDialog extends React.PureComponent {
  getItemSuggestedPrice(item, actionType) {
    let price = ''

    if (item.suggestedPrice === 0 && actionType === SuggestionActionType.REPLACE) {
      price = tr('global.suggestions.same_price')
      return price
    }

    if (actionType === SuggestionActionType.REPLACE) {
      price = `${item.suggestedPrice > 0 ? '+' : ''}`
    }

    price += item.suggestedPriceFormatted

    return price
  }

  handleOpenItem = (item, actionType, options) => {
    const {
      addOrderMenuToCart,
      setSuspendedDatas,
      hideSuggestionDialog,
      openMenu,
      openProduct,
    } = this.props

    // we set suspended item here so we can add it if the user cancel on product/menu view
    setSuspendedDatas(this.props.orderItem, actionType)
    hideSuggestionDialog()

    if (item.isProduct) {
      openProduct(item, options)
    } else if (item.isMenu) {
      if (actionType === SuggestionActionType.REPLACE && isMenuAutofillable(item)) {
        const filledMenu =
          item.isOrderMenu === true
            ? getFilledMenu(item)
            : getFilledMenu(createOrderMenuFromMenu(item))

        addOrderMenuToCart(filledMenu, { skipSuggestion: true })
        setSuspendedDatas(null, null)
      } else {
        openMenu(item, options)
      }
    }
  }

  handleHideDialogAndAddItem = () => {
    this.props.hideSuggestionDialog()

    if (this.props.orderItem.isProduct) {
      EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT, {
        dueDate: this.props.orderProperties.dueDate,
        item: this.props.orderItem,
        restaurant: this.props.restaurant,
      })
      this.props.addOrderProductToCart(this.props.orderItem, { skipSuggestion: true })
    } else if (this.props.orderItem.isMenu) {
      EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_MENU, {
        dueDate: this.props.orderProperties.dueDate,
        item: this.props.orderItem,
        restaurant: this.props.restaurant,
      })
      this.props.addOrderMenuToCart(this.props.orderItem, { skipSuggestion: true })
    }
  }

  getItemsByActionType = (suggestions, actionType) =>
    suggestions.filter(({ item, action }) => action === actionType && item.available)

  render() {
    const { classes, orderItem, addToCartFromShortcut, restaurant, width } = this.props
    const isMobile = isWidthDown('sm', width)

    return (
      <React.Fragment>
        <DialogTitle disableTypography>
          <TypographyCustom type="226" skipColor className={classes.dialogTitle}>
            {!isNil(orderItem.suggestedIntroduction) && !isEmpty(orderItem.suggestedIntroduction)
              ? orderItem.suggestedIntroduction
              : tr('order.suggestions')}
          </TypographyCustom>
          <div
            className={classNames('u-flexCenter', 'u-justifyContentCenter', classes.closeArea)}
            onClick={this.handleHideDialogAndAddItem}
          >
            <CloseIcon className={classes.closeBtn} />
          </div>
        </DialogTitle>
        <DialogContent>
          {values(SuggestionActionType).map((actionType) => {
            const items = this.getItemsByActionType(orderItem.suggestions, actionType)
            return (
              items.length > 0 && (
                <Grid
                  spacing={2}
                  key={`actionType-${actionType}-label`}
                  container
                  className={classes.actionTypeContainer}
                >
                  <Grid item xs={12} md={12} className={classes.actionTypeLabelWrapper}>
                    <TypographyCustom component="span" type={164}>
                      <React.Fragment>
                        <span className={classes.actionTypeLabel}>
                          {tr(`order.suggestions.action_type.${actionType}`)}
                        </span>
                        &nbsp;
                        {tr(`order.suggestions.action_type.your`)}
                        &nbsp;
                        {orderItem.name}
                      </React.Fragment>
                    </TypographyCustom>
                  </Grid>
                  <Grid
                    spacing={isMobile ? 0 : 2}
                    container
                    key={`actionType-${actionType}-items`}
                    item
                    xs={12}
                    md={12}
                  >
                    {items.map(({ item, action }) => (
                      <Grid
                        key={`item-${item.id}`}
                        item
                        xs={12}
                        md={items.length === 1 ? 12 : items.length === 2 ? 6 : 4}
                        className={isMobile ? 'u-marginBottom20' : classes.cardsCentered}
                      >
                        <StateMachine
                          states={State}
                          default={State.NORMAL}
                          transitions={[
                            {
                              state: State.ADDING_TO_CART,
                              to: State.NORMAL,
                              duration: 1000,
                            },
                          ]}
                        >
                          {({ currentState, transitionTo }) => (
                            <CardItem
                              type={<CardItemOrder />}
                              // give item even if it is not used, to simplify debug on react dev tools
                              item={item}
                              description={item.description}
                              image={item.image}
                              restaurantLogo={restaurant.logo}
                              name={item.name}
                              price={this.getItemSuggestedPrice(item, actionType)}
                              isAvailable={item.available}
                              isCategory={item.isCategory}
                              hasCartModifier={
                                SuggestionActionType.REPLACE !== actionType && item.hasCartModifier
                              }
                              basePrice={
                                SuggestionActionType.REPLACE !== actionType && item.hasCartModifier
                                  ? item.basePriceFormatted
                                  : null
                              }
                              cartModifierTag={(cartModifier, index, hasBorder = false) => (
                                <CartModifierTag
                                  cartModifier={cartModifier}
                                  chipElement={
                                    <Chip
                                      key={index}
                                      label={cartModifier.tag}
                                      noBorder={!hasBorder}
                                      classes={{
                                        root: classNames(classes.cartModifierChip, {
                                          [classes.cartModifierUnavailable]: !item.available,
                                        }),
                                      }}
                                    />
                                  }
                                  key={index}
                                  overEventsEnabled={false}
                                />
                              )}
                              onClickCard={() => {
                                if (item.available) {
                                  EventManager.dispatch(
                                    EventManager.Events.ON_CATALOG_ITEM_DETAIL,
                                    {
                                      item,
                                      restaurant,
                                      category: {
                                        name: 'Suggestion',
                                      },
                                    }
                                  )

                                  if (actionType === SuggestionActionType.COMPOSE) {
                                    this.handleHideDialogAndAddItem()
                                  }
                                  this.handleOpenItem(item, actionType, {
                                    restaurantId: restaurant.id,
                                    skipSuggestion: true,
                                  })
                                }
                              }}
                              buttonAdd={
                                <Button
                                  size="small"
                                  classes={{
                                    root: classes.buttonAdd,
                                  }}
                                  icon={
                                    actionType === SuggestionActionType.COMPOSE &&
                                    (currentState === State.NORMAL ? (
                                      <ContentAdd />
                                    ) : (
                                      <ActionDone />
                                    ))
                                  }
                                  label={tr(
                                    actionType === SuggestionActionType.COMPOSE
                                      ? currentState === State.NORMAL
                                        ? 'order.catalog.add'
                                        : 'order.catalog.added'
                                      : currentState === State.NORMAL
                                      ? 'order.catalog.replace'
                                      : 'order.catalog.replaced'
                                  )}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    if (item.available) {
                                      if (actionType === SuggestionActionType.COMPOSE) {
                                        this.handleHideDialogAndAddItem()
                                      } else {
                                        this.props.hideSuggestionDialog()
                                      }
                                      if (item.isComplexItem) {
                                        this.handleOpenItem(item, actionType, {
                                          restaurantId: restaurant.id,
                                          skipSuggestion: true,
                                        })
                                      } else {
                                        transitionTo(State.ADDING_TO_CART)
                                        EventManager.dispatch(
                                          EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT,
                                          {
                                            item,
                                            restaurant,
                                          }
                                        )
                                        addToCartFromShortcut(item)
                                      }
                                    }
                                  }}
                                />
                              }
                            />
                          )}
                        </StateMachine>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )
            )
          })}
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            variant="outlined"
            label={tr('order.suggestions.no_thanks')}
            onClick={this.handleHideDialogAndAddItem}
            size="large"
            color="secondary"
          />
        </DialogActions>
      </React.Fragment>
    )
  }
}

// Dialog root properties
SuggestionDialog.DialogRootProps = {
  // we want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

SuggestionDialog.DialogStyles = (theme) => ({
  paper: {
    // fix weird bug on Safari
    zIndex: 100,
    // -- for IE
    '-ms-overflow-style': '-ms-autohiding-scrollbar',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      maxHeight: '100%',
      margin: 0,
      borderRadius: 0,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 1100,
      // -- for IE
      width: '100%',
    },
  },
})

const mapStateToProps = (state, props) => ({
  orderProperties: getOrderProperties(state),
  orderItem: getSuggestion(state, props),
})

export default compose(
  withWidth(),
  withStyles(styles),
  connect(mapStateToProps, {
    addOrderProductToCart,
    addOrderMenuToCart,
    setSuspendedDatas,
    hideSuggestionDialog,
    addToCartFromShortcut,
    openMenu,
    openProduct,
  })
)(SuggestionDialog)
