import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Clock = (props) => (
  <SvgIcon {...props}>
    <path d="M12,0.2C5.5,0.2,0.2,5.5,0.2,12c0,6.5,5.3,11.8,11.8,11.8c6.5,0,11.8-5.3,11.8-11.8      C23.8,5.5,18.5,0.2,12,0.2z M12,21.2c-5.1,0-9.2-4.1-9.2-9.2c0-5.1,4.1-9.2,9.2-9.2c5.1,0,9.2,4.1,9.2,9.2      C21.2,17.1,17.1,21.2,12,21.2z" />
    <polygon xmlns="http://www.w3.org/2000/svg" points="12.9,5.3 11.1,5.3 11.1,12.4 15.4,16.7 16.7,15.4 12.9,11.6" />
  </SvgIcon>
)

Clock = pure(Clock)
Clock.displayName = 'Clock'
Clock.pmtName = 'SvgIcon'

export default Clock
