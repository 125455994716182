import { createAction } from '../redux'

import { DEFAULT_GEOLOCATION_ID } from './constants'

export const GeolocationAction = {
  INIT: 'GEOLOCATION::INIT',
  SUCCESS: 'GEOLOCATION::SUCCESS',
  FAILURE: 'GEOLOCATION::FAILURE',
  SET_ADDRESS: 'GEOLOCATION::SET::ADDRESS',
  SET_COMPUTE_ADDRESS: 'GEOLOCATION::SET::COMPUTE_ADDRESS',
  SET_COMPUTE_ADDRESS_EXTRA: 'GEOLOCATION::SET::COMPUTE_ADDRESS_EXTRA',
  SET_COORDINATES: 'GEOLOCATION::SET::COORDINATES',
  RESET: 'GEOLOCATION::RESET::RESET',
  RESET_COORDINATES: 'GEOLOCATION::RESET::COORDINATES',
  RESET_FULL: 'GEOLOCATION::RESET::FULL',
  RESET_ERROR: 'GEOLOCATION::RESET::ERROR',
}

export const initGeolocation = (id = DEFAULT_GEOLOCATION_ID, origin) =>
  createAction(GeolocationAction.INIT, {
    id,
    origin,
  })

/**
 * @param {string} successCallback The function called in case of success
 */
export const successGeolocation = (id = DEFAULT_GEOLOCATION_ID, latitude, longitude) =>
  createAction(GeolocationAction.SUCCESS, {
    id,
    latitude,
    longitude,
  })

/**
 * @param {string} failureCallback The function called in case of failure
 */
export const failureGeolocation = (id = DEFAULT_GEOLOCATION_ID, failureCallback) =>
  createAction(GeolocationAction.FAILURE, {
    id,
    failureCallback,
  })

export const setGeolocationAddress = (id = DEFAULT_GEOLOCATION_ID, address) =>
  createAction(GeolocationAction.SET_ADDRESS, {
    id,
    address,
  })

export const setGeolocationComputeAddress = (id = DEFAULT_GEOLOCATION_ID, computeAddress) =>
  createAction(GeolocationAction.SET_COMPUTE_ADDRESS, {
    id,
    computeAddress,
  })

export const setGeolocationComputeAddressExtra = (
  id = DEFAULT_GEOLOCATION_ID,
  computeAddressExtra
) =>
  createAction(GeolocationAction.SET_COMPUTE_ADDRESS_EXTRA, {
    id,
    computeAddressExtra,
  })

export const resetGeolocation = (id = DEFAULT_GEOLOCATION_ID) =>
  createAction(GeolocationAction.RESET, {
    id,
  })

export const resetFullGeolocation = () => createAction(GeolocationAction.RESET_FULL, {})

export const setGeolocationCoordinates = (id = DEFAULT_GEOLOCATION_ID, latitude, longitude) =>
  createAction(GeolocationAction.SET_COORDINATES, {
    id,
    latitude,
    longitude,
  })

export const resetGeolocationCoordinates = (id = DEFAULT_GEOLOCATION_ID) =>
  createAction(GeolocationAction.RESET_COORDINATES, {
    id,
  })

export const resetGeolocationError = () => createAction(GeolocationAction.RESET_ERROR, {})
