import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import invariant from 'invariant'

import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import isFunction from 'lodash/isFunction'
import isNull from 'lodash/isNull'

import { initSearch, updateSearch, resetSearch, getSearch } from '../actions'
import { makeGetSearchList, makeIsFetchingSearchList, makeGetSearchError } from '../selectors'
import Search from './Search'

let timeOutSearch

class SearchContainer extends React.Component {
  state = {
    active: false,
  }

  componentWillMount() {
    this.props.initSearch(this.props.searchType)
    if (this.props.runSearchAtMount) {
      this.runSearch()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isNull(nextProps.query) && nextProps.query !== this.props.query) {
      this.onHandleChange(nextProps.query)
    } else if (!isEqual(this.props.apiCallProps.body, nextProps.apiCallProps.body)) {
      this.runSearch(null, nextProps)
    }
  }

  runSearch = (query = null, props = this.props) => {
    props.getSearch(props.searchType, {
      body: merge(
        {
          query,
        },
        props.apiCallProps.body
      ),
      ...omit(props.apiCallProps, ['body']),
    })
  }

  // We save a lot of api calls like that
  static TIMEOUT_SIZE = 800

  onHandleChange = query => {
    this.props.updateSearch(this.props.searchType, { query })

    clearTimeout(timeOutSearch)
    if (query.length >= this.props.minLength) {
      timeOutSearch = setTimeout(() => {
        this.runSearch(query)
        this.setState({
          active: true,
        })
      }, SearchContainer.TIMEOUT_SIZE)

      return
    }

    this.setState({
      active: false,
    })
  }

  render() {
    const {
      className,
      label,
      children,
      searchList,
      isFetchingSearchList,
      searchError,
      hideInput,
    } = this.props

    invariant(isFunction(children), 'SearchContainer children must be a function')

    return (
      <React.Fragment>
        <Search
          className={className}
          show={!hideInput}
          label={label}
          onChange={query => {
            this.onHandleChange(query)
          }}
          searchError={searchError}
        />
        {children({
          searchActive: this.state.active,
          searchList,
          isFetchingSearchList,
        })}
      </React.Fragment>
    )
  }
}

SearchContainer.defaultProps = {
  hideInput: false,
  runSearchAtMount: false,
  query: null,

  minLength: 3,
}

SearchContainer.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  searchType: PropTypes.string.isRequired,
  apiCallProps: PropTypes.object.isRequired,
  hideInput: PropTypes.bool,

  /**
   * Run the search when mounting the component
   * Used for search with paging and no query required
   */
  runSearchAtMount: PropTypes.bool,

  /**
   * Defines the query to use.
   * The hideInput prop should be set to true in this case
   */
  query: PropTypes.string,

  /**
   * Min length of the query to run the search
   */
  minLength: PropTypes.number,
}

const mapStateToProps = (state, props) => {
  const getSearchList = makeGetSearchList()
  const isFetchingSearchList = makeIsFetchingSearchList()
  const getSearchError = makeGetSearchError()

  return {
    searchList: getSearchList(state, props),
    isFetchingSearchList: isFetchingSearchList(state, props),
    searchError: getSearchError(state, props),
  }
}

export default connect(
  mapStateToProps,
  {
    initSearch,
    updateSearch,
    resetSearch,
    getSearch,
  }
)(SearchContainer)
