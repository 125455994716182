// import Immutable from 'immutable'

import isFunction from 'lodash/isFunction'

import ApiManager from '../ApiManager'

import { REQUEST, TYPES, DATA, API_CALL } from '../constants'
import { unauthorized } from '../actions'
import { createMiddleware } from '../../redux'
import { isActionApiCall } from '../utils'

// Fetches an API response. It is here we should normalizes the result JSON according to schema.
// This would make every API response have the same shape, regardless of how nested it was.
const callApi = (request, schema) =>
  new Promise((resolve, reject) => {
    const success = (responseJsonContent, response) => {
      resolve({ responseJsonContent, response })
    }

    const failure = apiError => {
      reject(apiError)
    }

    request.success = success
    request.failure = failure

    ApiManager.run(request)
  })

// A Redux middleware that interprets actions with API_CALL info specified.
// Performs the call and promises when such actions are dispatched.
export const apiMiddleware = createMiddleware(
  isActionApiCall,
  ({ getState, dispatch, next, action }) => {
    const apiCall = action[API_CALL]

    const request = apiCall[REQUEST]
    const types = apiCall[TYPES]
    const data = apiCall[DATA]

    const { schema } = request

    // if (!schema) {
    //   throw new Error('Specify one of the exported Schemas.')
    // }

    if (!Array.isArray(types) || types.length !== 3) {
      throw new Error('Expected an array of three action types.')
    }

    if (!types.every(type => typeof type === 'string')) {
      throw new Error('Expected action types to be strings.')
    }

    const actionWith = data => {
      const finalAction = Object.assign({}, action, data)
      delete finalAction[API_CALL]
      return finalAction
    }

    const [requestType, successType, failureType] = types
    next(
      actionWith({
        type: requestType,
        request: request,
        data: data,
      })
    )

    request.attemptRefreshOptions = {
      // The next 3 parameters are simply the arguments of
      // the middleware function
      action,
      next,
      getState,
      dispatch,
      failureAction: unauthorized,
      ...request.attemptRefreshOptions,
    }

    return callApi(request, schema)
      .then(({ responseJsonContent, response }) =>
        next(
          actionWith({
            type: successType,
            response: responseJsonContent,
            data: data,
            responseData: {
              status: response && response.status,
              headers: response && response.headers,
              ok: response && response.ok,
              url: response && response.url,
            },
          })
        )
      )
      .catch(apiError => {
        // see oauth attemptRefresh
        if (isFunction(request.attemptRefresh)) {
          request.attemptRefresh(request.attemptRefreshOptions)(apiError)
        }

        next(
          actionWith({
            type: failureType,
            error: apiError,
            data: data,
          })
        )
      })
  }
)
