import { createMiddleware } from 'pmt-modules/redux'
import { isOrderPlugin } from 'pmt-modules/environment'
import { getRoute, replaceWith } from 'pmt-modules/routing'

import { ForgotPasswordAction } from './actions'

/**
 * Intercepts ForgotPasswordAction.SUCCESS action and redirects to login screen if the recovery
 * Otherwise just sends action to next middleware
 */
const forgotPasswordSuccessMiddleware = createMiddleware(
  ForgotPasswordAction.SUCCESS,
  ({ next, action }) => {
    if (!isOrderPlugin()) {
      next(action) // send it to next so identity will be set

      return next(replaceWith(getRoute('LOGIN')))
    }
  }
)

export const forgotPasswordMiddlewares = [forgotPasswordSuccessMiddleware]
