import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { compose } from 'redux'

import Input, { InputLabel, InputAdornment } from '../Input'
import { FormHelperText } from 'pmt-ui/Form'
import IconButton from '../IconButton'
import Visibility from '../svg-icons/action/visibility'
import VisibilityOff from '../svg-icons/action/visibility-off'

import isEmpty from 'lodash/isEmpty'
import { makeStyles } from 'pmt-ui/styles'
import { withStyles } from 'pmt-ui/styles'

import HibpPasswordRangeContainer from 'pmt-modules/hibp/container/HibpPasswordRangeContainer'

const styles = theme => ({
  containerTextfield: {
    borderColor: theme.shape.border.color.default,
    borderWidth: theme.shape.border.width.default,
    borderRadius: theme.shape.border.radius.content,
    borderStyle: 'solid',
    position: 'relative',
  },
  label: {
    zIndex: 1,
    transform: 'translate(9px, 20px) scale(1)', // TODO: use theme?
    position: 'absolute',
    top: 0,
    left: 0,
    transformOrigin: 'top left',
    padding: '0 5px',
  },
  labelAnimated: {
    transform: 'translate(9px, -8px) scale(0.75)', // TODO: use theme?
    background: '#FFF',
  },
  input: {
    padding: '18.5px 14px', // TODO: use theme?
  },
})

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
}))

const HibpPasswordView = ({
  password,
  pwnedText,
  displayIsSecureMessage = true,
  displayCheckSecure = true,
  minPasswordLength = 8,
}) => {
  const classes = useStyles()

  // Kiosk doesn't need HIBP here, and thus, not message block either
  let MessageBlock = null
  if (!process.env.FEATURE_KIOSK) {
    MessageBlock = require('app/components/MessageBlock').default
  }

  return (
    <HibpPasswordRangeContainer password={password}>
      {({ isPwned, isFetching }) => {
        if (isFetching) {
          if (displayCheckSecure) {
            return (
              <MessageBlock
                classes={{ root: classes.root }}
                type={MessageBlock.Type.INFO}
                text={tr('security.hibp.pwned.checking')}
              />
            )
          }
          return null
        }

        if (isPwned) {
          return (
            <MessageBlock
              classes={{ root: classes.root }}
              type={MessageBlock.Type.WARNING}
              text={pwnedText}
            />
          )
        }

        const isTooSmall = isEmpty(password) || password.length < minPasswordLength
        if (displayIsSecureMessage && !isTooSmall) {
          return (
            <MessageBlock
              classes={{ root: classes.root }}
              type={MessageBlock.Type.INFO}
              text={tr('security.hibp.pwned.is_secure')}
            />
          )
        }
        return null
      }}
    </HibpPasswordRangeContainer>
  )
}

class PasswordFieldOrder extends React.Component {
  constructor(props) {
    super(props)

    // we don't think we can have > 10 password fields on a same page, so this should work
    this.key = Math.random() * 100 + 1
  }

  state = {
    showPassword: false,
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    })
  }

  // Handle field change event, pass name and value to the onChange callback.
  handleChange = event => {
    this.props.onChange(event)
  }

  render() {
    const {
      label,
      value,
      name,
      fullWidth,
      disabled,
      className,
      helperText,
      classes,
      checkSecurity = false,
      ...props
    } = this.props

    const key = `adornment-password_${this.key}`

    return (
      <div className={className}>
        <div className={classes.containerTextfield}>
          <InputLabel
            htmlFor={key}
            variant="outlined"
            className={`${classes.label} ${!isEmpty(value) ? classes.labelAnimated : null}`}
          >
            {label}
          </InputLabel>
          <Input
            id={key}
            name={name}
            type={this.state.showPassword ? 'text' : 'password'}
            value={value}
            fullWidth={fullWidth || false}
            disabled={disabled || false}
            onChange={this.handleChange}
            disableUnderline={true}
            classes={{ input: classes.input }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword}
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            {...props}
          />
        </div>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {checkSecurity && (
          <HibpPasswordView
            password={value}
            pwnedText={tr('security.hibp.pwned.should_choose_another')}
          />
        )}
      </div>
    )
  }
}
export default compose(withStyles(styles))(PasswordFieldOrder)
