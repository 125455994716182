import moment from 'moment'

import { getMs } from 'pmt-utils/date'

/**
 * The regex to validate an idempotency key.
 *
 * An idempotency key is a String with:
 * <ul>
 * <li>first a timestamp in milliseconds,
 * the timestamp has a length of 13, which run through 9999999999999ms = Saturday 20 November 2286 17:46:39.999
 * <li>then an underscore,
 * <li>then an alphanumeric 8 length string.
 *
 * Ex: 1528985071000_4d1y68y4
 *
 * With this rule, we can have up to 4^30 possibilities per milliseconds.
 */
export const generateIdempotencyKey = () => {
  const idempotencyKey = `${getMs(moment())}_${Math.random()
    .toString(32)
    .slice(4)}`
  const idempotencyRegex = new RegExp('^[0-9]{13}_[A-Za-z0-9]{8}$')

  if (idempotencyRegex.test(idempotencyKey)) {
    return idempotencyKey
  } else {
    return generateIdempotencyKey()
  }
}
