import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Add from 'pmt-ui/svg-icons/content/add'
import Grid from 'pmt-ui/Grid'
import InfoOutlineIcon from 'pmt-ui/svg-icons/action/info-outline'
import WheatIcon from 'pmt-ui/svg-icons/food/wheat'
import Hidden from 'pmt-ui/Hidden'
import Price from 'pmt-ui/Price'
import PlaceholderLogo from 'pmt-ui/svg-icons/maps/restaurant'

import Button, { ButtonLink } from '../../../components/Button'
import CartModifierTag from '../../../components/CartModifier/CartModifierTag'
import Chip from '../../../components/Chip'
import TipWithIcon from '../../../components/TipWithIcon'

import SubmitBtnText from './SubmitBtnText'

const styles = (theme) => ({
  imageContainer: {
    position: 'relative',
    width: 280,
    aspectRatio: 1.75,
    borderRadius: theme.shape.border.radius.secondaryContent,
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 30px)',
      maxWidth: 348,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  placeHolderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.pmt.colors.itemPlaceholderBackground,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.pmt.colors.itemPlaceholderBackgroundSecondary,
    },
  },
  mediaRestaurantLogo: {
    height: '120px',
    opacity: 0.3,
  },
  mediaPlaceholderLogo: {
    height: '80px',
    width: '100%',
    opacity: 0.3,
  },
  alignCenterVertically: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentContainer: {
    [theme.breakpoints.down('md')]: {
      flexBasis: 'calc(100% - 20px)',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  name: {
    clear: 'both',
    paddingRight: 24,
    lineHeight: '1.2em',
  },
  description: {
    opacity: 0.5,
    lineHeight: '1.5em',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 30px)',
    },
  },
  allergen: {
    marginTop: theme.spacing(1),
    justifyContent: 'flex-start',
    '& svg': {
      marginRight: `5px !important`,
      opacity: 0.5,
    },
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  actionsArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      margin: '0 10px',
    },
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  tipWithIcon: {
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(3),
      maxWidth: 320,
      float: 'right',
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(1),
      textAlign: 'left',
      marginBottom: theme.spacing(2),
    },
  },
  submitButtonWithText: {
    float: 'right',
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  price: {
    float: 'left',
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  baseValue: {
    textDecoration: 'line-through',
  },
  hr: {
    display: 'none',
  },
  cartModifierContainer: {
    marginBottom: 5,
  },
  cartModifierChip: {
    marginRight: theme.spacing(0.5),
    cursor: 'pointer',
    borderRadius: 6,
    padding: '5px 8px',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '14px',
    height: 'auto',
    display: 'inline',
    float: 'none',
    textTransform: 'capitalize',
  },
  imageAreaFromDialog: {
    [theme.breakpoints.up('md')]: {
      order: 0,
    },
  },
  contentContainerFromDialog: {
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  actionsAreaFromDialog: {
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
})

const Header = ({
  appConfigPreset,
  fromDialog,
  orderProduct,
  isMenuMode,
  isEditMode,
  restaurantLogo,
  onSubmit,
  classes,
  showDietaryInfoDialog,
}) => (
  <React.Fragment>
    <Grid spacing={2} container className={classes.root}>
      {/** PICTURE AREA
       * on desktop we always display this Grid item
       * on mobile, we display it only if there is an image, to avoid an empty block with padding
       */}
      <Hidden smDown={!orderProduct.hasImage}>
        <Grid
          item
          xs={12}
          md={fromDialog ? 6 : 3}
          className={{ [classes.imageAreaFromDialog]: fromDialog }}
        >
          <div className={classes.imageContainer}>
            {orderProduct.hasImage ? (
              <div
                className={classes.backgroundImage}
                style={{ backgroundImage: `url(${orderProduct.image})` }}
              />
            ) : !isEmpty(restaurantLogo) ? (
              <div className={classes.placeHolderContainer}>
                <img
                  className={classes.mediaRestaurantLogo}
                  src={restaurantLogo}
                  alt={orderProduct.name}
                />
              </div>
            ) : (
              <div className={classes.placeHolderContainer}>
                <PlaceholderLogo className={classes.mediaPlaceholderLogo} />
              </div>
            )}
          </div>
        </Grid>
      </Hidden>

      {/* TITLE AREA */}
      <Grid
        item
        xs={12}
        md={fromDialog ? 12 : 5}
        className={classNames(classes.contentContainer, {
          [classes.contentContainerFromDialog]: fromDialog,
        })}
      >
        {/* Name */}
        <TypographyCustom type="226" className={classes.name}>
          {orderProduct.name}
          <div className="u-marginTop10">
            <TypographyCustom type="124" component="p" className={classes.description}>
              {orderProduct.description}
            </TypographyCustom>
          </div>
        </TypographyCustom>
        {orderProduct.hasAllergens && (
          <ButtonLink
            onClick={showDietaryInfoDialog}
            className={classes.allergen}
            icon={<WheatIcon />}
            color="default"
          >
            <TypographyCustom type="146">{tr('order.dietary_info.title')}</TypographyCustom>
          </ButtonLink>
        )}
        {/* Price */}
        {(!isMenuMode || orderProduct.additionalPrice !== 0) && (
          <TypographyCustom type="226" className={classes.priceContainer}>
            <Price
              value={
                isMenuMode ? orderProduct.additionalPriceFormatted : orderProduct.priceFormatted
              }
              hasCartModifier={orderProduct.hasCartModifier}
              baseValue={orderProduct.hasCartModifier ? orderProduct.basePriceFormatted : null}
              classes={{ root: classes.price, baseValue: classes.baseValue, hr: classes.hr }}
            />
            {orderProduct.hasCartModifier && (
              <div className={classes.cartModifierContainer}>
                {orderProduct.cartModifiers.list.map((cartModifier, index) => (
                  <CartModifierTag
                    cartModifier={cartModifier}
                    chipElement={
                      <Chip
                        key={index}
                        label={cartModifier.tag}
                        classes={{ root: classes.cartModifierChip }}
                      />
                    }
                    key={index}
                  />
                ))}
              </div>
            )}
          </TypographyCustom>
        )}
      </Grid>

      {/* RIGHT AREA */}
      <Hidden smDown={!orderProduct.hasOptions || !orderProduct.hasMandatoryOptions}>
        <Grid
          item
          xs={12}
          md={fromDialog ? 6 : 4}
          className={classNames(classes.actionsArea, {
            [classes.actionsAreaFromDialog]: fromDialog,
          })}
        >
          <div className={classes.actionsContainer}>
            <Hidden smDown>
              <Button
                disabled={!orderProduct.isValid || !orderProduct.available}
                onClick={onSubmit}
                size="large"
                icon={!isEditMode && <Add />}
                className={classes.submitButtonWithText}
              >
                <SubmitBtnText
                  appConfigPreset={appConfigPreset}
                  orderProduct={orderProduct}
                  isMenuMode={isMenuMode}
                  isEditMode={isEditMode}
                />
              </Button>
            </Hidden>
            <TypographyCustom type="164">
              <TipWithIcon
                classes={{
                  root: classNames(classes.tipWithIcon, 'u-marginTop10', {
                    'u-hide': orderProduct.isValid,
                  }),
                }}
                icon={<InfoOutlineIcon />}
                label={
                  isMenuMode ? (
                    <span>{tr('order.product.options.select_menu')}</span>
                  ) : (
                    <span>{tr('order.product.options.select_cart')}</span>
                  )
                }
              />
            </TypographyCustom>
          </div>
        </Grid>
      </Hidden>
    </Grid>
  </React.Fragment>
)

export default withStyles(styles)(Header)
