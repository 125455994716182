import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'

import { EventManager, SendEventContainer } from 'pmt-modules/event'
import { getRoute, redirectTo } from 'pmt-modules/routing'
import { withAppConfig } from 'pmt-modules/appConfig'
import { withCatalog } from 'pmt-modules/catalog'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { SuggestionActionType, hideSuggestionDialog } from 'pmt-modules/suggestion'
import {
  showRestaurantDisabledDialog,
  withCategory,
  SelectedCategoryContainer,
  OrderPluginUrlCheckerContainer,
  goBackToStoreLocator,
  fromApp,
  getAppConfigFrontSettings,
  withOrderProperties,
} from 'pmt-modules/orderPlugin'
import { displayUpselling } from 'pmt-modules/orderFront'
import { displayDeleteCartDialog } from 'pmt-modules/dialog'
import {
  getItemListFromCart,
  addOrderMenuToCart,
  addOrderProductToCart,
  getSuspendedDatas,
  setSuspendedDatas,
} from 'pmt-modules/cart'

import withWidth, { isWidthUp, isWidthDown } from 'pmt-ui/utils/withWidth'
import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'

import OrderPage from '../../components/OrderPage'
import View from './View'

/**
 * @specs N/A
 */
@withAppConfig
@withOrderProperties
@withRestaurant
@withCatalog
@withCategory
@withScrollToTop({
  isInactive: (prevProps, props) =>
    (isNil(prevProps.categoryId) && !isNil(props.categoryId)) ||
    prevProps.categoryId !== props.categoryId,
})
class CatalogPage extends React.Component {
  constructor(props) {
    super(props)

    this.addSuspendedItem()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.restaurant && this.props.restaurant && this.props.restaurant.isStatusDisabled) {
      this.props.showRestaurantDisabledDialog()
    }
  }

  componentWillUnmount() {
    this.props.hideSuggestionDialog()
  }

  addSuspendedItem = () => {
    const {
      cartSuspendedDatas,
      addOrderProductToCart,
      addOrderMenuToCart,
      setSuspendedDatas,
    } = this.props

    // we check if we have suspended item to be added to cart
    // we need to be in replace mode because the compose mode is already added
    if (
      !isNil(cartSuspendedDatas) &&
      cartSuspendedDatas.suspendedActionType === SuggestionActionType.REPLACE
    ) {
      if (cartSuspendedDatas.suspendedItem.isProduct) {
        addOrderProductToCart(cartSuspendedDatas.suspendedItem, { skipSuggestion: true })
      } else if (cartSuspendedDatas.suspendedItem.isMenu) {
        addOrderMenuToCart(cartSuspendedDatas.suspendedItem, { skipSuggestion: true })
      }
      setSuspendedDatas(null, null)
    }
  }

  hasRedirectUriDefined = () => this.props.frontSettings && this.props.frontSettings.redirectUri

  handleRedirectToCart = () => {
    if (
      this.props.catalog &&
      this.props.catalog.hasUpsellings &&
      !this.props.orderProperties.hasAlreadyDisplayedUpselling
    ) {
      this.props.displayUpselling(this.props.catalog.upsellingCategory)
    }

    EventManager.dispatch(EventManager.Events.ON_CATALOG_CART_SUBMIT, {
      items: this.props.itemListFromCart,
      restaurant: this.props.restaurant,
    })

    this.props.redirectTo(getRoute('ORDER__CART'))
  }

  handleRedirectToStoreLocator = () => {
    if (this.hasRedirectUriDefined() && this.props.orderProperties.from === fromApp.WEBAPP) {
      window.location.href = this.props.frontSettings.redirectUri
    } else {
      this.props.itemListFromCart.length > 0
        ? this.props.displayDeleteCartDialog()
        : this.props.goBackToStoreLocator()
    }
  }

  render() {
    const {
      appConfigPreset,
      isFetchingCatalog,
      isFetchingRestaurant,
      catalog,
      catalogError,
      orderProperties,
      restaurantError,
      restaurant,
      location,
      params,
      route,
      width,
    } = this.props

    return (
      <SelectedCategoryContainer catalog={catalog}>
        {({ selectedCategory, parentCategories, isSubCategory }) => (
          <OrderPage
            headerProps={{
              // hide the header when there is a selected children category, since we display
              // the category header
              displayHeader: isWidthUp('md', width) || !isSubCategory,
              backLink: isWidthDown('sm', width) && this.handleRedirectToStoreLocator,
              displayRestaurantName: true,
              displayRestaurantLogo: isWidthUp('sm', width),
              displayTitle: isWidthUp('md', width),
              displayOrderMode: true,
              displayAddress: true,
              changeButton: isWidthUp('md', width) && this.handleRedirectToStoreLocator,
              displayDueDate: isWidthDown('sm', width),
              displayTableNumber: isWidthDown('sm', width),
              displayUser: true,
            }}
            orderProperties={orderProperties}
            restaurant={restaurant}
            location={location}
          >
            <OrderPluginUrlCheckerContainer
              location={location}
              params={params}
              orderProperties={orderProperties}
              checkApiConsumer
              verifyMode
              verifyRestaurantId
              verifyIsAsap
              verifyTableNumber
              verifyFrom
            >
              <SendEventContainer
                send={
                  orderProperties &&
                  !isNil(orderProperties.restaurant) &&
                  !isNil(orderProperties.dueDate)
                }
                event={EventManager.Events.ON_PAGE_LOADED}
                eventProps={() => ({
                  restaurant: orderProperties.restaurant,
                  dueDate: orderProperties.dueDate,
                })}
              >
                <View
                  appConfigPreset={appConfigPreset}
                  isFetchingCatalog={isFetchingCatalog && !catalog}
                  isFetchingRestaurant={isFetchingRestaurant}
                  catalog={catalog}
                  catalogError={catalogError}
                  restaurantError={restaurantError}
                  restaurant={restaurant}
                  orderProperties={orderProperties}
                  hideSideInformations={this.hideSideInformations}
                  showSideInformations={this.showSideInformations}
                  onRedirectToCart={this.handleRedirectToCart}
                  route={route}
                  selectedCategory={selectedCategory}
                  parentCategories={parentCategories}
                  isSubCategory={isSubCategory}
                  handleRedirectToStoreLocator={this.handleRedirectToStoreLocator}
                  from={orderProperties.from}
                  width={width}
                />
              </SendEventContainer>
            </OrderPluginUrlCheckerContainer>
          </OrderPage>
        )}
      </SelectedCategoryContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  itemListFromCart: getItemListFromCart(state),
  cartSuspendedDatas: getSuspendedDatas(state),
  frontSettings: getAppConfigFrontSettings(state),
})

export default compose(
  connect(mapStateToProps, {
    showRestaurantDisabledDialog,
    displayDeleteCartDialog,
    goBackToStoreLocator,
    addOrderMenuToCart,
    addOrderProductToCart,
    getSuspendedDatas,
    setSuspendedDatas,
    hideSuggestionDialog,
    redirectTo,
    displayUpselling,
  }),
  withWidth()
)(CatalogPage)
