import { tr } from 'pmt-modules/i18n'
import keys from 'lodash/keys'
import map from 'lodash/map'

/**
 * Defines the different possible type to display the history (on the web customer)
 */
export const FrontCateringHistoryType = {
  PAY_MY_TABLE: 'PAY_MY_TABLE',
  THIRD_PARTY: 'THIRD_PARTY',
}

export const GroupType = {
  USER: 'USER',
  OTHER: 'OTHER',
}

export const AcquirerType = {
  MANGOPAY: 'MANGOPAY',
  PAYGREEN: 'PAYGREEN',
  FAKE: 'FAKE',
  NONE: null,
}

export const getAcquirerLabel = acquirer => {
  switch (acquirer) {
    case AcquirerType.MANGOPAY:
      return 'MangoPay'
    case AcquirerType.PAYGREEN:
      return 'PayGreen'
    case AcquirerType.FAKE:
      return 'FAKE'
    default:
      return tr('global.none')
  }
}

export const UserManagerType = {
  NONE: null,
  LDB: 'leon_de_bruxelles',
}

export const getUserManagerTypeLabel = type => {
  switch (type) {
    case UserManagerType.LDB:
      return 'Leon de Bruxelles'
    default:
      return tr('global.none')
  }
}

export const getGroupType = type => GroupType[type]

export const getGroupTypeLabel = type => {
  switch (type) {
    case GroupType.USER:
      return tr('global.groups.type.user')
    case GroupType.OTHER:
      return tr('global.groups.type.other')
    default:
      return ''
  }
}

export const getGroupTypesLabelAsArray = () =>
  keys(GroupType).map(value => ({
    label: getGroupTypeLabel(value),
    value,
  }))

export const UserAccountManagerType = {
  NONE: 'NONE',

  /**
   * Local user account manager
   */
  paymytable: 'paymytable',

  /**
   * Cashpad user account manager
   */
  cashpad: 'cashpad',

  cashpadV2: 'cashpadV2',

  /**
   * POS CSI user account manager. User account manager settings identifier must contain
   * exploitation code + ":" + exploitation key (both values provided by CSI for each site)
   */
  csi: 'csi',
}

/**
 * Enum of locales that are handled by PMT. Only those locales can be defined as used locales on our
 * configurations (such as RestaurantsGroup locales).
 */
export const PMTLocale = {
  fr_FR: 'fr-FR',

  fr_CH: 'fr-CH',

  en_US: 'en-US',

  de_DE: 'de-DE',
}

export const getLocales = () => map(PMTLocale, value => value)
