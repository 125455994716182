import Immutable from 'immutable'

import { CartDiffAction } from './actions'

export * from './actions'
export * from './selectors'
export * from './middlewares'
export * from './utils'

const DEFAULT = Immutable.fromJS({
  itemList: [],
})

export const cartDiffReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case CartDiffAction.SET_ITEM_LIST:
      return state.merge({
        itemList: action.itemList,
      })

    default:
      return state
  }
}
