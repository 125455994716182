import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import ui from 'pmt-modules/reduxUi'

import { EventManager, SendEventContainer } from 'pmt-modules/event'
import { withAppConfig } from 'pmt-modules/appConfig'
import { getRoute, redirectTo } from 'pmt-modules/routing'
import {
  resetDayAndSlot,
  setDueDateAsap,
  OrderPluginUrlCheckerContainer,
  withOrderProperties,
  resetSelectedCategory,
} from 'pmt-modules/orderPlugin'
import { resetCart } from 'pmt-modules/cart/actions'
import { resetCatalog } from 'pmt-modules/catalog'
import RestaurantContainer from 'pmt-modules/restaurant/components/RestaurantContainer'

import withWidth, { isWidthUp, isWidthDown } from 'pmt-ui/utils/withWidth'
import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'

import { getQueryParam } from 'pmt-utils/url'

import OrderPage from '../../components/OrderPage'
import View from './View'

/**
 * @specs N/A
 */
@withAppConfig
@withOrderProperties
@withScrollToTop()
class StoreLocatorPage extends React.PureComponent {
  constructor(props) {
    super(props)

    // clean cart:
    // - redirected to store locator
    // - making a new order after a one being successfully made but the data has not been reset

    // false: do not send an order preview. We were sending an order preview when on mobile, on the
    // catalog page, making back to the store locator. See catchCartActionMiddleware.
    this.props.resetCart(false)
    this.props.resetDayAndSlot()
    if (this.props.orderProperties.forceAsap) {
      this.props.setDueDateAsap()
    }
    this.props.resetCatalog()
    this.props.resetSelectedCategory()

    // set default restaurantId from query param
    this.restaurantId = getQueryParam('restaurantId')
  }

  componentWillReceiveProps(nextProps) {
    // set isAsap after received onsite/delivery change of state
    if (
      nextProps.orderProperties.forceAsap &&
      this.props.orderProperties.forceAsap !== nextProps.orderProperties.forceAsap
    ) {
      nextProps.setDueDateAsap()
    }

    // if restaurantId differs from query param, update with one from state
    if (nextProps.orderProperties.restaurantId !== this.restaurantId) {
      this.restaurantId = nextProps.orderProperties.restaurantId
    }
  }

  getRestaurantId = () => this.restaurantId

  handleMenuRedirection = () => {
    EventManager.dispatch(EventManager.Events.ON_STORE_LOCATOR_SUBMIT, {
      restaurant: this.props.orderProperties.restaurant,
      mode: this.props.orderProperties.mode,
    })

    this.props.redirectTo(getRoute('ORDER__CATALOG'), {
      restaurantId: this.props.orderProperties.restaurantId,
    })
  }

  handleRedirectToMode = () => {
    const { ui, updateUI, redirectTo } = this.props

    ui.displaySecondPart
      ? updateUI({ displaySecondPart: false })
      : redirectTo(getRoute('ORDER__MODE'))
  }

  render() {
    const { appConfig, orderAppConfig, location, ui, updateUI, width, orderProperties } = this.props

    return (
      <OrderPluginUrlCheckerContainer
        location={location}
        verifyMode
        verifyRestaurantId
        verifyLatLng
        verifyAddress
        verifyIsAsap
        verifyTableNumber
      >
        <RestaurantContainer restaurantId={this.getRestaurantId()}>
          {({ restaurant, restaurantError }) => (
            <OrderPage
              headerProps={{
                displayTitle: isWidthUp('md', width),
                displaySecondLayout: ui.displaySecondPart,
                backLink:
                  isWidthDown('sm', width) &&
                  (orderAppConfig.modes.length > 1 || ui.displaySecondPart) &&
                  this.handleRedirectToMode,
                displayRestaurantName: true,
                displayOrderModeSelect:
                  (!ui.displaySecondPart || isWidthUp('md', width)) &&
                  orderAppConfig.modes.length > 1,
                displayOrderMode:
                  (ui.displaySecondPart && isWidthDown('sm', width)) ||
                  orderAppConfig.modes.length === 1,
                displayAddress: isWidthDown('sm', width),
                displayUser: true,
              }}
              orderProperties={orderProperties}
              appConfig={appConfig}
              restaurant={restaurant}
              location={location}
            >
              <SendEventContainer
                event={EventManager.Events.ON_PAGE_LOADED}
                eventProps={() => ({
                  props: orderProperties,
                })}
              >
                <View
                  handleMenuRedirection={this.handleMenuRedirection}
                  orderProperties={orderProperties}
                  restaurant={restaurant}
                  restaurantError={restaurantError}
                  ui={ui}
                  updateUI={updateUI}
                />
              </SendEventContainer>
            </OrderPage>
          )}
        </RestaurantContainer>
      </OrderPluginUrlCheckerContainer>
    )
  }
}

const mapStateToProps = state => ({})

export default compose(
  ui({
    state: {
      displaySecondPart: false,
      addressLocationNotGood: false,
    },
  }),
  connect(mapStateToProps, {
    resetDayAndSlot,
    setDueDateAsap,
    redirectTo,
    resetCart,
    resetCatalog,
    resetSelectedCategory,
  }),
  withWidth()
)(StoreLocatorPage)
