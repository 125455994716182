import invariant from 'invariant'
import Immutable, { fromJS } from 'immutable'
import isNil from 'lodash/isNil'

import Logger from 'pmt-utils/logger'
import { createReducer } from 'pmt-modules/redux'
import { CatalogItemType } from 'pmt-modules/catalog/constants'
import { getMenuFromCart, getMenuFromCartWithOrderId } from 'pmt-modules/orderMenu'
import {
  createOrderProductFromProduct,
  getProductFromCart,
  getProductFromCartWithOrderId,
} from 'pmt-modules/orderProduct'
import transformOrderMenuForStore from './utils/transformOrderMenuForStore'
// import { getCircularCulprit } from 'pmt-utils/data'

import { CartAction } from './actions'
import { updateItem, deleteItemFromCart } from './utils'

export * from './actions'
export * from './middlewares'
export * from './selectors'

const decreaseQuantity = object => {
  object.quantity--
  object.totalPriceWithQuantity = object.totalPriceWithQuantity - object.totalPrice
}

const increaseQuantity = object => {
  object.quantity++
  object.totalPriceWithQuantity = object.totalPrice + object.totalPriceWithQuantity
}

const addToCart = (itemListFromCart, item) => {
  if (item.isProduct) {
    let productFormatted = createOrderProductFromProduct(item)
    let productFromCart = getProductFromCart(itemListFromCart, productFormatted)
    // if product exists in cart
    if (!isNil(productFromCart)) {
      // increase its quantity
      increaseQuantity(productFromCart)
    } else {
      // add it to cart
      itemListFromCart.push(productFormatted)
    }
  } else {
    Logger.warn('CART_ERROR', `Item's type to add from cart not found. ID: ${item.id}`)
  }

  return itemListFromCart
}

// only products use this way of adding item to cart
// we have to format the given product because it's not formatted for being in cart
const addToCartFromShortcut = (state, action) => {
  let itemListFromCart = state.get('itemList')
  if (!isNil(itemListFromCart)) {
    itemListFromCart = itemListFromCart.toJS()
    let item = action.item

    invariant(
      item !== CatalogItemType.PRODUCT,
      `${item.id} is not a product and try be added to cart from shortcut`
    )

    itemListFromCart = addToCart(itemListFromCart, item)

    return state.mergeIn(['itemList'], fromJS(itemListFromCart))
  }

  return state
}

const addOrderProductToCart = (state, action) => {
  let itemListFromCart = state.get('itemList')
  if (!isNil(itemListFromCart)) {
    itemListFromCart = itemListFromCart.toJS()
    const orderProduct = action.orderProduct

    let productFromCart = getProductFromCart(itemListFromCart, orderProduct)
    // if product exists in cart
    if (!isNil(productFromCart)) {
      // increase its quantity
      increaseQuantity(productFromCart)
    } else {
      // add it to cart
      itemListFromCart.push(orderProduct)
    }

    return state.mergeIn(['itemList'], fromJS(itemListFromCart))
  }

  return state
}

const addOrderMenuToCart = (state, action) => {
  let itemListFromCart = state.get('itemList')
  if (!isNil(itemListFromCart)) {
    // commented since in re-adds nextPart on menus previously selected.
    itemListFromCart = itemListFromCart.toJS()
    const orderMenu = transformOrderMenuForStore(action.orderMenu)

    let menuFromCart = getMenuFromCart(itemListFromCart, orderMenu)

    // if product exists in cart
    if (!isNil(menuFromCart)) {
      // increase its quantity
      increaseQuantity(menuFromCart)
    } else {
      // push it
      itemListFromCart.push(orderMenu)
    }

    // uncomment to debug circular references errors.
    // try {
    return state.mergeIn(['itemList'], fromJS(itemListFromCart))
    // } catch (e) {
    //   console.error(e)
    //   console.log(itemListFromCart)
    //   console.log(getCircularCulprit(itemListFromCart))
    //   // avoid crashing the plugin, just log the error and return
    //   return state
    // }
  }

  return state
}

const updateOrderMenuOnCart = (state, action) => {
  let itemListFromCart = state.get('itemList')
  if (!isNil(itemListFromCart)) {
    itemListFromCart = itemListFromCart.toJS()

    const orderMenu = transformOrderMenuForStore(action.orderMenu)

    itemListFromCart = updateItem(itemListFromCart, orderMenu)
    return state.mergeIn(['itemList'], fromJS(itemListFromCart))
  }

  return state
}

const duplicateItemOnCart = (state, action) => {
  let itemListFromCart = state.get('itemList')
  if (!isNil(itemListFromCart)) {
    itemListFromCart = itemListFromCart.toJS()
    const item = action.item

    if (item.isProduct) {
      let productFromCart = getProductFromCartWithOrderId(itemListFromCart, item.orderId)
      // if product exists in cart
      if (!isNil(productFromCart)) {
        // increase its quantity
        increaseQuantity(productFromCart)
      }
    } else if (item.isMenu) {
      let menuFromCart = getMenuFromCartWithOrderId(itemListFromCart, item.orderId)
      // if menu exists in cart
      if (!isNil(menuFromCart)) {
        // increase its quantity
        increaseQuantity(menuFromCart)
      }
    }

    return state.mergeIn(['itemList'], fromJS(itemListFromCart))
  }

  return state
}

const removeFromCart = (state, action) => {
  let itemListFromCart = state.get('itemList')
  if (!isNil(itemListFromCart)) {
    itemListFromCart = itemListFromCart.toJS()
    let item = action.item

    let itemFromCart
    switch (item.type) {
      case CatalogItemType.PRODUCT:
        itemFromCart = getProductFromCartWithOrderId(itemListFromCart, item.orderId)
        break

      case CatalogItemType.MENU:
        itemFromCart = getMenuFromCartWithOrderId(itemListFromCart, item.orderId)
        break

      default:
        Logger.warn('CART_ERROR', `Item's type to remove from cart not found. ID: ${item.id}`)
    }

    if (!isNil(itemFromCart)) {
      decreaseQuantity(itemFromCart)
    } else {
      Logger.warn('CART_ERROR', `Item to remove from cart not found. ID: ${item.id}`)
    }

    return state.mergeIn(['itemList'], fromJS(itemListFromCart))
  }

  return state
}

const deleteFromCart = (state, action) => {
  let itemListFromCart = state.get('itemList')
  if (!isNil(itemListFromCart)) {
    itemListFromCart = itemListFromCart.toJS()
    let item = action.item

    itemListFromCart = deleteItemFromCart(itemListFromCart, item)
    return state.setIn(['itemList'], fromJS(itemListFromCart))
  }

  return state
}

const updateOrderProductOnCart = (state, action) => {
  let itemListFromCart = state.get('itemList')
  if (!isNil(itemListFromCart)) {
    itemListFromCart = itemListFromCart.toJS()
    const orderProduct = action.orderProduct
    itemListFromCart = updateItem(itemListFromCart, orderProduct)
    return state.mergeIn(['itemList'], fromJS(itemListFromCart))
  }

  return state
}

const setModificationDate = (state, action) => state.set('modificationDate', action.date)

// TODO: should be refactored to not be duplicated from orderSettings
const setMinimumPrice = (state, action) => state.set('minimumPrice', action.minimumPrice)

const updateComment = (state, action) => state.set('comment', action.comment)

const setSuspendedDatas = (state, action) =>
  state
    .set('suspendedItem', fromJS(action.suspendedItem))
    .set('suspendedActionType', action.suspendedActionType)

const resetCart = state =>
  state
    .set('itemList', fromJS([]))
    .set('modificationDate', null)
    .set('comment', '')

const setCart = (state, action) => state.set('itemList', fromJS(action.itemList))

const DEFAULT = Immutable.fromJS({
  itemList: [],
  suspendedItem: null,
  suspendedActionType: null,
  minimumPrice: 0,
  modificationDate: null,
  comment: '',
})

export const cartReducer = createReducer(DEFAULT, {
  [CartAction.ADD_TO_CART_FROM_SHORTCUT]: addToCartFromShortcut,
  [CartAction.ADD_ORDER_PRODUCT_TO_CART]: addOrderProductToCart,
  [CartAction.UPDATE_ORDER_PRODUCT_ON_CART]: updateOrderProductOnCart,
  [CartAction.ADD_ORDER_MENU_TO_CART]: addOrderMenuToCart,
  [CartAction.UPDATE_ORDER_MENU_ON_CART]: updateOrderMenuOnCart,
  [CartAction.DUPLICATE_ITEM_ON_CART]: duplicateItemOnCart,
  [CartAction.REMOVE_FROM_CART]: removeFromCart,
  [CartAction.DELETE_FROM_CART]: deleteFromCart,
  [CartAction.SET_MODIFICATION_DATE]: setModificationDate,
  [CartAction.SET_MINIMUM_PRICE]: setMinimumPrice,
  [CartAction.UPDATE_COMMENT]: updateComment,
  [CartAction.SET_CART]: setCart,
  [CartAction.SET_SUSPENDED_DATAS]: setSuspendedDatas,
  [CartAction.RESET_CART]: resetCart,
})
