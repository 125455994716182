import isFunction from 'lodash/isFunction'
import invariant from 'invariant'

import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { getHistory } from 'pmt-modules/routing'
import crashReporter from '../middleware/crashReporter'
import {
  apiMiddleware,
  unauthorizedMiddleware,
  requestConfigurationMiddleware,
} from '../../api/middlewares'

import { chooseLocaleMiddleware } from '../../i18n/middlewares'

import { isDev } from '../../environment'

const generateMiddlewares = (appOptions, appMiddlewares) => {
  let middlewares = []

  if (process.env.FEATURE_FIREBASE) {
    middlewares.push(thunk.withExtraArgument(import('react-redux-firebase').getFirebase))
  } else {
    middlewares.push(thunk) // Pass getFirebase function as extra argument,
  }

  middlewares = [
    ...middlewares,
    requestConfigurationMiddleware,
    apiMiddleware,
    process.env.__DEV__ ? require('pmt-modules/api/middlewares/apiTest').default : null,
    unauthorizedMiddleware,
    chooseLocaleMiddleware,
    crashReporter,
    process.env.__DEV__
      ? require('redux-logger').createLogger(
          // have to be before the apiMiddleware // only on dev // must be before reduxLogger and after thunk and api
          { collapsed: true }
        )
      : null,
    ...appMiddlewares, // work.. // FIXME: the router middleware has to be in the end, the actions made on middleware will not // only on dev, but requires to be at this specific place
    //
    //
    // connected-react-router need this middleware to handle functions such as `push(location)`,
    // `replace(loacation)`
    // It handle actions creators that correspond with the history methods of the same name.
    // see https://github.com/supasate/connected-react-router
    //
    // For reference they are defined as follows:
    // push - Pushes a new location to history, becoming the current location.
    // replace - Replaces the current location in history.
    // go - Moves backwards or forwards a relative number of locations in history.
    // goForward - Moves forward one location. Equivalent to go(1)
    // goBack - Moves backwards one location. Equivalent to go(-1)
    //
    // Both push and replace take in a location descriptor, which can be an object describing the URL
    // or a plain string URL.
    //
    routerMiddleware(getHistory()),
  ].filter(elem => elem !== null)

  if (isDev()) {
    middlewares.forEach((middleware, index) => {
      invariant(
        isFunction(middleware),
        `middleware is not a function at index ${index}\n${middleware}`
      )
    })
  }

  return middlewares // remove null elements
}

export default generateMiddlewares
