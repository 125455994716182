import { tr } from 'pmt-modules/i18n'
import each from 'lodash/each'
import isEmpty from 'lodash/isEmpty'

import {
  notEmpty,
  isGreaterThan,
  isValidEmail,
  isValidPhone,
  isTrue,
} from 'pmt-modules/form/validation'
import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

import { getField } from 'pmt-modules/registration/forms/register/constants'

export ProfileFormView from './ProfileFormView'

// in DEV environment, prefill form with the following data
const devData = {}

const defaultData = {
  email: null,
  firstName: null,
  lastName: null,
  phone: null,
  marketing: {
    allowContact: false,
  },
}

const validationRules = options => {
  const rules = {
    email: [[isValidEmail, tr('global.register.form.validation.email_invalid')]],
    phone: [[isValidPhone, tr('global.register.form.validation.phone_invalid')]],
    password: [[isGreaterThan(5), tr('global.register.form.validation.password_length')]],
    acceptCgu: [[isTrue, tr('global.register.form.validation.accept_legal_terms')]],
  }

  if (!isEmpty(options.fields)) {
    each(options.fields, optionField => {
      if (optionField.required) {
        const field = getField(optionField.type)
        rules[field.key] = [
          ...(rules[field.key] || []),
          [notEmpty, tr(`global.register.form.validation.required.${field.key}`)],
        ]
      }
    })
  }

  return rules
}

export default createForm(FormType.USER_PROFILE, validationRules, defaultData, devData)
