import React from 'react'

import OrderMode from 'pmt-modules/order/constants/OrderMode'
import { isOrderPlugin } from 'pmt-modules/environment'

import Delivery from 'pmt-ui/svg-icons/pmt/Delivery'
import OnSite from 'pmt-ui/svg-icons/pmt/OnSite'
import TakeAway from 'pmt-ui/svg-icons/pmt/TakeAway'

import DeliveryOrder from 'pmt-ui/svg-icons/pmt/DeliveryOrder'
import OnSiteOrder from 'pmt-ui/svg-icons/pmt/OnSiteOrder'
import TakeAwayOrder from 'pmt-ui/svg-icons/pmt/TakeAwayOrder'

const Icons = {
  [OrderMode.DELIVERY]: <Delivery />,
  [OrderMode.ON_SITE]: <OnSite />,
  [OrderMode.TAKE_AWAY]: <TakeAway />,
}

const IconsOrder = {
  [OrderMode.DELIVERY]: <DeliveryOrder />,
  [OrderMode.ON_SITE]: <OnSiteOrder />,
  [OrderMode.TAKE_AWAY]: <TakeAwayOrder />,
}

const IconOrderMode = ({ type, ...props }) =>
  React.cloneElement(isOrderPlugin() ? IconsOrder[type] : Icons[type], props)

export default IconOrderMode
