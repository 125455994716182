import { createMiddleware } from '../redux'

import { CHOOSE_LOCALE } from './actions'

import { saveLocaleToStorage } from './storage'

export const chooseLocaleMiddleware = createMiddleware(CHOOSE_LOCALE, ({ action }) => {
  const { locale } = action

  // save chosen language
  saveLocaleToStorage(locale)

  // retrieve messages
  // dispatch(retrieveMessages(locale))

  // for now reload the entire page since it is too difficult to update the whole app.
  // We would have to:
  // - reload top view to use the retrieved messages
  // - make sure there is no local state that depends of the old/new locale
  window.location.reload()
})
